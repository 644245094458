import React from "react";
import { Component, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import AddAlertRoundedIcon from "@material-ui/icons/AddAlertRounded";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { Snackbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import BoltIcon from "@mui/icons-material/Bolt";
import Load from "../../components/Load";
// axios.defaults.withCredentials =true
// import Alert from '@mui/material/Alert';

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"For Help, "}
        <Link color="primary" href="/help">
          Click Here!
        </Link>{" "}
        {"."}
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="primary" target="_blank" href="https://alarrt.com/">
          ALARRT
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        A Product Of AT-ALARRT Digital Technologies Limited
        {/* <Link
          style={{ paddingLeft: 3 }}
          color="primary"
          target="_blank"
          href="https://soaenterprise.com/"
        >
          SOA Digital Enterprise.
        </Link> */}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "130vh",
  },
  image: {
    backgroundImage:
      "url(https://alert-app-v2.s3.amazonaws.com/alarrt+small+single+phone1.png)",
    // backgroundImage: 'url(https://source.unsplash.com/random)' https://alert-app-v2.s3.amazonaws.com/ALARRT+Exhibition.mp4,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image2: {
    backgroundImage:
      "url(https://alert-app-v2.s3.amazonaws.com/ALARRT+APP+LOADER+V3.svg)",
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "contain",
    backgroundPosition: "cover",
    width: "100vw",
    height: "110vh",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
      backgroundSize: "contain",
    },
  },
  video2: {
    objectFit: "cover",
    width: "100vw",
    height: "100vh",

    // position: 'fixed',
    // top: 0,
    // left: 0,
    // zIndex: -1,
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  function redirect() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
    }
  }

  function redirectToRouute() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
    }
  }

  useEffect(() => {
    redirect();

   
    //  else {
    //    localStorage.removeItem("reloadCount");
    //  }
    // if(window.localStorage.getItem("access_token"))
    // {
    //   // history.push("/")
    //   const pathname = location.pathname;
    //     if(pathname === '/signin'){
    //       // history.push("/");
    //       console.log('pathname is on signin')
    //     }
  }, []);

  const [email, setEmail] = useState("");
  const [loginMessage, setLoginMessage] = useState("Logging You In");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  // const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [emptyEmail, setEmptyEmail] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [blockedError, setBlockedError] = useState(false);
  const [blockedMessage, setBlockedMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const loginTimeout = window.localStorage.getItem("auth_timeout");

  console.log("pathname is " + location.pathname);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEmptyEmail(false);
    setError(false);
    setBlockedError(false);
    // setOpenAlert(false);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  async function validate() {
    if (!email || !password) {
      setEmptyEmail(true);
      return (
        <Alert severity="warning">
          Please type in your email address or password{" "}
        </Alert>
        // alert('Please type in your email address')
      );
    } else {
      signIn();
    }
  }

  async function signIn() {
    setIsLoggingIn(true);
    const formData = new FormData();
    // formData.append("client_id", process.env.REACT_APP_OAUTH_CLIENT_ID);
    // formData.append("client_secret", process.env.REACT_APP_OAUTH_CLIENT_SECRET);
    formData.append("email", email);
    formData.append("password", password);
    // formData.append("grant_type", 'password');

    // axios.post('https://alert-lumen-api.herokuapp.com/oauth/token', formData,
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/${process.env.REACT_APP_LOGIN_URL}`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((resp) => {
        if (resp.data.status == "error") {
          setIsLoggingIn(false);
          setBlockedError(true);
          setBlockedMessage(resp.data.message);
          console.log("you have been blocked");
        } else {
          // console.log(JSON.stringify(resp.data));
          window.localStorage.setItem("access_token", resp.data.access_token);
          window.localStorage.setItem("refresh_token", resp.data.refresh_token);
          // window.localStorage.setItem("expires_in", resp.data.expires_in);
          const token = window.localStorage.getItem("access_token");
          //  console.log(`session stored token ${token}`)
          setIsLoggedIn(true);
          setEmail("");
          setPassword("");

          setOpenAlert(true);
          getProfile();
          // history.push("/");
        }
      })
      .catch((error) => {
        // e.preventDefault()
        setIsLoggingIn(false);
        console.log("helloooo " + error);
        if (error.response.status == 403) {
          setBlockedError(true);
          setBlockedMessage(error.response.data.message);
          console.log("you have been blocked");
        } else {
          setError(true);
          setErrorMessage(error.message);
        }
      });
  }

  function getProfile() {
    setLoginMessage("Almost There ...");
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axios
      .get(`${process.env.REACT_APP_LIVE_URL}/users/profile`, {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        var now = new Date();
        var date = now.getFullYear() + (now.getMonth() + 1) + now.getDate();
        var time = now.getHours() + now.getMinutes();
        //  + now.getSeconds();
        var dateTime = date + time;
        // var date = now.getFullYear()+', '+(now.getMonth()+1)+', '+now.getDate();
        // var time = now.getHours() + ", " + now.getMinutes() + ", " + now.getSeconds();
        // var dateTime = date+', '+time;
        console.log(dateTime);
        window.localStorage.setItem("last_login", dateTime);
        window.localStorage.setItem("user_name", resp.data.user.user_name);
        window.localStorage.setItem("user_id", resp.data.user.id);
        window.localStorage.setItem("type", resp.data.user.is_ers);
        window.localStorage.setItem("au", resp.data.user.is_admin);
        window.localStorage.setItem("first_name", resp.data.user.first_name);
        window.localStorage.setItem("last_name", resp.data.user.last_name);
        window.localStorage.setItem("email", resp.data.user.email);
        window.localStorage.removeItem("auth_timeout");
        window.localStorage.setItem(
          "verified",
          resp.data.user.email_verified_at
        );
        window.localStorage.setItem("profile_pic", resp.data.user.profile_pic);
        window.localStorage.setItem("friends", resp.data.friends);
        // window.localStorage.setItem("bio", JSON.stringify(resp.data.user.bio));
        const userID = window.localStorage.getItem("user_id");
        const userFN = window.localStorage.getItem("first_name");
        const userLN = window.localStorage.getItem("last_name");
        const userUN = window.localStorage.getItem("user_name");
        const userEM = window.localStorage.getItem("user_email");
        const userPic = window.localStorage.getItem("profile_pic");

        // console.log(userID, userUN, userFN, userLN);

        const pathname = location.pathname;
        const newPath = window.localStorage.getItem("newPath");
        if (window.localStorage.getItem("newPath")) {
          const queryParams = window.localStorage.getItem("params");
          // console.log(`hiii ${queryParams}`);
          // localStorage.setItem('params', queryParams);
          // console.log('it has a parameter')
          // const newPath = pathname.slice(7)
          // console.log('new path is '+ newPath)
          // localStorage.setItem('newPath', newPath);
          // console.log('new path stored')

          // history.push('/signin')
          history.push(`${newPath}?${queryParams}`);
          history.go();
        } else {
          history.push("/");
          history.go();
        }
        // if(pathname === '/signin'){
        //   history.push("/");
        //   console.log('pathname is on signin')
        // }

        // else{
        //   console.log('it has a parameter')
        //   const newPath = location.pathname.slice(7)
        //   console.log('new path is '+ newPath)

        //   localStorage.setItem('newPath', newPath);
        //   console.log('new path stored')
        //   history.push(`${newPath}`)
        //   history.go()

        //   localStorage.removeItem('newPath');

        // }
        setIsLoggingIn(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  // useEffect(() => {

  // }, []);

  //  if(!isLoggedIn) {
  //   return(
  //     <Redirect to='/' />
  //   );
  // } else {
  return (
    <>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
        <Grid item xs={12} sm={6} md={6}>
          <br />
          <Typography
            component="h1"
            variant="overline"
            className="text-center"
            style={{ color: "#2516c7", padding: 10 }}
          >
            {" "}
            Welcome! Scroll Down. Please always accept the notification and
            location prompt when asked for a better experience on ALARRT
          </Typography>
          <div>
            <video loop muted autoPlay>
              {/* <source url='https://alert-app-v2.s3.amazonaws.com/ALARRT+Exhibition.mp4' type='video/mp4' /> */}
              <source
                src="/assets/videos/video2.mp4"
                style={{ objectFit: "cover", width: "100vw", height: "50%" }}
                type="video/mp4"
              />
            </video>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
          {/* <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square> */}
          <div className={classes.paper}>
            <img
              src="/assets/images/alarrt-logo.png"
              style={{ width: 250, height: 100 }}
            />
            {/* <Avatar className={classes.avatar}>
              <BoltIcon style={{ fontSize: 25 }} />
            </Avatar> */}
            <br />
            <Typography component="h1" variant="h5">
              Welcome | Sign In
            </Typography>
            <br />
            {/* <form className={classes.form}> */}
            <form className={classes.form} noValidate>
              <div className="form-group icon-input mb-1 ">
                <i
                  className="font-sm feather-mail text-grey-500 pe-8"
                  // style={{paddingLeft: 50}}
                ></i>
                {/* <span style ={{paddingRight:40}}></span> */}
                <input
                  style={{ paddingLeft: 40 }}
                  type="email"
                  placeholder="Your Email"
                  className="style2-input form-control text-grey-900 font-xsss ls-3"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <br />
              <div className="form-group icon-input mb-3">
                {passwordShown ? (
                  <i
                    // style={{paddingLeft: 50}}
                    className="font-sm feather-eye-off text-grey-500 pe-5"
                    onClick={togglePassword}
                  ></i>
                ) : (
                  <i
                    className="font-sm feather-eye text-grey-500 pe-3"
                    onClick={togglePassword}
                  ></i>
                )}
                {/* <span style ={{paddingTop:40}}></span> */}
                <input
                  // type="Password"
                  style={{ paddingLeft: 40 }}
                  type={passwordShown ? "text" : "password"}
                  className="style2-input form-control text-grey-900 font-xsss ls-3"
                  placeholder="Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              {/* <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                // id="email"
                label="Email Address"
                // name="email"
                // autoComplete="email"
                value={email}
                autoFocus
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                // name="password"
                label="Password"
                type="password"
                // id="password"
                // autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              {isLoggingIn ? (
                <>
                  <Button
                    // type="submit"
                    fullWidth
                    disabled
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    // onClick={signIn}
                  >
                    {loginMessage}
                  </Button>
                  <br />

                  <Load message="Logging You In..." />
                </>
              ) : (
                <Button
                  // type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  // onClick={signInTest}
                  onClick={validate}
                >
                  Sign In
                </Button>
              )}

              {loginTimeout ? (
                <div>
                  <Alert severity="success">
                    Welcome back! Seems like its been a while since you were
                    here. Kindly login first to proceed
                  </Alert>
                  <br />
                </div>
              ) : (
                ""
              )}

              {emptyEmail ? (
                <div>
                  <Alert severity="warning" onClose={handleClose}>
                    Please type in your email address or password
                  </Alert>
                  <br />
                </div>
              ) : (
                ""
              )}

              {blockedError ? (
                <div>
                  <Alert severity="error" onClose={handleClose}>
                    {" "}
                    Suspension Notice [{blockedMessage}]{" "}
                  </Alert>
                  {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                  <br />
                </div>
              ) : (
                ""
              )}

              {error ? (
                <div>
                  <Alert severity="error" onClose={handleClose}>
                    There was an issue logging you in. Kindly confirm your email
                    or password is correct. You can reset a new password with
                    the forgot password button. [ {errorMessage} ]
                  </Alert>
                  {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                  <br />
                </div>
              ) : (
                ""
              )}
              <Grid container>
                <Grid item xs>
                  <Link href="forgot-password" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up First"}
                  </Link>
                </Grid>
              </Grid>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
        {/* <Grid item xs={12} sm={false} md={false} className={classes.image} /> */}
      </Grid>

      {/* <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Alert Created Successfully!
        </Alert>
      </Snackbar> */}
    </>
  );
  // }
}
