// import "./channel.scss";

import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// import ChannelComp from "../components/ChannelComp";
import Header from "../components/Header";
import axiosFetch from "../Interceptors2";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";

const SingleChannelAdmin = () => {
  const history = useHistory();
  const [subAccountCode, setSubAccountCode] = useState("");
  const [message, setMessage] = useState("");
  const slug = useParams("");
  const [isCreating, setIsCreating] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [channel, setChannel] = useState("");
  const [error, setError] = useState(false);

  async function checkProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.is_admin);
        if (resp.data.user.is_admin !== "true") {
          history.push("/");
          history.go();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getChannel() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/channels/show/${slug.slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setChannel(resp.data.channel);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClose = (event, reason) => {
    setIsSuccess(false);
    setEmptyFields(false);
  };
  function closeError() {
    setError(false);
    console.log("closed");
  }

  async function validate() {
    if (!subAccountCode) {
      setEmptyFields(true);
      return;
    } else {
      approveChannel();
    }
  }

  async function validateDisapproval() {
    if (!message) {
      setEmptyFields(true);
      return;
    } else {
      disApproveChannel();
    }
  }

  async function approveChannel() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("subAccountCode", subAccountCode);

    axiosFetch
      .post(`/channels/approve_channel/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function disApproveChannel() {
    setIsCreating(true);

    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);

    axiosFetch
      .post(`/channels/dis/approve_channel/${channel.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp);
        setIsSuccess(true);
        setIsCreating(false);
        getChannel();
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    checkProfile();
    getChannel();
    // alert(JSON.stringify(slug));
  }, []);

  return (
    <div className="list" style={{ backgroundColor: "#fff" }}>
      {/* <Sidebar /> */}
      <Header />
      <div className="listContainer main-content" style={{ margin: 30 }}>
        {/* <Navbar /> */}
        {/* <div className="datatable"> */}
        <div className="top" style={{ color: "#000" }}>
          <h1>Approve {channel.name}</h1>
        </div>

        <div
          className="datatableTitle"
          style={{ color: "#000", marginBottom: 20 }}
        >
          Is-Approved: {channel.is_approved}
          <br />
          Previous Acc Code : {channel.subaccount_code}
        </div>

        <div className="row">
          <div className="col-lg-12 mb-3">
            <div className="form-group">
              <label className="mont-font text-black fw-600 font-xsss mb-2">
                Add / Update Channel Sub Account Code
              </label>
              {/* value={this.state.first_name} */}
              <input
                type="text"
                value={subAccountCode}
                onChange={(e) => {
                  setSubAccountCode(e.target.value);
                }}
                className="form-control"
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12">
          {isCreating ? (
            <>
              <Button disabled variant="contained" color="primary">
                Approving Channel
              </Button>
              <br />{" "}
              <p style={{ color: "#000" }}>This may take a quick minute.</p>
            </>
          ) : (
            <Button
              onClick={validate}
              // fullWidth
              variant="contained"
              color="primary"
            >
              Approve Channel{" "}
            </Button>
          )}
          <br />
          <br />
          {isCreating ? (
            <>
              <Button disabled variant="contained" color="primary">
                Dis Approving Channel
              </Button>
              <br />{" "}
              <p style={{ color: "#000" }}>This may take a quick minute.</p>
            </>
          ) : (
            <>
            <br/>
              <div className="top" style={{ color: "#000" }}>
                <h3>Dis Approve {channel.name}</h3>
              </div>
              <br />
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="form-group">
                    <label className="mont-font text-black fw-600 font-xsss mb-2">
                      Type the reason for disapproving. This is what is
                      displayed to channel admin.
                    </label>
                    {/* value={this.state.first_name} */}
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <Button
                onClick={validateDisapproval}
                // fullWidth
                variant="contained"
                color="primary"
              >
                Dis Approve Channel{" "}
              </Button>
            </>
          )}
          <br />
          <br />
          {emptyFields ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert onClose={handleClose} severity="warning">
                To dis/approve a channel, you need to ensure it has a sub account
                code / message
              </Alert>
            </>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue creating your channel. Kindly try again [{" "}
                {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          {isSuccess ? (
            <Snackbar
              open={isSuccess}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose} variant="filled" severity="success">
                Channel was dis/approved sucessfully!
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleChannelAdmin;
