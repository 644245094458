import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosFetch from '../Interceptors2';

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Button } from "@material-ui/core";
import Load from "../components/Load";


// class Socialaccount extends Component {
  export default function Socialaccount() {

    const [isFetching, setIsFetching] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [alarrt, setAlarrt] = useState("");
  const [instagram, setInstagram] = useState('');
  const [youtube, setYoutube] = useState('');
  const [socials, setSocials] = useState([]);
  const userName = window.localStorage.getItem("user_name");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

    async function getUserSocials() {
      setIsFetching(true);
      const access_token = await window.localStorage.getItem("access_token");
      const userID = await window.localStorage.getItem("user_id");
      axiosFetch
        // .get("https://alert-lumen-api.herokuapp.com/api/users/showId/" + userID, {
        .get(`/users/socials/get/${userID}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          setSocials(resp.data);
          setIsFetching(false);
          console.log(`profile details ${JSON.stringify(resp.data)}`);
    
        })
        .catch((error) => {
          setIsFetching(false);
          console.log(error);
          setError(true);
          setErrorMessage(error.message);
        });
    }


    async function updateSocials() {
      console.log("started updating profle");
      setIsUpdating(true);
  
      const access_token = await window.localStorage.getItem("access_token");
      const userID = await window.localStorage.getItem("user_id");
      // window.localStorage.setItem("user_name",   user_name);
  
      const formData = new FormData();
      formData.append("facebook", facebook);
      formData.append("twitter", twitter);
      formData.append("instagram", instagram);
      formData.append("youtube", youtube);
      formData.append("whatsapp", whatsapp);
      formData.append("linkedIn", linkedIn);
     
  
      let result = await axiosFetch
        .post(
          `/users/socials/${userID}`,
          formData,
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          if (resp.data.status == "success") {
            
            getUserSocials();
            setIsUpdating(false);
          } else {
            setIsUpdating(false);
            setError(true);
            setErrorMessage(resp.data.message);
          }
        })
        .catch((error) => {
          setIsUpdating(false);
          console.log(error);
          setError(true);
          setErrorMessage(error.message);
        });
      // }
    }

    useEffect(() => {
      updateSocials();
    }, []);

  if(isFetching == true) {
    return (
      <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
          <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
            <h5 className="text-center"> Fetching Your Socials </h5>
          </div>
          <Load />
        </div>
    )
  }
  else {
    return (
      <Fragment>
        <div className="main-wrapper">
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left">
                <div className="middle-wrap">
                  <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                    <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                      <Link to="/settings" className="d-inline-block mt-2">
                        <i className="ti-arrow-left font-sm text-white"></i>
                      </Link>
                      <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                        Your Social Network
                      </h4>
                    </div>
                    <div className="card-body p-lg-5 p-4 w-100 border-0">
                      <form action="#">
                        <div className="row">

                        <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Alarrt
                              </label>
                              <input
                                type="text"
                                disabled
                                name="comment-name"
                                value={`https://app.alarrt.com/profile/${userName}`}
                                // onChange={(e) => setYoutube(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Facebook
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                className="form-control"
                                defaultValue={socials.facebook}
                                onChange={(e) => setFacebook(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Twitter
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                className="form-control"
                                defaultValue={socials.twitter}
                                onChange={(e) => setTwitter(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Linkedin
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                className="form-control"
                                defaultValue={socials.linkedIn}
                                onChange={(e) => setLinkedIn(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Instagram
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                className="form-control"
                                defaultValue={socials.instagram}
                                onChange={(e) => setInstagram(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Whatsapp
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                className="form-control"
                                defaultValue={socials.whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <label className="mont-font fw-600 font-xsss">
                                Youtube
                              </label>
                              <input
                                type="text"
                                name="comment-name"
                                defaultValue={socials.youtube}
                                onChange={(e) => setYoutube(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>

                          {/* <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Skype</label>
                                                            <input type="text" name="comment-name" className="form-control" />
                                                        </div>        
                                                    </div> */}

                          {/* <div className="col-lg-6 mb-3">
                                                        <div className="form-group">
                                                            <label className="mont-font fw-600 font-xsss">Google</label>
                                                            <input type="text" name="comment-name" className="form-control" />
                                                        </div>        
                                                    </div> */}

                          <div className="col-lg-12 mb-0 mt-2">
                            {isUpdating ? 
                            <Button
                            disabled
                            variant="contained"
                            color="primary"
                          >
                            Updating Your Socials
                          </Button> 
                          :
                            <a
                              onClick={updateSocials}
                              className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                            >
                              Update
                            </a>
  }
                            {/* <button onClick={methodDoesNotExist}>Break the world</button> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Socialaccount;
