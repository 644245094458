import React, { useState, useEffect } from "react";
import { AutoComplete } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Select } from "antd";

export default function ChatV2() {
  const { Option } = Select;
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState("");

  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const onChange = (data) => {
    // setValue(data);
    console.log("onSelect", data);
  };

  function getTags() {
    const access_token = window.localStorage.getItem("access_token");
    axios
      .get("https://alert-lumen-api.herokuapp.com/api/tags", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // const result = resp.data;
        // const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts tags: ${JSON.stringify(resp.data)}`);
        setTags(resp.data);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  //   const children = [];
  //   for (let i = 10; i < 36; i++) {
  //     children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
  //   }

  const children = [];
  {
    tags.map((tag) =>
      children.push(<Option key={tag.name}> {tag.name} </Option>)
    );
  }

  useEffect(() => {
    getTags();
  }, []);

  return (
    <>
      <br />
      <div className="lg:w-8/12 bg-white dark:bg-gray-800">
                        <div className="px-5 py-4 flex uk-flex-between">
                          <a href="#" className="flex items-center space-x-3">
                            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
                              <img
                                src="assets/images/avatars/avatar-1.jpg"
                                alt=""
                                className="h-full rounded-full w-full"
                              />
                              <span className="absolute bg-green-500 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                            </div>
                            <div className="flex-1 min-w-0 relative text-gray-500">
                              <h4 className="font-semibold text-black text-lg">
                                Sindy Forest
                              </h4>

                              <p className="font-semibold leading-3 text-green-500 text-sm">
                                is online
                              </p>
                            </div>
                          </a>

                          <a
                            href="#"
                            className="flex hover:text-red-400 items-center leading-8 space-x-2 text-red-500 font-medium"
                          >
                            <i className="uil-trash-alt"></i>{" "}
                            <span className="lg:block hidden">
                              {" "}
                              Delete Conversation{" "}
                            </span>
                          </a>
                        </div>

                        <div className="border-t dark:border-gray-600">
                          <div className="lg:p-8 p-4 space-y-5">
                            <h3 className="lg:w-60 mx-auto text-sm uk-heading-line uk-text-center lg:pt-2">
                              <span> 28 June, 2018 </span>
                            </h3>

                            {/* <!-- my message--> */}
                            <div className="flex lg:items-center flex-row-reverse">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-2.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-white py-2 px-3 rounded bg-blue-600 relative h-full lg:mr-5 mr-2 lg:ml-20">
                                <p className="leading-6">
                                  consectetuer adipiscing elit, sed diam nonummy
                                  nibh euismod laoreet dolore magna{" "}
                                  <i className="uil-grin-tongue-wink"></i>{" "}
                                </p>
                                <div className="absolute w-3 h-3 top-3 -right-1 bg-blue-600 transform rotate-45"></div>
                              </div>
                            </div>

                            <h3 className="lg:w-60 mx-auto text-sm uk-heading-line uk-text-center lg:pt-2">
                              <span> 28 June, 2018 </span>
                            </h3>

                            <div className="flex lg:items-center">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-1.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-gray-700 py-2 px-3 rounded bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-20 dark:bg-gray-700 dark:text-white">
                                <p className="leading-6">
                                  In ut odio libero vulputate{" "}
                                  <urna className="i uil-heart"></urna>{" "}
                                  <i className="uil-grin-tongue-wink"> </i>{" "}
                                </p>
                                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-700"></div>
                              </div>
                            </div>

                            {/* <!-- my message--> */}
                            <div className="flex lg:items-center flex-row-reverse">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-2.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-white py-2 px-3 rounded bg-blue-600 relative h-full lg:mr-5 mr-2 lg:ml-20">
                                <p className="leading-6">
                                  Nam liber tempor cum soluta nobis eleifend
                                  option{" "}
                                  <i className="uil-grin-tongue-wink-alt"></i>
                                </p>
                                <div className="absolute w-3 h-3 top-3 -right-1 bg-blue-600 transform rotate-45"></div>
                              </div>
                            </div>

                            <h3 className="lg:w-60 mx-auto text-sm uk-heading-line uk-text-center lg:pt-2">
                              <span> 28 June, 2018 </span>
                            </h3>
                            <div className="flex lg:items-center flex-row-reverse">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-2.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-white py-2 px-3 rounded bg-blue-600 relative h-full lg:mr-5 mr-2 lg:ml-20">
                                <p className="leading-6">
                                  consectetuer adipiscing elit, sed diam nonummy
                                  nibh euismod laoreet dolore magna.
                                </p>
                                <div className="absolute w-3 h-3 top-3 -right-1 bg-blue-600 transform rotate-45"></div>
                              </div>
                            </div>

                            <h3 className="lg:w-60 mx-auto text-sm uk-heading-line uk-text-center lg:pt-2">
                              <span> 28 June, 2018 </span>
                            </h3>

                            <div className="flex lg:items-center">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-1.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-gray-700 py-2 px-3 rounded bg-gray-100 relative h-full lg:ml-5 ml-2 lg:mr-20 dark:bg-gray-700 dark:text-white">
                                <p className="leading-6">
                                  Nam liber tempor cum soluta nobis eleifend
                                  option congue nihil imperdiet doming{" "}
                                </p>
                                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-700"></div>
                              </div>
                            </div>

                            {/* <!-- my message--> */}

                            <div className="flex lg:items-center flex-row-reverse">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-2.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-white py-2 px-3 rounded bg-blue-600 relative h-full lg:mr-5 mr-2 lg:ml-20">
                                <p className="leading-6">
                                  quis nostrud exerci tation ullamcorper
                                  suscipit .
                                </p>
                                <div className="absolute w-3 h-3 top-3 -right-1 bg-blue-600 transform rotate-45"></div>
                              </div>
                            </div>

                            <div className="flex lg:items-center">
                              <div className="w-14 h-14 rounded-full relative flex-shrink-0">
                                <img
                                  src="assets/images/avatars/avatar-1.jpg"
                                  alt=""
                                  className="absolute h-full rounded-full w-full"
                                />
                              </div>
                              <div className="text-gray-700 py-2 px-3 rounded bg-gray-100 relative h-full lg:ml-5 ml-2 lg:mr-20 dark:bg-gray-700 dark:text-white">
                                <div className="flex space-x-0.5 my-2 animate-pulse">
                                  <div className="w-2 h-2 rounded-full bg-gray-400"></div>
                                  <div className="w-2 h-2 rounded-full bg-gray-300"></div>
                                  <div className="w-2 h-2 rounded-full bg-gray-300"></div>
                                </div>
                                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-700"></div>
                              </div>
                            </div>
                          </div>

                          <div className="border-t flex p-6 dark:border-gray-700">
                            <textarea
                              cols="1"
                              rows="1"
                              placeholder="Your Message.."
                              className="border-0 flex-1 h-10 min-h-0 resize-none min-w-0 shadow-none dark:bg-transparent"
                            ></textarea>
                            <div className="flex h-full space-x-2">
                              <button
                                type="submit"
                                className="bg-blue-600 font-semibold px-6 py-2 rounded-md text-white"
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
    </>
  );
}
