import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import "./movie-grid.scss";
import MovieCard2 from "../movie-card/MovieCard2";
import Button, { OutlineButton } from "../button/Button";
import Input from "../input/Input";
import axios from "axios";
import tmdbApi, { category, movieType, tvType } from "../../api/tmdbApi";

const MovieGrid = (props) => {
  const [content, setContent] = useState([]);
  // const url = "https://image.tmdb.org/t/p/w300";

  // async function getMovies() {
  //     //    const {data } =
  //     axios
  //       .get(
  //         // `https://api.themoviedb.org/3/movie/popular?api_key=${process.env.REACT_APP_API_KEY}`,
  //         `https://api.themoviedb.org/3/movie/popular?api_key=42f731815804618872f9aeab9511cc98`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             // Authorization: "Bearer " + access_token,
  //           },
  //         }
  //       )
  //       .then((resp) => {
  //         // console.log(resp.data.results);
  //         setContent(resp.data.results);
  //       })
  //       .catch((error) => console.log(`Error Seen : ${error}`));
  //   }

  // useEffect(() => {
  //     getMovies();
  // }, []);

  return (
    <>
      {/* {console.log(`cont ${props.content}`)} */}
      {/* <div className="section mb-3">
                <MovieSearch category={props.category} keyword={keyword}/>
            </div> */}
      <div className="movie-grid">
        {props.content.map((c, id) => (
          <MovieCard2
            movies={c}
            id={id}
            channelId={props.channelId}
            type={props.type}
            url={props.url}
            channelSlug={props.channelSlug}
            isAdmin={props.isAdmin}
            adminId={props.adminId}
            functionProp={props.functionProp}
            functionProp2={props.functionProp2}
            // functionProp={() => props.functionProp()}
          />
        ))}
      </div>
    </>
  );
};

export default MovieGrid;
