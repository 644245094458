import React, { Component, useState, useEffect, useRef } from "react";
import "./comment.css";
import { Button, Paper, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles, Snackbar } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import Alert from "@mui/material/Alert";

import CancelIcon from "@mui/icons-material/Cancel";
import VideoRecorder from "react-video-recorder";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AddLinkIcon from "@mui/icons-material/AddLink";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MicIcon from "@mui/icons-material/Mic";
import Tooltip from "@mui/material/Tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Resizer from "react-image-file-resizer";
import { Camera } from "react-camera-pro";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import { Upload } from "@aws-sdk/lib-storage";

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

export default function CreateComment(props) {
  const classes = useStyles();
  const [isCreating, setIsCreating] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  // const [userPic, setUserPic] = useState("");
  const userPic = window.localStorage.getItem("profile_pic");

  const camera = useRef(null);
  const [creatingPost, setCreatingPost] = useState(false);
  const [creatingMessage, setCreatingMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [audioData, setAudioData] = React.useState("");
  const [audioFile, setAudioFile] = React.useState("");
  const [recordState, setRecordState] = React.useState(null);
  const [audioDetails, setAudioDetails] = useState([]);
  const [alertImage2, setAlertImage2] = useState([]);
  const [alertImageUrls2, setAlertImageUrls2] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  //   const [isSuccess, setIsSuccess] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [image, setImage] = useState("");
  const [images2, setImages2] = useState([]);
  const [showLive, setShowLive] = useState(false);
  const [showUrl, setShowUrl] = useState(false);
  const [mediaUrl, setMediaUrl] = useState('');
  const [showRec, setShowRec] = useState(false);
  const [showPic, setShowPic] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [checked, setChecked] = React.useState(false);
  

  const Input = styled("input")({
    display: "none",
  });
  const StyledInput = styled("div")({
    width: "100%",
    padding: "10px 20px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    fontSize: "0.95rem",
    outline: "none",
    marginTop: "0.2rem",
    marginBottom: "1rem",
    boxSizing: "border-box",

    // &:focus {
    //   border: '1.5px solid #24a19c',
    // }
  });

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      setChecked(true);
    } else {
      setShowLive(false);
      setChecked(false);
    }
    // setChecked(event.target.checked);
  };

  const toogleUrl = (event) => {
    if (showUrl == false) {
      setShowUrl(true);
      // setChecked(true);
    } else {
      setShowUrl(false);
      // setChecked(false);
    }
    // setChecked(event.target.checked);
  };

  async function validateImage(e, value) {
    // setAlertImage([])
    setAlertImage2([...e]);
    alertImage2.push(e);
    for (let i = 0; i < alertImage2[0].length; i++) {
      alertImageUrls2.push(URL.createObjectURL(alertImage2[0][i]));
    }
    setAlertImage2(alertImageUrls2);
    // onchange()
  }

  const resizeFile2 = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images2.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  const onChange32 = async (event) => {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile2(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  function clearImages2() {
    setAlertImage2([]);
    setAlertImageUrls2([]);
    setImages2([]);
  }

  function handleClose2() {
    // setIsLiked(false);
    setIsFavorite(false);
  }

  function handleAudioStop(data) {
    console.log("stop ", JSON.stringify(data));
    console.log("stop blob ", data.blob);
    setAudioDetails(data);
    setAudioData(data.url);
    setAudioFile(data.blob);
  }

  function handleReset() {
    setAudioData("");
    setAudioFile("");
    setAudioDetails("");
  }
  function clearAudio() {
    setAudioData("");
    setAudioFile("");
    setAudioDetails("");
  }

  async function favComment(id) {
    setIsFavoriting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    axiosFetch
      .post(`/comment/fav/${id}/${props.favUrl}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsFavoriting(false);
        setIsFavorite(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function uploadVideo() {
    setIsCreating(true);
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "comments/videos/" + user_id + "/" + uuidv4();
      // video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/305/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two (0% completed)"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% . Please be patient`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            postComment();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      postComment();
      console.log("video very empty");
    }
  }

  async function postComment() {
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);
    formData.append("status", "sent");

    formData.append("audio", audioFile);
    formData.append("mediaUrl", mediaUrl);

    for (let i = 0; i < images2.length; i++) {
      formData.append("image[]", images2[i]);
      console.log(`hjsimage ${JSON.stringify(images2)}`);
    }
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }



    // formData.append("status", channelStatus);

    axiosFetch
      .post(`/${props.url}/${props.Id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {

        if (resp.data.status == "success") {
          console.log(`hi ${resp.data}`);
          setMessage("");
          props.functionProp();
          props.openComment();
          setIsCreating(false);

          setCreatingMessage("");
          setCreatingPost(false)
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("postVideo");
          setVideo("");
          setShowLive(false);
          setShowRec(false);
          setShowUrl(false);
          setMediaUrl('')
          setAudioFile("");
          setAudioData("");
          setAudioDetails("");
          setImages2([]);
          setAlertImage2([]);
          setAlertImageUrls2([]);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          // setCreatingPost(false);
          //   setCreated(false);
        }
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  useEffect(() => {
    // getProfile();
  }, []);

  return (
    <>
      <div className="border-t pt-4 space-y-4 dark:border-gray-600">
        {props.favComments.map((comment) => (
          <div className="flex">
            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
              <Link to={`/profile/${comment.user.user_name}`}>
                <img
                  src={comment.user.profile_pic}
                  alt=""
                  // className="shadow-sm rounded-circle w45"
                  style={{ marginRight: 10, border: "2px solid #045de9" }}
                  className="absolute h-full rounded-full w-full"
                />
              </Link>
            </div>
            <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-20  dark:bg-gray-800 dark:text-gray-100">
              {/* <p className="leading-6"> */}
              {/* <span style={{ marginRight: 20 }}> */}
              <Link to={`/profile/${comment.user.user_name}`}>
                @{comment.user.user_name}
              </Link>
              <ShowMoreText
                lines={1}
                more="Read more"
                less="Show less"
                // className="leading-6"
                className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                anchorClass="my-anchor-css-class"
                width={300}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {comment.message}
              </ShowMoreText>

              {isFavoriting && currentComment == comment.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {props.authId == props.userId ? (
                    // {props.adminId == props.userId[0] ? (
                    <RemoveCircleOutlineIcon
                      onClick={() => {
                        setCurrentComment(comment.id);
                        favComment(comment.id);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}

              <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
            </div>
          </div>
        ))}
      </div>
      <br />

      <div className="shar">
        <div className="shareWrapper">
          <div className="shareTop">
            {/* <figure className="avatar me-3"> */}
            {isFetching ? (
              <img
                className="shareProfileImg"
                src="/assets/images/user.png"
                alt=""
              />
            ) : (
              <>
                <img
                  src={userPic}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                  style={{ marginRight: 10, border: "2px solid #045de9" }}
                />
                {/* </figure> */}
              </>
            )}

            <TextField
              id="standard2"
              label="Comment..."
              multiline
              className="shareInput"
              // maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* {props.alert} */}
            {isCreating ? (
              <HourglassEmptyRoundedIcon />
            ) : (
              <SendRoundedIcon className="pointer" onClick={uploadVideo} />
            )}
          </div>
          
{props.mutipleMedia == 'true' && 
     <div>
     <h3 className="fw-600 text-grey-900 font-xsss mt-0 mb-0 me-4">
       Include media files and show others what is happening - Optional.
     </h3>
     <br />

     <Stack direction="row" spacing={1}>
    
       {alertImageUrls2.length == 0 ? (
         <Tooltip
           title="Select images from gallery"
           arrow
           placement="bottom"
         >
           <label htmlFor="icon-file-2">
             <Input
               accept="image/*"
               id="icon-file-2"
               multiple
               type="file"
               onChange={(e) => {
                 console.log([...e.target.files]);
                 validateImage(e.target.files);
                 onChange32(e);
               }}
             />
             <IconButton
               color="primary"
               // size="large"
               aria-label="upload picture"
               component="span"
             >
               <ImageSearchIcon color="primary" style={{ fontSize: 24 }} />
             </IconButton>
           </label>
         </Tooltip>
       ) : (
         <IconButton
           disabled
           // color="primary"
           // size="large"
           aria-label="upload picture"
           component="span"
         >
           <ImageSearchIcon
             // color="primary"
             style={{ fontSize: 24 }}
           />
         </IconButton>
       )}

       <Tooltip title="Record a video" arrow placement="bottom">
         <IconButton
           // size="large"
           color="secondary"
           // aria-label="add to shopping cart"
           style={{ fontSize: 24 }}
         >
           <VideoCallIcon
             onClick={() => setShowLive(!showLive)}
             color="secondary"
             style={{ fontSize: 24 }}
           />
         </IconButton>
       </Tooltip>

       <Tooltip title="Upload A Video" arrow placement="bottom">
         <label htmlFor="icon-video-2">
           <Input
             accept="video/*"
             id="icon-video-2"
             // multiple
             type="file"
             onChange={(e) => {
               if (e.target.files[0].size > 300000 * 1028) {
                 setUploadLimit(true);
               } else {
                 setUploadLimit(false);
                 console.log(e.target.files[0]);
                 setVideo(e.target.files[0]);
               }
             }}
           />
           <IconButton
             // size="large"
             color="primary"
             aria-label="upload video"
             component="span"
           >
             <VideoLibraryIcon
               color="primary"
               color="primary"
               style={{ fontSize: 24 }}
             />
           </IconButton>
         </label>
       </Tooltip>

       <Tooltip title="Record A Voice Note" arrow placement="bottom">
         <IconButton
           // size="large"
           color="secondary"
           aria-label="record audio"
           style={{ fontSize: 24 }}
         >
           <MicIcon
             onClick={() => setShowRec(!showRec)}
             color="secondary"
             style={{ fontSize: 24 }}
           />
         </IconButton>
       </Tooltip>

       <Tooltip title="Add External URL" arrow placement="bottom">
         <IconButton
           // size="large"
           color="primary"
           // aria-label="add to shopping cart"
           style={{ fontSize: 24 }}
         >
           <AddLinkIcon
             onClick={() => setShowUrl(!showUrl)}
             color="primary"
             style={{ fontSize: 24 }}
           />
         </IconButton>
       </Tooltip>
     </Stack>
     </div>
}

     
          <div>
            {showPic ? (
              <>
                <div
                  style={{
                    width: "70%",
                    height: 300,
                    backgroundColor: "red",
                  }}
                >
                  {/* <Camera ref={camera} /> */}
                  <Camera
                    isFullscreen={false}
                    onTakePhoto={(dataUri) => {
                      setImage(dataUri);
                    }}
                  />
                </div>
                <button onClick={() => setImage(camera.current.takePhoto())}>
                  Take photo
                </button>
                <img src={image} alt="Taken photo" />
              </>
            ) : (
              ""
            )}

            {uploadLimit ? (
              <div>
                <MuiAlert
                  severity="warning"
                  onClose={closeError}
                  action={
                    <>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  Maximum size allowed is 300 mb
                </MuiAlert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
                <a
                  href="https://www.freeconvert.com/video-compressor"
                  target="_blank"
                  className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
                >
                  {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                  <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                    Quickly compress it
                  </span>{" "}
                </a>
              </div>
            ) : (
              ""
            )}

            {!alertImageUrls2.length == 0 ? (
              <>
                <br />
                {/* <Typography gutterBottom variant="subtitle2">
                    Slide to view all
                  </Typography> */}
                <h4> Slide to view all</h4>

                <Carousel>
                  {alertImageUrls2.map((slideImage, index) => (
                    <img
                      src={slideImage}
                      key={index}
                      style={{ zIndex: -10 }}
                      alt=""
                    />
                  ))}
                </Carousel>

                {/* <div data-uk-slider="finite: true center: true autoplay: true">
                    <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
                      {alertImageUrls2.map((imageSrc) => (
                        <li style={{ marginRight: 10 }}>
                          <img src={imageSrc} alt="" />
                        </li>
                      ))}
                    </ul>
                  </div> */}

                <Button
                  // onClick={() => setAlertImageUrls2([])}
                  onClick={clearImages2}
                >
                  Clear Images
                </Button>
                {/* {/* <br /> */}
              </>
            ) : (
              ""
            )}
            <br />
            <div>
              {video.length !== 0 ? (
                <>
                  <br />
                  <Typography gutterBottom variant="subtitle2">
                    Your alarrt's video
                  </Typography>

                  <br />
                  <video width="400" controls>
                    <source src={URL.createObjectURL(video)} />
                  </video>
                  <br />
                  <br />
                  {creatingPost ? (
                    <Button disabled>{creatingMessage}</Button>
                  ) : (
                    <Button
                      onClick={
                        () => {
                          setVideo("");
                          setShowLive(false);
                        }
                        //   this.setState({ profile_pic: "" })
                      }
                    >
                      Remove
                    </Button>
                  )}

                  <br />
                </>
              ) : (
                ""
              )}
            </div>

            {showUrl ? (
              <>
                <TextField
                  id="standard-multiline-flexible-2"
                  label="External Link https://..."
                  multiline
                  className="shareInput"
                  // maxRows={4}
                  value={mediaUrl}
                  onChange={(e) => {setMediaUrl(e.target.value)
                  console.log(mediaUrl)}}
                />
              </>
            ) : (
              <></>
            )}

            <br />
            {showRec ? (
              <>
                <Typography
                  gutterBottom
                  variant="body1"
                  // className={classes.postHead}
                >
                  Record a voice note of what's happening
                </Typography>
                <Recorder
                  record={true}
                  uploadButtonDisabled={true}
                  title={"Record What Is Happening"}
                  audioURL={audioDetails.url}
                  // showUIAudio
                  handleAudioStop={(data) => handleAudioStop(data)}
                  // handleAudioUpload={(data) => handleAudioUpload(data)}
                  // handleCountDown={(data) => handleCountDown(data)}
                  handleReset={() => handleReset()}
                  mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
                />
                <br />
                {/* <br /> */}

                {/* <AudioReactRecorder state={recordState} onStop={onStop} />

                    <button onClick={start}>Start</button>
                    
                    <button onClick={stop}>Stop</button> */}
              </>
            ) : (
              <></>
            )}
            {audioData && (
              <>
                {/* <br/> */}
                <h4> Your Recorded Audio.</h4>
                <audio controls>
                  <source src={audioData} type="audio/mpeg" />
                </audio>
                <br />
                <Button onClick={clearAudio}>Remove</Button>
              </>
            )}
            {showLive ? (
              <>
                <Typography
                  gutterBottom
                  variant="body1"
                  // className={classes.postHead}
                >
                  You can take a live recording using either your front or back
                  camera. If you have a video recorded already, use the upload
                  video button instead. Press "Rec" when ready and wait for the
                  countdown
                </Typography>
                <VideoRecorder
                  isOnInitially={true}
                  countdownTime={1000}
                  onRecordingComplete={(videoBlob) => {
                    // Do something with the video...
                    console.log("videoBlob", videoBlob);
                    setVideo(videoBlob);
                    toogleLiveVid();
                  }}
                />
                <br />
                <br />
              </>
            ) : (
              <></>
            )}
          </div>

          <br />
          {isFavorite ? (
            <Snackbar
              open={isFavorite}
              autoHideDuration={2000}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose2} variant="filled" severity="success">
                Comment removed from favorites successfully!
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}

          <div className="shareBottom">
            <div className="shareOptions"></div>
          </div>
        </div>
      </div>
    </>
  );
}
