import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Bugsnag from "@bugsnag/js";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";

export default function LikersModal(props) {
  const [replyOpen, setReplyOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const [isLiked, setIsLiked] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [message, setMessage] = useState("");
  const [userPic, setUserPic] = useState("");
  const comment = props.comments;

  // Bugsnag.notify(new Error('Test error'))

  function toggleReply() {
    replyOpen == false ? setReplyOpen(true) : setReplyOpen(false);
  }
  function toggleReplyView() {
    showReplies == false ? setShowReplies(true) : setShowReplies(false);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  function handleClose() {
    setOpen(false);
  }

  const handleClose2 = () => {
    // setOpen(false);
    setConfirmDelete(false);
    // setEmptyFields(false);
  };

  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }

  async function deleteAlert(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/${props.url}/${props.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        props.functionProp();
        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function Follow(user_name) {
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
        // getFollowing();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  return (
    <>
      {props.type == "Page" ? (
        <a
          onClick={handleClickOpen}
          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
        >
          VIEW FOLLOWERS
        </a>
      ) : (
        // <span style={{ display: "flex" }}>
        //   <p
        //     style={{ color: props.color, paddingLeft: 3 }}
        //     className={props.class}
        //   >
        //     {props.likers.length} {props.text}
        //   </p>{" "}
        // </span>

        <span onClick={handleClickOpen} style={{ display: "flex" }}>
          <p
            style={{ color: props.color, paddingLeft: 3 }}
            className={props.class}
          >
            view {props.likers.length} {props.text}
          </p>{" "}
        </span>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {" "}
          {props.type} {props.text}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Click to view profile</DialogContentText>

          {props.likers.map((user) => (
            <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
              <div className="flex items-center justify-between py-3">
                <div className="flex flex-1 items-center space-x-4">
                  <a href={`/profile/${user.user_name}`}>
                    <img
                      src={user.profile_pic}
                      className="bg-gray-200 rounded-full w-10 h-10"
                      // onError={fixImage}
                    />
                  </a>
                  <Link to={`/profile/${user.user_name}`}>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold">
                        {" "}
                        {user.first_name} {user.last_name}
                      </span>
                      <span className="block capitalize text-sm">
                        {" "}
                        @{user.user_name}{" "}
                      </span>
                    </div>
                  </Link>

                  <Button
                    href={`/profile/${user.user_name}`}
                    variant="contained"
                    color="primary"
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>{" "}
      {/* //   ))} */}
    </>
  );
}
