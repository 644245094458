import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import {
  Route,
  useParams,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "../components/Load";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Snackbar } from "@material-ui/core";

// class Followers extends Component {
export default function CloseFriends() {
  //   const [userId, setUserId] = useState("");
  //   const authId = useState(window.localStorage.getItem("user_id"));
  const history = useHistory();
  const [unFriendedUser, setUnFriendedUser] = useState(false);
  const [fetchingCloseFriends, setFetchingCloseFriends] = useState(false);
  const [closeFriends, setCloseFriends] = useState([]);
  // const [userName, setUserName] = useState("");
  const userName = window.localStorage.getItem("user_name");
  const firstName = window.localStorage.getItem("first_name");
  const userFriendsCount = window.localStorage.getItem("friends");
  const [adding, setAdding] = useState(false);
  const [addingMessage, setAddingMessage] = useState("Add Friend");
  const [isSearching, setIsSearching] = useState(false);
  const [addedFriend, setAddedFriend] = useState(false);
  const [open, setOpen] = useState(false);
  // const [isLimit, setIsLimit] = useState(false);
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [friendUserName, setFriendUserName] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function getCloseFriends() {
    setFetchingCloseFriends(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/friends`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(`followers 1 ${resp.data}`)
        console.log(JSON.stringify(resp.data));
        setCloseFriends(resp.data);
        setFetchingCloseFriends(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  }

  async function unFriendUser(user_name) {
    setUnFriendedUser(true);
    console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    let result = await axiosFetch
      .post(`/users/unfriend/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // setUnFriendUser(true);
        getProfile();
        getCloseFriends();
        setUnFriendedUser(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function searchUsers(searchText, results) {
    const access_token = window.localStorage.getItem("access_token");
    setIsSearching(true);
    let result = axiosFetch
      .get(`/users/search/${searchText}`, {
        // .get("https://alert-lumen-api.herokuapp.com/api/users", {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setIsSearching(false);
        // setItems(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function redirect() {
    setAddedFriend(false);
    // history.push("/closefriends");
  }

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  const handleOnSearch = (string, results) => {
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };
  const handleOnSelect = (item) => {
    // the item selected
    console.log("selesct" + JSON.stringify(item));
    // setUserId(item.id);
    setUser(item.first_name);
    setLastName(item.last_name);
    setFriendUserName(item.user_name);
  };
  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  function addFriend(user_name) {
    setAdding(true);
    setAddingMessage("Adding Friend");
    // console.log(user_name);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/befriend/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setError(true);
          setErrorMessage(resp.data.message);
          setAdding(false);
          setAddingMessage("Try Another");
          // console.log('you have been blocked')
        } else {
          setAddedFriend(true);
          getProfile();
          setUser("");
          setLastName("");
          getCloseFriends();
          console.log("added friend");
        }
      })
      .catch((error) => {
        setAdding(false);
        // setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }
  function getProfile() {
    setAddingMessage("Almost Done ...");
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        window.localStorage.setItem("friends", resp.data.friends);

        setAdding(false);
        setAddingMessage("Add Another Friend");
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAddedFriend(false);
    setError(false);
  };
  useEffect(() => {
    getCloseFriends();
    // getProfile();
  }, []);

  function fixImage(ev) {
    // ev.target.src = "https://via.placeholder.com/200x200.png";
  }

  if (fetchingCloseFriends == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Your Close Friends"}
                      //   userName={userName}
                      //   url="followers"
                      //   text="View Followers"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <Load />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  }
  if (closeFriends.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Your Close Friends"}
                      //   title={"Friends " + userName + " is Following"}
                      //   userName={userName}
                      //   url="followers"
                      //   text="View Followers"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                      <div className="card-body p-0">
                        {/* {authId[0] == userId ?  */}
                        <h5
                          className="middle-wrap fw-600 text-grey-900"
                          style={{ textAlign: "center", lineHeight: 2 }}
                        >
                          Hello {userName}! You don't have any close friends.
                          Add some now to gift them items on Alarrt. 
                          {/* auto send them alerts you create and  realaert via email and sms */}
                        </h5>

                        <div>
                          <p>
                            {" "}
                            You currently have{" "}
                            <b>{userFriendsCount} out of 5</b> Close Friends{" "}
                            {firstName}.
                          </p>
                        </div>

                        <br />
                        <div
                          style={{ width: 300, marginTop: 10 }}
                          className="d-block w-100 z-index-1"
                        >
                          {isSearching ? (
                            <p className="text-current"> Searching ...</p>
                          ) : (
                            ""
                          )}
                          <ReactSearchAutocomplete
                            items={items}
                            onSearch={searchUsers}
                            // onHover={handleOnHover}
                            onSelect={handleOnSelect}
                            // onFocus={handleOnFocus}
                            // autoFocus
                            placeholder="search users"
                            formatResult={formatResult}
                            fuseOptions={{
                              keys: ["first_name", "user_name", "last_name"],
                            }}
                            resultStringKeyName="user_name"
                          />
                        </div>
                        <br />
                        <br />

                        {user ? (
                          <>
                            {" "}
                            <br />
                            <br />
                            <h4>
                              {" "}
                              You've selected {user} {lastName}{" "}
                            </h4>
                            {adding ? (
                              <Button
                                disabled
                                // style={{width: '50%'}}
                              >
                                {addingMessage}
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => addFriend(friendUserName)}
                                // style={{width: 50}}
                              >
                                {addingMessage}
                              </Button>
                            )}
                            {/* <br/> */}
                          </>
                        ) : (
                          ""
                        )}
                        <br />
                        {addedFriend ? (
                          <>
                            {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                            <Alert
                              onClose={handleClose}
                              variant="filled"
                              severity="success"
                            >
                              You've Successfully Added {friendUserName} To Your
                              Close Friends!
                            </Alert>
                            {/* </Snackbar> */}
                            <br />
                          </>
                        ) : (
                          ""
                        )}
                        <br />
                        {error ? (
                          <div>
                            <Alert severity="warning" onClose={handleClose}>
                              {errorMessage}
                            </Alert>
                            {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                            <br />
                          </div>
                        ) : (
                          ""
                        )}

                        <br />
                        <br />

                       {/* <h5
                      className="middle-wrap fw-600 text-grey-900"
                      style={{ textAlign: "center", lineHeight: 2 }}
                    >
                      Friends That Have Added You As Their Close Friend
                    </h5>
                    <p> 
                      Update This means once they realert or create an alert, you are
                      notified immediately via SMS and email. You can easily
                      remove yourself with the remove button</p> <br/><br/><br/>  */}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 40 }}></div>
                    <div className="text-center">
                      <a
                        href={`/profile/${userName}`}
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                      >
                        BACK TO PROFILE
                        {/* means auth id = the user i params */}
                      </a>
                    </div>
                    <div style={{ marginTop: 80 }}></div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={"Your Close Friends"}
                    // userName={userName}
                    // url="followers"
                    // text="View Followers"
                  />
                  {/* <h1> {user_name} </h1>@@'''¬¬``!!1""" */}

                  <div className="row ps-2 pe-2">
                    <div>
                      <p>
                        {" "}
                       Hi {firstName}! You Have <b>{userFriendsCount} out of 5</b> Close
                        Friends. Add Some Now So You Can Gift Them Items on Alarrt.
                        {/* Send Alerts                        To Them Via Email And SMS */}
                      </p>
                      <br />
                    </div>

                    <br />
                    <div
                      style={{ width: 300, marginTop: 10 }}
                      className="d-block w-100 z-index-1"
                    >
                      {isSearching ? (
                        <p className="text-current"> Searching ...</p>
                      ) : (
                        ""
                      )}
                      <ReactSearchAutocomplete
                        items={items}
                        onSearch={searchUsers}
                        // onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        // autoFocus
                        placeholder="search users"
                        formatResult={formatResult}
                        fuseOptions={{
                          keys: ["first_name", "user_name", "last_name"],
                        }}
                        resultStringKeyName="user_name"
                      />
                    </div>
                    <br />
                    <br />

                    {user ? (
                      <>
                        {" "}
                        <br />
                        <br />
                        <h4>
                          {" "}
                          You've selected {user} {lastName}{" "}
                        </h4>
                        {adding ? (
                          <Button
                            disabled
                            // style={{width: '50%'}}
                          >
                            {addingMessage}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => addFriend(friendUserName)}
                            // style={{width: 50}}
                          >
                            {addingMessage}
                          </Button>
                        )}
                        {/* <br/> */}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    {addedFriend ? (
                      <>
                        {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                        <Alert
                          onClose={handleClose}
                          variant="filled"
                          severity="success"
                        >
                          You've Successfully Added {friendUserName} To Your
                          Close Friends!
                        </Alert>
                        {/* </Snackbar> */}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    {error ? (
                      <div>
                        <Alert severity="warning" onClose={handleClose}>
                          {errorMessage}
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}

                    <br />

                    {closeFriends.map((user, index) => (
                      <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 ">
                              <img
                                onError={fixImage}
                                src={user.profile_pic}
                                alt="avater"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                              />
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">
                              {user.first_name} {user.last_name}
                            </h4>
                            <Link to={`/profile/${user.user_name}`}>
                              <p className="fw-500 text-primary mt-0 mb-3">
                                @{user.user_name}
                              </p>
                            </Link>
                            {!user.phone_number ? (
                              <p>
                                {" "}
                                {user.first_name} {user.last_name} doesn't have a phone number on Alarrt. Alerts will be sent via emails only
                              </p>
                            ) : (
                              ""
                            )}

                            {/* {user.country !== 'nigeria' || user.country !== 'Nigeria' ?
                            <p>
                            {" "}
                            Seems your friend is 
                          </p>
                          :
                          ''
                            } */}

                            {unFriendedUser ? (
                              ""
                            ) : (
                              <a
                                onClick={() => unFriendUser(user.user_name)}
                                className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                              >
                                REMOVE
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}

                    <br />
                    <br />

                   {/* <h5
                      className="middle-wrap fw-600 text-grey-900"
                      style={{ textAlign: "center", lineHeight: 2 }}
                    >
                      Friends That Have Added You As Their Close Friend
                    </h5>
                    <p> 
                     Updated This means once they realert or create an alert, you are
                      notified immediately via SMS and email. You can easily
                      remove yourself with the remove button</p> <br/><br/><br/>  */}
                  </div>
                </div>

                     <div style={{ marginTop: 40 }}></div>
                    <div className="text-center">
                      <a
                        href={`/profile/${userName}`}
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                      >
                        BACK TO PROFILE
                        {/* means auth id = the user i params */}
                      </a>
                    </div>
                    <div style={{ marginTop: 80 }}></div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
  // }
}

// export default Followers;
//
