import React, { Component } from "react";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "./Load";
import Load2 from "./Load2";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Link,
} from "react-router-dom";
import PostComponent from "./PostComponent";
import CreatePost2 from "./CreatePost2";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import CreatePostFab from "./CreatePostFab";
import InfiniteScroll from 'react-infinite-scroll-component';

class PostExplore extends React.Component {
  state = {
    isOpen: false,
    OpenAction: false,
    posts: [],
    fetchingPosts: false,
    error: false,
    errorMessage: "",
    skip: 0,
    take: 30,
    taken: 0,
  };

  handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    console.log('scroll top is ', scrollTop)
    console.log('client height is ',clientHeight)
    console.log('scroll height is ',scrollHeight)

    if (scrollHeight - scrollTop === clientHeight) {
      this.setState({ take: this.state.take + 2 });
      this.getAllPosts();
      // setPage(prev => prev + 1);
    }
  };


  closeError = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  closeError = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  handleClose = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  getAllPostsLoad = async () => {
    this.setState({ fetchingPosts: true });
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/posts/all/skip/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
          this.setState({posts: [...this.state.posts, ...resp.data]});
        // this.setState({ posts: resp.data });
        // setUsers((prev) => [...prev, ...newUsers]);
        // console.log(`posts list ${this.state.posts}`);
        this.setState({ fetchingPosts: false });
        // this.refreshToken()
        // }
      })
      .catch((error) => {
        this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getAllPosts = async () => {
    // this.setState({fetchingPosts: true})
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/posts/all/skip/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // this.setState({posts: [...this.state.posts, ...resp.data]});
        this.setState({ posts: resp.data });
        // this.setState({ posts: resp.data });
        
      })
      .catch((error) => {
        //   this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getAllPostsProps = async () => {
    // this.setState({fetchingPosts: true})
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/posts/all/skip/0/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        this.setState({posts: [...this.state.posts, ...resp.data]});
        // this.setState({ posts: resp.data });
        // this.setState({ posts: resp.data });
        
      })
      .catch((error) => {
        //   this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getNext = () => {
    this.setState({ skip: this.state.skip + 30 });
    this.setState({ taken: this.state.taken + 3 });
    this.getNextPosts();
  }


  getNextPosts = async () => {
    // this.setState({fetchingPosts: true})
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/posts/all/skip/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // this.setState({ posts: resp.data });
        this.setState({posts: [...this.state.posts, ...resp.data]});
      })
      .catch((error) => {
        //   this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  fixImage = async (ev) => {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleAction = () => this.setState({ OpenAction: !this.state.OpenAction });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });

  componentDidMount() {
    this.getAllPostsLoad();
  }

  render() {
    console.log(`posts are ${this.state.posts}`);
    const { user, time, des, avater, postimage, postvideo, id } = this.props;

    const menuClass = `${this.state.isOpen ? " show" : ""}`;
    const actionClass = `${this.state.OpenAction ? " show" : ""}`;
    const emojiClass = `${this.state.isActive ? " active" : ""}`;

    if (this.state.fetchingPosts == true) {
      return (
        <>
          {/* <CreatePost2 type='post' functionProp={() => this.getAllPosts()}/> */}
          <Load2 />
        </>
      );
    } else {
      return (
        <>
          {this.state.error ? (
            <div>
              <br />
              <br />
              <Alert
                severity="error"
                onClose={this.handleClose}
                action={
                  <>
                    <CancelIcon onClick={this.closeError} />
                  </>
                }
              >
                There was an issue fetching all posts. Kindly refresh or check
                your network [ {this.state.errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          <CreatePost2
            type="post"
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />

          {/* <InfiniteScroll
          dataLength={this.state.posts.length} //This is important field to render the next data
          next={this.getNext}
          hasMore={true}
          // loader={<Load message='scroll down to load more' />}
          loader={''}
          // <Load message='scroll down to load more' />
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          > */}
            <PostComponent
              posts={this.state.posts}
              type="post"
              // functionProp={() => this.getAllPostsProps()}
              functionProp={() => this.getAllPosts()}
              functionPropNext={() => this.getNext()}
            />
          {/* </InfiniteScroll> */}


          {/* <CreatePostFab */}
          <CreatePostFab
            type="post"
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />
          <CreatePostFab
            type="post"
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />
        </>
      );
    }
  }
}

export default PostExplore;
