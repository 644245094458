import React, { Component } from "react";
import { Route, useParams, Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Chip from "@material-ui/core/Chip";
import { format, formatDistance, subDays } from "date-fns";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteOutlinedIcon from "@material-ui/icons/FavoriteOutlined";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import Comment from "./comment/CreateComment";
import Load from "./Load";
import Load2 from "./Load2";
import PostComponent from "./PostComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import CreateComment from "./comment/CreateComment";
import SingleComment from "./comment/SingleComment";
import CreatePostFab from "./CreatePostFab";

//
class CategoryPosts extends Component {
  // constructor(props){
  //     super(props);
  //     this.state = {
  //         slug: this.props.slug,
  //     }
  //     console.log(`props ${JSON.stringify(this.props)}`)
  // }
  state = {
    user_name: window.localStorage.getItem("user_name"),
    isOpen: false,
    isComment: false,
    OpenAction: false,
    posts: [],
    postCat: [],
    postTag: [],
    likes: 0,
    isLiked: false,
    fetchingPosts: false,
  };

  executeOnClick(isExpanded) {
    console.log(isExpanded);
  }

  // history = useHistory();
  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  loggedIn = async () => {
    this.setState({ isLoggedIn: true });
    // alert("LoggedIn Successfully");
  };

  getAllPostsLoad = async () => {
    this.setState({ fetchingPosts: true });
    // console.log(` 1 ${this.state.fetchingPosts}`)
    this.setState({ user_name: window.localStorage.getItem("user_name") });
    const access_token = await window.localStorage.getItem("access_token");
    const postSlug = this.props.slug;
    const cat_user_name = this.props.user_name;
    const url = this.props.url;
    axiosFetch
      .get(
        `/${url}/posts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        this.setState({ posts: resp.data.posts });
        this.setState({ postCat: resp.data.postCategory });
        this.setState({ postTag: resp.data.tag });
        this.setState({ fetchingPosts: false });
        // console.log(` 2 ${this.state.fetchingPosts}`)
        //  console.log(resp.data)
        //  console.log(this.state.alerts.length)
        // console.log(`alerts list ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  };

  getAllPosts = async () => {
    // this.setState({ fetchingPosts: true });
    // console.log(` 1 ${this.state.fetchingPosts}`)
    this.setState({ user_name: window.localStorage.getItem("user_name") });
    const access_token = await window.localStorage.getItem("access_token");
    const postSlug = this.props.slug;
    const cat_user_name = this.props.user_name;
    const url = this.props.url;
    axiosFetch
      .get(
        `/${url}/posts`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        this.setState({ posts: resp.data.posts });
        this.setState({ postCat: resp.data.postCategory });
        this.setState({ postTag: resp.data.tag });
        // this.setState({ fetchingPosts: false });
        // console.log(` 2 ${this.state.fetchingPosts}`)
        //  console.log(resp.data)
        //  console.log(this.state.alerts.length)
        // console.log(`alerts list ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  };

  

 

  fixImage = async (ev) => {
    ev.target.src = 'https://alert-app-v1.s3.amazonaws.com/user.png'
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleAction = () => this.setState({ OpenAction: !this.state.OpenAction });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });
  toggleComment = () => this.setState({ isComment: !this.state.isComment });

  componentDidMount() {
    // this.loggedIn();
    this.getAllPostsLoad();
    // this.likeAlert();
  }

  // componentDidUpdate() {
  //     this.getAllPosts();
  //     console.log("component did uodate")
  // }

  render() {
    // console.log(`alerts are ${this.state.alerts}`);
    const { user, time, des, avater, postimage, postvideo, id } = this.props;
    // const {slug} = useParams(['slug']);
    const menuClass = `${this.state.isOpen ? " show" : ""}`;
    const actionClass = `${this.state.OpenAction ? " show" : ""}`;
    const emojiClass = `${this.state.isActive ? " active" : ""}`;
    const commentOpen = this.state.isComment;
    {
      /* <FlipMove> */
    }

    if (this.state.fetchingPosts == true) {
      // console.log(`final ${this.state.fetchingPosts}`)
      return (
        <>
          {/* <h3> No Alert Has Been Recently Created </h3> */}
          <Load2 />
        </>
      );
    }
    if (this.state.posts.length == 0) {
      return (
        <>
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h3
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello {this.state.user_name}! No Post Of This Series Type Has Been
                Use The Plus Button To Create One
              </h3>
            </div>
          </div>
        </>
      );
    }
    // if (this.props.type === 'category') {
    //     return (
    //         <>
    //     <h3 style={{marginLeft:20}}>Hi! We found <b>{this.state.alerts.length}</b> alerts for you in this <b>{this.state.alertCat.name}</b> category </h3>
    //     </>
    //     )
    // }
    else {
      return (
        <>
          {/* <h1>{this.props.type}</h1> */}
          {this.props.type == "category" ? (
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
            <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 items-baseline py-1 px-1 dark:border-gray-800">
              <h2 className="font-semibold text-lg text-center">
                Posts in {this.props.userName}'s Series
              </h2>
              <br />
            </div>
            <h3 className="fw-600 text-grey-900 font-xssss mt-3 mb-3 me-4 text-center">
             Hi! We found <b>{this.state.posts.length}</b> posts for you in
              @{this.props.userName}'s <b>{this.state.postCat.name}</b> Series{" "}
            </h3>
          </div>
           
          ) : (
            <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
            <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 items-baseline py-1 px-1 dark:border-gray-800">
              <h2 className="font-semibold text-lg text-center">
                Posts with {this.state.postTag.name} tag
              </h2>
              <br />
            </div>
            <h3 className="fw-600 text-grey-900 font-xssss mt-3 mb-3 me-4 text-center">
            Hi! We found <b>{this.state.posts.length}</b> posts for you in
              the <b>{this.state.postTag.name}</b> tag{" "}
            </h3>
          </div>
          
          )}
          {console.log(JSON.stringify(this.state.posts))}
          {/* <h2 style={{marginLeft:20}}>Hi! We found <b>{this.state.alerts.length}</b> alerts for you in this <b>{this.state.alertCat.name}</b> category </h2> */}
          <PostComponent posts={this.state.posts} type='post' functionProp={() => this.getAllPosts()}/>

          <CreatePostFab type='post'  pageId=''  channelId='' functionProp={() => this.getAllPosts()} />
          <CreatePostFab type='post'  pageId=''  channelId='' functionProp={() => this.getAllPosts()} />
        </>
      );
    }
  }
}

export default CategoryPosts;
