import React, { Component, Fragment, useState, useEffect } from "react";
import Load from "./Load";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Link, useHistory } from "react-router-dom";
import ImageComponentDiv from "./ImageComponentDiv";
import ImageComponent from "./ImageComponent";
import PageUpdateModal from "../pages/PageUpdateModal";
import LikersModal from "./LikersModal";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReportModal from "./ReportModal";
import Alert from "@mui/material/Alert";

import { Snackbar } from "@material-ui/core";

export default function PageCard(props) {
  const [copied, setCopied] = useState(false);

  function closeSnack() {
    setCopied(false);
  }


  async function unFollowPage() {
    const id = props.pages.page.id;
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/page/unfollow/${id}`, id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
        console.log(resp.data);
        // const data2 = JSON.stringify(resp.data);
        props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function followPage() {
    const id = props.pages.page.id;
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/page/follow/${id}`, id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // const data2 = JSON.stringify(resp.data);
        props.functionProp();
        console.log(resp.data);
        props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  if (props.pages.length == 0) {
    return <Load />;
  } else {
    // render() {
    return (
      <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
        <ImageComponentDiv
          image={props.pages.page.cover_pic}
          class="card-body position-relative h150 bg-image-cover bg-image-center"
        />

        {/* <div className="card-body position-relative h150 bg-image-cover bg-image-center"
                style={{backgroundImage: `url(${props.pages.page.cover_pic})`}}></div>  */}
        <div className="card-body d-block pt-4 text-center">
          <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
            <ImageComponent
              // onError={fixImage}
              image={props.pages.page.page_pic}
              class="p-1 bg-white rounded-xl w-100 h-100"
            />
            {/* <img src={props.pages.page.page_pic} alt="avater" className="p-1 bg-white rounded-xl w-100" /> */}
          </figure>
          <a href = {`/profile/${props.pages.page.admin.user_name}`}>
          <h4 className="font-xs ls-1 fw-700 text-grey-900">
            {props.pages.page.name}{" "}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              Admin: @{props.pages.page.admin.user_name}
            </span>
          </h4>
          </a>
        </div>
        <div
          className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0"
          style={{ display: "flex" }}
        >
          <Link>
            {" "}
            <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
              <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                {props.pages.page.posts_count}{" "}
              </b>{" "}
              Posts
            </h4>{" "}
          </Link>
          <Link>
            {" "}
            <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
              <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                {props.pages.page.subscribers_count}{" "}
              </b>{" "}
              Followers
            </h4>{" "}
          </Link>
        </div>
        {props.isLoggedInUser == true ? (
          <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
            <LikersModal
              likers={props.pages.page.subscribers}
              color="#000"
              text="Followers"
              type="Page"
            />
            {/* <LikersModal likers ={post.post.likers} color='#000' text='likes' type='post' */}
            <PageUpdateModal
              page={props.pages.page}
              adminDetails={props.pages.page.admin}
              functionProp={props.functionProp}
            />
            <Tooltip title="Share Page" aria-label="add">
              <>
                <CopyToClipboard
                  text={`https://alarrt.com/signin${window.location.pathname}`}
                  onCopy={() => setCopied(true)}
                >
                  <a
                    style={{ border: "#eee solid 2px" }}
                    className="btn-round-lg ms-2 d-inline-block rounded-3"
                  >
                    <i className="feather-share-2 text-2xl hover:bg-grey-200 rounded-full p-2 transition -mr-1 dark:hover:bg-grey-700"></i>{" "}
                  </a>
                </CopyToClipboard>
                <br />
                {copied ? (
                  <>
                    <Snackbar
                      open={copied}
                      autoHideDuration={3000}
                      onClose={closeSnack}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Alert onClose={closeSnack} severity="success">
                        Link Copied Successfully
                      </Alert>
                    </Snackbar>
                  </>
                ) : null}
              </>
            </Tooltip>
            {/* <a href="/defaultemailbox" className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"><i className="feather-mail font-md"></i></a> */}
            {/* <a
              disabled
              href="/home"
              className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
            >
              <i className="ti-more font-md"></i>
            </a> */}
          </div>
        ) : (
          <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
            {props.pages.action == "FOLLOW" ? (
              <a
                onClick={followPage}
                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
              >
                FOLLOW
              </a>
            ) : (
              <a
                onClick={unFollowPage}
                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
              >
                UNFOLLOW
              </a>
            )}
            
               <Tooltip title="Share Channel" aria-label="add">
              <>
                <CopyToClipboard
                  text={`https://alarrt.com/signin${window.location.pathname}`}
                  onCopy={() => setCopied(true)}
                >
                  <a
                    style={{ border: "#eee solid 2px" }}
                    className="btn-round-lg ms-2 d-inline-block rounded-3"
                  >
                    <i className="feather-share-2 text-2xl hover:bg-grey-200 rounded-full p-2 transition -mr-1 dark:hover:bg-grey-700"></i>{" "}
                  </a>
                </CopyToClipboard>
                <br />
                {copied ? (
                  <>
                    <Snackbar
                      open={copied}
                      autoHideDuration={3000}
                      onClose={closeSnack}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Alert onClose={closeSnack} severity="success">
                        Link Copied Successfully
                      </Alert>
                    </Snackbar>
                  </>
                ) : null}
              </>
            </Tooltip>
            <a
                  // href="/home"
                  className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                >
                  <ReportModal type="page" url="page" id={props.pages.page.id} />
                </a>
            {/* <a
              disabled
              className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
            >
              <i className="feather-mail font-md"></i>
            </a> */}
            {/* <a
              disabled
              className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
            >
              <i className="ti-more font-md"></i>
            </a> */}
          </div>
        )}
      </div>
    );
  }
}


