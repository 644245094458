import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Load from "../components/Load";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import ImageComponentDiv from "../components/ImageComponentDiv";
import ImageComponent from "../components/ImageComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";

export default function Communities() {
  const [fetchingPages, setFetchingPages] = useState("");
  const [pages, setPages] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  // const [userName, setUserName] = useState("");
  const userName = window.localStorage.getItem("user_name");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  function closeError() {
    setError(false);
    console.log("closed");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setError(false);
  };

  async function unFollowPage(id) {
    // console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    let result = await axiosFetch
      .post(`/community/unfollow/${id}`, userName, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        getAllpages();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAllpagesLoad() {
    setFetchingPages(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/community`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        // if (resp.data.status == "success") {
        setPages(resp.data);
        setFetchingPages(false);
        // } else {
        //   setFetchingPages(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingPages(false);
        console.log(error);
        setError(true);
        setFetchingPages(error.message);
      });
  }
  async function followPage(id) {
    // console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    let result = await axiosFetch
      .post(`/community/follow/${id}`, userName, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        getAllpages();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAllpages() {
    // setFetchingPages(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/community`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        // if (resp.data.status == "success") {
        setPages(resp.data);
        // setFetchingPages(false);
        // } else {
        //   setFetchingPages(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        // setFetchingPages(false);
        console.log(error);
        setError(true);
        setFetchingPages(error.message);
      });
  }
  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    getAllpagesLoad();
  }, []);

  if (fetchingPages == true) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Communites On Alarrt"
                    isPage={"Create A Community"}
                    pageAdmin={adminId}
                    bg="#fff"
                    color="#000"
                    searchUrl="pages/search"
                    // govSearchUrl="pages/search/gov"
                    redirect="page"
                  />
                  <Load />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }

  if (pages.length == 0) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Communities on Alarrt"
                    isPage={"Create A Community"}
                    pageAdmin={adminId}
                    bg="#fff"
                    color="#000"
                    searchUrl="pages/search"
                    // govSearchUrl="pages/search/gov"
                    redirect="page"
                  />

                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ textAlign: "center", lineHeight: 2 }}
                  >
                    Hello no Community page has been created yet. Be the first
                    to do so now!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Communities on Alarrt"
                    isPage={"Create A Community"}
                    pageAdmin={adminId}
                    bg="#fff"
                    color="#000"
                    searchUrl="pages/search"
                    // govSearchUrl="pages/search/gov"
                    redirect="page"
                  />

                  <div className="row ps-2 pe-1">
                    {error ? (
                      <div>
                        <br />
                        <Alert
                          severity="error"
                          onClose={handleClose}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          There was an issue fetching pages. Kindly refresh or
                          check your network [ {errorMessage} ]
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}

                    {pages.map((page, id) => (
                      <>
                        <div className="col-md-6 col-sm-6 pe-2 ps-2">
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
                            {page.page.cover_pic == null ? (
                              <div
                                className="card-body position-relative h100 bg-image-cover bg-image-center"
                                style={{
                                  backgroundImage: `url('https://via.placeholder.com/1200x250.png')`,
                                }}
                                onError={fixImage}
                              ></div>
                            ) : (
                              <ImageComponentDiv
                                onError={fixImage}
                                image={page.page.cover_pic}
                                alt="avater"
                                class="card-body position-relative h100 bg-image-cover bg-image-center"
                              />
                            )}
                            <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                              <figure
                                className="avatar position-absolute w75 z-index-1 left-15"
                                style={{
                                  marginTop: `-40px`,
                                  marginLeft: "-10px",
                                }}
                              >
                                {page.page.page_pic == null ? (
                                  <img
                                    onError={fixImage}
                                    src={
                                      "https://alert-app-v2.s3.amazonaws.com/user.png"
                                    }
                                    alt="avater"
                                    className="float-right p-1 bg-white rounded-circle w-100 "
                                    style={{ objectFit: "contain" }}
                                  />
                                ) : (
                                  <ImageComponent
                                    // onError={fixImage}
                                    image={page.page.page_pic}
                                    alt="avater"
                                    class="float-right p-1 bg-white rounded-circle w-100 "
                                  />
                                )}
                              </figure>

                              <div className="clearfix"></div>

                              <Link
                                to={{
                                  pathname: `/community/${page.page.slug}`,
                                  state: { pageId: page.page.id },
                                }}
                              >
                                <h4 className="fw-700 font-xss mt-3 mb-0">
                                  {page.page.name}{" "}
                                </h4>

                                <h6 className="fw-500 font-xsss mt-3 mb-0">
                                  {page.page.description}{" "}
                                </h6>
                              </Link>
                              <br />
                              <Link
                                to={`/profile/${page.page.admin.user_name}`}
                              >
                                {" "}
                                <p className="fw-500 font-xssss text-grey-500 mt-0 mb-1">
                                  Created By:{" "}
                                  <span className="text-primary-500">
                                    {page.page.admin.user_name}
                                  </span>{" "}
                                </p>{" "}
                              </Link>

                              {page.page.status == "approved" && (
                                <div>
                                  <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                    Status:{" "}
                                    <span className="text-primary-500">
                                      {page.page.status}
                                    </span>
                                  </p>
                                </div>
                              )}
                              {page.page.status == "verified" && (
                                <div>
                                  <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                    Status:{" "}
                                    <span className="text-primary-500">
                                      {page.page.status}
                                    </span>
                                  </p>
                                </div>
                              )}
                              {page.page.status == "created" && (
                                <div>
                                  <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                    Status:{" "}
                                    <span className="text-primary-500">
                                      Recently {page.page.status}
                                    </span>
                                  </p>
                                </div>
                              )}

                              <div
                                style={{ marginTop: 2, marginLeft: "-5px" }}
                                className="d-flex align-items-center"
                                // className="position-absolute right-5 top-10 d-flex align-items-center"
                              >
                                <ul className="d-flex align-items-center justify-content-center mt-1">
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {page.page.subscribers_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Subscribers
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {page.page.alerts_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Alarrts
                                      </span>
                                    </h4>
                                  </li>
                                  <li className="m-2">
                                    <h4 className="fw-600 font-sm">
                                      {page.page.posts_count}
                                      <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                        Posts
                                      </span>
                                    </h4>
                                  </li>
                                 
                                </ul>
                              </div>

                              {/* <br/> */}
                              <div
                                style={{
                                  marginTop: "-15px",
                                  marginLeft: "-10px",
                                }}
                                // className="position-absolute right-15 top-10 d-flex align-items-center"
                              >
                                {page.action == "JOIN" ? (
                                  <a
                                    onClick={() => followPage(page.page.id)}
                                    style={{ alignSelf: "center" }}
                                    className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                                  >
                                    JOIN
                                  </a>
                                ) : (
                                  <a
                                    onClick={() => unFollowPage(page.page.id)}
                                    style={{ alignSelf: "center" }}
                                    className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                                  >
                                    LEAVE
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='mb-3'> </div> */}

                        {/* <div
                          key={channel.channel.id}
                          className="col-md-4 col-sm-6 pe-2 ps-2"
                        ></div> */}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default pages;
