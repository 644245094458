import React, { Component } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import Chip from "@material-ui/core/Chip";
import { format, formatDistance, subDays } from "date-fns";
import { Player } from "video-react";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CreateComment from "./comment/CreateComment";
import SingleComment from "./comment/SingleComment";
import Load from "./Load";
import ShowMoreText from "react-show-more-text";
import LikesProfilePic from "./LikesProfilePic";
import SingleImage from "./SingleImage";
import TwoImages from "./TwoImages";
import ThreeImages from "./ThreeImages";
import FourImages from "./FourImages";
import ShareButtons from "./ShareButtons";

import AlertUpdateModal from "./AlertUpdateModal";
import BoltIcon from "@mui/icons-material/Bolt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import MuiAlert from "@material-ui/lab/Alert";
import LikersModal from "./LikersModal";
import ReportModal from "./ReportModal";

//
export default function AlertSample(props) {
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const userId = useState(window.localStorage.getItem("user_id"));
  const profile_pic = window.localStorage.getItem("profile_pic");
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const authId = useState(window.localStorage.getItem("user_id"));
  const [realerting, setRealerting] = useState(false);
  const [realerted, setRealerted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  function handleClose2() {
    setRealerted(false);
    setSaved(false);
  }

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleAction = () => setOpenAction(!openAction);
  const toggleComment = () => setIsComment(!isComment);

  useEffect(() => {
    // getAlert();
  }, []);

  const menuClass = `${isOpen ? " show" : ""}`;
  const actionClass = `${openAction ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  //   const { isLiked } = this.state;
  const commentOpen = isComment;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }

  return (
    <div
      //   key={props.alert.alert.id}
      className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
    >
        <h2> Alarrt Hint </h2>
      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3" style={{ marginLeft: 0 }}>
          <img
            onError={fixImage}
            src={profile_pic}
            alt="avater"
            className="shadow-sm me-3 rounded-circle w45"
          />
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
          {" "}
          <span style={{ paddingRight: 2 }}>
            Alarrted From : Alarrt's Creator Name Comes Here
          </span>
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
            {" "}
            @ alarrt creator's user_name
          </span>{" "}
        </h4>
      </div>

      <div className="card-body p-0 d-flex">
        <figure className="avatar me-3" style={{ marginLeft: 0 }}>
          <img
            onError={fixImage}
            src={profile_pic}
            alt="avater"
            className="shadow-sm me-3 rounded-circle w45"
          />
        </figure>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
          {" "}
          Name of User That Realarrts This{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
            {" "}
            @ user's user_name comes here
          </span>{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Time Created (...mins ago)
            {/* {alert.created_at} */}
          </span>
        </h4>
        {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
        <div className={`ms-auto pointer`}>
          <>
            <div>
              <a>
                {" "}
                <i
                  style={{ fontSize: 25 }}
                  className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                ></i>{" "}
              </a>
              <div
                className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                data-uk-drop="mode: click;pos: bottom-right"
              >
                <ul className="space-y-1">
                  <li>
                    <a
                      disabled
                      className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    >
                      <i className="uil-share-alt mr-1"></i> Edit
                    </a>
                  </li>
                  <li>
                    <a
                      disabled
                      className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    >
                      <i className="uil-share-alt mr-1"></i> Re-alarrt
                    </a>
                  </li>

                  <li>
                    <a
                      disabled
                      className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    >
                      <i className="uil-favorite mr-1"></i> Save Alarrt
                    </a>
                  </li>

                  <li>
                    <hr className="-mx-2 my-2 dark:border-gray-800" />
                    <a
                      disabled
                      className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                    >
                      <i className="uil-trash-alt mr-1"></i> Report
                    </a>
                  </li>

                  <li>
                    <hr className="-mx-2 my-2 dark:border-gray-800" />
                  </li>
                  <li>
                    <a
                      disabled
                      className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                    >
                      <i className="uil-trash-alt mr-1"></i> Delete
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        </div>
      </div>
      <h3 className="fw-700 text-grey-900 pointer">
        {" "}
        Your Alarrt Headline. This is the summary of your emergency or public
        info (It is required)
      </h3>
      <br />
   

      {/* <div className="card-body d-block p-0 mb-3">
        <br />
        <p>Your Alert Images -> Maximum image is 4 (Optional)</p>
        <FourImages
          images={profile_pic}
          text="Alert Headline Sample Pictures"
        />
      </div> */}

      <div className="card-body p-0 me-lg-5">
        <ShowMoreText
          /* Default options */
          lines={1}
          more="Read more"
          less="Show less"
          className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
          // className="content-css"
          anchorClass="my-anchor-css-class"
          // onClick={executeOnClick}
          expanded={false}
          // width={480}
          truncatedEndingComponent={"... "}
        >
          Your alarrt details is displayed here. It should be straight forward and
          detailed. This is required
        </ShowMoreText>
        {/* <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{alert.body} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p> */}{" "}
        <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
          {" "}
          Category:{" "}
          <Chip
            className="pointer infotag"
            color="primary"
            size="small"
            label="Category (required)"
          />{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
            {" "}
          </span>{" "}
        </h6>{" "}
        <>
          <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
            {" "}
            Location:{" "}
            <Chip
              className="pointer infotag"
              color="primary"
              size="small"
              label="e.g Ikeja, Ikorodu, Ogun State"
            />{" "}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
              {" "}
            </span>{" "}
          </h6>{" "}

                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Alarrt Type:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label='Distress / Info Alert'
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
                
                 <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Status:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label='sent_out/on_route/resolved/verified'
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
            
        </>
        <Link>
          {/* <Link to={`/alert.alert/${alert.alert.slug}`} state={{ id: alert.alert.id }}> */}
          <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
            {" "}
            URL: www.alertexternalsourcerefrenece.com
          </h6>
        </Link>
        <>
          <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">Info Tags: (For more info and ease in searching by tags) </h6>
          <div>
            <Chip
              style={{ marginRight: 10, marginBottom: 5 }}
              className="infotag pointer"
              variant="outlined"
              color="primary"
              size="small"
              label={`# location`}
            />
            <Chip
              style={{ marginRight: 10, marginBottom: 5 }}
              className="infotag pointer"
              variant="outlined"
              color="primary"
              size="small"
              label={`# don't call`}
            />
            <Chip
              style={{ marginRight: 10, marginBottom: 5 }}
              className="infotag pointer"
              variant="outlined"
              color="primary"
              size="small"
              label={`# info tags`}
            />
          </div>
        </>
      
      </div>
    </div>
  );
}
