import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { AutoComplete } from "antd";
import MuiAlert from "@material-ui/lab/Alert";
import CreatePost2 from "./CreatePost2";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getCircularProgressUtilityClass } from "@mui/material";
import { Typography } from "@material-ui/core";

export default function AddCasts(props) {
  const [emptyFields, setEmptyFields] = useState(false);
  const history = useHistory();
  const [currentCast, setCurrentCast] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [castRole, setCastRole] = useState("");
  const [casts, setCasts] = useState([]);
  const [role, setRole] = useState("");
  const animatedComponents = makeAnimated();
  const [open, setOpen] = React.useState(false);
  const { Option } = AutoComplete;
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);

  const roleOptions = [
    { value: "actor", label: "Actor" },
    { value: "actress", label: "Actress" },
    { value: "director", label: "Director" },
  ];

  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
  };

  function selectRole(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    setRole(value.value);
    setCastRole(value.value);
  }
  function selectRole2(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    value.map((role) => role.push(role.value));
    // value.map((castRole) => role.push(role.value));
    // console.log(`name ${role}`);
  }
  // function ChangeTag(value) {
  //   console.log(`selected ${value}`);
  //   value.map((tag) => tags.push(tag.value));
  //   console.log(`name ${tags}`);
  // }

  const handleOnSearch = (string, results) => {
    // searchUsers(string)
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };
  const handleOnSelect = (item) => {
    // the item selected
    console.log("selesct" + JSON.stringify(item));
    setUserId(item.id);
    setUser(item.first_name);
    setLastName(item.last_name);
    // history.push(`/profile/${item.user_name}`);
    // history.go(`/profile/${item.user_name}`);
  };
  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  async function validate() {
    if (!userId) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      addCast();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  function searchUsers(searchText, results) {
    const access_token = window.localStorage.getItem("access_token");
    setIsSearching(true);
    let result = axiosFetch
      .get(`/users/search/${searchText}`, {
        // .get("https://alert-lumen-api.herokuapp.com/api/users", {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setIsSearching(false);
        // setItems(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const onSelect = (data) => {
    console.log("onSelect", data);
    // history.push(`/profile/${data}`);
    // history.go(`/profile/${data}`);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  function getCasts() {
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .get(`/${props.getUrl}/${props.movieId}`, {
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setCasts(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function addCast() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("channel_id", props.channelId);
    formData.append("user_id", userId);
    formData.append("movie_id", props.movieId);
    formData.append("role", role);
    formData.append("type", props.type);

    axiosFetch
      .post(`/${props.addUrl}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(`hi ${resp.data}`);

        setIsCreating(false);
        // props.functionProp();
        getCasts();
        setUserId("");
        setUser("");
        setLastName("");
        setCastRole("");
        setRole("");
        setItems([]);
        // setOpen(false);
        // props.functionProp();
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function removeCast(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .delete(
        `/${props.removeUrl}/${props.movieId}/remove/${id}`,
        // id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // props.functionProp();
        getCasts();
        setIsDeleting(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getCasts();
    // searchUsers()
  }, []);

  return (
    <>
      <Tooltip
        className="d-inline-block"
        title={`Add casts to your ${props.movieType}`}
        onClick={handleClickOpen}
      >
        <IconButton>
          <GroupAddOutlined
            //  className="btn-outline"
            className="d-inline-block"
            onClick={() => console.log("video")}
            style={{
              color: props.color,
              fontSize: 30,
              marginLeft: 5,
              marginRight: 5,
            }}
          />{" "}
        </IconButton>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            Add people who contributed to this {props.movieType}{" "}
            
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              You've added {casts.length} casts to your {props.movieType}{" "}
            </DialogContentText>
            <br />{" "}
            <Typography gutterBottom variant="subtitle2">
              Search for user to start. start with the most important roles
            </Typography>
            {/* <p>Search for user to start. start with the most important roles</p> */}
            {/* {console.log(JSON.stringify(casts))} */}
            {casts.length == 0 ? (
              ""
            ) : (
              <>
                {casts.map((cast) => (
                  <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
                    <div className="flex items-center justify-between py-3">
                      <div className="flex flex-1 items-center space-x-4">
                        <a href={`/profile/${cast.user.user_name}`}>
                          <img
                            src={cast.user.profile_pic}
                            className="bg-gray-200 rounded-full w-10 h-10"
                            // onError={fixImage}
                          />
                        </a>
                        <Link to={`/profile/${cast.user.user_name}`}>
                          <div className="flex flex-col">
                            <span className="block capitalize font-semibold">
                              {" "}
                              {cast.user.first_name} {cast.user.last_name}
                            </span>
                            <span className="block capitalize text-sm">
                              {" "}
                              @{cast.user.user_name} - {cast.role}
                            </span>
                          </div>
                        </Link>
                      </div>

                      <a
                        onClick={() => removeCast(cast.user.id)}
                        // onClick={toogleFollow(user.user_name)}
                        className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
                      >
                        {" "}
                        REMOVE{" "}
                      </a>
                    </div>
                  </div>
                ))}
              </>
            )}
            {isDeleting ? (
              <>
                <br />
                <Typography gutterBottom variant="subtitle2">
                  removing cast ...
                </Typography>

                <br />
              </>
            ) : (
              ""
            )}
            <br />
            <br />
            <Typography gutterBottom variant="subtitle2">
              Type here to start searching
            </Typography>
            <div style={{ width: 300, marginTop: 10 }}>
              {isSearching ? (
                <p className="text-current"> Searching ...</p>
              ) : (
                ""
              )}
              <ReactSearchAutocomplete
                items={items}
                onSearch={searchUsers}
                // onHover={handleOnHover}
                onSelect={handleOnSelect}
                // onFocus={handleOnFocus}
                autoFocus
                placeholder="Search users"
                formatResult={formatResult}
                fuseOptions={{ keys: ["first_name", "user_name", "last_name"] }}
                resultStringKeyName="user_name"
              />
            </div>
            {user ? (
              <>
                <br />
                <Typography gutterBottom variant="subtitle2">
                  You've selected {user} {lastName}{" "}
                </Typography>
              </>
            ) : (
              <>
                <br />
                <Typography gutterBottom variant="subtitle2">
                  Select a user ...
                </Typography>
              </>
            )}
            {/* <h4>
              {" "}
              You've selected {user} {lastName}{" "}
            </h4> */}
            <br />
            <br />
            <Typography gutterBottom variant="subtitle2">
              Select user's role or click create in dropdown to create new
            </Typography>
            {/* <p> Select user's role or create new </p> */}
            <CreatableSelect
              isClearable
              // isMulti
              onChange={selectRole}
              options={roleOptions}
              components={animatedComponents}
              // options={options}
            ></CreatableSelect>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {isCreating ? (
              <Button disabled>Adding</Button>
            ) : (
              <Button onClick={validate}>Add To Cast</Button>
              // <Button onClick={console.log(`hi ${movieTitle}`)}>addCast</Button>
            )}
            {/* <Button onClick={validate}>Create</Button> */}
          </DialogActions>
          {emptyFields ? (
            <MuiAlert onClose={handleClose2} severity="warning">
              Add A User first
            </MuiAlert>
          ) : (
            ""
          )}
        </Dialog>{" "}
      </Box>
    </>
  );
}
