import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

export default function SearchBarV2(props) {
  const [open, setOpen] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const [searchMessage, setSearchMessage] = useState([]);
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);
  const history = useHistory();

  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };

  const handleOnHover = (result) => {
    // the item hovered
    console.log("hover res" + result);
  };

  const handleOnSelect = (item) => {
    // the item selected
    if (props.redirect == "user") {
      console.log("selesct" + JSON.stringify(item));
      history.push(`/profile/${item.user_name}`);
      history.go();
    }

    if (props.redirect == "channel") {
      console.log("selesct" + JSON.stringify(item));
      history.push(`/channel/${item.slug}`);
      history.go();
    }
    if (props.redirect == "page") {
      console.log("selesct" + JSON.stringify(item));
      history.push(`/page/${item.slug}`);
      history.go();
    }
    if (props.redirect == "alert") {
      console.log("selesct" + JSON.stringify(item));

      history.push({
        pathname: `/alert/${item.slug}?i=${item.id}`,
        // pathname: `/alert/${item.slug}`,
        // state: {
        //   id: item.id,
        // },
      });
      // history.push(`/channel/movie/${item.slug}`);
      history.go();
    }
    if (props.redirect == "movie") {
      console.log("selesct" + JSON.stringify(item));

      if (item.type == "episode") {
        const url = `channel/${item.channel.slug}/series/episode`;
        history.push({
          pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
          // pathname: `/channel/${item.channel.slug}/movie/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
        });
        history.go();
      }
      if (item.type == "series") {
        const url = `channel/${item.channel.slug}/series/episode`;
        history.push({
          pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
          // pathname: `/channel/${item.channel.slug}/movie/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
        });
        history.go();
        // const url = `channel/${item.channel.slug}/series`
        // history.push({
        //   pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
        // });
        // history.go();
      }
      if (item.type == "movie" || item.type == "video") {
        history.push({
          pathname: `/channel/${item.channel.slug}/movie/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`,
        });
        history.go();
      }
    }
    if (props.redirect == "series") {
      console.log("selesct" + JSON.stringify(item));
      history.push({
        pathname: `/channel/series/${item.slug}`,
        state: {
          channelSlug: item.channel.slug,
          id: item.id,
          adminId: item.channel.admin.id,
        },
      });
      // history.push(`/channel/series/${item.slug}`);
      history.go();
    }

    if (props.redirect == "episode") {
      console.log("selesct" + JSON.stringify(item));
      history.push({
        pathname: `/channel/series/episode/${item.slug}`,
        state: {
          channelSlug: item.channel.slug,
          id: item.id,
          adminId: item.channel.admin.id,
        },
      });
      // history.push(`/channel/series/episode/${item.slug}`);
      history.go();
    }
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  function searchUsers(searchText, results) {
    const access_token = window.localStorage.getItem("access_token");
    setSearching(true);
    setSearchMessage('continue')
    let result = axiosFetch
      .get(
        `/${props.searchUrl}/${searchText}`,
        {
          // .get("https://alert-lumen-api.herokuapp.com/api/users", {
          headers: {
            Accept: "*/*",
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setSearchMessage('continue typing')
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setSearching(false);
        
        // setItems(resp.data);
      })
      .catch((error) => {
        console.log(`Error Seen : ${error}`)
      setSearching(false)});
  }

  useEffect(() => {}, []);

  return (
    <div>
      <br />
      <br />

      {props.redirect == "user" ? (
        <div style={{ width: 200, marginTop: -30, marginLeft: 10 }}>
         
          {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
            {/* <br /> */}
            </>
          ) : (
            ""
          )}
            
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            placeholder="search users"
            styling={{ searchIconMargin: "0 -10px 0 20px" }}
            formatResult={formatResult}
            fuseOptions={{ keys: ["first_name", "user_name", "last_name"] }}
            resultStringKeyName="user_name"
          />

         
        </div>
      ) : (
        ""
      )}

      {props.redirect == "alert" ? (
        <div
          style={{ width: 200, marginTop: -30 }}
          className="pt-0 pb-0 ms-auto"
        >
         {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
            {/* <br /> */}
            </>
          ) : (
            ""
          )}
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            styling={{ display: "flex", alignSelf: "flex-start" }}
            placeholder="search for alarrt"
            formatResult={formatResult}
            fuseOptions={{ keys: ["headline", "body", 'location', "tags"] }}
            resultStringKeyName="headline"
          />

        </div>
      ) : (
        ""
      )}

      {props.redirect == "channel" ? (
        <div
          style={{ width: 200, marginTop: -30 }}
          className="pt-0 pb-0 ms-auto"
        >
         {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
            {/* <br /> */}
            </>
          ) : (
            ""
          )}
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            styling={{ display: "flex", alignSelf: "flex-end" }}
            placeholder="search channel"
            formatResult={formatResult}
            fuseOptions={{ keys: ["name", "description"] }}
            resultStringKeyName="name"
          />

        </div>
      ) : (
        ""
      )}

      {props.redirect == "page" ? (
        <div
          style={{ width: 200, marginTop: -30 }}
          className="pt-0 pb-0 ms-auto"
        >
         {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
            {/* <br /> */}
            </>
          ) : (
            ""
          )}
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            styling={{ display: "flex", alignSelf: "flex-end" }}
            placeholder="search business"
            formatResult={formatResult}
            fuseOptions={{ keys: ["name", "description"] }}
            resultStringKeyName="name"
          />
        
        </div>
      ) : (
        ""
      )}

      {props.govSearchUrl ? (
        <div
          style={{ width: 200, marginTop: 20 }}
          className="pt-0 pb-0 ms-auto"
        >
         {/* {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
          
            </>
          ) : (
            ""
          )} */}
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            styling={{ display: "flex", alignSelf: "flex-end" }}
            placeholder="gov pages"
            formatResult={formatResult}
            fuseOptions={{ keys: ["name", "description"] }}
            resultStringKeyName="name"
          />
         
        </div>
      ) : (
        ""
      )}

      {props.redirect == "movie" ||
      props.redirect == "series" ||
      props.redirect == "episode" ? (
        <div
          style={{ width: 200, marginTop: -30 }}
          className="pt-0 pb-0 ms-auto"
        >
        {searching == true ? (
            <>
            <p className="text-primary text-center">Searching ... {searchMessage}</p>
            {/* <br /> */}
            </>
          ) : (
            ""
          )}
          <ReactSearchAutocomplete
            items={items}
            onSearch={searchUsers}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            // autoFocus
            placeholder="search movies"
            formatResult={formatResult}
            fuseOptions={{ keys: ["title", "overview"] }}
            resultStringKeyName="title"
          />
         
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
