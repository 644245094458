import React, { Component, Fragment, useState, useEffect } from "react";
import "./ad.css";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import Load from "../../components/Load";
import { useHistory, Link } from "react-router-dom";
import AlertSample from "../AlertSample";

export default function Ad() {
  const [notices, setNotices] = useState([]);
  const [fetching, setFetching] = useState(false);
  const firstName = window.localStorage.getItem("first_name")

  async function getFollowing() {
    setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/notices", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setNotices(resp.data);
        setFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getFollowing();
    // getTags();
    // getAlertCategories();
  }, []);

  if (fetching == true) {
    return <Load />;
  }
  if (notices.length == 0) {
    return  <AlertSample />;
  } else {
    return (
      <>
        <div className="rightbar">
          <div className="rightbarWrapper">
            <div className="birthdayContainer">
              <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
                <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 items-baseline py-1 px-1 dark:border-gray-800">
                  <h2 className="font-semibold text-lg text-center">
                    <b>Welcome {firstName}! Glad to see you are at ALARRT</b>
                  </h2>
                  <br />
                </div>
                {notices.map((notice) => (
                  <div
                    style={{
                      paddingRight: 10,
                      paddingLeft: 20,
                      marginBottom: 20,
                    }}
                  >
                    {/* <img className="birthdayImg" src="assets/images/ad3.jpg" alt="" /> */}
                    <span className="birthdayText">
                      <b>{notice.title}</b>
                    </span>
                    <img className="rightbarAd" src={notice.image} alt="" />
                    <br />
                    {notice.url ? (
                      <>
                        <Link
                          // to={notice.url}
                          style={{ marginBottom: 10 }}
                          className="bg-primary font-bold hover:bg-primary hover:text-white inline-block items-center lg:block mr-4 px-4 py-2 rounded shado text-white"
                        >
                          <a href={notice.url} targert = '_blank'>
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            {/* fredoka-font */}
                            {notice.urlText}
                          </span>{" "}
                          </a>
                        </Link>
                      </>
                    ) : (
                      ""
                    )}
                    <hr/>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <br/>
        
        <AlertSample />
        
      </>
    );
  }
}
