import React from "react";
import { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./widgets.css";
import SearchIcon from "@material-ui/icons/Search";
import {
  // Link,
  Avatar,
  Container,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import axios from "axios";
import axiosFetch from '../../Interceptors2';

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(2),
    color: "#555",
    fontSize: 16,
  },
}));

function Widgets() {
  const classes = useStyles();
  const [alertCategory, setAlertCategory] = useState([]);

  async function getAlertCategories() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAlertCategory(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    //  createAlert();
    getAlertCategories();
  }, []);

  return (
    <div className="widgets">
      <div className="widgets__input">
        <SearchIcon className="widgets__searchIcon" />
        <input
          placeholder="Search Alert Categories"
          type="text"
          className="widgetInput"
        />
      </div>

      <div className="widgets__widgetContainer">
        <h2>See What's Happening In Popular Categories</h2>
        <ul className="rightbarFriendList">
          {/* <Typography className={classes.title} gutterBottom>
            Popular Categories
          </Typography> */}
          <Divider flexItem style={{ marginBottom: 10 }} />
          {alertCategory.map((value, index) => (
            <div key={index}>
              <li className="rightbarFriendListItem">
                <Link to={`/alert-category/${value.slug}`} className={classes.link} variant="body2">
                  {value.name}
                </Link>
              </li>
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Widgets;
