import React from "react";
import { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import Header from "../Header";
import Appfooter from "../Appfooter";
import Load from "../Load";

import { Link, NavLink } from "react-router-dom";
import "./widgets.css";
import SearchIcon from "@material-ui/icons/Search";
import {
  // Link,
  Avatar,
  Container,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import SearchBarV2 from "../SearchBarV2";
import FilterByLocation from "./FilterByLocation";



export default function AlertCategoriesSlide() {
  //   const classes = useStyles();
  const [alertCategory, setAlertCategory] = useState([]);

  const membersettings = {
    arrows: true,
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    // slidesToShow: 1,
    centerMode: false,
    padding: '10px',
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    // variableWidth: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category/active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        console.log(`Alerts Categories: ${allAlertCategories}`);
        setAlertCategory(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    //  createAlert();
    getAlertCategories();
  }, []);

  if (alertCategory.length == 0) {
    return (
      <>
        <Header />
        <br/><br/><br/>
        <Load />
        <Appfooter />
      </>
    );
  } else {
    return (
      <>
        <Header />
        {/* feed-body */}
        <div className="main-content">
          {/* <div className="middle-sidebar-bottom">  */}
          {/* <div className="middle-sidebar-left">  */}
          <div className="row ">
            {/* <div className="col-xl-8 col-xxl-9 col-lg-8">  */}

            <div style={{ marginLeft: 30, paddingLeft: 50 }}>
              <br /> <br /> <br />
              <h5> Filter Alarrts By Location (e.g Ikeja, Oyo) </h5>
              <FilterByLocation />
              <br />
              
              <h5> Slide To View More Categories </h5>
              <Slider {...membersettings}>
                {alertCategory.map((cat, index) => (
                  <div
                    key={index}
                    className="card w200 d-block border-0 shadow-xss rounded-xxl overflow-hidden mb-3 me-3"
                    style={{ marginRight: 10, paddingRight: 10 }}
                  >
                    <div
                      className="card-body position-relative h100 bg-image-cover bg-image-center"
                      style={{
                        backgroundImage: `url("/assets/images/bg-blue.webp")`,
                      }}
                    ></div>
                    <div className="card-body d-block w-100 ps-4 pe-4 pb-4 text-center">
                      <figure className="avatar overflow-hidden ms-auto me-auto mb-0 mt--6 position-relative w75 z-index-1">
                        <img
                          src={`/assets/images/alarrt-fav.svg`}
                          alt="avater"
                          className="float-right p-1 bg-white rounded-circle w-100"
                        />
                      </figure>
                      <div className="clearfix"></div>
                      <h4 className="fw-700 font-xsss mt-2 mb-1">
                        {cat.name}{" "}
                      </h4>
                      <br />
                      <p className="fw-500 font-xsssss text-grey-500 mt-0 mb-2">
                        {cat.active_alerts_count} Active Alarrts
                      </p>
                      <a href={`/alert-category/${cat.slug}`} >
                      <span className="live-tag mt-2 mb-0 pointer bg-blue-gradiant p-2 z-index-1 rounded-3 text-white font-xsssss text-uppersace fw-700 ls-3"
                      >
                        VIEW ALARRTS
                      </span>
                      </a>
                      <div className="clearfix mb-2"></div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            {/* </div> 
           </div> 
           </div>  */}
          </div>
        </div>
        <br /> <br />
        <Appfooter />
      </>
    );
  }
}

// export default AlertCategoriesSlide;
