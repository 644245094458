import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
import Load2 from "./Load2";
import PostComponent from "./PostComponent";


export default function UserPosts(props) {

  const [fetchingPosts, setFetchingPosts] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const loggedInId = useState(window.localStorage.getItem("user_id"));
  const { user_name } = useParams(["user_name"]);
  const posts  = props.posts;
  const userId = props.user_id;
 
  
  function checkUserId () {
 
    if (loggedInId[0] == userId) {
      console.log("is logged in user posts");
      // console.log(`no 1 ${loggedInId}`);
      // console.log(`no 2 ${userId}`);
      // return this.setState({ isLoggedInUser: true });
      setIsLoggedInUser(true);
    } else {
      console.log("is another user posts");
      // console.log(`no 1b ${loggedInId}`);
      // console.log(`no 2b ${userId}`);
      // return this.setState({ isLoggedInUser: false });
      return setIsLoggedInUser(false);
    }
  }

  useEffect(() => {
    checkUserId();
    // console.log(`no 3 ${userId}`);
    // console.log(`no 4 ${loggedInId}`);
  
  
  },[]);


    if (fetchingPosts == true) {
      return <Load2 />;
    }
    if (props.posts.length == 0) {
      return (
        <>
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
             {isLoggedInUser ?  
             <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello @{user_name}! Seems You Haven't Created Any Post. You Can Use The Box Above To Create One.
                If you have, hold on a few seconds while this Loads
              </h5>
              :
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello! @{user_name} Hasn't Created Any Post. You Can Check Back Later
              </h5>}
             
              
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
        {/* <p>Your Alerts</p> */}
        <PostComponent posts={posts} functionProp={props.functionProp} load={props.load}/>
        </>
        );
    }
  }


