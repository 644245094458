import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
// import Button from '@mui/material/Button';

// import { useState, useLocation, useEffect, useHistory  } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Load from "../components/Load";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
// import Select from '@mui/material/Select';
import CancelIcon from "@mui/icons-material/Cancel";
import { Select } from "antd";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Chip from "@material-ui/core/Chip";
import Resizer from "react-image-file-resizer";
import slugify from "react-slugify";
import { Snackbar } from "@material-ui/core";
// import Select from "react-select";

export default function CreatePage() {
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  const [isCreating, setIsCreating] = useState(false);
  const [pageName, setPageName] = useState("");
  const [pageDes, setPageDes] = useState("");
  const [pageStatus, setPageStatus] = useState("");
  const [tags, setTags] = useState([]);
  const [pageCover, setPageCover] = useState("");
  const [pageCoverFe, setPageCoverFe] = useState("");
  const [pagePic, setPagePic] = useState("");
  const [pagePicFe, setPagePicFe] = useState("");
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [newName, setNewName] = useState(pageName);
  const animatedComponents = makeAnimated();
  const [selectedTags, setSelectedTags] = useState([]);

  const options = [
    { value: "business", label: "Business" },
    { value: "lifestyle", label: "Lifestyle" },
    { value: "government", label: "Government" },
    { value: "fire", label: "Fire Service" },
    { value: "medical", label: "Medical" },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setIsSuccess(false);
    setEmptyFields(false);
    // setOpenAlert(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }
  function clearPagePics(value) {
    setPagePicFe("");
    setPagePic("");
  }
  function clearCoverImage(value) {
    setPageCover("");
    setPageCoverFe("");
  }

  function changeStatus(value) {
    console.log(`status ${value}`);
    setPageStatus(value);
  }

  function redirect(name) {
    setIsSuccess(false);
    history.push(`/page/${name}`);
  }

  function ChangeTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => tags.push(tag.value));
    console.log(`name ${tags}`);

    setSelectedTags([...new Set(tags)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  async function validate() {
    console.log(`pageName ${pageName}`);
    if (!pageName) {
      setEmptyFields(true);
      return;
    } else {
      createPage();
    }
  }

  async function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setPagePicFe(value);
      // setInvalidFile(false);
    // }
  }

  async function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setPageCoverFe(value);
      // setInvalidFile(false);
    // }
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setPagePic(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        80,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          setPageCover(uri);
        },
        "file",
        1080,
        1080
      );
    });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file);
      const image = await resizeCoverFile(file);
      //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function createPage() {
    setIsCreating(true);
    const name = slugify(pageName);
    setNewName(name);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    console.log(`new name is ${newName}`);
    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("name", pageName);
    formData.append("description", pageDes);
    formData.append("tags", tags);
    formData.append("status", pageStatus);
    formData.append("cover_pic", pageCover);
    formData.append("page_pic", pagePic);

    axiosFetch
      .post("/page", formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi ${resp.data}`);
          setPageName("");
          setPageDes("");
          setPageStatus("");
          setTags([]);
          setPageCover("");
          setPageCoverFe("");
          setPagePicFe("");
          setPagePic("");
          setIsCreating(false);
          console.log(`response ${JSON.stringify(resp.data)}`);
          setIsSuccess(true);
          redirect(name);
        } else {
          setIsCreating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;

  const statusSelect = [
    { value: "Business", label: "Business" },
    { value: "Government", label: "Government" },
  ];
  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/pages" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                    Create A Page
                  </h4>
                </div>
                <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                  {pageCoverFe ? (
                    <img
                      src={URL.createObjectURL(pageCoverFe)}
                      alt="avater"
                      style={{
                        maxWidth: 1450,
                        maxHeight: 450,
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      src={"https://via.placeholder.com/1200x250.png"}
                      alt="avater"
                      style={{
                        maxWidth: 1450,
                        maxHeight: 450,
                        objectFit: "contain",
                      }}
                    />
                  )}
                </div>
                <div className="card-body p-0 position-relative">
                  {pagePicFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      {/* <p> Your Channel Cover Picture </p> <br /> */}
                      <figure
                        className="avatar position-absolute w100 z-index-1"
                        style={{ top: "-40px" }}
                      >
                        <img
                          src={URL.createObjectURL(pagePicFe)}
                          alt="avater"
                          className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                        />
                      </figure>
                    </>
                  ) : (
                    <figure
                      className="avatar position-absolute w100 z-index-1"
                      style={{ top: "-40px" }}
                    >
                      <img
                        src={"/assets/images/user.png"}
                        alt="avater"
                        className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                      />
                    </figure>
                  )}
                  {/* <figure
                    className="avatar position-absolute w100 z-index-1"
                    style={{ top: "-40px" }}
                  >
                    <img
                      src={"/assets/images/user.png"}
                      alt="avater"
                      className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                    />
                  </figure> */}
                  <h4 className="fw-700 font-sm mt-2 pl-15">
                    {/* First Name{" "}
                        Last name */}
                    <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                      {/* @User Name */}
                    </span>
                  </h4>
                </div>
                <div className="card-body mt-5 p-lg-5 p-4 w-100 border-0 ">
                  {/* <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://via.placeholder.com/300x300.png"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>

                    </div>
                  </div> */}
                  {/* <h1> Your Profile Details </h1> */}
<br/><br/>
                  <form>
                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Page Name
                          </label>
                          {/* value={this.state.first_name} */}
                          <input
                            type="text"
                            value={pageName}
                            onChange={(e) => {
                              setPageName(e.target.value);
                              const name = slugify(pageName);
                              setNewName(name);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                          What is Your Page About?
                        </label>
                        <textarea
                          className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                          rows="5"
                          value={pageDes}
                          onChange={(e) => setPageDes(e.target.value)}
                          placeholder="Let people know a bit about your page..."
                        ></textarea>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Select Page Type
                          </label>
                          <p>
                            Only select Government if this is for a government
                            institution or you wish to provide assistance to
                            emergency alerts.
                          </p>
                          <Select
                            value={pageStatus}
                            label="Select One"
                            size="large"
                            style={{ width: "90%" }}
                            onChange={changeStatus}
                          >
                            {statusSelect.map((status) => (
                              <Option key={status.value} value={status.value}>
                                {" "}
                                {status.label}{" "}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Select / Create Page Services
                          </label>
                          <p>
                            Choose / type new to create your services. If your
                            page type is 'government', Alert emergencies will be
                            forwarded to you depending on your type of service
                          </p>

                          <div>
                            <CreatableSelect
                              isClearable
                              isMulti
                              onChange={ChangeTag}
                              //   options={props.tags}
                              components={animatedComponents}
                              options={options}
                            ></CreatableSelect>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 mb-3">
                      {/* </div> */}

                      {pagePicFe ? (
                        //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                        <>
                          <p> Your Page's Logo </p> <br />
                          <img
                            src={URL.createObjectURL(pagePicFe)}
                            alt="avater"
                            style={{
                              // width: 1450,
                              // maxWidth: 1450,
                              maxHeight: 450,
                              objectFit: "contain",
                              //    borderRadius: '10%'
                            }}
                          />
                          <Button onClick={clearPagePics}>Remove</Button>
                          <br />
                          <br />
                        </>
                      ) : (
                        //  </div>
                        <div className="card mt-3 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              <input
                                type="file"
                                accept="image/*"
                                name="file-2"
                                id="file-2"
                                className="input-file"
                                onChange={(e) => {
                                  validateProfileImage(e.target.files[0]);
                                  CompressImage(e);
                                }}
                              />
                              <label
                                htmlFor="file-2"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                  Upload your page's logo
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {invalidFile ? <h4> {invalidFile} </h4> : ""}
                    </div>

                    {invalidFile ? <h4> {invalidFile} </h4> : ""}

                    <div>
                      {pageCoverFe ? (
                        //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                        <>
                          <p> Your Page Cover Picture </p> <br />
                          <img
                            src={URL.createObjectURL(pageCoverFe)}
                            alt="avater"
                            style={{
                              // width: 1450,
                              // maxWidth: 1450,
                              maxHeight: 450,
                              objectFit: "contain",
                              //    borderRadius: '10%'
                            }}
                          />
                          <Button onClick={clearCoverImage}>Remove</Button>
                          {/* //  </div> */}
                        </>
                      ) : (
                        <div className="col-lg-12 mb-3">
                          <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="file"
                                  id="file"
                                  className="input-file"
                                  onChange={(e) => {
                                    validateCoverImage(e.target.files[0]);
                                    compressCoverImage(e);
                                  }}
                                  // onChange={(e) =>
                                  //     console.log(`test ${e.target.files[0]}`)}
                                />
                                <label
                                  htmlFor="file"
                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">
                                    Click to upload your page's cover picture
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-12">
                      {isCreating ? (
                        <>
                        <Button disabled variant="contained" color="primary">
                          Creating Your Page
                        </Button>
                        
                        <br/> <p>This may take a quick minute. You'll Be redirected after</p>
                        </>
                      ) : (
                        // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
                        <a
                          onClick={validate}
                          className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                        >
                          Create Page
                        </a>
                      )}
                      <br />
                      <br />
                      <br />
                      {emptyFields ? (
                        <>
                          {/* <br />
                          <br /> */}
                          <Alert onClose={handleClose} severity="warning">
                            To create a page, you need to ensure it has at least
                            a name
                          </Alert>
                        </>
                      ) : (
                        ""
                      )}

                      {error ? (
                        <div>
                          <Alert
                            severity="error"
                            onClose={handleClose}
                            action={
                              <>
                                <CancelIcon onClick={closeError} />
                              </>
                            }
                          >
                            There was an issue creating your page. Kindly try
                            again [ {errorMessage} ]
                          </Alert>
                          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}

                      {isSuccess ? (
                            <Snackbar
                            open={isSuccess}
                            autoHideDuration={4000}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                        <Alert
                          onClose={handleClose}
                          variant="filled"
                          severity="success"
                          action={
                            <Button
                              onClick={redirect}
                              style={{ color: "#fff", alignSelf: "center" }}
                            >
                              {" "}
                              Check It Out
                            </Button>
                          }
                        >
                          Your page was created sucessfully! Redirecting....
                        </Alert>
                        </Snackbar>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      // <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

{
  /* // export default createPage; */
}
