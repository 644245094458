import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import CategoryPosts from "../components/CategoryPosts";

import { Route, useParams } from "react-router-dom";
import CreateAlertFab from "../components/CreateAlertFab";
import CreatePostFab from "../components/CreatePostFab";

export default function PostCategory() {
  const { username, slug } = useParams();
  // const {id, category} = useParams();
  // const { slug } = useParams(["slug"]);
  // alert(username)
  // alert(slug)
  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
              {/* <CategoryPosts slug={slug} url={`tags/${slug}`} type={'tag'} /> */}
                <CategoryPosts userName={username} slug={slug} url={`post_category/${username}/${slug}`} type={'category'} />
                
              </div>
            </div>
          </div>
        </div>
      </div>

      // <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

// export default Authorpage;
