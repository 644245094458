import React, { Component } from "react";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import { Upload } from "@aws-sdk/lib-storage";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Fab, Tooltip } from "@material-ui/core";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";

// import { Select } from "antd";

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     // position: "absolute",
//     position: "fixed",
//     bottom: 160,
//     right: 20,
//   },

// }));

export default function CreateAlertDistress(props) {
  // const classes = useStyles();
  const [record, setRecord] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [checked, setChecked] = React.useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showLocation, setShowLocation] = useState(false);
  const animatedComponents = makeAnimated();
  const [alertTags, setAlertTags] = useState([]);
  const [alertTagsName, setAlertTagsName] = useState("Call Me");
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const ip_address = window.sessionStorage.getItem("ip");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const lat = window.sessionStorage.getItem("lat");
  const lng = window.sessionStorage.getItem("lon");

  const [alertId, setAlertId] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [updatingAlert, setUpdatingAlert] = useState(false);

  const options = [
    { value: "robbery-attack", label: "Robbery Attack" },
    { value: "need-medical-help", label: "Need Medical Help" },
    { value: "", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];
  const { Option } = Select;
  const alertTypeSelect = [
    { value: "fire", label: "Need Fire Rescue" },
    {
      value: "accident",
      label: "Need Medical Rescue (Accidents, Poisoning, Health Crises)",
    },
    {
      value: "security",
      label: "Need Security Rescue (Kidnap, Robbery, Assault)",
    },
  ];
  const handleModalClose = () => {
    setOpen(false);
    setCreated(false);
    setCreatedTest(false);
    setIsSuccess(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState(
    "I Am In A Life Threatening Crisis And I Need Immediate Help!"
  );
  const [headline, setHeadline] = useState(
    "I Am In A Life Threatening Crisis And I Need Immediate Help!"
  );

  const [okayBody, setOkayBody] = useState(
    "I Am No Longer In A Life Threatening Crisis. Please Stay Calm, All Is Well"
  );

  const [okayHeadline, setOkayHeadline] = useState(
    "I Am No Longer In A Life Threatening Crisis. Please Stay Calm, All Is Well"
  );

  const [testBody, setTestBody] = useState(
    "Hi! This Is Me Testing Out The SOS Alarrt. I Am Not In A Life Threatening Crisis. Please Stay Calm, All Is Well"
  );

  const [testHeadline, setTestHeadline] = useState(
    "Hi! This Is Me Testing Out The SOS Alarrt. I Am Not In A Life Threatening Crisis. Please Stay Calm, All Is Well"
  );

  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertCategoryName, setAlertCategoryName] = useState("");

  let history = useHistory();
  const bodyLocal = window.localStorage.getItem("body");
  const headlineLocal = window.localStorage.getItem("headline");

  const firstName = window.localStorage.getItem("first_name");
  const user_name = window.localStorage.getItem("user_name");
  const profile_pic = window.localStorage.getItem("profile_pic");
  const verified = window.localStorage.getItem("verified");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [compImages, setCompImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [alertImage, setAlertImage] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [created, setCreated] = useState(false);
  const [createdTest, setCreatedTest] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [sentVideo, setSentVideo] = useState(false);
  const [sosCount, setSosCount] = useState('');
  const [isLimit, setIsLimit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [creatingAlarrt, setCreatingAlarrt] = useState(false);
  const [emptyBody, setEmptyBody] = useState(false);
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [locationWarning, setLocationWarning] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [sendToFriends, setSendToFriends] = useState("yes");

  const [alertInfo, setAlertInfo] = useState("");

  const [error, setError] = useState(false);
  const loopData = [];

  const Input = styled("input")({
    display: "none",
  });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setEmptyBody(false);

    setCreated(false);
    setCreatedTest(false);
    setError(false);
    setIsSuccess(false)
    setLocationWarning(false);
  }

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  function handleClose2(event, reason) {
    // if (reason === "clickaway") {
    //   return;
    // }
    setIsSuccess(false);
    setBody("");
    localStorage.removeItem("body");
    localStorage.removeItem("headline");
    setImage("");
    props.functionProp();
    props.functionProp2();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const ChangeAlertCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setAlertCategory(value.id);
      setAlertCategoryName(value.name);
      console.log(alertCategory);
      console.log(alertCategoryName);
    } else {
      setAlertCategory("");
      setAlertCategoryName("");
      console.log("value empty");
      //   getTags();
    }
  };

  function addAlartInfo(value) {
    setAlertInfo("");
    // setHeadline("I Am In A Life Threatening Crisis And I Need Immediate Help!")
    console.log(`status ${value}`);
    console.log(`status 2 ${value.label}`);
    setAlertInfo(value.label);

    setBody(value.label);
  }

  async function getSos() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get("/users/check/sos", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        setSosCount(resp.data)
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function validateImage(e, value) {
    // setAlertImage([])
    setAlertImage([...e]);
    alertImage.push(e);
    for (let i = 0; i < alertImage[0].length; i++) {
      alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
    }
    setAlertImage(alertImageUrls);
    // onchange()
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  const onChange3 = async (event) => {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  function validate(e) {
    if (!lat || !lng) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Lat is :", position.coords.latitude);
        console.log("Lon is :", position.coords.longitude);
        window.sessionStorage.setItem("lat", position.coords.latitude);
        window.sessionStorage.setItem("lon", position.coords.longitude);
      });

      var retVal = window.confirm(
        `We do not know your location. ${firstName}! Kindly click CANCEL and follow the instructions below the click to send button, then try again. Click OK if you wish to create alarrt anyway`
      );
      if (retVal == true) {
        // document.write ("User wants to continue!");
        // return turue;
      } else {
        handleModalClose();
      }
    } else {
      createAlert();
    }
  }

  function getLastAlert() {
    setCreatingPost(true);
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/alerts/get_last", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // alert(resp.data);
        setAlertId(resp.data);
        uploadVideo(resp.data);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function uploadVideo(alertId) {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "alerts/videos/" + user_id + "/" + uuidv4();
      // video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(
            `Almost done uploading - ${uploaded}% completed. Please be patient`
          );
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            updateAlert(alertId);
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      updateAlert(alertId);

      console.log("video very empty");
    }
  }

  async function updateAlert(alertId) {
    setCreatingPost(true);
    setCreatingMessage("Updating Your Alert, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();

    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }

    console.log(video);
    let result = await axiosFetch
      .post(`/alerts/update_video/${alertId}`, formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setCreatingPost(false);
        props.functionProp();
        // props.functionProp2();
        setSentVideo(true);
        setIsSuccess(true);

        // setIsNext(true)
        window.localStorage.removeItem("postVideo");
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  async function createAlert() {
    if (!lat || !lng) {
      alert("Creating Your Alarrt Without GPS Location");
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Lat is :", position.coords.latitude);
        console.log("Lon is :", position.coords.longitude);
        window.sessionStorage.setItem("lat", position.coords.latitude);
        window.sessionStorage.setItem("lon", position.coords.longitude);
      });
      // return;
    }
    // else {
    setCreatingPost(true);
    setIsCreating(true);
    setCreatingMessage("Sending Your Alarrt, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", 75);
    formData.append("communities_id", '');
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("tags", alertTagsName);
    // formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    //THIS IS WORKING TOO
    // images.map((image) => {
    // formData.append('image[]', image)
    // console.log(`hjsimage ${JSON.stringify(image)}`)
    // });
    //THIS IS WORKING TOO
    // for(var a = 0; a<images.length; a++)
    // {
    //   // const fd = new FormData();
    //   //console.log(images[a])
    //   formData.append('image[]', images[a]['file']);
    //   console.log(`oluuu`)
    // }
    // formData.append("image[]", images);
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }

    // formData.append("tags", alertTagsName);
    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "distress");
    formData.append("status", "sent_out");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("alert_type", 'public');
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingPost(false);
          setIsCreating(false);
          props.functionProp();
          props.functionProp2();
          // setHeadline("");
          // setBody("");
          setAlertTagsName("Call Me");

          // localStorage.removeItem("body");
          // localStorage.removeItem("headline");

          setIsSuccess(true);
          // setCreated(true);
          // setIsNext(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingPost(false);
          setIsCreating(false);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  async function okayAlarrt() {
    // else {
    setCreatingAlarrt(true);
    setIsCreating(true);
    setCreatingMessage("Letting Everyone Know You're Okay, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", 75);
    formData.append("communities_id", '');
    formData.append("body", okayBody);
    formData.append("headline", okayHeadline);
    // formData.append("tags", alertTagsName);
    // formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }

    // formData.append("tags", alertTagsName);
    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "distress");
    formData.append("status", "sent_out");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("alert_type", 'public');
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts/okay", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingAlarrt(false);
          setIsCreating(false);
          props.functionProp();
          props.functionProp2();
          // setHeadline("");
          // setBody("");
          setAlertTagsName("Call Me");

          // localStorage.removeItem("body");
          // localStorage.removeItem("headline");

          // setIsSuccess(true);
          setCreated(true);
          // setIsNext(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingAlarrt(false);
          setIsCreating(false);
        }
      })
      .catch((error) => {
        setCreatingAlarrt(false);
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  async function testAlarrt() {
    // else {
    setCreatingAlarrt(true);
    setIsCreating(true);
    setCreatingMessage("Sendng Your Test Alarrt, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", 75);
    formData.append("communities_id", '');
    formData.append("body", okayBody);
    formData.append("headline", okayHeadline);
    // formData.append("tags", alertTagsName);
    // formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }

    // formData.append("tags", alertTagsName);
    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "distress");
    formData.append("status", "sent_out");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("alert_type", 'public');
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts/okay", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingAlarrt(false);
          setIsCreating(false);
          props.functionProp();
          props.functionProp2();
          // setHeadline("");
          // setBody("");
          setAlertTagsName("Call Me");

          // localStorage.removeItem("body");
          // localStorage.removeItem("headline");

          // setIsSuccess(true);
          setCreatedTest(true);
          // setIsNext(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingAlarrt(false);
          setIsCreating(false);
        }
      })
      .catch((error) => {
        setCreatingAlarrt(false);
        setIsCreating(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  const changeCallStatus = (event) => {
    setAlertTagsName(event.target.value);
    toogleDontCall();
  };

  const toogleDontCall = () => {
    if (showTags == true) {
      setShowTags(false);
      setAlertTagsName("Don't Call Me");
      // setChecked(true);
    } else {
      setShowTags(true);
      setAlertTagsName("Call Me");
      // setChecked(false);
    }
    // setChecked(event.target.checked);
  };

  function ChangeAlertTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => alertTagsName.push(tag.value));
    console.log(`name ${alertTagsName}`);

    // setSelectedTags([...new Set(alertTagsName)]);
    // console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/alert_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setNewCategory("");
        getAlertCategories();
        setCreatingCat(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function validateImage2(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select a valid image.");
      return false;
    } else {
      console.log(value);
      setImage(value);
      setInvalidFile(false);
    }
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  // function startRecording3() {
  //   setRecord(true);
  //   setIsRecording(true);
  // }

  // function stopRecording3() {
  //   setRecord(false);
  // }

  // function onData(recordedBlob) {
  //   // onData(recordedBlob) {
  //   console.log("chunk of real-time data is: ", recordedBlob);
  // }

  // async function onStop(recordedBlob) {
  //   setIsRecording(false);
  //   console.log("recordedBlob is: ", recordedBlob);
  //   console.log("recordedBlob blob file is  is: ", recordedBlob.blob);
  //   const blobFile2 = recordedBlob.blob;
  //   setBlobFile(blobFile2);
  // await FFMPEG.process(
  //   blobFile,
  //   '-metadata location="" -metadata location-eng="" -metadata author="" -c:v copy -c:a copy',
  //   function (e) {
  //     const video = e.result;
  //     console.log('ffmpeg is ' + video);
  //   }.bind(this)
  // );

  // const blob = new Blob('alert-audio',
  // {
  //   type: recordedBlob.blobURL.type,
  //   lastModified: Date.now()
  // });
  // const blobURL = URL.createObjectURL(recordedBlob.blob);
  // const file = new File( blobFile, {
  //   type: blobFile.type,
  //   lastModified: Date.now()
  // });
  // console.log('new file is is: ', file);
  // try{
  // var process = new FFmpeg('ppath/to/new/file')
  //   process.then(function (audio){
  //     audio.fnExtractSoundToM3('path/to/new/file.mp3', function(error, file){
  //       if(!error)
  //       console.log('audio file processed is '+ file );
  //     });

  //   }, function(err) {
  //     console.log('procesing error '+ err);
  //   });
  // }
  // catch (e) {
  //   console.log(e.code)
  //   console.log(e.msg)
  // }

  // const li = document.createElement("li");
  // const player = new Audio(URL.createObjectURL(blobFile2));
  // player.controls = true;
  // li.appendChild(player);
  // document.querySelector("#playlist").appendChild(li);

  // const player = new Audio(URL.createObjectURL(file));
  //   console.log("player is " + JSON.stringify(player));
  //   player.play();
  //   setBlobUrl(blobURL);
  //   uploadAudio();
  // }

  // function startRecording2() {
  //   if (isBlocked) {
  //     alert("Permission Denied");
  //   } else {
  //     Mp3Recorder.start()
  //       .then(() => {
  //         setIsRecording(true);
  //       })
  //       .catch((e) => console.error(e));
  //   }
  // }

  // function stopRecording2() {
  //   Mp3Recorder.finish()
  //     .getMp3()
  //     .then(([buffer, blob]) => {
  //       setIsRecording(false);
  //       console.log("mp3 blob is " + blob);
  //       console.log("mp3 blob buffer is " + blob);
  //       const file = new File(buffer, "alert-audio.mp3", {
  //         type: blob.type,
  //         lastModified: Date.now(),
  //       });

  //       const li = document.createElement("li");
  //       const player = new Audio(URL.createObjectURL(file));
  //       player.controls = true;
  //       li.appendChild(player);
  //       document.querySelector("#playlist").appendChild(li);

  // const player = new Audio(URL.createObjectURL(file));
  // console.log("player is " + JSON.stringify(player));
  // player.play();
  // setBlobUrl(player);

  // const blobURL = URL.createObjectURL(blob)
  // setBlobUrl(blobURL);

  // this.setState({ blobURL, isRecording: false });
  //     })
  //     .catch((e) => console.log(e));
  // }

  function getLocation() {
    if ("geolocation" in navigator) {
      console.log("Location is Available");
    } else {
      console.log("Location is Not Available");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Lat is :", position.coords.latitude);
      console.log("Lon is :", position.coords.longitude);
      window.sessionStorage.setItem("lat", position.coords.latitude);
      window.sessionStorage.setItem("lon", position.coords.longitude);
    });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("full posoition " + position);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        console.log("Watch Lat is :", position.coords.latitude);
        console.log("Watch Lon  is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
  }

  useEffect(() => {
    getLocation();
    watchLocation();
    getSos();
    // onChange={(e) => setLocation(e.target.value)}

    // if (bodyLocal) {
    //   setBody(bodyLocal);
    // }
    // if (headlineLocal) {
    //   setHeadline(headlineLocal);
    // }
  }, [console.log(" headline -> ", headline, "body is -> ", body)]);

  // render() {
  const menuClass = `${isOpen ? " show" : ""}`;
  //    const {user_name} = this.state;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0 text-center">
        {/* <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">
          Hi {firstName}! What Is Happening?
        </h3> */}
        {/* <Button
          //  disabled
          variant="contained"
          color="secondary"
          component="span"
          style={{ marginBottom: 10 }}
          //  style={{ marginLeft: 7 }}
          onClick={handleClickOpen}
        >
          Click Here To Send
        </Button> */}

        <Tooltip
          title="Send Distress Alarrt"
          aria-label="add"
          // onClick={handleClickOpen}
          onClick={() => {
            handleClickOpen();
            getLocation();
            watchLocation();
          }}
        >
          <Fab
            color="secondary"
            style={{
              zIndex: 30,
              width: 100,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <img src="/assets/images/alert-fab3.webp" /> */}
            <img src="/assets/images/alert-fab3.webp" />
            {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
          </Fab>
        </Tooltip>
        <br />
        <br />
        <h3 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
          {" "}
          Click To Send Personal Distress (SOS) Alarrt
        </h3>

        <div className="mt-3">
          {!lat || !lng ? (
            <>
              {/* <Typography gutterBottom variant="outline">
            Seems you've not enabled your location
           
          </Typography> */}

              {/* <Alert variant="filled" severity="warning">
                For distress alerts, we need to know your location.  Watch this quick silent video to see how to enable it on your
                phone now.
              </Alert> */}

              {/* just removed this fro mobile app to test if location is passed */}
              <br />
              <Typography gutterBottom variant="overline">
                Seems you've not enabled your location. For distress alerts, we
                need to know your location. If you're on the mobile app,
                <br />
                ->Close the app now, long-press the app icon and click 'App
                Info'. <br />
                ->Click permissions and allow location. Reopen the app once
                done. <br />
                ->If you're on the website (Chrome), you can update permissions
                from your chrome settings, and refresh
                {/* Watch this quick silent video to see how to enable it on your
                phone now.<br/>
                NOTE -> Send your distress alert first, so we get notified. Then enable your location, and resend so we know your location */}
              </Typography>
              <a href="https://alert-app-v2.s3.amazonaws.com/movies/videos/YouCut_20220405_072857560.mp4">
                {" "}
                Watch How to Enable On Chrome
              </a>

              <br />
              <Button
                variant="contained"
                color="primary"
                href="https://wa.me/message/3VHYR6VAI3MSD1"
                target="_blank"
              >
                Whatsapp Us For Help
              </Button>
              {/* <Button
              
                variant="contained"
                color="primary"
                href="https://alert-app-v2.s3.amazonaws.com/movies/videos/YouCut_20220405_072857560.mp4"
                target="_blank"
               
              >
                Watch How To Allow
              </Button>  */}
            </>
          ) : (
            ""
          )}
        </div>

        {/* {created ? (
          <>
            <br />
            <Alert variant="filled" onClose={handleClose} severity="success">
              Thank you {firstName} for notifying us! - Admins have been
              notified and help will be dispatched immediately. Your close
              friends have also been notified by email and sms.{" "}
              <b>
                Please if you are moving from your current location, move around
                the app if you can so it updates your new location
              </b>
            </Alert>
            <br />

            <br />

            <Button
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              Go Back Home
            </Button>
          </>
        ) : (
          ""
        )} */}
        {/* <div className="form-group icon-input mb-0.5">
          <input
            // type="Password"
            type="text"
            placeholder="Summarize headline here"
            className="style-input form-control text-grey-900 font-xsss ls-3"
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          />
        </div> */}

        {/* <a onClick={this.createPost} className="font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center"> */}
        {/* <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Click To Create Post</a> */}
      </div>

      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle>
          {" "}
          Your SOS Contacts saved on Alarrt will also be notified via SMS. 
          {/* {props.channelId}{" "} */}
        </DialogTitle>
        <DialogContentText
          style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 30 }}
        >
          {" "}
          If you are not in a life threatening crisis/emergency, please go back
          home. You currently have <b>{sosCount} SOS Contacts</b> On Alarrt
        </DialogContentText>
        <DialogContent>
          <br />

          {/* isNext Div */}
          {isNext == false ? (
            <>
              {isSuccess == true ? (
                ""
              ) : (
                <div>
                  <h4 className="text-center">
                    Proceed Only If In An Emergency {firstName}.
                  </h4>
                  {/* <Select
                    
                    defaultInputValue={alertInfo}
                    placeholder="Select Your Emergency Type"
                    // label="Select Your Emergency Type"
                    size="large"
                    style={{ width: "90%" }}
                    onChange={addAlartInfo}
                    options={alertTypeSelect}
                  ></Select> */}

                  <div className="card-body p-0 mt-1 mb-1">
                    {/* <FormControl>
                      <FormLabel id="demo-controlled-radio-buttons-group">
                        Can you recieve a call {firstName}?
                      </FormLabel>
                      <br />
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={alertTagsName}
                        onChange={changeCallStatus}
                        style={{ marginLeft: 10 }}
                      >
                        <FormControlLabel
                          value="Don't Call Me"
                          control={<Radio />}
                          label="Don't Call Me"
                        />
                        <FormControlLabel
                          value="Call Me"
                          control={<Radio />}
                          label="Yes, Call Me"
                        />
                      </RadioGroup>
                    </FormControl> */}
                    {/* {showTags ? (
                      <>
                       
                        <br />
                        <br />
                        <Chip
                          className="pointer infotag"
                          color="primary"
                          size="small"
                          label={"We will call you for more info if needed"}
                        />
                        <br />
                       
                      </>
                    ) : (
                      <>
                        <div>
                        
                          <br />
                          <Chip
                            className="pointer infotag"
                            color="primary"
                            size="small"
                            label={
                              "We wont't call but communicate via SMS or Whatsapp If Needed"
                            }
                          />
              
                        </div>
                      </>
                    )} */}
                  </div>

                  <div className="mt-1">
                    {!creatingPost ? (
                      <>
                        {/* <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3"> */}
                        <div className="card-body text-center">
                          <Tooltip
                            title="Send Distress Alarrt"
                            aria-label="add"
                            // onClick={handleClickOpen}
                            onClick={() => {
                              getLocation();
                              watchLocation();
                              // createAlert()
                              validate();
                            }}
                          >
                            <Fab
                              color="secondary"
                              style={{
                                zIndex: 30,
                                width: 100,
                                height: 100,
                                justifyContent: "center",
                                alignItems: "center",
                                alignSelf: "center",
                              }}
                            >
                              {/* <img src="/assets/images/alert-fab3.webp" /> */}
                              <img src="/assets/images/alert-fab3.webp" />
                              {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
                            </Fab>
                          </Tooltip>
                          {/* </div> */}
                        </div>
                        <br />
                        <br />
                        <h5 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
                          {" "}
                          Click The Button Above To Send A Distress (SOS) Alarrt
                        </h5>
                        {/* <Button
                          variant="contained"
                          color="secondary"
                          component="span"
                          style={{ marginBottom: 10 }}
                          onClick={() => {
                            getLocation();
                            watchLocation();
                            // createAlert()
                            validate();
                          }}
                        >
                          Send Distress Alarrt Now
                        </Button> */}
                      </>
                    ) : (
                      <div className="text-center">
                        <br />
                        <Typography gutterBottom variant="body1">
                          Notifications are being sent out!{" "}
                          <b>
                            Please stay calm, stay quiet, and continue to act
                            natural
                          </b>
                          {/* You can forward or edit your alert after creation to add more
                        info on what is going on */}
                        </Typography>
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          // onClick={this.createPost}
                        >
                          Sending Your Alarrt
                        </Button>
                        <br />
                        <h4>{creatingMessage}</h4>
                      </div>
                    )}
                  </div>
                  {/* <br /> */}
                  {/* <br /> */}

                  {/* end of isnext div */}
                </div>
              )}
            </>
          ) : (
            <>
              {sentVideo ? (
                <>
                  <h4>
                    {" "}
                    Thanks For That Additional Information {firstName}.
                    Notifications have been sent out. You will be contacted
                    shortly.
                  </h4>
                </>
              ) : (
                <div>
                  <h5>
                    You are in the next section {firstName}. To understand what
                    is really going on, and help you better, please send us a
                    live video recording. This doesn't make any sound
                  </h5>

                  <>
                    {video ? (
                      <>
                        {/* <br /> */}
                        <Typography
                          gutterBottom
                          variant="body1"
                          // className={classes.postHead}
                        >
                          Your alert's video
                        </Typography>

                        {/* <br /> */}
                        <video width="400" controls>
                          <source src={URL.createObjectURL(video)} />
                        </video>

                        <br />
                        <br />
                        {creatingPost ? (
                          <Button disabled>{creatingMessage}</Button>
                        ) : (
                          <>
                            <Button
                              // disabled
                              variant="contained"
                              color="primary"
                              onClick={getLastAlert}
                            >
                              Send Your Video Now
                            </Button>
                            <br />
                            <br />
                            <Button
                              onClick={() => {
                                setVideo("");
                                // getLastAlert()
                              }}
                            >
                              Click To Remove (Take Another)
                            </Button>
                          </>
                        )}

                        <br />
                      </>
                    ) : (
                      <>
                        <Typography
                          gutterBottom
                          variant="body1"
                          // className={classes.postHead}
                        >
                          You can take a live recording using either your front
                          or back camera. Do this discreetly if in a hostile
                          environment. Press "Rec" when ready and wait for the
                          countdown. Click stop to start your video upload.
                        </Typography>
                        <br />
                        <VideoRecorder
                          isOnInitially={true}
                          countdownTime={1000}
                          onRecordingComplete={(videoBlob) => {
                            // Do something with the video...
                            console.log("videoBlob", videoBlob);
                            setVideo(videoBlob);
                          }}
                        />
                        <br />
                        <br />
                      </>
                    )}
                  </>
                </div>
              )}
            </>
          )}

          {/* {created == true ? (
                ""
              ) : (
                <div> */}
          {!creatingAlarrt ? (
            <>
              <div className="mt-1">
                <h4 className="text-center">
                  Proceed To Notify Everyone You're Okay Now {firstName}.
                </h4>
                {/* <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3"> */}
                <div className="card-body text-center">
                  <Tooltip
                    title="Send I'm Okay Now Alarrt"
                    aria-label="add"
                    // onClick={handleClickOpen}
                    onClick={() => {
                      getLocation();
                      watchLocation();
                      // createAlert()
                      okayAlarrt();
                    }}
                  >
                    <Fab
                      color="primary"
                      style={{
                        zIndex: 30,
                        width: 100,
                        height: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      {/* <img src="/assets/images/alert-fab3.webp" /> */}
                      <img src="/assets/images/alert-fab3.webp" />
                      {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
                    </Fab>
                  </Tooltip>
                  {/* </div> */}
                </div>
                <br />
                <br />
                <h5 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
                  {" "}
                  Click The Button Above To Let Everyone Know You Are Okay Now
                </h5>
                <br />
                <br />

                <div className="text-center">
                  <h5>
                    {" "}
                    Do You Wish To See How The SOS Works? Use The Button Below
                    To Try It Out
                  </h5>
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{
                      // zIndex: 30,
                      // justifyContent: "center",
                      // alignItems: "center",
                      alignSelf: "center",
                      marginTop: 10,
                    }}
                    onClick={() => {
                      getLocation();
                      watchLocation();
                      // createAlert()
                      testAlarrt();
                    }}
                  >
                    Test The SOS Button
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center">
              <br />
              <Typography gutterBottom variant="body1">
                Notifications are being sent out! <b>Please stay calm.</b>
                {/* You can forward or edit your alert after creation to add more
                        info on what is going on */}
              </Typography>
              <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={this.createPost}
              >
                Sending Your Alarrt
              </Button>
              <br />
              <h4>{creatingMessage}</h4>
            </div>

            // </div>
          )}
          {/* <br /> */}
          {/* <br /> */}

          {/* end of isnext div */}
          {/* </div>
              )} */}

          {locationWarning && (
            <Alert
              onClose={handleClose}
              severity="error"
              action={
                <>
                  <a
                    onClick={handleClose}
                    className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                  >
                    No, undo.
                  </a>

                  <a
                    // onClick={() => handleUnSubscribe(channel.id)}
                    className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                  >
                    Yes, cancel it.
                  </a>
                </>
              }
            >
              We do not know your location first. Kindly click go back home and
              follow the instructions below the send distress button.
            </Alert>
          )}
          {created ? (
            <>
              <br />
              <Alert variant="filled" onClose={handleClose} severity="success">
                Glad you are okay {firstName}! - Admins and your SOS Contacts
                have been notified you are now okay.
                {/* <b>
                  Please if you are moving from your current location, Please
                  refresh the app at interval so it updates your new location.
                </b> */}
              </Alert>
            </>
          ) : (
            ""
          )}
          {createdTest ? (
            <>
              <br />
              <Alert variant="filled" onClose={handleClose} severity="success">
                Welldone {firstName}! - It's good you tested that out. Admins
                and your SOS Contacts have been notified you sent a test SOS.
                {/* <b>
                  Please if you are moving from your current location, Please
                  refresh the app at interval so it updates your new location.
                </b> */}
              </Alert>
            </>
          ) : (
            ""
          )}
          {isSuccess ? (
            <>
              {isNext == false && (
                <>
                  {/* <h4>
                    Optional: If this is a security emergency, To let us help you better, kindly record a live video of
                    what is happening in the next step
                  </h4>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      // getLastAlert();
                      setIsNext(true);
                    }}
                  >
                    Proceed To Record Video
                  </Button> b */}
                </>
              )}
              <Alert variant="filled" onClose={handleClose} severity="success">
                Thank you {firstName} for alarrting us! - Admins have been
                notified and help will be dispatched soonest. Your SOS Contacts
                have also been notified by sms.{" "}
                {/* <b>
                  Please if you are moving from your current location, Please
                  refresh the app at interval so it updates your new location.
                </b> */}
              </Alert>
              <br />
              <Typography
                gutterBottom
                variant="overline"
                // className={classes.postHead}
              >
                Security Tip: -> We'll call you shortly. Keep your phone close
                to you and{" "}
                <b>
                  put it on vibration / silence if stuck in a security threat.
                </b>
                <br />
                {/* <b> -> We'll contact you in the next minute.  */}
                {/* Stay in touch with us with the Whatsapp Button Below. */}
                {/* </b> */}
                {/* <br />
                -> After sending, if you are moving from your current location,{" "}
                <b>
                  please refresh the app at interval so it updates your new
                  location{" "}
                </b> */}
              </Typography>{" "}
            </>
          ) : (
            ""
          )}
          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue sending your alarrt. Kindly try again [{" "}
                {errorMessage} {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}
        </DialogContent>

        <br></br>
        <br />

        <DialogActions>
          <div style={{ marginRight: 10 }}>
            {!isCreating ? (
              <>
                {/* <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => {
                    getLocation();
                    watchLocation();
                    // createAlert()
                    validate()
                  }}
                >
                  Send Distress Alert Now
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  disabled
                  variant="contained"
                  color="primary"
                  // onClick={this.createPost}
                >
                  Sending Your Alarrt
                </Button>
                {/* <br /> */}
                {/* <h4>{creatingMessage}</h4> */}
              </>
            )}
          </div>
          {/* <Button
            variant="contained"
            color="primary"
            component="span"
            href="https://wa.me/message/3VHYR6VAI3MSD1"
            target="_blank"
            onClick={handleClickOpen}
            style={{ marginRight: 10 }}
          >
            Stay In Touch Via Whatsapp
          </Button> */}
          <a
            href="https://wa.me/message/3VHYR6VAI3MSD1"
            target="_blank"
            className="pt-2 pb-2 d-flex align-items-center"
          >
            <i
              className="btn-round-md bg-blue-gradiant text-white feather-message-circle font-md me-3"
              style={{
                display: "flex",
                fontSize: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            ></i>{" "}
            <h4 className="fw-600 font-xsss mb-0 mt-0">
              Stay In Touch Via Whatsapp
            </h4>
          </a>

          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
            style={{ marginLeft: 15 }}
          >
            Go Back Home
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// }

// export default CreatePost2;
