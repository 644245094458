import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
import { Provider } from "react-redux";
// import { ChakraProvider } from '@chakra-ui/react'
// import { store } from './createStore';
import HttpsRedirect from "react-https-redirect";

ReactDOM.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
    {/* <ChakraProvider> */}
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
    {/* </Provider>,  */}
    {/* </ChakraProvider> */}
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
