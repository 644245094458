import React, { Component, useState, useEffect } from "react";
import "./comment.css";
import { PermMedia, Label, Room, EmojiEmotions } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles, Snackbar } from "@material-ui/core";
import axios from "axios";
import { format, formatDistance, subDays } from "date-fns";
import axiosFetch from '../../Interceptors2';
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));


export default function CreateThread(props) {
  const classes = useStyles();
  const [isCreating, setIsCreating] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [message, setMessage] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // const [userPic, setUserPic] = useState("");
  const userPic = window.localStorage.getItem("profile_pic");
  const userName = window.localStorage.getItem("user_name");

  function handleClose2() {
    // setIsLiked(false);
    setIsDeleted(false);
  }

  async function deleteThread(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/${props.url}/${id}/delete`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setIsDeleting(false);
        props.functionProp();
        // setOpen(false);
        setIsDeleted(true)
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }




  useEffect(() => {
    // getProfile();
  }, []);

  return (
    <>
      <div className="border-t pt-4 space-y-4 dark:border-gray-600">
        {props.threads.map((thread) => (
          <div className="flex">
            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
            <Link to={`/profile/${props.user_name}`}>
              <img
                src={props.pic}
                alt=""
                // className="shadow-sm rounded-circle w45"
                style={{ marginRight: 10, border: "2px solid #045de9" }}
                className="absolute h-full rounded-full w-full"
              />
            </Link>
            </div>
            <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-20  dark:bg-gray-800 dark:text-gray-100">
              {/* <p className="leading-6"> */}
                {/* <span style={{ marginRight: 20 }}> */}
                <Link to={`/profile/${props.user_name}`}>
                  @{props.user_name}
                </Link>
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {formatDistance(
                      new Date(thread.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                    {/* {alert.alert.created_at} */}
                  </span>
                  <ShowMoreText
                    lines={1}
                    more="Read more"
                    less="Show less"
                    // className="leading-6"
                    className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                    anchorClass="my-anchor-css-class"
                    width={300}
                    expanded={false}
                    truncatedEndingComponent={"... "}
                  >
                    {thread.body}
                  </ShowMoreText>

{/* delete thread axios */}
                  {isDeleting && currentComment == thread.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {props.authId == props.userId ? (
                  // {props.adminId == props.userId[0] ? (
                    <RemoveCircleOutlineIcon
                      onClick={() => {
                        setCurrentComment(thread.id)
                        deleteThread(thread.id)
                    }}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            
              <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />
      {isDeleted ? (
        <Snackbar
        open={isDeleted}
        autoHideDuration={2000}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert onClose={handleClose2} variant="filled" severity="success">
          Thread removed successfully!
        </Alert>
        </Snackbar>
      ) : (
        ""
      )}
     
    </>
  );
}
