import React, { Component } from "react";
import { useState, useEffect } from "react";
import LikersModal from "./LikersModal";

export default function LikesProfilePic(props) {
  // const [lastLike, setLastLike] = useState(props.likers.pop());

  // function lastLikes() {
  //   setLastLike(props.likers.pop())
  // }

  // useEffect(() => {
  //   lastLikes()
  // }, [console.log('huu')]);
  return (
    // <>

    // {props.likes.map((likers) => (
    //   <>
    //   {console.log(like.user.id)}
    //   </>
    // ))}

    // </>
    <div className="card-body p-0">
      <ul className="memberlist mt-4 mb-2 ms-0 d-inline-block">
        {props.likers.map((likers) => (
          <>
            <li key={likers.id}>
              <a href={`/profile/${likers.user_name}`}>
                <img
                  src={likers.profile_pic}
                  alt="profile-pic"
                  className="w50 shadow-sm d-inline-block rounded-circle w45"
                  style={{
                    maxWidth: 40,
                    height: 40,
                    border: "2px solid #045de9",
                    objectFit: "cover",
                  }}
                />
              </a>
            </li>
          </>
        ))}
        <li className="last-member">
          <a
            // href={`/profile/${user.user_name}`}
            className="bg-greylight fw-600 text-grey-500 font-xssss ls-3 text-center"
          >
            +{props.likes.length}{" "}
          </a>
        </li>
      </ul>
      {props.type == "alert" ? (
        <span className="d-none-xss lh-32 mt-4" style={{ paddingLeft: 25 }}>
          Appreciated by <b>{props.likers[0].user_name}</b> and <b>more</b>
        </span>
      ) : (
        <span className="d-none-xss lh-32 mt-4" style={{ paddingLeft: 25 }}>
          Liked by <b>{props.likers[0].user_name}</b> and <b>more</b>
        </span>
      )}
      <span className="d-none-xss float-right lh-32 mt-4 ">
        {props.comments} comments
      </span>
      {props.realerts ?
      <span className="d-none-xss float-right lh-32 mt-4 mr-2">
        {props.realerts} re-alerts
      </span>
      :
      <span className="d-none-xss float-right lh-32 mt-4 mr-2">
      {props.reposts} re-posts
    </span>
}
      <span className="d-none-xss float-right lh-32 mt-4 mr-2">
        <LikersModal
          likers={props.allLikers}
          color={props.color}
          text={props.text}
          type={props.likersType}
          class="d-none-xss float-right lh-32 mr-2"
        />
      </span>
    </div>
  );
}
