import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../Interceptors2';
// import { Tabs, Tab } from "react-bootstrap";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ChannelDetails from "./ChannelDetails";
import Profilephoto from "./Profilephoto";
import UserChannels from "./UserChannels";
// import ProfileTabs from "./ProfileTabs";
import Events from "./Events";
import Createpost from "./Createpost";
// import Box from "@mui/material/Box";
import MuiAlert from "@material-ui/lab/Alert";
import CreatePost2 from "../components/CreatePost2";
import { Button } from "@material-ui/core";
import MovieGrid from "../moviesComponents/movie-grid/MovieGrid";
import ChannelsMoviesModal from "./ChannelsMoviesModal";
import ChannelsVideoModal from "./ChannelsVideoModal";
import ChannelsSeriesModal from "./ChannelsSeriesModal";
import SeriesCardChannels from "../moviesComponents/movie-card/SeriesCardChannels";
import ChannelPosts from "./ChannelPosts";
import ChannelSubscribers from "./ChannelSubscribers";
import CreatePostFab from "./CreatePostFab";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Load from "./Load";

export default function ChannelsTabAdmin(props) {
  const [key, setKey] = useState("details");
  const { slug } = useParams(["slug"]);
  const [channels, setChannels] = useState([]);
  const [channelId, setChannelId] = useState('');
  const [channelMovies, setChannelMovies] = useState([]);
  const [channelSubscribers, setChannelSubscribers] = useState([]);
  const [channelVideos, setChannelVideos] = useState([]);
  const [channelSeries, setChannelSeries] = useState([]);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState("1");
  const userId = useState(window.localStorage.getItem("user_id"));
  const admin_id = props.admin.id;
  const location = useLocation();
  const path = location.pathname;
  let history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [fetchingChannel, setFetchingChannel] = useState(false);
  const [value, setValue] = React.useState("details");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
    // setEmptyFields(false);
  };
  function closeError() {
    setError(false);
    console.log("closed");
  }

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.push(`/channel/${slug}`)
    setValue(newValue);
    // e.prevent
    event.preventDefault();
    history.push(`/channel/${slug}/${newValue}`)
  };

  function getQuery() {
    const queryParams = new URLSearchParams(location.search);
    console.log(`hiii ${queryParams}`);
    const firstQuery = queryParams.delete("channel");
    const secondQuery = queryParams.delete("w");
    const thirdQuery = queryParams.delete("a");
    // setChannelSlug(firstQuery);
    // setMovieId(secondQuery);
    // setAdminId(thirdQuery);
  }

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if(path.includes('/detail')){
      setValue('details')
    }
    if(path.includes('/video')){
      setValue('videos')
    }
    if(path.includes('/subscribe')){
      setValue('subscribers')
    }
    if(path.includes('/series')){
      setValue('series')
    }
    if(path.includes('/movie')){
      setValue('movies')
    }
    // .slice(-3)
  };

  //   validateImage(value) {
  //     if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
  //       this.setState({ invalidFile: "Please select valid image." });
  //       // setInvalidFile("Please select valid image.");
  //       return false;
  //     } else {
  //       console.log(value);
  //       this.setState({ profile_pic: value });
  //       this.setState({ invalidFile: false });
  //     }
  //   }

  async function getChannelDetailsLoad() {
    setFetchingChannel(true);
    // console.log(` 1 ${fetchingChannel}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/channels/details/admin/${slug}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data.subscribers);
        // console.log(data)
        // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
        setChannels(resp.data.channel);
        setChannelId(resp.data.channel.id);
        setChannelSubscribers(resp.data.channel.subscribers);
        setChannelMovies(resp.data.channel.admin_movies);
        setChannelVideos(resp.data.channel.admin_videos);
        setChannelSeries(resp.data.channel.admin_series);
        // setUserPosts(resp.data.posts);
        // setFollowing(resp.data.followings.length);

        setFetchingChannel(false);
        console.log(resp.data.channel.id)
        //  console.log(` 2 ${fetchingChannel}`)
        // } else {
        //   setFetchingChannel(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingChannel(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getChannelDetails() {
    // setFetchingChannel(true);
    // console.log(` 1 ${fetchingChannel}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/channels/details/admin/${slug}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data.subscribers);
        // console.log(data)
        // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
        setChannels(resp.data.channel);
        setChannelId(resp.data.channel.id);
        setChannelSubscribers(resp.data.channel.subscribers);
        setChannelMovies(resp.data.channel.admin_movies);
        setChannelVideos(resp.data.channel.admin_videos);
        setChannelSeries(resp.data.channel.admin_series);
        // setUserPosts(resp.data.posts);
        // setFollowing(resp.data.followings.length);

        // setFetchingChannel(false);
        //  console.log(` 2 ${fetchingChannel}`)
        // } else {
        //   // setFetchingChannel(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        // setFetchingChannel(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  // const getPathname = () => {
  //   console.log(`path name ${location.pathname.slice(-3)}`);
  // };
  function callback(k) {
    console.log(k);
    setKey(k);
    {
      console.log(`hello ${key}`);
    }
  }

  useEffect(() => {
    // getQuery()
    getChannelDetailsLoad();
    getPathname();
    // checkUserId();
  }, []);

  if (setFetchingChannel == true) {
    return (
      <>
        <Load />
      </>
    );
  } else {
  return (
    <>
      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        {error ? (
          <div>
            <br />
            <Alert
              severity="error"
              onClose={handleClose}
              action={
                <>
                  <CancelIcon onClick={closeError} />
                </>
              }
            >
              There was an issue fetching your channel details. Kindly refresh
              or check your network [ {errorMessage} ]
            </Alert>
            {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
            <br />
          </div>
        ) : (
          ""
        )}

        <Box sx={{ bgcolor: "background.paper" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
              <TabList
                // value={key}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                // centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
                style={{ marginleft: 20}}
              >
                <Tab value="details" label="Details"></Tab>
                <Tab value="subscribers" label="Subscribers"></Tab>
                <Tab value="videos" label="Videos"></Tab>
                <Tab value="movies" label="Movies"></Tab>
                <Tab value="series" label="Series"></Tab>
              </TabList>
            </Box>

            <TabPanel value="details">
              <br />
              <div className="row">
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  <ChannelDetails channel={props.channel} admin={props.admin} />
                  {/* <Profilephoto text="Latest Pictures" /> */}
                  {/* <Events /> */}
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  {/* <Createpost /> */}
                  <CreatePost2 type="channel" channelId={channels.id} pageId='' functionProp={props.functionProp} />
                  <ChannelPosts
                    channelName={channels.name}
                    posts={props.posts}
                    type='channel'
                    functionProp={props.functionProp}
                    user_name={`${channels.name} admin`}
                    user_id={props.adminId}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel value="subscribers">
              <br />
              <h5 className="text-center"> Your Channel Subscribers </h5>
              <ChannelSubscribers
                subscribers={channelSubscribers}
                channelId={channels.id}
                functionProp={() => getChannelDetails()}
              />
              {/* <Profilephoto text="Your Pictures" /> */}
            </TabPanel>

            <TabPanel value="videos">
              <br />
              <div style={{ marginLeft: 20 }}>
              {channels.is_approved == "false" ? (
                    <>
                      <h6 classsName="text-center text-green">
                        {" "}
                        Welcome {channels.name} Admin! Button to create contents will be
                        available here once your account has been verified{" "}
                      </h6>
                    </>
                  ) : (
                <ChannelsVideoModal
                  channelId={channels.id}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
                  )}
                {/* </Dialog>{" "} */}
                <br />
                <h5 className="text-center">
                 Quick Tips -> If you are using your mobile device, for a better user experience, click open in desktop or use your pc <br/>

                 -> To create a video all you need are your pictures and video content. Trailers are optional<br/>
                 <br/> -> You can add casts to your video after you have created it.
                  Simply click the add user icon. 
                </h5>
                <br />
                <MovieGrid
                  content={channelVideos}
                  type="Video"
                  url="video"
                  channelId={channels.id}
                  channelSlug={props.channel.slug}
                  isAdmin="true"
                  adminId={props.adminId}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
              </div>
            </TabPanel>

            <TabPanel value="movies">
              {/* <br/> */}
              <div style={{ marginLeft: 20 }}>
              {channels.is_approved == "false" ? (
                    <>
                      <h6 classsName="text-center text-green">
                        {" "}
                        Welcome {channels.name} Admin! Button to create contents will be
                        available here once your account has been verified{" "}
                      </h6>
                    </>
                  ) : (
                <ChannelsMoviesModal
                  channelId={channels.id}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
                  )}

                <br />
                <h5 className="text-center">
                Quick Tips -> If you are using your mobile device, for a better user experience, click open in desktop or use your pc <br/>

                 -> To create a movie all you need are your pictures and video content. Trailers are optional <br/>
                 <br/> -> You can add casts to your movie after you have created it.
                  Simply click the add user icon. 
                </h5>
                <br />

                <MovieGrid
                  content={channelMovies}
                  type="Movie"
                  url="movie"
                  channelId={channels.id}
                  channelSlug={props.channel.slug}
                  isAdmin="true"
                  adminId={props.adminId}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
              </div>
            </TabPanel>
            <TabPanel value="series">
              {/* <br/> */}
              <div style={{ marginLeft: 20 }}>
              {channels.is_approved == "false" ? (
                    <>
                      <h6 classsName="text-center text-green">
                        {" "}
                        Welcome {channels.name} Admin! Button to create contents will be
                        available here once your account has been verified{" "}
                      </h6>
                    </>
                  ) : (
                <ChannelsSeriesModal
                  channelId={channels.id}
                  // functionProp={() => getChannelDetails()}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
                  )}
                {/* </Dialog>{" "} */}
                <br />
                <h5 className="text-center">
                Quick Tips -> If you are using your mobile device, for a better user experience, click open in desktop or use your pc <br/>
                
                  -> To create a series all you need are your pictures and video content. Trailers are optional <br/>
                 <br/> -> You can add casts to your series after you have created it.
                  Simply click the add user icon. 
                </h5>
                <br />

                <SeriesCardChannels
                  series={channelSeries}
                  type="Series"
                  url={`series`}
                  channelId={channelId}
                  channelSlug={props.channel.slug}
                  isAdmin="true"
                  adminId={props.adminId}
                  functionProp={() => getChannelDetails()}
                  functionProp2={props.functionProp}
                />
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>

      <CreatePostFab type="channel" channelId={channels.id}  pageId='' functionProp={props.functionProp} />
      <CreatePostFab type="channel" channelId={channels.id}  pageId='' functionProp={props.functionProp}/>
    </>
  );
  }
}
