import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class TwoImages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    // console.log(`props image are ${JSON.stringify(this.props.images)}`)
  const { photoIndex, isOpen } = this.state;

  return (
    <>
        <div>
          <div className="grid grid-cols-2 gap-2 p-2">
            {/* <a href="assets/images/person/9.jpeg" className="col-span-2">
              <img
                src="assets/images/person/9.jpeg"
                alt=""
                // className="rounded-md w-full h-full"
                className="rounded-md w-full lg:h-76 object-cover"
              />
            </a> */}
            <a onClick={() => this.setState({ isOpen: true })}>
              <img
                src={this.props.images[0].url}
                alt=""
                className="rounded-md w-full h-full"
              />
            </a>
            <a onClick={() => this.setState({ isOpen: true })} className="relative">
              <img
                src={this.props.images[1].url}
                alt=""
                className="rounded-md w-full h-full"
              />
              <div className="absolute bg-gray-900 bg-opacity-10 flex justify-center items-center text-white rounded-md inset-0 text-2xl">
                {" "}
                view full{" "}
              </div>
            </a>
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={this.props.images[photoIndex].url}
              nextSrc={this.props.images[(photoIndex + 1) % this.props.images.length]}
              prevSrc={this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % this.props.images.length,
                })
              }
              imageCaption={this.props.text}
              imageTitle={this.props.text}
            />
          )}
        </div>
    </>
  );
}
}
