import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Alert from "@mui/material/Alert";
import { Route, useParams, Link, useLocation } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "../components/Load";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import { Snackbar } from "@material-ui/core";

// class Followers extends Component {
export default function Following() {
  const { userName } = useParams(["userName"]);
  const [userId, setUserId] = useState("");
  const authId = useState(window.localStorage.getItem("user_id"));
  const [unFollowedUser, setUnFollowedUser] = useState(false);
  const [fetchingFollowing, setFetchingFollowing] = useState(false);
  const [unFollowing, setUnFollowing] = useState(false);
  const [following, setFollowing] = useState([]);
  const [authFollowing, setAuthFollowing] = useState([]);
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [friendUserName, setFriendUserName] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adding, setAdding] = useState(false);
  const [addingMessage, setAddingMessage] = useState("Follow User");
  const [isSearching, setIsSearching] = useState(false);
  const [addedFriend, setAddedFriend] = useState(false);
  // const [action, setAction] = useState([]);

  // const user_name = useLocation()
  // const [alertId, setAlertId] = useState(id.state.id);

  async function test() {
    console.log("test");
  }

  async function getProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    // console.log(`state data = ${JSON.stringify(user_name.state.user_name)}`)
    axiosFetch
      .get(`/users/show/${userName}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.id);
        setUserId(resp.data.user.id);
        // console.log(userID, userUN, userFN, userLN);
        //
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function getFollowing() {
    setFetchingFollowing(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/${userName}/following`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(`followers 1 ${resp.data}`)
        console.log(JSON.stringify(resp.data));
        // console.log(`followers 2 ${data2}`)
        setFollowing(resp.data);
        // setAction(resp.data.action);
        setFetchingFollowing(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  }

  function followNewUser(user_name) {
    setAdding(true);
    setAddingMessage("Following User");
    // console.log(user_name);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setError(true);
          setErrorMessage(resp.data.message);
          setAdding(false);
          setAddingMessage("Try Again");
          // console.log('you have been blocked')
        } else {
          setAddedFriend(true);
          getFollowing();
          setUser("");
          setLastName("");
          // setFriendUserName("");
          // getCloseFriends();
          console.log("added friend");
        }
      })
      .catch((error) => {
        setAdding(false);
        // setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }

  function searchUsers(searchText, results) {
    const access_token = window.localStorage.getItem("access_token");
    setIsSearching(true);
    let result = axiosFetch
      .get(`/users/search/${searchText}`, {
        // .get("https://alert-lumen-api.herokuapp.com/api/users", {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setIsSearching(false);
        // setItems(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function redirect() {
    setAddedFriend(false);
    // history.push("/closefriends");
  }

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  const handleOnSearch = (string, results) => {
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };
  const handleOnSelect = (item) => {
    // the item selected
    console.log("selesct" + JSON.stringify(item));
    // setUserId(item.id);
    setUser(item.first_name);
    setLastName(item.last_name);
    setFriendUserName(item.user_name);
  };
  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAddedFriend(false);
    setError(false);
  };

  async function unFollowUser(user_name) {
    setUnFollowing(true);
    console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    let result = await axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // console.log(`unfollow ${resp.data}`);
        // const data2 = JSON.stringify(resp.data);
        // console.log(`unfollow 1 ${data2}`);
        setUnFollowedUser(true);
        getFollowing();
        setUnFollowing(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  function checkUserId() {
    console.log(authId[0]);
  }

  function checkIfUserIsFollowing() {
    const userFollowers = following.filter((value) =>
      authFollowing.includes(value)
    );

    console.log(`checking followings ${userFollowers}`);
  }

  useEffect(() => {
    getFollowing();
    // getAuthFollowing();
    getProfile();
    checkUserId();
    // checkIfUserIsFollowing();
  }, []);

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  if (fetchingFollowing == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Friends " + userName + " is Following"}
                      userName={userName}
                      url="followers"
                      text="View Followers"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <Load />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  }
  if (following.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Friends " + userName + " is Following"}
                      userName={userName}
                      url="followers"
                      text="View Followers"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                      <div className="card-body p-0">
                        {authId[0] == userId ? (
                          <>
                            <h3
                              className="middle-wrap fw-600 text-grey-900"
                              style={{ textAlign: "center", lineHeight: 2 }}
                            >
                              Hello {userName}! You are not following anyone
                              yet. Search for friends to follow
                            </h3>

                            <br />
                            <div
                              style={{ width: 300, marginTop: 10 }}
                              className="d-block w-100 z-index-1"
                            >
                              {isSearching ? (
                                <p className="text-current"> Searching ...</p>
                              ) : (
                                ""
                              )}
                              <ReactSearchAutocomplete
                                items={items}
                                onSearch={searchUsers}
                                // onHover={handleOnHover}
                                onSelect={handleOnSelect}
                                // onFocus={handleOnFocus}
                                // autoFocus
                                placeholder="search for friends"
                                formatResult={formatResult}
                                fuseOptions={{
                                  keys: [
                                    "first_name",
                                    "user_name",
                                    "last_name",
                                  ],
                                }}
                                resultStringKeyName="user_name"
                              />
                            </div>
                            <br />
                            {user ? (
                              <>
                                <br />
                                <h4>
                                  {" "}
                                  You've selected {user} {lastName}{" "}
                                </h4>
                                {adding ? (
                                  <Button disabled>{addingMessage}</Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      followNewUser(friendUserName)
                                    }
                                  >
                                    {addingMessage}
                                  </Button>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <br />
                          </>
                        ) : (
                          <h3
                            className="middle-wrap fw-600 text-grey-900"
                            style={{ textAlign: "center", lineHeight: 2 }}
                          >
                            Hello! {userName} is not following anyone yet. Check
                            Back Later
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={"Friends " + userName + " is Following"}
                    userName={userName}
                    url="followers"
                    text="View Followers"
                  />

                  <br />
                  <div
                    style={{ width: 300, marginTop: 10 }}
                    className="d-block w-100 z-index-1"
                  >
                    {isSearching ? (
                      <p className="text-current"> Searching ...</p>
                    ) : (
                      ""
                    )}
                    <ReactSearchAutocomplete
                      items={items}
                      onSearch={searchUsers}
                      // onHover={handleOnHover}
                      onSelect={handleOnSelect}
                      // onFocus={handleOnFocus}
                      // autoFocus
                      placeholder="search for friends"
                      formatResult={formatResult}
                      fuseOptions={{
                        keys: ["first_name", "user_name", "last_name"],
                      }}
                      resultStringKeyName="user_name"
                    />
                  </div>
                  <br />
                  {user ? (
                    <>
                      <br />
                      <h4>
                        {" "}
                        You've selected {user} {lastName}{" "}
                      </h4>
                      {adding ? (
                        <Button disabled>{addingMessage}</Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => followNewUser(friendUserName)}
                        >
                          {addingMessage}
                        </Button>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  <br />

                  <div className="row ps-2 pe-2">
                    {following.map((user, index) => (
                      <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                              <img
                                onError={fixImage}
                                src={user.user.profile_pic}
                                alt="avater"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                              />
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">
                              {user.user.first_name} {user.user.last_name}{" "}
                              
                            </h4>
                            <Link to={`/profile/${user.user.user_name}`}>
                              <p className="fw-500 text-primary mt-0 mb-3">
                                @{user.user.user_name} 
                              </p>
                              {/* <p> hiii   {user.action} </p> */}
                            </Link>

                            {/* {test()} */}
                            {authId[0] == user.user.id ? (
                              <>
                                <p className="fw-200 text-grey-500 mt-0 mb-3">
                                  {" "}
                                  {userName} Follows You!{" "}
                                </p>
                              </>
                            ) : authId[0] == userId ? (
                              <>
                                {unFollowing ? (
                                  <a
                                    disabled
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    Just A Sec ...
                                  </a>
                                ) : (
                                  <a
                                    onClick={() =>
                                      unFollowUser(user.user.user_name)
                                    }
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    {user.action} USER
                                    {/* means auth id = the user i params */}
                                  </a>
                                )}
                              </>
                            ) : (
                              <>
                                {unFollowing ? (
                                  <a
                                    disabled
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    Just A Sec ...
                                  </a>
                                ) : (
                                  <a
                                    onClick={() =>
                                      unFollowUser(user.user.user_name)
                                    }
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    {user.action} USER
                                    {/* means auth id = the user i params */}
                                  </a>
                                )}
                              </>
                            )}

                            {/* <Alert variant="filled" severity="success">
                            You've unfollowed {user.user.user_name} successfully!
                          </Alert> */}

                            {/* {authId[0] == userId ? (
                           ''
                          ) : (
                            <a
                              onClick={() => unFollowUser(user.user_name)}
                              className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                            >
                              FOLLOW @{user.user_name} 
                            </a>
                          )} */}
                          </div>
                        </div>
                      </div>
                    ))}

                    {addedFriend ? (
                      <>
                        {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                          <Alert
                            onClose={handleClose}
                            variant="filled"
                            severity="success"
                          >
                            You're Now Following @{friendUserName}!
                          </Alert>
                        {/* </Snackbar> */}
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {/* {!unFollowedUser ? (
                      ""
                    ) : (
                      <Alert
                        variant="filled"
                        severity="success"
                        onClose={() => {
                          setUnFollowedUser(false);
                        }}
                      >
                        User unfollowed successfully!
                      </Alert>
                    )} */}

                    <br />
                    {error ? (
                      <div>
                        <Alert severity="error" onClose={handleClose}>
                          {errorMessage}
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
  // }
}

// export default Followers;
//
