import { Component, useState, useEffect } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import {
  Button,
  Container,
  Fab,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
  Paper,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { Add as AddIcon } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import InputLabel from "@material-ui/core/InputLabel";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
// import { Select } from "antd";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Test3 from "./Test3";
import Chip from "@material-ui/core/Chip";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Resizer from "react-image-file-resizer";
import Bolt from "@mui/icons-material/Bolt";

import BoltIcon from "@mui/icons-material/Bolt";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { Upload } from "@aws-sdk/lib-storage";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const useStyles = makeStyles((theme) => ({
  fab: {
    // position: "absolute",
    position: "fixed",
    bottom: 160,
    right: 20,
  },
  container: {
    width: 500,
    overflow: "scroll",
    height: 600,
    backgroundColor: "white",
    position: "absolute",
    top: 30,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
      height: "90vh",
    },
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    maxHeight: 320,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  input: {
    display: "none",
  },
  textInput: {
    color: "red",
    // fontSize: 4
  },
  select: {
    width: "80%",
    backgroundColor: "#fff",
  },
}));

function getSteps() {
  return [
    "Select campaign settings",
    "Create an ad group",
    "Create an ad group",
    // "Create an ad",
    // "last",
  ];
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateAlertFab = (props) => {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [location, setLocation] = useState("");
  const [alertCategoryName, setAlertCategoryName] = useState("");
  const [alertTags, setAlertTags] = useState([]);
  const [alertTagsName, setAlertTagsName] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [uploadPictures, setUploadPictures] = useState(false);
  const [uploadVideos, setUploadVideos] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [adding, setAdding] = useState(false);
  const [addingMessage, setAddingMessage] = useState("Add Friend");
  const [addedFriend, setAddedFriend] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);

  const [headline, setHeadline] = useState("");
  const [url, setUrl] = useState("");
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);

  // remains type and status
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");

  const [alertImage, setAlertImage] = useState([]);
  const [alertVideo, setAlertVideo] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  // const [alertImage, setAlertImage] = useState("");
  const [open, setOpen] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [friendLimit, setFriendLimit] = useState(false);
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [friendUserName, setFriendUserName] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);
  // const [userName, setUserName] = useState("");

  const bodyLocal = window.localStorage.getItem("body");
  const headlineLocal = window.localStorage.getItem("headline");

  const firstName = window.localStorage.getItem("first_name");
  const userFriendsCount = window.localStorage.getItem("friends");
  const userName = window.localStorage.getItem("user_name");
  const userPic = window.localStorage.getItem("profile_pic");

  const [sendToFriends, setSendToFriends] = useState("No");
  const [openAlert, setOpenAlert] = useState(false);

  const [creatingTag, setCreatingTag] = useState(false);
  const [creatingAlert, setCreatingAlert] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  //state for alert categories mapping
  const [allCategories, setAllCategories] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const ip_address = window.sessionStorage.getItem("ip");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const [showLive, setShowLive] = useState(false);
  const [showUrl, setShowUrl] = useState(false);

  // const [userPic, setUserPic] = useState("");
  // const [userFriendsCount, setUserFriendsCount] = useState("");
  const history = useHistory();
  const [invalidFile, setInvalidFile] = useState(false);

  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const { Option } = Select;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  // const [age, setAge] = React.useState('');
  const animatedComponents = makeAnimated();
  const options = [
    { value: "alert", label: "Alert" },
    { value: "emergency", label: "Emergency" },
    { value: "lagos-state", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];

  const childrenTag = [];
  {
    allTags.map((tag) =>
      childrenTag.push(<Option key={tag.name}> {tag.name} </Option>)
    );
  }

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      // setChecked2(true);
    } else {
      setShowLive(false);
      // setChecked2(false);
    }
  };
  const toogleUrlVid = (event) => {
    if (showUrl == false) {
      setShowUrl(true);
      // setChecked2(true);
    } else {
      setShowUrl(false);
      // setChecked2(false);
    }
  };

  const sendToFriendsSelected = (event) => {
    if (sendToFriends == "No") {
      setSendToFriends("yes");
    } else {
      setSendToFriends("No");
    }
    setChecked(event.target.checked);
    // setSendToFriends("yes");
    console.log("sending to friends " + sendToFriends);
  };

  const ChangeAlertCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setAlertCategory(value.id);
      setAlertCategoryName(value.name);
      console.log(alertCategory);
      console.log(alertCategoryName);
    } else {
      setAlertCategory("");
      setAlertCategoryName("");
      console.log("value empty");
      getTags();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setEmptyFields(false);
    setAddedFriend(false);
    setFriendLimit(false);
    setCreatedCat(false);
    setError(false);
  };

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  //stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleSkip = () => {
    setActiveStep(3);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setProcessed(false);
    setOpen(false);
    // history.go(`/`);
    props.functionProp();
    props.functionProp2();
  };
  const cancelCreation = () => {
    setActiveStep(0);
    setOpen(false);
    // history.go(`/`);
  };

  const goBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOpenAlert(true);
  };

  async function validateImage(e, value) {
    setAlertImage([...e]);
    alertImage.push(e);
    for (let i = 0; i < alertImage[0].length; i++) {
      alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
    }
    setAlertImage(alertImageUrls);
  }
  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  async function onChange3(event) {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  }

  function processImage(e) {
    console.log(`proceesss ${JSON.stringify(e.target.files)}`);
    if (alertImage.length < 1 || alertImage.length > 4) {
      // alert('you can only upload 1 - 4 images')
      return;
    }
    const newImagesUrl = [];
    {
      e.target.files.map(
        (alertImage) =>
          // newImagesUrl.push(URL.createObjectURL(alertImage))
          alertImageUrls.push(URL.createObjectURL(alertImage))
        // alertTagsName.push(tag.name)
      );
    }
    {
      e.target.files.map(
        (alertImage) =>
          // newImagesUrl.push(URL.createObjectURL(alertImage))
          alertImage.push(URL.createObjectURL(alertImage))
        // alertTagsName.push(tag.name)
      );
    }
    // alertImage.forEach(alertImage => newImagesUrl.push(URL.createObjectURL(alertImage)));
    // setAlertImageUrls(newImagesUrl)
    // setAlertImage(newImagesUrl)
  }

  async function validate() {
    console.log(`tags ${alertTagsName}`);
    console.log(`headline ${headline}`);
    console.log(`image1 ${JSON.stringify(images)}`);
    console.log(`image2 ${alertImage}`);
    console.log(`send to friend ${sendToFriends}`);
    // alert(alertCategory)
    //  alert(alertCategoryName)
    if (!headline || !body || !alertCategory || !location) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      uploadVideo();
      // createAlert();
    }
  }

  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingAlert(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "alerts/videos/" + user_id + "/" + video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          // setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            // setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            createAlert();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createAlert()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingAlert(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      createAlert();
      console.log("video very empty");
    }
  }

  async function createAlert() {
    setCreatingAlert(true);
    setCreatingMessage("Creating Your Alert, almost done");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", alertCategory);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    //THIS IS WORKING TOO
    // images.map((image) => {
    // formData.append('image[]', image)
    // console.log(`hjsimage ${JSON.stringify(image)}`)
    // });
    //THIS IS WORKING TOO
    // for(var a = 0; a<images.length; a++)
    // {
    //   // const fd = new FormData();
    //   //console.log(images[a])
    //   formData.append('image[]', images[a]['file']);
    //   console.log(`oluuu`)
    // }
    // formData.append("image[]", images);
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }

    formData.append("tags", alertTagsName);
    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "alert");
    formData.append("status", "active");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setCreatingAlert(false);
          props.functionProp();
          props.functionProp2();
          setOpenAlert(true);
          setHeadline("");
          setBody("");
          setAlertTagsName([]);
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("postVideo");
          localStorage.removeItem("body");
          localStorage.removeItem("headline");
          setAlertCategory("");
          setAlertCategoryName("");
          setUrl("");
          setLocation("");
          setVideo("");
          setSelectedTags([]);
          setSendToFriends("No");
          setFriendUserName("");
          setUser("");
          setLastName("");
          setChecked(false);
          setChecked2(false);
          setShowLive(false);
          setShowUrl(false);
          setImages([]);
          setAlertImage([]);
          setAlertImageUrls([]);
          setProcessed(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingAlert(false);
          setProcessed(false);
        }
      })
      .catch((error) => {
        setCreatingAlert(false);
        setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
  }

  function showImages(e) {
    console.log(e.target.files);
    setAlertImage(e.target.files);
    console.log(`alert Image: ${alertImage}`);
  }

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/alert_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getAlertCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function getTags() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/tags", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts tags: ${JSON.stringify(result)}`);
        setAllTags(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function ChangeAlertTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => alertTagsName.push(tag.value));
    console.log(`name ${alertTagsName}`);

    setSelectedTags([...new Set(alertTagsName)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  function handleTagChange(value) {
    console.log(`selected ${value}`);
  }

  async function createTags() {
    setCreatingTag(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/tags",
        {
          method: "POST",
          name: newTag,
          // status: 'active'
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        // alertTagsName.push(newTag);
        console.log(newTag);
        setNewTag("");
        getTags();
        setCreatingTag(false);
        // alert("Tag Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function searchUsers(searchText, results) {
    setSearching(true);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .get(`/users/search/${searchText}`, {
        // .get("https://alert-lumen-api.herokuapp.com/api/users", {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setSearching(false);
        // setItems(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function redirect() {
    setAddedFriend(false);
    history.push("/closefriends");
  }

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  const handleOnSearch = (string, results) => {
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };
  const handleOnSelect = (item) => {
    // the item selected
    console.log("selesct" + JSON.stringify(item));
    setUserId(item.id);
    setUser(item.first_name);
    setLastName(item.last_name);
    setFriendUserName(item.user_name);
  };
  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };
  function addFriend(user_name) {
    setAdding(true);
    setAddingMessage("Adding Friend");
    // console.log(user_name);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/befriend/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setFriendLimit(true);
          setErrorMessage(resp.data.message);
          setAdding(false);
          setAddingMessage("Limit Reached");
          // console.log('you have been blocked')
        } else {
          setAddedFriend(true);
          setFriendLimit("");
          setLastName("");
          setUser("");
          getProfile();
          // getCloseFriends();
          console.log("added friend");
        }
      })
      .catch((error) => {
        setAdding(false);
        // setProcessed(false);
        console.log(error);
        setFriendLimit(true);
        setErrorMessage2(error.message);
      });
  }
  function getProfile() {
    setAddingMessage("Almost Done ...");
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        window.localStorage.setItem("friends", resp.data.friends);

        setAdding(false);
        setAddingMessage("Add Another Friend");
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function getLocation() {
    if ("geolocation" in navigator) {
      console.log("Location is Available");
    } else {
      console.log("Location is Not Available");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Lat is :", position.coords.latitude);
      console.log("Lon is :", position.coords.longitude);
      window.sessionStorage.setItem("lat", position.coords.latitude);
      window.sessionStorage.setItem("lon", position.coords.longitude);
    });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("full posoition " + position);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        // console.log("Watch Lat is :", position.coords.latitude);
        // console.log("Watch Lon  is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
  }

  useEffect(() => {
    getLocation();
    watchLocation();
    getTags();
    getAlertCategories();

    if (bodyLocal) {
      setBody(bodyLocal);
    }
    if (headlineLocal) {
      setHeadline(headlineLocal);
    }
  }, [console.log(sendToFriends)]);

  // const renderPhotos = (source) => {
  //   return source.map((photo) => {
  //     return (
  //       <img
  //         className="p-2"
  //         src={photo}
  //         alt=""
  //         ket={photo}
  //         style={{ width: "20%", height: "180px" }}
  //       />
  //     );
  //   });
  // };
  // }, [allCategories]);
  //  console.log(`Alerts Categories State: ${alertCategory}`);
  // const { Option } = Select;
  return (
    <>
      <Tooltip
        title="Create An Alert"
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <Fab color="primary" className={classes.fab} style={{ zIndex: 30 }}>
          {/* <img src="/assets/images/alert-fab3.webp" /> */}
          <img src="/assets/images/alert-fab3.webp" />
          {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
        </Fab>
      </Tooltip>
      <Modal open={open} className={classes.modalStyle}>
        <form
          className={classes.form}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <Container className={classes.container}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              // style={{ width: "95vw" }}
            >
              {/* {steps.map((label, index) => ( */}

              <Step key="first">
                <StepLabel>
                  {" "}
                  Select What Your Alarrt Is About - Required
                </StepLabel>
                <StepContent>
                  {/* <form className={classes.form} autoComplete="off">  What Emergency Or Crisis Are You Alarrting ?*/}
                  <div className={classes.item}>
                    <FormControl required className={classes.select}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                       Hi {firstName}! What Emergency Or Crisis Are You
                        Alarrting? Select One / Create New Below
                    </Typography>
                     
                      <br />
                      <Autocomplete
                        id="combo-box-demo"
                        options={allCategories}
                        onChange={ChangeAlertCategory}
                        // onChange={(e) => setAlertCategoryName(e.target.value.name) }
                        inputValue={alertCategoryName}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#fff", width: "100%" }}>
                            {children}
                          </Paper>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Category"
                            variant="standard"
                            style={{ backgroundColor: "pink !important" }}
                          />
                        )}
                        required
                      />
                    </FormControl>

                    {/* <div className={classes.actionsContainer}> */}
                    <TextField
                      id="standard-basic"
                      label="Or Create New"
                      size="small"
                      // style={{width:'40%'}}
                      style={{ width: "100%" }}
                      onChange={(e) => setNewCategory(e.target.value)}
                      value={newCategory}
                    />

                    <FormHelperText>
                      Select Existing Or Create New Incident / Information Type
                    </FormHelperText>

                    {!newCategory ? (
                      <>
                        <br />
                        <br />
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          // component="span"
                          style={{ marginLeft: 7 }}
                        >
                          Type First
                        </Button>
                      </>
                    ) : (
                      <>
                        {creatingCat ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginTop: 7 }}
                          >
                            Creating New Category
                          </Button>
                        ) : (
                          <>
                            <Button
                              onClick={createCategory}
                              variant="contained"
                              color="primary"
                              component="span"
                              style={{ marginTop: 7 }}
                            >
                              Create Category
                            </Button>
                            {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                          </>
                        )}
                      </>
                    )}
                    {/* </div> */}
                  </div>
                  {createdCat ? (
                    <>
                      <Snackbar
                        open={createdCat}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Alert onClose={handleClose} severity="success">
                          Category successfully created. Select it above
                        </Alert>
                      </Snackbar>
                    </>
                  ) : (
                    ""
                  )}

                  {!alertCategoryName ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        Hi {firstName}! Select your Alarrt category to proceed
                      </Typography>
                      <br />
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            // disabled={activeStep === 0}
                            onClick={cancelCreation}
                            className={classes.button}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          // disabled={activeStep === 0}
                          onClick={cancelCreation}
                          className={classes.button}
                        >
                          Close
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? "Next" : "Next"}
                        </Button>
                      </div>
                    </div>
                  )}
                </StepContent>
              </Step>

              <Step key="second">
                <StepLabel> All fields are required here - Required</StepLabel>
                <StepContent>
                  <h5>
                    {" "}
                    Well done {firstName}! Thanks for alarrting us and the
                    public. Kindly let us know what is going on below
                  </h5>
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.postHead}
                  >
                    Summarize what is happening in the headline. Make it simple
                    and straight forward
                  </Typography> 
<br/>
                  <div className={classes.item}>
                    <TextField
                      id="standard-basic"
                      label={`Your Headline`}
                      onClick={() => {
                        // handleClickOpen()
                        getLocation();
                        watchLocation();
                      }}
                      required
                      size="small"
                      style={{ width: "100%", fontSize: 4, color: "red" }}
                      onChange={(e) => {
                        window.localStorage.setItem("headline", e.target.value);
                        setHeadline(e.target.value);
                        // setBody(e.target.value);
                      }}
                      defaultValue={window.localStorage.getItem("headline")}
                      value={headline}
                      // onChange={(e) => setHeadline(e.target.value)}
                      // value={headline}
                      LabelProps={{
                        className: classes.textInput,
                      }}
                    />
                  </div>

                  <div className={classes.item}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Give more details asides from your headline below. E.g How
                      critical is what is happening and any other important info
                      that will help
                    </Typography>
                    <div
                      className="card-body p-0 mt-3 position-relative"
                      style={{ width: "100%" }}
                    >
                      <figure className="avatar position-absolute ms-2 mt-1 top-5">
                        <img
                          src={userPic}
                          alt="icon"
                          className="shadow-sm rounded-circle w30"
                        />
                      </figure>
                      <textarea
                        onChange={(e) => {
                          window.localStorage.setItem("body", e.target.value);
                          setBody(e.target.value);
                        }}
                        defaultValue={window.localStorage.getItem("body")}
                        value={body}
                        style={{ paddingLeft: 50, paddingTop: 10 }}
                        // value={body}
                        name="message"
                        className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                        cols="30"
                        rows="10"
                        placeholder={`Tell us what is happening ${firstName}`}
                      ></textarea>
                    </div>
                    <br />
                    <TextField
                      id="standard-basic"
                      label={"Where is it happening?"}
                      // required
                      size="small"
                      style={{ width: "90%", fontSize: 4, color: "red" }}
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      LabelProps={{
                        className: classes.textInput,
                      }}
                    />
                    <FormHelperText>
                      Location helps everyone know where to avoid. Please Use
                      This Format: State->Location (e.g. Lagos->Ikeja)
                    </FormHelperText>
                  </div>

                  {/* </form> */}

                  <div className={classes.actionsContainer}>
                    {!headline || !body || !location ? (
                      <>
                        <p>
                          {" "}
                          Oops! To proceed, kindly inpute all fields. You can
                          upload related media in the next step or skip to
                          creating you alarrt
                        </p>
                        <br />
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          disabled
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Next
                        </Button>
                      </>
                    ) : (
                      <>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1 ? "Next" : "Next"}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSkip}
                            className={classes.button}
                          >
                            Skip
                          </Button>
                        </div>
                      </>
                    )}
                  </div>

                  {/* <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Close Modal
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Alert All" : "Next"}
                      </Button>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSkip}
                          className={classes.button}
                        >
                          Skip
                        </Button>
                    </div>
                  </div> */}
                </StepContent>
              </Step>

              {/* <Step key="third">
                <StepLabel> Add Alerts Location and Tags </StepLabel>
                <StepContent>
                 
                  <div className={classes.item}>
                    <FormControl required className="d-flex">
                      <TextField
                        id="standard-basic"
                        label={"Where is it happening?"}
                        // required
                        size="small"
                        style={{ width: "90%", fontSize: 4, color: "red" }}
                        onChange={(e) => setLocation(e.target.value)}
                        value={location}
                        LabelProps={{
                          className: classes.textInput,
                        }}
                      />
                      <FormHelperText>
                        Location is required Please Use This Format:
                        Country->State->Location (e.g. Nigeria->Lagos->Location
                      </FormHelperText>
                    
                      <br />
                      <FormHelperText>
                        OPTIONAL-> Tags helps us know more about your alert, and
                        helps other users search for alerts by tag. Tags you've
                        selected :
                      </FormHelperText>
                      {selectedTags.map((selectedTags) => (
                        <Chip
                          style={{ marginRight: 10, marginBottom: 5 }}
                          className="infotag pointer"
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={`${selectedTags}`}
                        />
                      ))}
                      <br />
                      <CreatableSelect
                        isClearable
                        isMulti
                        onChange={ChangeAlertTag}
                        components={animatedComponents}
                        options={options}
                        placeholder="Select or Create New Tags"
                        // setValue={selectedTags}
                      ></CreatableSelect>

                      <FormHelperText>
                        Type and click create to create new tag
                      </FormHelperText>

                      <div className={classes.item}>
                       
                        <TextField
                          id="standard-basic"
                          label={"https://.."}
                          // required
                          size="small"
                          style={{ width: "90%", fontSize: 4, color: "red" }}
                          onChange={(e) => setUrl(e.target.value)}
                          value={url}
                          LabelProps={{
                            className: classes.textInput,
                          }}
                        />
                        <br />
                        <FormHelperText>
                          Optional->Add External Source If You Found This Info
                          Somewhere Online. Adding link is optional - Start With
                          https://....
                        </FormHelperText>
                      </div>
                    </FormControl>
                  </div>
                  <div className={classes.actionsContainer}>
                    {!location ? (
                      <>
                        <p>
                          {" "}
                          To proceed, kindly type the location so people know
                          where it's happening{" "}
                        </p>
                        <br />
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                      </>
                    ) : (
                      <>
                        <div>
                      
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1 ? "Next" : "Next"}
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSkip}
                            className={classes.button}
                          >
                            Skip
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </StepContent>
              </Step> */}

              <Step key="third">
                <StepLabel>Upload Incident Media - Optional</StepLabel>
                <StepContent>
                  <div className={classes.item}>
                    <div>
                      <h4> Almost Done {firstName} </h4>
                    </div>
                    {/* <FormHelperText>
                      You can post pictures or reocrd a live video on what's
                      going now, or update your alarrt after creation to add
                      them
                    </FormHelperText> */}
                    
                         
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        You can post pictures or reocrd a live video on what's
                      going now, or update your alarrt after creation to add
                      them
                      </Typography>
                    

                    {uploadLimit ? (
                      <div>
                        <MuiAlert
                          severity="warning"
                          onClose={closeError}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum size allowed is 300 mb
                        </MuiAlert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                        <a
                          href="https://www.freeconvert.com/video-compressor"
                          target="_blank"
                          className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
                        >
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Quickly compress it
                          </span>{" "}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}

                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        uploadPictures
                          ? setUploadPictures(false)
                          : setUploadPictures(true);
                        // setUploadPictures(true)
                      }}
                      className={classes.button}
                    >
                      Upload Images About Incident
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={
                        () =>
                          uploadVideos
                            ? setUploadVideos(false)
                            : setUploadVideos(true)
                        // setUploadVideos(true)
                      }
                      className={classes.button}
                    >
                      Upload / Record Video
                    </Button>
                    {uploadPictures == false ? (
                      ""
                    ) : (
                      <>
                        {!alertImageUrls.length == 0 ? (
                          <>
                            <Typography
                              gutterBottom
                              variant="body1"
                              className={classes.postHead}
                            >
                              Slide to view all
                            </Typography>

                            <Carousel>
                              {alertImageUrls.map((slideImage, index) => (
                                <img
                                  src={slideImage}
                                  key={index}
                                  style={{ zIndex: -10 }}
                                  alt=""
                                />
                              ))}
                            </Carousel>

                            <Button
                              // onClick={() => setAlertImageUrls([])}
                              onClick={clearImages}
                              className={classes.button}
                            >
                              Clear Images
                            </Button>
                            {/* {/* <br /> */}
                          </>
                        ) : (
                          <>
                            <div className="col-lg-12 mb-3">
                              <div className="card mt-3 border-0">
                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                  <div className="form-group mb-0 w-100">
                                    <input
                                      type="file"
                                      name="file"
                                      accept="image/*"
                                      multiple
                                      id="file"
                                      className="input-file"
                                      // onChange={validateImage}
                                      onChange={(e) => {
                                        // if (e.target.files.length > 4) {
                                        //   setIsLimit(true);
                                        // } else {
                                        // setImages([...e.target.files]);
                                        console.log([...e.target.files]);
                                        validateImage(e.target.files);
                                        onChange3(e);
                                        // }
                                      }}
                                    />
                                    <label
                                      htmlFor="file"
                                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                    >
                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                      <span className="js-fileName">
                                        Drag and drop or click to add your alert
                                        image(s)
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <span
                              style={{
                                marginTop: 5,
                                display: "flex",
                                alignSelf: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                className="text-center"
                                color="primary"
                                onClick={() => setUploadPictures(false)}
                                className={classes.button}
                              >
                                Upload Images Later
                              </Button>
                            </span>
                          </>
                        )}
                      </>
                    )}

                    {isLimit ? (
                      <div>
                        <Alert
                          severity="warning"
                          onClose={handleClose}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum upload is 4 images, kindly reduce.
                        </Alert>
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {invalidFile ? <h4> {invalidFile} </h4> : ""}
                    <br />

                    {uploadVideos == false ? (
                      ""
                    ) : (
                      <>
                        <div>
                          <>
                            {videoUrl ? (
                              <>
                                <br />
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  className={classes.postHead}
                                >
                                  Your alert's video
                                </Typography>

                                <br />
                                <ReactPlayer
                                  controls={true}
                                  // width='70%'
                                  height="400px"
                                  url={videoUrl}
                                  config={{
                                    file: {
                                      attributes: {
                                        controlsList: "nodownload",
                                      },
                                    },
                                  }}
                                />
                                <br />
                                <br />
                                {creatingAlert ? (
                                  <Button disabled>{creatingMessage}</Button>
                                ) : (
                                  <Button
                                    onClick={
                                      () => {
                                        setVideoUrl("");
                                        setShowLive(false);
                                        setShowUrl(false);
                                      }
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Clear
                                  </Button>
                                )}

                                <br />
                              </>
                            ) : (
                              <>
                                {video ? (
                                  <>
                                    <br />
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      className={classes.postHead}
                                    >
                                      Your alert's video
                                    </Typography>

                                    <br />
                                    <video width="400" controls>
                                      <source
                                        src={URL.createObjectURL(video)}
                                      />
                                    </video>
                                    <br />
                                    <br />
                                    {creatingAlert ? (
                                      <Button disabled>
                                        {creatingMessage}
                                      </Button>
                                    ) : (
                                      <Button
                                        onClick={
                                          () => {
                                            setVideo("");
                                            setShowLive(false);
                                            setShowUrl(false);
                                          }
                                          //   this.setState({ profile_pic: "" })
                                        }
                                      >
                                        Remove
                                      </Button>
                                    )}

                                    <br />
                                  </>
                                ) : (
                                  <>
                                    {showUrl ? (
                                      <>
                                        <br />
                                        <Typography
                                          gutterBottom
                                          variant="body1"
                                          // className={classes.postHead}
                                        >
                                          You can upload a video from a url. If
                                          you wish to record a live video, use
                                          the record live video button instead
                                        </Typography>
                                        <br />
                                        <TextField
                                          //autoFocus
                                          margin="dense"
                                          id="name"
                                          label={`Your video's url`}
                                          value={videoUrl}
                                          // { window.localStorage.getItem("postVideo")}
                                          onChange={(e) => {
                                            // window.localStorage.setItem(
                                            //   "postVideo",
                                            //   e.target.value
                                            // )
                                            setVideoUrl(e.target.value);
                                          }}
                                          type="text"
                                          fullWidth
                                          variant="standard"
                                          style={{ width: "100%" }}
                                        />
                                        <br />
                                        <span
                                          style={{
                                            marginTop: 20,
                                            display: "flex",
                                            alignSelf: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <label htmlFor="contained-button-file-3">
                                            <Button
                                              variant="contained"
                                              component="span"
                                              color="primary"
                                              onClick={() => {
                                                toogleUrlVid();
                                                // setChecked2(true);
                                              }}
                                              // onClick={toogleLiveVid}
                                            >
                                              Cancel
                                            </Button>
                                            <br />
                                          </label>
                                        </span>
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        {showLive ? (
                                          <>
                                            <br />
                                            <Typography
                                              gutterBottom
                                              variant="body1"
                                              // className={classes.postHead}
                                            >
                                              You can take a live recording
                                              using either of your front or back
                                              camera. If you have a video
                                              recorded already, use the upload
                                              video button instead. Press "Rec" when ready and wait for the countdown
                                            </Typography>
                                            <br />
                                            <VideoRecorder
                                               isOnInitially = {true}
                                               countdownTime={1000}
                                              onRecordingComplete={(
                                                videoBlob
                                              ) => {
                                                // Do something with the video...
                                                console.log(
                                                  "videoBlob",
                                                  videoBlob
                                                );
                                                setVideo(videoBlob);
                                              }}
                                            />
                                            <br />
                                            <br />
                                          </>
                                        ) : (
                                          <>
                                            <div className="col-lg-12 mb-1">
                                              <div className="card mt-1 border-0">
                                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                                  <div className="form-group mb-0 w-100">
                                                    <input
                                                      type="file"
                                                      name="file3"
                                                      id="file3"
                                                      accept="video/*"
                                                      className="input-file"
                                                      onChange={(e) => {
                                                        if (
                                                          e.target.files[0]
                                                            .size >
                                                          300000 * 1028
                                                        ) {
                                                          setUploadLimit(true);
                                                        } else {
                                                          setUploadLimit(false);
                                                          setVideo(
                                                            e.target.files[0]
                                                          );
                                                        }
                                                      }}
                                                      // onChange={(e) => setVideo(e.target.files[0])}
                                                    />
                                                    <label
                                                      htmlFor="file3"
                                                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                                    >
                                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                      <span className="js-fileName">
                                                        Click to add your
                                                        alert's video
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <br /> */}
                                            {/* <br /> */}
                                          </>
                                        )}

                                        <span
                                          style={{
                                            marginTop: 20,
                                            display: "flex",
                                            alignSelf: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <label htmlFor="contained-button-file-3">
                                            <Button
                                              variant="contained"
                                              component="span"
                                              color="primary"
                                              onClick={() => {
                                                toogleLiveVid();
                                                // setChecked2(true);
                                              }}
                                              // onClick={toogleLiveVid}
                                            >
                                              Record Live Video
                                            </Button>
                                            <br />
                                          </label>
                                        </span>

                                        <span
                                          style={{
                                            marginTop: 20,
                                            display: "flex",
                                            alignSelf: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <label htmlFor="contained-button-file-3">
                                            <Button
                                              variant="contained"
                                              component="span"
                                              color="primary"
                                              onClick={() => {
                                                toogleUrlVid();
                                                // setChecked2(true);
                                              }}
                                              // onClick={toogleLiveVid}
                                            >
                                              Upload Video Url
                                            </Button>
                                            <br />
                                          </label>
                                        </span>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        </div>

                        <br />
                        <span
                          style={{
                            marginTop: 5,
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className="text-center"
                            variant="contained"
                            color="primary"
                            onClick={() => setUploadVideos(false)}
                            className={classes.button}
                          >
                            Upload Video Later
                          </Button>
                        </span>
                      </>
                    )}
                  </div>

                  {/* </form> */}
                  <div className={classes.actionsContainer}>
                    <div>
                      {/* <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Close
                      </Button> */}
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Next" : "Next"}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key="fourth">
                <StepLabel>Create Your Alarrt</StepLabel>
                <StepContent>
                  {/* <form className={classes.form} autoComplete="off"> */}

                  <div className={classes.item}>
                    <div>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        Be your friend's keeper! You Currently Have{" "}
                        <b>{userFriendsCount} out of 5</b> Close Friends{" "}
                        {firstName}. If you wish to notify your close friends
                        immediately, click the checkbox below. Adding close
                        friends ensures they get notified by SMS whenever you
                        create an alarrt or are in distress.
                      </Typography>

                      {userFriendsCount == 0 ? (
                        <>
                          <br />
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            className={classes.postHead}
                          >
                            Seems like you haven't added any close friend. Add
                            some now so they get notified via SMS when you
                            realert or create an alarrt. They also get notified
                            via SMS if you are in distress
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Checkbox
                            checked={checked}
                            onChange={sendToFriendsSelected}
                            label="Yes"
                            inputProps={{ "aria-label": "controlled" }}
                          />{" "}
                          <Typography
                            gutterBottom
                            variant="overline"
                            className={classes.postHead}
                          >
                            Alarrt Friends
                          </Typography>
                        </>
                      )}
                    </div>

                    {/* <hr /> */}
                    <br />
                    <FormHelperText>
                      Add Friends by searching below. If no record is found, it
                      means your friend doesn't have an account on alarrt yet.
                    </FormHelperText>
                    {/* <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.postHead}
                        >
                          Search for your friends with the search below. If no record is found, it means your friend doesn't have an account on alert yet.
                        </Typography> */}

                    <br />
                    <div
                      style={{ width: 300, marginTop: 10 }}
                      className="d-block w-100 z-index-1"
                    >
                      {searching ? (
                        <h4 className="text-current text-center">
                          {" "}
                          Searching ...{" "}
                        </h4>
                      ) : (
                        ""
                      )}
                      <ReactSearchAutocomplete
                        items={items}
                        onSearch={searchUsers}
                        // onHover={handleOnHover}
                        onSelect={handleOnSelect}
                        // onFocus={handleOnFocus}
                        // autoFocus
                        placeholder="search for friends"
                        formatResult={formatResult}
                        fuseOptions={{
                          keys: ["first_name", "user_name", "last_name"],
                        }}
                        resultStringKeyName="user_name"
                      />
                    </div>

                    {friendUserName ? (
                      <>
                        {" "}
                        <h4>
                          {" "}
                          You've selected {user} {lastName}{" "}
                        </h4>
                        {adding ? (
                          <Button disabled className={classes.button}>
                            {addingMessage}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => addFriend(friendUserName)}
                            className={classes.button}
                          >
                            {addingMessage}
                          </Button>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <br />

                    {friendLimit ? (
                      <div>
                        <Alert severity="error" onClose={handleClose}>
                          {errorMessage} [ {errorMessage2}]
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {addedFriend ? (
                      <>
                        {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                        <Alert
                          onClose={handleClose}
                          variant="filled"
                          severity="success"
                          action={
                            <>
                              {/* <Button
                                  onClick={redirect}
                                  style={{ color: "#fff", alignSelf: "center" }}
                                >
                                  {" "}
                                  View Close Friends
                                </Button> */}
                              <Button
                                onClick={handleClose}
                                style={{ color: "#fff", alignSelf: "center" }}
                              >
                                {" "}
                                Close
                              </Button>
                            </>
                          }
                        >
                          You've Successfully Added {friendUserName} To Your
                          Close Friends!
                        </Alert>
                        {/* </Snackbar> */}
                        <br />
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className={classes.actionsContainer}>
                    <div>
                      {/* <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Cancel
                      </Button> */}
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>

                      {/* {activeStep === steps.length - 1 ?  */}
                      {creatingAlert ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            // onClick={handleNext}
                            disabled
                            className={classes.button}
                          >
                            Creating Your Alarrt
                            {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                          </Button>
                          <br />
                          <h4>{creatingMessage}</h4>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={validate}
                          // onClick={uploadImage}
                          className={classes.button}
                        >
                          {/* {creatingAlert ? Alert All 2 : Creating Your Alert } */}
                          {activeStep === steps.length - 1
                            ? "Create Alarrt"
                            : "Create Alarrt"}
                        </Button>
                      )}
                      {/* onClick={() => setOpenAlert(true)} */}
                      {emptyFields ? (
                        <>
                          <br />
                          <br />
                          <Alert onClose={handleClose} severity="warning">
                            Oops! To create an alarrt, you need to ensure the
                            headline, body, location and category are not empty
                          </Alert>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
              {/* ))} */}
            </Stepper>
            {/* {activeStep === steps.length && ( */}

            {error ? (
              <div>
                <Alert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <a
                        onClick={handleBack}
                        className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                      >
                        Go Back
                      </a>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue creating your alarrt. Kindly try again [{" "}
                  {errorMessage} {errorMessage2} ]
                </Alert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
              </div>
            ) : (
              ""
            )}

            {processed ? (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>
                  {" "}
                  You are awesome {firstName}! Thank you for notifying us -
                  Notifications are being sent out and help will be dispatched
                  immediately if this is a life threatnening emergency. Invite your
                  friends and family with your referral link so you can add them
                  as your close friend! Close friends are notified by SMS
                  whenever you create an alarrt or are in distress. Go to your
                  profile to get your referral link now.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  // onClick={() => setOpen(false)}
                  onClick={handleReset}
                >
                  Go Back Home
                </Button>
                {/* <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
              </Paper>
            ) : (
              ""
            )}
            {/* )} */}
          </Container>
        </form>
      </Modal>

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Alarrt Created Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateAlertFab;
