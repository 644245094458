import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class SingleImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    // console.log(`props image are ${JSON.stringify(this.props.images)}`)
  const { photoIndex, isOpen } = this.state;
  return (
    <>

     <div>
          <div className="grid grid-cols-2 gap-2 p-2">
          <a onClick={() => this.setState({ isOpen: true })} className="col-span-2 data-uk-button data-uk-button-default" >
              <img
                 src={this.props.images[0].url}
                alt=""
                // className="rounded-md w-full h-full"
                className="rounded-md w-full lg:h-76 object-cover"
              />
            </a>
          
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={this.props.images[photoIndex].url}
              nextSrc={this.props.images[(photoIndex + 1) % this.props.images.length]}
              prevSrc={this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length]}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % this.props.images.length,
                })
              }
              imageCaption={this.props.text}
              imageTitle={this.props.text}
            />
          )}
        </div>

    </>
  );
}
}
