import React, { Component } from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import { Upload } from "@aws-sdk/lib-storage";
import { Typography } from "@material-ui/core";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Checkbox from "@mui/material/Checkbox";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export default function CreatePost2(props) {
  // const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const [showTags, setShowTags] = useState(false);
  const animatedComponents = makeAnimated();
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState("");
  let history = useHistory();
  const user_name = window.localStorage.getItem("user_name");
  const profile_pic = window.localStorage.getItem("profile_pic");
  const verified = window.localStorage.getItem("verified");
  const postBody = window.localStorage.getItem("postBody");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [compImages, setCompImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [postTags, setPostTags] = useState([]);
  const [postTagsName, setPostTagsName] = useState([]);
  const [alertImage, setAlertImage] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [emptyBody, setEmptyBody] = useState(false);
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [error, setError] = useState(false);
  const loopData = [];

  const Input = styled("input")({
    display: "none",
  });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setEmptyBody(false);
    setError(false);
  }

  const toogleTags = (event) => {
    if (showTags == false) {
      setShowTags(true);
    } else {
      setShowTags(false);
    }
    setChecked(event.target.checked);
  };

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  function handleClose2(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setIsSuccess(false);
    setBody("");
    localStorage.removeItem("postBody");
    setImage("");
    props.functionProp();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function ChangePostTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => postTagsName.push(tag.value));
    console.log(`name ${postTagsName}`);

    // setSelectedTags([...new Set(postTagsName)]);
    // console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }
  const options = [
    { value: "type-to", label: "Type to create new tag" },
    { value: "first-post", label: "First Post" },
    { value: "new-post", label: "New Post" },
  ];

  async function validateImage(e, value) {
    // setAlertImage([])
    setAlertImage([...e]);
    alertImage.push(e);
    for (let i = 0; i < alertImage[0].length; i++) {
      alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
    }
    setAlertImage(alertImageUrls);
    // onchange()
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  const onChange3 = async (event) => {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  function validate(e) {
    //    alert(this.state.body)
    if (body == "") {
      setEmptyBody(true);
      return;
    } else {
      // createPost();
      uploadVideo();
    }
  }

  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = {
        Bucket: "alert-app-v2",
        Key: video.name,
        Body: video,
        setVisibilty: "pubic",
      };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "posts/videos/" + user_id + "/" + video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = {
        Bucket: "alert-app-v2",
        Key: filePath,
        Body: video,
        visibility: "public",
        Visibility: "public",
        setVisibilty: "public",
      };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            createPost();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      createPost();
      console.log("video very empty");
    }
  }

  async function createPost() {
    setCreatingPost(true);
    console.log("creating post now ooo");
    setCreatingMessage("Creating Your Post, almost done");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    // console.log("uploading");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    // const userUN = await window.localStorage.getItem('user_name')

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("body", body);
    formData.append("channel_id", props.channelId);
    formData.append("communities_id", props.communityId);
    formData.append("page_id", props.pageId);
    formData.append("tags", postTagsName);
    // formData.append("communities_id", '');

    console.log(`form data image ${JSON.stringify(images)}`);
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }
    console.log("post file url is " + postVideoUrl);
    console.log("post video url is " + postVideoUrl);
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    formData.append("type", props.type);
    formData.append("status", "active");

    //     let token = document.head.querySelector('meta[name="csrf-token"]');

    // if (token) {
    //     // window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    //     console.log('gotten' + token)
    // } else {
    //     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
    // }
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log("percentage os " + percentCompleted);
      },
    };

    let result = await axiosFetch
      .post("/posts", formData, {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
          // 'x-csrf-token': token.content
          // document.querySelector("[name=csrf-token]").getAttribute('content')
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          setCreatingMessage("Post Created Successfully");
          console.log(resp.data);
          setAlertImageUrls([]);
          setAlertImage([]);
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("postVideo");
          setImages([]);
          setCompImages([]);
          setBody("");
          localStorage.removeItem("postBody");
          setCreatingPost(false);
          setIsSuccess(true);
          props.functionProp();
        } else {
          setCreatingPost(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function validateImage2(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select a valid image.");
      return false;
    } else {
      console.log(value);
      setImage(value);
      setInvalidFile(false);
    }
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    // getProfile();

    if (postBody) {
      setBody(postBody);
    }
    // else{
    //   setBody(e.target.value)
    // }
  }, [console.log(`fileurl ${fileUrl}  videourl ${videoUrl}`)]);

  // render() {
  const menuClass = `${isOpen ? " show" : ""}`;
  //    const {user_name} = this.state;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0">
        {/* {verified === "null" ? (
          <h4> Verify Your Email Now To Start Creating Posts And Much More!</h4>
        ) : (
          <> */}
        {props.type == "channel" || props.type == "page" || props.type == "community" ? (
          <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">
            Type what you want to share on your {props.type} {user_name}?{" "}
          </h3>
        ) : (
          ""
        )}

        {props.type == "post" ? (
          <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">
            What is on your mind {user_name}?{" "}
          </h3>
        ) : (
          ""
        )}
        {/* </>
      verified ends here  )} */}

        {/* <a onClick={this.createPost} className="font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center"> */}
        {/* <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Click To Create Post</a> */}
      </div>
      <div className="card-body p-0 mt-3 position-relative">
        <figure className="avatar position-absolute ms-2 mt-1">
          {/* top-5 */}
          {isFetching ? (
            <img
              className="shareProfileImg"
              src="/assets/images/user.png"
              alt=""
              style={{
                marginRight: 30,
                maxWidth: 35,
                height: 35,
                border: "2px solid #045de9",
              }}
            />
          ) : (
            <>
              <img
                src={profile_pic}
                alt="avater"
                className="shadow-sm rounded-circle w45"
                style={{
                  marginRight: 30,
                  maxWidth: 35,
                  height: 35,
                  border: "2px solid #045de9",
                }}
              />
              {/* </figure> */}
            </>
          )}
        </figure>
        <textarea
          name="message"
          style={{ paddingLeft: 50, paddingTop: 10 }}
          className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
          cols="20"
          rows="10"
          // style=
          placeholder="What's on your mind?"
          onChange={(e) => {
            window.localStorage.setItem("postBody", e.target.value);
            setBody(e.target.value);
          }}
          defaultValue={window.localStorage.getItem("postBody")}
          value={body}
          // this.setState({ isOpen: !this.state.isOpen })
        ></textarea>
      </div>

      <div className="card-body p-0 mt-1 mb-1">
        {showTags ? (
          <>
            <Typography
              gutterBottom
              variant="body1"
              // className={classes.postHead}
            >
              Tags helps other users search for posts by tag and see what's
              trending. Select multiple or type and click create to create a new
              tag.
            </Typography>
            <CreatableSelect
              isClearable
              isMulti
              onChange={ChangePostTag}
              components={animatedComponents}
              options={options}
              placeholder="Select or Create New Tags"
            ></CreatableSelect>
            <br />
            <Checkbox
              checked={checked}
              onChange={toogleTags}
              label="Yes"
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography
              gutterBottom
              variant="body1"
              // className={classes.postHead}
            >
              Hide tags
            </Typography>
          </>
        ) : (
          <>
            <div>
              <Checkbox
                checked={checked}
                onChange={toogleTags}
                label="Yes"
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                gutterBottom
                variant="body1"
                // className={classes.postHead}
              >
                Add tags
              </Typography>
              <br />
            </div>
          </>
        )}

        <div className="card-body d-flex p-0 mt-2 mb-2">
          {!alertImageUrls.length == 0 ? (
            ""
          ) : (
            <>
              {/* <p>Maximum file upload is 4 images</p> */}
              <a className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                {/* <i className="font-md text-success feather-image me-2"></i> */}
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(e) => {
                      // if (e.target.files.length > 4) {
                      //   setIsLimit(true);
                      // } else {
                        // setImages([...e.target.files]);
                        console.log([...e.target.files]);
                        validateImage(e.target.files);
                        onChange3(e);
                      // }
                    }}
                  />

                  <Button variant="contained" component="span">
                    Image
                  </Button>
                </label>
              </a>
            </>
          )}
          <br />
          <div>
            {video ? (
              ""
            ) : (
              <a className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4">
                <label htmlFor="contained-button-file-2">
                  <Input
                    accept="video/*"
                    id="contained-button-file-2"
                    // multiple
                    type="file"
                    onChange={(e) => {
                      if (e.target.files[0].size > 300000 * 1028) {
                        setUploadLimit(true);
                      } else {
                        setUploadLimit(false);
                        setVideo(e.target.files[0]);
                      }
                    }}
                  />

                  <Button variant="contained" component="span">
                    Video
                  </Button>
                </label>
              </a>
            )}
          </div>
        </div>
      </div>

      {uploadLimit ? (
        <div>
          <MuiAlert
            severity="warning"
            onClose={closeError}
            action={
              <>
                <CancelIcon onClick={closeError} />
              </>
            }
          >
            Maximum size allowed is 300 mb
          </MuiAlert>
          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
          <br />
          <a
            href="https://www.freeconvert.com/video-compressor"
            target="_blank"
            className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
          >
            {/* <BoltIcon style={{ fontSize: 35 }} /> */}
            <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
              Quickly compress it
            </span>{" "}
          </a>
        </div>
      ) : (
        ""
      )}

      {!alertImageUrls.length == 0 ? (
        <>
          <br />
          <Typography
            gutterBottom
            variant="subtitle2"
            //  className={classes.postHead}
          >
             Slide to view all
          </Typography>
          <h4> Slide to view all </h4>

          <Carousel>
              {alertImageUrls.map((slideImage, index) => (
                
                <img
                  src={slideImage}
                  key={index}
                  style={{ zIndex: -10, }}
                  alt=""
                />
              ))}
            </Carousel>
          {/* <Zoom scale={1.4} arrows={false} indicators={true} duration='2000'>
            {alertImageUrls.map((slideImage, index) => (
              // <div className="each-slide" key={index}>
                <img src={slideImage} key={index} style={{zIndex: -10}} alt="" />
               
              // </div>
            ))}
          </Zoom> */}
          <br/>

          {/* <div data-uk-slider="finite: true center: true autoplay: true">
            <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
              {alertImageUrls.map((imageSrc) => (
                <li style={{ marginRight: 10 }}>
                  <img src={imageSrc} alt="" />
                </li>
              ))}
            </ul>
          </div> */}

          <Button
            // onClick={() => setAlertImageUrls([])}
            onClick={clearImages}
            // className={classes.button}
          >
            Clear Images
          </Button>
          {/* {/* <br /> */}
        </>
      ) : (
        ""
      )}
      <br />
      <div>
        {video.length !== 0 ? (
          <>
            <br />
            <Typography
              gutterBottom
              variant="subtitle2"
              //  className={classes.postHead}
            >
              Your post's video
            </Typography>

            <br />
            <video width="400" controls>
              <source src={URL.createObjectURL(video)} />
            </video>
            <br />
            <br />
            {creatingPost ? (
              <Button disabled>{creatingMessage}</Button>
            ) : (
              <Button
                onClick={
                  () => setVideo("")
                  //   this.setState({ profile_pic: "" })
                }
              >
                Remove
              </Button>
            )}

            <br />
          </>
        ) : (
          ""
        )}
      </div>

      {/* {verified === "null" ? (
        <>
          <br />
          <a
            href="/email_verification"
            class="bg-success flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
          >
            Verify Your Email
          </a>
        </>
      ) : (
        <> */}
      {props.type == "channel" || props.type == "page" || props.type == "community" ? (
        // 'channel'
        // className="ms-auto">
        <div className="mt-3">
          {!creatingPost ? (
            <a
              onClick={validate}
              className="pointer font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center"
            >
              <i
                onClick={validate}
                className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"
              ></i>
              Create {props.type} post
            </a>
          ) : (
            <>
              <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={this.createPost}
              >
                Creating Your Post
              </Button>
              <br />
              <h4>{creatingMessage}</h4>
            </>
          )}
        </div>
      ) : (
        // 'post'
        <div className="mt-3">
          {!creatingPost ? (
            <a
              // comeback bg-current text-white hover:bg-current hover:text-white
              onClick={validate}
              className="pointer font-xssss fw-600 text-black-500 hover:text-primary card-body p-0 d-flex align-items-center"
            >
              <i
                onClick={validate}
                className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"
              ></i>
              Create Post
            </a>
          ) : (
            <>
              <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={this.createPost}
              >
                Creating Your Post
              </Button>
              <br />
              <h4>{creatingMessage}</h4>
            </>
          )}
        </div>
      )}
      {/* </>
   verified ends here   )} */}

      {invalidFile ? <h4> {invalidFile} </h4> : ""}
      {emptyBody ? (
        <div>
          <br />
          <MuiAlert severity="warning" onClose={handleClose}>
            Oops! type in your post first
          </MuiAlert>
          <br />
        </div>
      ) : (
        ""
      )}
      {isSuccess ? (
        <>
          <br />
          <Alert onClose={handleClose2} variant="filled" severity="success">
            Your post was created sucessfully!
          </Alert>
        </>
      ) : (
        ""
      )}

      {isLimit ? (
        <div>
          <Alert
            severity="warning"
            onClose={handleClose}
            action={
              <>
                <CancelIcon onClick={closeError} />
              </>
            }
          >
            Maximum upload is 4 images, kindly reduce.
          </Alert>
          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
          <br />
        </div>
      ) : (
        ""
      )}

      {error ? (
        <div>
          <Alert
            severity="error"
            onClose={handleClose}
            action={
              <>
                <CancelIcon onClick={closeError} />
              </>
            }
          >
            There was an issue creating your post. Kindly try again [{" "}
            {errorMessage} ]
          </Alert>
          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
          <br />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
// }

// export default CreatePost2;
