import React, { Component, useEffect, useState } from "react";
import "./comment.css";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import ShowMoreText from "react-show-more-text";
import { format, formatDistance, subDays } from "date-fns";
import CreateReply from "./CreateReply";
import SingleReply from "./SingleReply";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Chip from "@material-ui/core/Chip";
import ReactPlayer from "react-player";

export default function SingleComment(props) {
  const [replyOpen, setReplyOpen] = useState(false);
  const [isLiking, setIsLiking] = useState(false);

  const [showReplies, setShowReplies] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isLiked, setIsLiked] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  function closeSnack() {
    setIsFavorite(false);
    setIsLiked(false);
    setIsDeleted(false);
  }

  function toggleReply() {
    replyOpen == false ? setReplyOpen(true) : setReplyOpen(false);
  }
  function closeReply() {
    setReplyOpen(false);
  }
  function openReplyView() {
    // showReplies == false ? setShowReplies(true) : setShowReplies(false);
    setShowReplies(true);
  }
  function closeReplyView() {
    setShowReplies(false);
  }

  async function likeComment(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/${id}/${props.favUrl}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        setIsLiked(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function favComment(id) {
    setIsFavoriting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/fav/${id}/${props.favUrl}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsFavoriting(false);
        setIsFavorite(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteComment(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .delete(`/comment/${id}/${props.deleteUrl}`, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsDeleting(false);
        setIsDeleted(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  return (
    <>
      {props.comments.map((comment) => (
        <>
          <div key={comment.comment.id}>
            <div
              className="d-flex"
              style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}
            >
              <Link to={`/profile/${comment.comment.user.user_name}`}>
                <img
                  src={comment.comment.user.profile_pic}
                  alt=""
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: 10,
                    border: "2px solid #045de9",
                  }}
                />
              </Link>
              <div className="flex">
                <div
                  style={{ width: "100%" }}
                  className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-15  dark:bg-gray-800 dark:text-gray-100"
                >
                  <div className="d-flex">
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                      <Link to={`/profile/${comment.comment.user.user_name}`}>
                        @{comment.comment.user.user_name}
                      </Link>
                    </h4>
                  </div>
                  <p className="leading-6">
                    <span style={{ marginRight: 20 }}>
                      <ShowMoreText
                        lines={1}
                        more="Read more"
                        less="Show less"
                        // className="leading-6"
                        className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                        anchorClass="my-anchor-css-class"
                        width={300}
                        expanded={false}
                        truncatedEndingComponent={"... "}
                      >
                        {comment.comment.message}
                      </ShowMoreText>
                    </span>
                  </p>

                  <div>
                    {!comment.comment.mediaUrl == "" ? (
                      <>
                        <a href={comment.comment.mediaUrl} target="_blank">
                          <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                            {" "}
                            Learn More:{" "}
                            <Chip
                              className="pointer infotag"
                              color="primary"
                              size="small"
                              label={comment.comment.mediaUrl}
                            />
                          </h6>
                        </a>
                        <br />
                      </>
                    ) : (
                      ""
                    )}

                    {!comment.comment.audio == "" && (
                      <>
                        <audio controls>
                          <source
                            src={comment.comment.audio}
                            type="audio/mpeg"
                          />
                        </audio>
                      </>
                    )}

                    {comment.comment.video && (
                      <>
                        <ReactPlayer
                          url={comment.comment.video}
                          controls={true}
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playsinline={true}
                          width="100%"
                          // light={alert..image}
                        />
                        <br />
                      </>
                    )}

                    {comment.comment.images && (
                      <Carousel>
                        {comment.comment.images.map((slideImage, index) => (
                          <img
                            src={slideImage.url}
                            key={index}
                            style={{ zIndex: -10 }}
                            alt=""
                          />
                        ))}
                      </Carousel>
                    )}
                  </div>

                  {props.isGuest == "true" ? (
                    <>
                      <h5>
                        {" "}
                        You're missing out! Signup/login to view / add comment
                        updates
                      </h5>
                    </>
                  ) : (
                    <div>
                      <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                        {formatDistance(
                          new Date(comment.comment.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}{" "}
                        |
                      </span>

                      <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                        {" "}
                        <b>{comment.comment.likes.length}</b> likes |
                      </span>

                      {replyOpen ? (
                        <span
                          className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                          onClick={toggleReply}
                        >
                          Close Reply |
                        </span>
                      ) : (
                        <span
                          className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                          onClick={toggleReply}
                        >
                          Add Reply |
                        </span>
                      )}
                      {/* <span
className="fw-500 text-grey-600 font-xssss mt-1 pointer"
onClick={toggleReply}
>
Toogle Reply |
</span> */}

                      {comment.comment.replies.length === 0 ? (
                        ""
                      ) : (
                        <>
                          {showReplies &&
                          currentComment == comment.comment.id ? (
                            <span
                              className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                              onClick={() => {
                                setCurrentComment(comment.comment.id);
                                closeReplyView();
                              }}
                              // onClick={closeReplyView}
                            >
                              Close {comment.comment.replies.length} Replies
                            </span>
                          ) : (
                            <span
                              data-uk-toggle="target: #reply"
                              className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                              // onClick={openReplyView}
                              onClick={() => {
                                setCurrentComment(comment.comment.id);
                                openReplyView();
                              }}
                            >
                              View {comment.comment.replies.length} Replies
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                </div>
              </div>

              {props.isGuest == "true" ?
               <FavoriteBorderIcon
               style={{ marginLeft: 10, color: '#eee' }}
               
             />
              :
              <div className="flex">
                {isLiking && currentComment == comment.comment.id ? (
                  <HourglassEmptyRoundedIcon />
                ) : (
                  <>
                    {comment.likedComment == "true" ? (
                      <FavoriteRoundedIcon
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          likeComment(comment.comment.id);
                        }}
                      />
                    ) : (
                      <FavoriteBorderIcon
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          likeComment(comment.comment.id);
                        }}
                      />
                    )}
                  </>
                )}
                {/* <PushPinOutlinedIcon /> */}
              </div>
}

              {isFavoriting && currentComment == comment.comment.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {props.authId == props.userId ? (
                    <>
                      <PushPinOutlinedIcon
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          favComment(comment.comment.id);
                        }}
                      />
                      {/* <DeleteIcon  onClick={() => deleteComment(comment.comment.id)}  /> */}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}

              {isDeleting && currentComment == comment.comment.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {props.authId == props.userId ? (
                    <>
                      <DeleteIcon
                        onClick={() => {
                          setCurrentComment(comment.comment.id);
                          deleteComment(comment.comment.id);
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>

            {isDeleted ? (
              <Snackbar
                open={isDeleted}
                autoHideDuration={2000}
                onClose={closeSnack}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Alert onClose={closeSnack} variant="filled" severity="success">
                  Comment deleted successfully!
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}

            {isLiked ? (
              <>
                <Snackbar
                  open={isLiked}
                  autoHideDuration={2000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    That was successful
                  </Alert>
                </Snackbar>
              </>
            ) : null}

            {isFavorite ? (
              <>
                <Snackbar
                  open={isFavorite}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Pinned To Favorite Successfully
                  </Alert>
                </Snackbar>
              </>
            ) : null}

            {/* <div className="shareBottom" style={{ marginLeft: 170 }}>
              <div className="shareOptions">
            
                <div className="shareOption">
                 
                  <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                    {formatDistance(
                      new Date(comment.comment.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                  </span>
                </div>
                <div className="shareOption">
                  
                  <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                    {" "}
                    <b>{comment.comment.likes.length}</b> likes
                  </span>
                </div>
                <div className="shareOption">
                
                  <span
                    className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                    onClick={toggleReply}
                  >
                    Reply
                  </span>
                </div>
                {comment.comment.replies.length === 0 ? (
                  ""
                ) : (
                  <div className="shareOption">
                   
                    {showReplies ? (
                      <span
                        className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                        onClick={toggleReplyView}
                      >
                        Close {comment.comment.replies.length} Replies
                      </span>
                    ) : (
                      <span
                        className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                        onClick={toggleReplyView}
                      >
                        View {comment.comment.replies.length} Replies
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div> */}
            {replyOpen ? (
              <CreateReply
                Id={props.Id}
                commentId={comment.comment.id}
                url1={props.url1}
                url2={"comment"}
                functionProp={props.functionProp}
                openReplyView={() => openReplyView()}
                closeReply={() => closeReply()}
              />
            ) : (
              ""
            )}

            {/* <div id='reply' >
                <SingleReply 
                  replies={comment.comment.replies}
                  functionProp={props.functionProp}
                />
</div> */}
            {comment.comment.replies.length === 0 ? (
              ""
            ) : (
              <>
                {showReplies && currentComment == comment.comment.id ? (
                  <>
                    <div style={{ marginLeft: 80 }}></div>
                    <SingleReply
                      replies={comment.comment.replies}
                      functionProp={props.functionProp}
                      authId={props.authId}
                      userId={props.userId}
                      url1={props.url1}
                      likeUrl={props.favUrl}
                      deleteUrl={props.deleteUrl}
                      favUrl={props.favUrl}
                      openReplyView={() => openReplyView()}
                    />
                    {/* <br/> */}
                    <span
                      className="fw-500 text-grey-600 mt-2 pointer"
                      onClick={() => {
                        setCurrentComment(comment.comment.id);
                        closeReplyView();
                      }}
                      // onClick={closeReplyView}
                    >
                      {/* 283 Comments */}
                      Close Reply
                    </span>
                  </>
                ) : (
                  <span
                    data-uk-toggle="target: #reply"
                    className="fw-500 text-grey-600 font-xssss mt-1 pointer"
                    onClick={() => {
                      setCurrentComment(comment.comment.id);
                      openReplyView();
                    }}
                    // onClick={openReplyView}
                  >
                    View {comment.comment.replies.length} Replies
                  </span>
                )}
              </>
            )}
          </div>
        </>
      ))}
    </>
  );
}
