import React from 'react';
import PropTypes from 'prop-types';

import './button.scss';

const Button2 = props => {
    return (
        <button
           
            className={`btn ${props.className}`}
            onClick={props.onClick ? () => props.onClick() : null}
        >
            {props.children}
        </button>
    );
}

export const OutlineButton = props => {
    return (
        <Button2
            className={`btn-outline ${props.className}`}
            style={{marginLeft:15}}
            onClick={props.onClick ? () => props.onClick() : null}
        >
            {props.children}
        </Button2>
    );
}

Button2.propTypes = {
    onClick: PropTypes.func
}

export default Button2;
