import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Component, useState, useEffect } from "react";
import { debounce } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MuiAlert from "@material-ui/lab/Alert";
import PostUpdateModal from "./PostUpdateModal";
import axiosFetch from "../Interceptors2";
import ReportModal from "./ReportModal";

import { Snackbar } from "@material-ui/core";

export default function PostDropUserAuth(props) {
  const [copied, setCopied] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [reposting, setReposting] = useState(false);
  const [reposted, setReposted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [currentPost, setCurrentPost] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );

  const [open, setOpen] = React.useState(false);

  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 10,
    border: "1px solid",
    p: 1,
    width: "90%",
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };
  const handleClose2 = () => {
    setOpen(false);
    setReposted(false);
    setSaved(false);
    setConfirmDelete(false);
  };
  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }

  async function deletePost(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/posts/delete/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        props.functionProp();
        setConfirmDelete(false);
        setIsDeleting(false);
        
        setIsDeleted(true)
        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function savePost(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disableComment(id) {
    setDisabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/disablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setDisabling(false);
        setDisabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function enableComment(id) {
    setEnabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/enablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setEnabling(false);
        setEnabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function repost(id) {
    setReposting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/repost/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setReposting(false);
        setReposted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function closeSnack() {
    setCopied(false);
    setIsDeleted(true)
  }
  return (
    <>
      <div>
        <a>
          
          <i
            onClick={toggleMenu}
            style={{ fontSize: 25, position: "relative", zIndex: 10 }}
            className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
            // className="ti-more-alt text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
          ></i>{" "}
        </a>
        {showMenu ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
              // data-uk-drop="mode: click;pos: bottom-right"
              style={styles}
            >
              <ul className="space-y-1">
                <li>
                  {props.canUpdate == true ? (
                    <PostUpdateModal
                      title="Edit Post"
                      content={props.post}
                      type="post"
                      postId={props.post.id}
                      functionProp={() => props.functionProp()}
                    />
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <a
                    onClick={() => repost(props.post.post_origin)}
                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  >
                    <i className="feather-refresh-ccw mr-1"></i> Re-post
                  </a>
                </li>
                <li>
                  <div>
                    {reposting ? (
                      <p> Reposting... This will take a few seconds </p>
                    ) : (
                      ""
                    )}
                    {reposted ? (
                      <MuiAlert
                        onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setReposted(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've reposted this post successfully. Your followers
                        will see it on their timeline.
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                {props.canDisable == true ? (
                  <>
                    {props.post.can_comment == "true" ? (
                      <li>
                        <a
                          onClick={() => disableComment(props.post.id)}
                          //  onClick={disableComment}
                          className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                        >
                          <i className="uil-comment-slash mr-1"></i> Disable
                          comments
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a
                          onClick={() => enableComment(props.post.id)}
                          //  onClick={disableComment}
                          className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                        >
                          <i className="uil-comment-slash mr-1"></i> Enable
                          comments
                        </a>
                      </li>
                    )}
                  </>
                ) : (
                  ""
                )}
                <li>
                  <div>
                    {disabling ? (
                      <p>
                        {" "}
                        Disabling Future Comments... This will take a few
                        seconds{" "}
                      </p>
                    ) : (
                      ""
                    )}
                    {disabled ? (
                      <MuiAlert
                        // onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setDisabled(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've disabled future comments on this post. Simply
                        click enable to undo
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li>
                  <div>
                    {enabling ? (
                      <p>
                        {" "}
                        Enabling Comments ... This will take a few seconds{" "}
                      </p>
                    ) : (
                      ""
                    )}
                    {enabled ? (
                      <MuiAlert
                        // onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setEnabled(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've re-enabled comments on this post. Simply click
                        disable to undo
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li>
                  <a
                    onClick={() => savePost(props.post.id)}
                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  >
                    <i className="uil-favorite mr-1"></i> Save Post
                  </a>
                </li>
                <li>
                  <div>
                    {saving ? (
                      <p> Saving Post... This will take a few seconds </p>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <MuiAlert
                        onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setSaved(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've saved this post successfully.
                        <a href={`/profile/${userName}`}>
                          View it in your profile
                        </a>
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li>{" "}
                {/* <li>
                                <a
                                  onClick={() => {
                                    setCurrentPost(props.post.id) 
                                    toggleMenu()
                                  }}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-x mr-1"></i> Close This
                                </a>
                              </li> */}
                <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li>
                <li>
                  {props.canDelete == true ? (
                    <a
                      // href="#"
                      onClick={() => setConfirmDelete(true)}
                      className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                    >
                      <i className="uil-trash-alt mr-1"></i> Delete
                    </a>
                  ) : (
                    ""
                  )}
                  {confirmDelete ? (
                    <div>
                      {isDeleting ? (
                        <p>Deleting, Just a sec ...</p>
                      ) : (
                        <p> Are you sure you want to delete this ? </p>
                      )}
                      <MuiAlert
                        onClose={handleClose2}
                        severity="error"
                        action={
                          <>
                            <a
                              onClick={() => deletePost(props.post.id)}
                              className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              Yes, delete it.
                            </a>
                            <br />
                            <a
                              onClick={() => setConfirmDelete(false)}
                              className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              No, Cancel
                            </a>
                          </>
                        }
                      >
                        Are you sure you want to delete this ?
                      </MuiAlert>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  {props.canReport == true ? (
                    <ReportModal
                      type="post"
                      text="Report"
                      url="posts"
                      id={props.post.id}
                    />
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </ClickAwayListener>
        ) : (
          ""
        )}

        {copied ? (
          <>
            <br />
            {/* <p className="text-primary">link copied</p> */}
            <Snackbar
              open={copied}
              autoHideDuration={1000}
              onClose={closeSnack}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert onClose={closeSnack} severity="success">
                Link Copied Successfully
              </Alert>
            </Snackbar>
          </>
        ) : null}
      </div>

      {isDeleted ? (
              // <>
                <Snackbar
                  open={isDeleted}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Post Deleted Successfully!
                  </Alert>
                </Snackbar>
              // </>
            ) : (
              ""
            )}

    </>
  );
}
