import React, { Component, Fragment, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, useLocation, Link } from "react-router-dom";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";

import UserTransactionsAdmin from "../components/UserTransactionsAdmin";
import UserRequestsAdmin from "../components/UserRequestsAdmin";
import TestTransaction from "../components/TestTransaction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";



export default function UserSubscription() {
  // const [userName, setUserName] = useState("");
  const userName = window.localStorage.getItem("first_name");
  const [key, setKey] = useState("home");
  const [value, setValue] = React.useState("subscriptions");
  const location = useLocation();
  const history = useHistory();

  const path = location.pathname;
  const pathname = window.location.pathname

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if(path.includes('/subscriptions')){
      setValue('subscriptions')
    }
    if(path.includes('/request')){
      setValue('requests')
    }
 
    // .slice(-3)
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.replace(`/admin/${newValue}`)
    setValue(newValue);
    // e.prevent
    // event.preventDefault();
    // history.replace(`/profile/${user_name}/${newValue}`)
  };

  useEffect(() => {
    getPathname();
  },[]);

  return (
    <Fragment>
      <Header />
      <div style={{marginTop: 75}} className="main-content ">
      <Box sx={{  width: "100%", bgcolor: "background.paper" }}>
            
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                // value={key}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
              >
            <Tab value="subscriptions" label="Subscriptions">
           
            </Tab>

            <Tab value="requests" label="Requests" component={Link} to={`/admin/requests`} >
           
            </Tab>
            

            </Tabs>
            </Box>


            <TabPanel value="subscriptions">
              <br/>
              <div className="row">
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                <UserTransactionsAdmin />
                {/* <h2> All Transactions </h2> */}
                  {/* <Events />  */}
                </div>
                
               
              </div>

            </TabPanel>

            <TabPanel value="requests">
            <br/>
  
             <h2 className="text-center"> Hello Admin! List of Current & Past Requests on Alarrt </h2>
             <UserRequestsAdmin />
            
            </TabPanel>

            </TabContext>
          </Box>
          </div>
     

      <Appfooter />
    </Fragment>
  );
}
