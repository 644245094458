import React, { Component } from "react";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import { Upload } from "@aws-sdk/lib-storage";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Fab, Tooltip } from "@material-ui/core";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";

// import { Select } from "antd";

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     // position: "absolute",
//     position: "fixed",
//     bottom: 160,
//     right: 20,
//   },

// }));

export default function CreateAlertVideo(props) {
  // const classes = useStyles();
  const [record, setRecord] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [checked, setChecked] = React.useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showLocation, setShowLocation] = useState(false);
  const animatedComponents = makeAnimated();
  const [alertTags, setAlertTags] = useState([]);
  const [alertTagsName, setAlertTagsName] = useState("Call Me");
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const ip_address = window.sessionStorage.getItem("ip");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const lat = window.sessionStorage.getItem("lat");
  const lng = window.sessionStorage.getItem("lon");

  const [alertId, setAlertId] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [updatingAlert, setUpdatingAlert] = useState(false);

  const options = [
    { value: "robbery-attack", label: "Robbery Attack" },
    { value: "need-medical-help", label: "Need Medical Help" },
    { value: "", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];
  const { Option } = Select;
  const alertTypeSelect = [
    { value: "fire", label: "Need Fire Rescue" },
    {
      value: "accident",
      label: "Need Medical Rescue (Accidents, Poisoning, Health Crises)",
    },
    {
      value: "security",
      label: "Need Security Rescue (Kidnap, Robbery, Assault)",
    },
  ];
  const handleModalClose = () => {
    setOpen(false);
    setCreated(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState("");
  const [headline, setHeadline] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertCategoryName, setAlertCategoryName] = useState("");

  let history = useHistory();
  const bodyLocal = window.localStorage.getItem("body");
  const headlineLocal = window.localStorage.getItem("headline");

  const firstName = window.localStorage.getItem("first_name");
  const user_name = window.localStorage.getItem("user_name");
  const profile_pic = window.localStorage.getItem("profile_pic");
  const verified = window.localStorage.getItem("verified");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [compImages, setCompImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [alertImage, setAlertImage] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(false);
  const [completedAlert, setCompletedAlert] = useState(false);
  const [sentVideo, setSentVideo] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [emptyBody, setEmptyBody] = useState(false);
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [locationWarning, setLocationWarning] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [sendToFriends, setSendToFriends] = useState("no");
  const [created, setCreated] = useState(false);
  const [alertInfo, setAlertInfo] = useState("");
  const [status, setStatus] = useState("");

  const [error, setError] = useState(false);
  const loopData = [];

  const Input = styled("input")({
    display: "none",
  });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setEmptyBody(false);
    setCreatedCat(false);
    setCreated(false);
    setError(false);
    setLocationWarning(false);
  }

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  function handleClose2(event, reason) {
    // if (reason === "clickaway") {
    //   return;
    // }
    setIsSuccess(false);
    setBody("");
    localStorage.removeItem("body");
    localStorage.removeItem("headline");
    setImage("");
    props.functionProp();
    props.functionProp2();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function changeStatus(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    setStatus(value.label);
    setAlertCategory(value.value);
    setAlertCategoryName(value.label);
    // setBody(`There is a ${value.label} ... going on in ... `);
  }

  const statusSelect = allCategories.map((cat, index) => {
    return {
      id: index,
      value: cat.id,
      label: cat.name,
    };
  });

  function addAlartInfo(value) {
    setAlertInfo("");
    // setHeadline("I Am In A Life Threatening Crisis And I Need Immediate Help!")
    console.log(`status ${value}`);
    console.log(`status 2 ${value.label}`);
    setAlertInfo(value.label);

    setBody(value.label);
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  const onChange3 = async (event) => {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  function validate() {
    if (!video) {
      setEmptyBody(true);
      return;
    } else {
      uploadVideo();
      setCreatingPost(true);
      setCreatingMessage("Video Detected, Uploading First");
    }
  }
  function validate2nd(e) {
    getLastAlert();
    if (!headline || !body || !alertCategory || !location) {
      setEmptyBody(true);
      return;
    } else {
      //   updateAlert();
      getLastAlert();
    }
  }

  function getLastAlert() {
    setCreatingPost(true);
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/alerts/get_last", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // alert(resp.data);
        setAlertId(resp.data);
        updateAlert(resp.data);
        // uploadVideo(resp.data);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "alerts/videos/" + user_id + "/" + uuidv4();
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            createAlert();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      alert("Oops! your video can not be empty");
      //   createAlert(alertId);

      console.log("video very empty");
    }
  }

  async function createAlert() {
    setCreatingPost(true);
    setCreatingMessage("Sending Your Alarrt, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();

    formData.append("user_id", userID);
    formData.append("alert_category_id", 65);
    formData.append("body", "A new alarrt was just recorded");
    formData.append("headline", "I Am Alarrting A Live Incident!");
    // formData.append("url", url); "");

    formData.append("location", "Nigeria");
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }
    formData.append("type", "alert");
    formData.append("status", "sent_out");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setCreatingPost(false);
        props.functionProp();
        // setUploadedVideo(true)
        setIsSuccess(true);
        setCreated(true);
        // setVideo()
        // props.functionProp2();
        // setSentVideo(true);
        // setIsSuccess(true);
        // setCreated(true);

        window.localStorage.removeItem("postVideo");
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  async function updateAlert(alertId) {
    setCreatingPost(true);
    setCreatingMessage("Updating Your Alarrt, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    // formData.append("user_id", userID);
    formData.append("alert_category_id", alertCategory);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("location", location);
    console.log("olumide!!!");

    // formData.append("sendToFriends", sendToFriends);
    // formData.append("type", "alert");
    // formData.append("status", "sent_out");
    // formData.append("latitude", latitude);
    // formData.append("longitude", longitude);
    // formData.append("watch_latitude", watch_latitude);
    // formData.append("watch_longitude", watch_longitude);
    // formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post(`/alerts/update/${alertId}`, formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingPost(false);
          props.functionProp();
          props.functionProp2();
          setSentVideo(true);
          setUploadedVideo(true);
          setIsSuccess(true);
          setCreated(true);

          setHeadline("");
          setBody("");
          setAlertTagsName("Call Me");

          // localStorage.removeItem("body");
          // localStorage.removeItem("headline");

          // setIsNext(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingPost(false);
          //   setCreated(false);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  const changeCallStatus = (event) => {
    setAlertTagsName(event.target.value);
    toogleDontCall();
  };

  const toogleDontCall = () => {
    if (showTags == true) {
      setShowTags(false);
      setAlertTagsName("Don't Call Me");
      // setChecked(true);
    } else {
      setShowTags(true);
      setAlertTagsName("Call Me");
      // setChecked(false);
    }
    // setChecked(event.target.checked);
  };

  function ChangeAlertTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => alertTagsName.push(tag.value));
    console.log(`name ${alertTagsName}`);

    // setSelectedTags([...new Set(alertTagsName)]);
    // console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/alert_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getAlertCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function validateImage2(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select a valid image.");
      return false;
    } else {
      console.log(value);
      setImage(value);
      setInvalidFile(false);
    }
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  // function startRecording3() {
  //   setRecord(true);
  //   setIsRecording(true);
  // }

  // function stopRecording3() {
  //   setRecord(false);
  // }

  // function onData(recordedBlob) {
  //   // onData(recordedBlob) {
  //   console.log("chunk of real-time data is: ", recordedBlob);
  // }

  // async function onStop(recordedBlob) {
  //   setIsRecording(false);
  //   console.log("recordedBlob is: ", recordedBlob);
  //   console.log("recordedBlob blob file is  is: ", recordedBlob.blob);
  //   const blobFile2 = recordedBlob.blob;
  //   setBlobFile(blobFile2);
  // await FFMPEG.process(
  //   blobFile,
  //   '-metadata location="" -metadata location-eng="" -metadata author="" -c:v copy -c:a copy',
  //   function (e) {
  //     const video = e.result;
  //     console.log('ffmpeg is ' + video);
  //   }.bind(this)
  // );

  // const blob = new Blob('alert-audio',
  // {
  //   type: recordedBlob.blobURL.type,
  //   lastModified: Date.now()
  // });
  // const blobURL = URL.createObjectURL(recordedBlob.blob);
  // const file = new File( blobFile, {
  //   type: blobFile.type,
  //   lastModified: Date.now()
  // });
  // console.log('new file is is: ', file);
  // try{
  // var process = new FFmpeg('ppath/to/new/file')
  //   process.then(function (audio){
  //     audio.fnExtractSoundToM3('path/to/new/file.mp3', function(error, file){
  //       if(!error)
  //       console.log('audio file processed is '+ file );
  //     });

  //   }, function(err) {
  //     console.log('procesing error '+ err);
  //   });
  // }
  // catch (e) {
  //   console.log(e.code)
  //   console.log(e.msg)
  // }

  // const li = document.createElement("li");
  // const player = new Audio(URL.createObjectURL(blobFile2));
  // player.controls = true;
  // li.appendChild(player);
  // document.querySelector("#playlist").appendChild(li);

  // const player = new Audio(URL.createObjectURL(file));
  //   console.log("player is " + JSON.stringify(player));
  //   player.play();
  //   setBlobUrl(blobURL);
  //   uploadAudio();
  // }

  // function startRecording2() {
  //   if (isBlocked) {
  //     alert("Permission Denied");
  //   } else {
  //     Mp3Recorder.start()
  //       .then(() => {
  //         setIsRecording(true);
  //       })
  //       .catch((e) => console.error(e));
  //   }
  // }

  // function stopRecording2() {
  //   Mp3Recorder.finish()
  //     .getMp3()
  //     .then(([buffer, blob]) => {
  //       setIsRecording(false);
  //       console.log("mp3 blob is " + blob);
  //       console.log("mp3 blob buffer is " + blob);
  //       const file = new File(buffer, "alert-audio.mp3", {
  //         type: blob.type,
  //         lastModified: Date.now(),
  //       });

  //       const li = document.createElement("li");
  //       const player = new Audio(URL.createObjectURL(file));
  //       player.controls = true;
  //       li.appendChild(player);
  //       document.querySelector("#playlist").appendChild(li);

  // const player = new Audio(URL.createObjectURL(file));
  // console.log("player is " + JSON.stringify(player));
  // player.play();
  // setBlobUrl(player);

  // const blobURL = URL.createObjectURL(blob)
  // setBlobUrl(blobURL);

  // this.setState({ blobURL, isRecording: false });
  //     })
  //     .catch((e) => console.log(e));
  // }

  function getLocation() {
    if ("geolocation" in navigator) {
      console.log("Location is Available");
    } else {
      console.log("Location is Not Available");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Lat is :", position.coords.latitude);
      console.log("Lon is :", position.coords.longitude);
      window.sessionStorage.setItem("lat", position.coords.latitude);
      window.sessionStorage.setItem("lon", position.coords.longitude);
    });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("full posoition " + position);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        console.log("Watch Lat is :", position.coords.latitude);
        console.log("Watch Lon  is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
  }

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getLocation();
    watchLocation();
    getAlertCategories();
    // onChange={(e) => setLocation(e.target.value)}

    // if (bodyLocal) {
    //   setBody(bodyLocal);
    // }
    // if (headlineLocal) {
    //   setHeadline(headlineLocal);
    // }
  }, []);

  // render() {
  const menuClass = `${isOpen ? " show" : ""}`;
  //    const {user_name} = this.state;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0 text-center">
        <Tooltip
          title="Send Distress Alert"
          aria-label="add"
          // onClick={handleClickOpen}
          onClick={() => {
            handleClickOpen();
            getLocation();
            watchLocation();
          }}
        >
          <Fab
            color="primary"
            style={{
              zIndex: 30,
              width: 100,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <img src="/assets/images/alert-fab3.webp" /> */}
            <img src="/assets/images/alert-fab3.webp" />
            {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
          </Fab>
        </Tooltip>
        <br />
        <br />
        <h3 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
          {" "}
          Click To Record A Live Incident
        </h3>
      </div>

      <Dialog open={open} onClose={handleModalClose}>
        {/* <DialogTitle>
          {" "}
          Create Personal Distress Alert Quickly Here If You Are In An
          Emergency. Use the headline or edit to what is happening to you if you
          can
          {props.channelId}{" "}
        </DialogTitle> */}
        <DialogContentText
          style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 30 }}
        >
          {" "}
          Hi {firstName} alarrt a live incident. This doesn't make any sound.
         
        </DialogContentText>
        <DialogContent>
          <br />

          {uploadedVideo == true ? (
            <h4>Great! We've recieved your alert.</h4>
          ) : (
            <>
              {isNext == false ? (
                <div>
                  <>
                    {video ? (
                      <>
                        {isSuccess == true ? (
                          ""
                        ) : (
                          //  <h4> One  Kindly proceed to the last step to add more info about your video. This lets others understand what was recorded better.</h4>
                          <>
                            {/* <br /> */}
                            <Typography
                              gutterBottom
                              variant="body1"
                              // className={classes.postHead}
                            >
                              Your alert's video
                            </Typography>

                            {/* <br /> */}
                            <video width="400" controls>
                              <source src={URL.createObjectURL(video)} />
                            </video>

                            <br />
                            <br />
                            {creatingPost ? (
                              <Button disabled>{creatingMessage}</Button>
                            ) : (
                              <>
                                <Button
                                  onClick={() => {
                                    setVideo("");
                                    // getLastAlert()
                                  }}
                                >
                                  Click To Remove (Take Another)
                                </Button>
                              </>
                            )}

                            <br />
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Typography
                          gutterBottom
                          variant="body1"
                          // className={classes.postHead}
                        >
                          You can take a live recording using either your front
                          or back camera. Do this discreetly if in a hostile
                          environment. Press "Rec" when ready and wait for the
                          countdown. Click stop to start your video upload.
                        </Typography>
                        <br />
                        <VideoRecorder
                          isOnInitially = {true}
                          countdownTime={1000}
                          onRecordingComplete={(videoBlob) => {
                            // Do something with the video...
                            console.log("videoBlob", videoBlob);
                            setVideo(videoBlob);
                          }}
                        />
                        <br />
                        <br />
                      </>
                    )}
                  </>

                  <div className="mt-3">
                    {!video ? (
                      <>
                        <Typography gutterBottom variant="body1">
                          Hi {firstName}! You need to record your video to
                          proceed.
                        </Typography>
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleModalClose}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        {isSuccess == true ? (
                          ""
                        ) : (
                          <>
                            {!creatingPost ? (
                              <>
                                <Typography gutterBottom variant="body1">
                                  You can upload your video now. You'll be
                                  directed to the last step.
                                </Typography>
                                <br />
                                <Button
                                  //  disabled
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  // onClick={() => alert(uuidv4())}
                                  onClick={validate}
                                >
                                  Create Alarrt Now
                                </Button>
                                {/* <br />
                          <h4>{creatingMessage}</h4> */}
                              </>
                            ) : (
                              <>
                                <Typography gutterBottom variant="body1">
                                  You will be redirected to the next step to
                                  provide more info on what's going on
                                </Typography>
                                <Button
                                  disabled
                                  variant="contained"
                                  color="primary"
                                  // onClick={this.createPost}
                                >
                                  Creating Your Alarrt
                                </Button>
                                {/* <br />
                            <h4>{creatingMessage}</h4> */}
                              </>
                            )}
                          </>
                        )}
                        {/* </> */}
                        
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="card-body">
                    {sentVideo ? (
                      <>
                        <h4>
                          {" "}
                          Thanks For That Additional Information {firstName}.
                          Notifications have been sent out. You might be
                          contacted shortly.
                        </h4>
                      </>
                    ) : (
                      <h4>
                        You are in the next section {firstName}. To help others
                        know more of what is really going on, please add
                        more information below
                      </h4>
                    )}
                    <br />
                    <Typography
                      gutterBottom
                      variant="overline"
                      style={{ color: "blue" }}
                    >
                      Select from type of alert, or create new one below
                    </Typography>
                    <Select
                      // isClearable
                      components={animatedComponents}
                      // defaultValue={status}
                      options={statusSelect}
                      onChange={changeStatus}
                      placeholder="select alarrt category"
                      defaultInputValue={status}
                    />
                    <br />
                    <div className="d-flex">
                      <TextField
                        id="standard-basic"
                        label="Or Create New Category"
                        size="small"
                        // style={{width:'40%'}}
                        style={{ width: "80%" }}
                        onChange={(e) => setNewCategory(e.target.value)}
                        value={newCategory}
                      />
                      {!newCategory ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          // component="span"
                          style={{ marginLeft: 7 }}
                        >
                          Type First
                        </Button>
                      ) : (
                        <>
                          {creatingCat ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              // component="span"
                              style={{ marginLeft: 7 }}
                            >
                              Creating
                            </Button>
                          ) : (
                            <>
                              <Button
                                onClick={createCategory}
                                variant="contained"
                                color="primary"
                                // component="span"
                                style={{ marginLeft: 7 }}
                              >
                                Create
                              </Button>
                              <br />
                              <br />

                              {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <Typography
                      gutterBottom
                      variant="overline"
                      style={{ color: "blue" }}
                    >
                      Alarrt Summary e.g Massive Standstill Along Ojodu Berger
                      Express
                    </Typography>
                    <TextField
                      id="standard-basic"
                      onClick={() => {
                        // handleClickOpen();
                        getLocation();
                        watchLocation();
                      }}
                      // onClick={handleClickOpen}

                      label={`Your headline`}
                      required
                      size="small"
                      style={{ width: "100%", fontSize: 4, color: "red" }}
                      onChange={(e) => {
                        window.localStorage.setItem("headline", e.target.value);
                        setHeadline(e.target.value);
                      }}
                      defaultValue={window.localStorage.getItem("headline")}
                      value={headline}
                      // value={headline}
                      LabelProps={
                        {
                          // className: classes.textInput,
                        }
                      }
                    />
                    {/* {isBodyOpen && ( */}
                    <div className="card-body p-0 mt-3 position-relative">
                      <Typography
                        gutterBottom
                        variant="overline"
                        style={{ color: "blue" }}
                      >
                        Give more details here. e.g How critical is what is
                        happening and any other important info
                      </Typography>
                      <figure className="avatar position-absolute ms-2 mt-1">
                        <img
                          src={profile_pic}
                          alt="avater"
                          className="shadow-sm rounded-circle w45"
                          style={{
                            marginRight: 30,
                            maxWidth: 35,
                            height: 35,
                            border: "2px solid #045de9",
                          }}
                        />
                      </figure>
                      <textarea
                        name="message"
                        style={{ paddingLeft: 50, paddingTop: 10 }}
                        className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                        cols="20"
                        rows="10"
                        // style=
                        placeholder="Give full details here. e.g A petrol tanker just fell on Ojodu Berger express, It's contents are still spilling out and traffic is building up. Help is needed to help those stuck here."
                        onChange={(e) => {
                          window.localStorage.setItem("body", e.target.value);
                          setBody(e.target.value);
                        }}
                        defaultValue={window.localStorage.getItem("body")}
                        value={body}
                        // this.setState({ isOpen: !this.state.isOpen })
                      ></textarea>
                    </div>
                    {/* )} */}

                    {/* <Typography gutterBottom variant="body1">
                Tell Us Where It Is Happening
              </Typography> */}
                    <div>
                      {/* <br/> */}

                      <TextField
                        id="standard-basic"
                        label={"Where is it happening?"}
                        // required
                        size="small"
                        style={{ width: "100%", marginTop: 10, fontSize: 4 }}
                        onChange={(e) => setLocation(e.target.value)}
                        value={location}
                        LabelProps={
                          {
                            // className: classes.textInput,
                          }
                        }
                      />
                      <Typography
                        gutterBottom
                        variant="caption"
                        // className={classes.postHead}
                      >
                        Location is required! Use This Format Please:
                        Country->State->Area (e.g. Nigeria->Lagos->Ikeja)
                      </Typography>
                      <br />
                    </div>

                    {createdCat ? (
                      <>
                        <Snackbar
                          open={createdCat}
                          autoHideDuration={4000}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert onClose={handleClose} severity="success">
                            Category successfully created. Select it above
                          </Alert>
                        </Snackbar>
                      </>
                    ) : (
                      ""
                    )}

                    <div className="mt-3">
                      {!alertCategoryName || !headline || !body || !location ? (
                        <>
                          <Typography gutterBottom variant="body1">
                            Hi {firstName}! You need to type in your headline,
                            body, location and category to proceed.
                          </Typography>
                          <br />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleModalClose}
                          >
                            Close
                          </Button>
                        </>
                      ) : (
                        <>
                          {!creatingPost ? (
                            <>
                              <Typography gutterBottom variant="body1">
                                You may update your alarrt now
                              </Typography>
                              <Button
                                //  disabled
                                variant="contained"
                                color="primary"
                                component="span"
                                //  style={{ marginLeft: 7 }}
                                onClick={validate2nd}
                              >
                                Update Alarrt Now
                              </Button>
                            </>
                          ) : (
                            <>
                              <Typography gutterBottom variant="body1">
                                Thanks for that additional information{" "}
                                {firstName}
                              </Typography>
                              <Button
                                disabled
                                variant="contained"
                                color="primary"
                                // onClick={this.createPost}
                              >
                                Updating Your Alarrt
                              </Button>
                              <br />
                              <h4>{creatingMessage}</h4>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    {invalidFile ? <h4> {invalidFile} </h4> : ""}
                    {emptyBody ? (
                      <>
                        {isNext == false ? (
                          <div>
                            <br />
                            <Alert
                              variant="filled"
                              onClose={handleClose}
                              severity="warning"
                            >
                              Oops! To create an alert, you need to record your
                              video first
                            </Alert>
                            <br />
                          </div>
                        ) : (
                          <div>
                            <br />
                            <Alert
                              variant="filled"
                              onClose={handleClose}
                              severity="warning"
                            >
                              Oops! To create an alert, you need to ensure the
                              headline, body, location and category are not
                              empty
                            </Alert>
                            <br />
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {created ? (
            <>
              <br />
              <Alert variant="filled" severity="success">
                You are awesome {firstName}! Thank you for notifying us -
                Notifications are being sent out and help will be dispatched if
                this is a life threatnening emergency.
              </Alert>
              <br />

              {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleModalClose}
                    >
                      Go Back Home
                    </Button> */}
            </>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue creating your alert. Kindly try again [{" "}
                {errorMessage} {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}
          {isSuccess ? (
            <>
              {isNext == false && (
                <>
                  <h4>
                    {" "}
                    Kindly proceed to the last step to add more info about your
                    video. This lets others understand what was recorded better.
                  </h4>
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    onClick={() => {
                      // getLastAlert();
                      setIsNext(true);
                      setIsSuccess(false);
                      setCreated(false);
                    }}
                  >
                    Proceed To The Last Step
                  </Button>
                </>
              )}
              <br />
              <Typography
                gutterBottom
                variant="overline"
                // className={classes.postHead}
              >
                Security Tips: ->If in distress, please keep your phone close to
                you and <b>put it on vibration / silence now.</b>
                <br />
                -> After sending, if you are moving from your current location,{" "}
                <b>
                  please refresh the app at interval so it updates your new
                  location{" "}
                </b>
              </Typography>{" "}
            </>
          ) : (
            ""
          )}
          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue sending your alert. Kindly try again [{" "}
                {errorMessage} {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}
        </DialogContent>

        <br></br>
        <br />

        <DialogActions>
          <div style={{ marginRight: 10 }}>
            {!creatingPost ? (
              <>
                {/* <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={() => {
                    getLocation();
                    watchLocation();
                    // createAlert()
                    validate()
                  }}
                >
                  Send Distress Alert Now
                </Button> */}
              </>
            ) : (
              <>
                {/* <Button
                  disabled
                  variant="contained"
                  color="primary"
                  // onClick={this.createPost}
                >
                  Sending Your Alert
                </Button> */}
                {/* <br /> */}
                {/* <h4>{creatingMessage}</h4> */}
              </>
            )}
          </div>
          <Button
            variant="contained"
            color="secondary"
            component="span"
            href="https://wa.me/message/3VHYR6VAI3MSD1"
            target="_blank"
            onClick={handleClickOpen}
            style={{ marginRight: 10 }}
          >
            Stay In Touch Via Whatsapp
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
          >
            Go Back Home
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// }

// export default CreatePost2;
