import React, { Component } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Link,
} from "react-router-dom";
import { Redirect } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "./Load";
import Load2 from "./Load2";
import AlertComponent from "./AlertComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import CreateAlertFab from "./CreateAlertFab";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import AlertFilter from "../components/widgets/AlertFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import CreateAlertQuick from "./CreateAlertQuick";
// import { Fab, Action,  } from 'react-tiny-fab';
import { Fab, Tooltip, Button } from "@material-ui/core";
import "react-tiny-fab/dist/styles.css";
import Bolt from "@mui/icons-material/Bolt";
import CreateAlertVideo from "./CreateAlertVideo";
import CreateAlertDistress from "./CreateAlertDistress";
import LaunchIcon from "@mui/icons-material/Launch";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import ForwardIcon from "@mui/icons-material/Forward";
import CallForHelp from "./CallForHelp";
import CallForHelpGuest from "./CallForHelpGuest";

//
class Alerts extends Component {
  state = {
    user_name: window.localStorage.getItem("user_name"),
    isOpen: false,
    isComment: false,
    OpenAction: false,
    alerts: [],
    alertLikes: [],
    likes: 0,
    isLiked: false,
    fetchingAlerts: false,
    error: false,
    errorMessage: "",
    alertCategory: [],
    skip: 0,
    take: 20,
    isDistress: false,
    isLiveRec: false,
    profile: [],
    // isLoggedIn: false,
  };

  executeOnClick(isExpanded) {
    // console.log(isExpanded);
  }

  //  history = useHistory();
  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  loggedIn = async () => {
    this.setState({ isLoggedIn: true });
    // alert("LoggedIn Successfully");
  };

  closeError = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  handleClose = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  getAllAlertsLoad = () => {
    this.setState({ fetchingAlerts: true });

    const config = {
      onDownloadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log("percentage os " + percentCompleted);
      },
    };
    // console.log(` 1 ${this.state.fetchingAlerts}`)
    this.setState({ user_name: window.localStorage.getItem("user_name") });
    const access_token = window.localStorage.getItem("access_token");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/alerts`, {
        // config,
        // method: "GET",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data))
        // if (resp.data.status == "success") {
        this.setState({ alerts: resp.data });
        this.setState({ alertLikes: resp.data });
        this.setState({ fetchingAlerts: false });
        // this.refreshToken()
      })
      .catch((error) => {
        this.setState({ fetchingAlerts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
        // error.response.data.message
      });
  };

  getNext = () => {
    this.setState({ skip: this.state.skip + 20 });
    // this.setState({ take: this.state.take + 2 });

    this.getNextAlerts();
  };

  getAllAlerts = () => {
    const access_token = window.localStorage.getItem("access_token");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/alerts`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        console.log("gotten alerts");
        this.setState({ alerts: resp.data });
        this.setState({ alertLikes: resp.data });
        // } else {
        //   // this.setState({ fetchingAlerts: false });
        //   this.setState({ error: true });
        //   this.setState({ errorMessage: resp.data.message });
        // }
      })
      .catch((error) => {
        // this.setState({ fetchingAlerts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getNextAlerts = () => {
    const access_token = window.localStorage.getItem("access_token");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/alerts/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        this.setState({ alerts: [...this.state.alerts, ...resp.data] });
      })
      .catch((error) => {
        // this.setState({ fetchingAlerts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getProfile = () => {
    const access_token = window.localStorage.getItem("access_token");

    axiosFetch
      .get(`/users/check/status`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        console.log("gotten alerts");
        this.setState({ profile: resp.data });
        console.log(resp.data);
      })
      .catch((error) => {
        // this.setState({ fetchingAlerts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  getAlertCategories = () => {
    // function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category/active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        this.setState({ alertCategory: result });
        // setAlertCategory(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  };

  refreshToken = async () => {
    axios
      .post(`${process.env.REACT_APP_REFRESH_URL}/oauth/token`, {
        grant_type: "refresh_token",
        refresh_token: window.localStorage.getItem("refresh_token"),
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      })
      .then((response) => {
        {
          console.log(JSON.stringify(response));
        }
        window.localStorage.setItem("access_token", response.data.access_token);

        window.localStorage.setItem(
          "refresh_token",
          response.data.refresh_token
        );
        // watchLocation()
        // console.log('new access in +'    window.localStorage.setItem("access_token", res.access_token)
        // window.location.reload()
        console.log("New Token Recieved");
        //  return axiosFetch();
      })
      .catch((err) => {
        console.log("seems it has expired");
        // window.localStorage.clear();
        // window.localStorage.removeItem("refresh_token");
        // window.location.replace("/signin");
        // window.location.reload()
        // const reloadCount = window.localStorage.getItem("reloadCount");
        // if (!reloadCount) {
        //   localStorage.setItem("reloadCount", String(reloadCount + 1));
        //   // alert('Seems like its been a while since you were here. Kindly Login.')
        // }

        // localStorage.setItem("auth_timeout", 'true');
        // return Promise.reject(err);
      });
  };

  fixImage = async (ev) => {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleAction = () => this.setState({ OpenAction: !this.state.OpenAction });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });
  toggleComment = () => this.setState({ isComment: !this.state.isComment });

  componentDidMount() {
    this.getProfile();
    // this.refreshToken()
    this.getAlertCategories();
    this.getAllAlertsLoad();
    this.getAllAlerts();

    window.localStorage.setItem("can_update", "yes");
  }

  componentDidUpdate() {
    // this.getAllAlerts();
    // console.log("component did uodate")
  }

  render() {
    // console.log(`alerts are ${this.state.alerts}`);
    const { user, time, des, avater, postimage, postvideo, id } = this.props;

    if (this.state.fetchingAlerts == true) {
      // console.log(`final ${this.state.fetchingAlerts}`)
      return (
        <>
          {/* <h3> No Alert Has Been Recently Created </h3> */}
          <Load2 />
        </>
      );
    } else {
      return (
        <>
          <AlertFilter alertCategory={this.state.alertCategory} />

          <br />
          {/* <p> hii {this.state.profile.isSubscribed} COME BACK </p> */}
          {this.state.profile.isSubscribed == "false" ? (
            <CallForHelpGuest
              functionProp={() => this.getAllAlerts()}
              functionProp2={() => this.getAlertCategories()}
            />
          ) : (
            <CallForHelp
              functionProp={() => this.getAllAlerts()}
              functionProp2={() => this.getAlertCategories()}
              status={this.state.profile}
            />
          )}

          {this.state.isDistress ? (
            <CreateAlertDistress
              functionProp={() => this.getAllAlerts()}
              functionProp2={() => this.getAlertCategories()}
              communityId=""
            />
          ) : (
            <>
              {this.state.isLiveRec ? (
                <CreateAlertVideo
                  functionProp={() => this.getAllAlerts()}
                  functionProp2={() => this.getAlertCategories()}
                />
              ) : (
                <CreateAlertQuick
                  functionProp={() => this.getAllAlerts()}
                  functionProp2={() => this.getAlertCategories()}
                  communityId=""
                />
              )}
            </>
          )}

          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-1">
            <div className="card-body p-0 text-center">
              {this.state.isDistress ? (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ReplyAllIcon />}
                    style={{ marginRight: 10, marginBottom: 15 }}
                    onClick={() =>
                      this.setState({ isDistress: !this.state.isDistress })
                    }
                    // target="_blank"this.setState({ isComment: !this.state.isComment });
                  >
                    Quick Alarrt
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    href="/sos-contacts"
                    style={{ marginBottom: 15 }}
                    // style={{marginLeft: 20}}
                    endIcon={<LaunchIcon />}
                  >
                    Add SOS Contact
                  </Button>{" "}
                </>
              ) : (
                <>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ReplyAllIcon />}
                    
                    onClick={() =>
                      this.setState({ isLiveRec: !this.state.isLiveRec })
                    }
                    style={{ marginRight: 10, marginBottom:15 }}
                    
                  >
                    Rec Video
                  </Button> */}
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ marginBottom: 15 }}
                    // endIcon={<ForwardIcon />}
                    onClick={() =>
                      this.setState({ isDistress: !this.state.isDistress })
                    }
                    // target="_blank"this.setState({ isComment: !this.state.isComment });
                  >
                    Send SOS Alarrt
                  </Button>
                </>
              )}
            </div>
          </div>
          <br />

          {/* <InfiniteScroll
            dataLength={this.state.alerts.length} //This is important field to render the next data
            next={this.getNext}
            hasMore={true}
            // loader={<Load message='scroll down to load more' />}
            loader={''}
            // <Load message='scroll down to load more' />
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          > */}

          {this.state.alerts.length == 0 ? (
            <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
              <div className="card-body p-0">
                <h3
                  className="middle-wrap fw-600 text-grey-900"
                  style={{ textAlign: "center", lineHeight: 2 }}
                >
                  Hello {this.state.user_name}! No alarrt has been created in
                  the last 24 hours. You could be the first to create one.{" "}
                  <a href="/alarrts" style={{ color: "blue" }}>
                    Click Here{" "}
                  </a>{" "}
                  to view past alarrts
                  {/* to view past activities on ALARRT */}
                </h3>
              </div>
            </div>
          ) : (
            <>
              <AlertComponent
                alerts={this.state.alerts}
                functionProp={() => this.getAllAlerts()}
                functionProp2={() => this.getAlertCategories()}
              />

              <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                <div className="card-body p-0">
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ textAlign: "center", lineHeight: 2 }}
                  >
                    That's all {this.state.user_name}!{" "}
                    <a href="/alarrts" style={{ color: "blue" }}>
                      Click Here{" "}
                    </a>{" "}
                    to view past alarrts
                  </h3>
                </div>
              </div>
            </>
          )}

          <br />
          {this.state.error ? (
            <div>
              <br />
              <br />
              <Alert
                severity="error"
                onClose={this.handleClose}
                action={
                  <>
                    <CancelIcon onClick={this.closeError} />
                  </>
                }
              >
                There was an issue fetching alerts. Kindly refresh or check your
                network. If this continues, kindly login again [{" "}
                {this.state.errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}
          {/* </InfiniteScroll> */}
          {/* <CreateAlertFab
            functionProp={() => this.getAllAlerts()}
            functionProp2={() => this.getAlertCategories()}
          />
          <CreateAlertFab
            functionProp={() => this.getAllAlerts()}
            functionProp2={() => this.getAlertCategories()}
          /> */}
          {/* <CreateAlertFab functionProp={() => props.functionProp} functionProp={() => props.functionProp2} /> */}
        </>
      );
    }
  }
}

export default Alerts;
