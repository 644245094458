import axios from "axios";
import dayjs from 'dayjs';

const access_token = window.localStorage.getItem('access_token')
const latitude = window.sessionStorage.getItem("lat");
const longitude = window.sessionStorage.getItem("lon");
const watch_latitude = window.sessionStorage.getItem('wat_lat')
const watch_longitude = window.sessionStorage.getItem('wat_lon')

function watchLocation(){
  console.log('Inter Watching location....')
  console.log("Inter Lat is :", latitude);
  console.log("Inter Lon  is :", longitude);
  console.log("Inter Watch Lat is :", watch_latitude);
  console.log("Inter Watch Lon  is :", watch_longitude);
}
const axiosFetch = axios.create({
    
  // alert-lumen-api.herokuapp   alarrt-dev-api.herokuapp
    baseURL:   process.env.REACT_APP_LIVE_URL,
    headers: {
      Accept: 'application/json',
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer ' + access_token,
    },
  });


axiosFetch.interceptors.request.use(
    (request) => {
      request.headers.common['Accept'] = `application/json`;
    //   request.headers.common['access_token'] = window.localStorage.getItem("access_token");
    //   request.header["x-access-token"] = window.localStorage.getItem("access_token");
      console.log('auth fetch request sent');
      
      // must return request
      const expiryTime = window.localStorage.getItem("expires_in")
      
      const isExpired = dayjs.unix(expiryTime).diff(dayjs()) < 1;
      const now = dayjs();
      // watchLocation()


      // if(!isExpired) return request

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  axiosFetch.interceptors.response.use(
    (response) => {
      console.log('got response');
      return response;
    },
    (error) => {
      console.log(error.response);
      // console.log('ststext is '+ error.response.statusText)
      // console.log('confog is '+ JSON.stringify(error.config))
      const originalRequest = error.config
    //   const originalRequest = err.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        // do something
        originalRequest._retry = true;
        console.log('auth axios error FOUND getting refresh');

        axios
        .post(`${process.env.REACT_APP_REFRESH_URL}/oauth/token`, {
          grant_type: "refresh_token",
          refresh_token: window.localStorage.getItem("refresh_token"),
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
        })
        .then((response) => {
          {console.log(JSON.stringify(response))}
          window.localStorage.setItem("access_token", response.data.access_token);
         
          window.localStorage.setItem("refresh_token", response.data.refresh_token);
          // watchLocation()
           // console.log('new access in +'    window.localStorage.setItem("access_token", res.access_token)
          window.location.reload()
          console.log("New Token Recieved");
           return axiosFetch();
        })
        .catch((err) => {
          console.log('seems it has expired')
          window.localStorage.clear();
          // window.localStorage.removeItem("refresh_token");
          window.location.replace("/signin");
          window.location.reload()
          // const reloadCount = window.localStorage.getItem("reloadCount");
          // if (!reloadCount) {
          //   localStorage.setItem("reloadCount", String(reloadCount + 1));
          //   // alert('Seems like its been a while since you were here. Kindly Login.')
          // }
         
        
          localStorage.setItem("auth_timeout", 'true');
          return Promise.reject(err);
        });

      }

      // console.log('seems it has expired 2222')
      // alert('you will be redirected to signin again')
      //  window.localStorage.clear();
      //  // window.localStorage.removeItem("refresh_token");
      //  window.location.replace("/signin");
      return Promise.reject(error);
    }
  );

  export default axiosFetch;