import React, { Component, useEffect, useState } from "react";
import "./comment.css";
import { Link } from "react-router-dom";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import ShowMoreText from "react-show-more-text";
import { format, formatDistance, subDays } from "date-fns";
import CreateReply from "./CreateReply";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function SingleReply(props) {
  //   const [replyOpen, setReplyOpen] = useState(false)
  const [replyOpen, setReplyOpen] = useState(false);
  const [currentReply, setCurrentReply] = useState("");
  const [isLiking, setIsLiking] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  function closeSnack() {
    setIsFavorite(false);
    setIsLiked(false);
    setIsDeleted(false);
  }

  function toggleReplyView() {
    showReplies == false ? setShowReplies(true) : setShowReplies(false);
  }

  async function favComment(id) {
    setIsFavoriting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/fav/${id}/${props.favUrl}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsFavoriting(false);
        console.log(`hiii ${resp.data}`);
        setIsFavorite(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteComment(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .delete(`/comment/${id}/${props.deleteUrl}`, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsDeleting(false);
        setIsDeleted(true);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function likeReply(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/${id}/${props.likeUrl}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
        setIsLiked(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  return (
    <>
      {props.replies.map((reply) => (
        <>
          <div key={reply.id}>
            <div className="flex">
              <div
                className="d-flex"
                style={{ marginLeft: 70, marginRight: 10, marginTop: 15 }}
              >
                <img
                  src={reply.user.profile_pic}
                  alt=""
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: 10,
                    border: "2px solid #045de9",
                  }}
                />
              </div>
              <div
                style={{ width: "100%", marginTop: 10 }}
                className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-15  dark:bg-gray-800 dark:text-gray-100"
              >
                <div className="d-flex">
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    <Link to={`/profile/`}>@{reply.user.user_name}</Link>
                  </h4>
                </div>
                {/* <p className="leading-6"> */}
                {/* <span style={{ marginRight: 20 }}> */}
                <ShowMoreText
                  lines={1}
                  more="Read more"
                  less="Show less"
                  // className="leading-6"
                  className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                  anchorClass="my-anchor-css-class"
                  width={300}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {reply.message}
                </ShowMoreText>
                {/* </span> */}
                {/* </p> */}

                <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                  {formatDistance(new Date(reply.created_at), new Date(), {
                    addSuffix: true,
                  })}{" "}
                  |
                </span>

                <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                  {" "}
                  <b>{reply.likes.length}</b> likes |
                </span>

                <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
              </div>
              <div className="flex" style={{ marginTop: 10 }}>
                {
                  isLiking  && currentReply == reply.id ? (
                    <HourglassEmptyRoundedIcon />
                  ) : (
                    <ThumbUpAltOutlinedIcon
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        setCurrentReply(reply.id)
                        likeReply(reply.id)}}
                    />
                  )

                  // <PushPinOutlinedIcon onClick={() => favComment(reply.id)} />
                }
                {/* {props.userId} {props.authId} */}
                {isFavoriting  && currentReply == reply.id ? (
                  <HourglassEmptyRoundedIcon />
                ) : (
                  <>
                    {props.authId == props.userId ? (
                      <>
                        <PushPinOutlinedIcon
                          onClick={() => {
                            setCurrentReply(reply.id)
                            favComment(reply.id)}}
                        />
                        {/* <DeleteIcon onClick={() => deleteComment(reply.id)}  /> */}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}

                {isDeleting && currentReply == reply.id ? (
                  <HourglassEmptyRoundedIcon />
                ) : (
                  <>
                    {props.authId == props.userId ? (
                      <>
                        <DeleteIcon onClick={() => {
                          setCurrentReply(reply.id)
                          deleteComment(reply.id)}} />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>

            {isDeleted ? (
              <Snackbar
                open={isDeleted}
                autoHideDuration={2000}
                onClose={closeSnack}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Alert onClose={closeSnack} variant="filled" severity="success">
                  Reply deleted successfully!
                </Alert>
              </Snackbar>
            ) : (
              ""
            )}

            {isLiked ? (
              <>
                <Snackbar
                  open={isLiked}
                  autoHideDuration={2000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Liked Successfully
                  </Alert>
                </Snackbar>
              </>
            ) : null}

            {isFavorite ? (
              <>
                <Snackbar
                  open={isFavorite}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Pinned To Favorite Successfully
                  </Alert>
                </Snackbar>
              </>
            ) : null}

            {/* <div key={reply.id} style={{ marginLeft: 30 }}>
            <div
              className="d-flex"
              style={{ marginLeft: 10, marginRight: 10, marginTop: 15 }}
            >
              <img
                src={reply.user.profile_pic}
                alt=""
                style={{
                  width: 40,
                  height: 40,
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: 10,
                  border: "2px solid #045de9",
                }}
              />

              <div className="d-flex">
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  <Link to={`/profile/`}>@{reply.user.user_name}</Link>
                </h4>
              </div>

              <div
                style={{
                  marginLeft: 15,
                  width: "100%",
                  height: "100%",
                  marginBottom: 10,
                }}
              >
              
                <ShowMoreText
                  lines={1}
                  more="Read more"
                  less="Show less"
                  className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                  anchorClass="my-anchor-css-class"
                  width={300}
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {reply.message}
                </ShowMoreText>
              </div>

              <FavoriteRoundedIcon
                style={{ marginLeft: 10 }}
                onClick={() => likeReply(reply.id)}
              />
              <PushPinOutlinedIcon onClick={() => favComment(reply.id)} />
            </div>
            <div className="shareBottom" style={{ marginLeft: 170 }}>
              <div className="shareOptions">
                
                <div className="shareOption">
              
                  <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                    {formatDistance(new Date(reply.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </span>
                </div>
                <div className="shareOption">
               
                  <span className="fw-500 text-grey-600 font-xssss mt-1 pointer">
                    {" "}
                    <b>{reply.likes.length}</b> likes
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </>
      ))}
      {/* <span
        className="fw-600 text-grey-900 mt-2 pointer"
        onClick={toggleReplyView}
      >
        
        Close Reply
      </span> */}
    </>
  );
}
