import React, { Component , Fragment } from "react";
import { Link } from 'react-router-dom';

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


class NotFoundPage extends Component {
    render() {
        return (
            <h1> Page Not Found, Kindly Go Back </h1>
        );
    }
}

export default NotFoundPage;