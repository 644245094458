import React, { Component, useState, useEffect } from "react";

import "./movie-card.scss";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import { format, formatDistance, subDays } from "date-fns";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "../button/Button";
import MuiAlert from "@material-ui/lab/Alert";

import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

import makeAnimated from "react-select/animated";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import ChannelsContentEdit from "../../components/ChannelsContentEdit";
import AddCasts from "../../components/AddCasts";
import ContentBuyers from "../../components/ContentBuyers";
// import IconButton from "@mui/material/IconButton";

const MovieCard2 = (props) => {
  // const item  = props.item;
  const movie = props.movies;
  const id = props.id;
  const type = props.type;
  const url = props.url;
  const channelSlug = props.channelSlug;
  const channelId = props.channelId;
  const adminId = props.adminId;
  const { channel } = useParams(["channel"]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("released");
  const [isCreating, setIsCreating] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [overview, setOverview] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [image, setImage] = useState("");
  const animatedComponents = makeAnimated();
  // const { url } = useParams();

  let history = useHistory();

  const handleClose = () => {
    // setOpen(false);
    setConfirmDelete(false);
    // setEmptyFields(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  function refresh() {
    window.location.reload();
  }

  function ChangeTag(value) {
    console.log(`selected ${value}`);
    // setAlertTagsName(value);

    // console.log(`state ${alertTagsName}`);

    value.map((tag) =>
      // alertTagsName.push(tag)
      tags.push(tag.value)
    );

    console.log(`name ${tags}`);
  }
  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }
  async function deleteVideo(id) {
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");
    // alert(id)
    axiosFetch
      .delete(
        `/movie/${id}`,
        // id,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        props.functionProp()

        // window.location.reload();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }
  async function createSeries() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("channel_id", props.channelId);
    formData.append("title", title);
    formData.append("tags", tags);
    formData.append("overview", overview);
    formData.append("image", image);
    formData.append("cover_image", coverImage);
    // formData.append("pricing", pricing);
    formData.append("status", status);

    axiosFetch
      .post(
        `/movie/series/${props.channelId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(`hi ${resp.data}`);
        setTitle("");
        setTags("");
        setOverview("");
        setImage("");
        setCoverImage("");
        setIsCreating(false);
        // props.functionProp();
        setOpen(false);
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }
  // useEffect(() => {}, [console.log(status)]);
  return (
    <>
      <div
        className="movie-card overflow-hidden"
        style={{
          backgroundImage: `url("${movie.image}")`,
        }}
      >
        {/* <Link
          to={{
            pathname: `${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
            // state: { channelSlug:channelSlug, id: movie.id, adminId: adminId },
          }}
        >
          <Button
            className="btn-outline"
            // onClick={() => history.push( url +'/' + movie.slug)}
          >
            <i className="bx bx-play"></i>
          </Button>
        </Link> */}
        
        <div style={{display:'flex', alignSelf:'center', justifyContent:'center'}}>
        <IconButton   className="btn-outline">
          <i className="bx bx-play"  
          style={{ color: "#fff", fontSize: 50 }}
            onClick={() => {
              history.push({
                pathname: `${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
              })
              window.location.reload()
            }
          }></i>
        </IconButton>
        {props.isAdmin ? (
          <>
            <ChannelsContentEdit
              movie={movie}
              title={movie.title}
              overview={movie.overview}
              tags={movie.tags}
              image={movie.image}
              cover_image={movie.cover_image}
              type={movie.type}
              tags={movie.tags}
              status={movie.status}
              pricing={movie.pricing}
              price={movie.price}
              movieId={movie.id}
              channelId={props.channelId}
              functionProp={props.functionProp}
              functionProp2={props.functionProp2}
            />
            <AddCasts movieId={movie.id} color='#fff' getUrl='casts/movie/get' addUrl='casts' removeUrl='casts' type='movie' channelId={props.channelId} movieType ={movie.type}/>
            <Tooltip title="Delete Content">
              <IconButton>
                <DeleteForeverOutlinedIcon
                  onClick={() => confirmDeletion(movie.id)}
                  style={{ color: "#fff", fontSize: 30 }}
                />{" "}
              </IconButton>
            </Tooltip>
            {movie.pricing == 'paid' ?
              <ContentBuyers buyers={movie.buyers} movieTitle={movie.title} color='#fff' movieType ={movie.type} />
             :
            ''}
          
          </>
          
        ) : (
          ""
        )}
        </div>

        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {movie.title}
          {/* {movie.type} */}
        </h4>

        {props.isAdmin ? (
          <>
          <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
            {/* {movie.title} */}
            Status: {movie.status}
          </h4>
          <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
            {/* {movie.title} */}
            Pricing: {movie.pricing}  {movie.price}
          </h4>
          </>
        ) : (
          <>
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
              {/* {movie.title} */}
              Type: {type}
            </h4>
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
              {/* {movie.title} */}
              Pricing: {movie.pricing}  {movie.price}
            </h4>
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
              {/* {movie.title} */}
              Released:{" "}
              {formatDistance(new Date(movie.created_at), new Date(), {
                addSuffix: true,
              })}
            </h4>
          </>
        )}
        {confirmDelete ? (
          <MuiAlert
            onClose={handleClose}
            severity="error"
            action={
              <a
                onClick={() => deleteVideo(movie.id)}
                // onClick={deleteVideo(movie.id)}
                // onClick={deleteVideo}
                //  style={{ marginLeft: 80 }}
                className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
              >
                Yes, delete it.
              </a>
            }
          >
            Are you sure you want to delete this ?
          </MuiAlert>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MovieCard2;
