import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import { Upload } from "@aws-sdk/lib-storage";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import VideoRecorder from "react-video-recorder";
import { fabClasses } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import MicIcon from "@mui/icons-material/Mic";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Select from "react-select";
import { Camera } from "react-camera-pro";
import { MultiStepForm, Step } from "react-multi-form";


export default function CreateAlertQuick(props) {
  // const classes = useStyles();
  const camera = useRef(null);
  const [record, setRecord] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [audioData, setAudioData] = React.useState("");
  const [audioFile, setAudioFile] = React.useState("");
  const [recordState, setRecordState] = React.useState(null);
  const [status, setStatus] = useState("");
  const [active, setActive] = React.useState(1);

  // function start() {
  //   setRecordState(RecordState.START);
  // }
  // function pause() {
  //   setRecordState(RecordState.PAUSE);
  // }

  // function stop() {
  //   setRecordState(RecordState.STOP);
  // }

  //audioData contains blob and blobUrl
  function onStop(audioData) {
    console.log("audioData", audioData);
    console.log("audioData blob is ", audioData.blob);
    setAudioData(audioData.url);
    setAudioFile(audioData.blob);
  }

  const [checked, setChecked] = React.useState(false);
  const [showLive, setShowLive] = useState(false);
  const [showRec, setShowRec] = useState(false);
  const [showPic, setShowPic] = useState(false);
  const [audioDetails, setAudioDetails] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const animatedComponents = makeAnimated();
  const [alertTags, setAlertTags] = useState([]);
  const [alertTagsName, setAlertTagsName] = useState([]);
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const ip_address = window.sessionStorage.getItem("ip");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");

  const options = [
    { value: "alert", label: "Alert" },
    { value: "emergency", label: "Emergency" },
    { value: "lagos-state", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];
  const handleModalClose = () => {
    setOpen(false);
    setCreated(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [isBodyOpen, setIsBodyOpen] = useState(false);
  const [body, setBody] = useState("");
  const [headline, setHeadline] = useState("");
  const CHARACTER_LIMIT = 60;
  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertCategoryName, setAlertCategoryName] = useState("");

  let history = useHistory();
  const bodyLocal = window.localStorage.getItem("body");
  const headlineLocal = window.localStorage.getItem("headline");

  const firstName = window.localStorage.getItem("first_name");
  const user_name = window.localStorage.getItem("user_name");
  const profile_pic = window.localStorage.getItem("profile_pic");
  const verified = window.localStorage.getItem("verified");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [compImages, setCompImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [alertImage, setAlertImage] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  //   const [isSuccess, setIsSuccess] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [emptyBody, setEmptyBody] = useState(false);
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [sendToFriends, setSendToFriends] = useState("no");
  const [created, setCreated] = useState(false);

  const [error, setError] = useState(false);
  const loopData = [];

  const Input = styled("input")({
    display: "none",
  });

  const StyledInput  = styled("div")({
    width: '100%',
  padding: '10px 20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  fontSize: '0.95rem',
  outline: 'none',
  marginTop: '0.2rem',
  marginBottom: '1rem',
  boxSizing: 'border-box',

  // &:focus {
  //   border: '1.5px solid #24a19c',
  // }
  });


  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setEmptyBody(false);
    setCreatedCat(false);
    setCreated(false);
    setError(false);
  }

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  function handleClose2(event, reason) {
    // if (reason === "clickaway") {
    //   return;
    // }
    setIsSuccess(false);
    setBody("");
    localStorage.removeItem("body");
    localStorage.removeItem("headline");
    setImage("");
    props.functionProp();
    props.functionProp2();
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleAudioStop(data) {
    console.log("stop ", JSON.stringify(data));
    console.log("stop blob ", data.blob);
    setAudioDetails(data);
    setAudioData(data.url);
    setAudioFile(data.blob);
  }

  function handleReset() {
    setAudioData("");
    setAudioFile("");
    setAudioDetails("");
  }
  function clearAudio() {
    setAudioData("");
    setAudioFile("");
    setAudioDetails("");
  }

  const ChangeAlertCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setAlertCategory(value.id);
      setAlertCategoryName(value.name);
      console.log(alertCategory);
      console.log(alertCategoryName);
      // setHeadline(`New ${alertCategoryName} Alarrt `)
      // handleClickOpen()
    } else {
      setAlertCategory("");
      setAlertCategoryName("");
      console.log("value empty");
      //   getTags();
    }
  };

  async function validateImage(e, value) {
    // setAlertImage([])
    setAlertImage([...e]);
    alertImage.push(e);
    for (let i = 0; i < alertImage[0].length; i++) {
      alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
    }
    setAlertImage(alertImageUrls);
    // onchange()
  }

  // async function validateImageTest(e, value) {
  //   // setAlertImage([])
  //   setAlertImage([...e]);
  //   alertImage.push(e);
  //   for (let i = 0; i < alertImage[0].length; i++) {
  //     const imgSr = URL.createObjectURL(alertImage[0][i]);

  //     alertImageUrls.push(imgSr)
  //     // URL.createObjectURL(alertImageUrls[0][i]);
  //     // alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
  //   }
  //   setAlertImage(alertImageUrls);
  //   // onchange()
  // }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  const onChange3 = async (event) => {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  function validate(e) {
    //    alert(this.state.body)
    if (!headline || !body || !alertCategory || !location) {
      setEmptyBody(true);
      return;
    } else {
      // createPost();
      uploadVideo();
    }
  }

  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "alerts/videos/" + user_id + "/" + uuidv4();
      // video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/305/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed. Please be patient`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            createAlert();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      createAlert();
      console.log("video very empty");
    }
  }

  async function createAlert() {
    setCreatingPost(true);
    setCreatingMessage("Creating Your Alarrt, almost done");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", alertCategory);
    // formData.append("communities_id", '');
    formData.append("communities_id", props.communityId);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("tags", alertTagsName);
    formData.append("audio", audioFile);
    // formData.append("url", url);
    formData.append("location", location);
    formData.append("alert_type", 'public');
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }

    // formData.append("tags", alertTagsName);
    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "alert");
    formData.append("status", "active");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingPost(false);
          props.functionProp();
          props.functionProp2();
          setHeadline("");
          setBody("");
          setAlertTagsName([]);
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("postVideo");
          localStorage.removeItem("body");
          localStorage.removeItem("headline");
          setAlertCategory("");
          setAlertCategoryName("");
          setLocation("");
          setVideo("");
          setShowLive(false);
          setShowRec(false);
          setAudioFile("");
          setAudioData("");
          setAudioDetails("");
          setImages([]);
          setAlertImage([]);
          setAlertImageUrls([]);
          setIsSuccess(true);
          setCreated(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingPost(false);
          //   setCreated(false);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
  }

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      setChecked(true);
    } else {
      setShowLive(false);
      setChecked(false);
    }
    // setChecked(event.target.checked);
  };

  function ChangeAlertTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => alertTagsName.push(tag.value));
    console.log(`name ${alertTagsName}`);
  }

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/alert_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getAlertCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function validateImage2(value) {
    if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      setInvalidFile("Please select a valid image.");
      return false;
    } else {
      console.log(value);
      setImage(value);
      setInvalidFile(false);
    }
  }

  function toggleBodyOpen() {
    setIsBodyOpen(!isBodyOpen);
  }

  function getLocation() {
    if ("geolocation" in navigator) {
      console.log("Location is Available");
    } else {
      console.log("Location is Not Available");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Lat is :", position.coords.latitude);
      console.log("Lon is :", position.coords.longitude);
      window.sessionStorage.setItem("lat", position.coords.latitude);
      window.sessionStorage.setItem("lon", position.coords.longitude);
    });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("full posoition " + position);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        console.log("Watch Lat is :", position.coords.latitude);
        console.log("Watch Lon  is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
  }

  const statusSelect = allCategories.map((cat, index) => {
    return {
      id: index,
      value: cat.id,
      label: cat.name,
    };
  });

  // const statusSelect = [
  //   { value: "released", label: "Released" },
  //   { value: "draft", label: "Draft" },
  // ];

  function changeStatus(value) {
    console.log(`selected ${JSON.stringify(value)}`);
    setStatus(value.label);
    setAlertCategory(value.value);
    setAlertCategoryName(value.label);
    // setBody(`There is a ${value.label} ... going on in ... `);
  }

  useEffect(
    () => {
      getAlertCategories();
      getLocation();
      watchLocation();

      if (bodyLocal) {
        setBody(bodyLocal);
      }
      if (headlineLocal) {
        setHeadline(headlineLocal);
      }
    },
    [
      // console.log(
      //   `alertImageUrls ${JSON.stringify(
      //     alertImageUrls
      //   )}, alertImage ${JSON.stringify(alertImage)}`
      // ),
    ]
  );

  // render() {
  // const menuClass = `${isOpen ? " show" : ""}`;
  //    const {user_name} = this.state;

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0">
        <h3 className="fw-600 text-grey-900 font-xss mt-0 mb-3 me-4">
          {" "}
          Create Alarrt Quickly Here
        </h3>

        <h3 className="fw-600 text-grey-900 font-xssss mt-0 me-4">
          Hi {firstName}! What kind of info / crisis are you alarrting?
        </h3>
        <Typography gutterBottom variant="body1">
          Select what is happening below 
        </Typography>
        <div className="card-body" onClick={handleClickOpen}>
          {/* <Select
            // isClearable
            components={animatedComponents}
            // defaultValue={status}
            onClick={handleClickOpen}
            options={statusSelect}
            onChange={changeStatus}
            placeholder="select alarrt category"
            defaultInputValue={status}
          /> */}
          <Autocomplete
            id="combo-box-demo"
            options={allCategories}
            onChange={ChangeAlertCategory}
            // onClick={handleClickOpen}
            // onChange={(e) => setAlertCategoryName(e.target.value.name) }
            inputValue={alertCategoryName}
            getOptionLabel={(option) => option.name}
            fullWidth
            PaperComponent={({ children }) => (
              <Paper style={{ background: "#fff", width: "100%" }}>
                {children}
              </Paper>
            )}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Alarrt Category"
                variant="standard"
                style={{ backgroundColor: "pink !important" }}
              />
            )}
            // required
          />
        </div>

        <Dialog open={open} onClose={handleModalClose}>
          <DialogTitle>
            {" "}
            Hi {firstName}! Is Happening?
            {props.channelId}{" "}
          </DialogTitle>
          <DialogContent>
            <div >
              {/* <DialogContentText> className="card-body"
                You can type, snap, record audio or video of what is happening
              </DialogContentText> */}
              {/* <br /> hj*/}
              <h4 className="fw-600 text-grey-900 font-xss mt-0 mb-0 me-4">
                Tell us what you're alarrting below. You can also snap, record audio or
                video of what is happening.
              </h4>
              <br/>
            </div>

            <div>
              <MultiStepForm activeStep={active} style={{color:'red'}}>
                <Step label="1" active="1">
                  <div >
                  {/* className="card-body" */}
                  <h3 className="fw-600 text-grey-900 font-xsss mt-0 mb-0 me-4">
                  Select what kind of info you are alarrting
              </h3><br/>
                    <Typography
                      gutterBottom
                      variant="overline"
                      style={{ color: "blue" }}
                    >
                      Select from existing categories, or create new one below
                    </Typography>
                    <Select
                      // isClearable
                      components={animatedComponents}
                      // defaultValue={status}
                      options={statusSelect}
                      onChange={changeStatus}
                      placeholder="select alarrt category"
                      defaultInputValue={status}
                    />
                    {/* <Autocomplete
                id="combo-box-demo"
                options={allCategories}
                onChange={ChangeAlertCategory}
                // onChange={(e) => setAlertCategoryName(e.target.value.name) }
                inputValue={alertCategoryName}
                getOptionLabel={(option) => option.name}
                fullWidth
                PaperComponent={({ children }) => (
                  <Paper style={{ background: "#fff", width: "100%" }}>
                    {children}
                  </Paper>
                )}
                style={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Alert Category"
                    variant="standard"
                    style={{ backgroundColor: "pink !important" }}
                  />
                )}
                required
              /> */}
                    <br />
                    <div className="d-flex">
                      <TextField
                        id="standard-basic"
                        label="Or Create New Category"
                        size="small"
                        // style={{width:'40%'}}
                        style={{ width: "80%" }}
                        onChange={(e) => setNewCategory(e.target.value)}
                        value={newCategory}
                      />
                      {!newCategory ? (
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          // component="span"
                          style={{ marginLeft: 7 }}
                        >
                          Type First
                        </Button>
                      ) : (
                        <>
                          {creatingCat ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                              // component="span"
                              style={{ marginLeft: 7 }}
                            >
                              Creating
                            </Button>
                          ) : (
                            <>
                              <Button
                                onClick={createCategory}
                                variant="contained"
                                color="primary"
                                // component="span"
                                style={{ marginLeft: 7 }}
                              >
                                Create
                              </Button>
                              <br />
                              <br />

                              {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Step>

                <Step label="2" active="2">
                  <div >
                {/*   <h3 className="fw-600 text-grey-900 font-xsss mt-0 mb-0 me-4">
                  Alarrt what is going onnn.
              </h3><br/> */}
                    <Typography
                      gutterBottom
                      variant="overline"
                      style={{ color: "blue" }}
                    >
                      Alarrt Summary e.g Massive Standstill Along Ojodu Berger
                      Express
                    </Typography>
                  
                    <TextField
                      id="standard-basic"
                      onClick={() => {
                        // handleClickOpen();
                        getLocation();
                        watchLocation();
                      }}
                      // onClick={handleClickOpen}

                      label={`Your headline`}
                      required
                      maxLength={10}
                      size="small"
                      style={{ width: "100%", fontSize: 4, color: "red" }}
                      onChange={(e) => {
                        window.localStorage.setItem("headline", e.target.value);
                        setHeadline(e.target.value);

                        if(e.target.value.length===CHARACTER_LIMIT){ 
                          window.alert("Oops! Maximum word exceeded. Tell us more below")
                        }
                      }}
                      defaultValue={window.localStorage.getItem("headline")}
                      value={headline}
                      inputProps={{
                        maxlength: CHARACTER_LIMIT
                      }}
                      helperText={`${headline.length}/${CHARACTER_LIMIT}`}
                      // value={headline}
                      LabelProps={
                        {
                          // className: classes.textInput,
                        }
                      }
                    />
                    {/* {isBodyOpen && ( */}
                    <div className="card-body p-0 mt-3 position-relative">
                      <Typography
                        gutterBottom
                        variant="overline"
                        style={{ color: "blue" }}
                      >
                        Give more details here. e.g How critical is what is
                        happening and any other important info
                      </Typography>
                      <figure className="avatar position-absolute ms-2 mt-1">
                        <img
                          src={profile_pic}
                          alt="avater"
                          className="shadow-sm rounded-circle w45"
                          style={{
                            marginRight: 30,
                            maxWidth: 35,
                            height: 35,
                            border: "2px solid #045de9",
                          }}
                        />
                      </figure>
                      <textarea
                        name="message"
                        style={{ paddingLeft: 50, paddingTop: 10 }}
                        className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                        cols="20"
                        rows="10"
                        // style=
                        placeholder="Give full details here. e.g A petrol tanker just fell on Ojodu Berger express, It's contents are still spilling out and traffic is building up. Help is needed to help those stuck here."
                        onChange={(e) => {
                          window.localStorage.setItem("body", e.target.value);
                          setBody(e.target.value);
                        }}
                        defaultValue={window.localStorage.getItem("body")}
                        value={body}
                        // this.setState({ isOpen: !this.state.isOpen })
                      ></textarea>
                    </div>
                  </div>
                </Step>

                <Step label="3" active="3">
                   <div > 
               {/*   <h3 className="fw-600 text-grey-900 font-xsss mt-0 mb-0 me-4">
                  Where is this happening?
              </h3><br/> */}
                    <TextField
                      id="standard-basic"
                      label={"Where is it happening?"}
                      // required
                      size="small"
                      style={{ width: "100%", marginTop: 10, fontSize: 4 }}
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      LabelProps={
                        {
                          // className: classes.textInput,
                        }
                      }
                    />
                    <Typography
                      gutterBottom
                      variant="caption"
                      // className={classes.postHead}
                    >
                      Location is required! Use This Format Please:
                      Country->State->Area (e.g. Nigeria->Lagos->Ikeja)
                    </Typography>
                    <br />
                  </div>
                </Step>

                <Step label="4" active="4">
                  <div>
                  <h3 className="fw-600 text-grey-900 font-xsss mt-0 mb-0 me-4">
                  Upload media files and show others what is happening - Optional.
              </h3><br/>
                  
                    <Stack direction="row" spacing={1}>
                      {alertImageUrls.length == 0 ? (
                        <Tooltip
                          title="Select images from gallery"
                          arrow
                          placement="bottom"
                        >
                          <label htmlFor="icon-button-file-2">
                            <Input
                              accept="image/*"
                              id="icon-button-file-2"
                              multiple
                              type="file"
                              onChange={(e) => {
                                console.log([...e.target.files]);
                                validateImage(e.target.files);
                                onChange3(e);
                              }}
                            />
                            <IconButton
                              color="primary"
                              // size="large"
                              aria-label="upload picture"
                              component="span"
                            >
                              <ImageSearchIcon
                                color="primary"
                                style={{ fontSize: 24 }}
                              />
                            </IconButton>
                          </label>
                        </Tooltip>
                      ) : (
                        <IconButton
                          disabled
                          // color="primary"
                          // size="large"
                          aria-label="upload picture"
                          component="span"
                        >
                          <ImageSearchIcon
                            // color="primary"
                            style={{ fontSize: 24 }}
                          />
                        </IconButton>
                      )}

                      {/* Should be able to take mutiple pics */}
                      {/* <Tooltip title="Take A Picture" arrow placement="bottom">
                    <IconButton
                      // size="large"
                      aria-label="delete"
                      color="primary"
                      style={{ fontSize: 34 }}
                    >
                      <AddPhotoAlternateIcon
                        color="primary"
                        style={{ fontSize: 30 }}
                        onClick={() => setShowPic(!showPic)}
                      />
                    </IconButton>
                  </Tooltip> */}

                      <Tooltip title="Record a video" arrow placement="bottom">
                        <IconButton
                          // size="large"
                          color="secondary"
                          aria-label="add to shopping cart"
                          style={{ fontSize: 24 }}
                        >
                          <VideoCallIcon
                            onClick={() => setShowLive(!showLive)}
                            color="secondary"
                            style={{ fontSize: 24 }}
                          />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Upload A Video" arrow placement="bottom">
                        <label htmlFor="icon-button-file">
                          <Input
                            accept="video/*"
                            id="icon-button-file"
                            // multiple
                            type="file"
                            onChange={(e) => {
                              if (e.target.files[0].size > 300000 * 1028) {
                                setUploadLimit(true);
                              } else {
                                setUploadLimit(false);
                                console.log(e.target.files[0]);
                                setVideo(e.target.files[0]);
                              }
                            }}
                          />
                          <IconButton
                            // size="large"
                            color="primary"
                            aria-label="upload video"
                            component="span"
                          >
                            <VideoLibraryIcon
                              color="primary"
                              color="primary"
                              style={{ fontSize: 24 }}
                            />
                          </IconButton>
                        </label>
                      </Tooltip>

                      <Tooltip
                        title="Record A Voice Note"
                        arrow
                        placement="bottom"
                      >
                        <IconButton
                          // size="large"
                          color="secondary"
                          aria-label="record audio"
                          style={{ fontSize: 24 }}
                        >
                          <MicIcon
                            onClick={() => setShowRec(!showRec)}
                            color="secondary"
                            style={{ fontSize: 24 }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>

                    {showPic ? (
                      <>
                        <div
                          style={{
                            width: "70%",
                            height: 300,
                            backgroundColor: "red",
                          }}
                        >
                          {/* <Camera ref={camera} /> */}
                          <Camera
                            isFullscreen={false}
                            onTakePhoto={(dataUri) => {
                              setImage(dataUri);
                            }}
                          />
                        </div>
                        <button
                          onClick={() => setImage(camera.current.takePhoto())}
                        >
                          Take photo
                        </button>
                        <img src={image} alt="Taken photo" />
                      </>
                    ) : (
                      ""
                    )}

                    {uploadLimit ? (
                      <div>
                        <MuiAlert
                          severity="warning"
                          onClose={closeError}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum size allowed is 300 mb
                        </MuiAlert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                        <a
                          href="https://www.freeconvert.com/video-compressor"
                          target="_blank"
                          className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
                        >
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Quickly compress it
                          </span>{" "}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}

                    {!alertImageUrls.length == 0 ? (
                      <>
                        <br />
                        {/* <Typography gutterBottom variant="subtitle2">
                    Slide to view all
                  </Typography> */}
                        <h4> Slide to view all</h4>

                        <Carousel>
                          {alertImageUrls.map((slideImage, index) => (
                            <img
                              src={slideImage}
                              key={index}
                              style={{ zIndex: -10 }}
                              alt=""
                            />
                          ))}
                        </Carousel>

                        {/* <div data-uk-slider="finite: true center: true autoplay: true">
                    <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
                      {alertImageUrls.map((imageSrc) => (
                        <li style={{ marginRight: 10 }}>
                          <img src={imageSrc} alt="" />
                        </li>
                      ))}
                    </ul>
                  </div> */}

                        <Button
                          // onClick={() => setAlertImageUrls([])}
                          onClick={clearImages}
                        >
                          Clear Images
                        </Button>
                        {/* {/* <br /> */}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    <div>
                      {video.length !== 0 ? (
                        <>
                          <br />
                          <Typography gutterBottom variant="subtitle2">
                            Your alarrt's video
                          </Typography>

                          <br />
                          <video width="400" controls>
                            <source src={URL.createObjectURL(video)} />
                          </video>
                          <br />
                          <br />
                          {creatingPost ? (
                            <Button disabled>{creatingMessage}</Button>
                          ) : (
                            <Button
                              onClick={
                                () => {
                                  setVideo("");
                                  setShowLive(false);
                                }
                                //   this.setState({ profile_pic: "" })
                              }
                            >
                              Remove
                            </Button>
                          )}

                          <br />
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <br />
                    {showRec ? (
                      <>
                        <Typography
                          gutterBottom
                          variant="body1"
                          // className={classes.postHead}
                        >
                          Record a voice note of what's happening
                        </Typography>
                        <Recorder
                          record={true}
                          uploadButtonDisabled={true}
                          title={"Record What Is Happening"}
                          audioURL={audioDetails.url}
                          // showUIAudio
                          handleAudioStop={(data) => handleAudioStop(data)}
                          // handleAudioUpload={(data) => handleAudioUpload(data)}
                          // handleCountDown={(data) => handleCountDown(data)}
                          handleReset={() => handleReset()}
                          mimeTypeToUseWhenRecording={`audio/webm`} // For specific mimetype.
                        />
                        <br />
                        {/* <br /> */}

                        {/* <AudioReactRecorder state={recordState} onStop={onStop} />

                    <button onClick={start}>Start</button>
                    
                    <button onClick={stop}>Stop</button> */}
                      </>
                    ) : (
                      <></>
                    )}
                    {audioData && (
                      <>
                        {/* <br/> */}
                        <h4> Your Recorded Audio.</h4>
                        <audio controls>
                          <source src={audioData} type="audio/mpeg" />
                        </audio>
                        <br />
                        <Button onClick={clearAudio}>Remove</Button>
                      </>
                    )}
                    {showLive ? (
                      <>
                        <Typography
                          gutterBottom
                          variant="body1"
                          // className={classes.postHead}
                        >
                          You can take a live recording using either your front
                          or back camera. If you have a video recorded already,
                          use the upload video button instead. Press "Rec" when
                          ready and wait for the countdown
                        </Typography>
                        <VideoRecorder
                          isOnInitially={true}
                          countdownTime={1000}
                          onRecordingComplete={(videoBlob) => {
                            // Do something with the video...
                            console.log("videoBlob", videoBlob);
                            setVideo(videoBlob);
                            toogleLiveVid();
                          }}
                        />
                        <br />
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </Step>
              </MultiStepForm>
            </div>

            <br />
            <br />
            <div className="mt-3">
              {!alertCategoryName || !headline || !body || !location ? (
                <>
                  <Typography gutterBottom variant="body1">
                    Hi {firstName}! You need to type in your headline, body,
                    location and category to create this alarrt.
                  </Typography>
                  <br />
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleModalClose}
                  >
                    Close
                  </Button> */}
                </>
              ) : (
                <>
                  {!creatingPost ? (
                    <>
                      <Typography gutterBottom variant="body1">
                        You may create your alarrt now. Provide more proof
                        (picture, video, voice recording) on what's going on and
                        post it using the buttons below, or edit your alarrt
                        after creation to add them
                      </Typography>
                      {/* <Button
                        //  disabled
                        variant="contained"
                        color="primary"
                        component="span"
                        //  style={{ marginLeft: 7 }}
                        onClick={validate}
                      >
                        Create Alarrt Now
                      </Button> */}
                    </>
                  ) : (
                    <>
                      <Typography gutterBottom variant="body1">
                        Notifications are being sent out. You can edit your
                        alarrt after creation to add more info, picture or
                        video of what is going on
                      </Typography>
                      {/* <Button
                        disabled
                        variant="contained"
                        color="primary"
                        // onClick={this.createPost}
                      >
                        Creating Your Alarrt
                      </Button>
                      <br /> */}
                      <h4>{creatingMessage}</h4>
                    </>
                  )}
                </>
              )}
            </div>
            <br/><br/>

            {active == 1 && (
               <Button
               variant="contained"
               color="primary"
               onClick={handleModalClose}
             >
               Close
             </Button>
            )}
          
            {active !== 1 && (
              <Button variant="contained" onClick={() => setActive(active - 1)}>
                Previous
              </Button>
            )}
            {active !== 4 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setActive(active + 1)}
                style={{ float: "right" }}
              >
                Next
              </Button>
            )}

            {active == 4 ? (
              <>
                {!alertCategoryName || !headline || !body || !location ? (
                  <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled
                    onClick={() => setActive(active + 1)}
                    style={{ float: "right" }}
                  >
                    Oops!
                  </Button>
                  </>
                 ) : (
                  <>
                    {!creatingPost ? (
                   <>
                        <Button
                          //  disabled
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{ marginLeft: 25 }}
                          onClick={validate}
                        >
                          ALARRT IT
                        </Button>
                      </>
                    ) : (
                      <>
                        
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          style={{ marginLeft: 15 }}
                          // onClick={this.createPost}
                        >
                          Alarrting...
                        </Button>
                        <br />
                        {/* <h4>{creatingMessage}</h4> */}
                      </>
                    )}
                  </>
                
                )}
              </>
            ) : (
              ""
            )}

            <br />
            <br />

         

            {invalidFile ? <h4> {invalidFile} </h4> : ""}
            {emptyBody ? (
              <div>
                <br />
                <MuiAlert severity="warning" onClose={handleClose}>
                  Oops! To create an alarrt, you need to ensure the headline,
                  body, location and category are not empty
                </MuiAlert>
                <br />
              </div>
            ) : (
              ""
            )}

            {createdCat ? (
              <>
                <Snackbar
                  open={createdCat}
                  autoHideDuration={4000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                >
                  <Alert onClose={handleClose} severity="success">
                    Category successfully created. Select it above
                  </Alert>
                </Snackbar>
              </>
            ) : (
              ""
            )}

            {created ? (
              <>
                <br />
                <Alert variant="filled" severity="success">
                  You are awesome {firstName}! Thank you for notifying us -
                  Notifications are being sent out and help will be dispatched
                  if this is a life threatnening emergency. Invite your friends
                  and family with your referral link so you can add them as your
                  close friend! Close friends are notified by default whenever
                  you realarrt or create an alarrt. Go to your profile to get your
                  referral link now.
                </Alert>
                <br />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleModalClose}
                >
                  Go Back Home
                </Button>
              </>
            ) : (
              ""
            )}



            {isLimit ? (
              <div>
                <Alert
                  severity="warning"
                  onClose={handleClose}
                  action={
                    <>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  Maximum upload is 4 images, kindly reduce.
                </Alert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
              </div>
            ) : (
              ""
            )}

            {error ? (
              <div>
                <Alert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue creating your alarrt. Kindly try again [{" "}
                  {errorMessage} {errorMessage2} ]
                </Alert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
              </div>
            ) : (
              ""
            )}
            <div style={{marginBottom:30}}>

            </div>
            {/* </div> */}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
// }

// export default CreatePost2;
