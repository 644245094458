import React,{Component} from 'react';
import axios from 'axios';
import axiosFetch from '../Interceptors2';
import {Button} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from '@mui/material/Alert';


class Createpost extends Component {
    state = {
        isOpen: false,
        body: '',
        headline: '',
        image: '',
        profile_pic: '',
        user_name: '',
        isSuccess: false,
        creatingPost: false,
        emptyBody: false,
        invalidFile: false,
    };
      constructor(props){
        super(props);
        this.state = {
            user_name: this.props.user_name,
        }
    }

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        this.setState({emptyBody: false});
    };
    handleClose2 = (event, reason) => {
        if (reason === "clickaway") {
        return;
        }
        this.setState({isSuccess: false});
        this.setState({body: ''})
    };
    Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    validateImage(value) {
        if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
          this.setState({ invalidFile: "Please select valid image." });
          // setInvalidFile("Please select valid image.");
          return false;
        } else {
          console.log(value);
          this.setState({ iamge: value });
          this.setState({ invalidFile: false });
        }
      }
    
  


    // profile = async () => {
    //     this.setState({user_name: await window.localStorage.getItem('user_name')})
    // }

    // createPost = async () => {
    //     console.log(this.state.body);
    // }

    validate = async (e) => {
    //    alert(this.state.body)
       if(this.state.body == '') {
            this.setState({emptyBody: true});
            return;
       }
       else{
           this.createPost()
       }

    }

    getProfile = () => {
        const access_token = window.localStorage.getItem('access_token')
        // console.log(`user profile ${access_token}`);
        axiosFetch.get('/users/profile', {
          method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + access_token,
            },
        
        }).then(resp => {
            // console.log(JSON.stringify(resp.data.user.profile_pic));
            this.setState({profile_pic: resp.data.user.profile_pic})
            // setUserPic(resp.data.user.profile_pic);
        })
        .catch(error => console.error(`Error Seen : ${error}`));
      }

    createPost = async () => {
    this.setState({creatingPost: true})
    const access_token = await window.localStorage.getItem('access_token')
    const userID = await window.localStorage.getItem('user_id')
    // const userUN = await window.localStorage.getItem('user_name')

    const formData = new FormData();
    formData.append('user_id', userID);
    // formData.append('alert_category_id', alertCategory);
    formData.append('body', this.state.body);
    // formData.append('headline', this.state.headline);
    formData.append('image', this.state.image);
    formData.append('type', 'post');
    formData.append('status', 'active');
    formData.append("communities_id", '');

    let result = await axiosFetch.post('/posts', formData, {
      headers: {
       'Accept': '*/*',
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      }
    })
    .then(resp => {
        // uploadImage();
        // console.log(resp.data)
        // console.log(this.state.body, this.state.headline, this.state.image);
        // this.setState({headline: ''})
        this.setState({image: ''})
        this.setState({body: ''})
        this.setState({creatingPost: false})
        this.setState({isSuccess: true})

    })
    .catch(error =>  console.log(`Error Seen : ${error}`));
   
  }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidMount() {
        this.getProfile();
    }

    render() {
        const menuClass = `${this.state.isOpen ? " show" : ""}`;
           const {user_name} = this.state;

            return (
                <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                    <div className="card-body p-0">
                        <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">What is on your mind {this.state.user_name}? </h3> 
                        {/* <a onClick={this.createPost} className="font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center"> */}
                        {/* <i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Click To Create Post</a> */}
                    </div>
                    <div className="card-body p-0 mt-3 position-relative">
                        <figure className="avatar position-absolute ms-2 mt-1 top-5"><img src={this.state.profile_pic} alt="pic" className="shadow-sm rounded-circle w30" /></figure>
                        <textarea name="message" className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-black-500 fw-500 border-light-md theme-dark-bg" cols="30" 
                        rows="10" placeholder="What's on your mind?"
                        onChange={e => this.setState({ body: e.target.value })}
                        // this.setState({ isOpen: !this.state.isOpen })
                        ></textarea>
                    </div>
                    <div className="card-body d-flex p-0 mt-0">
                        {/* <a href="#video" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-danger feather-video me-2"></i><span className="d-none-xs">Live Video</span></a> */}
                        <a href="#photo" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-success feather-image me-2"></i>
                        <span className="d-none-xs"> Photo/Video</span><input type="file" 
                        onChange={e => 
                            this.validateImage(e.target.files[0])}
                            // this.setState({ image: e.target.files[0] })}
                        />
                        </a>
                        {/* <a href="#activity" className="d-flex align-items-center font-xssss fw-600 ls-1 text-grey-700 text-dark pe-4"><i className="font-md text-warning feather-camera me-2"></i><span className="d-none-xs">Feeling/Activity</span></a> */}
                        {/* <a href="/" className="font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center"><i className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Click To Create Post</a> */}
                        <div className="ms-auto">
                        {!this.state.creatingPost ?
                            <a onClick={this.validate} className="pointer font-xssss fw-600 text-black-500 card-body p-0 d-flex align-items-center">
                            <i onClick={this.validate} className="btn-round-sm font-xs text-primary feather-edit-3 me-2 bg-greylight"></i>Create Post</a>
                        :
                        <Button
                        disabled
                        variant="contained"
                        color="primary"
                        // onClick={this.createPost}
                        >
                        Creating Your Post
                        </Button>
                        }
                        </div>
                        {/* <div className={`ms-auto pointer ${menuClass}`} id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false" onClick={this.toggleOpen}><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div>
                        <div className={`dropdown-menu p-4 right-0 rounded-xxl border-0 shadow-lg ${menuClass}`} aria-labelledby="dropdownMenu4">
                            <div className="card-body p-0 d-flex">
                                <i className="feather-bookmark text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Save Link <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Add this to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-alert-circle text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide Post <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-alert-octagon text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 text-grey-900 font-xssss mt-0 me-4 pointer">Hide all from Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                            <div className="card-body p-0 d-flex mt-2">
                                <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                                <h4 className="fw-600 mb-0 text-grey-900 font-xssss mt-0 me-4 pointer">Unfollow Group <span className="d-block font-xsssss fw-500 mt-1 lh-3 text-grey-500">Save to your saved items</span></h4>
                            </div>
                        </div> */}
                        
                    </div>
                        {
                            this.state.image ?
                            <> 
                                <br/>
                                <img src={URL.createObjectURL(this.state.image)} style={{ maxWidth: "100%", maxHeight: 320,}} alt="post image" />
                                <Button
                                onClick={() =>
                                  this.setState({ image: "" })
                                }
                              > <br/> <br/>
                                Remove
                              </Button>
                            </>
                            :
                            ''
                        }
                        {this.state.invalidFile ? (
                            <h4> {this.state.invalidFile} </h4>
                          ) : (
                            ""
                        )}
                        {
                            this.state.emptyBody ? 
                
                            <div>
                                <br/>
                                <MuiAlert severity="warning" onClose={this.handleClose}>Please type in your post</MuiAlert>
                                <br />
                            </div>
                            :
                            ''
                        }
                        {this.state.isSuccess? 
                        <>
                        <br/>
                        <Alert onClose={this.handleClose2} variant="filled" severity="success">
                                Your post was created sucessfully! 
                        </Alert> 
                        </>
                            : ''}
                </div>
            );

        
    }
}

export default Createpost;