import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";

import axios from "axios";
import axiosFetch from "../Interceptors2";

import Load2 from "./Load2";
import SingleAlertComponent from "./SingleAlertComponent";
import SingleAlertAdmin from "./SingleAlertAdmin";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";

//
export default function AlertSingle(props) {
  const [isOpen, setIsOpen] = useState("");
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const [isActive, setIsActive] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [profile, setProfile] = useState([]);
  // const [aId, setAId] = useState("");
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname);

  const alertId = queryParams.get("i");
  // setAId(alertId);

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function getProfile() {
    // function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setProfile(resp.data);
        getAlertLoad()
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function getAlertLoad() {
    // const reloadCount = localStorage.getItem("reloadCount");
    // if (reloadCount < 1) {
    //   localStorage.setItem("reloadCount", String(reloadCount + 1));
    //   console.log("reload count is less than one");
    //   // history.push(`${pathname}`)
    //   // history.go()
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem("reloadCount");
    // }

    setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        setFetchingAlerts(false);
        console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAlert() {
    // setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        // setFetchingAlerts(false);
        // console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getProfile();
    // getAlertLoad();
  }, []);

  // componentDidUpdate() {
  //     this.getAllAlerts();
  //     console.log("comonent did uodate")
  // }

  // render() {
  // console.log(`alerts are ${this.state.alerts}`);
  // const {user ,time , des, avater , postimage , postvideo ,id } = this.props;

  const menuClass = `${isOpen ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingAlerts == true) {
    console.log(`final ${fetchingAlerts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load2 />
      </>
    );
  }
  if (alerts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Hello {userName}! Still fetching the Alarrt.
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   if(isAdmin == true){
  //     return(
  // <p>hello admin</p>
  //       // <SingleAlertAdmin alertId = {aId} />
  //     )
  //   }
  //   else {
  // if (profile.user.is_admin == "true") {
  //   return <SingleAlertAdmin alertId={alertId} />;
  // } 
  else {
    return (
      <>
        
        {profile.user.is_admin == "true" ? (
          <>
          <SingleAlertAdmin alertId={alertId} />
          </>
        ) : (
          <>
            <SingleAlertComponent
              alert={alerts}
              functionProp={() => getAlert()}
            />
          </>
        )} 

        {/* <SingleAlertComponent alert={alerts} functionProp={() => getAlert()} /> */}
      </>
    );
  }
  // }
}

// export default Alerts;
