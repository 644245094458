import React, { Component, Fragment } from "react";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import Friends from "../components/Friends";
import Contacts from "../components/Contacts";
import Group from "../components/Group";
import Events from "../components/Events";
import Createpost from "../components/Createpost";
import CreatePost2 from "../components/CreatePost2";
import Memberslider from "../components/Memberslider";
import Friendsilder from "../components/Friendsilder";
import Storyslider from "../components/Storyslider";
import Postview from "../components/Postview";
import Load from "../components/Load";
import Profilephoto from "../components/Profilephoto";
import RecentlyFollowing from "../components/online/RecentlyFollowing";
import RecentUsers from "../components/online/RecentUsers";
import CreatePostFab from "../components/CreatePostFab";
import SearchBarV2 from "../components/SearchBarV2";

class Postfeed extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row feed-body">
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <br />
                  <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
                    <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 items-baseline py-1 px-1 dark:border-gray-800">
                      <h2 className="font-semibold text-lg text-center">
                        Your Timeline{" "}
                      </h2>
                      <br />
                    </div>
                    <h3 className="fw-600 text-grey-900 font-xssss mt-3 mb-3 me-4 text-center">
                      Explore posts of people you follow.
                      <br />
                      <p className="fw-600 font-xssss text-center mt-0 mr-3 me-4">
                        {" "}
                        To create a detailed and flexible post, you can use the
                        blue button at the bottom right part of your screen.{" "}
                      </p>
                    </h3>
                  </div>
                  {/* <Storyslider /> */}
                  {/* <CreatePost2 type='post'/> */}
                  {/* <Createpost /> */}
                  {/* <Friendsilder />
                                    <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">Suggested People To Follow </h3> 
                                    <Memberslider /> */}
                  {/* <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                  <Postview type="post" />

                  {/* <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">Suggested People To Follow </h3>  */}
                  {/* <Memberslider /> */}
                  {/* <Postview id="35" postvideo="icon-play.png" avater="user.png" user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                  {/* <Friendsilder /> */}
                  {/* <Postview id="36" postvideo="post.png"  avater="user.png" user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="35" postvideo={`assets/images/`} postimage="post.png" avater="user.png" user="Victor Exrixon" time="3 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                  {/* <Friendsilder /> */}
                  {/* <Postview id="36" postvideo={`assets/images/`} postimage="post.png" avater="user.png" user="Victor Exrixon" time="12 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." /> */}
                  {/* <Load /> */}
                </div>

                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  {/* <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">
                    Friends You Follow{" "}
                  </h3> */}
                  <RecentlyFollowing />
                  <br/><br/>
                  <RecentUsers />
                  <br/><br/>
                  {/* <Memberslider /> */}
                  {/* <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">Suggested People To Follow </h3>  */}
                  {/* <Friendsilder />
                                    <Friends />
                                    <Contacts />
                                    <Group />
                                    <Events />
                                    <Profilephoto /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Popupchat /> */}
        {/* <CreatePostFab type='post'/> */}
        <Appfooter />
      </Fragment>
    );
  }
}

export default Postfeed;
