import React from "react";
import { Component, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { Route, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddAlertRoundedIcon from "@material-ui/icons/AddAlertRounded";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import BoltIcon from "@mui/icons-material/Bolt";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"For Help, "}
        <Link color="primary" href="/help">
          Click Here!
        </Link>{" "}
        {"."}
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="primary" target="_blank" href="https://alarrt.com/">
        ALARRT
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        A Product Of AT-ALARRT Digital Technologies Limited
        {/* <Link
          style={{ paddingLeft: 3 }}
          color="primary"
          target="_blank"
          href="https://soaenterprise.com/"
        >
          SOA Digital Enterprise
        </Link> */}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "150vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image2: {
    backgroundImage: 'url(https://alert-app-v2.s3.amazonaws.com/ALARRT+APP+LOADER+V3.svg)',
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'contain',
    backgroundPosition: 'cover',
    width: "100vw",
    height: "130vh", 
    [theme.breakpoints.down("sm")]: {
      height: "80vh", 
      backgroundSize: 'contain',
    },
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const { id } = useParams(["id"]);
  const [email, setEmail] = useState("");
  const [pword, setPword] = useState("");
  const [pword2, setPword2] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [params, setParams] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const history = useHistory();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPasswordMismatch(false);
    setEmptyFields(false);
    setResetError(false);
    setIsSuccess(false);
    // setOpenAlert(false);
  };

  async function validate() {
    if (!pword || !pword2 || !email) {
      setEmptyFields(true);
      return <Alert severity="warning">Please fill in all fields</Alert>;
    }

    if (pword !== pword2) {
      setPasswordMismatch(true);
    } else {
      // alert( id)
      //   alert( pword2)
      //   alert( pword)
      resetPassword();
    }
  }

  async function resetPassword() {
    setIsSending(true);
    axios
      .post(`${process.env.REACT_APP_LIVE_URL}/password/reset`, {
        method: "POST",
        token: id,
        email: email,
        password: pword,
        password_confirmation: pword2,
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        console.log(resp.data, email, pword, pword2);

        setIsSuccess(true);
        setMessage(resp.data.message);
        setEmail("");

        setIsSending(false);
      })
      .catch((error) => {
        setIsSending(false);
        setResetError(true);
        setErrorMessage(error.message);
        console.log(error);
      });
  }
  function redirect() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get("token");
    setParams(value);
    redirect();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
      <Grid item xs={12} sm={6} md={6} >
        <br/>
        <Typography component="h1" variant="overline" className="text-center" style={{color: "#2516c7"}} > Welcome! Please always accept the notification and location prompt when asked for a better experience on ALARRT</Typography>
        <div className={classes.image2}></div>
      </Grid>
        <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        <img src='/assets/images/alarrt-logo.png' style={{width:250, height:100}} />
          {/* <Avatar className={classes.avatar}>
            <BoltIcon style={{fontSize:25}} />
          </Avatar> */}
          <br />
          <Typography component="h1" variant="h5">
            Change Your Password
          </Typography>
          <br />
          <p className="text-center">Use a password you'll remember</p>
          <br />
          <form className={classes.form} noValidate>
            <div className="form-group icon-input mb-1">
            <i className="font-sm feather-mail text-grey-500 pe-0"></i>
              
              <input
                style={{paddingLeft: 40}}
                type="email"
                placeholder="Type The Email The Reset Link Was Sent To"
                className="style2-input  form-control text-grey-900 font-xsss ls-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

             
            </div>
            <div className="form-group icon-input mb-3">
            {passwordShown ? (
                <i
                  className="font-sm feather-eye-off text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="font-sm feather-eye text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              )}
              <input
                style={{paddingLeft: 40}}
                type={passwordShown ? "text" : "password"}
                className="style2-input  form-control text-grey-900 font-xsss ls-3"
                placeholder="New Password"
                value={pword}
                onChange={(e) => setPword(e.target.value)}
              />
             
            </div>
            <div className="form-group icon-input mb-1">
            {passwordShown ? (
                <i
                  className="font-sm feather-eye-off text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              ) : (
                <i
                  className="font-sm feather-eye text-grey-500 pe-0"
                  onClick={togglePassword}
                ></i>
              )}

              <input
                style={{paddingLeft: 40}}
                type={passwordShown ? "text" : "password"}
                placeholder="Confirm your password"
                className="style2-input  form-control text-grey-900 font-xsss ls-3"
                value={pword2}
                onChange={(e) => setPword2(e.target.value)}
              />
             
            </div>

            {isSending ? (
              <Button
                disabled
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                //   onClick={validate}
              >
                Changing Password
              </Button>
            ) : (
              <Button
                //   type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={validate}
              >
                Change Password
              </Button>
            )}

            {emptyFields ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Please type in all fields
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {passwordMismatch ? (
              <div>
                <Alert severity="warning" onClose={handleClose}>
                  Your passwords are not the same. Kindly retype them
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}

            {isSuccess ? (
              <div>
                <Alert severity="success" onClose={handleClose}>
                  {message}
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}

            {resetError ? (
              <div>
                <Alert severity="error" onClose={handleClose}>
                  {errorMessage}
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/signin" variant="body2">
                  {"Click Here To Sign In"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
