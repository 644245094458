import React, { Component, Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";
import AlertLocationCurrent from "../components/AlertLocationCurrent";

export default function WatchLocation() {
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const adminLat = window.sessionStorage.getItem("wat_lat");
  const adminLng = window.sessionStorage.getItem("wat_lon");

  const userName = window.localStorage.getItem("first_name");

  return (
    <Fragment>
        {/* <Header /> */}
      <div style={{ marginTop: 150, marginLeft:30 }}>
        <h2> Hi {userName}! </h2>
        <br />
        <br />
        <h4>Your Previous latitude Was : {latitude}</h4>
        <br />
        <h4>Your Previous longitude Was : {longitude}</h4>
        <br />
        <h4>Your Current latitude Is : {watch_latitude}</h4>
        <br />
        <h4>Your Current longitude Is : {watch_longitude}</h4>
        <br />

        <AlertLocationCurrent 
        alert=''
        startLat={latitude}
        startLong={longitude}
        currentLat={watch_latitude}
        currentLong={watch_longitude}
        locationName=''
        adminLat={adminLat}
        adminLng={adminLng}
        />
      </div>
      <Appfooter />
    </Fragment>
  );
}
