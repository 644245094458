import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ImageComponentDiv from "./ImageComponentDiv";
import ImageComponent from "./ImageComponent";
import axios from "axios";
import ChannelComp2 from "./ChannelComp2";

export default function StarredMovies(props) {
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.starredMovies.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          <Leftnav />
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello {props.user_name}! You Haven't Featured In Any Channel Content.
              </h5>
            </div>
          </div>
          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0"> */}
        <div className="row">
          <div className="col-xl-12">
            <div className="row ps-2 pe-1">
            <>
    {/* <h1> new </h1> */}
    {props.starredMovies.map((movie, id) => (
      <div className="col-md-6 col-sm-6 pe-2 ps-2">
        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
          {movie.movie.image === null ? (
            <div
              className="card-body position-relative h100 bg-image-cover bg-image-center"
              style={{
                backgroundImage: `url('https://alert-app-v1.s3.amazonaws.com/user.png')`,
              }}
              onError={fixImage}
            ></div>
          ) : (
            <ImageComponentDiv
              onError={fixImage}
              image={movie.movie.image}
              alt="avater"
              class="card-body position-relative h100 bg-image-cover bg-image-center"
            />
          )}
          <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
            <figure
              className="avatar position-absolute w75 z-index-1 left-15"
              style={{
                marginTop: `-40px`,
                marginLeft: "-10px",
              }}
            >
              {movie.movie.channel.channel_pic === null ? (
                <img
                  onError={fixImage}
                  src={"https://alert-app-v1.s3.amazonaws.com/user.png"}
                  alt="avater"
                  className="float-right p-1 bg-white rounded-circle w-100 "
                  style={{ objectFit: "contain" }}
                />
              ) : (
                <ImageComponent
                  // onError={fixImage}
                  image={movie.movie.channel.channel_pic}
                  alt="avater"
                  class="float-right p-1 bg-white rounded-circle w-100 "
                />
              )}
            </figure>

            <div className="clearfix"></div>

            <Link
            //   to={{
            //     pathname: `/channel/${movie.movie.channel.slug}`,
            //     state: { channelId: movie.movie.id },
            //   }}
            >
              <h4 className="fw-700 font-xss mt-3 mb-0">
                Movie: {movie.movie.title}{" "}
              </h4>
            </Link><br/>
            <Link
              to={{
                pathname: `/channel/${movie.movie.channel.slug}`,
                state: { channelId: movie.movie.id },
              }}
            >
              {" "}
              <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                Channel:{" "}
                <span className="text-primary-500">
                {movie.movie.channel.name}
                </span>{" "}
              </p>{" "}
            </Link>
            <Link
            //  to={`/profile/${movie.movie.admin.user_name}`}
             >
              {" "}
              <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                Created By:{" "}
                <span className="text-primary-500">
                  {movie.movie.channel.admin.user_name}
                </span>{" "}
              </p>{" "}
            </Link>
           
           
            <div
              style={{ marginTop: 2, marginLeft: "-5px" }}
              className="d-flex align-items-center"
            //   className="position-absolute right-5 top-10 d-flex align-items-center"
            >
              <ul className="d-flex align-items-center justify-content-center mt-1">
                <li className="m-2">
                  <h4 className="fw-400 font-xss">
                    {/* {movie.movie.subscribers_count} */} {movie.role}
                    <span className="font-xsss fw-500 mt-1 text-grey-500 d-block">
                      Role
                    </span>
                  </h4>
                </li>
                <li className="m-2">
                  <h4 className="fw-400 font-xss">
                    {/* {movie.movie.videos_count} */} {movie.movie.type}
                    <span className="font-xsss fw-500 mt-1 text-grey-500 d-block">
                    Type
                    </span>
                  </h4>
                </li>
                <li className="m-2">
                  <h4 className="fw-400 font-xss">
                    {/* {movie.movie.movies_count} */} {movie.movie.pricing} {movie.movie.price}
                    <span className="font-xsss fw-500 mt-1 text-grey-500 d-block">
                      Pricing
                    </span>
                  </h4>
                </li>
              </ul>
            </div>

            {/* <br/> */}
            <div
              style={{ marginTop: "-15px", marginLeft: "-10px" }}
              // className="position-absolute right-15 top-10 d-flex align-items-center"
            >
             <Link
          to={{
            pathname: `/channel/${movie.movie.channel.slug}/${movie.movie.type}/${movie.movie.slug}?channel=${movie.movie.channel.slug}&w=${movie.movie.id}&a=${movie.movie.channel.admin.id}`,
            // state: { channelSlug:channelSlug, id: movie.id, adminId: adminId },
          }}
        >
              <a
                // onClick={() => subscribeToogle(movie.movie.id)}
                style={{ alignSelf: "center" }}
                className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
              >
               VIEW MOVIE
              </a>
              </Link> 
            </div>
          </div>
        </div>
      </div>
       ))}
    </>
          </div> 
          </div>
        </div>
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

{/* // export default Channels; */}
