import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
import Load from "./Load";
import AlertComponent from "./AlertComponent";


export default function UserAlerts(props) {

  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const loggedInId = useState(window.localStorage.getItem("user_id"));
  const { user_name } = useParams(["user_name"]);
  const alerts  = props.alerts;
  const userId = props.user_id;
 
  
  function checkUserId () {
 
    if (loggedInId[0] == userId) {
      console.log("is logged in user alerts");
      setIsLoggedInUser(true);
    } else {
      console.log("is another user alerts");
      return setIsLoggedInUser(false);
    }
  }

  useEffect(() => {
    checkUserId();
    // console.log(`no 3 ${userId}`);
    // console.log(`no 4 ${loggedInId}`);
  
  
  },[]);


    if (fetchingAlerts == true) {
      return <Load />;
    }
    if (alerts.length == 0) {
      return (
        <>
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
             {isLoggedInUser ?  
             <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello @{user_name}! You Haven't Created Any Alert. You
                Can Create One On The Alerts Page
              </h5> 
              :
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello! @{user_name} Hasn't Created Any Alert. You Can Check Back Later
              </h5>}
             
              
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
        {/* <p>Your Alerts</p> */}
        <AlertComponent alerts={alerts} functionProp={props.functionProp} functionProp2={props.functionProp}/>
        </>
        );
    }
  }


