import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export default class FourImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    // console.log(`props image are ${JSON.stringify(this.props.images)}`)
    const { photoIndex, isOpen } = this.state;

    return (
      <>
        <div>
          <div className="grid grid-cols-2 gap-2 p-3 data-uk-link-reset">
            <div className="bg-red-500 max-w-full h-32 rounded-lg relative overflow-hidden uk-transition-toggle">
              <a onClick={() => this.setState({ isOpen: true })}>
                <img
                  src={this.props.images[0].url}
                  // className="rounded-md w-full h-full object-cover"
                  className="w-full h-full absolute object-cover inset-0"
                />
              </a>
            </div>

            <div className="bg-red-500 max-w-full h-40 rounded-lg relative overflow-hidden uk-transition-toggle">
              <a onClick={() => this.setState({ isOpen: true })}>
                <img
                  src={this.props.images[1].url}
                  // className="rounded-md w-full h-full object-cover"
                  className="w-full h-full absolute object-cover inset-0"
                />
              </a>
            </div>

            <div className="bg-red-500 max-w-full h-40 -mt-8 rounded-lg relative overflow-hidden uk-transition-toggle">
              <a onClick={() => this.setState({ isOpen: true })}>
                <img
                  src={this.props.images[2].url}
                  // className="rounded-md w-full h-full object-cover"
                  className="w-full h-full absolute object-cover inset-0"
                />
              </a>
            </div>

            <div className="bg-red-500 max-w-full h-32 rounded-lg relative overflow-hidden uk-transition-toggle">
              <a onClick={() => this.setState({ isOpen: true })}>
                <img
                  src={this.props.images[3].url}
                  // className="rounded-md w-full h-full object-cover"
                  className="w-full h-full absolute object-cover inset-0"
                />
                <div className="absolute bg-gray-900 bg-opacity-10 flex justify-center items-center text-white rounded-md inset-0 text-2xl">
                  {" "}
                  View Full
                </div>
              </a>
            </div>
          </div>

          {isOpen && (
            <Lightbox
              mainSrc={this.props.images[photoIndex].url}
              nextSrc={
                this.props.images[(photoIndex + 1) % this.props.images.length]
              }
              prevSrc={
                this.props.images[
                  (photoIndex + this.props.images.length - 1) %
                    this.props.images.length
                ]
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex:
                    (photoIndex + this.props.images.length - 1) %
                    this.props.images.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % this.props.images.length,
                })
              }
              imageCaption={this.props.text}
              imageTitle={this.props.text}
            />
          )}
        </div>
      </>
    );
  }
}
