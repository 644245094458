import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
// import { Select } from "antd";
import slugify from "react-slugify";
// import slugify from "react-slugify";
import Resizer from "react-image-file-resizer";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Snackbar } from "@material-ui/core";

export default function ChannelUpdateModal(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  // const channel = props.channel
  const adminDetails = props.adminDetails.id;
  const channelId = props.channel.id;
  const channel = props.channel;
  const [isUpdating, setIsUpdating] = useState(false);
  const [channelName, setChannelName] = useState(channel.name);
  const [channelDes, setChannelDes] = useState(channel.description);
  const [channelStatus, setChannelStatus] = useState("");
  const [channelStatusFe, setChannelStatusFe] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [channelType, setChannelType] = useState("");
  const [channelTypeFe, setChannelTypeFe] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelPriceFe, setChannelPriceFe] = useState("");
  const [channelPricing, setChannelPricing] = useState("");
  const [channelCoverFe, setChannelCoverFe] = useState("");
  const [channelCover, setChannelCover] = useState("");
  const [channelPic, setChannelPic] = useState("");
  const [channelPicFe, setChannelPicFe] = useState("");
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newName, setNewName] = useState(channel.slug);
  const [error, setError] = useState(false);
  const { slug } = useParams(["slug"]);
  const [value, setValue] = React.useState("1");
  const location = useLocation();
  const name = "";
  const animatedComponents = makeAnimated();

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setOpen(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function changeType(value) {
    console.log(`type ${value}`);
    setChannelTypeFe(value);
    setChannelType(value.value);
  }
  function changeStatus(value) {
    console.log(`status ${value}`);
    setChannelStatus(value.value);
    setChannelStatusFe(value);
  }

  function redirect(name) {
    setIsSuccess(false);
    // history.push(`/profile/${data}`);
    console.log(`redirect ${name}`);
    history.push(`/channel/${name}`);
    history.go(`/channel/${name}`);
  }

  function clearPagePics(value) {
    setChannelPicFe('');
    setChannelPic('');
  }
  function clearCoverImage(value) {
    setChannelCover('');
    setChannelCoverFe('');
  }

  async function validate() {
    console.log(`channelName ${channelName}`);
    if (!channelName) {
      setEmptyFields(true);
      return;
    } else {
      updateChannel();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
    setIsSuccess(false);
  };

  async function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setChannelPicFe(value);
    //   setInvalidFile(false);
    // }
  }

  async function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setChannelCoverFe(value);
    //   setInvalidFile(false);
    // }
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      90,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setChannelPic(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      80,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setChannelCover(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeCoverFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function updateChannel() {
    setIsUpdating(true);
    const name = slugify(channelName);
    setNewName(name);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    console.log(`new name is ${newName}`);
    const formData = new FormData();
    // formData.append("user_id", userID);
    formData.append("name", channelName);
    formData.append("description", channelDes);
    formData.append("status", "public");
    formData.append("cover_pic", channelCover);
    formData.append("channel_pic", channelPic);
    formData.append("public_key", channelKey);
    formData.append("type", channelType);
    formData.append("price", channelPrice);
    formData.append("status", channelStatus);

    axiosFetch
      .post(
        `/channels/update/${channelId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi name 2 ${name}`);
          setIsSuccess(true);
          //   setChannelName("");
          setChannelDes("");
          setChannelStatus("");
          setChannelCover("");
          setChannelPic("");
          setChannelCoverFe("");
          setChannelPicFe("");
          setChannelKey("");
          setChannelPrice("");
          setChannelPriceFe("");
          setIsUpdating(false);
          //   props.functionProp()
          console.log(`response ${JSON.stringify(resp.data)}`);
          
          redirect(name);
          //   history.push(`/channel/${newName}`);
          //   history.go(`/channel/${newName}`);
        } else {
          setIsUpdating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;
  // const types = ["free", "paid"];
  const statusSelect = [
    { value: "public", label: "Publish" },
    { value: "private", label: "Draft" },
  ];
  const types = [
    { value: "free", label: "Free" },
    { value: "paid", label: "Paid" },
  ];

  function updateName(e) {
    console.log("new name update");
    const name = slugify(channelName);
    setNewName(name);
  }

  useEffect(() => {
    // console.log(`state name is ${newName}`)
  }, [console.log(`state name is ${newName}`)]);

  return (
    <>
      <a
        onClick={handleClickOpen}
        className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
      >
        <i className="feather-edit font-md"></i>
      </a>
      {/* <a className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
        Edit Channel
      </a> */}
      <Dialog open={open} onClose={handleClose} style={{ width: "95vw" }}>
        <DialogTitle> Update Your Channel Here</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skip The Step You Don't Want To Update. You don't need to update
            all, just edit the info you need to change and click update
          </DialogContentText>

          <div className="card-body mt-1 p-lg-5 p-4 w-100 border-0 ">
            <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
              {channelCoverFe ? (
                <img
                  src={URL.createObjectURL(channelCoverFe)}
                  alt="avater"
                  style={{
                    maxWidth: 1450,
                    maxHeight: 450,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={channel.cover_pic}
                  alt="avater"
                  style={{
                    maxWidth: 1450,
                    maxHeight: 450,
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="card-body p-0 position-relative">
              {channelPicFe ? (
                //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                <>
                  {/* <p> Your Channel Cover Picture </p> <br /> */}
                  <figure
                    className="avatar position-absolute w100 z-index-1"
                    style={{ top: "-40px" }}
                  >
                    <img
                      src={URL.createObjectURL(channelPicFe)}
                      alt="avater"
                      className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                    />
                  </figure>
                </>
              ) : (
                <figure
                  className="avatar position-absolute w100 z-index-1"
                  style={{ top: "-40px" }}
                >
                  <img
                    src={channel.channel_pic}
                    alt="avater"
                    className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                  />
                </figure>
              )}

              <h4 className="fw-700 font-sm mt-2 pl-15">
                {/* First Name{" "}
                        Last name */}
                <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                  {/* @User Name */}
                </span>
              </h4>
            </div>
            <div className="card-body mt-5 p-lg-5 p-4 w-100 border-0 ">
              {/* <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://via.placeholder.com/300x300.png"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>

                    </div>
                  </div> */}
              <br />
              <b>
              <br />  
                <h4>
                  Hello {channel.admin.first_name} you don't have to edit all.
                  Only edit what needs to be changed and click update{" "}
                </h4>
              </b>

              <form>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <br /><br/>
                      <br />
                      <label className="mont-font fw-600 font-xsss mb-2">
                        Channel Name
                      </label>
                      {/* value={this.state.first_name} */}
                      <input
                        type="text"
                        defaultValue={channel.name}
                        onChange={(e) => {
                          setChannelName(e.target.value);
                          const name = slugify(channelName);
                          setNewName(name);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                      What is Your Channel About?
                    </label>
                    <textarea
                      className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                      rows="5"
                      defaultValue={channel.description}
                      onChange={(e) => setChannelDes(e.target.value)}
                      placeholder="Let subscribers know a bit about your contents..."
                    ></textarea>
                  </div>

                  {/* <div className="form-group">
                    <label className="mont-font fw-600 font-xsss mb-2">
                      Your Paystack Public Key (Live / Test). - If you still don't have one yet, leave it empty to use our default test keys for testing. You can always update it with your live public key once you have yours. Also ensure you use your live keys so real payments can be made to you
                    </label>
                    <p>
                      {" "}
                      Create an account on paystack to start monetizing your
                      content. To recieve real payments, you are advised to use
                      your live key. Test keys only enables you test how payment is made{" "}
                    </p>
                  
                    <input
                      type="text"
                      defaultValue={channel.public_key}
                      onChange={(e) => setChannelKey(e.target.value)}
                      className="form-control"
                    />
                  </div> */}
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <br />
                      <label className="mont-font fw-600 font-xsss mb-2">
                        Select Channel Pricing Type - Can be changed later
                      </label>
                      <p>
                        Select paid if you want monetize your content and gain
                        extra income. Subscribers to pay a fee before
                        subscribing. To update your bank details, Please contact us from your settings.
                      </p>
                      <Select
                        
                        value={channelTypeFe}
                        label="Select One"
                        size="large"
                        style={{ width: "90%" }}
                        onChange={changeType}
                        options={types}
                        defaultInputValue={channel.type}
                      >
                       {types.map((type) => (
                              <Option key={type.value} value={type.value}>
                                {" "}
                                {type.label}{" "}
                              </Option>
                            ))}
                      </Select>
                      <br />
                      <p>Pricing previously set to {channel.type}</p>
                    </div>
                  </div>
                </div>

                {channelType == "paid" ? (
                  <div className="form-group">
                    <br />
                    <label className="mont-font fw-600 font-xsss mb-2">
                      You selected paid. Update your subscription fee
                    </label>
                    <p>
                      This is a one time payment fee. It grants subscribers
                      access to contents for subscribers only
                    </p>
                    {/* value={this.state.first_name} */}
                    <input
                      type="text"
                      value={channelPrice}
                      // defaultValue='N500'
                      onChange={(e) => setChannelPrice(e.target.value)}
                      className="form-control"
                    />
                    <br /><br/>
                  </div>
                ) : (
                  ""
                )}

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        Select Channel Published Status
                      </label>
                      <p>
                        {" "}
                        Draft channels can be seen only by you in your profile.
                        They aren't visible in the channels page
                      </p>
                      <Select
                       
                        value={channelStatusFe}
                        label="Select One"
                        size="large"
                        style={{ width: "90%" }}
                        onChange={changeStatus}
                        options={statusSelect}
                        defaultInputValue={channel.status}
                      >
                          {statusSelect.map((status) => (
                              <Option key={status.value} value={status.value}>
                                {" "}
                                {status.label}{" "}
                              </Option>
                            ))}
                      </Select>
                      <br />
                      {/* <p>Currently set to {channelStatus}</p> */}
                    </div>
                  </div>
                </div>
                {/* <div className="row"> */}

                <div className="col-lg-12 mb-3">
                  {/* </div> */}

                  {channelPicFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      <p> Your Channel's Logo </p> <br />
                      <img
                        src={URL.createObjectURL(channelPicFe)}
                        alt="avater"
                        style={{
                          // width: 1450,
                          // maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                          //    borderRadius: '10%'
                        }}
                      />
                      <Button onClick={clearPagePics}>Remove</Button>
                      <br />
                      <br />
                    </>
                  ) : (
                    //  </div>
                    <div className="card mt-3 border-0">
                      <div className="card-body d-flex justify-content-between align-items-end p-0">
                        <div className="form-group mb-0 w-100">
                          <input
                            type="file"
                            accept="image/*"
                            name="file-2"
                            id="file-2"
                            className="input-file"
                            onChange={(e) =>{
                              validateProfileImage(e.target.files[0])
                              CompressImage(e)
                            }}
                          />
                          <label
                            htmlFor="file-2"
                            className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                          >
                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                            <span className="js-fileName">
                              Replace your 
                              logo
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {invalidFile ? <h4> {invalidFile} </h4> : ""}
                </div>

                {invalidFile ? <h4> {invalidFile} </h4> : ""}

                <div>
                  {channelCoverFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      <p> Your Channel's Cover Picture </p> <br />
                      <img
                        src={URL.createObjectURL(channelCoverFe)}
                        alt="avater"
                        style={{
                          // width: 1450,
                          // maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                          //    borderRadius: '10%'
                        }}
                      />
                      <Button onClick={clearCoverImage}>
                        Remove
                      </Button>
                      {/* //  </div> */}
                    </>
                  ) : (
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <input
                              type="file"
                              accept="image/*"
                              name="file"
                              id="file"
                              className="input-file"
                              onChange={(e) => {
                                validateCoverImage(e.target.files[0])
                                compressCoverImage(e)
                              }}
                              // onChange={(e) =>
                              //     console.log(`test ${e.target.files[0]}`)}
                            />
                            <label
                              htmlFor="file"
                              className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                            >
                              <i className="ti-cloud-down large-icon me-3 d-block"></i>
                              <span className="js-fileName">
                                Replace channel's
                                cover picture
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-12">
                  {isUpdating ? (
                    <Button disabled variant="contained" color="primary">
                      Updating Your Channel
                    </Button>
                  ) : (
                    // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
                    <a
                      onClick={validate}
                      className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                    >
                      Update Channel
                    </a>
                  )}
                  <br />
                  <br />
                  <br />
                  {emptyFields ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert onClose={handleClose} severity="warning">
                        To create a channel, you need to ensure it has at least
                        a name
                      </Alert>
                    </>
                  ) : (
                    ""
                  )}

                  {error ? (
                    <div>
                      <Alert
                        severity="error"
                        onClose={handleClose}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        There was an issue updating your channel. Kindly try
                        again [ {errorMessage} ]
                      </Alert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}

                  {isSuccess ? (
                        <Snackbar
                        open={isSuccess}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                    <Alert
                      onClose={handleClose}
                      variant="filled"
                      severity="success"
                      action={
                        <Button
                          onClick={redirect}
                          style={{ color: "#fff", alignSelf: "center" }}
                        >
                          {" "}
                          Check It Out
                        </Button>
                      }
                    >
                      Your channel was updated sucessfully! Redirecting...
                    </Alert>
                    </Snackbar>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {isUpdating ? (
            <>
              <Button disabled>Updating Your Channel</Button>
              <p>This may take a quick minute. You'll Be redirected after</p>
            </>
          ) : (
            <>
              <Button onClick={validate}>Update Now</Button>
            </>
          )}
          {/* <Button onClick={validate}>Create</Button> */}
        </DialogActions>
        {emptyFields ? (
          <Alert onClose={handleClose2} severity="warning">
            Oops - body can not be empty. Kindly update
          </Alert>
        ) : (
          ""
        )}
      </Dialog>{" "}
    </>
  );
}

