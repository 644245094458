import { Component, useState, useEffect } from "react";
import React from "react";
import "../components/online/online.css";
import axios from "axios";
import Load from "./Load";
import { useHistory, Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function UserReferrals(props) {
  const [following, setFollowing] = useState([]);
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);
  const [copied, setCopied] = useState(false);
  const [value, setValue] = useState("");

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  //   useEffect(() => {
  //     getFollowing();
  //     // getTags();
  //     // getAlertCategories();
  //   }, []);
  function closeSnack() {
    setCopied(false);
  }

  if (props.referrals.length == 0) {
    return (
      <>
        <p className="text-center">
          Seems like you have no referrals yet. When friends signup with your
          referral link, you earn 10 sms points. Points allows you send sms to
          your friends from Alarrt. Copy your link now and start sharing!
        </p>

        <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800">
          <h2 className="font-semibold text-lg">Your Referral Link</h2>
          <p>
            {process.env.REACT_APP_URL}/signup?ref={user_name}
          </p>
          <CopyToClipboard
            text={`I'm inviting you to sign up on Alarrt with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Alarrt informs you if there is a crises on the road before you get there. It also helps you get help immediately if you are in an emergency. Visit https://alarrt.com today to know more. Using this link gives you additional points to send sms to your friends. Sign up now`}
            onCopy={() => setCopied(true)}
          >
            <button>Copy</button>
          </CopyToClipboard>
          <br />

          {copied ? (
            <>
              <Snackbar
                open={copied}
                autoHideDuration={3000}
                onClose={closeSnack}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Alert onClose={closeSnack} severity="success">
                  Link Copied Successfully
                </Alert>
              </Snackbar>
            </>
          ) : null}
        </div>

        <br />
        <br />

        {/* <Load/> */}
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white dark:bg-gray-900 shadow-md rounded-md">
          <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800">
            <h2 className="font-semibold text-lg ">
              Total Points: {props.totalPoints}.
            </h2>
            <h2 className="font-semibold text-lg">Your Referral Link</h2>
            <p>
              {process.env.REACT_APP_URL}/signup?ref={user_name}
            </p>
            <CopyToClipboard
              // text={`I'm inviting you to sign up on Alarrt with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Alarrt helps you get help immediately if you are in an emergency. It also helps you stay informed of State and National emergencies. visit https://about.alarrt.com today to know more. Using this link gives you additional points to send sms to your friends. Sign up now`}
              text={`I'm inviting you to sign up on Alarrt with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Alarrt informs you if there is a crises on the road before you get there. It also helps you get help immediately if you are in an emergency. Visit https://alarrt.com today to know more. Using this link gives you additional points to send sms to your friends. Sign up now`}
              // text={`${process.env.REACT_APP_URL}/signup?ref=${user_name}`}
              onCopy={() => setCopied(true)}
            >
              <button>Copy Link</button>
            </CopyToClipboard>
            <br />

            {copied ? (
              <>
                <Snackbar
                  open={copied}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Link Copied Successfully
                  </Alert>
                </Snackbar>
              </>
            ) : null}
          </div>
          <br />
          <p className="text-center">
            Points allows you send sms to your friends from Alarrt. Invite more
            friends to signup on Alarrt with your link to get more sms points
          </p>

          {/* <br />
        <br /> */}

          {props.referrals.map((referral) => (
            <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
              <div className="flex items-center justify-between py-3">
                <div className="flex flex-1 items-center space-x-4">
                  <a href={`/profile/${referral.user.user_name}`}>
                    <img
                      src={referral.user.profile_pic}
                      className="bg-gray-200 rounded-full w-10 h-10"
                      onError={fixImage}
                    />
                  </a>
                  <Link to={`/profile/${referral.user.user_name}`}>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold">
                        {" "}
                        {referral.user.first_name} {referral.user.last_name}
                      </span>
                      <span className="block capitalize text-sm">
                        {" "}
                        @{referral.user.user_name}{" "}
                      </span>
                    </div>
                  </Link>
                </div>

                <a
                  href={`/profile/${referral.user.user_name}`}
                  // onClick={toogleFollow(user.user_name)}
                  className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
                >
                  {" "}
                  VIEW{" "}
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* <h4 className="rightbarTitle">Recently Following</h4>
        <ul className="rightbarFriendList">
      
          <Online user={following} />
        
        </ul> */}
      </>
    );
  }
}
