import React, { Component, Fragment, useState } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Load from "../components/Load";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function Points(props) {
  // class Badge extends Component {
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);
  const [copied, setCopied] = useState(false);
  function closeSnack() {
    setCopied(false);
  }

  if (props.points.length == 0) {
    return (
      <>
        <p className="text-center">Seems like you have no points left</p>
        <Load />
      </>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav />
                <Rightchat /> */}
        <div>
          <div className="bg-white dark:bg-gray-900 shadow-md rounded-md">
            {/* <div className="main-content right-chat-active"> */}
            {/* <div className="middle-sidebar-bottom"> */}
            {/* <div className="middle-sidebar-left pe-0"> */}
            {/* <div className="row"> */}
            {/* <div className="col-xl-12"> */}
            <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800">
              <h2 className="font-semibold text-lg">
                Total Points: {props.totalPoints}.
              </h2>
              {/* <br /> */}
              <h2 className="font-semibold text-lg">Your Referral Link</h2>
              <p>
                {process.env.REACT_APP_URL}/signup?ref={user_name}
              </p>
              <CopyToClipboard
               text={`I'm inviting you to sign up on Alarrt with this link. ${process.env.REACT_APP_URL}/signup?ref=${user_name} . Alarrt helps you get help immediately if you are in an emergency. It also helps you stay informed of State and National emergencies. visit https://about.alarrt.com today to know more. Using this link gives you additional points to send sms to your friends. Sign up now`}
                // text={`${process.env.REACT_APP_URL}/signup?ref=${user_name}`}
                onCopy={() => setCopied(true)}
              >
                <button>Copy Link</button>
              </CopyToClipboard>
              <br />

              {copied ? (
                      <>
                        <Snackbar
                          open={copied}
                          autoHideDuration={3000}
                          onClose={closeSnack}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert onClose={closeSnack} severity="success">
                            Link Copied Successfully
                          </Alert>
                        </Snackbar>
                      </>
                    ) : null}
              <br />
            </div>
            <p className="text-center">
              Points enable you send sms to your friends. Invite more friends
              to Alarrt with your link to get more free points
            </p>
            <br />
          </div>

            {/* <div> */}
          <div className="row ps-2 pe-1">
            {props.points.map((point, index) => (
              <div key={index} className="col-md-4 col-sm-6 pe-2 ps-2">
                  {/* <div> */}
                <div className="card d-block border-0 shadow-xss rounded-3 mb-3">
                  <div className="card-body d-block w-100 p-4 text-center">
                    <div className="clearfix"></div>
                    <h4 className="fw-700 font-xss mt-3 mb-0">{point.name} </h4>
                    <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                      {point.description}
                    </p>
                    {/* <ul className="d-flex align-items-center justify-content-center mt-1">
                                                        <li className="m-2"><h4 className="fw-700 font-sm">{value.connections} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Connections</span></h4></li>
                                                        <li className="m-2"><h4 className="fw-700 font-sm">{value.follower} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Follower</span></h4></li>
                                                        <li className="m-2"><h4 className="fw-700 font-sm">{value.following} <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">Followings</span></h4></li>
                                                    </ul> */}
                    <ul className="d-flex align-items-center justify-content-center mt-1">
                      {point.key == 1 ? (
                        <li className="m-1">
                          <img
                            src={`/assets/images/top-student.svg`}
                            alt="icon"
                          />
                        </li>
                      ) : (
                        ""
                      )}
                      {point.key == 2 ? (
                        <li className="m-1">
                          <img
                            src={`/assets/images/challenge-medal.svg`}
                            alt="icon"
                          />
                        </li>
                      ) : (
                        ""
                      )}
                      {point.key == 3 ? (
                        <li className="m-1">
                          <img src={`/assets/images/onfire.svg`} alt="icon" />
                        </li>
                      ) : (
                        ""
                      )}
                      {point.key == 4 ? (
                        <li className="m-1">
                          <img
                            src={`/assets/images/fast-graduate.svg`}
                            alt="icon"
                          />
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                    <a className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white">
                      {point.points}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* </div> */}
          {/* </div> */}
          {/* // </div> */}
          {/* //   </div> */}
          {/* // </div> */}
        </div>
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Badge;
