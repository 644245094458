import React, { Component , Fragment, useState, useEffect } from "react";
import { Route, useParams, useLocation } from "react-router-dom";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import ChannelDetails from '../components/ChannelDetails';
import Profilephoto from '../components/Profilephoto';
import ProfilecardThree from '../components/ProfilecardThree';
import CommunityCard from '../components/CommunityCard';
import ProfilecardTwo from '../components/ProfilecardTwo';

import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';

// class ChannelPage extends Component {
//     render() {
export default function CommunityPage(history) {
    const {slug} = useParams(['slug'])
    // const id = useLocation()
    // const [channelId, setChannelId] = useState(id.state.id);

    useEffect(() => {
        // console.log(channelId);
      }, []);

        return (
            <Fragment> 
                <Header />
                {/* <Leftnav /> */}
                {/* <Rightchat /> */}

{/* {console.log(id)} */}
                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-12 mb-3">
                                    {/* <h1>SLUG IS {slug}</h1> */}
                                    <CommunityCard slug={slug}/>
                                    
                                </div>
                                {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <ChannelDetails />
                                    <Profilephoto />
                                    <Events />
                                </div> */}
                                {/* <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    <Createpost />
                                    <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Postview id="33" postvideo="" postimage="post.png" avater="user.png" user="Anthony Daugloi" time="2 hour ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                    <Load />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Popupchat /> */}
                <Appfooter /> 

            </Fragment>
        );
    }
// }

// export default ChannelPage;