import React, { Component, useState, Fragment, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import "./singleMovie.css";
import Chip from "@material-ui/core/Chip";
import { format, formatDistance, subDays } from "date-fns";
import { Grid, makeStyles } from "@material-ui/core";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Pagetitle from "../../components/Pagetitle";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import IconButton from "@mui/material/IconButton";
import { MDBRipple } from "mdb-react-ui-kit";
import Slider from "react-slick";
import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { category, movieType, tvType } from "../../api/tmdbApi";
import MovieCard from "../../moviesComponents/movie-card/MovieCard";
import MovieGrid from "../../moviesComponents/movie-grid/MovieGrid";
import MovieList from "../../moviesComponents/movie-list/MovieList";
import MovieCard2 from "../../moviesComponents/movie-card/MovieCard2";
import Detail from "../../pages/movies/detail/Detail";
import SeriesDetail from "../../pages/movies/detail/SeriesDetail";
import Load3 from "../Load3";
import Load from "../Load";
import HeroSlide from "../../moviesComponents/hero-slide/HeroSlide";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Button from "../../moviesComponents/button/Button";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function AllMovies(props) {
  const classes = useStyles();
  let history = useHistory();
  const userId = useState(window.localStorage.getItem("user_id"));
  const [fetchingMovies, setFetchingMovies] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [movies, setMovies] = useState([]);
  const [favComments, setFavComments] = useState([]);
  const [series, setSeries] = useState([]);
  const [video, setVideo] = useState("");
  const [trailer, setTrailer] = useState("");
  const [comments, setComments] = useState([]);
  const [casts, setCasts] = useState([]);
  const [ratings, setRatings] = useState("");
  const [isSub, setIsSub] = useState("");
  const [isPurchased, setIsPurchased] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [purchasedSeries, setPurchasedSeries] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(40);

  //
  //   const channelSlug = location.state.channelSlug;
  //   const movieId = location.state.id;
  //   const adminId = location.state.adminId;

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function goToChannel(url) {
    console.log(url);
    history.push("/channel/" + url);
  }

  async function getMoviesLoad() {
    // setIsFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
        setMovies(resp.data);
        // setIsFetching(false);
      })
      .catch((error) => {
        setFetchingMovies(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getMovies() {
    // setIsFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/movie/${skip}/${take}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        setMovies((prev) => [...prev, ...resp.data]);
      })
      .catch((error) => {
        setFetchingMovies(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function redirect(movie) {
    if( movie.type == "movie"){
      history.push({
          pathname: `/channel/${movie.channel.slug}/movie/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
        })
        window.location.reload()
    }
    if( movie.type == "video"){
      history.push({
          pathname: `/channel/${movie.channel.slug}/video/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
        })
        window.location.reload()
    }
    if( movie.type == "episode"){
      history.push({
          pathname: `/channel/${movie.channel.slug}/series/episode/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
        })
        window.location.reload()
    }
  }

  function getNext() {
    setSkip(skip + 40 );
    getMoviesLoad();
  }

  useEffect(() => {
    getMovies();
    // getMoviesLoad();
    // console.log(JSON.stringify(location))
  }, [skip]);

  if (isFetching == true) {
    return (
      <Fragment>
        <div style={{ marginTop: -55 }}>
          <Pagetitle
            title={`Explore More Movies!`}
            // {`Channel ${movie.type}s`}
            bg="#152238"
            color="#fff"
            //   back='Go Back To Home'
            home="Go Back To Alarrt"
          />
        </div>

        <Load3 />

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div style={{ backgroundColor: "#0f0f0f", marginTop: -55 }}>
          <Pagetitle
            title={`Explore More Movies!`}
            bg="#152238"
            color="#fff"
            home="Go Back To Alarrt"
            searchUrl="movie/search"
            redirect="movie"
          />

          <InfiniteScroll
            dataLength={movies.length} //This is important field to render the next data
            next={getNext}
            hasMore={true}
            // loader={<Load message='scroll down to load more' />}
            loader={''}
            // <Load message='scroll down to load more' />
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            <div
              className="row ps-2 pe-1"
              style={{ marginRight: 20, marginLeft: 20 }}
            >
              {movies.map((movie, index) => (
                <div
                  key={index}
                  // className='movie-grid'
                  className="col-md-6 col-xss-12 pe-2 ps-2"
                  //   style={{paddingTop:180}}
                >
                  {/* {movie.type == "movie" && (
                    <>
                      {window.sessionStorage.setItem(
                        "url",
                        `/channel/${movie.channel.slug}/movie/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                      )}
                    </>
                  )}
                  {movie.type == "video" && (
                    <>
                      {window.sessionStorage.setItem(
                        "url",
                        `/channel/${movie.channel.slug}/video/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                      )}
                    </>
                  )}
                  {movie.type == "episode" && (
                    <>
                      {window.sessionStorage.setItem(
                        "url",
                        `/channel/${movie.channel.slug}/series/episode/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                      )}
                    </>
                  )} */}
                  {/* <Link
                  // to={{
                  //   pathname: window.sessionStorage.getItem("url"),
                  // }}
                > */}
                  <a 
                   onClick={() => {
                   redirect(movie)
                    
                  }}
                  // href={window.sessionStorage.getItem("url")}
                  
                  >
                    <div
                      className="movie-card overflow-hidden shadow-xss bg-gradiant-bottom"
                      style={{
                        backgroundImage: `url("${movie.image}")`,
                      }}
                    >
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />

                      <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                        <Link
                          // to={{
                          //   pathname: `/channel/${movie.channel.slug}`,
                          // }}

                          onClick={() => {
                            history.push({
                              pathname: `/channel/${movie.channel.slug}`,
                            });
                            window.location.reload();
                          }}
                        >
                          <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                            <img
                              src={movie.channel.channel_pic}
                              alt="avater"
                              className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                            />
                          </figure>
                        </Link>
                        <div className="clearfix"></div>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
                          {movie.title}
                        </h4>

                        <a
                          onClick={() => {
                            // alert("clicked");
                            history.go({
                              pathname: `channel/${movie.channel.slug}`,
                            });
                          }}
                        >
                          <Chip
                            className="pointer infotag"
                            color="primary"
                            size="small"
                            label={movie.channel.name}
                            //   variant="outlined"
                            // clickable
                            // onClick={goToChannel(movie.channel.slug)}
                          />
                        </a>
                        {movie.type == "episode" ? (
                          <>
                            <br />
                            <br />
                            {/* <Link
                          // to={{
                          //   pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
                          // }}
                        > */}
                            <a
                              // href={`/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`}

                              onClick={() => {
                                history.push({
                                  pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
                                });
                                window.location.reload();
                              }}
                            >
                              {/* Episode Of{" "} */}
                              <Chip
                                className="pointer infotag"
                                color="#fff"
                                size="small"
                                label={`Series: ${movie.series.title}`}
                                //   variant="outlined"
                                clickable
                                onClick={() => {
                                  history.push({
                                    pathname: `/channel/${movie.series.channel.slug}/series/${movie.series.slug}?channel=${movie.series.channel.slug}&w=${movie.series.id}&a=${movie.series.channel.admin.id}`,
                                  });
                                  window.location.reload();
                                }}
                              />
                            </a>
                            {/* </Link> */}
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          className="fw-500 text-white font-xssss mt-1 pointer"
                          style={{
                            display: "flex",
                            alignSelf: "center",
                            justifyContent: "center",
                            // marginTop: -20
                          }}
                        >
                          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                            {" "}
                          </span>
                        </div>
                        {/* )} */}
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                          {/* {movie.title} */}
                          Type: {movie.type} - Pricing: {movie.pricing}{" "}
                          {movie.price}
                        </h4>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                          Released:{" "}
                          {formatDistance(
                            new Date(movie.created_at),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </h4>
                      </div>
                    </div>
                  </a>
                  {/* </Link> */}
                </div>

                //   </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>

        {/* </div> */}

        {error ? (
          <div>
            <br />
            <Alert
              severity="error"
              onClose={closeError}
              action={
                <>
                  <CancelIcon onClick={closeError} />
                </>
              }
            >
              There was an issue fetching movies. Kindly refresh or check your
              network [ {errorMessage} ]
            </Alert>
            <br />
          </div>
        ) : (
          ""
        )}

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}
