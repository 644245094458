import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
// import { Select } from "antd";
import slugify from "react-slugify";
import Resizer from "react-image-file-resizer";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { Snackbar } from "@material-ui/core";

export default function PageUpdateModal(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  // const page = props.page
  const adminDetails = props.adminDetails.id;
  const pageId = props.page.id;
  const page = props.page;
  const [isUpdating, setIsUpdating] = useState(false);
  const [pageName, setPageName] = useState(page.name);
  const [pageDes, setPageDes] = useState(page.description);
  const [pageStatus, setPageStatus] = useState("");
  const [pageStatusFe, setPageStatusFe] = useState("");
  const [pageCover, setPageCover] = useState("");
  const [pageCoverFe, setPageCoverFe] = useState("");
  const [pagePic, setPagePic] = useState("");
  const [pagePicFe, setPagePicFe] = useState("");
  const [pageTags, setPageTags] = useState([]);
  const history = useHistory();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [newName, setNewName] = useState(page.slug);
  const [error, setError] = useState(false);
  const { slug } = useParams(["slug"]);
  const [value, setValue] = React.useState("1");
  const location = useLocation();
  const name = "";
  const animatedComponents = makeAnimated();

  const [open, setOpen] = React.useState(false);
  const serviceOptions = [
    { value: "business", label: "Business" },
    { value: "lifestyle", label: "Lifestyle" },
    { value: "government", label: "Government" },
    { value: "fire", label: "Fire Service" },
    { value: "medical", label: "Medical" },
  ];

  const statusSelect = [
    { value: "government", label: "Government" },
    { value: "business", label: "Business" },
  ];
  

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // setEmptyEmail(false);
    setOpen(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function changeStatus(value) {
    console.log(`status ${JSON.stringify(value)}`);
    setPageStatus(value.value);
    setPageStatusFe(value)
  }
  function clearPagePics(value) {
    setPagePicFe('');
    setPagePic('');
  }
  function clearCoverImage(value) {
    setPageCover('');
    setPageCoverFe('');
  }


  function redirect(name) {
    // setIsSuccess(false);
    // history.push(`/profile/${data}`);
    console.log(`redirect ${name}`);
    history.push(`/page/${name}`);
    history.go(`/page/${name}`);
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      90,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setPagePic(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      80,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setPageCover(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeCoverFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function validate() {
    console.log(`pageName ${pageName}`);
    if (!pageName) {
      setEmptyFields(true);
      return;
    } else {
      updatepage();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
    setIsSuccess(false);
  };

  function ChangeTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => pageTags.push(tag.value));
    console.log(`name ${pageTags}`);

    // setSelectedTags([...new Set(tags)]);
    // console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  async function validateProfileImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setPagePicFe(value);
    //   setInvalidFile(false);
    // }
  }

  async function validateCoverImage(value) {
    // if (!value.name.match(/\.(jpg|jpeg|png|gif)$/)) {
    //   setInvalidFile("Please select valid image.");
    //   return false;
    // } else {
      console.log(value);
      setPageCoverFe(value);
    //   setInvalidFile(false);
    // }
  }

  async function updatepage() {
    setIsUpdating(true);
    const name = slugify(pageName);
    setNewName(name);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    console.log(`new name is ${newName}`);
    const formData = new FormData();
    // formData.append("user_id", userID);
    formData.append("name", pageName);
    formData.append("description", pageDes);
    formData.append("tags", pageTags);
    // formData.append("status", pageStatus);
    formData.append("cover_pic", pageCover);
    formData.append("page_pic", pagePic);
    formData.append("status", pageStatus);

    axiosFetch
      .post(
        `/page/update/${pageId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if (resp.data.status == "success") {
          console.log(`hi name 2 ${name}`);

          //   setPageName("");
          setPageDes("");
          setPageStatus("");
          setPageStatusFe("");
          setPageCover("");
          setPageCoverFe("");
          setPagePic("");
          setPagePicFe("");
          setPageTags([]);

    
          setIsUpdating(false);
          //   props.functionProp()
          console.log(`response ${JSON.stringify(resp.data)}`);
          setIsSuccess(true);
          redirect(name);
          //   history.push(`/page/${newName}`);
          //   history.go(`/page/${newName}`);
        } else {
          setIsUpdating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setIsUpdating(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }
  const { Option } = Select;
  const types = ["free", "paid"];


  function updateName(e) {
    console.log("new name update");
    const name = slugify(pageName);
    setNewName(name);
  }

  useEffect(() => {
    // console.log(`state name is ${newName}`)
  }, [console.log(`page pic is ${JSON.stringify(pagePic)}`)]);

  return (
    <>
      <a
        onClick={handleClickOpen}
        className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
      >
        <i className="feather-edit font-md"></i>
      </a>
      {/* <a className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">
        Edit page
      </a> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Update Your Page Here</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skip The Step You Don't Want To Update. You don't need to update
            all, just edit the info you need to change and click update
          </DialogContentText>

          <div className="card-body mt-1 p-lg-5 p-4 w-100 border-0 ">
            <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
              {pageCoverFe ? (
                <img
                  src={URL.createObjectURL(pageCoverFe)}
                  alt="avater"
                  style={{
                    maxWidth: 1450,
                    maxHeight: 450,
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={page.cover_pic}
                  alt="avater"
                  style={{
                    maxWidth: 1450,
                    maxHeight: 450,
                    objectFit: "contain",
                  }}
                />
              )}
            </div>
            <div className="card-body p-0 position-relative">
              {pagePicFe ? (
                //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                <>
                  {/* <p> Your page Cover Picture </p> <br /> */}
                  <figure
                    className="avatar position-absolute w100 z-index-1"
                    style={{ top: "-40px" }}
                  >
                    <img
                      src={URL.createObjectURL(pagePicFe)}
                      alt="avater"
                      className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                    />
                  </figure>
                </>
              ) : (
                <figure
                  className="avatar position-absolute w100 z-index-1"
                  style={{ top: "-40px" }}
                >
                  <img
                    src={page.page_pic}
                    alt="avater"
                    className="float-right p-1 bg-white shadow-sm rounded-circle w-100"
                  />
                </figure>
              )}

              <h4 className="fw-700 font-sm mt-2 pl-15">
                {/* First Name{" "}
                        Last name */}
                <span className="fw-500 font-xssss text-primary mt-1 mb-3 d-block">
                  {/* @User Name */}
                </span>
              </h4>
            </div>
            <div className="card-body mt-5 p-lg-5 p-4 w-100 border-0 ">
              {/* <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w100">
                        <img
                          src="https://via.placeholder.com/300x300.png"
                          alt="avater"
                          className="shadow-sm rounded-3 w-100"
                        />
                      </figure>

                    </div>
                  </div> */}
              <br />    <br />   
              <b>
                <h4>
                  Hello {page.admin.first_name} you don't have to edit all.
                  Only edit what needs to be changed and click update{" "}
                </h4>
              </b>

              <form>
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <br />
                      <br />
                      <label className="mont-font fw-600 font-xsss mb-2">
                        page Name
                      </label>
                      {/* value={this.state.first_name} */}
                      <input
                        type="text"
                        defaultValue={page.name}
                        onChange={(e) => {
                          setPageName(e.target.value);
                          const name = slugify(pageName);
                          setNewName(name);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                      What is Your Page About?
                    </label>
                    <textarea
                      className="form-control mb-0 p-3 h100 bg-greylight lh-16"
                      rows="5"
                      defaultValue={page.description}
                      onChange={(e) => setPageDes(e.target.value)}
                      placeholder="Let subscribers know a bit about your contents..."
                    ></textarea>
                  </div>

                 
                </div>

                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <div className="form-group">
                      <label className="mont-font fw-600 font-xsss mb-2">
                        Select Page Type
                      </label>
                      <p>
                        {" "}
                        Only select 'Government' if this is for a government institution or you wish to provide assistnce to emergency alerts. 
                      </p>
                      <Select
                            value={pageStatusFe}
                            label="Select One"
                            size="large"
                            style={{ width: "90%" }}
                            options={statusSelect}
                            onChange={changeStatus}
                            defaultInputValue={page.status}
                          >
                            {statusSelect.map((status) => (
                              <Option key={status.value} value={status.value}>
                                {" "}
                                {status.label}{" "}
                              </Option>
                            ))}
                          </Select>
                      <br /><br/>
                      <p>Page type currently set to {page.status}</p>
                    </div>
                  </div>
                </div>
                
                <div className="row">
                      <div className="col-lg-12 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Update Page Services
                          </label>
                          <p>
                            Select or type new to create. Existing ones are deleted
                          </p>

                          <div>
                            <CreatableSelect
                              isClearable
                              isMulti
                              onChange={ChangeTag}
                              //   options={props.tags}
                              components={animatedComponents}
                              options={serviceOptions}
                            ></CreatableSelect>
                          </div>
                        </div>
                      </div>
                    </div>

                <div className="col-lg-12 mb-3">
                  {/* </div> */}

                  {pagePicFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      <p> Your Page's Logo </p> <br />
                      <img
                        src={URL.createObjectURL(pagePicFe)}
                        alt="avater"
                        style={{
                          // width: 1450,
                          // maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                          //    borderRadius: '10%'
                        }}
                      />
                      <Button onClick={clearPagePics}>Remove</Button>
                      <br />
                      <br />
                    </>
                  ) : (
                    //  </div>
                    <div className="card mt-3 border-0">
                      <div className="card-body d-flex justify-content-between align-items-end p-0">
                        <div className="form-group mb-0 w-100">
                          <input
                            type="file"
                            accept="image/*"
                            name="file-2"
                            id="file-2"
                            className="input-file"
                            onChange={(e) => {
                              validateProfileImage(e.target.files[0])
                              CompressImage(e)
                            }}
                          />
                          <label
                            htmlFor="file-2"
                            className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                          >
                            <i className="ti-cloud-down large-icon me-3 d-block"></i>
                            <span className="js-fileName">
                              Replace your page
                              logo
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}

                  {invalidFile ? <h4> {invalidFile} </h4> : ""}
                </div>

                {invalidFile ? <h4> {invalidFile} </h4> : ""}

                <div>
                  {pageCoverFe ? (
                    //    <div className="card-body h250 p-0 rounded-xxl overflow-hidden m-3 shadow-sm">
                    <>
                      <p> Your page Cover Picture </p> <br />
                      <img
                        src={URL.createObjectURL(pageCoverFe)}
                        alt="avater"
                        style={{
                          // width: 1450,
                          // maxWidth: 1450,
                          maxHeight: 450,
                          objectFit: "contain",
                          //    borderRadius: '10%'
                        }}
                      />
                      <Button onClick={ clearCoverImage}>
                        Remove
                      </Button>
                      {/* //  </div> */}
                    </>
                  ) : (
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            <input
                              type="file"
                              accept="image/*"
                              name="file"
                              id="file"
                              className="input-file"
                              onChange={(e) => {
                                validateCoverImage(e.target.files[0])
                                compressCoverImage(e)
                              }}
                              // onChange={(e) =>
                              //     console.log(`test ${e.target.files[0]}`)}
                            />
                            <label
                              htmlFor="file"
                              className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                            >
                              <i className="ti-cloud-down large-icon me-3 d-block"></i>
                              <span className="js-fileName">
                                Replace your page's
                                cover picture
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-lg-12">
                  {isUpdating ? (
                    <Button disabled variant="contained" color="primary">
                      Updating Your page
                    </Button>
                  ) : (
                    // <a className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block" disabled>Updating Your Profile</a> */}
                    <a
                      onClick={validate}
                      className="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block"
                    >
                      Update Page
                    </a>
                  )}
                  <br />
                  <br />
                  <br />
                  {emptyFields ? (
                    <>
                      {/* <br />
                          <br /> */}
                      <Alert onClose={handleClose} severity="warning">
                        To create a page, you need to ensure it has at least
                        a name
                      </Alert>
                    </>
                  ) : (
                    ""
                  )}

                  {error ? (
                    <div>
                      <Alert
                        severity="error"
                        onClose={handleClose}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        There was an issue updating your page. Kindly try
                        again [ {errorMessage} ]
                      </Alert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}

                  {isSuccess ? (
                        <Snackbar
                        open={isSuccess}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                    <Alert
                      onClose={handleClose}
                      variant="filled"
                      severity="success"
                      action={
                        <Button
                          onClick={redirect}
                          style={{ color: "#fff", alignSelf: "center" }}
                        >
                          {" "}
                          Check It Out
                        </Button>
                      }
                    >
                      Your page was updated sucessfully! Redirecting ...
                    </Alert>
                    </Snackbar>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {isUpdating ? (
            <>
              <Button disabled>Updating Your page</Button>
              <p>This may take a quick minute. You'll Be redirected after</p>
            </>
          ) : (
            <>
              <Button onClick={validate}>Update Now</Button>
            </>
          )}
          {/* <Button onClick={validate}>Create</Button> */}
        </DialogActions>
        {emptyFields ? (
          <Alert onClose={handleClose2} severity="warning">
            Oops - name can not be empty. Kindly update
          </Alert>
        ) : (
          ""
        )}
      </Dialog>{" "}
    </>
  );
}
