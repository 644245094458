import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Component, useState, useEffect } from "react";
import { debounce } from "lodash";
import axiosFetch from "../Interceptors2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ReportModal from "./ReportModal";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ForwardAlerts from "./ForwardAlert";
import ForwardAlertRandom from "./ForwardAlertRandom";
import AlertUpdateModal from "./AlertUpdateModal";
import MuiAlert from "@material-ui/lab/Alert";

import { Snackbar } from "@material-ui/core";

export default function AlertDropComp(props) {
  const [copied, setCopied] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  // const [error, setError] = useState(false);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const [realerting, setRealerting] = useState(false);
  const [realerted, setRealerted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );

  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 10,
    border: "1px solid",
    p: 1,
    width: "90%",
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };
  function closeSnack() {
    setCopied(false);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function handleClose2() {
    setRealerted(false);
    setSaved(false);
    setConfirmDelete(false);
  }

  async function realert(id) {
    setRealerting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/realert/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setRealerting(false);
        setRealerted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function saveAlert(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteAlert(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/alerts/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        setDeleted(true);
        props.functionProp();
        props.functionProp2();
        setShowMenu(false);

        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  return (
    <>
      <div>
        <a>
          {" "}
          <i
            onClick={toggleMenu}
            style={{ fontSize: 25, position: "relative", zIndex: 10 }}
            className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
            // className="ti-more-alt text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
          ></i>{" "}
        </a>
        {showMenu ? (
          <ClickAwayListener onClickAway={handleClickAway} style={{zIndex: 10}}>
            <div
              className="bg-white  shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
              // data-uk-drop="mode: click;pos: bottom-right"
              style={styles}
            >
              <ul className="space-y-1">
                <li>
                  {props.canUpdate == true ? (
                    <AlertUpdateModal
                      title="Edit Alarrt"
                      content={props.alert}
                      type="alert"
                      alertId={props.alert.id}
                      functionProp={() => props.functionProp()}
                      functionProp2={() => props.functionProp2()}
                    />
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <a
                    onClick={() => realert(props.alert.alert_origin)}
                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  >
                    <i className="feather-zap mr-1"></i> Re-alarrt
                  </a>
                </li>

                <li>
                  <div>
                    {realerting ? (
                      <p>
                        {" "}
                        Realarrting... This will take a few seconds. Your close
                        friends will also be notified by SMS and email
                      </p>
                    ) : (
                      ""
                    )}
                    {realerted ? (
                      <MuiAlert
                        onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setRealerted(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've realarrted this alert successfully. Your close
                        friends have been notified via email and sms as well.
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>

                <li>
                  <ForwardAlerts alertId={props.alert.alert_origin} />
                </li>
                <li>
                  <ForwardAlertRandom alertId={props.alert.alert_origin} />
                </li>
                <li>
                  <a
                    onClick={() => saveAlert(props.alert.id)}
                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  >
                    <i className="uil-favorite mr-1"></i> Save Alarrt
                  </a>
                </li>
                <li>
                  <div>
                    {saving ? (
                      <p> Saving Alarrt... This will take a few seconds </p>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <MuiAlert
                        onClose={handleClose2}
                        severity="success"
                        action={
                          <>
                            <a
                              onClick={() => setSaved(false)}
                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              close
                            </a>
                          </>
                        }
                      >
                        You've saved this alarrt successfully.
                        <a href={`/profile/${userName}`}>
                          View it in your profile
                        </a>
                      </MuiAlert>
                    ) : (
                      ""
                    )}
                  </div>
                </li>
                <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li>

                <li>
                  {props.canDelete == true ? (
                    <a
                      // href="#"
                      // disabled
                      onClick={() => setConfirmDelete(true)}
                      className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                    >
                      <i className="uil-trash-alt mr-1"></i> Delete
                    </a>
                  ) : (
                    ''
                  //   <a
                  //   // href="#"
                  //   disabled
                  //   // onClick={() => setConfirmDelete(true)}
                  //   className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                  // >
                  //   <i className="uil-trash-alt mr-1"></i> You Can't Delete
                  // </a>
                  )}
                  {confirmDelete ? (
                    <div>
                      <p> Are you sure you want to delete this ? </p>
                      <MuiAlert
                        onClose={handleClose2}
                        severity="error"
                        action={
                          <>
                            <a
                              onClick={() => deleteAlert(props.alert.id)}
                              className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              Yes, delete it.
                            </a>
                            <br />
                            <a
                              onClick={() => setConfirmDelete(false)}
                              className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                            >
                              No, Cancel
                            </a>
                          </>
                        }
                      >
                        Are you sure you want to delete this ?
                      </MuiAlert>
                    </div>
                  ) : (
                    ""
                  )}
                </li>
                <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li>
                <li>
                  {props.canReport == true ? (
                    <ReportModal
                      type="alert"
                      text="Report"
                      url="alerts"
                      id={props.alert.id}
                    />
                  ) : (
                    ""
                  )}
                </li>
              </ul>
            </div>
          </ClickAwayListener>
        ) : (
          ""
        )}

        {copied ? (
          <>
            <br />
            {/* <p className="text-primary">link copied</p> */}
            <Snackbar
              open={copied}
              autoHideDuration={1000}
              onClose={closeSnack}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <Alert onClose={closeSnack} severity="success">
                Link Copied Successfully
              </Alert>
            </Snackbar>
          </>
        ) : null}
      </div>
    </>
  );
}
