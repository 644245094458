import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import axiosFetch from '../Interceptors2';
// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";

export default function SendSms() {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const { user_name } = useParams(["user_name"]);
  const [failedSms, setFailedSms] = useState(false);
  const [sentSms, setSentSms] = useState(false);
  const [points, setPoints] = useState("");
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSentSms(false);
    setFailedSms(false);
  };

  async function sendSms() {
    setSending(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("phone", phone);
    formData.append("message", message);

    axiosFetch
      .post(
        `/sms/send/random`,
        formData,
        {
          // method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if(resp.data.status == 'error'){
          setFailedSms(true)
          setErrorMessage(resp.data.message);
          // console.log('you have been blocked')
        }else{
        console.log(resp.data);
        setSentSms(true);
        getUserPoints();
        setMessage("");
        setSending(false);
        // setSms("");
        // setOpen(false);
        }
      })
      .catch((error) => {
        // if(error.response.status == 403){
          setSending(false);
        setFailedSms(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
        // }
        
      });
  }

  async function getUserPoints() {
    // setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/sms/points`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setPoints(resp.data);
      })
      .catch((error) => {
        // setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getUserPoints();
  }, []);

  return (
    <div>
      <Tooltip title="Send SMS">
          <li className="list-inline-item d-block  me-0"><Link onClick={handleClickOpen} className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Send Random Sms</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li>
      </Tooltip>
      <br />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Send an sms to any number</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your points are used to send sms. Depending on your friend's network
            provider, each sms costs a minimum of 4 points. (This only works
            with Nigerian Numbers At The Moment)
          </DialogContentText>
          <br />
          <h5> You currently have {points} points</h5>
           <TextField
            // autoFocus
            // margin="dense"
            // id="name"
            label="Phone Number"
            type="text"
            fullWidth
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            variant="standard"
          /><br/><br/>
          <div className="col-lg-12 mb-3">
            <label className="mont-font fw-600 font-xsss mb-2 text-dark">
              Type Your Message
            </label>
            <textarea
              // className="fw-500 bg-greylight  text-black-900 lh-26 font-xssss w-100 mb-2"
              className="form-control mb-0 p-3 h100 bg-greylight lh-20"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
            ></textarea>
          </div>
         
        </DialogContent>
        <br />
        <br />
        <br />

        {sentSms ? (
          <Alert onClose={handleClose} variant="filled" severity="success">
            Your sms has been sent to {user_name} successfully!
          </Alert>
        ) : (
          ""
        )}

        {failedSms ? (
          <Alert onClose={handleClose} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        ) : (
          ""
        )}
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
          {sending ? (
            <Button disabled>Sending</Button>
          ) : (
            <Button onClick={sendSms}>Send SMS</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
