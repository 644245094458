import React from "react";
import { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./widgets.css";
import SearchIcon from "@material-ui/icons/Search";
import {
  // Link,
  Avatar,
  Container,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import SearchBarV2 from "../SearchBarV2";
import Appfooter from "../Appfooter";
import Header from "../Header";
import UpdateCategory from "./UpdateCategory";

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(2),
    color: "#555",
    fontSize: 16,
  },
}));

function AlertFilterAdmin(props) {
  const classes = useStyles();
  const [alertCategory, setAlertCategory] = useState([]);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const [profile, setProfile] = useState([]);
  const [deactivating, setDeactivating] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [status, setStatus] = useState("inactive");
//   const [update, setUpdate] = useState(false);
//   const [isUpdating, setIsUpdating] = useState(false);
//   const [updated, setUpdated] = useState(false);

  async function getProfile() {
    // function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setProfile(resp.data);
        getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function getAlertCategories() {
    setFetchingAlerts(true);
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category/active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAlertCategory(result);
        setFetchingAlerts(false);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }


  async function makeInactive(id) {
    setDeactivating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    const formData = new FormData();

    formData.append("status", status);
    axiosFetch
      .post(`/alert_category/disable/${id}`, formData, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setDeactivating(false);
        setDeactivated(true);
        // console.log(resp.data);
        getAlertCategories();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function closeSnack() {
    setDeactivated(false);
    // setUpdated(false);
  }

  useEffect(() => {
    getProfile();
    // getAlertCategories();
  }, []);

  if (fetchingAlerts == true) {
    console.log(`final ${fetchingAlerts}`);
    return <>Fetching Categories ...</>;
  }
  if (profile.length == 0) {
    return <p>Just A Sec...</p>;
  } else {
    return (
      <>
        {profile.user.is_admin != "true" ? (
          <>
            <p>Oops! You are not permitted to view this page</p>
          </>
        ) : (
          <>
            <div className="widgets">
              <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
                <div
                  style={{
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "flex-start",
                    justifyItems: "flex-start",
                  }}
                  className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800"
                >
                  <SearchBarV2 searchUrl="alerts/search" redirect="alert" />
                  <br />
                  <br />
                  {/* <p className='text-center'>You can search by location also (Nigeria, Lagos etc)</p> */}
                </div>
                <p
                  className="text-center"
                  style={{
                    marginLeft: 20,
                    marginTop: 3,
                    display: "flex",
                    alignSelf: "flex-start",
                    justifyContent: "flex-start",
                    justifyItems: "flex-start",
                  }}
                >
                  You can search by location also (Nigeria, Lagos etc)
                </p>

                <div className="widgets__widgetContainer">
                  <h2>Alerts Created In The Last 24 Hrs</h2>
                  <h5>You are viewing alerts by their category as an admin. Deactivated Categories will not appear here, so beware</h5>
                  <ul className="rightbarFriendList">
                    {/* <Typography className={classes.title} gutterBottom>
            Popular Categories
          </Typography> */}
                    <Divider flexItem style={{ marginBottom: 10 }} />
                    {alertCategory.map((value, index) => (
                      <div key={index}>
                        <li className="rightbarFriendListItem">
                          <Link
                            to={`/alert-category/${value.slug}`}
                            className={classes.link}
                            // className=' text-white hover:text-primary '
                            variant="body2"
                          >
                            {value.name} - {value.status} -{" "}
                            {value.active_alerts.length} Alerts
                          </Link>

                          {deactivating ? (
                            <Button
                              disabled
                              variant="contained"
                              color="primary"
                            >
                              Deactivating ..
                            </Button>
                          ) : (
                            <Button
                              // disabled
                              variant="contained"
                              color="primary"
                              // href="/"
                              // target="_blank"
                              onClick={() => makeInactive(value.id)}
                              // onClick={makeInactive}
                            >
                              Make Inactive
                            </Button>
                          )}
                          <UpdateCategory slug={value.slug} name={value.name} id={value.id} functionProp={() => getAlertCategories()} />
                          
                        </li>
                      </div>
                    ))}
                  </ul>
                  <br />
                  <p className="fw-600 font-xssss mt-0 text-primary">
                    Only update or disable alert categories that do not comply
                    to our regulations.
                  </p>

                  {deactivated ? (
          <Snackbar
            open={deactivated}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Deactivated Alert Category Successfully
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
                </div>
              </div>
            </div>
          </>
        )}

        <Appfooter />
      </>
    );
  }
}

export default AlertFilterAdmin;
