import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import axiosFetch from "../Interceptors2";
import Chip from "@material-ui/core/Chip";
import { Button } from "@material-ui/core";
import { format, formatDistance, subDays } from "date-fns";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CreateComment from "./comment/CreateComment";
import SingleComment from "./comment/SingleComment";
import Load from "./Load";
import Load2 from "./Load2";

import SingleImage from "./SingleImage";
import TwoImages from "./TwoImages";
import ThreeImages from "./ThreeImages";
import FourImages from "./FourImages";
import ShareButtonsPost from "./ShareButtonsPost";
import Test4 from "./Test4";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import LikesProfilePic from "./LikesProfilePic";
import SinglePostComponent from "./SinglePostComponents";
import { Player } from "video-react";
// import { Player } from "video-react";
import AlertUpdateModal from "./AlertUpdateModal";
import PostUpdateModal from "./PostUpdateModal";
import BoltIcon from "@mui/icons-material/Bolt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LikersModal from "./LikersModal";
import AllThreads from "./comment/AllThreads";
import CreateThread from "./comment/CreateThread";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import { CloudinaryContext, Image, Video } from "cloudinary-react";
import { Cloudinary, Transformation } from "@cloudinary/url-gen";
import { AdvancedVideo } from "@cloudinary/react";
import { scale } from "@cloudinary/url-gen/actions/resize";

import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import IconButton from "@mui/material/IconButton";
import style from "./style.js";
import ShareMovie from "./ShareMovie";
import ReportModal from "./ReportModal";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PostDropUserAuth from "./PostDropUserAuth";
import ReactPlayer from "react-player";
import ImageComponent from "./ImageComponent";
import ImageComponentDiv from "./ImageComponentDiv";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//
const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];

export default function PostComponent(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isThread, setIsThread] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isGetting, setIsGetting] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [currentPost, setCurrentPost] = useState("");
  const [singlePost, setSinglePost] = useState([]);
  const [likes, setLikes] = useState(0);

  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const authId = useState(window.localStorage.getItem("user_id"));
  const [reposting, setReposting] = useState(false);
  const [reposted, setReposted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followed, setFollowed] = useState(false);
  const [followText, setFollowText] = useState("follow");
  const [hover, setHover] = useState(false);
  const linkStyle = {};
  const postVid = "";
  const [showMenu, setShowMenu] = useState(false);
  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    width: "90%",
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }

  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "alarrt",
    },
  });

  function closeSnack() {
    setFollowed(false);
  }

  // const toggleHover = () => setHover(!hover);
  function toogleHover() {
    if (hover) {
      linkStyle = { backgroundColor: "red" };
    } else {
      linkStyle = { backgroundColor: "blue" };
    }
  }

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function openComment() {
    setIsComment(true);
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function likePost(id) {
    setIsLiking(true);
    // setIsLiked(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/like/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;

        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
        // setIsLiked(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function unLikePost(id) {
    setIsLiking(true);
    // setIsLiked(false);
    // setIsLiked(false);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/like/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;

        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
        // setIsLiked(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleAction = () => setOpenAction(!openAction);
  const toggleComment = () => setIsComment(!isComment);
  const toggleThread = () => setIsThread(!isThread);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (post) => {
    console.log("opened");
    setOpen(true);
    setSinglePost(post);
  };

  const handleClose2 = () => {
    setOpen(false);
    setReposted(false);
    setSaved(false);
    setConfirmDelete(false);
  };

  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }

  async function deletePost(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/posts/delete/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        props.functionProp();
        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function savePost(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disableComment(id) {
    setDisabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/disablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setDisabling(false);
        setDisabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function enableComment(id) {
    setEnabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/enablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setEnabling(false);
        setEnabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function repost(id) {
    setReposting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/repost/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setReposting(false);
        setReposted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function Follow(user_name) {
    setFollowing(true);
    setFollowText("following...");
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setFollowText("Followed!");
        props.functionProp();
        setFollowed(true);
        setFollowing(false);
        // getFollowing();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }

  let menuRef = useRef();

  const handleClickAway = (e) => {
    console.log(e);
    if (e.path[0] !== menuRef.current) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    // document.addEventListener("contextmenu", handleContextMenu);

    // return () => {
    //   document.removeEventListener("contextmenu", handleContextMenu);
      
    // };

  }, []);

  const menuClass = `${isOpen ? " show" : ""}`;
  const actionClass = `${openAction ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  //   const { isLiked } = this.state;
  const commentOpen = isComment;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingAlerts == true) {
    // console.log(`final ${fetchingAlerts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load />
      </>
    );
  }
  if (props.posts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              <Load />
              {/* Hello {userName}! No posts can be displayed yet. Create one or
              follow people to populate your timeline. */}
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <>
        {props.posts.map((post, id) => (
          <div
            // once = {post.id}
            // count = {id + 1}
            key={post.post.id}
            className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
          >
            {/* <p> Post id is {post.post.id}</p> */}
            {/* {post.post.owner == null ? ( */}
            {post.post.reposted == "false" ? (
              <div className="card-body p-0 d-flex">
                {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
                <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={post.post.user.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                    onError={fixImage}
                    src={post.post.user.profile_pic}
                    alt="avater"
                    className="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  /> */}
                </figure>
                {/* </div> */}
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  {post.post.user.first_name} {post.post.user.last_name}
                  <Link to={`/profile/${post.post.user.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{post.post.user.user_name}
                      {post.post.user.is_active == "false" ? (
                        <span className="text-danger ml-5">
                          ( Deactivated )
                        </span>
                      ) : (
                        ""
                      )}
                    </span>{" "}
                  </Link>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {formatDistance(
                      new Date(post.post.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                    {/* {alert.created_at} */}
                  </span>
                </h4>

                <div className={`ms-auto pointer`} ref={menuRef}>
                  {userId[0] == post.post.user.id ? (
                    <>
                      {/* <ShareButtonsPost post={post.post} /> */}
                      <PostDropUserAuth
                        canReport={false}
                        canDisable={true}
                        canUpdate={true}
                        canDelete={true}
                        post={post.post}
                        functionProp={() => props.functionProp()}
                      />
                    </>
                  ) : (
                    <>
                      {/* <ShareButtonsPost post={post.post} /> */}
                      <PostDropUserAuth
                        canReport={true}
                        canDisable={false}
                        canUpdate={false}
                        canDelete={false}
                        post={post.post}
                        functionProp={() => props.functionProp()}
                      />
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {/* owner */}
                <div className="card-body p-0 d-flex">
                  {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
                  <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                    <ImageComponent
                      image={post.post.owner.profile_pic}
                      class="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    />
                    {/* <img
                      onError={fixImage}
                      src={post.post.owner.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                  </figure>
                  {/* </div> */}
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    <span style={{ paddingRight: 2 }}>
                      Reposted From : {post.post.owner.first_name}{" "}
                      {post.post.owner.last_name}'s
                    </span>
                    {post.post.type == "alert" ? (
                      <Link
                        to={{
                          pathname: `/alert/${post.post.slug}?i=${post.post.post_origin}`,
                          // state: { id: post.post.post_origin },
                        }}
                      >
                        Alert
                      </Link>
                    ) : (
                      <Link
                        to={{
                          
                          pathname: `/post/${post.post.unique_id}/${post.post.post_origin}`
                          // `/post/${post.post.unique_id}`,
                          // state: { id: post.post.post_origin },
                        }}
                      >
                        Post
                      </Link>
                    )}
                    <Link to={`/profile/${post.post.owner.user_name}`}>
                      {" "}
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                        {" "}
                        @{post.post.owner.user_name}
                        {post.post.owner.is_active == "false" ? (
                          <span className="text-danger ml-5">
                            ( Deactivated )
                          </span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </Link>
                  </h4>
                </div>

                <div className="card-body p-0 d-flex">
                  {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
                  <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                    <ImageComponent
                      image={post.post.user.profile_pic}
                      class="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    />
                    {/* <img
                      onError={fixImage}
                      src={post.post.user.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                  </figure>
                  {/* </div> */}
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    {post.post.user.first_name} {post.post.user.last_name}
                    <Link to={`/profile/${post.post.user.user_name}`}>
                      {" "}
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                        {" "}
                        @{post.post.user.user_name}
                        {post.post.user.is_active == "false" ? (
                          <span className="text-danger ml-5">
                            ( Deactivated )
                          </span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </Link>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {formatDistance(
                        new Date(post.post.created_at),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}
                      {/* {alert.created_at} */}
                    </span>
                  </h4>
                  {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}

                  <div className={`ms-auto pointer`} ref={menuRef}>
                    {userId[0] == post.post.user.id ? (
                      <>
                        {/* <ShareButtonsPost post={post.post} /> */}
                        <PostDropUserAuth
                          canReport={false}
                          canDisable={true}
                          canUpdate={false}
                          canDelete={true}
                          post={post.post}
                          functionProp={() => props.functionProp()}
                        />
                      </>
                    ) : (
                      <>
                        {/* <ShareButtonsPost post={post.post} /> */}
                        <PostDropUserAuth
                          canReport={true}
                          canDisable={false}
                          canUpdate={false}
                          canDelete={false}
                          post={post.post}
                          functionProp={() => props.functionProp()}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <div>
              <Link
                to={{
                  pathname: `/post/${post.post.unique_id}/${post.post.id}`,
                  // state: { id: post.post.post_origin },
                }}
              >
                <h3 className="fw-700 text-grey-900 pointer">
                  {" "}
                  {post.post.headline}{" "}
                </h3>
              </Link>
            </div>

            {post.post.video ? (
              // {post.post.videos.length > 0 ? (
              <div className="card-body p-0 mb-2 rounded-3 overflow-hidden uttam-die">
                {/* <Player
                  playsInline
                  // poster={alert.alert.images[0].url}
                  src={post.post.video}
                  fluid
                /> */}
                <ReactPlayer
                  url={post.post.video}
                  controls={true}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  playsinline={true}
                  width="100%"
                  // light={alert..image}
                />
              </div>
            ) : (
              ""
            )}

            {post.post.images ? (
              <div className="card-body d-block p-0 mb-1">
                <br />
                {/* {post.post.images.length} */}
                {post.post.images.length == 1 ? (
                  <SingleImage images={post.post.images} text={""} />
                ) : (
                  ""
                )}
                {post.post.images.length == 2 ? (
                  <TwoImages images={post.post.images} text={""} />
                ) : (
                  ""
                )}
                {post.post.images.length == 3 ? (
                  <ThreeImages images={post.post.images} text={""} />
                ) : (
                  ""
                )}
                {post.post.images.length == 4 ? (
                  <FourImages images={post.post.images} text={""} />
                ) : (
                  ""
                )}

                {post.post.images.length > 4 && (
                  // <SimpleImageSlider
                  //   style={{
                  //     backgroundSize: "auto",
                  //     backgroundPosition: "auto",
                  //   }}
                  //   width="100%"
                  //   height={500}
                  //   images={post.post.images}
                  //   showBullets={true}
                  //   showNavs={true}
                  // />
                  <Carousel>
                    {post.post.images.map((slideImage, index) => (
                      // <ImageComponent image={slideImage.url}/>
                      <img
                        src={slideImage.url}
                        key={index}
                        style={{ zIndex: -10 }}
                        alt=""
                      />
                    ))}
                  </Carousel>

                  // <Zoom scale={1.4} indicators={true} style={{zIndex: -10}}>
                  //   {post.post.images.map((slideImage, index) => (
                  //     // <div className="each-slide" key={index} style={{zIndex: -10}}>
                  //       <img src={slideImage.url} key={index} style={{zIndex: -10}} alt="" />

                  //     // </div>
                  //   ))}
                  // </Zoom>
                )}

                {/* <SingleImage image={post.post.image}/> */}
                {/* <div className="row ps-2 pe-2">
                    <div className="col-sm-12 p-1">
                      <img
                        src={post.post.image}
                        className="rounded-3 w-100 float-left"
                        alt="Post Image"
                        style={{
                          maxWidth: 800,
                          maxHeight: 450,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                </div> */}
              </div>
            ) : (
              ""
            )}

            <div className="card-body p-0 me-lg-5">
              <ShowMoreText
                /* Default options */
                lines={3}
                more="Read more"
                less="Show less"
                className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                // className="content-css"
                anchorClass="my-anchor-css-class"
                // onClick={executeOnClick}
                expanded={false}
                // width={480}
                truncatedEndingComponent={"... "}
              >
                {post.post.body}
              </ShowMoreText>
              {/* <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{post.post.body} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p> */}
              {/* COME BACK  */}

              {!post.post.alert_category ? (
                ""
              ) : (
                <Link to={`/alert-category/${post.post.alert_category.slug}`}>
                  {" "}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Alert Category:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.alert_category.name}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                  {/* <br/> */}
                </Link>
              )}

              {!post.post.caption ? (
                ""
              ) : (
                <>
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Alert Location:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.caption}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                </>
              )}

              {!post.post.channel ? (
                ""
              ) : (
                <Link to={`/channel/${post.post.channel.slug}`}>
                  {" "}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Channel Post:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.channel.name}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                  {/* <br/> */}
                </Link>
              )}

              {!post.post.page ? (
                ""
              ) : (
                <Link to={`/page/${post.post.page.slug}`}>
                  {" "}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Page Post:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.page.name}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                  {/* <br/> */}
                </Link>
              )}

              {!post.post.post_category ? (
                ""
              ) : (
                <Link
                  to={`/post-category/${post.post.user.user_name}/${post.post.post_category.slug}`}
                >
                  {/* {alert(post.post.user.user_name)} */}{" "}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Series:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.post_category.name}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                  {/* <br/> */}
                </Link>
              )}
              {/* {post.post.channel_id ? (
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  <br />
                  Type:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label="Channel Post"
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>
              ) : (
                ""
              )} */}

              {!post.post.url == "" ? (
                <a href={post.post.url} target="_blank">
                  {/* <Link to={`/post.post/${post.post.slug}`} state={{ id: post.post.id }}> */}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    URL: <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={post.post.url}
                    />
                  </h6>
                </a>
              ) : (
                ""
              )}

              {!post.post.tags.length == 0 ? (
                <>
                  {/* <br/> */}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    Hash Tags{" "}
                  </h6>
                  <div>
                    {post.post.tags.map((tag) => (
                      <>
                        <a href={`/tag/${tag.slug}/posts`}>
                          {/* <Link to={`/tag/${tag.slug}/posts`}> */}
                          <Chip
                            style={{ marginRight: 10, marginBottom: 5 }}
                            className="infotag pointer"
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={`# ${tag.name}`}
                          />
                          {/* </Link> */}
                        </a>
                      </>
                    ))}
                    <br /> <br />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>

            {!post.post.likers.length == 0 ? (
              <LikesProfilePic
                type="post"
                likers={post.likers}
                likes={post.likers}
                comments={post.post.post_comments_count}
                reposts={post.post.re_posts}
                allLikers={post.post.likers}
                color="#000"
                text="likes"
                likersType="post"
              />
            ) : (
              ""
            )}
            <br />

            {post.post.alert_category ? (
              <>
                <p className="py-3 space-x-4">
                  {" "}
                  {post.post.user.user_name} created this as an Alarrt.
                </p>{" "}
                {/* <br /> */}
                {/* {post.followingUser == "true" ? (
                  ""
                ) : (
                  <>
                    {userId[0] == post.post.user.id ? (
                      ""
                    ) : (
                      <div className="flex items-center justify-between py-3">
                        <div className="flex flex-1 items-center space-x-4">
                          {" "}
                          <br />
                          <p>
                            Follow @{post.post.user.user_name} to see past and
                            future post in your timeline{" "}
                          </p>{" "}
                          {following && currentPost == post.post.id ? (
                            <a
                              // className="ms-auto pointer"
                              disabled
                              // onClick={() => Follow(post.post.user.user_name)}
                              style={{ marginTop: -8 }}
                              className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                            >
                              {" "}
                              {followText}{" "}
                            </a>
                          ) : (
                            <a
                              // className="ms-auto pointer"
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                Follow(post.post.user.user_name);
                              }}
                              style={{ marginTop: -8 }}
                              className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                            >
                              {" "}
                              follow
                            </a>
                          )}
                        </div>
                        <br />
                        {followed && currentPost == post.post.id ? (
                          <Snackbar
                            open={followed}
                            autoHideDuration={3000}
                            onClose={closeSnack}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Alert onClose={closeSnack} severity="success">
                              Followed {post.post.user.user_name} Successfully
                            </Alert>
                          </Snackbar>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>
                )} */}
              </>
            ) : (
              ''
              )}
              <>
                <div className="card-body d-flex p-0">
                  <div
                    className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                    onClick={toggleActive}
                  >
                    {isLiking && currentPost == post.post.id ? (
                      <HourglassEmptyRoundedIcon style={{ color: "#2516c7" }} />
                    ) : (
                      <>
                        {post.likedStatus == "false" && (
                          <>
                            {/* <p>like it</p> */}
                            <FavoriteBorderIcon
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                likePost(post.post.id);
                                setIsLiked(true);
                              }}
                              style={{ fontSize: 25, color: "#2516c7" }}
                            />
                          </>
                        )}
                        {/* // ) : ( */}
                        {post.likedStatus == "true" && (
                          <>
                            {/* <p>unlike</p> */}
                            <FavoriteRoundedIcon
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                unLikePost(post.post.id);
                                setIsLiked(false);
                              }}
                              style={{ fontSize: 25, color: "#2516c7" }}
                            />
                          </>
                        )}

                        {/* {isLiked && currentPost == post.post.id && (
                          <>
                            <p>state is liked</p>
                            <FavoriteRoundedIcon
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                unLikePost(post.post.id);
                                setIsLiked(false);
                              }}
                              style={{ fontSize: 25, color: "#2516c7" }}
                            />
                          </>
                        )} */}

                        {/* {!isLiked && currentPost == post.post.id && (
                          <>
                            <p>state is unliked</p>
                            <FavoriteBorderIcon
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                likePost(post.post.id);
                                setIsLiked(true);
                              }}
                              style={{ fontSize: 25, color: "#2516c7" }}
                            />
                          </>
                        )} */}

                        {/* // <>
                          //   {post.likedStatus == "true" && isLiked == true ? (
                          //     <>
                          //       <p>state like</p>
                          //       <FavoriteRoundedIcon
                          //         onClick={() => {
                          //           setCurrentPost(post.post.id);
                          //           unLikePost(post.post.id);
                          //           setIsLiked(false);
                          //         }}
                          //         style={{ fontSize: 25, color: "#2516c7" }}
                          //       />
                          //     </>
                          //   ) : (
                          //     <>
                          //       {post.likedStatus == "true" && isLiked == false ? (
                          //         <>
                          //           <p>state unlike api like</p>
                          //           <FavoriteRoundedIcon
                          //             onClick={() => {
                          //               setCurrentPost(post.post.id);
                          //               likePost(post.post.id);
                          //               setIsLiked(true);
                          //             }}
                          //             style={{ fontSize: 25, color: "#2516c7" }}
                          //           />
                          //         </>
                          //       ) : (
                          //         <>
                          //           <p>state like api un</p>
                          //           {post.likedStatus == "false" &&
                          //           isLiked == true ? (
                          //             <FavoriteRoundedIcon
                          //               onClick={() => {
                          //                 setCurrentPost(post.post.id);
                          //                 unLikePost(post.post.id);
                          //                 setIsLiked(false);
                          //               }}
                          //               style={{ fontSize: 25, color: "#2516c7" }}
                          //             />
                          //           ) : (
                          //             <>
                          //               <p>neutral api unlike</p>
                          //               {post.likedStatus == "false" ? (
                          //                 <>
                          //                   <FavoriteBorderIcon
                          //                     onClick={() => {
                          //                       setCurrentPost(post.post.id);
                          //                       likePost(post.post.id);
                          //                       setIsLiked(true);
                          //                     }}
                          //                     style={{
                          //                       fontSize: 25,
                          //                       color: "#2516c7",
                          //                     }}
                          //                   />
                          //                 </>
                          //               ) : (
                          //                 <>
                          //                   <p>api liked</p>
                          //                   <FavoriteRoundedIcon
                          //                     onClick={() => {
                          //                       setCurrentPost(post.post.id);
                          //                       unLikePost(post.post.id);
                          //                       setIsLiked(false);
                          //                     }}
                          //                     style={{
                          //                       fontSize: 25,
                          //                       color: "#2516c7",
                          //                     }}
                          //                   />
                          //                 </>
                          //               )}
                          //             </>
                          //           )}
                          //         </>
                          //       )}
                          //     </>
                          //   )}
                          // </>


                      //   )} */}
                      </>
                    )}

                    <span style={{ paddingLeft: 5 }}>
                      {post.post.likers.length}{" "}
                      {/* <LikersModal
                        likers={post.post.likers}
                        color="#000"
                        text="likes"
                        type="post"
                        class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss"
                      /> */}
                    </span>
                    <span className="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss">
                    {reposting && currentPost == post.post.id ? (
                    <HourglassEmptyRoundedIcon
                      style={{ fontSize: 25, color: "#2516c7" }}
                    />
                  ) : (
                    <RepeatIcon
                      style={{
                        fontSize: 25,
                        color: "#2516c7",
                        marginLeft: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCurrentPost(post.post.id);
                        repost(post.post.post_origin);
                      }}
                    />
                  )}
                      {post.post.re_posts}
                      {/* re-posts */}
                      {/* View 0 Comments */}
                    </span>
                    <div style={{ marginLeft: 13 }}></div>

                    {authId[0] == post.post.user.id && (
                      <span className="d-flex align-items-center fw-800 text-grey-800 text-dark lh-26 font-xssss">
                        <RepeatOneIcon
                          style={{
                            fontSize: 25,
                            color: "#2516c7",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCurrentPost(post.post.id);
                            toggleThread();
                          }}
                        />{" "}
                      </span>
                    )}
                   
                    {/* <div className="pointer">
                  {" "}
                  <RepeatIcon /> 0 re-posts{" "}
                </div> */}
                    {/* <i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> <i className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i>2.8K Like */}
                  </div>
                 
                  <div style={{ marginLeft: 10 }}></div>
                  <a
                    // href="#"
                    className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
                  >
                    <i
                      style={{ color: "#2516c7", fontSize: 25 }}
                      className="feather-message-circle btn-round-sm "
                    ></i>
                    {isComment && currentPost == post.post.id ? (
                      <span
                        className="d-none-xss"
                        onClick={() => {
                          setCurrentPost(post.post.id);
                          toggleComment();
                        }}
                      >
                        {/* 283 Comments */}
                        Close {post.post.post_comments_count} comments
                        {/* View 0 Comments */}
                      </span>
                    ) : (
                      <span
                        className="d-none-xss"
                        onClick={() => {
                          setCurrentPost(post.post.id);
                          toggleComment();
                        }}
                      >
                        {/* 283 Comments */}
                        View {post.post.post_comments_count} comments
                        {/* View 0 Comments */}
                      </span>
                    )}
                  </a>
                  <div
                    className={`pointer ms-auto d-flex align-items-center fw-600 text-current lh-26 font-xssss`}
                  >
                    {/* < */}
                    <ShareButtonsPost post={post.post} />
                    {/* <ShareMovie /> */}
                  </div>
                </div>
                {/* <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li> */}

                {post.followingUser == "true" ? (
                  ""
                ) : (
                  <>
                    {userId[0] == post.post.user.id ? (
                      ""
                    ) : (
                      <div className="flex items-center justify-between py-3">
                        <div className="flex flex-1 items-center space-x-4">
                          {" "}
                          <br />
                          <p>
                            Follow @{post.post.user.user_name} to see past and
                            future post in your timeline{" "}
                          </p>{" "}
                          {following && currentPost == post.post.id ? (
                            <a
                              // className="ms-auto pointer"
                              disabled
                              // onClick={() => Follow(post.post.user.user_name)}
                              style={{ marginTop: -8 }}
                              className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                            >
                              {" "}
                              {followText}{" "}
                            </a>
                          ) : (
                            <a
                              // className="ms-auto pointer"
                              onClick={() => {
                                setCurrentPost(post.post.id);
                                Follow(post.post.user.user_name);
                              }}
                              style={{ marginTop: -8 }}
                              className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                            >
                              {" "}
                              follow
                            </a>
                          )}
                        </div>
                        <br />
                        {followed && currentPost == post.post.id ? (
                          <Snackbar
                            open={followed}
                            autoHideDuration={3000}
                            onClose={closeSnack}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Alert onClose={closeSnack} severity="success">
                              Followed {post.post.user.user_name} Successfully
                            </Alert>
                          </Snackbar>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </>
                )}

                {/* {post.post.reposted == "false" */}
                {isThread && (
                  <CreateThread
                    Id={post.post.id}
                    url={"post_thread"}
                    threads={post.post.threads}
                    functionProp={props.functionProp}
                    // openComment={openComment}
                    // favUrl={"post"}
                    authId={authId[0]}
                    userId={post.post.user.id}
                  />
                )}
                <AllThreads
                  Id={post.post.id}
                  url={"post_thread"}
                  user_name={post.post.user.user_name}
                  pic={post.post.user.profile_pic}
                  threads={post.post.threads}
                  functionProp={props.functionProp}
                  // openComment={openComment}
                  // favUrl={"post"}
                  authId={authId[0]}
                  userId={post.post.user.id}
                />
                <>
                  {post.post.can_comment == "true" ? (
                    <CreateComment
                      Id={post.post.id}
                      url={"posts/comment"}
                      favComments={post.favComments}
                      functionProp={props.functionProp}
                      openComment={openComment}
                      favUrl={"post"}
                      authId={authId[0]}
                      userId={post.post.user.id}
                    />
                  ) : (
                    ""
                  )}
                </>
              </>
           

            {/* {followed ? (
              // <>
                <Snackbar
                  open={followed}
                  autoHideDuration={3000}
                  onClose={closeSnack}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Alert onClose={closeSnack} severity="success">
                    Followed User Successfully
                  </Alert>
                </Snackbar>
              // </>
            ) : (
              ""
            )} */}

            {isComment && currentPost == post.post.id ? (
              <>
                <SingleComment
                  Id={post.post.id}
                  comments={post.comments}
                  favUrl={"post"}
                  deleteUrl="postComment"
                  url1={"posts"}
                  functionProp={props.functionProp}
                  authId={authId[0]}
                  userId={post.post.user.id}
                />
                <span
                  className="fw-600 text-grey-900 mt-2 pointer"
                  onClick={() => {
                    setCurrentPost(post.post.id);
                    toggleComment();
                  }}
                  // onClick={toggleComment}
                >
                  {/* 283 Comments */}
                  Close Comments
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        ))}

{reposted ? (
            <Snackbar
              open={reposted}
              autoHideDuration={2000}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose2} variant="filled" severity="success">
                Re posted successfully
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}

          {props.load == 'false' ?
          ''
        :

        <Button
          variant="contained"
          color="primary"
          style={{
            marginTop: 20,
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
          }}
          // {/* style={{ justifyContent: "center", alignItems: "center" }} */}
          onClick={() => {
            setIsGetting(true);
            props.functionPropNext();
            setTimeout(() => {
              setIsGetting(false);
            }, 5000);
            clearTimeout();
            // setIsGetting(false);
          }}
        >
          Load More
        </Button>
  }
        <br />
        <br />

        {isGetting && <Load message="loading more" />}
      </>
    );
  }
  // }
}

// export default Alerts;
