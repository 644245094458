import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import { format, formatDistance, subDays } from "date-fns";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import MovieGrid from "../moviesComponents/movie-grid/MovieGrid";
import Button from "../moviesComponents/button/Button";
import IconButton from "@mui/material/IconButton";

export default function UserMovies(props) {
  let history = useHistory();
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.movies.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello {props.user_name}! {props.message}
                
              </h5>
            </div>
          </div>
          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, marginBottom: 50 }}
        >
          <h4 className="text-center">
            {" "}
            You've {props.message2} {props.movies.length} movies{" "}
          </h4>
          {props.movies.map((movie, index) => (
            <div
              key={index}
              // className='movie-grid'
              className="col-md-6 col-xss-12 pe-2 ps-2"
              //   style={{paddingTop:180}}
            >
                 {movie.movie.type == 'movie' &&
                <>
                {window.sessionStorage.setItem("url", `/channel/${movie.movie.channel.slug}/movie/${movie.movie.slug}?channel=${movie.movie.channel.slug}&w=${movie.movie.id}&a=${movie.movie.channel.admin.id}`)}
                </>
                 }
                {movie.movie.type == 'video' &&
                <>
                  {window.sessionStorage.setItem("url", `/channel/${movie.movie.channel.slug}/video/${movie.movie.slug}?channel=${movie.movie.channel.slug}&w=${movie.movie.id}&a=${movie.movie.channel.admin.id}`)}
                </>
              }
                {movie.movie.type == 'episode' &&
                <>
                  {window.sessionStorage.setItem("url", `/channel/${movie.movie.channel.slug}/series/episode/${movie.movie.slug}?channel=${movie.movie.channel.slug}&w=${movie.movie.id}&a=${movie.movie.channel.admin.id}`)}
                </>
              }
                <Link  
                to={{
                    pathname: window.sessionStorage.getItem("url"),
                  }}>
              <div
                className="movie-card overflow-hidden shadow-xss bg-gradiant-bottom"
                style={{
                  backgroundImage: `url("${ movie.movie.image}")`,
                }}
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                  <Link
                    to={{
                      pathname: `/channel/${ movie.movie.channel.slug}`,
                    }}
                  >
                    <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                      <img
                        src={ movie.movie.channel.channel_pic}
                        alt="avater"
                        className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                      />
                    </figure>
                  </Link>
                  <div className="clearfix"></div>
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
                    { movie.movie.title}
                  </h4>

                  <a
                    onClick={() => {
                      alert("clicked");
                      history.go({
                        pathname: `channel/${ movie.movie.channel.slug}`,
                      });
                    }}
                  >
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={ movie.movie.channel.name}
                      //   variant="outlined"
                      // clickable
                      // onClick={goToChannel(movie.movie.channel.slug)}
                    />
                  </a>
                  {/* {movie.movie.movie_id == null ? (
                      ""
                    ) : ( */}
                  <div
                    className="fw-500 text-white font-xssss mt-1 pointer"
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      // marginTop: -20
                    }}
                  >
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>
                  </div>
                  {/* )} */}
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                    {/* {movie.movie.title} */}
                    Type: { movie.movie.type} - Pricing: { movie.movie.pricing}{" "}
                    {movie.movie.price}
                  </h4>
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                    Purchased:{" "}
                    {formatDistance(new Date(movie.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </h4>
                </div>
              </div>
              </Link>
            </div>
         
            //   </div>
          ))}
         </div>

          <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, }}
        >
          <h4 className="text-center">
            {" "}
            You've {props.message2} {props.series.length} series{" "}
          </h4>
          {props.series.map((series, index) => (
            <div
              key={index}
              // className='movie-grid'
              className="col-md-6 col-xss-12 pe-2 ps-2"
              //   style={{paddingTop:180}}
            >
                 <Link
                  to={{
                    pathname: `/channel/${series.series.channel.slug}/series/${series.series.slug}?channel=${series.series.channel.slug}&w=${series.series.id}&a=${series.series.channel.admin.id}`,
                   
                  }}
                >
              <div
                className="movie-card overflow-hidden shadow-xss bg-gradiant-bottom"
                style={{
                  backgroundImage: `url("${series.series.image}")`,
                }}
              >
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                  <Link
                    to={{
                      pathname: `/channel/${series.series.channel.slug}`,
                    }}
                  >
                    <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                      <img
                        src={series.series.channel.channel_pic}
                        alt="avater"
                        className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                      />
                    </figure>
                  </Link>
                  <div className="clearfix"></div>
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
                    {series.series.title}
                  </h4>

                  <a
                    onClick={() => {
                      // alert("clicked");
                      history.go({
                        pathname: `channel/${series.series.channel.slug}`,
                      });
                    }}
                  >
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={series.series.channel.name}
                      //   variant="outlined"
                      // clickable
                      // onClick={goToChannel(series.series.channel.slug)}
                    />
                  </a>
                  {/* {series.series.movie_id == null ? (
                      ""
                    ) : ( */}
                  <div
                    className="fw-500 text-white font-xssss mt-1 pointer"
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      justifyContent: "center",
                      // marginTop: -20
                    }}
                  >
                    <Link
                      to={{
                        pathname: `/channel/${series.series.channel.slug}`,
                      }}
                    >
                      {" "}
                      {/* Episode Of{" "} */}
                      <Chip
                        className="pointer infotag"
                        color="#fff"
                        size="small"
                        label={`Series: ${series.series.title}`}
                        //   variant="outlined"
                        clickable
                        onClick={() => {
                          history.push({
                            pathname: `/channel/${series.series.channel.slug}/series/${series.series.slug}?channel=${series.series.channel.slug}&w=${series.series.id}&a=${series.series.channel.admin.id}`,
                          });
                        }}
                      />
                    </Link>

                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>
                  </div>
                  {/* )} */}
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                    {/* {movie.title} */}
                    Type: {series.series.type} - Pricing: {series.series.pricing}{" "}
                    {series.series.price}
                  </h4>
                  <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                    Purchased:{" "}
                    {formatDistance(new Date(series.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </h4>
                </div>
              </div>
              </Link>
            </div>
            //   </div>
          ))}
         </div>

          <br />

       
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Channels;
