import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import axiosFetch from '../Interceptors2';
// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

export default function PostSeriesEdit(props) {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const userName = window.localStorage.getItem("user_name");

  const [updated, setUpdated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [newSeries, setNewSeries] = useState(props.cat);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUpdated(false);
    setError(false);
  };

  async function updateSeries() {
    setUpdating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("name", newSeries);

    axiosFetch
      .post(
        `/post_category/update/${props.id}`,
        formData,
        {
          // method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if(resp.data.status == 'error'){
            setUpdating(false);
            setError(true);
            setErrorMessage(resp.data.message);
        }else{
        console.log(resp.data);
        setUpdated(true);
        props.functionProps();
        // setMessage("");
        setUpdating(false);
        // setSms("");
        // setOpen(false);
        }
      })
      .catch((error) => {
        // if(error.response.status == 403){
          setUpdating(false);
        setError(true);
        setErrorMessage(error.message);
        console.log(error);
        // }
        
      });
  }


  useEffect(() => {
    // getUserPoints();
  }, []);

  return (
    <div>
      <Tooltip title="Update Your Series">
      <ModeEditOutlineIcon 
       onClick={handleClickOpen} 
                  style={{ color: "#2516c7", fontSize: 30 }}
                  />
          {/* <li className="list-inline-item d-block  me-0"><Link onClick={handleClickOpen} className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Send Random Sms</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
      </Tooltip>
      <br />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Your Series</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hello {userName}! Your series helps you organize your posts. It enables friends view all posts that belongs to your series with the click of a button. You can always create more series using the create post modal on your timeline or profile
          </DialogContentText>
          <br />
          
           <TextField
            // autoFocus
            // margin="dense"
            // id="name"
            label="Update To New"
            type="text"
            fullWidth
            defaultValue={props.cat}
            // value={newSeries}
            onChange={(e) => setNewSeries(e.target.value)}
            variant="standard"
          /><br/><br/>
          
         
        </DialogContent>
        <br />
        <br />
        <br />

        {updated ? (
          <Alert onClose={handleClose} variant="filled" severity="success">
            Your series was updated successfully {userName}
          </Alert>
        ) : (
          ""
        )}

        {error ? (
          <Alert onClose={handleClose} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        ) : (
          ""
        )}
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
          {updating ? (
            <Button disabled>Updating</Button>
          ) : (
            <Button onClick={updateSeries}>Update Series</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
