import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import Profiledetail from "../components/Profiledetail";
import Profilephoto from "../components/Profilephoto";
import Events from "../components/Events";
import ProfilecardTwo from "../components/ProfilecardTwo";
import Category from "../components/CategoryAlerts";

import Createpost from "../components/Createpost";
import PostSingle from "../components/PostSingle";
import Load from "../components/Load";
import { Route, useParams, useLocation } from "react-router-dom";
import CreateAlertFab from "../components/CreateAlertFab";

export default function SinglePost() {

  const location = useLocation();
  const { u_id } = useParams(["u_id"]);
  const { id } = useParams(["id"]);

  // alert(u_id)

  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
        {/* {id} */}
                <PostSingle postId={id}/>
               
              </div>
           
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      <Appfooter />
    </Fragment>
  );
}

// export default Authorpage;
