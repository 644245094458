import React, {
  Component,
  Fragment,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
// import { FaLocationArrow, FaTimes } from 'react-icons/fa'
// feather-send feather-c-circle
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  Circle,
  DirectionsRenderer,
} from "@react-google-maps/api";
// import { useRef, useState } from 'react'



const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
};
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D",
};
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF0000",
  fillColor: "#FF0000",
};

const svgMarker = {
  path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
  fillColor: "blue",
  fillOpacity: 0.6,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
  // anchor: window.google.maps.Point(15, 30),
};

function AlertLocationCurrent(props) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const adminLat = parseFloat(window.sessionStorage.getItem("wat_lat"));
  const adminLng = parseFloat(window.sessionStorage.getItem("wat_lon"));
  const adminCords = {
    lat: parseFloat(window.sessionStorage.getItem("wat_lat")),
    lng: parseFloat(window.sessionStorage.getItem("wat_lon")),
  };
  const currentCords = {
    lat: parseFloat(props.currentLat),
    lng: parseFloat(props.currentLong),
  };
  const startCords = {
    lat: parseFloat(props.startLat),
    lng: parseFloat(props.startLong),
  };

  const center = { lat: adminLat, lng: adminLng };

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

 

  if (!isLoaded) {
    return <p>Loading Map...</p>;
    // <SkeletonText />
  }

  async function calculateRouteAdmin() {
    // if (currentCords === "" || adminCords === "") {
    //   return;
    // }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: adminCords,
      destination: currentCords,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    alert('this is where the user currently is')
  }
  
 async function calculateRouteStart() {
    // if (currentCords === "" || adminCords === "") {
    //   return;
    // }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: adminCords,
      destination: startCords,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
    alert('this is where the user sent the alert')
  }
  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    });
    console.log(originRef.current.value)
    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destiantionRef.current.value = "";
  }

  // useEffect(() => {
  //   console.log('mmmm');
  // }, [console.log(`hiii origin ${originRef.current.value}`)]);

  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignItems="center"
      h="100vh"
      w="100vw"
    >
      <Box position="absolute" left={0} top={0} h="100%" w="100%">
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: "90%", height: "80%" }}
          options={{
            mapId: "a4e2da29cdf9cd48",
            zoomControl: true,
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
            scaleControl : true,
            panControl : true,
            rotateControl : true
          }}
          onLoad={(map) => setMap(map)}
        >
          <Marker
            position={center}
            onClick={calculateRouteAdmin}
            label="You"
            // onClick={() => {
            //    calculateRouteAdmin();
            //   }} , 
          />

          <Marker
            position={startCords}
            onClick={calculateRouteStart}
            // icon = {svgMarker}
            label="B"
            // icon={{
            //   url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
            //   anchor: window.google.maps.Point(12, 12),
            //   scaledSize: window.google.maps.Size(12, 12),
            //   // scaledSize: new google.maps.Size(64,64)
            // }}
          />

          <Marker
            position={currentCords}
            onClick={calculateRouteAdmin}
            label="C"
            // icon={{
            //   url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
            //   anchor: window.google.maps.Point(12, 12),
            //   scaledSize: window.google.maps.Size(12, 12),
            //   // scaledSize: new google.maps.Size(64,64)
            // }}
          />

          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}

          <Circle
            radius={5000}
            center={adminCords}
            onMouseover={() => console.log("mouse over on circle")}
            onClick={() => console.log("click circle")}
            onMouseout={() => console.log("mouseout from circle")}
            strokeColor="transparent"
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#FBC02D"
            strokeColor= "#8BC34A"
            fillOpacity={0.05}
            zIndex={1}
          />

          <Circle center={adminCords} radius={10000} options={closeOptions} />
          <Circle center={adminCords} radius={30000} options={middleOptions} />
          <Circle center={adminCords} radius={50000} options={farOptions} />
        </GoogleMap>
      </Box>
      <Box
        p={4}
        borderRadius="lg"
        m={4}
        bgColor="white"
        shadow="base"
        minW="container.md"
        zIndex="1"
      ><p>Search for close landmarks for additional directions here</p>
        <HStack spacing={2} justifyContent="space-between">
          
          <Box flexGrow={1}>
            <Autocomplete>
              <Input type="text" placeholder="Origin" ref={originRef} style={{backgroundColor:'#000080'}} />
            </Autocomplete>
          </Box>
          <Box flexGrow={1}>
            <Autocomplete>
              <Input
                type="text"
                placeholder="Destination"
                ref={destiantionRef}
                style={{backgroundColor:'#000080'}}
              />
            </Autocomplete>
          </Box>

          <ButtonGroup>
            <Button colorScheme="pink" type="submit" onClick={calculateRoute}>
              Calculate Route
            </Button>
            <IconButton
              aria-label="center back"
              icon={<CloseIcon />}
              onClick={clearRoute}
            />
          </ButtonGroup>
        </HStack>
        <HStack spacing={4} mt={4} justifyContent="space-between">
          <Text>Distance: {distance} </Text>
          <Text>Duration: {duration} </Text>
          <IconButton
            aria-label="center back"
            icon={<SendIcon />}
            isRound
            onClick={() => {
              map.panTo(center);
              map.setZoom(15);
            }}
          />
        </HStack>
      </Box>
    </Flex>
  );
}

export default AlertLocationCurrent;
