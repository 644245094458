import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "./Load";
import ShowMoreText from "react-show-more-text";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import MuiAlert from "@material-ui/lab/Alert";
import PostSeriesEdit from "./PostSeriesEdit";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";

export default function Profiledetail(props) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const userName = window.localStorage.getItem("user_name");
  const [showSocials, setShowSocials] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [copied, setCopied] = useState(false);
  function closeSnack() {
    setCopied(false);
  }

  function executeOnClick(isExpanded) {
    // console.log(isExpanded);
  }
  function toggleSocials() {
    showSocials == false ? setShowSocials(true) : setShowSocials(false);
  }

  const handleClose = () => {
    // setOpen(false);
    setConfirmDelete(false);
    // setEmptyFields(false);
  };
  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
      setDeleteId("");
    } else {
      setConfirmDelete(true);
      setDeleteId(id);
    }
  }

  async function removeCat(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .delete(
        `/post_category/delete/${id}`,
        // id,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setIsDeleting(false);
        setConfirmDelete(false);
        props.functionProp();
        //   props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  // render() {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">Your Bio</h4>
        {/* <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{props.profile.bio}</p> */}
        <ShowMoreText
          /* Default options */
          lines={3}
          more="Read more"
          less="Show less"
          className="fw-500 text-black-900 lh-26 font-xssss w-100 mb-2"
          // className="content-css"
          anchorClass="my-anchor-css-class"
          onClick={executeOnClick}
          expanded={false}
          // width={480}
          truncatedEndingComponent={"... "}
        >
          {props.profile.bio}
        </ShowMoreText>
      </div>

      <div className="card-body d-flex pt-0">
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          Social Networks
        </h4>
      </div>

      <div className="card-body border-top-xs d-flex">
        <i className="feather-zap text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          <span style={{ paddingRight: 20 }}> Alarrt </span>
          <CopyToClipboard
            text={`https://app.alarrt.com/profile/${props.user_name}`}
            onCopy={() => setCopied(true)}
          >
            <button>Copy Here</button>
          </CopyToClipboard>
          {/* <a href={`https://alarrt.com/profile/${userName}`} target='_blank'> */}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            https://app.alarrt.com/profile/{props.user_name}
          </span>
          {/* </a> */}
        </h4>
      </div>

      {props.socials == null ? (
        ""
      ) : (
        <>
          {/* // ""
      )} */}
          {props.socials.instagram ? (
            <div className="card-body border-top-xs d-flex">
              <i className="feather-instagram text-grey-500 me-3 font-lg"></i>
              <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                <span style={{ paddingRight: 20 }}> Instagram </span>
                <CopyToClipboard
                  text={props.socials.instagram}
                  onCopy={() => setCopied(true)}
                >
                  <button>Copy Here</button>
                </CopyToClipboard>

                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {props.socials.instagram}
                </span>
              </h4>
            </div>
          ) : (
            ""
          )}
          <Button onClick={toggleSocials}>Toogle Socials</Button>

          {showSocials ? (
            <>
              {props.socials.linkedIn ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-linkedin text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> LinkedIn </span>
                    <CopyToClipboard
                      text={props.socials.linkedIn}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.linkedIn}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}
              {props.socials.youtube ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-youtube text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Youtube </span>
                    <CopyToClipboard
                      text={props.socials.youtube}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.youtube}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}
              {props.socials.facebook ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-facebook text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Facebook </span>
                    <CopyToClipboard
                      text={props.socials.facebook}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.facebook}
                    </span>
                  </h4>
                </div>
              ) : (
                ""
              )}

              {props.socials.whatsapp ? (
                <div className="card-body border-top-xs d-flex">
                  <i className="feather-message-circle text-grey-500 me-3 font-lg"></i>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-0">
                    <span style={{ paddingRight: 20 }}> Whatsapp </span>{" "}
                    <CopyToClipboard
                      text={props.socials.whatsapp}
                      onCopy={() => setCopied(true)}
                    >
                      <button>Copy Here</button>
                    </CopyToClipboard>
                    {/* <a href={props.socials.instagram} target='_blank'> */}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {props.socials.whatsapp}
                    </span>
                    {/* </a> */}
                  </h4>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}

      {copied ? (
        <>
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Copied Successfully
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {/* <div className="card-body d-flex pt-0">
        <i className="feather-eye text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Visible{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Anyone can find you
          </span>
        </h4>
      </div> */}
      {props.isLoggedInUser ? (
        <div className="card-body d-flex pt-0">
          <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            Location:
            {!props.profile.state && props.profile.country === null ? (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                Update Your Location In Your Profile Settings
              </span>
            ) : (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {props.profile.state}, {props.profile.country}
              </span>
            )}
          </h4>
        </div>
      ) : (
        <div className="card-body d-flex pt-0">
          <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">
            Location:
            {!props.profile.state && props.profile.country === null ? (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                Location Not Set Yet
              </span>
            ) : (
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                {props.profile.state}, {props.profile.country}
              </span>
            )}
          </h4>
        </div>
      )}

      <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
        {props.cats.length == 0 ? (
          ""
        ) : (
          <>
            <br />
            <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:border-gray-800">
              <h2 className="font-semibold text-lg">Your Post Categories</h2>
            </div>
            <br />
            {props.cats.map((cat) => (
              <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100">
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    {/* <Link to={`/profile/${cat.cat_name}`}> */}
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold">
                        {" "}
                        {cat.name}
                      </span>
                      <span className="block capitalize text-sm">
                        {" "}
                        {cat.name}{" "}
                      </span>
                    </div>
                    {/* </Link> */}
                  </div>
                  {/* <ModeEditOutlineIcon 
                  style={{ color: "#2516c7", fontSize: 30 }}
                  /> */}
                  <PostSeriesEdit
                    id={cat.id}
                    cat={cat.name}
                    functionProps={props.functionProp}
                  />
                  <DeleteForeverOutlinedIcon
                    onClick={() => confirmDeletion(cat.id)}
                    style={{ color: "#2516c7", fontSize: 30 }}
                  />{" "}
                  {/* <a
                onClick={() => removeCat(cat.id)}
                // onClick={toogleFollow(user.user_name)}
                className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-pink-600 hover:text-white hover:border-pink-600 dark:border-gray-800"
              >
                {" "}
                DELETE{" "}
              </a> */}
                </div>
              </div>
            ))}
          </>
        )}

        {isDeleting == true ? <p> Removing Category</p> : ""}

        {confirmDelete ? (
          <MuiAlert
            onClose={handleClose}
            severity="error"
            action={
              <>
                <a
                  onClick={() => removeCat(deleteId)}
                  // onClick={deleteVideo(movie.id)}
                  // onClick={deleteVideo}
                  //  style={{ marginLeft: 80 }}
                  className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                >
                  Yes, delete it.
                </a>
                <a
                  onClick={() => setConfirmDelete(false)}
                  className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                >
                  Cancel
                </a>
              </>
            }
          >
            Are you sure ? This will remove all posts with this category
          </MuiAlert>
        ) : (
          ""
        )}
      </div>
    </div>
  );
  // }
}

// export default Profiledetail;
