import React,{Component} from 'react';
import Slider from "react-slick";
import "./story.css";
import axios from 'axios';
import axiosFetch from '../Interceptors2';
import {Button} from "@material-ui/core";

const storyList = [
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Aliqa Macale ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Seary Victor ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'John Steere ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Mohannad ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Studio ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Hendrix ',
        email: 'support@gmail.com',
    },
    {   
        bgUrl: 'story.png',
        imageUrl: 'user.png',
        name: 'Zitoun ',
        email: 'support@gmail.com',
    },
]

class Storyslider extends Component {
    state = {
        stories: [],
        fetchingAlerts: false,
        storyOpen: false,
        storyPost: '',
        emptyPost: false
        // isLoggedIn: false,
    };

    getAllStories = async () => {
        const access_token = await window.localStorage.getItem('access_token')
        axiosFetch.get(`/posts/stories`, {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + access_token,

        },
        }).then(resp => {
           
            this.setState({stories: resp.data})
             console.log(JSON.stringify(resp.data))
             console.log(this.state.stories.length)
            console.log(`stories list ${this.state.stories}`);
        })
        .catch(error => alert(`Error Seen : ${error}`));
        // }
    }

    toggleAction = () => this.setState({ storyOpen: !this.state.storyOpen });
    openStory = async (e) => {
        if(this.state.storyOpen == false) {
            console.log('hiiii')
            this.setState({storyOpen: true})
            return;
        }
       
    }

    validate = async (e) => {
    //    alert(this.state.body)
       if(this.state.storyPost == '') {
         
            this.setState({emptyPost: true});
            return;
       }
       else{
           this.createStory()
       }

    }

    createStory = async () => {
    // this.setState({creatingPost: true})
    const access_token = await window.localStorage.getItem('access_token')
    const userID = await window.localStorage.getItem('user_id')
    const userUN = await window.localStorage.getItem('user_name')

    const formData = new FormData();
    formData.append('user_id', userID);
    formData.append('body', 'story');
    formData.append('image', this.state.storyPost);
    formData.append('type', 'story');
    formData.append('status', 'active');

    let result = await axiosFetch.post('/posts', formData, {
      headers: {
       'Accept': '*/*',
        // 'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      }
    })
    .then(resp => {
        // uploadImage();
        console.log(resp.data)
        // console.log(this.state.body, this.state.headline, this.state.image);
        this.setState({storyPost: ''})
        this.setState({storyOpen: false})
        this.getAllStories()
        // this.setState({image: ''})
        // this.setState({creatingPost: false})

    })
    .catch(error =>  alert(`Error Seen : ${error}`));
   
  }

    componentDidMount() {
        this.getAllStories();
    }

    render() {
        const storysettings = {
            arrows: true,
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: false,
            variableWidth: true
        };
        return (
            <>
            <Slider {...storysettings}>
                <div data-bs-toggle="modal" data-bs-target="#Modalstory" className="card w125 h200 d-block border-0 shadow-none rounded-xxxl bg-dark overflow-hidden mb-3 mt-0 me-3 pr-1" style={{marginRight: 20}}>
                    <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center">
                        <span onClick={this.toggleAction}  className="btn-round-lg bg-white"><i className="feather-plus font-lg"></i></span>
                        <div className="clearfix mt-1"></div>
                        <a onClick={this.openStory} ><h4 className="fw-700 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">Add Story </h4> </a>             
                    </div>
                </div>
                {/* {storyList.map((value , index) => ( */}
                 {this.state.stories.map((story , id) => ( 
                <div key={story.id}>
                    <div className="storyImg card w125 h200 d-block border-0 shadow-xss rounded-xxxl bg-gradiant-bottom overflow-hidden cursor-pointer mb-3 mt-0 me-3" style={{ backgroundImage: `url(${story.image})`}}>
                        <div className="card-body d-block p-3 w-100 position-absolute bottom-0 text-center" >
                            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w50 z-index-1"><img src={story.image} alt="avater" className="float-right p-0 bg-white rounded-circle w-100 shadow-xss" /></figure>
                            <div className="clearfix mt-1"></div>
                            <h4 className="fw-600 position-relative z-index-1 ls-1 font-xssss text-white mt-2 mb-1">{story.user.user_name} </h4>
                        </div>
                    </div>
                </div>
                ))}
              
            </Slider>
            
            {
                this.state.storyOpen ?
                <> 
                    <br/>
                    <h1> Upload Your Story Here </h1>
                    <div className="col-lg-12 mb-3">
                        <div className="card mt-3 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                <div className="form-group mb-0 w-100">
                                    <input type="file" name="file" id="file" className="input-file" onChange={e => this.setState({ storyPost: e.target.files[0] })} />
                                    <label htmlFor="file" className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed">
                                    <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                    <span className="js-fileName">Drag and drop or click to add your media file</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <input type="file" onChange={e => this.setState({ storyPost: e.target.files[0] })}/> */}
                    {/* <img src={URL.createObjectURL(this.state.image)} style={{ maxWidth: "100%", maxHeight: 320,}} alt="post image" /> */}
                    {
                        this.state.storyPost ?
                         <>
                            <img src={URL.createObjectURL(this.state.storyPost)} style={{ maxWidth: "100%", maxHeight: 320,}} alt="post image" />

                            <Button
                            // disabled
                            variant="contained"
                            color="primary"
                            onClick={this.createStory}
                            >
                            Create Your Story
                            </Button>
                        </>
                        :
                        ''
                        
                    }
                </>
                :
                ''
            }
            </>
        );
    }
}

export default Storyslider;