import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import delayAdapterEnhancer from "axios-delay";
import "./movie-list.scss";
import Slider from "react-slick";
import { SwiperSlide, Swiper } from "swiper/react";
import { Link } from "react-router-dom";

import Button from "../button/Button";

import tmdbApi, { category } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";

import MovieCard from "../movie-card/MovieCard";
import MovieCard2 from "../movie-card/MovieCard2";

export default function MovieList(props) {
  const [content, setContent] = useState([]);
  const id = props.channelId;
  const movieType = props.movieType;
  const hotelsettings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 600,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: false,
    spaceBetween: 10,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const api = axios.create({
    adapter: delayAdapterEnhancer(axios.defaults.adapter),
  });

  async function getMovies() {
    const id = props.channelId;
    const link = props.link;
    // console.log(`hii ${id} ${link}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/${link}`, {
        method: "GET",
        // delay: 500000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data)
        setContent(resp.data);
      })
      .catch((error) => alert(`Error Seen : ${error}`));
  }
  // async function getMovies() {
  //   const access_token = await window.localStorage.getItem("access_token");
  //     axios
  //       .get(
  //         `https://alert-lumen-api.herokuapp.com/api/channels/${props.channelId}/${props.movieType}s`,
  //         // `https://api.themoviedb.org/3/movie/popular?api_key=42f731815804618872f9aeab9511cc98`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             Authorization: "Bearer " + access_token,
  //           },
  //         }
  //       )
  //       .then((resp) => {

  //         setContent(resp.data);
  //       })
  //       .catch((error) => console.log(`Error Seen : ${error}`));
  //   }

  useEffect(() => {
    getMovies();
  }, []);

  if(content.length == 0) {
    return ''
  }
else{
  return (
    <div className="movie-list">
      <br/><br/>
      <div className="section__header mb-2 text-center">
        <h2 className='text-center' style={{ color: "#fff" }}>{props.heading}</h2>
      </div>
      <br/>
      {/* <h2>{props.movieType} {id}/{movieType}</h2> */}
      <Slider {...hotelsettings}>
        {content.map((c, id) => (
          <MovieCard
            movies={c}
            id={id}
            route={props.route}
            type={props.type}
            channelId={props.id}
            type={props.type}
            url={props.url}
            channelSlug={props.channelSlug}
            isAdmin={props.isAdmin}
            adminId={props.adminId}
            functionProp={props.functionProp}
            style={{ marginRight: 30 }}
            // functionProp={() => props.functionProp()}
          />
        ))}
      </Slider>
      {/* <Swiper
                grabCursor={true}
                spaceBetween={10}
                slidesPerView={'auto'}
            > */}
      {/* {
                    content.map((item, i) => (
                        <SwiperSlide key={i}>
                
                            <MovieCard2 movies={item} id={i} />
                        </SwiperSlide>
                    ))
                } */}
      {/* </Swiper> */}
    </div>
  );
}
}

// MovieList.propTypes = {
//     category: PropTypes.string.isRequired,
//     type: PropTypes.string.isRequired
// }

// export default MovieList;
