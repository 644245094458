import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import axiosFetch from "../Interceptors2";
import Chip from "@material-ui/core/Chip";
import { Snackbar } from "@material-ui/core";
import { format, formatDistance, subDays } from "date-fns";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import RepeatOneIcon from "@mui/icons-material/RepeatOne";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import CreateComment from "./comment/CreateComment";
import SingleComment from "./comment/SingleComment";
import AllThreads from "./comment/AllThreads";
import CreateThread from "./comment/CreateThread";
import Load2 from "./Load2";

import SingleImage from "./SingleImage";
import TwoImages from "./TwoImages";
import ThreeImages from "./ThreeImages";
import FourImages from "./FourImages";
import ShareButtonsPost from "./ShareButtonsPost";
import Test4 from "./Test4";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import LikesProfilePic from "./LikesProfilePic";
// import SinglePostComponent from "./SinglePostComponents";
import { Player } from "video-react";
// import { Player } from "video-react";
import AlertUpdateModal from "./AlertUpdateModal";
import PostUpdateModal from "./PostUpdateModal";
import BoltIcon from "@mui/icons-material/Bolt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LikersModal from "./LikersModal";

import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

import Dropdown from "react-bootstrap/Dropdown";
// import DropdownButton from "react-bootstrap/DropdownButton";
import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import IconButton from "@mui/material/IconButton";
import style from "./style.js";
import ReportModal from "./ReportModal";
import ImageComponent from "./ImageComponent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import PostDropUserAuth from "./PostDropUserAuth";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//
const actions = [
  { icon: <FileCopyIcon />, name: "Copy" },
  { icon: <SaveIcon />, name: "Save" },
  { icon: <PrintIcon />, name: "Print" },
  { icon: <ShareIcon />, name: "Share" },
];

export default function SinglePostComponent(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isThread, setIsThread] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [singlePost, setSinglePost] = useState([]);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const authId = useState(window.localStorage.getItem("user_id"));
  const [reposting, setReposting] = useState(false);
  const [reposted, setReposted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [enabling, setEnabling] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followed, setFollowed] = useState(false);
  const [followText, setFollowText] = useState("follow");
  const [hover, setHover] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const linkStyle = {};
  const [showMenu, setShowMenu] = useState(false);
  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    width: "80%",
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };

  function closeSnack() {
    setFollowed(false);
  }

  // const toggleHover = () => setHover(!hover);
  function toogleHover() {
    if (hover) {
      linkStyle = { backgroundColor: "red" };
    } else {
      linkStyle = { backgroundColor: "blue" };
    }
  }

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function openComment() {
    setIsComment(true);
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function likePost(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/posts/like/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        console.log(`hiii ${resp.data}`);
        setIsLiking(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleAction = () => setOpenAction(!openAction);
  const toggleComment = () => setIsComment(!isComment);
  const toggleThread = () => setIsThread(!isThread);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (post) => {
    console.log("opened");
    setOpen(true);
    setSinglePost(props.post);
  };

  const handleClose2 = () => {
    setOpen(false);
    setReposted(false);
    setSaved(false);
    setConfirmDelete(false);
  };

  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }

  async function deletePost(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/posts/delete/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        props.functionProp();
        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function savePost(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disableComment(id) {
    setDisabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/disablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setDisabling(false);
        setDisabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function enableComment(id) {
    setEnabling(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/enablecomment/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setEnabling(false);
        setEnabled(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function repost(id) {
    setReposting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/posts/repost/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        // const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setReposting(false);
        setReposted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function Follow(user_name) {
    setFollowing(true);
    setFollowText("following...");
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setFollowText("Followed!");
        props.functionProp();
        setFollowed(true);
        setFollowing(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }

  let menuRef = useRef();

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);

    // getAlert();
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("contextmenu", handleContextMenu);
    };
    // return () => {
    //   document.removeEventListener("contextmenu", handleContextMenu)
    // }
  }, []);

  const menuClass = `${isOpen ? " show" : ""}`;
  const actionClass = `${openAction ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  //   const { isLiked } = this.state;
  const commentOpen = isComment;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingAlerts == true) {
    // console.log(`final ${fetchingAlerts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load2 />
      </>
    );
  }
  if (props.post.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              <Load2 />
              {/* Hello {userName}! No posts can be displayed yet. Create one or
              follow people to populate your timeline. */}
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <>
        {/* {console.log(JSON.stringify(props.posts))} */}
        {/* <h4 className="text-center">{props.type} Posts </h4>  */}
        {/* {props.posts.map((props.post) => ( */}
        <div
          key={props.post.post.id}
          className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
        >
          {/* <p> Post type is {props.type}</p> */}
          {/* {props.post.post.owner == null ? ( */}
          {props.post.post.reposted == "false" ? (
            <div className="card-body p-0 d-flex">
              {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
              <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                <ImageComponent
                  image={props.post.post.user.profile_pic}
                  class="shadow-sm me-3 rounded-circle w45"
                  style={{ border: "2px solid #045de9" }}
                />
                {/* <img
                    onError={fixImage}
                    src={props.post.post.user.profile_pic}
                    alt="avater"
                    className="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  /> */}
              </figure>
              {/* </div> */}
              <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                {" "}
                {props.post.post.user.first_name}{" "}
                {props.post.post.user.last_name}
                <Link to={`/profile/${props.post.post.user.user_name}`}>
                  {" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                    @{props.post.post.user.user_name}
                  </span>{" "}
                </Link>
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {formatDistance(
                    new Date(props.post.post.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                  {/* {alert.created_at} */}
                </span>
              </h4>
              {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
              {/* <Box
                        sx={{
                          // height: 320,
                          transform: "translateZ(0px)",
                          flexGrow: 1,
                        }}
                      >
                        <SpeedDial
                          ariaLabel="SpeedDial basic example"
                          sx={{ position: "absolute", top: 16, right: 16 }}
                          direction='down'
                          icon={<MoreVertIcon />}
                        >
                      
                            <SpeedDialAction
                              key='Edit Post'
                              icon={<PostUpdateModal
                                title="Edit Post"
                                content={props.post.post}
                                type="post"
                                postId={props.post.post.id}
                                functionProp={() => props.functionProp()}
                              />}
                              tooltipTitle='Edit Post'
                            />
                             <SpeedDialAction
                              key='Repost'
                              icon={<a
                                onClick={() => repost(props.post.post.post_origin)}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-refresh-ccw mr-1"></i> 
                              </a>}
                              tooltipTitle='Repost'
                            />
                            {props.post.post.can_comment == "true" ? (
                             <SpeedDialAction
                              key='Disable Comments'
                              icon={<a
                                onClick={() => disableComment(props.post.post.id)}
                                //  onClick={disableComment}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-comment-slash mr-1"></i>{" "}
                                
                              </a>}
                              tooltipTitle='Disable Comments'
                            />
                            ) : (
                             <SpeedDialAction
                              key='Enable Comments'
                              icon={<a
                                onClick={() => enableComment(props.post.post.id)}
                                //  onClick={disableComment}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-comment-slash mr-1"></i>{" "}
                                
                              </a>}
                              tooltipTitle='Enable Comments'
                            />
                            )}
                                <SpeedDialAction
                              key='Save Post'
                              icon={<a
                                onClick={() => savePost(props.post.post.id)}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-favorite mr-1"></i>
                              </a>}
                              tooltipTitle='Save Post'
                            />
                                <SpeedDialAction
                              key='Delete'
                              icon={ <a
                                // href="#"
                                onClick={() => setConfirmDelete(true)}
                                className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                              >
                                <i className="uil-trash-alt mr-1"></i>
                              </a>}
                              tooltipTitle='Delete'
                            />
                       
                        </SpeedDial>
                      </Box> */}

              {/* <Dropdown className={`ms-auto pointer ${actionClass}`}>
                  <Dropdown.Toggle
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                      style={{
                        ...style.normal,
                        ...(hover ? style.hover : null),
                      }}
                    
                    variant="success"
                    id="dropdown-basic"
                  >
                    <a>
                      {" "}
                      <i className="ti-more-alt text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>{" "}
                    </a>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700">
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

              <div className={`ms-auto pointer`} ref={menuRef}>
                {userId[0] == props.post.post.user.id ? (
                  <>
                    <PostDropUserAuth
                      canReport={false}
                      canDisable={true}
                      canUpdate={true}
                      canDelete={true}
                      post={props.post.post}
                      functionProp={() => props.functionProp()}
                    />
                    {/* <div>
                      <a>
                        {" "}
                        <i
                          onClick={toggleMenu}
                          style={{ fontSize: 25, position: "relative" }}
                          className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                        ></i>{" "}
                      </a>
                      {showMenu ? (
                        // <ClickAwayListener onClickAway={handleClickAway}>
                        <div
                          className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                          // data-uk-drop="mode: click;pos: bottom-right"
                          style={styles}
                        >
                          <ul className="space-y-1">
                            <li>
                              <PostUpdateModal
                                title="Edit Post"
                                content={props.post.post}
                                type="post"
                                postId={props.post.post.id}
                                functionProp={() => props.functionProp()}
                              />
                            </li>

                            <li>
                              <a
                                onClick={() =>
                                  repost(props.post.post.post_origin)
                                }
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-refresh-ccw mr-1"></i>{" "}
                                Re-post
                              </a>
                            </li>

                            <li>
                              <div>
                                {reposting ? (
                                  <p>
                                    {" "}
                                    Reposting... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {reposted ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setReposted(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've reposted this post successfully. Your
                                    followers will see it on their timeline.
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>

                            {props.post.post.can_comment == "true" ? (
                              <li>
                                <a
                                  onClick={() =>
                                    disableComment(props.post.post.id)
                                  }
                                  //  onClick={disableComment}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="uil-comment-slash mr-1"></i>{" "}
                                  Disable comments
                                </a>
                              </li>
                            ) : (
                              <li>
                                <a
                                  onClick={() =>
                                    enableComment(props.post.post.id)
                                  }
                                  //  onClick={disableComment}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="uil-comment-slash mr-1"></i>{" "}
                                  Enable comments
                                </a>
                              </li>
                            )}

                            <li>
                              <div>
                                {disabling ? (
                                  <p>
                                    {" "}
                                    Disabling Future Comments... This will take
                                    a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {disabled ? (
                                  <MuiAlert
                                    // onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setDisabled(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've disabled future comments on this
                                    post. Simply click enable to undo
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            <li>
                              <div>
                                {enabling ? (
                                  <p>
                                    {" "}
                                    Enabling Comments ... This will take a few
                                    seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {enabled ? (
                                  <MuiAlert
                                    // onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setEnabled(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've re-enabled comments on this post.
                                    Simply click disable to undo
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            <li>
                              <a
                                onClick={() => savePost(props.post.post.id)}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-favorite mr-1"></i> Save Post
                              </a>
                            </li>
                            <li>
                              <div>
                                {saving ? (
                                  <p>
                                    {" "}
                                    Saving Post... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {saved ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setSaved(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've saved this post successfully.
                                    <a href={`/profile/${userName}`}>
                                      View it in your profile
                                    </a>
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>
                            <li>
                              <a
                                onClick={toggleMenu}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-x mr-1"></i> Close This
                              </a>
                            </li>
                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>
                            <li>
                              <a
                                // href="#"
                                onClick={() => setConfirmDelete(true)}
                                className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                              >
                                <i className="uil-trash-alt mr-1"></i> Delete
                              </a>
                              {confirmDelete ? (
                                <div>
                                  <p>
                                    {" "}
                                    Are you sure you want to delete this ?{" "}
                                  </p>
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="error"
                                    action={
                                      <>
                                        <a
                                          onClick={() =>
                                            deletePost(props.post.post.id)
                                          }
                                          className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          Yes, delete it.
                                        </a>
                                        <br />
                                        <a
                                          onClick={() =>
                                            setConfirmDelete(false)
                                          }
                                          className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          No, Cancel
                                        </a>
                                      </>
                                    }
                                  >
                                    Are you sure you want to delete this ?
                                  </MuiAlert>
                                </div>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        // // <ClickAwayListener>
                        ""
                      )}
                    </div> */}
                  </>
                ) : (
                  <>
                    <PostDropUserAuth
                      canReport={true}
                      canDisable={false}
                      canUpdate={false}
                      canDelete={false}
                      post={props.post.post}
                      functionProp={() => props.functionProp()}
                    />
                    {/* <div>
                      <a>
                        {" "}
                        <i
                          onClick={toggleMenu}
                          style={{ fontSize: 25, position: "relative" }}
                          className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                        ></i>{" "}
                      </a>
                      {showMenu ? (
                        // <ClickAwayListener onClickAway={handleClickAway}>
                        <div
                          className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                          // data-uk-drop="mode: click;pos: bottom-right"
                          style={styles}
                        >
                          <ul className="space-y-1">
                            <li>
                              <a
                                onClick={() =>
                                  repost(props.post.post.post_origin)
                                }
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-refresh-ccw mr-1"></i>{" "}
                                Re-post
                              </a>
                            </li>

                            <li>
                              <div>
                                {reposting ? (
                                  <p>
                                    {" "}
                                    Reposting... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {reposted ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setReposted(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've reposted this post successfully. Your
                                    followers will see it on their timeline.
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>

                            <li>
                              <a
                                onClick={() => savePost(props.post.post.id)}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-favorite mr-1"></i> Save Post
                              </a>
                            </li>
                            <li>
                              <div>
                                {saving ? (
                                  <p>
                                    {" "}
                                    Saving Post... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {saved ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setSaved(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've saved this post successfully.
                                    <a href={`/profile/${userName}`}>
                                      View it in your profile
                                    </a>
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            {/* <li>
                              <div>
                                {reposting ? (
                                  <p>
                                    {" "}
                                    Reporting... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {reposted ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setReposted(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've reported this post successfully. It will be disabled soon
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li> 
                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>
                            <li>
                              <a
                                onClick={toggleMenu}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-x mr-1"></i> Close This
                              </a>
                            </li>
                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>
                            <li>
                              <ReportModal
                                type="post"
                                text="Report"
                                url="post"
                                id={props.post.post.id}
                              />
                            </li>
                          </ul>
                        </div>
                      ) : (
                        // // <ClickAwayListener>
                        ""
                      )}
                    </div> */}
                  </>
                )}
              </div>
            </div>
          ) : (
            <>
              {/* owner */}
              <div className="card-body p-0 d-flex">
                {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
                <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={props.post.post.owner.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                      onError={fixImage}
                      src={props.post.post.owner.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                </figure>
                {/* </div> */}
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  <span style={{ paddingRight: 2 }}>
                    Reposted From : {props.post.post.owner.first_name}{" "}
                    {props.post.post.owner.last_name}'s
                  </span>
                  {props.post.post.type == "alert" ? (
                    <a
                      href={
                        `/alert/${props.post.post.slug}?i=${props.post.post.post_origin}`
                        // pathname: `/alert/${props.post.post.slug}?i=${props.post.post.slug}`,
                        //   state: { id: post.post.post_origin },
                      }
                      className="text-primary"
                    >
                      Alert
                    </a>
                  ) : (
                    <a
                      href={
                        `/post/${props.post.post.unique_id}/${props.post.post.post_origin}`
                        // pathname: `/post/${props.post.post.unique_id}`,
                        //   state: { id: post.post.post_origin },
                      }
                      className="text-primary"
                    >
                      Post
                    </a>
                  )}
                  <Link to={`/profile/${props.post.post.owner.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{props.post.post.owner.user_name}
                    </span>{" "}
                  </Link>
                </h4>
              </div>

              <div className="card-body p-0 d-flex">
                {/* <div className="bg-gradient-to-tr from-yellow-600 to-pink-600 p-0.5 rounded-full"> */}
                <figure className="avatar me-3 " style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={props.post.post.user.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                      onError={fixImage}
                      src={props.post.post.user.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                </figure>
                {/* </div> */}
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  {props.post.post.user.first_name}{" "}
                  {props.post.post.user.last_name}
                  <Link to={`/profile/${props.post.post.user.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{props.post.post.user.user_name}
                    </span>{" "}
                  </Link>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {formatDistance(
                      new Date(props.post.post.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                    {/* {alert.created_at} */}
                  </span>
                </h4>
                {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}

                <div className={`ms-auto pointer`} ref={menuRef}>
                  {userId[0] == props.post.post.user.id ? (
                    <>
                      <PostDropUserAuth
                        canReport={false}
                        canDisable={true}
                        canUpdate={false}
                        canDelete={true}
                        post={props.post.post}
                        functionProp={() => props.functionProp()}
                      />
                      {/* <div>
                        <a>
                          {" "}
                          <i
                            onClick={toggleMenu}
                            style={{ fontSize: 25, position: "relative" }}
                            className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                          ></i>{" "}
                        </a>
                        {showMenu ? (
                          // <ClickAwayListener onClickAway={handleClickAway}>
                          <div
                            className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                            // data-uk-drop="mode: click;pos: bottom-right"
                            style={styles}
                          >
                            <ul className="space-y-1">
                              <li>
                                <a
                                  onClick={() =>
                                    repost(props.post.post.post_origin)
                                  }
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-refresh-ccw mr-1"></i>{" "}
                                  Re-post
                                </a>
                              </li>

                              <li>
                                <div>
                                  {reposting ? (
                                    <p>
                                      {" "}
                                      Reposting... This will take a few seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {reposted ? (
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setReposted(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've reposted this post successfully.
                                      Your followers will see it on their
                                      timeline.
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>

                              {props.post.post.can_comment == "true" ? (
                                <li>
                                  <a
                                    onClick={() =>
                                      disableComment(props.post.post.id)
                                    }
                                    //  onClick={disableComment}
                                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                  >
                                    <i className="uil-comment-slash mr-1"></i>{" "}
                                    Disable comments
                                  </a>
                                </li>
                              ) : (
                                <li>
                                  <a
                                    onClick={() =>
                                      enableComment(props.post.post.id)
                                    }
                                    //  onClick={disableComment}
                                    className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                  >
                                    <i className="uil-comment-slash mr-1"></i>{" "}
                                    Enable comments
                                  </a>
                                </li>
                              )}

                              <li>
                                <div>
                                  {disabling ? (
                                    <p>
                                      {" "}
                                      Disabling Future Comments... This will
                                      take a few seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {disabled ? (
                                    <MuiAlert
                                      // onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setDisabled(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've disabled future comments on this
                                      post. Simply click enable to undo
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                              <li>
                                <div>
                                  {enabling ? (
                                    <p>
                                      {" "}
                                      Enabling Comments ... This will take a few
                                      seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {enabled ? (
                                    <MuiAlert
                                      // onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setEnabled(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've re-enabled comments on this post.
                                      Simply click disable to undo
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>

                              <li>
                                <a
                                  onClick={() => savePost(props.post.post.id)}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="uil-favorite mr-1"></i> Save
                                  Post
                                </a>
                              </li>
                              <li>
                                <div>
                                  {saving ? (
                                    <p>
                                      {" "}
                                      Saving Post... This will take a few
                                      seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {saved ? (
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setSaved(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've saved this post successfully.
                                      <a href={`/profile/${userName}`}>
                                        View it in your profile
                                      </a>
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                              <li>
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <a
                                  onClick={toggleMenu}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-x mr-1"></i> Close This
                                </a>
                              </li>
                              <li>
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <a
                                  // href="#"
                                  onClick={() => setConfirmDelete(true)}
                                  className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                                >
                                  <i className="uil-trash-alt mr-1"></i> Delete
                                </a>
                                {confirmDelete ? (
                                  <div>
                                    <p>
                                      {" "}
                                      Are you sure you want to delete this ?{" "}
                                    </p>
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="error"
                                      action={
                                        <>
                                          <a
                                            onClick={() =>
                                              deletePost(props.post.post.id)
                                            }
                                            className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            Yes, delete it.
                                          </a>
                                          <br />
                                          <a
                                            onClick={() =>
                                              setConfirmDelete(false)
                                            }
                                            className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            No, Cancel
                                          </a>
                                        </>
                                      }
                                    >
                                      Are you sure you want to delete this ?
                                    </MuiAlert>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          // // <ClickAwayListener>
                          ""
                        )}
                      </div> */}
                    </>
                  ) : (
                    <>
                      <PostDropUserAuth
                        canReport={true}
                        canDisable={false}
                        canUpdate={false}
                        canDelete={false}
                        post={props.post.post}
                        functionProp={() => props.functionProp()}
                      />
                      {/* <div>
                        <a>
                          {" "}
                          <i
                            onClick={toggleMenu}
                            style={{ fontSize: 25, position: "relative" }}
                            className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                          ></i>{" "}
                        </a>
                        {showMenu ? (
                          // <ClickAwayListener onClickAway={handleClickAway}>
                          <div
                            className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                            // data-uk-drop="mode: click;pos: bottom-right"
                            style={styles}
                          >
                            <ul className="space-y-1">
                              <li>
                                <a
                                  onClick={() =>
                                    repost(props.post.post.post_origin)
                                  }
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-refresh-ccw mr-1"></i>{" "}
                                  Re-post
                                </a>
                              </li>

                              <li>
                                <div>
                                  {reposting ? (
                                    <p>
                                      {" "}
                                      Reposting... This will take a few seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {reposted ? (
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setReposted(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've reposted this post successfully.
                                      Your followers will see it on their
                                      timeline.
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>

                              <li>
                                <a
                                  onClick={() => savePost(props.post.post.id)}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="uil-favorite mr-1"></i> Save
                                  Post
                                </a>
                              </li>
                              <li>
                                <div>
                                  {saving ? (
                                    <p>
                                      {" "}
                                      Saving Post... This will take a few
                                      seconds{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {saved ? (
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setSaved(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've saved this post successfully.
                                      <a href={`/profile/${userName}`}>
                                        View it in your profile
                                      </a>
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                              <li>
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <a
                                  onClick={toggleMenu}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-x mr-1"></i> Close This
                                </a>
                              </li>
                              <li>
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <ReportModal
                                  type="post"
                                  text="Report"
                                  url="post"
                                  id={props.post.post.id}
                                />
                              </li>
                            </ul>
                          </div>
                        ) : (
                          // // <ClickAwayListener>
                          ""
                        )}
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          <div>
            <h3 className="fw-700 text-grey-900 pointer">
              {" "}
              {props.post.post.headline}{" "}
            </h3>
          </div>

          {props.post.post.video ? (
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
              {/* <Player
                playsInline
                // poster={alert.alert.images[0].url}
                src={props.post.post.video}
                fluid
              /> */}

              <ReactPlayer
                url={props.post.post.video}
                controls={true}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                playsinline={true}
                width="100%"
                // light={alert..image}
              />

              <br />
              {/* </a> */}
            </div>
          ) : (
            ""
          )}

          {props.post.post.images ? (
            <div className="card-body d-block p-0 mb-3">
              <br />
              {/* {props.post.post.images.length} */}
              {props.post.post.images.length == 1 ? (
                <SingleImage images={props.post.post.images} text={""} />
              ) : (
                ""
              )}
              {props.post.post.images.length == 2 ? (
                <TwoImages images={props.post.post.images} text={""} />
              ) : (
                ""
              )}
              {props.post.post.images.length == 3 ? (
                <ThreeImages images={props.post.post.images} text={""} />
              ) : (
                ""
              )}
              {props.post.post.images.length == 4 ? (
                <FourImages images={props.post.post.images} text={""} />
              ) : (
                ""
              )}

              {props.post.post.images.length > 4 && (
                <Carousel>
                  {props.post.post.images.map((slideImage, index) => (
                    <img
                      src={slideImage.url}
                      key={index}
                      style={{ zIndex: -10 }}
                      alt=""
                    />
                  ))}
                </Carousel>
                // <Zoom scale={1.4} indicators={true} style={{ zIndex: -10 }}>
                //   {props.post.post.images.map((slideImage, index) => (

                //       <img src={slideImage.url} key={index} style={{zIndex: -10}}  alt="" />

                //   ))}
                // </Zoom>
              )}
              {/* <SingleImage image={props.post.post.image}/> */}
              {/* <div className="row ps-2 pe-2">
                    <div className="col-sm-12 p-1">
                      <img
                        src={props.post.post.image}
                        className="rounded-3 w-100 float-left"
                        alt="Post Image"
                        style={{
                          maxWidth: 800,
                          maxHeight: 450,
                          objectFit: "contain",
                        }}
                      />
                    </div>
                </div> */}
            </div>
          ) : (
            ""
          )}

          <div className="card-body p-0 me-lg-5">
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more"
              less="Show less"
              className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
              // className="content-css"
              anchorClass="my-anchor-css-class"
              // onClick={executeOnClick}
              expanded={false}
              // width={480}
              truncatedEndingComponent={"... "}
            >
              {props.post.post.body}
            </ShowMoreText>
            {/* <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{props.post.post.body} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p> */}
            {/* COME BACK  */}

            {!props.post.post.alert_category ? (
              ""
            ) : (
              <Link
                to={`/alert-category/${props.post.post.alert_category.slug}`}
              >
                {" "}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Alert Category:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.alert_category.name}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
                {/* <br/> */}
              </Link>
            )}

            {!props.post.post.caption ? (
              ""
            ) : (
              <>
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Alert Location:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.caption}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
              </>
            )}

            {!props.post.post.channel ? (
              ""
            ) : (
              <Link to={`/channel/${props.post.post.channel.slug}`}>
                {" "}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Channel Post:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.channel.name}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
                {/* <br/> */}
              </Link>
            )}

            {!props.post.post.page ? (
              ""
            ) : (
              <Link to={`/page/${props.post.post.page.slug}`}>
                {" "}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Page Post:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.page.name}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
                {/* <br/> */}
              </Link>
            )}

            {!props.post.post.post_category ? (
              ""
            ) : (
              <Link
                to={`/post-category/${props.post.post.user.user_name}/${props.post.post.post_category.slug}`}
              >
                {/* {alert(props.post.post.user.user_name)} */}{" "}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Series:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.post_category.name}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
                {/* <br/> */}
              </Link>
            )}
            {/* {props.post.post.channel_id ? (
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  <br />
                  Type:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label="Channel Post"
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>
              ) : (
                ""
              )} */}

            {!props.post.post.url == "" ? (
              <a href={props.post.post.url} target="_blank">
                {/* <Link to={`/post.post/${props.post.post.slug}`} state={{ id: post.post.id }}> */}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  URL:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.post.post.url}
                  />{" "}
                </h6>
              </a>
            ) : (
              ""
            )}

            {!props.post.post.tags.length == 0 ? (
              <>
                {/* <br/> */}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  Hash Tags{" "}
                </h6>
                <div>
                  {props.post.post.tags.map((tag) => (
                    <>
                      <a href={`/tag/${tag.slug}/posts`}>
                        {/* <Link to={`/tag/${tag.slug}/posts`}> */}
                        <Chip
                          style={{ marginRight: 10, marginBottom: 5 }}
                          className="infotag pointer"
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={`# ${tag.name}`}
                        />
                        {/* </Link> */}
                      </a>
                    </>
                  ))}
                  <br /> <br />
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {props.post.post.alert_category ? (
            <>
              <br />
              <p>
                {" "}
                {props.post.post.user.user_name} created this as an Alarrt.
              </p>
            </>
          ) : (
            ""
          )}
          {/* <> */}
          {!props.post.post.likers.length == 0 ? (
            <LikesProfilePic
              type="post"
              likers={props.post.likers}
              likes={props.post.likers}
              comments={props.post.post.post_comments_count}
              reposts={props.post.post.re_posts}
              allLikers={props.post.post.likers}
              color="#000"
              text="Likes"
              likersType="Post"
            />
          ) : (
            ""
          )}
          <br />

          <div className="card-body d-flex p-0">
            <div
              className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
              onClick={toggleActive}
            >
              {isLiking ? (
                <HourglassEmptyRoundedIcon style={{ color: "#2516c7" }} />
              ) : (
                <>
                  {props.post.likedStatus == "false" ? (
                    <>
                      {/* <p>hii</p> */}
                      <FavoriteBorderIcon
                        onClick={() => likePost(props.post.post.id)}
                        style={{ fontSize: 25, color: "#2516c7" }}
                      />
                    </>
                  ) : (
                    <>
                      {/* <p>huu</p> */}
                      <FavoriteRoundedIcon
                        onClick={() => likePost(props.post.post.id)}
                        style={{ fontSize: 25, color: "#2516c7" }}
                      />
                    </>
                  )}
                </>
              )}
              <span style={{ paddingLeft: 5 }}>
                {props.post.post.likers.length}
                {/* <LikersModal
                    likers={props.post.post.likers}
                    color="#000"
                    text="Likes"
                    type="Post"
                    class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss"
                  /> */}
              </span>
              <span className="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss">
                {reposting ? (
                  <HourglassEmptyRoundedIcon
                    style={{ fontSize: 25, color: "#2516c7" }}
                  />
                ) : (
                  <RepeatIcon
                    style={{
                      fontSize: 25,
                      color: "#2516c7",
                      marginLeft: 10,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      repost(props.post.post.post_origin);
                    }}
                  />
                )}
                {props.post.post.re_posts}
                {/* re-posts */}
                {/* View 0 Comments jdjks */}
              </span>
              <div style={{ marginLeft: 13 }}></div>

              {authId[0] == props.post.post.user.id && (
                <span className="d-flex align-items-center fw-800 text-grey-800 text-dark lh-26 font-xssss">
                  <RepeatOneIcon
                    style={{
                      fontSize: 25,
                      color: "#2516c7",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setCurrentPost(props.post.post.id);
                      toggleThread();
                    }}
                  />{" "}
                </span>
              )}

              {/* <div className="pointer">
                  {" "}
                  <RepeatIcon /> 0 re-posts{" "}
                </div> */}
              {/* <i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> <i className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i>2.8K Like */}
            </div>
            {/* <div className={`emoji-wrap pointer ${emojiClass}`}>
                                  <ul className="emojis list-inline mb-0">
                                      <li className="emoji list-inline-item"><i className="em em---1"></i> </li>
                                      <li className="emoji list-inline-item"><i className="em em-angry"></i></li>
                                      <li className="emoji list-inline-item"><i className="em em-anguished"></i> </li>
                                      <li className="emoji list-inline-item"><i className="em em-astonished"></i> </li>
                                      <li className="emoji list-inline-item"><i className="em em-blush"></i></li>
                                      <li className="emoji list-inline-item"><i className="em em-clap"></i></li>
                                      <li className="emoji list-inline-item"><i className="em em-cry"></i></li>
                                      <li className="emoji list-inline-item"><i className="em em-full_moon_with_face"></i></li>
                                  </ul>
                              </div> */}
            <div style={{ marginLeft: 10 }}></div>
            <a
              // href="#"
              className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss"
            >
              <i
                style={{ color: "#2516c7", fontSize: 25 }}
                className="feather-message-circle btn-round-sm"
              ></i>
              {isComment ? (
                <span className="d-none-xss" onClick={toggleComment}>
                  {/* 283 Comments */}
                  Close {props.post.post.post_comments_count} comments
                  {/* View 0 Comments */}
                </span>
              ) : (
                <span className="d-none-xss" onClick={toggleComment}>
                  {/* 283 Comments */}
                  View {props.post.post.post_comments_count} comments
                  {/* View 0 Comments */}
                </span>
              )}
            </a>

            <div
              className={`pointer ms-auto d-flex align-items-center fw-600 text-current lh-26 font-xssss ${menuClass}`}
            >
              {/* < */}
              <ShareButtonsPost post={props.post.post} />
            </div>
          </div>
          {/* <li>
                  <hr className="-mx-2 my-2 dark:border-gray-800" />
                </li> */}

          {props.post.followingUser == "true" ? (
            ""
          ) : (
            <>
              {userId[0] == props.post.post.user.id ? (
                ""
              ) : (
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    {" "}
                    <br />
                    <p>
                      Follow @{props.post.post.user.user_name} to see past and
                      future post in your timeline{" "}
                    </p>{" "}
                    {following ? (
                      <a
                        // className="ms-auto pointer"
                        disabled
                        // onClick={() => Follow(post.post.user.user_name)}
                        style={{ marginTop: -8 }}
                        className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                        {" "}
                        {followText}{" "}
                      </a>
                    ) : (
                      <a
                        // className="ms-auto pointer"
                        onClick={() => Follow(props.post.post.user.user_name)}
                        style={{ marginTop: -8 }}
                        className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                        {" "}
                        {followText}{" "}
                      </a>
                    )}
                  </div>
                  <br />
                  {followed ? (
                    <Snackbar
                      open={followed}
                      autoHideDuration={3000}
                      onClose={closeSnack}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Alert onClose={closeSnack} severity="success">
                        Followed {props.post.post.user.user_name} Successfully
                      </Alert>
                    </Snackbar>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          )}

          {isThread && (
            <CreateThread
              Id={props.post.post.id}
              url={"post_thread"}
              threads={props.post.post.threads}
              functionProp={props.functionProp}
              // openComment={openComment}
              // favUrl={"post"}
              authId={authId[0]}
              userId={props.post.post.user.id}
            />
          )}
          <AllThreads
            Id={props.post.post.id}
            url={"post_thread"}
            user_name={props.post.post.user.user_name}
            pic={props.post.post.user.profile_pic}
            threads={props.post.post.threads}
            functionProp={props.functionProp}
            // openComment={openComment}
            // favUrl={"post"}
            authId={authId[0]}
            userId={props.post.post.user.id}
          />
          {/* {props.post.post.can_comment} */}

          {props.isGuest ? (
            <>
            
           {/* <h5> <a href={`/post/${props.post.post.unique_id}/${props.post.post.post_origin}`}>  Click here </a> to view as an ALARRT user / add comments </h5> */}
            </>

          ) : (
            <>
              {props.post.post.can_comment == "true" ? (
                <CreateComment
                  Id={props.post.post.id}
                  url={"posts/comment"}
                  favComments={props.post.favComments}
                  functionProp={props.functionProp}
                  openComment={openComment}
                  favUrl={"post"}
                  authId={authId[0]}
                  userId={props.post.post.user.id}
                />
              ) : (
                ""
              )}
              {/* </> */}
              {/* // )} */}

              {isComment ? (
                <>
                  <SingleComment
                    Id={props.post.post.id}
                    comments={props.post.comments}
                    favUrl={"post"}
                    deleteUrl="postComment"
                    url1={"posts"}
                    functionProp={props.functionProp}
                    authId={authId[0]}
                    userId={props.post.post.user.id}
                  />
                  <span
                    className="fw-600 text-grey-900 mt-2 pointer"
                    onClick={toggleComment}
                  >
                    {/* 283 Comments */}
                    Close Comments
                  </span>
                </>
              ) : (
                ""
              )}
            </>
          )}

          {reposted ? (
            <Snackbar
              open={reposted}
              autoHideDuration={2000}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose2} variant="filled" severity="success">
                Re posted successfully
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}

          {/* {open ? (
              
              <SinglePostComponent
                post={singlePost}
                open={true}
                functionProp={() => props.functionProp()}
              />
            ) : (
              ""
            )} */}
          {/* </div> */}
        </div>
        {/* // ))} */}
      </>
    );
  }
  // }
}

// export default Alerts;
