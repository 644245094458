import React from "react";
import { Component, useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { Route, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AddAlertRoundedIcon from "@material-ui/icons/AddAlertRounded";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import BoltIcon from "@mui/icons-material/Bolt";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

function Copyright() {
  return (
    <>
      <br />
      <Typography variant="body2" color="textSecondary" align="center">
        {"For Help, "}
        <Link color="primary" href="/help">
          Click Here!
        </Link>{" "}
        {"."}
      </Typography>

      <Typography variant="body2" color="textSecondary" align="center">
        {"Copyright © "}
        <Link color="primary" target="_blank" href="https://alarrt.com/">
        ALARRT
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        A Product Of AT-ALARRT Digital Technologies Limited
        {/* <Link
          style={{ paddingLeft: 3 }}
          color="primary"
          target="_blank"
          href="https://soaenterprise.com/"
        >
          SOA Digital Enterprise
        </Link> */}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "950vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image2: {
    backgroundImage:
      "url(https://alert-app-v2.s3.amazonaws.com/ALARRT+APP+LOADER+V3.svg)",
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "contain",
    backgroundPosition: "cover",
    width: "100vw",
    height: "130vh",
    [theme.breakpoints.down("sm")]: {
      height: "80vh",
      backgroundSize: "contain",
    },
  },
  paper: {
    margin: theme.spacing(6, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function AppPolicy() {
  const classes = useStyles();
  const { id } = useParams(["id"]);
  const [email, setEmail] = useState("");
  const [pword, setPword] = useState("");
  const [pword2, setPword2] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [params, setParams] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  const [resetError, setResetError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();
  const history = useHistory();

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setPasswordMismatch(false);
    setEmptyFields(false);
    setResetError(false);
    setIsSuccess(false);
    // setOpenAlert(false);
  };

  function redirect() {
    if (window.localStorage.getItem("access_token")) {
      history.push("/");
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const value = queryParams.get("token");
    setParams(value);
    redirect();
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {/* <Grid item xs={12} sm={6} md={6} className={classes.image2} /> */}
      <Grid item xs={12} sm={6} md={6}>
        <br />
        <Typography
          component="h1"
          variant="overline"
          className="text-center"
          style={{ color: "#2516c7" }}
        >
          {" "}
          Welcome! Please always accept the notification and location prompt
          when asked for a better experience on ALARRT
        </Typography>
        <div className={classes.image2}></div>
      </Grid>
      <Grid item xs={12} sm={6} md={6} component={Paper} elevation={6} square>
        <div className={classes.paper}>
        <img src='/assets/images/alarrt-logo.png' style={{width:250, height:100}} />
          {/* <Avatar className={classes.avatar}>
            <BoltIcon style={{ fontSize: 25 }} />
          </Avatar> */}
          <br />
          <Typography component="h1" variant="h5" className="text-center">
            ALARRT Terms, Conditions and Usage Policy
          </Typography>
          <br />
          <p className="text-center">Welcome to ALARRT!</p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
            These terms and conditions outline the rules and regulations for the
            use of ALARRT. By using this app we assume you accept these terms
            and conditions.{" "}
          </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
            {" "}
            Do not continue to use ALARRT if you do not agree to take all of the
            terms and conditions stated on this page. The following terminology
            applies to these Terms and Conditions, Privacy Statement and
            Disclaimer Notice and all Agreements: "Client", "You" and "Your"
            refers to you, the person log on this website and compliant to the
            Company’s terms and conditions. "The Company", "Ourselves", "We",
            "Our" and "Us", refers to ALARRT and SOA Digital Enterprise. "Party",
            "Parties", or "Us", refers to both the Client and ourselves.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
             By
            downloading or using the app, these terms will automatically apply
            to you – you should make sure therefore that you read them carefully
            before using the app. You’re not allowed to copy or modify the app,
            any part of the app, or our trademarks in any way. You’re not
            allowed to attempt to extract the source code of the app, and you
            also shouldn’t try to translate the app into other languages or make
            derivative versions. The app itself, and all the trademarks,
            copyright, database rights, and other intellectual property rights
            related to it, still belongs to ALARRT and SOA Digital Enterprise. We are committed to ensuring that the app is as useful and
            efficient as possible. For that reason, we reserve the right to make
            changes to the app or to charge for its services, at any time and
            for any reason. We will never charge you for the app or its services
            without making it very clear to you exactly what you’re paying for.
            The ALARRT app stores and processes personal data that you have
            provided to us, to provide our Service. It’s your responsibility to
            keep your phone and access to the app secure.
          </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
            At some point, we may wish to update the app. The app is currently
            available on Android – the requirements for the system(and for any
            additional systems we decide to extend the availability of the app
            to) may change, and you’ll need to download the updates if you want
            to keep using the app. ALARRT does not promise that
            it will always update the app so that it is relevant to you and/or
            works with the Android version that you have installed on your
            device. However, you promise to always accept updates to the
            application when offered to you, We may also wish to stop providing
            the app, and may terminate use of it at any time without giving
            notice of termination to you.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}> Changes to This Terms and Conditions
            We may update our Terms and Conditions from time to time. Thus, you
            are advised to review this page periodically for any changes. We
            will notify you of any changes by posting the new Terms and
            Conditions on this page. These terms and conditions are effective as
            of 2022-06-25 Contact Us If you have any questions or suggestions
            about our Terms and Conditions, do not hesitate to contact us at
            appsupport@alarrt.com. License Unless otherwise stated, ALARRT
            and/or its licensors own the intellectual property rights for all
            material on ALARRT.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}> All intellectual property rights are reserved.
            You may access this from ALARRT for your own personal use subjected
            to restrictions set in these terms and conditions. You must not:
            Sell, rent or sub-license material from ALARRT Reproduce, duplicate
            or copy material from ALARRT Parts of this app offer an opportunity
            for users to post and exchange opinions and information in certain
            areas of the website. ALARRT does not filter, edit, publish or
            review Comments prior to their presence on the website. Comments do
            not reflect the views and opinions of ALARRT, its agents and/or
            affiliates. Comments reflect the views and opinions of the person
            who post their views and opinions. To the extent permitted by
            applicable laws, ALARRT shall not be liable for the Comments or for
            any liability, damages or expenses caused and/or suffered as a
            result of any use of and/or posting of and/or appearance of the
            Comments on this website.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}> ALARRT reserves the right to monitor all
            Comments and to remove any Comments which can be considered
            inappropriate, offensive or causes a breach of these Terms and
            Conditions. You warrant and represent that: You are entitled to post
            the Comments on our App and have all necessary licenses and consents
            to do so; The Comments do not invade any intellectual property
            right, including without limitation copyright, patent or trademark
            of any third party; The Comments do not contain any defamatory,
            libelous, offensive, indecent or otherwise unlawful material which
            is an invasion of privacy The Comments will not be used to solicit
            or promote business or custom or present commercial activities or
            unlawful activity.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
             You hereby grant ALARRT a non-exclusive license
            to use, reproduce, edit and authorize others to use, reproduce and
            edit any of your Comments in any and all forms, formats or media.
            Hyperlinking to our App The following organizations may link to our
            App without prior written approval: Government agencies; Search
            engines; News organizations; Online directory distributors may link
            to our App in the same manner as they hyperlink to the Websites of
            other listed businesses; and System wide Accredited Businesses
            except soliciting non-profit organizations, charity shopping malls,
            and charity fundraising groups which may not hyperlink to our Web
            site. These organizations may link to our home page, to publications
            or to other App information so long as the link: (a) is not in any
            way deceptive; (b) does not falsely imply sponsorship, endorsement
            or approval of the linking party and its products and/or services;
            and (c) fits within the context of the linking party’s site. We may
            consider and approve other link requests from the following types of
            organizations: commonly-known consumer and/or business information
            sources; dot.com community sites; associations or other groups
            representing charities; online directory distributors; internet
            portals; accounting, law and consulting firms; and educational
            institutions and trade associations. We will approve link requests
            from these organizations if we decide that: (a) the link would not
            make us look unfavorably to ourselves or to our accredited
            businesses; (b) the organization does not have any negative records
            with us; (c) the benefit to us from the visibility of the hyperlink
            compensates the absence of ALARRT; and (d) the link is in the
            context of general resource information. These organizations may
            link to our App so long as the link: (a) is not in any way
            deceptive; (b) does not falsely imply sponsorship, endorsement or
            approval of the linking party and its products or services; and (c)
            fits within the context of the linking party’s site. If you are one
            of the organizations listed in paragraph 2 above and are interested
            in linking to our App, you must inform us by sending an e-mail to
            ALARRT. Please include your name, your organization name, contact
            information as well as the URL of your site, a list of any URLs from
            which you intend to link to our App, and a list of the URLs on our
            site to which you would like to link. Wait 2-3 weeks for a response.
            Approved organizations may hyperlink to our App as follows: By use
            of our corporate name; or By use of the uniform resource locator
            being linked to; or By use of any other description of our App being
            linked to that makes sense within the context and format of content
            on the linking party’s site. No use of ALARRT's logo or other
            artwork will be allowed for linking absent a trademark license
            agreement. iFrames Without prior approval and written permission,
            you may not create frames around our Webpages that alter in any way
            the visual presentation or appearance of our App. Content Liability
            We shall not be hold responsible for any content that appears on
            your App. You agree to protect and defend us against all claims that
            is rising on our App. No link(s) should appear on any Website that
            may be interpreted as libelous, obscene or criminal, or which
            infringes, otherwise violates, or advocates the infringement or
            other violation of, any third party rights.</p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
             Your Privacy While using
            Our Service, We may ask You to provide Us with certain personally
            identifiable information that can be used to contact or identify
            You. Personally identifiable information may include, but is not
            limited to: Email address First name and last name Phone number
            Address, State, Province, ZIP/Postal code, City Usage Data Usage
            Data is collected automatically when using the Service. Usage Data
            may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other
            diagnostic data. When You access the Service by or through a mobile
            device, We may collect certain information automatically, including,
            but not limited to, the type of mobile device You use, Your mobile
            device unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}> We may also
            collect information that Your browser sends whenever You visit our
            Service or when You access the Service by or through a mobile
            device. Information from Third-Party Social Media Services You may
            also have the option of sharing additional information with the
            Company through Your Third-Party Social Media Service's account. If
            You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy. 
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
            Information Collected while Using the Application While
            using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission: Information
            regarding your location Pictures and other information from your
            Device's camera and photo library We use this information to provide
            features of Our Service, to improve and customize Our Service. The
            information may be uploaded to the Company's servers and/or a
            Service Provider's server or it may be simply stored on Your device.
            You can enable or disable access to this information at any time,
            through Your Device settings. Tracking Technologies and Cookies We
            use Cookies and similar tracking technologies to track the activity
            on Our Service and store certain information. Tracking technologies
            used are beacons, tags, and scripts to collect and track information
            and to improve and analyze Our Service. 
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>The Company may use Personal
            Data for the following purposes: To provide and maintain our
            Service, including to monitor the usage of our Service. To manage
            Your Account: to manage Your registration as a user of the Service.
            The Personal Data You provide can give You access to different
            functionalities of the Service that are available to You as a
            registered user. For the performance of a contract: the development,
            compliance and undertaking of the purchase contract for the
            products, items or services You have purchased or of any other
            contract with Us through the Service. To contact You: To contact You
            by email, telephone calls, SMS, or other equivalent forms of
            electronic communication, such as a mobile application's push
            notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation. To provide You with news, and general
            information you have opted to receive . To manage Your requests: To
            attend and manage Your requests to Us. For business transfers: We
            may use Your information to evaluate or conduct a merger,
            divestiture, restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred. For other purposes: We may
            use Your information for other purposes, such as data analysis,
            identifying usage trends, determining the effectiveness of our
            promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience. We may share Your
            personal information in the following situations: With Service
            Providers: We may share Your personal information with Service
            Providers to monitor and analyze the use of our Service, to contact
            You. For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company. With Affiliates: We
            may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us. With business partners: We may
            share Your information with Our business partners to offer You
            certain products, services or promotions. With other users: when You
            share personal information or otherwise interact in the public areas
            with other users, such information may be viewed by all users and
            may be publicly distributed outside. If You interact with other
            users or register through a Third-Party Social Media Service, Your
            contacts on the Third-Party Social Media Service may see Your name,
            profile, pictures and description of Your activity. Similarly, other
            users will be able to view descriptions of Your activity,
            communicate with You and view Your profile. With Your consent: We
            may disclose Your personal information for any other purpose with
            Your consent.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>
             Retention of Your Personal Data The Company will
            retain Your Personal Data only for as long as is necessary for the
            purposes set out in this Privacy Policy. We will retain and use Your
            Personal Data to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to
            comply with applicable laws), resolve disputes, and enforce our
            legal agreements and policies. The Company will also retain Usage
            Data for internal analysis purposes. Usage Data is generally
            retained for a shorter period of time, except when this data is used
            to strengthen the security or to improve the functionality of Our
            Service, or We are legally obligated to retain this data for longer
            time periods. 
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>Transfer of Your Personal Data Your information,
            including Personal Data, is processed at the Company's operating
            offices and in any other places where the parties involved in the
            processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of
            Your state, province, country or other governmental jurisdiction
            where the data protection laws may differ than those from Your
            jurisdiction. Your consent to this Privacy Policy followed by Your
            submission of such information represents Your agreement to that
            transfer. The Company will take all steps reasonably necessary to
            ensure that Your data is treated securely and in accordance with
            this Privacy Policy and no transfer of Your Personal Data will take
            place to an organization or a country unless there are adequate
            controls in place including the security of Your data and other
            personal information. Disclosure of Your Personal Data Business
            Transactions If the Company is involved in a merger, acquisition or
            asset sale, Your Personal Data may be transferred. We will provide
            notice before Your Personal Data is transferred and becomes subject
            to a different Privacy Policy. Law enforcement Under certain
            circumstances, the Company may be required to disclose Your Personal
            Data if required to do so by law or in response to valid requests by
            public authorities (e.g. a court or a government agency). Other
            legal requirements The Company may disclose Your Personal Data in
            the good faith belief that such action is necessary to: Comply with
            a legal obligation Protect and defend the rights or property of the
            Company Prevent or investigate possible wrongdoing in connection
            with the Service Protect the personal safety of Users of the Service
            or the public Protect against legal liability Security of Your
            Personal Data The security of Your Personal Data is important to Us,
            but remember that no method of transmission over the Internet, or
            method of electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security. Children's Privacy Our
            Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information. Links to Other Websites Our Service may contain links
            to other websites that are not operated by Us. If You click on a
            third party link, You will be directed to that third party's site.
            We strongly advise You to review the Privacy Policy of every site
            You visit. We have no control over and assume no responsibility for
            the content, privacy policies or practices of any third party sites
            or services.
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}> Changes to this Privacy Policy We may update Our
            Privacy Policy from time to time. We will notify You of any changes
            by posting the new Privacy Policy on this page. We will let You know
            via email and/or a prominent notice on Our Service, prior to the
            change becoming effective and update the "Last updated" date at the
            top of this Privacy Policy. You are advised to review this Privacy
            Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page. Contact Us If you
            have any questions about this Privacy Policy, You can contact us: By
            email: <b>appsupport@alarrt.com</b> 
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>Reservation of Rights We reserve the
            right to request that you remove all links or any particular link to
            our App. You approve to immediately remove all links to our App upon
            request. We also reserve the right to amen these terms and
            conditions and it’s linking policy at any time. By continuously
            linking to our App, you agree to be bound to and follow these
            linking terms and conditions. Removal of links from our App If you
            find any link on our App that is offensive for any reason, you are
            free to contact and inform us any moment. We will consider requests
            to remove links but we are not obligated to or so or to respond to
            you directly. We do not ensure that the information on this website
            is correct, we do not warrant its completeness or accuracy; nor do
            we promise to ensure that the website remains available or that the
            material on the website is kept up to date. 
            </p>
          <p style={{ lineHeight: 2, marginBottom: 15 }}>Disclaimer To the
            maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our App and
            the use of this website. Nothing in this disclaimer will: limit or
            exclude our or your liability for death or personal injury; limit or
            exclude our or your liability for fraud or fraudulent
            misrepresentation; limit any of our or your liabilities in any way
            that is not permitted under applicable law; or exclude any of our or
            your liabilities that may not be excluded under applicable law. The
            limitations and prohibitions of liability set in this Section and
            elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty. As long as the website and the
            information and services on the website are provided free of charge,
            we will not be liable for any loss or damage of any nature.
          </p>
          <br />
          <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
      
            <br />
            <br />
            <a
              href="/contact-us"
              className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block mr-4 px-4 py-2 rounded shado text-white"
            >
              Need More Info? Click To Send Us A Message
            </a>{" "}
            <br />
            <a
              href="https://alarrt.com"
              className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
            >
              Know more about ALARRT here
            </a>{" "}
            <br />
            <a
              href="/signin"
              className="bg-current text-center flex font-bold hover:bg-current text-center hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
            >
              Go Home
            </a>{" "}
            <br />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
