import { Component, useState, useEffect } from "react";
import React from "react";
import "./online.css";
import Online from "./Online";
import { Users } from "../../dummyData";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import Load from "../../components/Load";
import { useHistory, Link } from "react-router-dom";

export default function RecentlyFollowing() {
  const [following, setFollowing] = useState([]);
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);
  const [unFollowing, setUnFollowing] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  async function getFollowingLoad() {
    setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/following", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log('heeloooo 2 '.JSON.stringify(resp.data));
        // console.log('heeloooo 2 '.JSON.stringify(resp.data));
        setFollowing(resp.data);
        setFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function getFollowing() {
    // setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/following", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log('heeloooo '.JSON.stringify(resp.data));
        setFollowing(resp.data);
        setUnFollowing(false)
        // setFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function toogleFollow(user_name) {
    setUnFollowing(true)
    const access_token = await window.localStorage.getItem("access_token");
    console.log(user_name);
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        getFollowing();
        // setUnFollowing(false)
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    getFollowingLoad();
    // getTags();
    // getAlertCategories();
  }, []);

  if (fetching == true) {
    return <Load />;
  }
  if (following.length == 0) {
    return (
      <>
        <p>
          {" "}
          Hi {user_name} You are not following anyone yet. Use the search bar to
          find your friends. You can also find some interesting people to follow
          in the explore page.{" "}
        </p>
        <br />
        <br />
        <a
          onClick={() => getFollowing()}
          style={{
            display: "flex",
            alignSelf: "center",
            marginBottom: 10,
            marginLeft: 30,
            marginRight: 30,
          }}
          // onClick={toogleFollow(user.user_name)}
          className="text-center border border-gray-200 font-semibold px-4 py-1 rounded-full hover:text-current dark:border-gray-800"
        >
          {" "}
          Refresh
        </a>
        <br />
        <br />
        <br />
        <br />
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
          <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:border-gray-800">
            <h2 className="font-semibold text-lg">Recently Following</h2>
            <a href={`/${user_name}/following`}> View All</a>
          </div>

          {following.map((user) => (
            <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100"
            key={user.id}>
              <div className="flex items-center justify-between py-3">
                <div className="flex flex-1 items-center space-x-4">
                  <a href={`/profile/${user.user_name}`}>
                    <img
                      src={user.profile_pic}
                      className="bg-gray-200 rounded-full w-10 h-10"
                      onError={fixImage}
                    />
                  </a>
                  <Link to={`/profile/${user.user_name}`}>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold">
                        {" "}
                        {user.first_name} {user.last_name}
                      </span>
                      <span className="block capitalize text-sm">
                        {" "}
                        @{user.user_name}{" "}
                      </span>
                    </div>
                  </Link>
                </div>

                {user.is_active == "false" ? (
                  <span className="text-danger ml-5">( Deactivated )</span>
                ) : (

                  <>
                  {unFollowing && currentUser == user.id ? (
                      <a
                      // onClick={toogleFollow(user.user_name)}
                      className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-primary-600 hover:text-primary hover:border-primary-600 dark:border-gray-800"
                    >
                      just a sec...
                    </a>
                  ) : (
                  //   <a
                  //   onClick={() => toogleFollow(user.user_name)}
                  //   // onClick={toogleFollow(user.user_name)}
                  //   className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-primary-600 hover:text-primary hover:border-primary-600 dark:border-gray-800"
                  // >
                  //   {" "}
                  //   UnFollow{" "}
                  // </a>
                    <a
                      onClick={() => {
                          setCurrentUser(user.id)    
                          toogleFollow(user.user_name)
                      }}
                      // onClick={toogleFollow(user.user_name)}
                      className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-primary-600 hover:text-primary hover:border-primary-600 dark:border-gray-800"
                    >
                      UnFollow
                    </a>
                  )}
                </>
                  
                )}
              </div>
            </div>
          ))}

          <br />
          <br />
          <a
            onClick={() => getFollowingLoad()}
            style={{
              display: "flex",
              alignSelf: "center",
              marginBottom: 10,
              marginLeft: 30,
              marginRight: 30,
            }}
            // onClick={toogleFollow(user.user_name)}
            className="text-center border border-gray-200 font-semibold px-4 py-1 rounded-full hover:text-current dark:border-gray-800"
          >
            {" "}
            Refresh
          </a>
          <br />
          <br />
        </div>
        <br />

        {/* <h4 className="rightbarTitle">Recently Following</h4>
        <ul className="rightbarFriendList">
      
          <Online user={following} />
        
        </ul> */}
      </>
    );
  }
}
