import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import MuiAlert from "@material-ui/lab/Alert";
import CreatePost2 from "./CreatePost2";
import { Button, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@material-ui/core";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Chip from "@material-ui/core/Chip";
import { Upload } from "@aws-sdk/lib-storage";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from 'uuid';

import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


import Resizer from "react-image-file-resizer";

const steps = [
  {
    label: "Select campaign settings",
  },
  {
    label: "Create an ad group",
  },
  {
    label: "Create an ad",
  },
];

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  textInput: {
    color: "red",
    // fontSize: 4
  },
}));

export default function AlertUpdateModal(props) {
  const classes = useStyles();
  const [emptyFields, setEmptyFields] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [headline, setHeadline] = useState(props.content.headline);
  const [url, setUrl] = useState("");
  const [location, setLocation] = useState("");
  const [body, setBody] = useState(props.content.body);
  const [alertCategory, setAlertCategory] = useState(
    props.content.alert_category_id
  );
  const [showLive, setShowLive] = useState(false);
  const [showUrl, setShowUrl] = useState(false);

  const [isLimit, setIsLimit] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [alertCategoryName, setAlertCategoryName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [createdCat, setCreatedCat] = useState(false);
  const [alertTagsName, setAlertTagsName] = useState([]);
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [alertImage, setAlertImage] = useState([]);
  const [alertVideo, setAlertVideo] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [creatingAlert, setCreatingAlert] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadLimit, setUploadLimit] = useState(false);

  const animatedComponents = makeAnimated();
  const options = [
    { value: "alert", label: "Alert" },
    { value: "emergency", label: "Emergency" },
    { value: "lagos-state", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];

  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const cancelCreation = () => {
    setActiveStep(0);
    setOpen(false);
    // history.go(`/`);
  };

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      // setChecked2(true);
    } else {
      setShowLive(false);
      // setChecked2(false);
    }
  };
  const toogleUrlVid = (event) => {
    if (showUrl == false) {
      setShowUrl(true);
      // setChecked2(true);
    } else {
      setShowUrl(false);
      // setChecked2(false);
    }
    
  };

  const handleReset = () => {
    setActiveStep(0);
    setOpen(false);
    setProcessed(false);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setCreatedCat(false);
    setEmptyFields(false);
    setProcessed(false);
  };

  async function validate() {
    // console.log(`video ${JSON.stringify(video)}`);
    // console.log(`trailer ${JSON.stringify(trailer)}`);
    if (!headline || !body || !alertCategory) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      uploadVideo();
      // update();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  function clearImages() {
    setAlertImage([]);
    setAlertImageUrls([]);
    setImages([]);
  }

  async function validateImage(e, value) {
    setAlertImage([...e]);
    alertImage.push(e);
    for (let i = 0; i < alertImage[0].length; i++) {
      alertImageUrls.push(URL.createObjectURL(alertImage[0][i]));
    }
    setAlertImage(alertImageUrls);
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  async function onChange3(event) {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  }

  const ChangeAlertCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setAlertCategory(value.id);
      setAlertCategoryName(value.name);
      console.log(alertCategory);
      console.log(alertCategoryName);
    } else {
      setAlertCategory("");
      setAlertCategoryName("");
      console.log("value empty");
      // getTags();
    }
  };

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/alert_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getAlertCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function ChangeAlertTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => alertTagsName.push(tag.value));
    console.log(`name ${alertTagsName}`);

    setSelectedTags([...new Set(alertTagsName)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }
  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setIsUpdating(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "alerts/videos/" + user_id + "/" + uuidv4();
      // video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          // setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            // setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            update();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      update();
      console.log("video very empty");
    }
  }

  async function update() {
    setIsUpdating(true);
    setCreatingMessage("Updating Your Alert, almost done");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();

    formData.append("alert_category_id", alertCategory);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }
    formData.append("tags", alertTagsName);
    // formData.append("sendToFriends", sendToFriends);
    formData.append("type", props.content.type);
    formData.append("status", props.content.status);

    axiosFetch
      .post(`/alerts/update/${props.alertId}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(`updated ${JSON.stringify(resp.data)}`);

        setIsUpdating(false);
        //
        if (resp.data.status == "success") {
          // setOpen(false);
          setAlertImage([]);
          setAlertImageUrls([]);
          setImages([]);
          setVideoUrl('')
          setSelectedTags([]);
          localStorage.removeItem("postVideo");
          setProcessed(true);
          props.functionProp();
          props.functionProp2();
          setOpen(false)
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setIsUpdating(false);
          setProcessed(false);
        }
      })
      .catch((error) => {
        setIsUpdating(false);
        setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
    // }
  }
  useEffect(() => {
    getAlertCategories();
  }, []);
  return (
    <>
      <a
        onClick={handleClickOpen}
        className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
      >
        <i className="uil-edit-alt mr-1"></i> {props.title}
      </a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Update Your Alert Here</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skip The Step You Don't Want To Update. You don't need to update
            all, just edit the info you need to change and click update
          </DialogContentText>

          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ width: "100vw" }}
          >
            {error ? (
              <div>
                <Alert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue updating your alert. Kindly try again. If
                  this continues, kindly login again [ {errorMessage} ]
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {processed ? (
              <Paper square elevation={0} className={classes.resetContainer}>
                <br />
                <Typography>
                  {" "}
                  Your alert has been updated! Invite Your Friends Too So They
                  Can Benefit From This!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  // onClick={() => setOpen(false)}
                  onClick={() => handleClose() }
                >
                  Go Back Home 1
                </Button>

                <br />
              </Paper>
            ) : (
              ""
            )}

            <Step key="first">
              <StepLabel> Update Emergency Headline </StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Update your alert if you missed out an important info. Don't
                  forget to summarize your alert in the headline. Make it simple
                  and straight forward.
                </Typography>
                <TextField
                  variant="standard"
                  id="standard-basic"
                  // label={`Type to update your headline here ${userName}`}
                  required
                  size="small"
                  style={{
                    width: "100%",
                    fontSize: 4,
                    color: "red",
                    paddingleft: 60,
                    paddingleft: 20,
                  }}
                  onChange={(e) => setHeadline(e.target.value)}
                  defaultValue={props.content.headline}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />{" "}
                <br />
                <br />
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Tell us about your alert in details here. What is happening,
                  the location, and any other important info
                </Typography>
                <textarea
                  onChange={(e) => setBody(e.target.value)}
                  style={{ paddingLeft: 50, paddingTop: 10 }}
                  defaultValue={props.content.body}
                  name="message"
                  className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                  cols="30"
                  rows="10"
                  // placeholder={`Tell us what is happening ${userName}`}
                ></textarea>
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="second">
              <StepLabel>Update Your Alert Category - Optional</StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  This is optional, if no change is made, your previous category
                  will be used.
                </Typography>{" "}
                <Autocomplete
                  id="combo-box-demo"
                  options={allCategories}
                  onChange={ChangeAlertCategory}
                  // onChange={(e) => setAlertCategoryName(e.target.value.name) }
                  inputValue={alertCategoryName}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#fff" }}>{children}</Paper>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      variant="standard"
                      style={{ backgroundColor: "pink !important" }}
                      LabelProps={{
                        className: classes.textInput,
                      }}
                    />
                  )}
                  required
                />
                <br />
                <TextField
                  variant="standard"
                  id="standard-basic"
                  label="Create New"
                  size="small"
                  // style={{width:'40%'}}
                  style={{ width: "100%" }}
                  onChange={(e) => setNewCategory(e.target.value)}
                  value={newCategory}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />
                {creatingCat ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: 7 }}
                  >
                    Creating New Category
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={createCategory}
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ marginTop: 7 }}
                    >
                      Create Category
                    </Button>
                    {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                  </>
                )}
                {/* </div> */}
                {/* </div> */}
                {createdCat ? (
                  <>
                    <Snackbar
                      open={createdCat}
                      autoHideDuration={4000}
                      onClose={handleClose2}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Alert onClose={handleClose2} severity="success">
                        Category successfully created. Select it above
                      </Alert>
                    </Snackbar>
                  </>
                ) : (
                  ""
                )}
                
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="third">
              <StepLabel> Add More Alert Tags</StepLabel>
              <StepContent>
                <Typography gutterBottom variant="body1">
                  New tags added will replace the ones.
                </Typography>{" "}
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Tags helps us know more about your alert, and helps other
                  users search for alerts by tag. Previous tags you created:
                </Typography>
                {props.content.tags.map((tag) => (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 5 }}
                    className="infotag pointer"
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={`${tag.name}`}
                  />
                ))}{" "}
                <br />
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Tags you've selected
                </Typography>
                {selectedTags.map((selectedTags) => (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 5 }}
                    className="infotag pointer"
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={`${selectedTags}`}
                  />
                ))}
                <br />
                <CreatableSelect
                  isClearable
                  isMulti
                  onChange={ChangeAlertTag}
                  components={animatedComponents}
                  options={options}
                ></CreatableSelect>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Leave empty if no new tag
                </Typography>
                {/* <br/> */}
                <TextField
                  variant="standard"
                  id="standard-basic"
                  label={"Tell us where"}
                  required
                  size="small"
                  style={{ width: "100%", fontSize: 4, color: "red" }}
                  onChange={(e) => setLocation(e.target.value)}
                  defaultValue={location}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />
                <br />
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Add External Source If You Found This Info Somewhere Online.
                  This is Optional - Start With https://....
                </Typography>
                <TextField
                  variant="standard"
                  id="standard-basic"
                  label={"https://..."}
                  required
                  size="small"
                  style={{ width: "100%", fontSize: 4, color: "red" }}
                  onChange={(e) => setUrl(e.target.value)}
                  defaultValue={url}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="fourth">
              <StepLabel> Change Your Alert's Image - (Optional)</StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  // className={classes.postHead}
                >
                  <Typography
                    gutterBottom
                    variant="body1"
                    className={classes.postHead}
                  >
                    Upload your images below. Old ones are removed
                  </Typography>
                </Typography>{" "}
                {/* <br /> */}
                {uploadLimit ? (
                  <div>
                    <MuiAlert
                      severity="warning"
                      onClose={closeError}
                      action={
                        <>
                          <CancelIcon onClick={closeError} />
                        </>
                      }
                    >
                      Maximum size allowed is 300 mb
                    </MuiAlert>
                    {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                    <br />
                    <a
                      href="https://www.freeconvert.com/video-compressor"
                      target="_blank"
                      className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block   mr-4 px-4 py-2 rounded shado text-white"
                    >
                      {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                      <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                        Quickly compress it
                      </span>{" "}
                    </a>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {!alertImageUrls.length == 0 ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        Slide to view all
                      </Typography>

                      {/* <Zoom scale={1.4} arrows={false} indicators={true} duration='2000'> */}
                      <Carousel>
              {alertImageUrls.map((slideImage, index) => (
                
                <img
                  src={slideImage}
                  key={index}
                  style={{ zIndex: -10 }}
                  alt=""
                />
              ))}
            </Carousel>
                          {/* {alertImageUrls.map((slideImage, index) => (
                            // <div className="each-slide" key={index}>
                              <img src={slideImage} key={index} style={{zIndex: -10}} alt="" />
                            
                            // </div>
                          ))}
                        </Zoom> */}
                        <br/>

                      {/* <div data-uk-slider="finite: true center: true autoplay: true">
                        <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
                          {alertImageUrls.map((imageSrc) => (
                            <li style={{ marginRight: 10 }}>
                              <img src={imageSrc} alt="" />
                            </li>
                          ))}
                        </ul>
                      </div> */}

                      <Button
                        // onClick={() => setAlertImageUrls([])}
                        onClick={clearImages}
                        // className={classes.button}
                      >
                        Clear Images
                      </Button>
                      {/* {/* <br /> */}
                    </>
                  ) : (
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            {/* {/* <input type="file" name="file" id="file" multiple className="input-file" onChange={showImages} /> */}

                            <input
                              type="file"
                              name="file"
                              accept="image/*"
                              multiple
                              id="file"
                              className="input-file"
                              // onChange={validateImage}
                              onChange={(e) => {
                                // if (e.target.files.length > 4) {
                                //   setIsLimit(true);
                                // } else {
                                  // setImages([...e.target.files]);
                                  console.log([...e.target.files]);
                                  validateImage(e.target.files);
                                  onChange3(e);
                                // }
                              }}
                            />
                            <label
                              htmlFor="file"
                              className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                            >
                              <i className="ti-cloud-down large-icon me-3 d-block"></i>
                              <span className="js-fileName">
                                Drag and drop or click to add your media file
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isLimit ? (
                    <div>
                      <Alert
                        severity="warning"
                        onClose={handleClose}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        Maximum upload is 4 images, kindly reduce.
                      </Alert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                </div>
                {/* </form> */}
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="fifth">
              <StepLabel> Last Step - (Optional) </StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Add or update your alert video
                </Typography>
                <br />
             
                <div>
                      <>
                        {videoUrl ? (
                          <>
                            <br />
                            <Typography
                              gutterBottom
                              variant="body1"
                              className={classes.postHead}
                            >
                              Your alert's video
                            </Typography>

                            <br />
                            <ReactPlayer
                              controls={true}
                              // width='70%'
                              height="400px"
                              url={videoUrl}
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                            />
                            <br />
                            <br />
                            {creatingAlert ? (
                              <Button disabled>{creatingMessage}</Button>
                            ) : (
                              <Button
                                onClick={
                                  () => {
                                    setVideoUrl("");
                                    setShowLive(false);
                                    setShowUrl(false);
                                  }
                                  //   this.setState({ profile_pic: "" })
                                }
                              >
                                Clear
                              </Button>
                            )}

                            <br />
                          </>
                        ) : (
                          <>
                            {video ? (
                              <>
                                <br />
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  className={classes.postHead}
                                >
                                  Your alert's video
                                </Typography>

                                <br />
                                <video width="400" controls>
                                  <source src={URL.createObjectURL(video)} />
                                </video>
                                <br />
                                <br />
                                {creatingAlert ? (
                                  <Button disabled>{creatingMessage}</Button>
                                ) : (
                                  <Button
                                    onClick={
                                      () => {
                                        setVideo("");
                                        setShowLive(false);
                                        setShowUrl(false);
                                      }
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Remove
                                  </Button>
                                )}

                                <br />
                              </>
                            ) : (
                              <>
                                {showUrl ? (
                                  <>
                                    <br />
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      // className={classes.postHead}
                                    >
                                      You can upload a video from a url. If you
                                      wish to record a live video, use the
                                      record live video button instead
                                    </Typography>
                                    <br />
                                    <TextField
                                      //autoFocus
                                      margin="dense"
                                      id="name"
                                      label={`Your video's url`}
                                      value={videoUrl}
                                      // { window.localStorage.getItem("postVideo")}
                                      onChange={(e) => {
                                        // window.localStorage.setItem(
                                        //   "postVideo",
                                        //   e.target.value
                                        // )
                                        setVideoUrl(e.target.value);
                                      }}
                                      type="text"
                                      fullWidth
                                      variant="standard"
                                      style={{ width: "100%" }}
                                    />
                                    <br />
                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Cancel
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    {showLive ? (
                                      <>
                                        <br />
                                        <Typography
                                          gutterBottom
                                          variant="body1"
                                          // className={classes.postHead}
                                        >
                                          You can take a live recording using
                                          either of your front or back camera.
                                          If you have a video recorded already,
                                          use the upload video button instead. Press "Rec" when ready and wait for the countdown
                                        </Typography>
                                        <br />
                                        <VideoRecorder
                                          onRecordingComplete={(videoBlob) => {
                                            // Do something with the video...
                                            console.log("videoBlob", videoBlob);
                                            setVideo(videoBlob);
                                          }}
                                        />
                                        <br />
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-12 mb-1">
                                          <div className="card mt-1 border-0">
                                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                              <div className="form-group mb-0 w-100">
                                                <input
                                                  type="file"
                                                  name="file3"
                                                  id="file3"
                                                  accept="video/*"
                                                  className="input-file"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.files[0].size >
                                                      300000 * 1028
                                                    ) {
                                                      setUploadLimit(true);
                                                    } else {
                                                      setUploadLimit(false);
                                                      setVideo(
                                                        e.target.files[0]
                                                      );
                                                    }
                                                  }}
                                                  // onChange={(e) => setVideo(e.target.files[0])}
                                                />
                                                <label
                                                  htmlFor="file3"
                                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                                >
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">
                                                    Click to replace your alert's
                                                    video
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <br /> */}
                                        {/* <br /> */}
                                      </>
                                    )}

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleLiveVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Record Live Video
                                        </Button>
                                        <br />
                                      </label>
                                    </span>

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Upload Video Url
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                <div>
                  <div>
                    <br />

                    <Button
                      //  disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    {isUpdating ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled
                        >
                          Updating Your Alert
                        </Button>
                        <h4>{creatingMessage}</h4>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={validate}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1
                            ? "Update"
                            : "Update"}
                        </Button>
                      </>
                    )}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={validate}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Update" : "Update"}
                    </Button> */}
                  </div>
                </div>
              </StepContent>
            </Step>
          </Stepper>

          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    {/* <a
                                onClick={handleBack}
                                className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                Go Back
                              </a> */}
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue updating your alert. Kindly try again. If
                this continues, kindly login again [ {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          {processed ? (
            <Paper square elevation={0} className={classes.resetContainer}>
              <br />
              <Typography>
                {" "}
                Your alert has been updated! - Invite your friends and family
                with your referral link so they can benefit from this! You also
                get free sms points when anyone signs up with your link. Go to
                your profile to get your referral link now.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                // onClick={() => setOpen(false)}
                // onClick={handleClose}
                onClick={() => handleClose() }
              >
                Go Back Home 2
              </Button>

              <br />
            </Paper>
          ) : (
            ""
          )}
          {processed ? (
                  <>
                    <Snackbar
                      open={processed}
                      autoHideDuration={4000}
                      onClose={handleClose2}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Alert onClose={handleClose2} severity="success">
                        Alert successfully updated!
                      </Alert>
                    </Snackbar>
                  </>
                ) : (
                  ""
                )}

          {/* <Box sx={{ maxWidth: 400 }}>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>
                  All steps completed - you&apos;re finished
                </Typography>
                <Button onClick={handleReset}className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )}
          </Box> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {isUpdating ? (
            <>
              <Button disabled>Updating Your Alert</Button>
              <h4>{creatingMessage}</h4>
            </>
          ) : (
            <>
              <Button onClick={validate}>Update Now</Button>
            </>
          )}
          {/* <Button onClick={validate}>Create</Button> */}
        </DialogActions>
        {emptyFields ? (
          <MuiAlert onClose={handleClose2} severity="warning">
            Oops - Headline, body and category can not be empty. Kindly update
          </MuiAlert>
        ) : (
          ""
        )}
      </Dialog>{" "}
    </>
  );
}
