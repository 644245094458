import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import MuiAlert from "@material-ui/lab/Alert";
import CreatePost2 from "./CreatePost2";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import MovieGrid from "../moviesComponents/movie-grid/MovieGrid";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import CancelIcon from "@mui/icons-material/Cancel";
import Chip from "@material-ui/core/Chip";
import Resizer from "react-image-file-resizer";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3, CompleteMultipartUploadCommand, AbortMultipartUploadCommand   } from "@aws-sdk/client-s3";
import Checkbox from "@mui/material/Checkbox";
import ReactPlayer from "react-player";

const steps = [
  {
    label: "Select campaign settings",
  },
  {
    label: "Create an ad group",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
  {
    label: "Create an ad",
  },
];
const options = [
    { value: 'comedy', label: 'Comedy' },
    { value: 'Action', label: 'Action' },
  ]

export default function ChannelsSeriesEdit(props) {
  const [emptyFields, setEmptyFields] = useState(false);
  const [status, setStatus] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [movieTitle, setMovieTitle] = useState(props.title);
  const [tags, setTags] = useState([]);
  const [overview, setOverview] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [image, setImage] = useState("");
  const [coverImageFe, setCoverImageFe] = useState("");
  const [imageFe, setImageFe] = useState("");
  const [pricing, setPricing] = useState('');
  const [price, setPrice] = useState('');
 
  const [trailer, setTrailer] = useState("");
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadingTrailer, setUploadingTrailer] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(false);
  const [uploadedTrailer, setUploadedTrailer] = useState(false);
  const [videoUploadLimit, setVideoUploadLimit] = useState(false);
  const [trailerUploadLimit, setTrailerUploadLimit] = useState(false);
  // const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");

  const animatedComponents = makeAnimated();
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);

  const [activeStep, setActiveStep] = React.useState(0);

  const [videoUrl, setVideoUrl] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [checkedT, setCheckedT] = React.useState(false);
  const [useVideoUrl, setUseVideoUrl] = useState(false);
  const [useTrailerUrl, setUseTrailerUrl] = useState(false);
  const [isNews, setIsNews] = useState("");


  const statusSelect = [
    { value: "released", label: "Released" },
    { value: "draft", label: "Draft" },
  ];
  const pricingSelect = [
    { value: "free", label: "Free" },
    { value: "subscription", label: "Subscribers Only" },
    { value: "paid", label: "Paid" },
  ];

  const toogleVideoType = (event) => {
    if (useVideoUrl == false) {
      setUseVideoUrl(true);
      setVideoUrl("");
    } else {
      setUseVideoUrl(false);
    }
    setChecked(event.target.checked);
  };

  const toogleTrailerType = (event) => {
    if (useTrailerUrl == false) {
      setUseTrailerUrl(true);
      setTrailerUrl("");
    } else {
      setUseTrailerUrl(false);
    }
    setCheckedT(event.target.checked);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  function closeError() {
    setError(false);
    setTrailerUploadLimit(false)
    setVideoUploadLimit(false)
    console.log("closed");
  }
  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setEmptyFields(false);
  };

  function ChangeTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) =>
      tags.push(tag.value)
    );
    console.log(`name ${tags}`);
    
    setSelectedTags([...new Set(tags)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  async function validate() {
    console.log(`tags ${tags}`);
    console.log(`title ${movieTitle}`);
    console.log(`image ${JSON.stringify(image)}`);
    console.log(`cover ${JSON.stringify(coverImage)}`);
    console.log(`overview ${overview}`);

    if (!movieTitle) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      // uploadVideo()
      update();
    }
  }
  function changePricing(value) {
    console.log(`selected ${JSON.stringify(value.value)}`);
    setPricing(value.value);
  }
  function changeStatus(value) {
    console.log(`selected ${JSON.stringify(value.value)}`);
    setStatus(value.value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  function selectChange(value) {
    console.log(`selected ${value}`);
    setStatus(value);
  }

  
  function clearImage(value) {
    setImage('');
    setImageFe('');
  }
  function clearCoverImage(value) {
    setCoverImage('');
    setCoverImageFe('');
  }

  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      90,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setImage(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const CompressImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  const resizeCoverFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1080,
      1080,
      "WEBP",
      90,
      0,
      (uri) => {
        resolve(uri);
        console.log(uri)
        setCoverImage(uri)
      },
      "file",
      1080,
      1080
    );
  });

  const compressCoverImage = async (event) => {
    try {
      const file = event.target.files[0];
      console.log(file)
      const image = await resizeCoverFile(file);
    //   console.log(image);
    } catch (err) {
      console.log(err);
    }
  };

  async function uploadVideo() {
    // if (video) {
    setCreatingMessage("Video Detected, Uploading First");
    console.log("video is nooot empty");
    setIsCreating(true);
    setUploadingVideo(true);

    // console.log(e.target.file[0]);
    // setVideo(e.target.files[0]);
    console.log("using youtube mutipart");
    console.log(video);

    const creds = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };

    const client = new S3Client({ region: "us-east-1", credentials: creds });
    const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
    const command = new CompleteMultipartUploadCommand(params);

    const user_id = window.localStorage.getItem("user_id");
    var filePath = "series/videos/" + user_id + "/" + video.name;
    // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
    const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

    setCreatingMessage(
      "Uploading your video. This may take a quick minute or two"
    );
    const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: "us-east-1", credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on("httpUploadProgress", (progress) => {
        console.log(progress);
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        console.log("status is" + uploaded);
        console.log("upload tyep is " + typeof uploaded);
        setVideoPercent(uploaded);
        setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
        setFileUrl(finalUrl);

        window.localStorage.setItem("video", finalUrl);
        // setVideoUrl(finalUrl);
        // videoUrl = fileUrl
        if (uploaded == 100) {
          setCreatingMessage(`Uploaded Your Video Clip. You can proceeed`);
          setUploadedVideo(true);
          setUploadingVideo(false);
          setIsCreating(false);
        }
      });

      const done = parallelUploads3.done();
      console.log("done 3 " + JSON.stringify(done));
      // if(videoPercent == '100'){
      //   setVideoUrl(fileUrl)
      //   createVideo()
      // }
    } catch (e) {
      console.log(e);
    }
  }

  async function uploadTrailer() {
    // if (video) {
    setCreatingMessage("Video Detected, Uploading First");
    console.log("video is nooot empty");
    setIsCreating(true);
    setUploadingTrailer(true);

    // console.log(e.target.file[0]);
    // setVideo(e.target.files[0]);
    console.log("using youtube mutipart");
    console.log(video);

    const creds = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };

    const client = new S3Client({ region: "us-east-1", credentials: creds });
    const params = { Bucket: "alert-app-v2", Key: trailer.name, Body: trailer };
    const command = new CompleteMultipartUploadCommand(params);

    const user_id = window.localStorage.getItem("user_id");
    var filePath = "series/trailer/" + user_id + "/" + trailer.name;
    // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
    const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

    setCreatingMessage(
      "Uploading your trailer. This may take a quick minute or two"
    );
    const target = { Bucket: "alert-app-v2", Key: filePath, Body: trailer };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({ region: "us-east-1", credentials: creds }),
        leavePartsOnError: false, // optional manually handle dropped parts
        params: target,
      });

      parallelUploads3.on("httpUploadProgress", (progress) => {
        console.log(progress);
        var uploaded = parseInt((progress.loaded * 100) / progress.total);
        console.log("status is" + uploaded);
        console.log("upload tyep is " + typeof uploaded);
        setVideoPercent(uploaded);
        setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
        setFileUrl(finalUrl);
        window.localStorage.setItem("trailer", finalUrl);
        // setVideoUrl(finalUrl);
        // videoUrl = fileUrl
        if (uploaded == 100) {
          setUploadedTrailer(true);
          setUploadingTrailer(false);
          setIsCreating(false);
          setCreatingMessage(`Uploaded Your Trailer. You can proceed`);
        }
      });

      const done = parallelUploads3.done();
      console.log("done 3 " + JSON.stringify(done));
      // if(videoPercent == '100'){
      //   setVideoUrl(fileUrl)
      //   createVideo()
      // }
    } catch (e) {
      console.log(e);
    }

    // setCreatingPost(false)
    // }
  }

  async function update() {
    setIsCreating(true);
    setCreatingMessage('Updating Your Series, almost done')
    const postVideoUrl = window.localStorage.getItem("video");
    const postTrailerUrl = window.localStorage.getItem("trailer");

    // {pricing != 'paid' && setPrice('')}
    if(pricing !== 'paid'){
      console.log('not paid')
      setPrice('')
    }
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("channel_id", props.channelId);
    formData.append("title", movieTitle);
    formData.append("tags", tags);
    formData.append("overview", overview);
    formData.append("image", image);
    formData.append("cover_image", coverImage);
    formData.append("pricing", pricing);
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }
    if (postTrailerUrl) {
      formData.append("trailer", postTrailerUrl);
    }
    if (trailerUrl) {
      formData.append("trailer", trailerUrl);
    }
    formData.append("price", price);
    formData.append("status", status);

    axiosFetch
      .post(
        `/movie/update/series/${props.seriesId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(`hi ${resp.data}`);
        if (resp.data.status == "success") {
          window.location.reload();
        setMovieTitle("");
        setTags("");
        setOverview("");
        setImage("");
        setCoverImage("");
        setImageFe("");
        setCoverImageFe("");
        setPrice("");
        setPricing("");
       
           setTrailer("");
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("video");
          localStorage.removeItem("trailer");

        setIsCreating(false);
        // window.location.reload(true);
        props.functionProp();
        setOpen(false);
     
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      } else {
        setError(true);
        setErrorMessage(resp.data.message);
        setIsCreating(false);
      }
    })
    .catch((error) => {
      setIsCreating(false);
      console.log(error);
      setError(true);
      setErrorMessage(error.message);
    });
  }

  return (
    <>
    {/* {console.log(JSON.stringify(props.movie))} */}
      {/* <a
        onClick={handleClickOpen}
        style={{ marginLeft: 85 }}
        className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
      >
        Edit
      </a> */}
      <Tooltip
       className="d-inline-block"
        title={`Edit your ${props.type}`}
        onClick={handleClickOpen}
      >
        <IconButton>
          <ModeEditOutlineOutlinedIcon
          //  className="btn-outline"
          className="d-inline-block"
            onClick={() => console.log("video")}
            style={{
              color: props.color,
              fontSize: 27,
              // marginLeft: 100,
              // marginRight: 20,
            }}
          />{" "}
        </IconButton>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle> Update {props.title} </DialogTitle>
          <DialogContent>
            <DialogContentText> Skip The Step You Don't Want To Update. You don't need to update all, just edit the info you need to change and click update</DialogContentText>

            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ width: "100vw" }}
            >
              <Step key="first">
                <StepLabel> update {props.type} info</StepLabel>
                <StepContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ width: "100%" }}
                  >
                    update {props.type} title
                  </Typography>

                  <div>
                    <TextField
                      //autoFocus
                      margin="dense"
                      id="name"
                      label={`update ${props.type} title`}
                      defaultValue={props.title}
                      onChange={(e) => setMovieTitle(e.target.value)}
                      type="text"
                      fullWidth
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                    <br /> <br />
                    <textarea
                      onChange={(e) => setOverview(e.target.value)}
                      defaultValue={props.overview}
                      name="message"
                      className="h100 bor-0 w-100 rounded-xxl p-2 ps-5 font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                      cols="30"
                      rows="10"
                      placeholder={`update ${props.type} description or more info`}
                    ></textarea>
              
                  </div>

                  <div>
                    <div>
                      <br />
                      <Button
                        onClick={handleClose}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="second">
                <StepLabel> More Info - Tags and Pricing</StepLabel>
                <StepContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ width: "100%" }}
                  >
                    Type to Update {props.type} genre / tags
                  </Typography>
                  <br />
                  <p> Previous tags: </p> 
                  {props.tags.map((tag) => (
                    <Chip
                      style={{ marginRight: 10, marginBottom: 5 }}
                      className="infotag pointer"
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={`${tag.name}`}
                    />
                  ))} 
                  <div>
                    <CreatableSelect
                      isClearable
                      isMulti
                      onChange={ChangeTag}
                      options={props.tags}
                      components={animatedComponents}
                      options={options}
                    ></CreatableSelect>
                    <br/>
                  
                  <p> You've selected </p>
                  {selectedTags.map((selectedTags) => (
                    <Chip
                      style={{ marginRight: 10, marginBottom: 5 }}
                      className="infotag pointer"
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={`${selectedTags}`}
                    />
                  ))}
                    <br />
                    <p> Select published status. Previously set to {props.status} </p>
                    <Select
                      // isClearable
                      components={animatedComponents}
                      defaultValue={props.status}
                      options={statusSelect}
                      onChange={changeStatus}
                      defaultInputValue={props.status}
                    />

                    <br />

                    <p> Select pricing. Previously set to {props.pricing} </p>
                    <Select
                      // isClearable
                      components={animatedComponents}
                      defaultValue={props.pricing}
                      options={pricingSelect}
                      onChange={changePricing}
                      defaultInputValue={props.pricing}
                    />
                    <br/>

                    {props.price == null ?  
                    <>        
                    {pricing == 'paid' ?  <TextField
                      //autoFocus
                      margin="dense"
                      id="name"
                      label={`Enter your video's price`}
                      // defaultValue={props.price}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      type="text"
                      fullWidth
                      variant="standard"
                      style={{ width: "100%" }}
                    /> : ''}
                    </>
                    :
                    <>
                    <TextField
                      //autoFocus
                      margin="dense"
                      id="name"
                      label={`Update your video's price`}
                      defaultValue={props.price}
                      // value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      type="text"
                      fullWidth
                      variant="standard"
                      style={{ width: "100%" }}
                    /> 
                    </>
                    }
                  </div>
                  <div>
                    <div>
                      <br />
                      <Button
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="third">
                <StepLabel> Update your {props.type} pictures </StepLabel>
                <StepContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    style={{ width: "100%" }}
                  >
                    This is your {props.type} main picture
                  </Typography>{" "}
                 
                  <div>
                    {imageFe ? (
                      <>
                        <br />
                        <p> Your {props.type} image </p>
                        <br />
                        <img
                          // src={props.image}
                          src={URL.createObjectURL(imageFe)}
                          style={{
                            maxWidth: "100%",
                            maxHeight: 450,
                            objectFit: "contain",
                          }}
                          // style={{ maxWidth: "100%", maxHeight: 400 }}
                          alt="profile image"
                        />
                        <br />
                        <br />
                        <Button
                            onClick={
                              clearImage
                            }
                        >
                          Remove
                        </Button>
                        <br />
                      </>
                    ) : (
                      <div className="col-lg-12 mb-1">
                        <div className="card mt-1 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              <input
                                type="file"
                                name="file3"
                                id="file3"
                                accept="image/*"
                                className="input-file"
                                onChange={(e) => {setImageFe(e.target.files[0])
                                  CompressImage(e)
                                }}
                              />
                              <label
                                htmlFor="file3"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                  Click to replace your{" "}
                                  {props.type} cover picture
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <Typography
                      gutterBottom
                      variant="body1"
                      style={{ width: "100%" }}
                      // className={classes.postHead}
                    >
                      Update your {props.type} backdrop picture
                    </Typography>{" "}
                    {coverImageFe ? (
                      <>
                        <br />
                        <p> Your {props.type} brackdrop image </p>
                        <br />
                        <img
                          //    src={props.cover_image}
                          src={URL.createObjectURL(coverImageFe)}
                          style={{
                            maxWidth: "100%",
                            maxHeight: 450,
                            objectFit: "contain",
                          }}
                          // style={{ maxWidth: "100%", maxHeight: 400 }}
                          alt="profile image"
                        />
                        <br />
                        <br />
                        <Button
                           onClick={
                            clearCoverImage
                          }
                        >
                          Remove
                        </Button>
                        <br />
                      </>
                    ) : (
                      <div className="col-lg-12 mb-1">
                        <div className="card mt-1 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              <input
                                type="file"
                                name="file2"
                                id="file2"
                                accept="image/*"
                                className="input-file"
                                onChange={(e) => {
                                  setCoverImageFe(e.target.files[0])
                                  compressCoverImage(e)
                                }
                                }
                              />
                              <label
                                htmlFor="file2"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                 Click to replace your{" "}
                                  {props.type} backdrop picture
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* </form> */}
                  <div>
                    <div>
                      <br />
                      <Button
                        // disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        // className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Alert All" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="fourth">
                <StepLabel> Upload your {props.type} first trailer (Optional)</StepLabel>
                <StepContent>
                  <Typography
                    gutterBottom
                    variant="body1"
                    // className={classes.postHead}
                  >
                    This is a short teaser of your series. It's free to
                    watched by all
                  </Typography>{" "}
                  {/* <br /> */}
                  <div>
                  <div>
                      {video ? (
                        <>
                          <br />
                          <p> Your series first trailer </p>
                          <br />
                          <video width="400" controls>
                            <source src={URL.createObjectURL(video)} />
                          </video>
                          <br />
                          <br />
                          <br />
                          {uploadedVideo ? (
                            <div>
                              <MuiAlert
                                severity="success"
                                // onClose={handleClose2}
                              >
                                {creatingMessage}
                              </MuiAlert>
                              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                              <br />
                            </div>
                          ) : (
                            <>
                              {isCreating ? (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    // onClick={handleNext}
                                    disabled
                                    // className={classes.button}
                                  >
                                    Uploading
                                    {/* Creating Your Alert */}
                                    {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                                  </Button>
                                  <br />
                                  <p>{creatingMessage}</p>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={
                                      () => uploadVideo()
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Upload Video
                                  </Button>
                                  <Typography
                                    gutterBottom
                                    variant="overline"
                                    style={{paddingLeft: 20}}
                                    // className={classes.postHead}
                                  >
                                    Upload this clip now to continue
                                  </Typography>{" "}
                                  <br />
                                  <br />
                                  <Button
                                    onClick={
                                      () => setVideo("")
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Remove
                                  </Button>
                                </>
                              )}

                              <br />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                        {useVideoUrl ? (
                          <>
                            <Typography
                              gutterBottom
                              variant="overline"
                              // className={classes.postHead}
                            >
                              You can upload your video file or add its url to
                              save time uploading
                            </Typography>

                            <div className="col-lg-12 mb-1">
                          <div className="card mt-1 border-0">
                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                              <div className="form-group mb-0 w-100">
                                <input
                                  type="file"
                                  name="file3"
                                  id="file3"
                                  accept="video/*"
                                  className="input-file"
                                  onChange={(e) => {
                                    if (e.target.files[0].size > 300000 * 1028) {
                                      setVideoUploadLimit(true);
                                    } else {
                                      setVideoUrl("");
                                      setVideoUploadLimit(false);
                                      setVideo(e.target.files[0]);
                                    }
                                  }}
                                />
                                <label
                                  htmlFor="file3"
                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                >
                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                  <span className="js-fileName">
                                  Click to add or replace your{" "}
                                    series trailer
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                            <br />
                            <Checkbox
                              checked={checked}
                              onChange={toogleVideoType}
                              label="Yes"
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            <Typography
                              gutterBottom
                              variant="overline"
                              // className={classes.postHead}
                            >
                              Use Url Instead
                            </Typography>
                          </>
                        ) : (
                          <>
                            <div>
                              <Typography
                                gutterBottom
                                variant="overline"
                                // className={classes.postHead}
                              >
                                You can upload your video file or add its url
                                to save time uploading
                              </Typography>

                              <TextField
                                //autoFocus
                                margin="dense"
                                id="name"
                                label={`Your video's url`}
                                value={videoUrl}
                                onChange={(e) => setVideoUrl(e.target.value)}
                                type="text"
                                fullWidth
                                variant="standard"
                                style={{ width: "100%" }}
                              />
                              <br />
                              <Checkbox
                                checked={checked}
                                onChange={toogleVideoType}
                                label="Yes"
                                inputProps={{ "aria-label": "controlled" }}
                              />
                              <Typography
                                gutterBottom
                                variant="overline"
                                // className={classes.postHead}
                              >
                                Upload File Instead
                              </Typography>
                            </div>
                          </>
                        )}
                      </>
                       
                      )}

{videoUrl ? (
                        <>
                          <ReactPlayer
                            controls={true}
                            // width='70%'
                            height="400px"
                            url={videoUrl}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                  {/* </form> */}
                  <div>
                  {videoUploadLimit ? (
                      <div>
                        <MuiAlert
                          severity="warning"
                          onClose={closeError}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum size allowed is 300 mb
                        </MuiAlert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                        <a
                          href="https://www.freeconvert.com/video-compressor"
                          target="_blank"
                          className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                        >
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Quickly compress your video here
                          </span>{" "}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <br />
                      <Button
                        // disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        // className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Next" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="fifth">
                <StepLabel>
                  {" "}
                  Upload your {props.type} second trailer (Optional)
                </StepLabel>
                <StepContent>
                  <div>
                    <div>
                      <Typography
                        gutterBottom
                        variant="body1"
                        // className={classes.postHead}
                      >
                        This is a short teaser of your {props.type}. Its free to
                        watch by all
                      </Typography>{" "}
                      <div>
                          {trailer ? (
                            <>
                              <br />
                              <p> Your Series Second Trailer </p>
                              <br />
                              <video width="400" controls>
                                <source src={URL.createObjectURL(trailer)} />
                              </video>
                              <br />
                              <br />
                              <br />
                              {uploadedTrailer ? (
                                <div>
                                  <MuiAlert
                                    severity="success"
                                    // onClose={handleClose2}
                                  >
                                    {creatingMessage}
                                  </MuiAlert>
                                  {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                                  <br />
                                </div>
                              ) : (
                                <>
                                  {isCreating ? (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        // onClick={handleNext}
                                        disabled
                                        // className={classes.button}
                                      >
                                        Uploading
                                        {/* Creating Your Alert */}
                                        {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                                      </Button>
                                      <br />
                                      <p>{creatingMessage}</p>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                          () => uploadTrailer()
                                          //   this.setState({ profile_pic: "" })
                                        }
                                      >
                                        Upload Trailer
                                      </Button>
                                      <Typography
                                    gutterBottom
                                    variant="overline"
                                    style={{paddingLeft: 20}}
                                    // className={classes.postHead}
                                  >
                                    Upload this clip now to continue
                                  </Typography>{" "}
                                      <br />
                                      <br />
                                      <Button
                                        onClick={
                                          () => setTrailer("")
                                          //   this.setState({ profile_pic: "" })
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </>
                                  )}

                                  <br />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                            {useTrailerUrl ? (
                              <>
                                <Typography
                                  gutterBottom
                                  variant="overline"
                                  // className={classes.postHead}
                                >
                                  You can upload your video file or add its
                                  url to save time uploading
                                </Typography>

                                <div className="col-lg-12 mb-1">
                              <div className="card mt-1 border-0">
                                <div className="card-body d-flex justify-content-between align-items-end p-0">
                                  <div className="form-group mb-0 w-100">
                                    <input
                                      type="file"
                                      name="file2"
                                      id="file2"
                                      accept="video/*"
                                      className="input-file"
                                      onChange={(e) => {
                                        if (
                                          e.target.files[0].size >
                                          300000 * 1028
                                        ) {
                                          setTrailerUploadLimit(true);
                                        } else {
                                          setTrailerUrl("");
                                          setTrailerUploadLimit(false);
                                          setTrailer(e.target.files[0]);
                                        }
                                      }}
                                    />
                                    <label
                                      htmlFor="file2"
                                      className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                    >
                                      <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                      <span className="js-fileName">
                                      Click to add or replace your{" "}
                                    series second trailer
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                                <br />
                                <Checkbox
                                  checked={checkedT}
                                  onChange={toogleTrailerType}
                                  label="Yes"
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                                <Typography
                                  gutterBottom
                                  variant="overline"
                                  // className={classes.postHead}
                                >
                                  Use Url Instead
                                </Typography>
                              </>
                            ) : (
                              <>
                                <div>
                                  <Typography
                                    gutterBottom
                                    variant="overline"
                                    // className={classes.postHead}
                                  >
                                    You can upload your video file or add its
                                    url to save time uploading
                                  </Typography>

                                  <TextField
                                    //autoFocus
                                    margin="dense"
                                    id="name"
                                    label={`Your video's url`}
                                    value={trailerUrl}
                                    onChange={(e) =>
                                      setTrailerUrl(e.target.value)
                                    }
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    style={{ width: "100%" }}
                                  />
                                  <br />
                                  <Checkbox
                                    checked={checkedT}
                                    onChange={toogleTrailerType}
                                    label="Yes"
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                  <Typography
                                    gutterBottom
                                    variant="overline"
                                    // className={classes.postHead}
                                  >
                                    Upload File Instead
                                  </Typography>
                                </div>
                              </>
                            )}
                          </>
                         
                          )}

{trailerUrl ? (
                            <ReactPlayer
                              controls={true}
                              // width='70%'
                              height="400px"
                              url={trailerUrl}
                              config={{
                                file: {
                                  attributes: {
                                    controlsList: "nodownload",
                                  },
                                },
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </div>

                    </div>
                  </div>
                  {/* </form> */}
                  <div>
                  {trailerUploadLimit ? (
                      <div>
                        <MuiAlert
                          severity="warning"
                          onClose={closeError}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum size allowed is 300 mb
                        </MuiAlert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                        <a
                          href="https://www.freeconvert.com/video-compressor"
                          target="_blank"
                          className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                        >
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Quickly compress your trailer here
                          </span>{" "}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                    <div>
                      <br />
                      <Button
                        // disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      {isCreating ? (
                        <>
                          <Button
                            variant="contained"
                            color="primary"
                            disabled
                          >
                            Just A Sec
                            
                          </Button>
                          <br />
                          <p>{creatingMessage}</p>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={update}
                        >
                          {activeStep === steps.length - 1
                            ? "Update"
                            : "Update"}
                        </Button>
                      )}
                    </div>
                  </div>
                </StepContent>
              </Step>
            </Stepper>

            <Box sx={{ maxWidth: 400 }}>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                    Reset
                  </Button>
                </Paper>
              )}
            </Box>

            {/* {props.title} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {isCreating ? (
              <Button disabled>Just A Sec</Button>
            ) : (
              <>
              <Button onClick={update}>Update</Button>
              <br/>
              <p> {creatingMessage} </p>
              </>
              // <Button onClick={console.log(`hi ${movieTitle}`)}>Update</Button>
            )}
            {/* <Button onClick={validate}>Create</Button> */}
          </DialogActions>

          {error ? (
              <div>
                <MuiAlert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <a
                        onClick={handleBack}
                        className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                      >
                        Go Back
                      </a>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue creating your video. Kindly try again [{" "}
                  {errorMessage} ]
                </MuiAlert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
              </div>
            ) : (
              ""
            )}

          {emptyFields ? (
            <MuiAlert onClose={handleClose2} severity="warning">
              Title can not be empty
            </MuiAlert>
          ) : (
            ""
          )}
        </Dialog>{" "}
      </Box>
    </>
  );
}
