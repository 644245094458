import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "./Load";
import "./story.css";
import ProfileTabs from "./ProfileTabs";
import ProfileTabsAuth from "./ProfileTabsAuth";
import ProfileTabsAuthOld from "./ProfileTabsAuthOld";

import { Grid, makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from "@mui/material/Tooltip";
import MapsUgcOutlinedIcon from "@mui/icons-material/MapsUgcOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
// import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Snackbar from "@mui/material/Snackbar";
import ImageComponentDiv from "./ImageComponentDiv";
import ImageComponent from "./ImageComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import ReportModal from "./ReportModal";

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function ProfileCard(props) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [key, setKey] = useState("home");
  // const [userId, setUserId] = useState('');
  const userId = useState(window.localStorage.getItem("user_id"));
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const { user_name } = useParams(["user_name"]);
  const [userName, setUserName] = useState(useParams(["user_name"]));
  // const [userName, setUserName] = useState('')
  const [unFollowedUser, setUnFollowedUser] = useState(false);
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [posts, setPosts] = useState("");
  const [alerts, setAlerts] = useState("");

  const [details, setDetails] = useState([]);
  const classes = useStyles();
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [failedSms, setFailedSms] = useState(false);
  const [sentSms, setSentSms] = useState(false);
  const [points, setPoints] = useState("");
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [profile, setProfile] = useState([]);
  const [sms, setSms] = useState("");

  const [action, setAction] = useState("");
  const [friends, setFriends] = useState("");
  const [open, setOpen] = useState(false);
  const [addedFriend, setAddedFriend] = useState(false);
  const [removedFriend, setRemovedFriend] = useState(false);
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSentSms(false);
    setFailedSms(false);
    setAddedFriend(false);
    setRemovedFriend(false);
  };

  function redirect() {
    setAddedFriend(false);
    history.push("/closefriends");
  }

  async function getUserPoints() {
    // setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/sms/points`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setPoints(resp.data);
      })
      .catch((error) => {
        // setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getDetails() {
    setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/show/${user_name}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          const data = JSON.stringify(resp.data);
          setProfile(resp.data.user);
          setDetails(resp.data);
          setAction(resp.data.action);
          setFriends(resp.data.friend);
          setFollowers(resp.data.followers);
          setFollowing(resp.data.followings);
          setPosts(resp.data.posts);
          setAlerts(resp.data.alerts);
          // console.log(`user:data 2 = ${JSON.stringify(resp.data)}`)
          setFetchingProfile(false);
          // refreshToken()
          // checkUserId();
          //  console.log(` 2 ${fetchingProfile}`)
          if (userId[0] == resp.data.user.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        } else {
          setFetchingProfile(false);
          setError(true);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setFetchingProfile(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function refreshToken() {
    axios
      .post(`${process.env.REACT_APP_LIVE_URL}/oauth/token`, {
        grant_type: "refresh_token",
        refresh_token: window.localStorage.getItem("refresh_token"),
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
      })
      .then((response) => {
        {
          console.log(JSON.stringify(response));
        }
        window.localStorage.setItem("access_token", response.data.access_token);
        // console.log('new access in +'    window.localStorage.setItem("access_token", res.access_token)
        window.localStorage.setItem(
          "refresh_token",
          response.data.refresh_token
        );
        // window.location.reload()
        console.log("New Token Recieved from inner componet");
        return axiosFetch();
      })
      .catch((err) => {
        console.log("seems it has expired");
        return Promise.reject(err);
      });
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  // function updateUserName() {
  //   const newName = name.user_name;
  //   setUserName(newName)
  //   console.log(`hijhj ${name.user_name}`)

  // }

  function removeFriend(user_name) {
    // console.log(user_name);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/unfriend/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        setRemovedFriend(true);
        getDetails();
        getProfile();
        // getCloseFriends();
        console.log("added friend");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function addFriend(user_name) {
    // console.log(user_name);
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/befriend/${user_name}`, user_name, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setError(true);
          setErrorMessage(resp.data.message);
        } else {
          setAddedFriend(true);
          getDetails();
          getProfile();
          // getCloseFriends();
          console.log("added friend");
        }
      })
      .catch((error) => {
        // setAdding(false);
        // setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }

  function getProfile() {
    // setAddingMessage("Almost Done ...");
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        window.localStorage.setItem("friends", resp.data.friends);

        // setAdding(false);
        // setAddingMessage("Add Another Friend");
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function sendSms2() {
    console.log(sms);
  }

  async function sendSms() {
    setSending(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("message", message);

    axiosFetch
      .post(`/sms/${user_name}`, formData, {
        // method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if(resp.data.status == 'error'){
        //   setFailedSms(true)
        //   setErrorMessage(resp.data.message);
        //   console.log('you have been blocked')
        // }else{
        console.log(resp.data);
        setSentSms(true);
        getUserPoints();
        setMessage("");
        setSending(false);
        // setSms("");
        // setOpen(false);
        // }
      })
      .catch((error) => {
        if (error.response.status == 403) {
          setSending(false);
          setFailedSms(true);
          setErrorMessage(error.response.data.message);
          console.log(error);
        }
      });
  }

  async function toogleFollow() {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // const data2 = JSON.stringify(resp.data);
        getDetails();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  function checkUserId() {
    // console.log(`no 1 ${profile.id}`);
    // console.log(`no 2 ${userId}`);
    if (userId == profile.id) {
      console.log(`no 1 ${profile.id}`);
      console.log(`no 2 ${userId}`);
      console.log("is logged in user");
      return setIsLoggedInUser(true);
    } else {
      console.log("is another user");
      console.log(`no 3 ${profile.id}`);
      console.log(`no 4 ${userId}`);
      return setIsLoggedInUser(false);
    }
    // console.log(userId[0]);
    // console.log(JSON.stringify(profile.id));
    // console.log(`no 2 ${profile.id}`);
  }
  useEffect(() => {
    // updateUserName()
    // console.log(`user 1 ${userName.user_name}`)
    // console.log(`userName 2 ${user_name}`)

    getDetails();
    getUserPoints();
    // checkUserId();
  }, []);

  if (fetchingProfile == true) {
    return (
      <>
        <Load />
      </>
    );
  } else {
    return (
      // <h1> h1 </h1>
      <>
        {/* {checkUserId()} */}
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
          <Grid item className={classes.pc}>
            {/* <h1 style={{ backgroundColor: "red" }}> HELOo PC </h1> */}
            {profile.cover_pic === null ? (
              <div
                className="card-body position-relative h240 bg-image-cover bg-image-center "
                style={{
                  backgroundImage: `url("https://via.placeholder.com/1200x450.png")`,
                }}
              ></div>
            ) : (
              <ImageComponentDiv
                image={profile.cover_pic}
                class="card-body position-relative h240 bg-image-cover bg-image-center coverImg"
              />
              // <div
              //   className="card-body position-relative h240 bg-image-cover bg-image-center coverImg"
              //   style={{ backgroundImage: `url(${profile.cover_pic})` }}
              // ></div>
            )}
            <div className="card-body d-block pt-4 text-center position-relative">
              {profile.profile_pic === null ? (
                //    <h2>  hello </h2>
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                  <img
                    onError={fixImage}
                    src="/assets/images/user.png"
                    alt="Loading Profile Pic"
                    className="p-1 bg-white rounded-xl w-100"
                  />
                </figure>
              ) : (
                //    <h2>  hello 22 </h2>
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                  <ImageComponent
                    onError={fixImage}
                    image={profile.profile_pic}
                    class="p-1 bg-white rounded-xl w-100"
                  />
                  {/* <img
                    onError={fixImage}
                    src={profile.profile_pic}
                    alt="Loading Profile Pic"
                    className="p-1 bg-white rounded-xl w-100"
                  /> */}
                </figure>
              )}
              {/* {console.log(`hello ${first_name}`)} */}
              <h4 className="font-xs ls-1 fw-700 text-grey-900">
                {" "}
                {profile.first_name} {profile.last_name}{" "}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary">
                  @{profile.user_name}
                </span>
              </h4>
              {/* <h4 className="font-xs ls-1 fw-700 text-grey-900">{this.state.profile.first_name} {this.state.profile.last_name}    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary-500">@{user_name}</span></h4> */}
              <div className="d-flex align-items-center justify-content-center pt-0 position-absolute left-15 top-10 mt-4 ms-2">
                <Link>
                  <h4 className="font-xsssss text-center d-lg-block text-primary fw-600 ms-2 me-2">
                    {/* <h4 className="font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"> removed d-none */}
                    <b className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                      {alerts}
                    </b>{" "}
                    Alarrts
                  </h4>
                </Link>
                <Link>
                  <h4 className="font-xsssss text-center d-lg-block text-primary fw-600 ms-2 me-2">
                    {/* <h4 className="font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"> removed d-none */}
                    <b className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                      {posts}
                    </b>{" "}
                    Posts
                  </h4>
                </Link>
                <Link
                  to={{
                    pathname: `/${profile.user_name}/followers`,
                    state: { user_name: profile.user_name },
                  }}
                >
                  {/* <Link to={`/${profile.user_name}/followers`}> */}{" "}
                  <h4 className="font-xsssss text-center d-lg-block text-primary fw-600 ms-2 me-2">
                    <b className="text-primary-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                      {followers}{" "}
                    </b>{" "}
                    Followers
                  </h4>{" "}
                </Link>
                <Link
                  to={{
                    pathname: `/${profile.user_name}/following`,
                    state: { user_name: profile.user_name },
                  }}
                >
                  {/* <Link to={`/${profile.user_name}/following`}> */}{" "}
                  <h4 className="font-xsssss text-center d-lg-block text-primary fw-600 ms-2 me-2">
                    <b className="text-primary-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                      {following}{" "}
                    </b>{" "}
                    Following
                  </h4>{" "}
                </Link>
              </div>
              {/* <br/> */}
              {isLoggedInUser ? (
                <div className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-4 me-2">
                  {/* <a
                    href="/closefriends"
                    className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                  >
                    View Close Friends
                  </a> */}
                     <a
                    href="/sos-contacts"
                    className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                  >
                    View SOS Contacts
                  </a>
                  <Tooltip title="Edit Profile">
                    <a
                      href="/profileinformation"
                      className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                    >
                      <i className="feather-edit font-md"></i>
                    </a>
                  </Tooltip>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-4 me-2">
                  {profile.is_active == "false" ? (
                    <span className="text-danger ml-5">
                      USER IS DEACTIVATED
                    </span>
                  ) : (
                    <a
                      onClick={toogleFollow}
                      className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                    >
                      {action}
                    </a>
                  )}

                  <Tooltip title="Send SMS">
                    <a
                      onClick={handleClickOpen}
                      style={{ color: "#50C878" }}
                      className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-success-700"
                    >
                      <i className="feather-mail font-md"></i>
                    </a>
                  </Tooltip>

                  {/* Drop Down */}
                  <div>
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle>Send an sms to {user_name}</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Your points are used to send sms. Depending on your
                          friend's network provider, each sms costs a minimum of
                          4 points. (This only works with Nigerian Numbers At
                          The Moment)
                        </DialogContentText>
                        <br />
                        <h5> You currently have {points} points</h5>
                        <div className="col-lg-12 mb-3">
                          <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                            Type Your Message
                          </label>
                          <textarea
                            // className="fw-500 bg-greylight  text-black-900 lh-26 font-xssss w-100 mb-2"
                            className="form-control mb-0 p-3 h100 bg-greylight lh-20"
                            rows="5"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message here..."
                          ></textarea>
                        </div>
                        {/* <TextField
                          // autoFocus
                          // margin="dense"
                          // id="name"
                          label="Your Message"
                          type="text"
                          fullWidth
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          variant="standard"
                        /> */}
                      </DialogContent>
                      <br />
                      <br />
                      <br />

                      {sentSms ? (
                        <Alert
                          onClose={handleClose}
                          variant="filled"
                          severity="success"
                        >
                          Your sms has been sent to {user_name} successfully!
                        </Alert>
                      ) : (
                        ""
                      )}

                      {failedSms ? (
                        <Alert
                          onClose={handleClose}
                          variant="filled"
                          severity="error"
                        >
                          {errorMessage}
                        </Alert>
                      ) : (
                        ""
                      )}
                      <DialogActions>
                        <Button onClick={handleModalClose}>Close</Button>
                        {sending ? (
                          <Button disabled>Sending</Button>
                        ) : (
                          <Button onClick={sendSms}>Send SMS</Button>
                        )}
                      </DialogActions>
                    </Dialog>
                  </div>

                  {friends == "true" ? (
                    <Tooltip title="Remove From Close Friends">
                      <a
                        onClick={() => removeFriend(profile.user_name)}
                        // onClick={addFriend(profile.user_name)}
                        className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                      >
                        {/* <MapsUgcOutlinedIcon style={{fontSize: 30}}/> */}
                        <PersonRemoveIcon
                          style={{ fontSize: 28, color: "#50C878" }}
                          className="feather-bookmark"
                        />
                      </a>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add To Close Friends">
                      <a
                        onClick={() => addFriend(profile.user_name)}
                        // onClick={addFriend(profile.user_name)}
                        className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                      >
                        {/* <MapsUgcOutlinedIcon style={{fontSize: 30}}/> */}
                        <GroupAddOutlinedIcon
                          style={{ fontSize: 28, color: "#50C878" }}
                          className="feather-bookmark"
                        />
                      </a>
                    </Tooltip>
                  )}

                  <Tooltip title="Report User">
                    <a
                      // onClick={handleClickOpen}
                      className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                      // className=" flex justify-center d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                    >
                      {/* <i className="feather-mail font-md"></i> */}
                      {/* <ReportOutlinedIcon
                        style={{ fontSize: 25, color: "red" }}
                      /> */}

                      <ReportModal type="user" url="users" id={profile.id} />
                    </a>
                  </Tooltip>
                </div>
              )}
            </div>
          </Grid>

          <Grid item sm={12} className={classes.mobile}>
            <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 mt-3 overflow-hidden">
              {/* <h1 style={{ backgroundColor: "yellow" }}> HELOO MOBILE </h1> */}
              {profile.cover_pic === null ? (
                <div
                  className="card-body position-relative h240 bg-image-cover bg-image-center "
                  style={{
                    backgroundImage: `url("https://via.placeholder.com/1200x450.png")`,
                  }}
                ></div>
              ) : (
                <ImageComponentDiv
                  image={profile.cover_pic}
                  class="card-body position-relative h240 bg-image-cover bg-image-center coverImg"
                />
                // <div
                //   className="card-body position-relative h240 bg-image-cover bg-image-center coverImg"
                //   style={{ backgroundImage: `url(${profile.cover_pic})` }}
                // ></div>
              )}
              <div className="card-body d-block pt-4 text-center position-relative">
                {profile.profile_pic === null ? (
                  //    <h2>  hello </h2>
                  <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                    <img
                      onError={fixImage}
                      src="/assets/images/user.png"
                      alt="Loading Profile Pic"
                      className="p-1 bg-white rounded-xl w-100"
                    />
                  </figure>
                ) : (
                  //    <h2>  hello 22 </h2>
                  <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                    <ImageComponent
                      onError={fixImage}
                      image={profile.profile_pic}
                      class="p-1 bg-white rounded-xl w-100"
                    />
                    {/* <img
                      onError={fixImage}
                      src={profile.profile_pic}
                      alt="Loading Profile Pic"
                      className="p-1 bg-white rounded-xl w-100"
                    /> */}
                  </figure>
                )}
                {/* {console.log(`hello ${first_name}`)} */}
                <h4 className="font-xs ls-1 fw-700 text-grey-900">
                  {" "}
                  {profile.first_name} {profile.last_name}{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary">
                    @{profile.user_name}
                  </span>
                </h4>
                {/* <h4 className="font-xs ls-1 fw-700 text-grey-900">{this.state.profile.first_name} {this.state.profile.last_name}    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary-500">@{user_name}</span></h4> */}
                {/* <br/><br/> */}
                {/* <div className="d-flex align-items-center pt-0 position-absolute left-15 top-10 mt-4 ms-2"> */}
                <div className="d-flex align-items-center justify-content-center pt-0 ms-2">
                  <Link>
                    <h4 className="font-xsssss text-center d-lg-block text-grey-500 fw-600 ms-2 me-2">
                      {/* <h4 className="font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"> removed d-none */}
                      <b className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                        {alerts}
                      </b>{" "}
                      Alarrts
                    </h4>
                  </Link>
                  <Link>
                    <h4 className="font-xsssss text-center d-lg-block text-grey-500 fw-600 ms-2 me-2">
                      {/* <h4 className="font-xsssss text-center d-none d-lg-block text-grey-500 fw-600 ms-2 me-2"> removed d-none */}
                      <b className="text-grey-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                        {posts}
                      </b>{" "}
                      Posts
                    </h4>
                  </Link>
                  <Link to={`/${profile.user_name}/followers`}>
                    {" "}
                    <h4 className="font-xsssss text-center  d-lg-block text-primary fw-600 ms-2 me-2">
                      <b className="text-primary-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                        {followers}{" "}
                      </b>{" "}
                      Followers
                    </h4>{" "}
                  </Link>
                  <Link to={`/${profile.user_name}/following`}>
                    {" "}
                    <h4 className="font-xsssss text-center  d-lg-block text-primary fw-600 ms-2 me-2">
                      <b className="text-primary-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark">
                        {following}{" "}
                      </b>{" "}
                      Following
                    </h4>{" "}
                  </Link>
                </div>
                <br />
                {isLoggedInUser ? (
                  <div className="d-flex align-items-center justify-content-center mt-2 me-2">
                    {/* // className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-4 me-2"> */}
                   
                    {/* <a
                    href="/closefriends"
                    className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                  >
                    View Close Friends
                  </a> */}
                     <a
                    href="/sos-contacts"
                    className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                  >
                    View SOS Contacts
                  </a>
                    <Tooltip title="Edit Profile">
                      <a
                        href="/profileinformation"
                        className="bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                      >
                        <i className="feather-edit font-md"></i>
                      </a>
                    </Tooltip>
                  </div>
                ) : (
                  // {/* <div className="d-flex align-items-center justify-content-center position-absolute right-15 top-10 mt-2 me-2"> */}
                  <div className="d-flex align-items-center justify-content-center mt-2 me-2">
                    {/* {!this.state.isFollowing ?  */}
                    {/* <a className="d-none d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3">Follow</a> */}
                    {/* : */}
                    <a
                      onClick={toogleFollow}
                      className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                    >
                      {action}
                    </a>

                    <Tooltip title="Send SMS">
                      <a
                        onClick={handleClickOpen}
                        style={{ color: "#50C878" }}
                        className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-success-700"
                      >
                        <i className="feather-mail font-md"></i>
                      </a>
                    </Tooltip>
                    {/* Drop Down */}
                    <div>
                      <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Send an sms to {user_name}</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Your points are used to send sms. Depending on your
                            friend's network provider, each sms costs a minimum
                            of 4 points. (This only works with Nigerian Numbers
                            At The Moment)
                          </DialogContentText>
                          <br />
                          <h5> You currently have {points} points</h5>
                          <div className="col-lg-12 mb-3">
                            <label className="mont-font fw-600 font-xsss mb-2 text-dark">
                              Type Your Message
                            </label>
                            <textarea
                              // className="fw-500 bg-greylight  text-black-900 lh-26 font-xssss w-100 mb-2"
                              className="form-control mb-0 p-3 h100 bg-greylight lh-20"
                              rows="5"
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              placeholder="Type your message here..."
                            ></textarea>
                          </div>
                          {/* <TextField
                            // autoFocus
                            // margin="dense"
                            // id="name"
                            label="Your Message"
                            type="text"
                            fullWidth
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            variant="standard"
                          /> */}
                        </DialogContent>
                        <br />
                        <br />
                        <br />

                        {sentSms ? (
                          <Alert
                            onClose={handleClose}
                            variant="filled"
                            severity="success"
                          >
                            Your sms has been sent to {user_name} successfully!
                          </Alert>
                        ) : (
                          ""
                        )}

                        {failedSms ? (
                          <Alert
                            onClose={handleClose}
                            variant="filled"
                            severity="error"
                          >
                            {errorMessage}
                          </Alert>
                        ) : (
                          ""
                        )}
                        <DialogActions>
                          <Button onClick={handleModalClose}>Close</Button>
                          {sending ? (
                            <Button disabled>Sending</Button>
                          ) : (
                            <Button onClick={sendSms}>Send SMS</Button>
                          )}
                        </DialogActions>
                      </Dialog>
                    </div>

                    {friends == "true" ? (
                      <Tooltip title="Remove From Close Friends">
                        <a
                          onClick={() => removeFriend(profile.user_name)}
                          // onClick={addFriend(profile.user_name)}
                          className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                        >
                          {/* <MapsUgcOutlinedIcon style={{fontSize: 30}}/> */}
                          <PersonRemoveIcon
                            style={{ fontSize: 28, color: "#50C878" }}
                            className="feather-bookmark"
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add To Close Friends">
                        <a
                          onClick={() => addFriend(profile.user_name)}
                          // onClick={addFriend(profile.user_name)}
                          className=" d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                        >
                          {/* <MapsUgcOutlinedIcon style={{fontSize: 30}}/> */}
                          <GroupAddOutlinedIcon
                            style={{ fontSize: 28, color: "#50C878" }}
                            className="feather-bookmark"
                          />
                        </a>
                      </Tooltip>
                    )}

                    <Tooltip title="Report User">
                      <a
                        // onClick={handleClickOpen}
                        className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                        // className="flex justify-center d-lg-block bg-greylight btn-round-lg ms-2 rounded-3 text-grey-700"
                      >
                        {/* <i className="feather-mail font-md"></i> */}
                        {/* <ReportOutlinedIcon
                          style={{ fontSize: 25, color: "red" }}
                        /> */}
                        <ReportModal type="user" url="users" id={profile.id} />
                      </a>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          </Grid>

          {/* <h1> sent</h1> */}

          {addedFriend ? (
            <>
              {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
              <Alert
                onClose={handleClose}
                variant="filled"
                severity="success"
                action={
                  <>
                    <Button
                      onClick={redirect}
                      style={{ color: "#fff", alignSelf: "center" }}
                    >
                      {" "}
                      View Close Friends
                    </Button>
                    <Button
                      onClick={handleClose}
                      style={{ color: "#fff", alignSelf: "center" }}
                    >
                      {" "}
                      Close
                    </Button>
                  </>
                }
              >
                You've Successfully Added {user_name} To Your Close Friends!
              </Alert>
              {/* </Snackbar> */}
              <br />
            </>
          ) : (
            ""
          )}

          {removedFriend ? (
            <>
              <Alert
                onClose={handleClose}
                variant="filled"
                severity="success"
                // action={
                //   <Button
                //     onClick={redirect}
                //     style={{ color: "#fff", alignSelf: "center" }}
                //   >
                //     {" "}
                //     Check It Out
                //   </Button>
                // }
              >
                You've Successfully Removed {user_name} From Your Close Friends!
              </Alert>
              <br />
            </>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <br />
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue fetching profile details. Kindly refresh or
                check your network [ {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          {/* TABS START  */}
          <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
            {isLoggedInUser ? (
              // <Profile
              <ProfileTabsAuth
                user_name={user_name}
                profile={profile}
                posts={posts}
              />
            ) : (
              <ProfileTabs
                user_name={user_name}
                profile={profile}
                posts={posts}
              />
            )}
          </div>

          {/* function ControlledTabs() {? */}
          {/* const [key, setKey] = useState('home'); */}

          {/* render(<ControlledTabs />); */}
        </div>
      </>
    );
  }
  // }
}

// export default ProfileCardTwoTest;
