import React, { Component, Fragment, useState, useEffect } from "react";
import {
  Route,
  useParams,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "./Load";
// import ChannelsTab from "./ChannelsTab";
import CommunityTabsAdmin from "./CommunityTabsAdmin";
import Alert from "@mui/material/Alert";
import CommunityTabs from "./CommunityTabs";
import ImageComponent from "./ImageComponent";
import ImageComponentDiv from "./ImageComponentDiv";
import UnfoldMoreOutlinedIcon from "@mui/icons-material/UnfoldMoreOutlined";
import UnfoldLessOutlinedIcon from "@mui/icons-material/UnfoldLessOutlined";
import CommunityUpdateModal from "../pages/CommunityUpdateModal";
import { usePaystackPayment } from "react-paystack";
import CancelIcon from "@mui/icons-material/Cancel";
import ShareChannel from "./ShareChannel";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReportModal from "./ReportModal";
// import Alert from "@mui/material/Alert";

import { Snackbar } from "@material-ui/core";

export default function CommunityCard(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const userName = window.localStorage.getItem("user_name");
  const [copied, setCopied] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [community, setCommunity] = useState([]);
  const [posts, setPosts] = useState([]);
  const [movies, setMovies] = useState("");
  const [videos, setVideos] = useState("");
  const [clicked, setClicked] = useState(false);
  const [adminDetails, setAdminDetails] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [subscribePromt, setSubscribePromt] = useState(false);
  const [unSubscribePromt, setUnSubscribePromt] = useState(false);
  const [subscribeSuccess, setSubscribeSuccess] = useState(false);
  const [unSubscribeSuccess, setUnSubscribeSuccess] = useState(false);
  const [action, setAction] = useState("");
  const [subscribing, setSubscribing] = useState(false);
  // const [userEmail, setUserEmail] = window.localStorage.getItem("email");
  const userEmail = window.localStorage.getItem("email");
  const [subscribers, setSubscribers] = useState([]);
  const { slug } = useParams(["slug"]);
  const [isfullScreen, setIsfullScreen] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const channelId = "";
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSubscribePromt(false);
    setSubscribeSuccess(false);
    setUnSubscribeSuccess(false);
    setUnSubscribePromt(false);
    setError(false);
  };

  function closeError() {
    setError(false);
    console.log("closed");
  }

  function closeSnack() {
    setCopied(false);
  }

  async function getChannelDetailsLoad() {
    setIsFetching(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
    .get(`/community/get/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
          setCommunity(resp.data.community);
          setPosts(resp.data.community.posts);
          // setSubscribers(resp.data.community.subscribers.length);
          setAction(resp.data.action);
        //   setVideos(resp.data.videos);
        //   setMovies(resp.data.movies);
          setAdminDetails(resp.data.community.admin);
          setAdminId(resp.data.community.admin.id);
          setIsFetching(false);

          if (userId[0] == resp.data.community.admin.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        // } else {
        //   setIsFetching(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setIsFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }
  async function getChannelDetails() {
    // setIsFetching(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/community/get/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
          setCommunity(resp.data.community);
          setPosts(resp.data.community.posts);
          // setSubscribers(resp.data.community.subscribers.length);
          setAction(resp.data.action);
          setAdminDetails(resp.data.community.admin);
          setAdminId(resp.data.community.admin.id);
          
          if (userId[0] == resp.data.community.admin.id) {
            return setIsLoggedInUser(true);
          } else {
            return setIsLoggedInUser(false);
          }
        // } else {
        //   // setIsFetching(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        // setIsFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }


  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function getProfile() {
    setIsFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data.user.profile_pic));
        // setUserEmail(resp.data.user.email);
        setIsFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function handleSubscription(id) {
    setSubscribing(true)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
    .post(`/community/follow/${id}`, userName, {
    //   .post(`/channels/${id}/subscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        getChannelDetails();
        setSubscribeSuccess(true);
        setSubscribing(false)
        // window.location.reload()
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function unSubscribe() {
    setUnSubscribePromt(true);
    // if (community.type == "paid") {
    //   setUnSubscribePromt(true);
    // } else {
    //   handleUnSubscribe(id);
    // }
  }

  async function handleUnSubscribe(id) {
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
    .post(`/community/unfollow/${id}`, userName, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        getChannelDetails();
        setUnSubscribePromt(false);
        setUnSubscribeSuccess(true);
        // window.location.reload()
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function toogle() {
    if (isfullScreen == true) {
      return setIsfullScreen(false);
    } else {
      return setIsfullScreen(true);
    }
  }

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    // console.log(`channel id is == ${community.id}`)
    handleSubscription(community.id);
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
    setSubscribePromt(true);
  };

  
  function subscribe() {}

  useEffect(() => {
    getChannelDetailsLoad();
    // getProfile();
    // getById()
    // console.log(props.channelId);
    {
      console.log(window.location.href);
    }
  }, []);

  if (isFetching == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load />
      </>
    );
  }

  if (isFetching.length == 0) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load />
      </>
    );
  } else {
    return (
      <>
        {/* <h1>{slug}</h1> */}
        {/* {console.log(`id issssss ${channelId}`)} */}
        <div className="card w-100 shadow-xss rounded-xxl overflow-hidden border-0 mb-3 mt-3 pb-3">
          {isfullScreen ? (
            ""
          ) : (
            <div>
              {community.cover_pic === null ? (
                <>
                  <div
                    className="card-body position-relative h240 bg-image-cover bg-image-center"
                    style={{
                      backgroundImage: `url("https://via.placeholder.com/1200x250.png")`,
                    }}
                  ></div>
                </>
              ) : (
                <>
                  <ImageComponentDiv
                    image={community.cover_pic}
                    class="card-body position-relative h240 bg-image-cover bg-image-center"
                  />
                  {/* <div
                className="card-body position-relative h240 bg-image-cover bg-image-center"
                style={{ backgroundImage: `url(${community.cover_pic})` }}
              >
              </div> */}
                </>
              )}
              {/* {clicked ? <ImageComponent isOpen='true' image={community.cover_pic} class='card-body position-relative h240 bg-image-cover bg-image-center'/> : ''} */}

              <div className="card-body d-block pt-4 text-center">
                <figure className="avatar mt--6 position-relative w75 z-index-1 w100 z-index-1 ms-auto me-auto">
                  {community.page_pic === null ? (
                    <img
                      onError={fixImage}
                      src={"https://alert-app-v2.s3.amazonaws.com/user.png"}
                      alt="avater"
                      className="p-1 bg-white rounded-xl w-100"
                    />
                  ) : (
                    <ImageComponent
                      image={community.page_pic}
                      class="p-1 bg-white rounded-xl w-100"
                    />
                    // <img
                    //   onError={fixImage}
                    //   src={community.channel_pic}
                    //   alt="avater"
                    //   className="p-1 bg-white rounded-xl w-100"
                    // />
                  )}
                  {/* <img src={community.channel_pic} alt="avater" className="p-1 bg-white rounded-xl w-100" /> */}
                </figure>
                <h4 className="font-xs ls-1 fw-700 text-grey-900">
                  {community.name}{" "} 
                  <Link to={`/profile/${adminDetails.user_name}`}>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      Admin: @{adminDetails.user_name}
                    </span>
                  </Link>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    Type: {community.type} 
                  </span>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    Status: {community.status}
                  </span>
                </h4>
              </div>
              <div className="card-body d-flex justify-content-center align-items-center ps-4 pe-4 pt-0">
                <Link>
                  {" "}
                  <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                      0
                    </b>{" "}
                    Alarrts
                  </h4>{" "}
                </Link>
                <Link>
                  {" "}
                  <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                      0
                    </b>{" "}
                    Posts
                  </h4>{" "}
                </Link>
                <Link>
                  {" "}
                  <h4 className="font-xsssss text-center text-grey-500 fw-600 ms-2 me-2">
                    <b className="text-grey-900 mb-1 font-xss fw-700 d-inline-block ls-3 text-dark">
                      {community.subscribers_count}
                    </b>{" "}
                    Members
                  </h4>{" "}
                </Link>
              </div>
              <div className="card-body d-flex align-items-center justify-content-center ps-4 pe-4 pt-0">
                {subscribing ? 
                <a
                disabled
                // onClick={() => {
                  // handleSubscription(community.id);
                // }}
                className="bg-grey p-3 z-index-1 rounded-3 text-primary font-xsssss text-uppercase fw-700 ls-3"
              >
                {/* free  */}
                Just A Sec...
              </a>
                :
                  <>
                {action == "JOIN" ? (
                  <>
                 
                      <a
                        onClick={() => {
                          handleSubscription(community.id);
                        }}
                        className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                      >
                        {/* free  */}
                        {action}
                      </a>
                  
                  </>
                ) : (
                  <a
                    onClick={() => {
                        unSubscribe()
                    // handleUnSubscribe(community.id);
                      // initializePayment(onSuccess, onClose);
                    }}
                    // onClick={toogleSubscription(community.id)}
                    className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-uppercase fw-700 ls-3"
                  >
                    {/* unsub */}
                    {action}
                  </a>
                )}

</>
  }

                {console.log(``)}

                {isLoggedInUser ? (
                  <>
                    <CommunityUpdateModal
                      channel={community}
                      adminDetails={adminDetails}
                      functionProp={() => getChannelDetails()}
                    />
                  </>
                ) : (
                  ""
                )}
                <Tooltip title="Share Community" aria-label="add">
                  <>
                    <CopyToClipboard
                      text={`https://app.alarrt.com/signin${window.location.pathname}`}
                      onCopy={() => setCopied(true)}
                    >
                      <a
                        style={{ border: "#eee solid 2px" }}
                        className="btn-round-lg ms-2 d-inline-block rounded-3"
                      >
                        <i className="feather-share-2 text-2xl hover:bg-grey-200 rounded-full p-2 transition -mr-1 dark:hover:bg-grey-700"></i>{" "}
                      </a>
                    </CopyToClipboard>
                    <br />
                    {copied ? (
                      <>
                        <Snackbar
                          open={copied}
                          autoHideDuration={3000}
                          onClose={closeSnack}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <Alert onClose={closeSnack} severity="success">
                            Link Copied Successfully
                          </Alert>
                        </Snackbar>
                      </>
                    ) : null}
                  </>
                </Tooltip>

                {isLoggedInUser ? (
                  ""
                ) : (
                  <a
                    // href="/home"
                    className="bg-greylight theme-white-bg btn-round-lg ms-2 rounded-3 text-grey-700"
                  >
                    <ReportModal
                      type="community"
                      url="community"
                      id={community.id}
                    />
                  </a>
                )}
              </div>
            </div>
          )}
          <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
            <br />
            <div
              style={{
                display: "flex",
                alignSelf: "center",
                justifyContent: "center",
              }}
            >
              {isfullScreen ? (
                <UnfoldMoreOutlinedIcon onClick={toogle} />
              ) : (
                <UnfoldLessOutlinedIcon onClick={toogle} />
              )}
            </div>
          </div>
          <br />
          {subscribeSuccess ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert onClose={handleClose} severity="success">
                Hurray!!! You've joined {community.name} community page successfully!
              </Alert>
              <br />
            </>
          ) : (
            ""
          )}
          {unSubscribeSuccess ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert onClose={handleClose} severity="success">
                You've left {community.name} community page successfully!
              </Alert>
              <br />
            </>
          ) : (
            ""
          )}
          {unSubscribePromt ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert
                onClose={handleClose}
                severity="error"
                action={
                  <>
                    <a
                      onClick={handleClose}
                      className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    >
                      No, undo.
                    </a>

                    <a
                      onClick={() => handleUnSubscribe(community.id)}
                      className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    >
                      Yes, leave.
                    </a>
                  </>
                }
              >
                Hi! Are you sure you want to leave this community? 
              </Alert>
              <br />
            </>
          ) : (
            ""
          )}

          {subscribePromt ? (
            <>
              {/* <br />
                          <br /> */}
              <Alert onClose={handleClose} severity="warning">
                You've left successfully. Click JOIN to come back whenever.
              </Alert>
              <br />
            </>
          ) : (
            ""
          )}
          {error ? (
            <div>
              <br />
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue fetching channel details. Kindly refresh or
                check your network. If this continues, kindly login again [{" "}
                {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

           {isLoggedInUser ? (
            <CommunityTabsAdmin
              community={community}
              action={action}
              posts={posts}
              admin={adminDetails}
              adminId={adminId}
              functionProp={() => getChannelDetails()}
            />
          ) : (
            ''
            // <CommunityTabs
            //   community={community}
            //   action={action}
            //   posts={posts}
            //   admin={adminDetails}
            //   adminId={adminId}
            //   functionProp={() => getChannelDetails()}
            // />
          )} 
         
        </div>
      </>
    );
  }
}
// }

// export default ChannelCard;
