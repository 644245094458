import React, { Component, useState, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
// import { useParams } from 'react-router-dom';
import axios from "axios";
import axiosFetch from "../../Interceptors2";
// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import Tooltip from "@mui/material/Tooltip";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import AlertComponent from "../AlertComponent";

export default function FilterByLocation(props) {
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const userName = window.localStorage.getItem("user_name");

  const [updated, setUpdated] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [location, setLocation] = useState("");
  const [searching, setSearching] = React.useState(false);
  const [searchMessage, setSearchMessage] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [emptyFields, setEmptyFields] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUpdated(false);
    setError(false);
    setEmptyFields(false);
  };

  //   const handleClose = (event, reason) => {
  // if (reason === "clickaway") {
  //   return;
  // }

  async function updateSeries() {
    setUpdating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("name", location);

    axiosFetch
      .post(`/alert_category/update}`, formData, {
        // method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setUpdating(false);
          setError(true);
          setErrorMessage(resp.data.message);
        } else {
          console.log(resp.data);
          setUpdated(true);
          // props.functionProp();
          // setMessage("");
          setUpdating(false);
          // setSms("");
          setOpen(false);
        }
      })
      .catch((error) => {
        // if(error.response.status == 403){
        setUpdating(false);
        setError(true);
        setErrorMessage(error.message);
        console.log(error);
        // }
      });
  }

  async function validate() {
    console.log(`location is ${location}`);
    // alert(alertCategory)
    //  alert(alertCategoryName)
    if (!location) {
      setEmptyFields(true);
      return;
    } else {
      searchAlerts();
      // createAlert();
    }
  }

  function searchAlerts(searchText, results) {
    const access_token = window.localStorage.getItem("access_token");
    setSearching(true);
    setSearchMessage("continue");
    let result = axiosFetch
      .get(`/alerts/location/search/${location}`, {
        // .get("https://alert-lumen-api.herokuapp.com/api/users", {
        headers: {
          Accept: "*/*",
          // 'Content-Type': 'multipart/form-data',
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        // console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setAlerts(resp.data);
        setSearchMessage("continue typing");

        setSearching(false);

        // setItems(resp.data);
      })
      .catch((error) => {
        console.log(`Error Seen : ${error}`);
        setSearching(false);
      });
  }

  useEffect(() => {
    // getUserPoints();
  }, []);

  //   if (alerts.length == 0) {
  //     return <p>Nothing has been searched for</p>;
  //   } else {
  return (
    <div>
      {/* <Tooltip title="Update Your Series"> */}
      <span>
        <Button
          // disabled
          variant="contained"
          color="primary"
          // href="/"
          // target="_blank"
          onClick={handleClickOpen}
          // onClick={makeInactive}
        >
          Filter By Location
        </Button>
      </span>
      {/* <ModeEditOutlineIcon 
       onClick={handleClickOpen} 
                  style={{ color: "#2516c7", fontSize: 30, paddingLeft:30 }}
                  /> */}
      {/* <li className="list-inline-item d-block  me-0"><Link onClick={handleClickOpen} className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Send Random Sms</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
      {/* </Tooltip> */}
      <br />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Get Alerts Within Your Location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hello {userName}! Quickly know what has been alerted around you.
          </DialogContentText>
          <br />
          <TextField
            label="Enter Location"
            type="text"
            fullWidth
            value={location}
            // value={location}
            onChange={(e) => setLocation(e.target.value)}
            variant="standard"
          />{" "}
          <br />
          <br />
          {searching == true ? (
            <>
              <Button
                disabled
                variant="contained"
                color="primary"
                // onClick={validate}
              >
                Searching...
              </Button>
              <br/>
              <p className="text-primary">
                Searching ... {searchMessage}
              </p>
              {/* <br /> */}
            </>
          ) : (
            <Button
              // disabled
              variant="contained"
              color="primary"
              onClick={validate}
            >
              Search Now
            </Button>
          )}
          <br /><br/>
          {alerts.length == 0 ? (
            <p>Nothing has been searched for</p>
          ) : (
            <AlertComponent
              alerts={alerts}
              functionProp={() => searchAlerts()}
              functionProp2={() => searchAlerts()}
            />
          )}
        </DialogContent>
        <br />
        <br />
        <br />

        {updated ? (
          <Alert onClose={handleClose} variant="filled" severity="success">
            Category was updated successfully {userName}
          </Alert>
        ) : (
          ""
        )}

        {error ? (
          <Alert onClose={handleClose} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        ) : (
          ""
        )}
        {emptyFields ? (
          <>
            <Alert onClose={handleClose} severity="warning">
              Oops! you haven't typed a location yet.
            </Alert>
          </>
        ) : (
          ""
        )}
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
          {/* {updating ? (
            <Button disabled>Updating</Button>
          ) : (
            <Button onClick={updateSeries}>Update Series</Button>
          )} */}
        </DialogActions>
      </Dialog>
    </div>
  );
  //   }
}
