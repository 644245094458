import React, { Component, useEffect, useState } from "react";
// import { Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { MenuItem } from "@material-ui/core";
import { format, formatDistance, subDays } from "date-fns";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
// import Typography from '@mui/material/Typography';
import ShowMoreText from "react-show-more-text";
import { Grid, makeStyles } from "@material-ui/core";
import ImageComponentDiv from "../ImageComponentDiv";

export default function EpisodeCard(props) {
 
  
  const useStyles = makeStyles((theme) => ({
    pc: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    mobile: {
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
  }));

  const theme = useTheme();
 const classes = useStyles();

  const channelSlug = props.channelSlug;
  const channelId = props.channelId;
  const adminId = props.adminId;
  // const url = props.url;
  const url = `channel/${channelSlug}/series/episode`;

  useEffect(() => {}, []);

  return (
    <>
      {/* {console.log(`prop ${JSON.stringify(props)}`)} */}
      <Card sx={{ display: "flex" }} style={{ marginBottom: 15 }}>
        {/* <Grid
          item
          xs={false}
          sm={12}
          md={12}
          pc={12}
          className={classes.mobile}
        > */}
                 <div style={{width: '40%', height:'100%'}}>
            <ImageComponentDiv
              image={props.movies.image}
              class="card-body position-relative h300 bg-image-cover bg-image-center coverImg"
            />
            </div>
        {/* <ImageComponentDiv
              image={props.movies.image}
              class="card-body position-relative h240 bg-image-cover bg-image-center coverImg"
            /> */}
          {/* <CardMedia
            component="img"
            sx={{ width: 80 }}
          
            image={props.movies.image}
            alt="content image"
          /> */}
        {/* </Grid> */}

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component="div" variant="h5">
              {props.movies.title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
            >
              <span style={{ paddingRight: 1 }}> Released </span>
              {formatDistance(new Date(props.movies.created_at), new Date(), {
                addSuffix: true,
              })}
            </Typography>
            {/* <Typography variant="body2" color="text.secondary">
              {props.movies.overview}
            </Typography> */}
            <ShowMoreText
              style={{ color: "#000", fontWeight: 600 }}
              lines={2}
              more="Read more"
              less="Show less"
              className="overview text-black"
              //    className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
              anchorClass="my-anchor-css-class"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {props.movies.overview}
            </ShowMoreText>
          </CardContent>

          <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
            {/* <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton> */}
            {/* <IconButton aria-label="play/pause">
            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
          </IconButton> */}
            {/* <IconButton aria-label="next">
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton> */}
          </Box>
          <CardActions>
            <a href = {`/${url}/${props.movies.slug}?channel=${channelSlug}&w=${props.movies.id}&a=${adminId}`} 
            onClick={
              () => {
                window.location.reload()
              }
            }>
            {/* // <Link
            //   to={{
            //     pathname: `/${url}/${props.movies.slug}?channel=${channelSlug}&w=${props.movies.id}&a=${adminId}`,
                // pathname: `/channel/series/episode/${props.movies.slug}?channel=${props.channelSlug}&w=${props.movies.id}&a=${props.adminId}`,
                // pathname: `/channel/series/episode/${props.movies.slug}`,
                // state: {
                //   channelSlug: props.channelSlug,
                //   id: props.movies.id,
                //   adminId: props.adminId,
                // },
            //   }}
            // > */}
              
              <Button size="small">VIEW EPISODE</Button>
              </a>
            {/* </Link> */}
            {/* <a href={`/channel/series/episode/${props.movies.slug}`} >
        <Button size="small">VIEW</Button>
        </a> */}
            {/* <Button size="small">SHARE </Button> */}
          </CardActions>
        </Box>
      </Card>
    </>
  );
}
