import React, { Component, useState, useEffect } from "react";
import "./comment.css";
import { PermMedia, Label, Room, EmojiEmotions } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles, Snackbar } from "@material-ui/core";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import Alert from "@mui/material/Alert";
import InputEmoji from 'react-input-emoji'
  

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));


export default function CreateThread(props) {
  const classes = useStyles();
  const [isCreating, setIsCreating] = useState(false);
  const [currentComment, setCurrentComment] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [thread, setThread] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  // const [userPic, setUserPic] = useState("");
  const userPic = window.localStorage.getItem("profile_pic");
  const userName = window.localStorage.getItem("user_name");

  function handleClose2() {
    // setIsLiked(false);
    setIsFavorite(false);
  }


  async function postThread() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("content_id", props.Id);
    formData.append("body", thread);
    // formData.append("status", channelStatus);

    axiosFetch
      .post(
        `/${props.url}/add`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(`hi ${resp.data}`);
        setThread("");
        
        props.functionProp();
        // props.openComment()
        setIsCreating(false);
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  function handleOnEnter (thread) {
    console.log('enter', thread)
  }

  useEffect(() => {
    // getProfile();
  }, []);

  return (
    <>

      <div className="shar">
        <div className="shareWrapper">
          <div className="shareTop">
            {/* <figure className="avatar me-3"> */}
            {isFetching ? (
              <img
                className="shareProfileImg"
                src="/assets/images/user.png"
                alt=""
              />
            ) : (
              <>
                <img
                  src={userPic}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                  style={{ marginRight: 10, border: "2px solid #045de9" }}
                />
                {/* </figure> */}
              </>
            )}

            <TextField
              id="standard-multiline-flexible"
              label="Add Thread..."
              multiline
              className="shareInput"
              // maxRows={4}
              value={thread}
              onChange={(e) => setThread(e.target.value)}
            />

{/* <InputEmoji
          value={thread}
          onChange={setThread}
          cleanOnEnter
          onEnter={handleOnEnter}
          placeholder="Type a message"
        /> */}

            {/* {props.alert} */}
            {isCreating ? (
              <HourglassEmptyRoundedIcon />
            ) : (
              <SendRoundedIcon className="pointer" onClick={postThread} />
            )}
          </div>

    

          <div className="shareBottom">
            <div className="shareOptions"></div>
          </div>
        </div>
      </div>
    </>
  );
}
