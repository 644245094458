import React, { Component , Fragment } from "react";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';


import Friends from '../components/Friends';
import Contacts from '../components/Contacts';
import Group from '../components/Group';
import Events from '../components/Events';
import Createpost from '../components/Createpost';
import Createalert from '../components/Createalert';
import Memberslider from '../components/Memberslider';
import Friendsilder from '../components/Friendsilder';
import Storyslider from '../components/Storyslider';
import Postview from '../components/Postview';
import Alerts from '../components/Alerts';
import Load from '../components/Load';
import Profilephoto from '../components/Profilephoto';
import Alertcategories1 from '../components/Alertcategories1';
import CreateAlertFab from "../components/CreateAlertFab";
import Ad from "../components/ad/Ad";
import Widgets from "../components/widgets/Widgets";
import AlertFilter from "../components/widgets/AlertFilter";
import RecentlyFollowing from "../components/online/RecentlyFollowing";
import RecentUsers from "../components/online/RecentUsers";



class Home extends Component {
    render() {
        return (
            <Fragment> 
                <Header />
                {/* <Leftnav /> */}
                
                {/* <Rightchat /> */}
                

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left">
                            <div className="row feed-body">
                                <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    {/* <AlertFilter /> */}
                                    {/* <Createalert /> */}

                                    <Alerts />
                                   
                                    {/* <Load /> */}
                                    {/* <CreateAlertFab /> */}
                                </div>
                                <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                                    {/* <Friends />
                                    <Contacts /> */}
                                    {/* <p>Announcement </p> */}
                                    <Ad />
                                    <br/><br/>
                                    <RecentUsers />
                                    
                  
                  <br/><br/><br/>
                  {/* <RecentlyFollowing /> */}
                  <br/>
                                    {/* <p>widget </p>
                                    <Widgets />
                                    <p>alcat </p>
                                    <Alertcategories1 />
                                    <p>group </p>
                                    <Group />
                                    <p>events </p>
                                    <Events /> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Popupchat /> */}
                <br/> <br/>
                <Appfooter /> 
                {/* <CreateAlertFab /> */}
            </Fragment>
        );
    }
}

export default Home;