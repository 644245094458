import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import { AutoComplete } from "antd";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@mui/material/Alert";
import CreatePost2 from "./CreatePost2";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getCircularProgressUtilityClass } from "@mui/material";

export default function ForwardAlerts(props) {
  const [emptyFields, setEmptyFields] = useState(false);
  const history = useHistory();
  const [isForwarding, setIsForwarding] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [forwarded, setForwarded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);

  const animatedComponents = makeAnimated();
  const [open, setOpen] = React.useState(false);
  const { Option } = AutoComplete;

  const roleOptions = [
    { value: "actor", label: "Actor" },
    { value: "actress", label: "Actress" },
    { value: "director", label: "Director" },
  ];

  const handleClose = () => {
    setOpen(false);

  };
  const handleClose2 = () => {
    setForwarded(false);
    setEmptyFields(false);
    setError(false);
  };

  const handleOnSearch = (string, results) => {
    console.log(
      "search string " + JSON.stringify(string),
      "search reults" + JSON.stringify(results)
    );
  };
  const handleOnSelect = (item) => {
    // the item selected
    console.log("selesct" + JSON.stringify(item));
    // setUserId(item.id);
    setUser(item.first_name);
    setLastName(item.last_name);
    setUserName(item.user_name);
  };
  const formatResult = (item) => {
    return item;
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  };

  async function validate() {
    if (!userName) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      forwardAlert();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  function searchUsers(searchText, results) {
    setIsSearching(true)
    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .get(
        `/users/search/${searchText}`,
        {
          // .get("https://alert-lumen-api.herokuapp.com/api/users", {
          headers: {
            Accept: "*/*",
            // 'Content-Type': 'multipart/form-data',
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // uploadImage();
        console.log(JSON.stringify(resp.data));
        const data = JSON.stringify(resp.data);
        setResults(resp.data);
        setItems(resp.data);
        handleOnSearch(searchText, resp.data);
        setIsSearching(false)
        // setItems(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const onSelect = (data) => {
    console.log("onSelect", data);
  };

  const onChange = (data) => {
    console.log("onchange", data);
    // setValue(data);
  };

  async function forwardAlert() {
    setIsForwarding(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("channel_id", props.channelId);
    formData.append("user_name", userName);

    axiosFetch
      .post(
        `/alerts/forward/${props.alertId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if(resp.data.status == 'warn'){
          setIsForwarding(false)
          setError(true);
          setErrorMessage(resp.data.message);
      }else{

        setIsForwarding(false);
        // setUserId("");
        setUser("");
        setUserName("");
        setLastName("");
        setForwarded(true);
        setItems([]);
        // setOpen(false);
        // props.functionProp();
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      }
      })
      .catch((error) => {
        setIsForwarding(false);
        setError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      });
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {}, []);

  return (
    <>
      <Tooltip
        className="d-inline-block"
        title="Send As Sms"
        onClick={handleClickOpen}
      >
        <a
          onClick={handleClickOpen}
          className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
        >
          <i className="feather-share-2 mr-1"></i> Alarrt Friend
        </a>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            Forward this alarrt to your friend via sms
            {props.channelId}{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              Forward this alarrt to your friends. They will be notified via SMS.
            </DialogContentText>
            <br/>
            <p>Only forward to friends this alarrt will be beneficial to</p>
            {/* {console.log(JSON.stringify(casts))} */}
            <p> Type here to start searching </p>
            <br />
            <div style={{ width: 300, marginTop: 10 }}>
              {isSearching ?
              <>
              <p className="text-current">Searching ...</p>
              </>
              :
              ''}
              <ReactSearchAutocomplete
                items={items}
                onSearch={searchUsers}
                // onHover={handleOnHover}
                onSelect={handleOnSelect}
                // onFocus={handleOnFocus}
                // autoFocus
                formatResult={formatResult}
                fuseOptions={{ keys: ["first_name", "user_name", "last_name"] }}
                resultStringKeyName="user_name"
                placeholder='Search For User..'
              />
            </div>
            {user  ?
            <>
               <br />
               <h4>
              {" "}
              You've selected {user} {lastName}{" "}
            </h4>
            <br />
            <br />
            {isForwarding ? 
            <Button
             disabled
              // fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              // onClick={validate}
            >
              Forwarding
            </Button>

            :

            <Button
              // type="submit"
              // fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              onClick={validate}
            >
              Forward Alarrt
            </Button>

           }

            </>
          :
          ''
          }
            
           

            <br />
            <br />
            {emptyFields ? (
            <MuiAlert onClose={handleClose2} severity="warning">
              Add A User first
            </MuiAlert>
          ) : (
            ""
          )}
          {forwarded ? (
            <MuiAlert onClose={handleClose2} severity="success">
              Yippy! You've alerted your friend successfully. Alarrt more friends
              that needs to be aware
            </MuiAlert>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <Alert severity="success" onClose={handleClose2}>
                 [ {errorMessage} ].
          
              </Alert>
              <br />
            </div>
          ) : (
            ""
          )}
            <br />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {isForwarding ? (
              <Button disabled>Forwarding</Button>
            ) : (
              <Button onClick={validate}>Forward Alarrt</Button>
              // <Button onClick={console.log(`hi ${movieTitle}`)}>forwardAlert</Button>
            )}
            {/* <Button onClick={validate}>Create</Button> */}
          </DialogActions>
        
        </Dialog>{" "}
      </Box>
    </>
  );
}
