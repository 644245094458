import { Component, useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import Load from "./Load";
import Header from "../components/Header";

import { format, formatDistance, subDays } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axiosFetch from "../Interceptors2";
import { Button } from "@mui/material";


export default function UserTransactions(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [userTransactions, setUserTransactions] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [fetching, setFetching] = useState([]);
  const [month, setMonth] = React.useState("1");

  const handleChange = (event) => {
    console.log(event.target.value);
    setMonth(event.target.value);
  };

  async function getUsersTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/my/subscriptions/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAllTransactions(resp.data.allTransactions);
        setUserTransactions(resp.data.allTransactions);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getAllUsersTransactions() {
    setFetching(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/my/subscriptions/${month}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setUserTransactions(resp.data.allTransactions);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  useEffect(() => {
    getUsersTransactions();

    var d = new Date("January 14, 2012");
console.log('1 ',d.toLocaleDateString());
d.setMonth(d.getMonth() + 3);
console.log('2 ',d.toLocaleDateString());

  }, []);

  return (
    <div style ={{marginLeft: 0}}>

    {/* <Header /> */}
      <h3 className="text-center" style={{ color: "#000" }}>
        Hello {props.userName}! Below are the subscriptions you've made on
        Alarrt{" "}
      </h3>{" "}
      <br />
     
      {fetching ? (
        <Load bg="#fff" color="#000" />
      ) : (
        <TableContainer component={Paper} style={{ zIndex: -20 }}>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Duration</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Reference</TableCell>
                <TableCell align="right">Transaction</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userTransactions.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {/* <TableCell component="th" scope="row">
                    {index}
                  </TableCell> */}
                  
                  <TableCell align="right">{row.user_transactions.type}</TableCell>
                  <TableCell align="right">{row.duration}</TableCell>
                  <TableCell align="right">{row.description}</TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell align="right">{row.reference}</TableCell>
                  <TableCell align="right">{row.transaction}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="right">
                    {" "}
                    {formatDistance(new Date(row.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <br />
      <br />
      <br />
      {userTransactions.length == 0 && (
        <h6 className='text-center'>
          {" "}
          Seems you haven't subscribed to a plan yet. Subscribe now to get the best services we have to offer.
        </h6>
      )}
    </div>
  );
}
