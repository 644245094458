import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Skeleton from "@mui/material/Skeleton";
import { Grid, makeStyles } from "@material-ui/core";

export default function Load3(props) {
  const { loading = false } = props;

  return (
    // className="row ps-2 pe-1"
    // className="col-md-3 mt-4 col-xss-12 pe-2 ps-2"
      <span style={{display:'flex'}} >
    <span style={{display:'flex'}}  style={{marginLeft: 40, marginTop:20}}>
     
      <Skeleton sx={{ height: 500 }} style={{ marginBottom: 6, borderRadius:40 }} animation="wave" variant="rectangular" /> 
     
      <Skeleton sx={{ height: 500 }} style={{ marginBottom: 6, borderRadius:40 }} animation="wave" variant="rectangular" /> 
     
      <Skeleton sx={{ height: 500 }} style={{ marginBottom: 6, borderRadius:40 }} animation="wave" variant="rectangular" /> 
    </span>
    
    {/* <Grid item sm={12} className={classes.mobile}>
                  <ImageComponentDiv
                    class="movie-content__poster__img"
                    image={movie.image}
                  />
                
                
                  <br />
                </Grid> */}
    </span>
  );
}

