import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosFetch from '../../../Interceptors2';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import tmdbApi from "../../../api/tmdbApi";
import apiConfig from "../../../api/apiConfig";

const CastList = (props) => {
  // const {category, id} = useParams();
  const id = props.id;
  const category = "movie";
  const url = "https://image.tmdb.org/t/p/w300";
  // const {id } = useParams();

  const [casts, setCasts] = useState([]);
  const hotelsettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 300,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    centerMode: false,
    variableWidth: false,
    spaceBetween: 10,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  async function getCast() {
    //    const {data } =
    axios
      .get(
        // `https://alert-lumen-api.herokuapp.com/api/casts/profile`,
        `https://api.themoviedb.org/3/movie/${id}/credits?api_key=42f731815804618872f9aeab9511cc98`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log(`CREDITS :  ${JSON.stringify(resp.data)}`);
        setCasts(resp.data.cast);
        // setMovie(resp.data);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    const getCredits = async () => {
      const res = await tmdbApi.credits(category, props.id);
      setCasts(res.cast.slice(0, 5));
    };
    getCredits();
    getCast();
  }, [category, props.id]);

  if (props.casts.length == 0) {
    return "";
  } else {
    return (
      <div>
        <div className="section__header">
          <h2 style={{ color: "#fff", marginTop:30 }}>Casts</h2> <br />
          <br />
        </div>
        <Slider {...hotelsettings} style={{ color: "#fff" }}>
          {props.casts.map((cast, id) => (
            <div key={id} className="casts__item">
              <div
                className="casts__item__img"
                style={{ backgroundImage: `url("${cast.user.profile_pic}")` }}
              ></div>
              {/* backgroundImage: `url("${`${url}/${movie.poster_path}`}")`, */}
              <Link to={`/profile/${cast.user.user_name}`}>
                <p
                  className="casts__item__name font-xss text-center"
                  style={{ color: "#fff" }}
                >
                  {cast.user.user_name}
                </p>
              </Link>
              <p
                  className="casts__item__name font-xss text-center"
                  style={{ color: "#fff" }}
                >
                [ {cast.role} ]
                </p>
            </div>
          ))}
        </Slider>
       
      </div>
    );
  }
};

export default CastList;
