import React, { Component, Fragment, useState, useEffect } from "react";
import { BrowserRouter, useHistory, Link } from "react-router-dom";
import axios from "axios";
import Load from "./Load";
import {
  useReactMediaRecorder,
  ReactMediaRecorder,
} from "react-media-recorder";
import { useCallback } from "react";
import Appfooter from "./Appfooter";
import Header from "./Header";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Load2 from "./Load2";
import {
  EmailIcon,
  WhatsappIcon,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import useScreenRecorder from "use-screen-recorder";

// import { useHistory, useLocation } from "react-router-dom";

//
export default function RecordScreen() {
  let history = useHistory();
  const [showTags, setShowTags] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const firstName = window.localStorage.getItem("first_name");
  const [video, setVideo] = useState("");
  const Input = styled("input")({
    display: "none",
  });

  const {
    blobUrl,
    pauseRecording,
    resetRecording,
    resumeRecording,
    startRecording,
    status,
    stopRecording,
  } = useScreenRecorder({ audio: true });

  useEffect(() => {}, []);

  return (
    <Fragment>
      <div className="main-wrapper">
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="middle-wrap">
                <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                  <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                    <Link to="/settings" className="d-inline-block mt-2">
                      <i className="ti-arrow-left font-sm text-white"></i>
                    </Link>
                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                      Let's See What Is Wrong
                    </h4>
                  </div>
                  <div className="card-body p-lg-5 p-4 w-100 border-0">
                    {/* <div className="d-flex"> */}

                    <h4>
                      Welcome {firstName}, We've opened this in a new page for
                      your ease. Please take note of the quick tips below.
                    </h4>
                    <ul>
                      <li>
                        1 -> Once you click the record button, please go to your previous
                        page and record what you wish to report. You can always
                        come back here to cancel and send us your final
                        recording.
                      </li>{" "}
                      <br />
                      <li>
                        2 -> Do not refresh this current page if you are already
                        recording so you don't loose what you've recorded.
                      </li>{" "}
                      <br />
                      <li>
                        3 -> Once you are done recording, click on the buttons
                        on the videoplayer to download. If you do not see a
                        download button, give it a quick minute, or skip the
                        video to the end
                      </li>{" "}
                      <br />
                      <li>
                        4 -> You can share your recording with us on whatsapp.
                        We're currently working on other ways to make this
                        process easier for you. Thank you for your support
                      </li>{" "}
                      <br />
                    </ul>

                    <br />

                    {isRecorded ? (
                      <>
                        {/* <br /> */}
                        <video src={blobUrl} controls autoPlay loop style={{width:700, height:500}} />
                        <br />
                        <br />
                        <p>
                          Awesome! Click on the download button inside the video
                          player to download this and send to us on whatsapp or
                          email. If you can't find the download button, you can
                          skip the video to the last and wait for some seconds
                          then try downloading.
                        </p>

                        <br />

                        <WhatsappShareButton
                          // url={`https://alarrt.com/`}
                          // title={`I'm sharing a video recording to you for technical assiatnce`}
                          // hashtag="#alarrt"
                        >
                          
                          <a 
                          href='https://wa.me/message/3VHYR6VAI3MSD1'
                          target='_blank'>
                          <span className="btn-round-lg pointer bg-whatsup">
                            <i className="font-xs feather-message-circle text-white"></i>
                          </span>
                          </a>
                          {/* <WhatsappIcon size={24} /> */}
                        </WhatsappShareButton>

                        <EmailShareButton
                          url={`https://alarrt.com/`}
                          title={`I'm sharing a video recording to you for technical assiatnce`}
                          hashtag="#alarrt"
                        >
                          <span className="btn-round-lg pointer bg-twiiter">
                            <i className="font-xs feather-mail text-white"></i>
                          </span>
                          {/* <EmailIcon size={24} /> */}
                        </EmailShareButton><br/>
                        <p>send to use as an attachment at appsupport@alarrt.com</p>
                        <br/><br/>

                        <Button
                              variant="contained"
                              component="span"
                              color="primary"
                              // onClick={resetRecording}
                              onClick={() => {
                                resetRecording()
                                setIsRecording(false);
                                setIsRecorded(false);
                                // window.location.reload()
                              }}
                            >
                              Record Another
                            </Button>
                      </>
                    ) : (
                      <>
                        {isRecording ? (
                          <>
                            <br />
                            <h4>Status of your recording -> {status}</h4><br/>
                            <p>Remember, don't refresh or move away from this page, use the previous page instead</p>
                            <br />

                            <Button
                              variant="contained"
                              component="span"
                              color="secondary"
                              onClick={() => {
                                stopRecording();
                                setIsRecording(false);
                                setIsRecorded(false);
                              }}
                            >
                              Cancel Recording
                            </Button>

                            <Button
                              variant="contained"
                              component="span"
                              color="secondary"
                              onClick={() => {
                                stopRecording();
                                setIsRecording(false);
                                setIsRecorded(true);
                              }}
                            >
                              Stop Recording
                            </Button>

                            <Button
                              variant="contained"
                              component="span"
                              onClick={() => {
                                pauseRecording();
                              }}
                            >
                              Pause Recording
                            </Button>
                            <Button
                              variant="contained"
                              component="span"
                              color="primary"
                              onClick={() => {
                                resumeRecording();
                              }}
                            >
                              Resume Recording
                            </Button>
                          </>
                        ) : (
                          <>
                            <p>
                              If you are encountering any technical issue and
                              want to report or request for assitance, You can take a screenshot and send to us via whatsapp, or email. You can also click the
                             record button below to record your screen. Clicking it will open a modal where you can select your screen. You should click share to start the recording. When
                              you are done, come back to this page and click
                              stop recording. If your recording status is <b>Error</b> this means your phone browser is unsupported. Try using you PC or just send a screenshot of the issue to us on whatsapp.
                            </p>
                            <br />
                            <Button
                              variant="contained"
                              component="span"
                              color="primary"
                              onClick={startRecording}
                              onClick={() => {
                                startRecording();
                                setIsRecording(true);
                              }}
                            >
                              Record Your Screen
                            </Button>

                              <br/><br/>
                            <WhatsappShareButton

                          // url={`https://alarrt.com/`}
                          // title={`I'm sharing a file with you for technical assiatnce`}
                          // hashtag="#alarrt"
                        >
                          <a href='https://wa.me/message/3VHYR6VAI3MSD1'
                          target='_blank'>
                          <span className="btn-round-lg pointer bg-whatsup">
                            <i className="font-xs feather-message-circle text-white"></i>
                          </span>
                          </a>
                          {/* <WhatsappIcon size={24} /> */}
                        </WhatsappShareButton>

                        <EmailShareButton
                          url={`https://alarrt.com/`}
                          title={`I'm sharing a file with you for technical assiatnce`}
                          hashtag="#alarrt"
                        >
                          <span className="btn-round-lg pointer bg-twiiter">
                            <i className="font-xs feather-mail text-white"></i>
                          </span>
                          {/* <EmailIcon size={24} /> */}
                        </EmailShareButton><br/><br/>
                        <p>Use the buttons above to send your screenshots to us on whatsapp or as an attachment to appsupport@alarrt.com</p>
                          </>
                        )}
                      </>
                    )}

                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* // <Popupchat /> */}
      <Appfooter />
    </Fragment>
  );
}
// }
