import { Component, useState, useEffect } from "react";
import React from "react";
import "./online.css";
import Online from "./Online";
import { Users } from "../../dummyData";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import Load from "../../components/Load";
import { useHistory, Link } from "react-router-dom";

export default function RecentUsers() {
  const [users, setUsers] = useState([]);
  const user_name = window.localStorage.getItem("user_name");
  const [fetching, setFetching] = useState(false);
  const [following, setFollowing] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  async function getUsersLoad() {
    setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/all", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setUsers(resp.data);
        setFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function getUsers() {
    // setFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/all", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data));
        setUsers(resp.data);
        setFollowing(false);
        // setFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function toogleFollow(user_name) {
    setFollowing(true);
    const access_token = await window.localStorage.getItem("access_token");
    console.log(user_name);
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        getUsers();
     
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    getUsersLoad();
    // getTags();
    // getAlertCategories();
  }, []);

  if (fetching == true) {
    return <Load />;
  }
  if (users.length == 0) {
    return (
      <>
        <Load />
        <br />
        <br />
      </>
    );
  } else {
    return (
      <>
        <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
          <div className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 flex items-baseline justify-between py-4 px-6 dark:border-gray-800">
            <h2 className="font-semibold text-lg">Suggested Users To Follow</h2>
            {/* <a href={`/${user_name}/users`}> View All</a> */}
          </div>

          {users.map((user) => (
            <div className="divide-gray-300 divide-gray-50 divide-opacity-50 divide-y px-4 dark:divide-gray-800 dark:text-gray-100"
            key={user.id}>
              <div className="flex items-center justify-between py-3">
                <div className="flex flex-1 items-center space-x-4">
                  <a href={`/profile/${user.user.user_name}`}>
                    <img
                      src={user.user.profile_pic}
                      className="bg-gray-200 rounded-full w-10 h-10"
                      onError={fixImage}
                    />
                  </a>
                  <Link to={`/profile/${user.user.user_name}`}>
                    <div className="flex flex-col">
                      <span className="block capitalize font-semibold">
                        {" "}
                        {user.user.first_name} {user.user.last_name}
                      </span>
                      <span className="block capitalize text-sm">
                        {" "}
                        @{user.user.user_name}{" "}
                      </span>
                    </div>
                  </Link>
                </div>

                {user.user.is_active == "false" ? (
                  <span className="text-danger ml-5">( Deactivated )</span>
                ) : (
                  <>
                    {following && currentUser == user.user.id ? (
                        <button
                        href='#'
                        // onClick={toogleFollow(user.user_name)}
                        className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-primary-600 hover:text-primary hover:border-primary-600 dark:border-gray-800"
                      >
                        just a sec...
                      </button>
                      
                    ) : (
                      <a
                      href="#t"
                        onClick={() => {
                            setCurrentUser(user.user.id)    
                            toogleFollow(user.user.user_name)
                        }}
                        // onClick={toogleFollow(user.user_name)}
                        className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-primary-600 hover:text-primary hover:border-primary-600 dark:border-gray-800"
                      >
                        {user.action}
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
<br/><br/>
<a
href="#t"
            onClick={() => getUsersLoad()}
            style={{
              display: "flex",
              alignSelf: "center",
              marginBottom: 10,
              marginLeft: 30,
              marginRight: 30,
            }}
            // onClick={toogleFollow(user.user_name)}
            className="text-center border border-gray-200 font-semibold px-4 py-1 rounded-full hover:text-current dark:border-gray-800"
          >
            {" "}
            Refresh
          </a>

          <br />
          <br />
        </div>
        <br />

        {/* <h4 className="rightbarTitle">Recently users</h4>
        <ul className="rightbarFriendList">
      
          <Online user={users} />
        
        </ul> */}
      </>
    );
  }
}
