import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Load from "../components/Load";
import axios from "axios";

export default function ChannelsOld() {
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  const [userName, setUserName] = useState("");

  async function getProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    // console.log(`state data = ${JSON.stringify(user_name.state.user_name)}`)
    axios
      .get(`https://alert-lumen-api.herokuapp.com/api/users/profile`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(resp.data.user.user_name);
        setUserName(resp.data.user.user_name);
        // console.log(userID, userUN, userFN, userLN);
        //
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function subscribeToogle(id) {
    // console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    let result = await axios
      .post(
        `https://alert-lumen-api.herokuapp.com/api/channels/${id}/subscribe`,
        userName,
        {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        }
      )
      .then((resp) => {
        getAllChannels();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAllChannels() {
    setFetchingChannels(true);
    // console.log(` 1 ${fetchingAlerts}`)
    const access_token = await window.localStorage.getItem("access_token");
    axios
      .get(`https://alert-lumen-api.herokuapp.com/api/channels`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setChannels(resp.data);
        setFetchingChannels(false);
        // console.log(JSON.stringify(resp.data));
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }
  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    getAllChannels();
    getProfile()
  }, []);

  if (fetchingChannels == true) {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Channels"
                    isChannel={"Create A Channel"}
                    channelAdmin={adminId}
                  />
                  <Load />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }

  if (channels.length == 0) {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Channels"
                    isChannel={"Create A Channel"}
                    channelAdmin={adminId}
                  />
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ textAlign: "center", lineHeight: 2 }}
                  >
                    Hello no channel has been created yet. Be the first to do so
                    now!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title="Latest Channels"
                    isChannel={"Create A Channel"}
                    channelAdmin={adminId}
                  />

                  <div className="row ps-2 pe-1">
                    {channels.map((channel, id) => (
                      <>
                      <div
                        key={channel.channel.id}
                        className="col-md-4 col-sm-6 pe-2 ps-2"
                      >
                        {/* <p> {channel.channel.id} </p> */}
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div className="card-body d-block w-100 p-4 text-center">
                            <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1">
                              {channel.channel.channel_pic === null ? (
                                <img
                                  onError={fixImage}
                                  src={
                                    "https://alert-app-v1.s3.amazonaws.com/user.png"
                                  }
                                  alt="avater"
                                  className="float-right p-1 bg-white rounded-circle w-100"
                                  style={{ objectFit: "contain" }}
                                />
                              ) : (
                                <img
                                  onError={fixImage}
                                  src={channel.channel.channel_pic}
                                  alt="avater"
                                  className="float-right p-1 bg-white rounded-circle w-100"
                                />
                              )}
                            </figure>
                            <div className="clearfix"></div>
                            <Link
                              to={{
                                pathname: `/channel/${channel.channel.slug}`,
                                state: { id: channel.channel.id },
                              }}
                            >
                              <h4 className="fw-700 font-xss mt-3 mb-0">
                                {channel.channel.name}{" "}
                              </h4>
                            </Link>
                            <Link
                              to={`/profile/${channel.channel.admin.user_name}`}
                            >
                              {" "}
                              <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                                Created By:{" "}
                                <span className="text-primary-500">
                                  {channel.channel.admin.user_name}
                                </span>{" "}
                              </p>{" "}
                            </Link>
                            <ul className="d-flex align-items-center justify-content-center mt-1">
                              <li className="m-2">
                                <h4 className="fw-700 font-sm">
                                {channel.channel.subscribers.length}
                                  <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                    Subscribers
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                                <h4 className="fw-700 font-sm">
                                  50{" "}
                                  <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                    Follower
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                                <h4 className="fw-700 font-sm">
                                  13{" "}
                                  <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                                    Followings
                                  </span>
                                </h4>
                              </li>
                            </ul>
                            {/* <ul className="d-flex align-items-center justify-content-center mt-1">
                                                        {channel.channel.badge1 ? <li className="m-1"><img src={`/assets/images/${channel.badge1}`} alt="icon" /></li> : ''}
                                                        {channel.badge2 ? <li className="m-1"><img src={`/assets/images/${channel.badge2}`} alt="icon" /></li> : ''}
                                                        {channel.badge3 ? <li className="m-1"><img src={`/assets/images/${channel.badge3}`} alt="icon" /></li> : ''}
                                                        {channel.badge4 ? <li className="m-1"><img src={`/assets/images/${channel.badge4}`} alt="icon" /></li> : ''}
                                                    </ul> */}
                            <a
                               onClick={() => subscribeToogle(channel.channel.id)}
                               style={{alignSelf: 'center'}}
                              className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                            >
                              {channel.action}
                            </a>
                          </div>
                        </div>
                      </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

// export default ChannelsOld;
