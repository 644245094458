import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import Profiledetail from "../components/Profiledetail";
import Profilephoto from "../components/Profilephoto";
import Events from "../components/Events";
import ProfilecardTwo from "../components/ProfilecardTwo";
import CategoryAlerts from "../components/CategoryAlerts";

import Createpost from "../components/Createpost";
import UserPosts from "../components/UserPosts";
import Load from "../components/Load";
import { Route, useParams } from "react-router-dom";
import CreateAlertFab from "../components/CreateAlertFab";

export default function AlertCategory() {
  const { slug } = useParams(["slug"]);
  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                <CategoryAlerts slug={slug} url={'alert_category'} type={'category'} />
                <CreateAlertFab />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      <Appfooter />
    </Fragment>
  );
}

// export default Authorpage;
