import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";


var firebaseConfig = {
    apiKey: "AIzaSyBgSZnya-ncOamTL8Y2ZmmIGBTMq6F0Aks",
    authDomain: "alarrt-cloud-messaging.firebaseapp.com",
    projectId: "alarrt-cloud-messaging",
    storageBucket: "alarrt-cloud-messaging.appspot.com",
    messagingSenderId: "642541893414",
    appId: "1:642541893414:web:4a5eee1992e9730543f3e7",
    measurementId: "G-DEFDBJCE1K"
};

// var firebaseConfigv2 = {
//   apiKey: "AIzaSyBYIVgLNL25IRSnuSsyubDcQKxP0lIXa5Q",
//   authDomain: "alarrt-fcm-v2.firebaseapp.com",
//   projectId: "alarrt-fcm-v2",
//   storageBucket: "alarrt-fcm-v2.appspot.com",
//   messagingSenderId: "470299043921",
//   appId: "1:470299043921:web:65319f2220aa51847fb070"
// };

// var firebaseConfig old = {
//   apiKey: "AIzaSyBli82IAAMBIxDvBBgdr4SWx0GWXNbtvQY",
//   authDomain: "alarrt-fcm.firebaseapp.com",
//   projectId: "alarrt-fcm",
//   storageBucket: "alarrt-fcm.appspot.com",
//   messagingSenderId: "173528501426",
//   appId: "1:173528501426:web:617285e0c13b7fc6f70dab"
// };

// initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BKU_fD0DmSkXLwcQSyHD5o8RFZqGIcNh9QjyxoukwFUxvFD1OxeGTBc13RozMuJYMVlVi-WWyLanZifdYxqHy0g'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      window.localStorage.setItem('token', currentToken)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
// e.preventDefault()

  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('recieved from fierbase.js')
      resolve(payload);
    });
});


// import firebase from 'firebase';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const config = {
//   apiKey: "AIzaSyBli82IAAMBIxDvBBgdr4SWx0GWXNbtvQY",
//   authDomain: "alarrt-fcm.firebaseapp.com",
//   projectId: "alarrt-fcm",
//   storageBucket: "alarrt-fcm.appspot.com",
//   messagingSenderId: "173528501426",
//   appId: "1:173528501426:web:617285e0c13b7fc6f70dab"
// };



// const firebaseApp = firebase.initializeApp(config);
// initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);

// export const getToken = (setTokenFound) => {
//     return getToken(messaging, {vapidKey: 'GENERATED_MESSAGING_KEY'}).then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);
//         setTokenFound(true);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//         setTokenFound(false);
//         // shows on the UI that permission is required 
//       }
//     }).catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//       // catch error while creating client token
//     });
//   }
  

// export default firebase;