import React, { Component, useState, useEffect } from "react";
import "./comment.css";
import { PermMedia, Label, Room, EmojiEmotions } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles, Snackbar } from "@material-ui/core";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import { Link } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import Alert from "@mui/material/Alert";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));

export default function FavComment(props) {
  const classes = useStyles();
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [currentComment, setCurrentComment] = useState("");

  const [message, setMessage] = useState("");
  const [userPic, setUserPic] = useState("");

  async function favComment(id) {
    setIsFavoriting(true)
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    // `https://alert-lumen-api.herokuapp.com/api/alerts/${alert.slug}`
    // const alertSlug = JSON.stringify(slug);
    axiosFetch
      .post(`/comment/fav/${id}/${props.favUrl}`, id, {
        // `/${props.favUrl}/${props.movieId}/fav/comment/${id}`,
        // id,
        // {
        headers: {
          Accept: "*/*",
          // "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
   
        props.functionProp();
        console.log(`hiii ${resp.data}`);
        setIsFavorited(true);
        setIsFavoriting(false)
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function getProfile() {
    setIsFetching(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(JSON.stringify(resp.data.user.profile_pic));
        setUserPic(resp.data.user.profile_pic);
        setIsFetching(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function handleClose2() {
    // setIsLiked(false);
    setIsFavorited(false);
  }

  async function postComment() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);
    formData.append("status", "sent");
    // formData.append("status", channelStatus);

    axiosFetch
      .post(`/${props.url}/${props.Id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(`hi ${resp.data}`);
        setMessage("");
        setIsCreating(false);
        props.functionProp();
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <div>
        {props.favComments.map((comment) => (
          <div className="flex" style={{ marginBottom: 10 }}>
            <div className="w-10 h-10 rounded-full relative flex-shrink-0">
              <Link to={`/profile/${comment.user.user_name}`}>
                <img
                  src={comment.user.profile_pic}
                  alt=""
                  // className="shadow-sm rounded-circle w45"
                  style={{ marginRight: 10, border: "2px solid #045de9" }}
                  className="absolute h-full rounded-full w-full  w45"
                />
              </Link>
            </div>
            <div className="text-gray-700 py-2 px-3 rounded-md bg-gray-100 h-full relative lg:ml-5 ml-2 lg:mr-20  dark:bg-gray-800 dark:text-gray-100">
              {/* <p className="leading-6"> */}
              {/* <span style={{ marginRight: 20 }}> */}
              <Link to={`/profile/${comment.user.user_name}`}>
                @{comment.user.user_name}
              </Link>
              <ShowMoreText
                lines={1}
                more="Read more"
                less="Show less"
                className="leading-6"
                // className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                anchorClass="my-anchor-css-class"
                width={300}
                expanded={false}
                truncatedEndingComponent={"... "}
              >
                {comment.message}
              </ShowMoreText>

              {isFavoriting && currentComment == comment.id ? (
                <HourglassEmptyRoundedIcon />
              ) : (
                <>
                  {props.adminId == props.userId[0] ? (
                    <RemoveCircleOutlineIcon
                      onClick={() => {
                        setCurrentComment(comment.id)
                        favComment(comment.id)}}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
              {/* {alert(props.userId)} */}

              {/* <p>
                {" "}
                Admin id {props.adminId} UserId {props.userId}
              </p> */}
              {/* </span> */}
              {/* <urna className="i uil-heart"></urna>{" "}
                <i className="uil-grin-tongue-wink"> </i>{" "} */}
              {/* </p> */}
              {/* <urna className="i uil-heart"></urna>{" "}
              <i className="uil-grin-tongue-wink"> </i>{" "} */}
              <div className="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
            </div>
            <br /> <br />
          </div>
        ))}
      </div>
      <br />
      {isFavorited ? (
        <Snackbar
        open={isFavorited}
        autoHideDuration={2000}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Alert onClose={handleClose2} variant="filled" severity="success">
          Comment removed from favorites successfully!
        </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="border-t pt-4 space-y-4 dark:border-gray-600"></div>
      <br />

      <div className="shar">
        <div className="shareWrapper">
          <div className="shareTop">
            {/* <figure className="avatar me-3"> */}
            {isFetching ? (
              <img
                className="shareProfileImg"
                src="/assets/images/user.png"
                alt=""
              />
            ) : (
              <>
                <img
                  src={userPic}
                  alt="avater"
                  className="shadow-sm rounded-circle w45"
                  style={{ marginRight: 10, border: "2px solid #045de9" }}
                />
                {/* </figure> */}
              </>
            )}

            <TextField
              id="standard-multiline-flexible"
              label="Comment..."
              multiline
              className="shareInput"
              // maxRows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            {/* {props.alert} */}
            {isCreating ? (
              <HourglassEmptyRoundedIcon />
            ) : (
              <SendRoundedIcon className="pointer" onClick={postComment} />
            )}
          </div>

          <div className="shareBottom">
            <div className="shareOptions"></div>
          </div>
        </div>
      </div>
    </>
  );
}
