import React, { Component, useState, Fragment, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import "./singleMovie.css";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Pagetitle from "../../components/Pagetitle";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { MDBRipple } from "mdb-react-ui-kit";
import Slider from "react-slick";
import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { category, movieType, tvType } from "../../api/tmdbApi";
import MovieCard from "../../moviesComponents/movie-card/MovieCard";
import MovieGrid from "../../moviesComponents/movie-grid/MovieGrid";
import MovieList from "../../moviesComponents/movie-list/MovieList";
import MovieCard2 from "../../moviesComponents/movie-card/MovieCard2";
import Detail from "../../pages/movies/detail/Detail";
import SeriesDetail from "../../pages/movies/detail/SeriesDetail";
import Load from "../Load";
import HeroSlide from "../../moviesComponents/hero-slide/HeroSlide";
import queryString from "query-string";

export default function SingleMovie(props) {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [gottonQuery, setGottonQuery] = useState(false);
  // const [channelSlug, setChannelSlug] = useState("");
  // const [adminId, setAdminId] = useState("");
  // const [movieId, setMovieId] = useState("");
  const [refreshed, setRefreshed] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [movie, setMovie] = useState([]);
  const [favComments, setFavComments] = useState([]);
  const [series, setSeries] = useState([]);
  const [video, setVideo] = useState("");
  const [views, setViews] = useState("");
  const [trailer, setTrailer] = useState("");
  const [comments, setComments] = useState([]);
  const [casts, setCasts] = useState([]);
  const [likers, setLikers] = useState([]);
  const [likedStatus, setLikedStatus] = useState("");
  const [ratings, setRatings] = useState("");
  const [isSub, setIsSub] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [isPurchased, setIsPurchased] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelName, setChannelName] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [purchasedSeries, setPurchasedSeries] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const location = useLocation();
  let history = useHistory()
  const {search} = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname)
  const pathname = location.pathname
  
  const localAdminId = queryParams.get("a");
  localStorage.setItem('a', localAdminId);
  const localChannelSlug = queryParams.get("channel");
  localStorage.setItem('channel', localChannelSlug);
  const localMovieId = queryParams.get("w");
  localStorage.setItem('w', localMovieId);
 
  const adminId = localStorage.getItem('a');
  const channelSlug = localStorage.getItem('channel');
  const movieId = localStorage.getItem('w');
  //   const url = 'movie';
  // const { url, slug } = useParams();
  

  // const adminId = location.state.adminId;
  const [query1, setQuery1] = useState("");
  const [query2, setQuery2] = useState("");
  const [query3, setQuery3] = useState("");

 async function getQuery() {
  //  setGottonQuery(false)
   
  // const  queryParams = new URLSearchParams(location.search);
//    console.log(`hiii ${queryParams}`);
//    const secondQuery = queryParams.get("w");
//    const firstQuery = queryParams.get("channel");
//  const thirdQuery  = queryParams.get("a");
 
    // setChannelSlug(firstQuery);
    // setMovieId(secondQuery);
    // setAdminId(thirdQuery);
    // window.location.reload(false)
    console.log(`movie is ${movieId}`)

    // if (queryParams.get('w') === null) {
    //   getQuery()
    // }
  


      // setQuery1(firstQuery);
    // setQuery2(secondQuery);
    // setQuery3(thirdQuery);
  }
  // getMovieDetailsLoad()
//  setGottonQuery(true)
 
    // alert(`channel slug is ${channelSlug}`)
    // getMovieDetailsLoad()

  // getQuery().then((resp) => {
  //   getMovieDetailsLoad()
  // })

//   function refreshPage(){ 
//     window.location.reload(false); 
// }

  async function getMovie(){
  
      getMovieDetailsLoad()
    
  }

  async function getMovieDetailsLoad() {
    const access_token = await window.localStorage.getItem("access_token");
    // const reloadCount = localStorage.getItem('reloadCount');
    // if(reloadCount < 1) {
    //   localStorage.setItem('reloadCount', String(reloadCount + 1));
    //   console.log('reload count is less than one')
    //   // history.push(`${pathname}`)
    //   // history.go()
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem('reloadCount');
    // }
    
    if (url == "series") {
      console.log("it is a series!");
      setIsFetching(true);
      axiosFetch
        .get(
          `/movie/series/show/${movieId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          // console.log(resp.data.series)
          setSeries(resp.data.series);
          setLikedStatus(resp.data.likedStatus);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setMovie(resp.data);
          setIsSub(resp.data.isSubscribed);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setSubAccount(resp.data.subAccount)
          setIsPurchased(resp.data.purchased);
          setViews(resp.data.views);
          setPublicKey(resp.data.public_key);
          setVideo(resp.data.series.video);
          setTrailer(resp.data.series.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          setIsFetching(false);
          
          //   setTrailer(resp.data.movie.trailer);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    } else {
      console.log("it is a movie or video!");
      setIsFetching(true);
      axiosFetch
        .get(
          `/movie/show/single/${movieId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          // alert('gotten movie 1')
          // console.log(JSON.stringify(resp.data))
          setMovie(resp.data.movie);
          setLikedStatus(resp.data.likedStatus);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setVideo(resp.data.movie.video);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setSubAccount(resp.data.subAccount)
          setIsPurchased(resp.data.purchased);
          setSeriesSlug(resp.data.seriesSlug);
          setSeriesId(resp.data.seriesId);
          setPurchasedSeries(resp.data.purchasedSeries);
          setPublicKey(resp.data.public_key);
          setTrailer(resp.data.movie.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          setIsFetching(false);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    }
  // gotten query else
  // }
  }

  async function getMovieDetails() {
    const access_token = await window.localStorage.getItem("access_token");
    if (url == "series") {
      console.log("it is a series!");
      //   setIsFetching(true);
      // await getQuery()
      axiosFetch
        .get(
          `/movie/series/show/${movieId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          // console.log(resp.data.series)
          setSeries(resp.data.series);
          setLikedStatus(resp.data.likedStatus);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setMovie(resp.data);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setSubAccount(resp.data.subAccount)
          setIsPurchased(resp.data.purchased);
          setPublicKey(resp.data.public_key);
          setVideo(resp.data.series.video);
          setTrailer(resp.data.series.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          //   setIsFetching(false);
          //   setTrailer(resp.data.movie.trailer);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    } else {
      console.log("it is a movie or video!");
      // setIsFetching(true);
      axiosFetch
        .get(
          `/movie/show/single/${movieId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token,
            },
          }
        )
        .then((resp) => {
          // console.log(JSON.stringify(resp.data))
          // alert('gotten movie 2')
          // console.log(JSON.stringify(resp.data))
          setMovie(resp.data.movie);
          setLikedStatus(resp.data.likedStatus);
          setLikers(resp.data.likers);
          setFavComments(resp.data.favComments);
          setVideo(resp.data.movie.video);
          setViews(resp.data.views);
          setIsSub(resp.data.isSubscribed);
          setSubAccount(resp.data.subAccount)
          setSeriesSlug(resp.data.seriesSlug);
          setChannelPrice(resp.data.channelPrice);
          setChannelName(resp.data.channelName);
          setSeriesId(resp.data.seriesId);
          setIsPurchased(resp.data.purchased);
          setPurchasedSeries(resp.data.purchasedSeries);
          setPublicKey(resp.data.public_key);
          setTrailer(resp.data.movie.trailer);
          setComments(resp.data.comments);
          setCasts(resp.data.casts);
          setRatings(resp.data.rates);
          //   setIsFetching(false);
        })
        .catch((error) => console.log(`Error Seen : ${error}`));
    }
    //   .then((resp) => {
    //     setMovie(resp.data.movie);
    //     setVideo(resp.data.movie.video);
    //     setTrailer(resp.data.movie.trailer);
    //   })
    //   .catch((error) => alert(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getMovieDetailsLoad();
      // console.log(`movieid is ${movieId}`)
      // alert(url)
  }, [  ]);

  // if (movieId === null) {
  //   getQuery()
  // }
  // else{
  //   getMovieDetailsLoad()
  // }
  if (isFetching == true) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Load />
                  {/* <h1> helloooo</h1> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#0f0f0f" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  {
                    //   url == 'movie'?
                    url == "movie" || url == "video" ? (
                      <Pagetitle
                        title={`Channel ${url}s`}
                        // {`Channel ${movie.type}s`}
                        bg="#152238"
                        color="#fff"
                        back="Go Back To Channel"
                        channelUrl={`${url}s`}
                        channelSlug={channelSlug}
                        searchUrl="movie/search"
                        redirect="movie"
                      />
                    ) : (
                      <Pagetitle
                        title={`Channel ${url}`}
                        // {`Channel ${movie.type}s`}
                        bg="#152238"
                        color="#fff"
                        back="Go Back To Channel"
                        channelUrl='series'
                        channelSlug={channelSlug}
                        searchUrl="movie/series/search"
                        redirect="series"
                      />
                    )
                  }

                  <div
                    //   className="row ps-2 pe-1"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {/* <h1>admin id {adminId}</h1> */}
                    {/* <HeroSlide /> */}

                    {url == "movie" || url == "video" ? (
                      <Detail
                        movie={movie}
                        likers={likers}
                        likedStatus={likedStatus}
                        movieType={url}
                        seriesType=""
                        channelId={movie.channel_id}
                        video={video}
                        trailer={trailer}
                        comments={comments}
                        isSub={isSub}
                        subAccount={subAccount}
                        isPurchased={isPurchased}
                        seriesSlug={seriesSlug}
                        seriesId={seriesId}
                        purchasedSeries={purchasedSeries}
                        publicKey={publicKey}
                        ratings={ratings}
                        views={views}
                        channelSlug={channelSlug}
                        channelPrice={channelPrice}
                        channelName={channelName}
                        favComments={favComments}
                        casts={casts}
                        adminId={adminId}
                        userId={userId}
                        functionProp={() => getMovieDetails()}
                      />
                    ) : (
                      <SeriesDetail
                        series={series}
                        movieType={url}
                        likers={likers}
                        likedStatus={likedStatus}
                        channelId={series.channel_id}
                        seriesMovies={series.movies}
                        channelSlug={channelSlug}
                        channelPrice={channelPrice}
               channelName={channelName}
                        video={video}
                        trailer={trailer}
                        views={views}
                        isSub={isSub}
                        subAccount={subAccount}
                        isPurchased={isPurchased}
                        // purchasedSeries={purchasedSeries}
                        publicKey={publicKey}
                        // channelId ={channelId}
                        comments={comments}
                        casts={casts}
                        ratings={ratings}
                        favComments={favComments}
                        adminId={adminId}
                        userId={userId}
                        functionProp={() => getMovieDetails()}
                        // functionProp={() => getMovieDetails()}
                        // video={video}
                        // trailer={trailer}
                      />
                    )}
                    {console.log(isPurchased)}
                    {/* <Detail
                      movie={movie}
                      movieType={url}
                      channelId={movie.channel_id}
                      video={video}
                      trailer={trailer}
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}
