import React, { Component, Fragment } from "react";

import Leftnav from "../components/Leftnav";
import { format, formatDistance, subDays } from "date-fns";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Header from "../components/Header";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import MovieGrid from "../moviesComponents/movie-grid/MovieGrid";
import Button from "../moviesComponents/button/Button";
import IconButton from "@mui/material/IconButton";

export default function UserSavedMovies(props) {
  let history = useHistory();
  const [fetchingChannels, setFetchingChannels] = useState("");
  const [channels, setChannels] = useState([]);
  // const [url, setUrl] = useState('');
  const [adminId, setAdminId] = useState(
    window.localStorage.getItem("user_id")
  );
  // const url = '';

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {
    // getAllCahnnels();
  }, []);

  if (props.movies.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello {props.user_name}! {props.message}
              </h5>
            </div>
          </div>
          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="row ps-2 pe-1"
          style={{ marginRight: 20, marginLeft: 20, marginBottom: 50 }}
        >
          <div
            className="row ps-2 pe-1"
            style={{ marginRight: 20, marginLeft: 20 }}
          >
            <h4 className="text-center">
              {" "}
              You've {props.message2} {props.series.length} series{" "}
            </h4>
            <br/><br/>
            {props.series.map((series, index) => (
              <div
                key={index}
                // className='movie-grid'
                className="col-md-6 col-xss-12 pe-2 ps-2"
                //   style={{paddingTop:180}}
              >
                <Link
                  to={{
                    pathname: `/channel/${series.channel.slug}/series/${series.slug}?channel=${series.channel.slug}&w=${series.id}&a=${series.channel.admin.id}`,
                  }}
                >
                  <div
                    className="movie-card overflow-hidden shadow-xss bg-gradiant-bottom"
                    style={{
                      backgroundImage: `url("${series.image}")`,
                    }}
                  >
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                      <Link
                        to={{
                          pathname: `/channel/${series.channel.slug}`,
                        }}
                      >
                        <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                          <img
                            src={series.channel.channel_pic}
                            alt="avater"
                            className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                          />
                        </figure>
                      </Link>
                      <div className="clearfix"></div>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
                        {series.title}
                      </h4>

                      <a
                        onClick={() => {
                          // console.log("clicked");
                          history.go({
                            pathname: `channel/${series.channel.slug}`,
                          });
                        }}
                      >
                        <Chip
                          className="pointer infotag"
                          color="primary"
                          size="small"
                          label={series.channel.name}
                          //   variant="outlined"
                          // clickable
                          // onClick={goToChannel(series.channel.slug)}
                        />
                      </a>
                      {/* {series.movie_id == null ? (
                      ""
                    ) : ( */}
                      <div
                        className="fw-500 text-white font-xssss mt-1 pointer"
                        style={{
                          display: "flex",
                          alignSelf: "center",
                          justifyContent: "center",
                          // marginTop: -20
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/channel/${series.channel.slug}/series/${series.slug}?channel=${series.channel.slug}&w=${series.id}&a=${series.channel.admin.id}`,
                          }}
                        >
                          {" "}
                          {/* Episode Of{" "} */}
                          <Chip
                            className="pointer infotag"
                            color="#fff"
                            size="small"
                            label={`Series: ${series.title}`}
                            //   variant="outlined"
                            clickable
                            onClick={() => {
                              history.push({
                                pathname: `/channel/${series.channel.slug}/series/${series.slug}?channel=${series.channel.slug}&w=${series.id}&a=${series.channel.admin.id}`,
                              });
                            }}
                          />
                        </Link>

                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                          {" "}
                        </span>
                      </div>
                      {/* )} */}
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                        {/* {movie.title} */}
                        Type: {series.type} - Pricing: {series.pricing}{" "}
                        {series.price}
                      </h4>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                        Purchased:{" "}
                        {formatDistance(
                          new Date(series.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </h4>
                    </div>
                  </div>
                </Link>
              </div>
              //   </div>
            ))}
          </div>
          <br />

          <h4 className="text-center">
            {" "}
            You've {props.message2} {props.movies.length} movies{" "}
          </h4><br/><br/>
          {props.movies.map((movie, index) => (
            <div
              key={index}
              // className='movie-grid'
              className="col-md-6 col-xss-12 pe-2 ps-2"
              //   style={{paddingTop:180}}
            >
              {movie.type == "movie" && (
                <>
                  {window.sessionStorage.setItem(
                    "url",
                    `/channel/${movie.channel.slug}/movie/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                  )}
                </>
              )}
              {movie.type == "video" && (
                <>
                  {window.sessionStorage.setItem(
                    "url",
                    `/channel/${movie.channel.slug}/video/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                  )}
                </>
              )}
              {movie.type == "episode" && (
                <>
                  {window.sessionStorage.setItem(
                    "url",
                    `/channel/${movie.channel.slug}/series/episode/${movie.slug}?channel=${movie.channel.slug}&w=${movie.id}&a=${movie.channel.admin.id}`
                  )}
                </>
              )}
              <Link
                to={{
                  pathname: window.sessionStorage.getItem("url"),
                }}
              >
                <div
                  className="movie-card overflow-hidden shadow-xss bg-gradiant-bottom"
                  style={{
                    backgroundImage: `url("${movie.image}")`,
                  }}
                >
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                    <Link
                      to={{
                        pathname: `/channel/${movie.channel.slug}`,
                      }}
                    >
                      <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                        <img
                          src={movie.channel.channel_pic}
                          alt="avater"
                          className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                        />
                      </figure>
                    </Link>
                    <div className="clearfix"></div>
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
                      {movie.title}
                    </h4>

                    <a
                      onClick={() => {
                        console.log("clicked");
                        history.go({
                          pathname: `channel/${movie.channel.slug}`,
                        });
                      }}
                    >
                      <Chip
                        className="pointer infotag"
                        color="primary"
                        size="small"
                        label={movie.channel.name}
                        //   variant="outlined"
                        // clickable
                        // onClick={goToChannel(movie.channel.slug)}
                      />
                    </a>

                    {movie.type == "episode" ? (
                      <>
                      <br/><br/>
                        <Link
                          to={{
                            pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
                          }}
                        >
                          {" "}
                          {/* Episode Of{" "} */}
                          <Chip
                            className="pointer infotag"
                            color="#fff"
                            size="small"
                            label={`Series: ${movie.series.title}`}
                            //   variant="outlined"
                            clickable
                            onClick={() => {
                              history.push({
                                pathname: `/channel/${movie.series.channel.slug}/series/${movie.series.slug}?channel=${movie.series.channel.slug}&w=${movie.series.id}&a=${movie.series.channel.admin.id}`,
                              });
                            }}
                          />
                        </Link>
                        </>
                      ) : (
                        ""
                      )}

                    <div
                      className="fw-500 text-white font-xssss mt-1 pointer"
                      style={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        // marginTop: -20
                      }}
                    >
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                        {" "}
                      </span>
                    </div>
                    {/* )} */}
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                      {/* {movie.title} */}
                      Type: {movie.type} - Pricing: {movie.pricing}{" "}
                      {movie.price}
                    </h4>
                    <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                      Released:{" "}
                      {formatDistance(new Date(movie.created_at), new Date(), {
                        addSuffix: true,
                      })}
                    </h4>
                  </div>
                </div>
              </Link>
            </div>

            //   </div>
          ))}
        </div>

        <br />

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Channels;
