import React from "react";
import { Component, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import "./widgets.css";
import SearchIcon from "@material-ui/icons/Search";
import {
  // Link,
  Avatar,
  Container,
  ImageList,
  ImageListItem,
  makeStyles,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import SearchBarV2 from "../SearchBarV2";
import FilterByLocation from "./FilterByLocation";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
  link: {
    marginRight: theme.spacing(2),
    color: "#555",
    fontSize: 16,
  },
}));

function AlertFilter(props) {
  const classes = useStyles();
  const [alertCategory, setAlertCategory] = useState([]);

  function getAlertCategories() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/alert_category/active", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAlertCategory(result);
        // getAlertCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    //  createAlert();
    // getAlertCategories();
  }, []);

  return (
    <div className="widgets">
      <div className="bg-white dark:bg-gray-900 shadow-md rounded-md overflow-hidden">
        <div
          style={{
            display: "flex",
            alignSelf: "flex-start",
            justifyContent: "flex-start",
            justifyItems: "flex-start",
          }}
          className="bg-gray-50 dark:bg-gray-800 border-b border-gray-100 py-4 px-6 dark:border-gray-800"
        >
          <SearchBarV2 searchUrl="alerts/search" redirect="alert" />
          <br />
          <br />
          {/* <p className='text-center'>You can search by location also (Nigeria, Lagos etc)</p> */}
        </div>
        <p
          className="text-center"
          style={{
            marginLeft: 20,
            marginTop: 3,
            display: "flex",
            alignSelf: "flex-start",
            justifyContent: "flex-start",
            justifyItems: "flex-start",
          }}
        >
          Search alarrts by location below (Nigeria, Lagos etc)
        </p>

        <div className="widgets__widgetContainer">
          <h2>Alarrts Created In The Last 24 Hrs</h2>
          <h5>Click to view alarrts by their category below</h5>
          <ul className="rightbarFriendList">
            {/* <Typography className={classes.title} gutterBottom>
            Popular Categories
          </Typography> */}
            <Divider flexItem style={{ marginBottom: 10 }} />

            {/* <TableContainer component={Paper}> */}
            <TableContainer>
              <Table sx={{ minWidth: 100 }} size="small" aria-label="simple table">
                <TableBody>
                  {props.alertCategory.map((value, index) => (
                    <TableRow
                      key={index}
                      // sx={{
                      //   "&:last-child td, &:last-child th": { border: 0 },
                      // }}
                    >
                      <TableCell align="left" style={{width: '40%'}}>
                        <Link
                          to={`/alert-category/${value.slug}`}
                          // className={classes.link}
                          className="rightbarFriendListItem"
                          variant="body2"
                        >
                          
                          {value.name}
                          
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        {value.active_alerts_count} Alarrts
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* {props.alertCategory.map((value, index) => (
              <div key={index}>
                <li className="rightbarFriendListItem">
                  <Link
                    to={`/alert-category/${value.slug}`}
                    className={classes.link}
                    // className=' text-white hover:text-primary '
                    variant="body2"
                  >
                    {value.name} - {value.active_alerts_count} Alarrts
                  </Link>
                </li>
              </div>
            ))} */}
          </ul>
          <br />
          <FilterByLocation />
          <br />
          {/* <p className="fw-600 font-xssss mt-0 text-primary">
            Only create a personal life threatening alert below. For other alert
            types, use the blue button at the bottom right part of your screen
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default AlertFilter;
