import React, { Component, useState, Fragment, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import axios from "axios";
import axiosFetch from "../../Interceptors2";
import "./singleMovie.css";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Pagetitle from "../../components/Pagetitle";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { MDBRipple } from "mdb-react-ui-kit";
import Slider from "react-slick";
import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { category, movieType, tvType } from "../../api/tmdbApi";
import MovieCard from "../../moviesComponents/movie-card/MovieCard";
import MovieGrid from "../../moviesComponents/movie-grid/MovieGrid";
import MovieList from "../../moviesComponents/movie-list/MovieList";
import MovieCard2 from "../../moviesComponents/movie-card/MovieCard2";
import Detail from "../../pages/movies/detail/Detail";
import SeriesDetail from "../../pages/movies/detail/SeriesDetail";
import Load from "../Load";
import HeroSlide from "../../moviesComponents/hero-slide/HeroSlide";

export default function SingleEpisode() {
  const userId = useState(window.localStorage.getItem("user_id"));
  const [movie, setMovie] = useState([]);
  const [favComments, setFavComments] = useState([]);
  const [series, setSeries] = useState([]);
  const [video, setVideo] = useState([]);
  const [views, setViews] = useState("");
  const [trailer, setTrailer] = useState([]);
  const [comments, setComments] = useState([]);
  const [casts, setCasts] = useState([]);
  const [ratings, setRatings] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [isSub, setIsSub] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [likers, setLikers] = useState([]);
  const [likedStatus, setLikedStatus] = useState("");
  const [isPurchased, setIsPurchased] = useState("");
  const [channelPrice, setChannelPrice] = useState("");
  const [channelName, setChannelName] = useState("");
  const [seriesSlug, setSeriesSlug] = useState("");
  const [seriesPricing, setSeriesPricing] = useState("");
  const [seriesId, setSeriesId] = useState("");
  const [purchasedSeries, setPurchasedSeries] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  // const channelSlug = queryParams.get("channel");
  // const movieId = queryParams.get("w");
  // const adminId = queryParams.get("a");

  const localAdminId = queryParams.get("a");
  localStorage.setItem("a", localAdminId);
  const localChannelSlug = queryParams.get("channel");
  localStorage.setItem("channel", localChannelSlug);
  const localMovieId = queryParams.get("w");
  localStorage.setItem("w", localMovieId);

  const adminId = localStorage.getItem("a");
  const channelSlug = localStorage.getItem("channel");
  const movieId = localStorage.getItem("w");

  async function getMovieDetailsLoad() {
    const access_token = await window.localStorage.getItem("access_token");
    // const reloadCount = localStorage.getItem('reloadCount');
    // if(reloadCount < 1) {
    //   localStorage.setItem('reloadCount', String(reloadCount + 1));
    //   console.log('reload count is less than one')
    //   // history.push(`${pathname}`)
    //   // history.go()
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem('reloadCount');
    // }
    setIsFetching(true);
    axiosFetch
      .get(`/movie/show/single/${movieId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setMovie(resp.data.movie);
        setFavComments(resp.data.favComments);
        setLikedStatus(resp.data.likedStatus);
        setLikers(resp.data.likers);
        setVideo(resp.data.movie.video);
        setViews(resp.data.views);
        setIsSub(resp.data.isSubscribed);
        setSubAccount(resp.data.subAccount);
        setChannelPrice(resp.data.channelPrice);
        setChannelName(resp.data.channelName);
        setSeriesSlug(resp.data.seriesSlug);
        setSeriesPricing(resp.data.seriesPricing);
        setSeriesId(resp.data.seriesId);
        setIsPurchased(resp.data.purchased);
        setPurchasedSeries(resp.data.purchasedSeries);
        setPublicKey(resp.data.public_key);
        setTrailer(resp.data.movie.trailer);
        setComments(resp.data.comments);
        setCasts(resp.data.casts);
        setRatings(resp.data.rates);
        setIsFetching(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getMovieDetails() {
    const access_token = await window.localStorage.getItem("access_token");
    // setIsFetching(true);
    axiosFetch
      .get(`/movie/show/single/${movieId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setMovie(resp.data.movie);
        setFavComments(resp.data.favComments);
        setLikedStatus(resp.data.likedStatus);
        setLikers(resp.data.likers);
        setIsSub(resp.data.isSubscribed);
        setSubAccount(resp.data.subAccount);
        setChannelPrice(resp.data.channelPrice);
        setChannelName(resp.data.channelName);
        setSeriesSlug(resp.data.seriesSlug);
        setSeriesPricing(resp.data.seriesPricing);
        setSeriesId(resp.data.seriesId);
        setIsPurchased(resp.data.purchased);
        setPurchasedSeries(resp.data.purchasedSeries);
        setPublicKey(resp.data.public_key);
        setVideo(resp.data.movie.video);
        setViews(resp.data.views);
        setTrailer(resp.data.movie.trailer);
        setComments(resp.data.comments);
        setCasts(resp.data.casts);
        setRatings(resp.data.rates);
        // setIsFetching(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getMovieDetailsLoad();
    console.log(JSON.stringify(location));
  }, []);

  if (isFetching == true) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Load />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div
          className="main-content right-chat-active"
          style={{ backgroundColor: "#0f0f0f" }}
        >
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={`Series Episode`}
                    // {`Channel ${movie.type}s`}
                    bg="#152238"
                    color="#fff"
                    back="Go Back To Channel"
                    channelUrl="series"
                    channelSlug={channelSlug}
                    searchUrl="movie/search"
                    redirect="episode"
                  />

                  <div
                    //   className="row ps-2 pe-1"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <Detail
                      movie={movie}
                      movieType="episode"
                      seriesPricing={seriesPricing}
                      likers={likers}
                      likedStatus={likedStatus}
                      channelId={movie.channel_id}
                      video={video}
                      trailer={trailer}
                      comments={comments}
                      isSub={isSub}
                      subAccount={subAccount}
                      channelPrice={channelPrice}
                      channelName={channelName}
                      isPurchased={isPurchased}
                      seriesSlug={seriesSlug}
                      seriesId={seriesId}
                      purchasedSeries={purchasedSeries}
                      publicKey={publicKey}
                      ratings={ratings}
                      views={views}
                      channelSlug={channelSlug}
                      favComments={favComments}
                      casts={casts}
                      adminId={adminId}
                      userId={userId}
                      functionProp={() => getMovieDetails()}
                      type="episode"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}
