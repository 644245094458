
import React from "react";

import "./movie-card.scss";

import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import Button from "../button/Button";
import { format, formatDistance, subDays } from "date-fns";
import { category } from "../../api/tmdbApi";
import apiConfig from "../../api/apiConfig";
import PlayCircleOutlinedIcon from "@mui/icons-material/PlayCircleOutlined";
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import IconButton from "@mui/material/IconButton";


const MovieCard = (props) => {
  // const item  = props.item;
  const movie = props.movies;
  const type = props.type;
  const channelSlug = props.channelSlug;
  const url = `channel/${channelSlug}/${props.route}`
  // const channelSlug = props.channelSlug;
  // const channelId = props.channelId;
  const adminId = props.adminId;
  //   const url = props.url;
  // const { url } = useParams(["url"]);
  // const { url } = useParams();
  function refresh() {
    window.location.reload();
  }

  let history = useHistory();
  // const link = '/' + category[props.category] + '/' + item.id;
  // const url = "https://image.tmdb.org/t/p/w300";
  // const bg = apiConfig.w500Image(item.poster_path || item.backdrop_path);
  //   if(url == 'movie'){
  //     console.log('it is a movie!')
  // }
  // if(url == 'video'){
  //   console.log('it is a video!')
  // }
  return (
    <>
      {/* {console.log(movie)} */}
      {/* <Link to="/"> */}
      {/* <div
                        className="card h300 d-block border-0 shadow-xss rounded-3 bg-gradiant-bottom overflow-hidden mb-3 bg-image-cover  hover-zoom"
                        style={{
                            backgroundImage: `url("${`${url}/${movie.poster_path}`}")`,
                        }}
                > */}
      <div
        className="movie-card overflow-hidden"
        style={{
          backgroundImage: `url("${movie.image}")`,
          marginRight: 10,
          // backgroundImage: `url(${bg})`
        }}
      >
        
          {/* <Link
          to={{
            pathname: `/channel/${props.route}/${movie.slug}`,
            state: {
              channelSlug: props.channelSlug,
              id: movie.id,
              adminId: props.adminId,
            },
            // onClick:{refresh}
          }}
          onClick={() => history.push(`/channel/${props.route}/${movie.slug}`)}
          // onClick={()=> window.location.reload()}
        > */}
          {/* <Button
            className="btn-outline"
            onClick={() => {
              history.push({
                pathname: `/${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
                // pathname: `/channel/${props.route}/${movie.slug}`,
                // state: {
                //   channelSlug: props.channelSlug,
                //   id: movie.id,
                //   adminId: props.adminId,
                // }
              })
              refresh()
            }
            }
          >
            <i className="bx bx-play"></i>
          </Button> */}
          {/* </Link> */}
          {/* <br /> */}

          <div style={{display:'flex', alignSelf:'center', justifyContent:'center'}}>
          <IconButton   className="btn-outline">
          <i className="bx bx-play"  
          style={{ color: "#fff", fontSize: 60 }}
            onClick={() => {
              history.push({
                pathname: `/${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
             
              })
              refresh()
            }
          }
          ></i>
              </IconButton>
          </div>
          {/* <PlayArrowOutlinedIcon
            style={{ color: "#fff", fontSize: 60, marginLeft: 70 }}
            onClick={() => {
              history.push({
                pathname: `/channel/${props.route}/${movie.slug}`,
                state: {
                  channelSlug: props.channelSlug,
                  id: movie.id,
                  adminId: props.adminId,
                },
              })
              refresh()
            }
          }
          /> */}
    
          
          {/* </Link> */}
       
        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {movie.title}
          {/* {movie.type} */}
        </h4>
        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {/* {movie.title} */}
          Type: {type}
        </h4>
        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {/* {movie.title} */}
          Pricing: {movie.pricing} {movie.price}
        </h4>
        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {/* {movie.title} */}
          Released:{" "}
          {formatDistance(new Date(movie.created_at), new Date(), {
            addSuffix: true,
          })}
        </h4>
      </div>

      {/* <h4 className='text-black text-center'
    //   className="fw-600 position-relative z-index-1 ls-3 font-xss text-black mt-2 mb-1 text-center"
      >
        {movie.title}
      </h4> */}
      {/* </Link> */}
      {/* </div> */}
    </>
  );
};

export default MovieCard;
