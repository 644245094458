import React, { Component } from "react";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import PostComponent from "./PostComponent";
import Load from "./Load";
import Load2 from "./Load2";
import CreatePost2 from "./CreatePost2";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import CreatePostFab from "./CreatePostFab";
import { Link, NavLink } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

class Postview extends React.Component {
  state = {
    isOpen: false,
    OpenAction: false,
    posts: [],
    fetchingPosts: false,
    error: false,
    errorMessage: "",
    firstName: window.localStorage.getItem("first_name"),
    skip: 0,
    take: 30,
  };

  getNext = () => {
    // this.setState({ take: this.state.take + 2 });
    this.setState({ skip: this.state.skip + 30 });
    // this.setState({ take: this.state.take + 2 });

    this.getNextPosts();
  };

  closeError = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  handleClose = () => {
    this.setState({ error: false });
    console.log("closed");
  };

  getAllPostsLoad = async () => {
    this.setState({ fetchingPosts: true });
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      // /timeline/skip/{id}/{skip}/{take}
      .get(`/users/timeline/skip/${user_id}/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // console.log(resp.data)
        this.setState({ posts: [...this.state.posts, ...resp.data] });
        // this.setState({ posts: resp.data });
        // console.log(`posts list ${JSON.stringify(resp.data.post_comments)}`);
        this.setState({ fetchingPosts: false });
        // this.refreshToken()
        // } else {
        //   this.setState({ fetchingPosts: false });
        //   this.setState({ error: true });
        //   this.setState({ errorMessage: resp.data.message });
        // }
      })
      .catch((error) => {
        this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.response.data.message });
      });
  };
  getAllPosts = async () => {
    // this.setState({ fetchingPosts: true });
    // this.setState({fetchingPosts: true})
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      // .get(`/users/timeline/${user_id}`, {
      .get(`/users/timeline/skip/${user_id}/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        // console.log(resp.data)
        this.setState({ posts: resp.data });
        // } else {
        //   // this.setState({ fetchingPosts: false });
        //   this.setState({ error: true });
        //   this.setState({ errorMessage: resp.data.message });
        // }
      })
      .catch((error) => {
        //   this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.response.data.message });
      });
  };

  getNextPosts = async () => {
    // this.setState({fetchingPosts: true})
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    const ski = this.state.skip;
    const tak = this.state.take;
    axiosFetch
      .get(`/users/timeline/skip/${user_id}/${ski}/${tak}`, {
        // .get(`/posts/all/${ski}/${tak}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        this.setState({ posts: [...this.state.posts, ...resp.data] });
      })
      .catch((error) => {
        //   this.setState({ fetchingPosts: false });
        console.log(error);
        this.setState({ error: true });
        this.setState({ errorMessage: error.message });
      });
  };

  fixImage = async (ev) => {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });
  toggleAction = () => this.setState({ OpenAction: !this.state.OpenAction });
  toggleActive = () => this.setState({ isActive: !this.state.isActive });

  componentDidMount() {
    this.getAllPostsLoad();
  }

  render() {
    //  console.log(`posts are ${this.state.posts}`);
    const { user, time, des, avater, postimage, postvideo, id } = this.props;

    const menuClass = `${this.state.isOpen ? " show" : ""}`;
    const actionClass = `${this.state.OpenAction ? " show" : ""}`;
    const emojiClass = `${this.state.isActive ? " active" : ""}`;

    if (this.state.fetchingPosts == true) {
      return <Load2 />;
    }
    if (this.state.posts.length == 0) {
      return (
        <>
          {this.state.error ? (
            <div>
              <br />
              <br />
              <Alert
                severity="error"
                onClose={this.handleClose}
                action={
                  <>
                    <CancelIcon onClick={this.closeError} />
                  </>
                }
              >
                There was an issue fetching all posts. Kindly refresh or check
                your network. If this continues, kindly login again [{" "}
                {this.state.errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          <CreatePost2
            type={this.props.type}
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />

          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                Hello and welcome to Alarrt {this.state.firstName}! Create a
                post right now to start populating your timeline. This allows
                other users to find you on the explore page. You can similarly
                find posts of interesting people to follow there. To follow
                recent users, scroll down or use the searchbar to find friends
                you know on Alarrt.
              </h5>
              {/* <br /> */}
              <h5
                className="middle-wrap fw-600 text-grey-900"
                style={{ textAlign: "center", lineHeight: 2 }}
              >
                {" "}
                Welcome once again - Stay Informed, Stay Connected and Invite
                your friends!
              </h5>
              <br />
              <Link
                to="/explorefeed"
                // target='_blank'
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "center",
                  justifySelf: "center",
                }}
                className="bg-blue-gradiant font-bold hover:text-white inline-block justify-center text-center items-center lg:block  mr-4 px-4 py-2 rounded shado text-white"
              >
                <span className="d-inline-block ls-3 fw-600 text-center text-white font-xxs mb-0">
                  Go To Explore Feed
                </span>{" "}
                <i className="font-sm feather-arrow-up-right text-white-500 pe-0"></i>
              </Link>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          {this.state.error ? (
            <div>
              <br />
              <br />
              <Alert
                severity="error"
                onClose={this.handleClose}
                action={
                  <>
                    <CancelIcon onClick={this.closeError} />
                  </>
                }
              >
                There was an issue fetching all posts. Kindly refresh or check
                your network. If this continues, kindly login again [{" "}
                {this.state.errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          <CreatePost2
            type={this.props.type}
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />

          {/* <InfiniteScroll
            dataLength={this.state.posts.length} //This is important field to render the next data
            next={this.getNext}
            hasMore={true}
            // loader={<Load message='scroll down to load more' />}
            loader={''}
            // <Load message='scroll down to load more' />
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          > */}
          <PostComponent
            posts={this.state.posts}
            type={this.props.type}
            functionProp={() => this.getAllPosts()}
            functionPropNext={() => this.getNext()}
          />
          {/* </InfiniteScroll> */}

          <CreatePostFab
            type={this.props.type}
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />
          <CreatePostFab
            type={this.props.type}
            pageId=""
            channelId=""
            communityId=""
            functionProp={() => this.getAllPosts()}
          />
        </>
      );
    }
  }
}

export default Postview;
