import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useParams } from 'react-router';

import tmdbApi from '../../../api/tmdbApi';


const VideoList = props => {

    const {category} = useParams();
    const id = props.id

    const [videos, setVideos] = useState([]);
    async function getCast() {
        //    const {data } =
        axios
          .get(
            // `https://api.themoviedb.org/3/movie/popular?api_key=${process.env.REACT_APP_API_KEY}`,
            `https://api.themoviedb.org/3/movie/${id}/videos?api_key=42f731815804618872f9aeab9511cc98`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + access_token,
              },
            }
          )
          .then((resp) => {
            console.log(`videos :  ${JSON.stringify(resp.results)}`);
            setVideos(resp.results.slice(0, 5));
            // setMovie(resp.data);
          })
          .catch((error) => console.log(`Error Seen : ${error}`));
      }


    // useEffect(() => {
    //     const getVideos = async () => {
    //         const res = await tmdbApi.getVideos(category, props.id);
    //         setVideos(res.results.slice(0, 5));
    //     }
    //     getVideos();
    // }, [category, props.id]);

    return (
        <>
            {
                videos.map((item, i) => (
                    <Video key={i} item={item}/>
                ))
            }
        </>
    );
}

const Video = props => {

    const item = props.item;

    const iframeRef = useRef(null);

    useEffect(() => {
        const height = iframeRef.current.offsetWidth * 9 / 16 + 'px';
        iframeRef.current.setAttribute('height', height);
    }, []);

    return (
        <div className="video">
            <div className="video__title">
                {/* <h2>{item.name}</h2> */}
                <h2>{props.channelId}</h2>
                
            </div>
            <iframe
                src={`https://www.youtube.com/embed/${item.key}`}
                ref={iframeRef}
                width="100%"
                title="video"
            ></iframe>
        </div>
    )
}

export default VideoList;
