import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import "../../../components/comment/comment.css";
import { format, formatDistance, subDays } from "date-fns";
import ReactDOM from "react-dom";
import ModalVideo from "react-modal-video";
import axios from "axios";
import axiosFetch from "../../../Interceptors2";
import TextField from "@mui/material/TextField";
import { useHistory, useLocation } from "react-router";
import { Grid, makeStyles } from "@material-ui/core";
import "./detail.scss";
import CastList from "./CastList";
import VideoList from "./VideoList";
import Modal, { ModalContent } from "../../../moviesComponents/modal/Modal";
import Button2, {
  OutlineButton,
} from "../../../moviesComponents/button/Button";
import MovieList from "../../../moviesComponents/movie-list/MovieList";
import Tooltip from "@mui/material/Tooltip";
import { InfoOutlined, PlayArrow } from "@material-ui/icons";
import ShowMoreText from "react-show-more-text";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";

import SendRoundedIcon from "@material-ui/icons/SendRounded";
import CommentComponent from "../../../components/comment/CommentComponent";
import ImageComponent from "../../../components/ImageComponent";
import ImageComponentDiv from "../../../components/ImageComponentDiv";
import { Player } from "video-react";
import FavComment from "../../../components/comment/FavComments";
import { usePaystackPayment } from "react-paystack";
import Alert from "@mui/material/Alert";
import ShareButtons from "../../../components/ShareButtons";
import ShareMovie from "../../../components/ShareMovie";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import LikersModal from "../../../components/LikersModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AllMovies from "../../../components/movies/AllMovies";
import { Snackbar } from "@material-ui/core";
import ReactPlayer from "react-player";
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import AddTaskIcon from '@mui/icons-material/AddTask';

const useStyles = makeStyles((theme) => ({
  pc: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Detail = (props) => {
  const [copied, setCopied] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  //   const { category, id } = useParams();
  const { slug } = useParams(["slug"]);
  const { url } = useParams(["url"]);
  const [isOpen, setOpen] = useState(false);
  const movie = props.movie;
  const [channelSubSuccess, setChannelSubSuccess] = useState(false);
  const comments = props.comments;
  const ratings = props.ratings;
  const userName = window.localStorage.getItem("user_name");
  //   const channelSlug = props.channelSlug;
  const seriesMovies = props.seriesMovies;
  const [isLiking, setIsLiking] = useState(false);
  const video = props.video;
  const trailer = props.trailer;
  const [item, setItem] = useState(null);
  let history = useHistory();
  const [disApprovalMessage, setDisApprovalMessage] = useState("");
  const [approving, setApproving] = useState(false);
  const [content, setContent] = useState([]);
  //   const [movie, setMovie] = useState([]);
  const [showMovie, setShowMovie] = useState(false);
  const [paymentPrompt, setPaymentPrompt] = useState(false);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [seriesPaymentPrompt, setSeriesPaymentPrompt] = useState(false);
  const [seriesSubPrompt, setSeriesSubPrompt] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [subAction, setSubAction] = useState("");
  const [subLink, setSubLink] = useState("");
  const [payAction, setPayAction] = useState("");
  const [payLink, setPayLink] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [subPrompt, setSubPrompt] = useState(false);
  const [subMessage, setSubMessage] = useState("");
  //   const url = "https://image.tmdb.org/t/p/w300";
  const [openComment, setOpenComment] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [message, setMessage] = useState("");
  // const [userPic, setUserPic] = useState("");
  // const [userId, setUserId] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  const userEmail = window.localStorage.getItem("email");
  const userPic = window.localStorage.getItem("profile_pic");
  const userId = window.localStorage.getItem("user_id");
  const queryParams = new URLSearchParams(location.search);

  const handleClickOpen = (scrollType) => () => {
    setOpenComment(true);
    setScroll(scrollType);
  };

  function closeSnack() {
    setCopied(false);
  }

  async function watchLater(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function approveContent(id) {
    setApproving(true);
    // alert('approving, click ok to continue')
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/approve_movie/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        alert(`approved successfuly. Wait few sec`);
        setApproving(false);
        // setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function disApproveContent(id) {
    if (disApprovalMessage == "") {
      alert("type in your reason for dis-approving first");
    } else {
      setApproving(true);
      // alert('approving, click ok to continue')
      const access_token = await window.localStorage.getItem("access_token");
      const authId = await window.localStorage.getItem("user_id");

      const formData = new FormData();
      formData.append("message", disApprovalMessage);

      axiosFetch
        .post(`/movie/dis/approve_movie/${id}`, id, {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        })
        .then((resp) => {
          const alerts = resp.data;
          props.functionProp();
          // alert('relaerted!')
          alert(`dis-approved successfuly. Wait few sec`);
          setApproving(false);
          // setSaved(true);
        })
        .catch((error) => console.error(`Error Seen : ${error}`));
    }
  }

  const handleClose = () => {
    setOpenComment(false);
    setSubPrompt(false);
    setPaymentSuccess(false);
    setSaved(false);
    setIsAction(false);
    setChannelSubSuccess(false);
  };
  const closeVideo = () => {
    setShowMovie(false);
  };

  const toogleVideo = () => {
    if (props.type == "episode") {
      // alert('this is a episode')
      if (props.type == "episode" && props.seriesPricing == "free") {
        // setShowMovie(true);
        setPaymentMessage(` Series is free! Enjoy its episodes below`);
        setIsAction(false);
        setShowMovie(true);
      }
      if (
        props.type == "episode" &&
        props.seriesPricing == "paid" &&
        props.purchasedSeries == "true"
      ) {
        // setShowMovie(true);
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          ` Hurray! You've purchased this series already. See more Episodes below and
          enjoy!`
        );
        setShowMovie(true);
      }
      if (
        props.type == "episode" &&
        props.seriesPricing == "paid" &&
        props.purchasedSeries == "false"
      ) {
        setPayAction("");
        setPayLink("");
        setSeriesPaymentPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is not free. Buy and watch the series first to enjoy this episode and more`
        );
        setIsAction(true);
        setPayAction("Buy Series");
        // pathname: `/channel/${movie.channel.slug}/series/${movie.series.slug}?channel=${movie.channel.slug}&w=${movie.series.id}&a=${movie.channel.admin.id}`,
        setPayLink(
          `/channel/${props.channelSlug}/series/${props.seriesSlug}?channel=${props.channelSlug}&w=${props.seriesId}&a=${props.adminId}`
        );
        // setPayLink(`/channel/series/${props.seriesSlug}`);
      }
      if (
        props.type == "episode" &&
        props.seriesPricing == "subscription" &&
        props.isSub == "false"
      ) {
        setSubAction("");
        setSubLink("");
        setSeriesSubPrompt(true);
        setPaymentMessage(
          `This ${movie.type} is for subscribers only. You need to
          subscribe first to enjoy this. Click the subscribe 
          button to subscribe now.`
        );
        setIsAction(true);
        setSubAction("Subscribe Now");
        setSubLink(`/channel/${props.channelSlug}`);
      }

      if (
        props.type == "episode" &&
        props.seriesPricing == "subscription" &&
        props.isSub == "true"
      ) {
        setSeriesSubPrompt(true);
        setPaymentMessage(
          ` Hurray! Series is for subscribers only and you are subscribed to this channel. See more episodes below and
          enjoy!`
        );
        setIsAction(false);
        // setShowMovie(true);
        setShowMovie(true);
      }

      // alert('this is a episode')
    } else {
      if (movie.pricing == "free") {
        setShowMovie(true);
      }
      if (movie.pricing == "paid" && props.isPurchased == "false") {
        setPaymentPrompt(true);
        setPaymentMessage(`This ${movie.type} is not free. Buy and watch now for N
      ${movie.price}`);
      }
      if (movie.pricing == "paid" && props.isPurchased == "true") {
        setShowMovie(true);
      }
      if (movie.pricing == "subscription" && props.isSub == "false") {
        // if (props.isSub == "false") {
        setSubPrompt(true);
        setSubMessage(
          `This ${movie.type} is for subscribers only. You need to
          subscribe first to enjoy this. Click the subscribe
          button to subscribe now.`
        );
        setShowMovie(false);
      }

      if (movie.pricing == "subscription" && props.isSub == "true") {
        setShowMovie(true);
      }
    }
  };

  async function purchasedMovie(id) {
    // setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("movie_id", movie.id);
    formData.append("type", "movie");

    axiosFetch
      .post(`/movie_buyers/${movie.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        props.functionProp();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function handleSubscription(id) {
    
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(`/channels/${id}/subscribe`, id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);

        props.functionProp();
        // setChannelSubSuccess(true);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const config = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: movie.price * 100,
    // publicKey: props.publicKey,
    subaccount: props.subAccount,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
  };
  const channelConfig = {
    reference: new Date().getTime().toString(),
    email: userEmail,
    amount: props.channelPrice * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
    subaccount: props.subAccount,
  };

  // you can call this function anything
  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    purchasedMovie();
    setPaymentPrompt(false);
    setPaymentSuccess(true);
    // window.location.reload();
  };
  const onSuccessChannel = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);
    setSeriesSubPrompt(false)
    setSubPrompt(false)
    handleSubscription(movie.channel.id);
    setChannelSubSuccess(true)
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const descriptionElementRef = useRef(null);
  const initializePayment = usePaystackPayment(config);
  const initializePaymentChannel = usePaystackPayment(channelConfig);

  // async function getProfile() {
  //   setIsFetching(true);
  //   const access_token = await window.localStorage.getItem("access_token");
  //   // console.log(`user profile ${access_token}`);
  //   axios
  //     .get("https://alert-lumen-api.herokuapp.com/api/users/profile", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       // console.log(JSON.stringify(resp.data.user.profile_pic));
  //       setUserPic(resp.data.user.profile_pic);
  //       setUserId(resp.data.user.id);
  //       setUserEmail(resp.data.user.email);
  //       setIsFetching(false);
  //     })
  //     .catch((error) => console.error(`Error Seen : ${error}`));
  // }
  async function postComment() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("message", message);

    axiosFetch
      .post(`/movie/comment/${movie.id}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(`sent message ${resp.data}`);
        setMessage("");
        setIsCreating(false);
        // setMessage('');
        props.functionProp();
        // alert(`response ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }

  async function likeMovie(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/movie/like/movie/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function handleContextMenu(e) {
    e.preventDefault();
  }
  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    {
      console.log(window.location.href);
    }
    {
      console.log(window.location.pathname);
    }

    console.log(`hiii ${queryParams}`);
    // getProfile();
    if (openComment) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }

    // if (url == "series") {
    //   console.log("it is a series ooo!");
    // }
    // if (url == "movie") {
    //   console.log("it is a movie 0000!");
    // }
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, [openComment]);

  //   return (
  if (movie.length == 0) {
    return "";
  } else {
    return (
      <>
        {movie && (
          <>
            <ImageComponentDiv class="banner" image={movie.cover_image} />
            {/* <div
              className="banner"
              style={{
                backgroundImage: `url("${movie.cover_image}")`,
              }}
            ></div> */}
            <div className="mb-3 movie-content container">
              <div className="movie-content__poster">
                <ImageComponentDiv
                  class="movie-content__poster__img"
                  image={movie.image}
                />
                {/* <div
                  className="movie-content__poster__img"
                  style={{
                    backgroundImage: `url("${movie.image}")`,
                  }}
                ></div> */}
                <br />
                <br />
                <div className="clearfix"></div>

                {/* {props.seriesPricing == "subscription" ? (
                  <>
                    <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      Pricing: This {movie.type} is only accessible to channel
                      subscribers
                    </h5>

                    {movie.pricing == "free" ? (
                      <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500">
                        <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                        Pricing: This {movie.type} is only accessible to channel
                        subscribers
                      </h5>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )} */}
                <div className="star d-block w-100 text-left mt-2">
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star.png"
                    alt="star"
                    className="w15 float-left"
                  />
                  <img
                    src="/assets/images/star-disable.png"
                    alt="star"
                    className="w15 float-left me-2"
                  />
                </div>
                <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                  ({ratings} ratings ) {comments.length} comments
                </p>
                <div className="clearfix"></div>
                <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                  {props.casts.length} casts
                </h5>

                {/* {props.seriesPricing } */}

                {movie.pricing == "paid" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    This {movie.type} is not free. Buy and watch now for N
                    {movie.price}
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "subscription" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    Pricing: This {movie.type} is only accessible to channel
                    subscribers
                  </h5>
                ) : (
                  ""
                )}

                {movie.pricing == "free" ? (
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                    <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                    Free: No payment required! This {movie.type} is free to be
                    watched by all
                  </h5>
                ) : (
                  ""
                )}

                <div className="clearfix"></div>
              </div>
              <div className="movie-content__info">
                <Grid item sm={12} className={classes.mobile}>
                  <ImageComponentDiv
                    class="movie-content__poster__img"
                    image={movie.image}
                  />

                  <br />
                </Grid>
                <h1
                  style={{
                    color: "#fff",

                    fontSize: "4vw",
                    lineHeight: 1,
                    textAlign: "start",
                  }}
                >
                  {movie.title}
                </h1>

                <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Status : {movie.status}{" "}
                  {formatDistance(new Date(movie.created_at), new Date(), {
                    addSuffix: true,
                  })}
                </h4>

                <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                  {/* {movie.title} */}
                  Type : {movie.type}{" "}
                  {movie.type == "episode" ? (
                    <>
                      {/* //   pathname: `/${url}/${item.slug}?channel=${item.channel.slug}&w=${item.id}&a=${item.channel.admin.id}`, */}
                      <a
                        href={`/channel/${props.channelSlug}/series/${props.seriesSlug}?channel=${props.channelSlug}&w=${props.seriesId}&a=${props.adminId}`}
                      >
                        <span
                          className="genres__item"
                          style={{
                            padding: "0.5rem 1.5rem",

                            color: "#fff",
                            display: "inline-block",
                            marginTop: 5,
                          }}
                        >
                          View Series
                        </span>
                        {/* <OutlineButton
                        // onClick={() => setOpen(true)}
                        // style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                           View Series 
                      </OutlineButton> */}
                        {/* <h5 className="fw-600 position-relative z-index-1 ls-3 font-xsss text-white mb-3 mb-1">
                      View Series 
                      </h5> */}
                      </a>
                    </>
                  ) : (
                    ""
                  )}
                </h5>

                <div
                  className="genres clearfix"
                  style={{ overFlow: "auto", display: "block" }}
                >
                  {movie.tags &&
                    movie.tags.slice(0, 5).map((tag, i) => (
                      <span
                        key={i}
                        className="genres__item"
                        style={{
                          padding: "0.5rem 1.5rem",

                          color: "#fff",
                          display: "inline-block",
                          marginTop: 5,
                        }}
                      >
                        {tag.name}
                      </span>
                    ))}
                  <div className="clearfix"></div>
                </div>

                <Grid item sm={12} className={classes.mobile}>
                  <div className="star d-block w-100 text-left mt-2">
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star.png"
                      alt="star"
                      className="w15 float-left"
                    />
                    <img
                      src="/assets/images/star-disable.png"
                      alt="star"
                      className="w15 float-left me-2"
                    />
                  </div>
                  <p className="review-link font-xssss fw-600 text-white lh-3 mb-0">
                    ({ratings} ratings ) {comments.length} comments
                  </p>
                  <div className="clearfix"></div>
                  <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                    <i className="btn-round-sm bg-greylight feather-users text-grey-500 me-1"></i>{" "}
                    {props.casts.length} casts
                  </h5>
                  {/* {props.seriesPricing } */}
                  {movie.pricing == "paid" && movie.type == "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      This {movie.type} is not free. Buy the series now for N
                      {movie.price} and get access to all its episodes.
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "paid" && movie.type !== "episode" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      This {movie.type} is not free. Buy and watch now for N
                      {movie.price}
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "subscription" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      Pricing: This {movie.type} is only accessible to channel
                      subscribers
                    </h5>
                  ) : (
                    ""
                  )}
                  {movie.pricing == "free" ? (
                    <h5 className="mt-1 mb-1 d-inline-block font-xssss fw-600 text-grey-500">
                      <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                      Free: No payment required! This {movie.type} is free to be
                      watched by all
                    </h5>
                  ) : (
                    ""
                  )}
                  <br /> <br />
                </Grid>

                <ShowMoreText
                  style={{ color: "#fff", fontWeight: 700 }}
                  lines={5}
                  more="Read more"
                  less="Show less"
                  className="overview text-white"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  truncatedEndingComponent={"... "}
                >
                  {movie.overview}
                </ShowMoreText>

                {props.isAdmin == true && (
                  <>
                    {movie.is_approved == "false" ? (
                      <>
                       <p>This hasn't been approved yet</p>
                        {/* <OutlineButton onClick={() => approveContent(movie.id)}>
                          Approve Content
                        </OutlineButton> */}
                        <br /> 
                      </>
                    ) : (
                      <>
                        <p>This has been approved </p>
                       
                        <br />
                      </>
                    )}
                  </>
                )}

                {approving ? (
                  <>
                    <br />
                    <p className="text-white">
                      {" "}
                      Approving / Dis-approving Content... This will take a few
                      seconds{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  ""
                )}

                <div className="btns">
                  {seriesPaymentPrompt ? (
                    <>
                      <div>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h4>
                        <br />
                      </div>

                      {payAction ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => {
                              history.push({
                                pathname: `/channel/${props.channelSlug}/series/${props.seriesSlug}?channel=${props.channelSlug}&w=${props.seriesId}&a=${props.adminId}`,
                              });
                              window.location.reload();
                            }}
                          >
                            {payAction}
                          </OutlineButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {seriesSubPrompt ? (
                    <>
                      <div>
                        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                          {paymentMessage}
                        </h4>
                        <br />
                      </div>

                      {isAction ? (
                        <>
                          {movie.channel.type == "free" ? (
                            <div style={{ paddingBottom: 20 }}>
                              <OutlineButton
                                onClick={() => {
                                  handleSubscription(movie.channel.id)
                                  setIsAction(false)
                                  setChannelSubSuccess(true)
                                }}
                              >
                                Subscribe Free
                              </OutlineButton>
                            </div>
                          ) : (
                            <div style={{ paddingBottom: 20 }}>
                              <OutlineButton
                                onClick={
                                  () =>
                                    initializePaymentChannel(
                                      onSuccessChannel,
                                      onClose
                                    )
                                  // THIS IS FOR EPISODES
                                }
                              >
                                Subscribe Now
                              </OutlineButton>
                            </div>
                          )}
                        </>
                      ) : (
                        // <div style={{ paddingBottom: 20 }}>
                        //   <OutlineButton
                        //     onClick={() => {
                        //       initializePaymentChannel(
                        //         onSuccessChannel,
                        //         onClose
                        //       );
                        //     }}
                        //   >
                        //     {subAction}
                        //   </OutlineButton>
                        // </div>
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {paymentPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        {paymentMessage}
                      </h4>
                      <br />
                      <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() => {
                            initializePayment(onSuccess, onClose);
                          }}
                        >
                          Buy Now
                        </OutlineButton>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {channelSubSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Awesome! You've subscribed to {movie.channel.name}{" "}
                        successfully! You now have access to all premium
                        entertainments on their channel. Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  {paymentSuccess ? (
                    <>
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        Hurray! You've purchased {movie.title} successfully! You
                        can always comeback to watch it here, or view them in
                        your purchased movies from your profile. Click Watch Now
                        and Enjoy!
                      </Alert>
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  <div style={{ marginRight: 20, marginBottom: 20 }}>
                    {video === null || video === "null" ? (
                      ""
                    ) : (
                      <Button2 onClick={toogleVideo}>Watch now</Button2>
                    )}
                  </div>

                  {subPrompt ? (
                    <>
                      <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1">
                        {subMessage}
                      </h4>
                      {movie.channel.type == "free" ? (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={() => 
                              {
                                handleSubscription(movie.channel.id)
                                setSubPrompt(false)
                                setChannelSubSuccess(true)
                              }}
                          >
                            Subscribe Free
                          </OutlineButton>
                        </div>
                      ) : (
                        <div style={{ paddingBottom: 20 }}>
                          <OutlineButton
                            onClick={
                              () =>
                                initializePaymentChannel(
                                  onSuccessChannel,
                                  onClose
                                )
                              // THIS IS FOR A SINGLE MOVIE
                            }
                          >
                            Subscribe Now
                          </OutlineButton>
                        </div>
                      )}
                      {/* <div style={{ paddingBottom: 20 }}>
                        <OutlineButton
                          onClick={() =>
                            history.push(`/channel/${props.channelSlug}/movies`)
                          }
                        >
                          Go To Channel
                        </OutlineButton>
                      </div> */}
                    </>
                  ) : (
                    ""
                  )}

                  {showMovie ? (
                    <>
                      <br />
                      {/* {!movie.video.includes("youtu") ? ( */}
                      {/* <Player
                        playsInline
                        poster={movie.image}
                        src={movie.video}
                        fluid
                      /> */}
                      {/* ) : ( */}
                      <ReactPlayer
                        url={movie.video}
                        controls={true}
                        config={{
                          file: { attributes: { controlsList: "nodownload" } },
                        }}
                        playsinline={true}
                        width="100%"
                        // light={true}
                        // imageUrl={movie.image}
                        // height='360px'
                        //  controlsList="nodownload"
                      />
                      {/* )} */}

                      <br />
                      <br />

                      <br />
                      <Button2 onClick={closeVideo}>Close</Button2>
                    </>
                  ) : (
                    ""
                  )}
                  <Grid item sm={12} className={classes.mobile}>
                    <br />
                  </Grid>

                  {/* {console.log(trailer)} */}
                  {trailer === null || trailer === "null" ? (
                    ""
                  ) : (
                    <>
                      {/* <p>jlllllll</p> */}
                      <OutlineButton
                        onClick={() => setOpen(true)}
                        style={{ paddingLeft: 20, paddingRight: 20 }}
                      >
                        Watch trailer
                      </OutlineButton>
                      <br />
                      <br />

                      {isOpen ? (
                        <>
                          <br />
                          {/* {trailer.includes("youtu") ? ( */}
                          <ReactPlayer
                            url={trailer}
                            controls={true}
                            config={{
                              file: {
                                attributes: { controlsList: "nodownload" },
                              },
                            }}
                            width="100%"
                            // light={true}
                            // imageUrl={movie.image}
                            // controlsList="nodownload"
                          />
                          {/* // ) : (
                          //   <Player
                          //     playsInline
                          //     poster={movie.cover_image}
                          //     src={trailer}
                          //     fluid
                          //   />
                          // )} */}

                          <br />
                          <br />

                          <Button2 onClick={() => setOpen(false)}>
                            Close
                          </Button2>
                          <br />
                          <br />
                        </>
                      ) : (
                        ""
                      )}
                      {/* <ModalVideo
                        channel="custom"
                        autoplay
                        isOpen={isOpen}
                        url={trailer}
                        onClose={() => setOpen(false)}
                      />*/}
                    </>
                  )}

                  <div>
                    <a
                      onClick={handleClickOpen("paper")}
                      // data-uk-toggle="target: #my-id" type="button"
                      // href="/defaulthoteldetails"
                      className="d-block p-2 lh-32 text-center bg-greylight fw-600 font-xssss text-grey-900 rounded-3"
                    >
                      Add a Review / Comment
                    </a>
                    <Dialog
                      open={openComment}
                      onClose={handleClose}
                      scroll={scroll}
                      aria-labelledby="scroll-dialog-title"
                      aria-describedby="scroll-dialog-description"
                    >
                      <DialogTitle id="scroll-dialog-title">
                        Comments and Feedbacks
                      </DialogTitle>
                      <DialogContent dividers={scroll === "paper"}>
                        <br />
                        <p> {props.favComments.length} Pinned Comments </p>

                        <FavComment
                          Id={movie.id}
                          url={"movie/comment"}
                          favUrl={`${movie.id}/movie`}
                          movieId={movie.id}
                          adminId={props.adminId}
                          userId={props.userId}
                          favComments={props.favComments}
                          functionProp={props.functionProp}
                        />
                        <br />
                        <DialogContentText
                          id="scroll-dialog-description"
                          ref={descriptionElementRef}
                          tabIndex={-1}
                        >
                          {comments.map((c, id) => (
                            <CommentComponent
                              comments={c}
                              id={id}
                              url="movie"
                              favUrl={`${movie.id}/movie`}
                              deleteUrl={`comment`}
                              likeUrl={`movie`}
                              adminId={props.adminId}
                              userId={props.userId}
                              movieId={movie.id}
                              functionProp={props.functionProp}
                            />
                          ))}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        {/* <Button onClick={handleClose}>Subscribe</Button> */}
                      </DialogActions>
                    </Dialog>
                  </div>
                  <br />

                  <div style={{ display: "flex" }}>
                    {isLiking ? (
                      <Tooltip title="Like" aria-label="add">
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <HourglassEmptyRoundedIcon
                            style={{ color: "#fff", fontSize: 30 }}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <>
                        {props.likedStatus == "true" ? (
                          <Tooltip title="Unlike" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Like" aria-label="add">
                            <>
                              <a
                                style={{ border: "#fff solid 2px" }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <FavoriteBorderRoundedIcon
                                  onClick={() => likeMovie(movie.id)}
                                  style={{ color: "#fff", fontSize: 30 }}
                                />

                                <span>
                                  {" "}
                                  <h3 className="text-white">
                                    <LikersModal
                                      color="#fff"
                                      likers={movie.likers}
                                      text="likes"
                                      type="movie"
                                      class="d-flex align-items-center fw-900 text-white-900 text-white lh-26 font-xssss"
                                    />
                                  </h3>{" "}
                                </span>
                              </a>
                            </>
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Tooltip title="Views" aria-label="add">
                      <>
                        <a
                          style={{ border: "#fff solid 2px" }}
                          className="btn-round-lg ms-2 d-inline-block rounded-3"
                        >
                          <i
                            className="feather-eye text-white rounded-full p-2 transition -mr-1"
                            style={{ fontSize: 25 }}
                          ></i>{" "}
                          <span>
                            {" "}
                            <h3 className="text-white font-xssss fw-600">
                              {props.views} Views
                            </h3>{" "}
                          </span>
                        </a>
                      </>
                    </Tooltip>

                    <Tooltip title="Share Link" aria-label="add">
                      <>
                        <CopyToClipboard
                          // text={window.location.href}
                          text={`https://alarrt.com/signin${window.location.pathname}?${queryParams}`}
                          onCopy={() => setCopied(true)}
                        >
                          <a
                            style={{ border: "#fff solid 2px" }}
                            className="btn-round-lg ms-2 d-inline-block rounded-3"
                          >
                            <i
                              className="feather-share-2 text-white rounded-full p-2 transition -mr-1"
                              style={{ fontSize: 25 }}
                            ></i>{" "}
                          </a>
                        </CopyToClipboard>
                        <br />
                        {copied ? (
                          <>
                            <Snackbar
                              open={copied}
                              autoHideDuration={3000}
                              onClose={closeSnack}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Alert onClose={closeSnack} severity="success">
                                Link Copied Successfully
                              </Alert>
                            </Snackbar>
                          </>
                        ) : null}
                      </>
                    </Tooltip>

                    {/* <ShareMovie /> */}

                    <Tooltip title="Add To Watch List" aria-label="add">
                      <a
                        onClick={() => watchLater(movie.id)}
                        // href="/defaulthoteldetails"
                        style={{ border: "#fff solid 2px" }}
                        className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                      >
                        <i className="feather-bookmark font-sm text-white"></i>{" "}
                      </a>
                    </Tooltip>

                    {props.isAdmin == true && (
                      <>
                        {movie.is_approved == "false" ? (
                          <>
                            <Tooltip title="Approve Movie" aria-label="add">
                              <a
                                onClick={() => approveContent(movie.id)}
                                style={{
                                  border: "#fff solid 2px",
                                  backgroundColor: "green",
                                }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3 bg-success"
                              >
                                <AddTaskIcon
                                  style={{ color: "#fff", fontSize: 30 }}
                                />
                               
                                <i
                                  style={{ color: "#fff" }}
                                  className=" font-sm text-white feather-eye"
                                ></i>{" "}
                              </a>
                            </Tooltip>
                            {/* <OutlineButton onClick={() => approveContent(movie.id)}>
                          Approve Content
                        </OutlineButton> */}
                            {/* <br /> */}
                          </>
                        ) : (
                          <>
                             <Tooltip title="Dis Approve Movie" aria-label="add">
                              <a
                                onClick={() => disApproveContent(movie.id)}
                                style={{
                                  border: "#fff solid 2px",
                                  backgroundColor: "#FF0000",
                                }}
                                className="btn-round-lg ms-2 d-inline-block rounded-3"
                              >
                                <CancelPresentationIcon
                                  style={{ color: "#fff", fontSize: 30 }}
                                />
                                
                                <i
                                  style={{ color: "#fff" }}
                                  className=" font-sm text-white feather-eye"
                                ></i>{" "}
                              </a>
                            </Tooltip>
                            {/* <p>This has been approved </p>
                            <OutlineButton
                              onClick={() => approveContent(movie.id)}
                            >
                              Dis-Approve Content
                            </OutlineButton> */}
                            {/* <br /> */}
                          </>
                        )}
                      </>
                    )}

                  </div>

                  <div>
                    {saving ? (
                      <>
                        <br />
                        <br />
                        <p className="text-white">
                          {" "}
                          Adding to watch list... This will take a few seconds{" "}
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    {saved ? (
                      <>
                        <br />
                        <br />
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          action={
                            <>
                              <a
                                onClick={() => setSaved(false)}
                                className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                close
                              </a>
                            </>
                          }
                        >
                          You've added this movie to your watch list
                          successfully.
                          <a href={`/profile/${userName}`}>
                            Click here to view it in your profile
                          </a>
                        </Alert>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <a
                    href="/defaulthoteldetails"
                    className="bg-primary-gradiant border-0 text-white fw-600 text-uppercase font-xssss float-left rounded-3 d-inline-block mt-0 p-2 lh-34 text-center ls-3 w200"
                  >
                    BUY NOW
                  </a> */}
                  {/* </div> */}
                </div>

                <div className="cast">
                  <CastList id={"580489"} casts={props.casts} />
                </div>
              </div>
            </div>
            <div className="container">
              {/* <div className="section mb-3">
              <h2 style={{ color: "#fff" }}>Similar</h2>
              <CastList id={movie.id} />
            </div> */}
              {/* {seriesMovies.length === 0 ? }   */}
              <div className="section mb-3">
                {/* <h2 style={{ color: "#fff" }}>Movies In Channel Series</h2> */}

                <VideoList id={movie.id} channelId={movie.channel_id} />
              </div>
              {props.type == "episode" ? (
                <div className="section mb-3">
                  {/* <div className="section__header mb-2">
                    <h2 style={{ color: "#fff" }}>
                      Watch Other Episodes In This Series
                    </h2>
                  </div> */}
                  <MovieList
                    heading="Watch Other Episodes In This Series"
                    link={`channels/series/${movie.series_id}`}
                    route="series/episode"
                    type="Episode"
                    channelId={props.channelId}
                    url="series/episode"
                    channelSlug={props.channelSlug}
                    adminId={props.adminId}
                    functionProp={props.functionProp}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>
                    Watch Other Series In Channel
                  </h2>
                </div> */}
                <MovieList
                  heading="Watch Other Series In Channel"
                  link={`channels/${props.channelId}/series`}
                  route="series"
                  type="Series"
                  channelId={props.channelId}
                  url="series"
                  channelSlug={props.channelSlug}
                  adminId={props.adminId}
                  functionProp={props.functionProp}
                />
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Movies</h2>
                </div> */}
                <MovieList
                  heading="Watch More Channel Movies"
                  link={`channels/${props.channelId}/movies`}
                  route="movie"
                  type="Movie"
                  channelId={props.channelId}
                  url="movie"
                  channelSlug={props.channelSlug}
                  adminId={props.adminId}
                  functionProp={props.functionProp}
                />
              </div>
              <div className="section mb-3">
                {/* <div className="section__header mb-2">
                  <h2 style={{ color: "#fff" }}>Watch More Channel Videos</h2>
                </div> */}
                <MovieList
                  heading="Watch More Channel Videos"
                  link={`channels/${props.channelId}/videos`}
                  route="video"
                  type="Video"
                  channelId={props.channelId}
                  url="video"
                  channelSlug={props.channelSlug}
                  adminId={props.adminId}
                  functionProp={props.functionProp}
                />
              </div>
            </div>
            <br />
            <AllMovies />
          </>
        )}
        {/* {video.map((item, i) => (
        <TrailerModal key={i} item={item} />
      ))} */}
      </>
    );
  }
  //   );
};

const TrailerModal = (props) => {
  const video = props.item;

  const iframeRef = useRef(null);

  const onClose = () => iframeRef.current.setAttribute("src", "");

  return (
    <Modal active={false} id={`modal_${video.id}`}>
      <ModalContent onClose={onClose}>
        <iframe
          ref={iframeRef}
          width="100%"
          height="500px"
          title="trailer"
        ></iframe>
      </ModalContent>
    </Modal>
  );
};

export default Detail;
