import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import Alert from "@mui/material/Alert";
import { Route, useParams, Link, useLocation } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "../components/Load";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Button from "@mui/material/Button";
import { Snackbar } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

// class Followers extends Component {
export default function SOSContact() {
  const { userName } = useState(window.localStorage.getItem("user_name"));
  const [firstName, setFirstName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");

  const authId = useState(window.localStorage.getItem("user_id"));
  const [unFollowedUser, setUnFollowedUser] = useState(false);
  const [fetchingFollowing, setFetchingFollowing] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [following, setFollowing] = useState([]);
  const [contactCount, setContactCount] = useState("");
  const [authFollowing, setAuthFollowing] = useState([]);
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [friendUserName, setFriendUserName] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [adding, setAdding] = useState(false);
  const [contactId, setContactId] = useState("");
  const [addingMessage, setAddingMessage] = useState("Follow User");
  const [isSearching, setIsSearching] = useState(false);
  const [addedFriend, setAddedFriend] = useState(false);
  const [removedFriend, setRemovedFriend] = useState(false);

  // const [action, setAction] = useState([]);

  // const user_name = useLocation()
  // const [alertId, setAlertId] = useState(id.state.id);

  async function test() {
    console.log("test");
  }

  async function getFollowing() {
    setFetchingFollowing(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/users/sos/list`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // console.log(`followers 1 ${resp.data}`)
        console.log(JSON.stringify(resp.data));
        // console.log(`followers 2 ${data2}`)
        setFollowing(resp.data.contacts);
        setContactCount(resp.data.count);
        // setAction(resp.data.action);
        setFetchingFollowing(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  }

  function addContact() {
    setAdding(true);
    const formData = new FormData();
    formData.append("name", firstName);
    formData.append("phone", phoneNo);

    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .post(`/users/sos/add`, formData, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setError(true);
          setErrorMessage(resp.data.message);
          setAdding(false);
          setAddingMessage("Try Again");
          // console.log('you have been blocked')
        } else {
          setAddedFriend(true);
          getFollowing();
          setFirstName("");
          setPhoneNo("");
          setAdding(false);
          // setFriendUserName("");
          // getCloseFriends();
          console.log("added contact");
        }
      })
      .catch((error) => {
        setAdding(false);
        // setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }

  function removeContact(id) {
    setContactId(id);
    setRemoving(true);

    const access_token = window.localStorage.getItem("access_token");
    let result = axiosFetch
      .delete(`/users/sos/delete/${id}`, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        if (resp.data.status == "error") {
          setError(true);
          setErrorMessage(resp.data.message);
          setRemoving(false);
        } else {
          setRemovedFriend(true);
          getFollowing();
          setRemoving(true);
          console.log("removed contact");
        }
      })
      .catch((error) => {
        setRemoving(false);
        // setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.response.data.message);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAddedFriend(false);
    setRemovedFriend(false);
    setError(false);
  };

  useEffect(() => {
    getFollowing();
  }, []);

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  if (fetchingFollowing == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Your SOS Contacts"}
                      // userName={userName}
                      // url={`profile/${userName}`}
                      // text="Back To Profile"
                      // type='external'
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <Load />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  }
  if (following.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Your SOS Contacts"}
                      // userName={userName}
                      // url={`profile/${userName}`}
                      // text="Back To Profile"
                      // type='external'
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                      <div className="card-body p-0">
                        <>
                          <h3
                            className="middle-wrap fw-600 text-grey-900"
                            style={{ textAlign: "center", lineHeight: 2 }}
                          >
                            Hello {userName}! You've not added any SOS Contact
                            yet. Add some below now.
                          </h3>

                          <br />

                          <br />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <div className="form-group icon-input mb-0.5">
                                <i className="font-sm feather-user text-grey-500 pe-8"></i>

                                <input
                                  style={{ paddingLeft: 40 }}
                                  type="text"
                                  placeholder="Contact's Full Name"
                                  className="style2-input form-control text-grey-900 font-xsss ls-3"
                                  value={firstName}
                                  onChange={(e) => setFirstName(e.target.value)}
                                />
                                <p> Your contact's full name </p>
                              </div>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <div className="form-group icon-input mb-0.5">
                                <i className="font-sm feather-phone text-grey-500 pe-0"></i>

                                <input
                                  style={{ paddingLeft: 40 }}
                                  type="text"
                                  placeholder="Active Phone"
                                  className="style2-input form-control text-grey-900 font-xsss ls-3"
                                  value={phoneNo}
                                  onChange={(e) => setPhoneNo(e.target.value)}
                                />
                                <p>
                                  Your contact's 11 digits active number for SMS
                                  delivery
                                </p>
                              </div>
                            </Grid>
                          </Grid>

                          <br />
                          {firstName && phoneNo ? (
                            <>
                              <br />

                              {adding ? (
                                <div className="text-center">
                                  <a
                                    disabled
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    Just A Sec ...
                                  </a>
                                </div>
                              ) : (
                                <div className="text-center">
                                  <a
                                    onClick={() => addContact()}
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    ADD CONTACT
                                    {/* means auth id = the user i params */}
                                  </a>
                                </div>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          <br />
                        </>

                        <div style={{ marginTop: 40 }}></div>
                    <div className="text-center">
                      <a
                        href="/"
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                      >
                        BACK TO ALARRTS
                        {/* means auth id = the user i params */}
                      </a>
                    </div>
                    <div style={{ marginTop: 80 }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={"Your SOS Contacts"}
                    // userName={userName}
                    // url='sos-contacts'
                    // text="Back To Profile"
                    // type='external'
                  />
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ textAlign: "center", lineHeight: 2 }}
                  >
                    Hello {userName}, you've added {contactCount} SOS Contacts.
                    The maximum contact you can add is 3.
                  </h3>
                  <br />
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <div className="form-group icon-input mb-0.5">
                        <i className="font-sm feather-user text-grey-500 pe-8"></i>

                        <input
                          style={{ paddingLeft: 40 }}
                          type="text"
                          placeholder="Contact's Full Name"
                          className="style2-input form-control text-grey-900 font-xsss ls-3"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                        <p> Your contact's full name </p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <div className="form-group icon-input mb-0.5">
                        <i className="font-sm feather-phone text-grey-500 pe-0"></i>

                        <input
                          style={{ paddingLeft: 40 }}
                          type="text"
                          placeholder="Active Phone"
                          className="style2-input form-control text-grey-900 font-xsss ls-3"
                          value={phoneNo}
                          onChange={(e) => setPhoneNo(e.target.value)}
                        />
                        <p>
                          Your contact's 11 digits active number for SMS
                          delivery
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <br />
                  {firstName && phoneNo ? (
                    <>
                      <br />

                      {adding ? (
                        <div className="text-center">
                          <a
                            disabled
                            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                          >
                            Just A Sec ...
                          </a>
                        </div>
                      ) : (
                        <div className="text-center">
                          <a
                            onClick={() => addContact()}
                            className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                          >
                            ADD CONTACT
                            {/* means auth id = the user i params */}
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {addedFriend ? (
                    <>
                      {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        You've successfully added a new contact!
                      </Alert>
                      {/* </Snackbar> */}
                      <br />
                    </>
                  ) : (
                    ""
                  )}
                  <br />
                  {error ? (
                    <div>
                      <Alert severity="error" onClose={handleClose}>
                        {errorMessage}
                      </Alert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}

                  {removedFriend ? (
                    <>
                      {/* <Snackbar autoHideDuration={4000} onClose={handleClose}> */}
                      <Alert
                        onClose={handleClose}
                        variant="filled"
                        severity="success"
                      >
                        You've removed a contact successfully!
                      </Alert>
                      {/* </Snackbar> */}
                      <br />
                    </>
                  ) : (
                    ""
                  )}

                  <div className="row ps-2 pe-2">
                    {following.map((user, index) => (
                      <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                              <img
                                // onError={fixImage}
                                src="/assets/images/fav-512.png"
                                alt="avater"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                              />
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">
                              Name: {user.name}
                            </h4>
                            <a href={`tel:+234${user.phone}`}>
                              <p className="fw-500 text-primary mt-0 mb-3">
                                Call: {user.phone}
                              </p>
                            </a>

                            <>
                              {removing && contactId == user.id ? (
                                <a
                                  disabled
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                >
                                  Just A Sec ...
                                </a>
                              ) : (
                                <a
                                  onClick={() => removeContact(user.id)}
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                >
                                  REMOVE
                                  {/* means auth id = the user i params */}
                                </a>
                              )}
                            </>

                            <div style={{ marginBottom: 40 }}> </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div style={{ marginTop: 40 }}></div>
                    <div className="text-center">
                      <a
                        href="/"
                        className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                      >
                        BACK TO ALARRTS
                        {/* means auth id = the user i params */}
                      </a>
                    </div>
                    <div style={{ marginTop: 80 }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
  // }
}

// export default Followers;
//
