import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import ChatV2 from "./ChatV2";

const emailList = [
  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Victor Exrixon",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Goria Coast",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "David Goria",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Seary Victor",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Ana Seary",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    status: "bg-success",
    subject: "Mobile App Design",
    des: "Hey Cak, Could you free now? Can you look and read the brief first before.",
    attach: "attach",
    time: "12:45 PM",
    read: "bg-lightblue theme-light-bg",
  },
];

class ChatList extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div
              className="middle-sidebar-left pe-0 ps-lg-3 ms-0 me-0"
              style={{ maxWidth: `100%` }}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="container m-auto pt-0">
                  
                  <div className="col-xl-6 d-none d-xl-block p-0 vh-100 bg-image-contain bg-image-center bg-no-repeat" 
                            style={{backgroundImage: `url("/assests/images/ad2.jpg")`}}></div>
                            <div className="col-xl-6 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                                <div className="card shadow-none border-0 ps-lg--5 me-auto coming-soon-card">
                                    <div className="card-body rounded-0 text-left pt-md-5  ps-0 pe-0">
                                        <div className="timer w-100 mb-3 bg-grey-time"><div className="time-count"><span className="text-time">00</span> <span className="text-day">Day</span></div> <div className="time-count"><span className="text-time">00</span> <span className="text-day">Hours</span> </div> <div className="time-count"><span className="text-time">00</span> <span className="text-day">Min</span> </div> <div className="time-count"><span className="text-time">00</span> <span className="text-day">Sec</span> </div> </div>
                                        <h2 className="fw-700 text-grey-900 display3-size display4-md-size lh-2">We're still <span className="text-primary">in development.</span> Check back for an update soon.</h2>
                                        {/* <div className="form-group mt-4 p-1 border p-2 bg-white rounded-3">
                                        </div> */}
                                    </div>
                                </div>
                            </div> 

                    <h1 className="font-semibold lg:mb-6 mb-3 text-2xl">
                      {" "}
                      Messages
                    </h1>

                    <div className="lg:flex lg:shadow lg:bg-white lg:space-y-0 space-y-8 rounded-md lg:-mx-0 -mx-5 overflow-hidden lg:dark:bg-gray-800">
                      {/* <!-- left message--> */}
                      <div className="lg:w-4/12 bg-white border-r overflow-hidden dark:bg-gray-800 dark:border-gray-600">
                        {/* <!-- search--> */}

                        <div className="border-b px-4 py-4 dark:border-gray-600">
                          <div className="bg-gray-100 input-with-icon rounded-md dark:bg-gray-700">
                            <form action="#" className="pt-0 pb-0 ms-auto">
                              <div className="search-form-2 ms-2">
                                <i className="ti-search font-xss"></i>
                                <input
                                  type="text"
                                  className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                                  placeholder="Search here."
                                />
                              </div>
                            </form>
                          </div>
                        </div>

                        {/* <!-- user list--> */}
                        <div className="pb-16 w-full">
                          <ul className="dark:text-gray-100">
                            <li>
                              <a
                                href="#"
                                className="block flex items-center py-3 px-4 space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <div className="w-12 h-12 rounded-full relative flex-shrink-0">
                                  <img
                                    src="assets/images/avatars/avatar-2.jpg"
                                    alt=""
                                    className="absolute h-full rounded-full w-full"
                                  />
                                  <span className="absolute bg-green-500 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                                </div>
                                <div className="flex-1 min-w-0 relative text-gray-500">
                                  <h4 className="text-black font-semibold dark:text-white">
                                    David Peterson
                                  </h4>
                                  <span className="absolute right-0 top-1 text-xs">
                                    Sun
                                  </span>
                                  <p className="truncate">
                                    Esmod tincidunt ut laoreet
                                  </p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block flex items-center py-3 px-4 space-x-3 bg-gray-100 dark:bg-gray-700"
                              >
                                <div className="w-12 h-12 rounded-full relative flex-shrink-0">
                                  <img
                                    src="assets/images/avatars/avatar-1.jpg"
                                    alt=""
                                    className="absolute h-full rounded-full w-full"
                                  />
                                  <span className="absolute bg-green-500 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                                </div>
                                <div className="flex-1 min-w-0 relative text-gray-500">
                                  <h4 className="text-black font-semibold dark:text-white">
                                    Sindy Forest
                                  </h4>
                                  <span className="absolute right-0 top-1 text-xs">
                                    {" "}
                                    Mon
                                  </span>
                                  <p className="truncate">
                                    Seddiam nonummy nibh euismod laoreet
                                  </p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block flex items-center py-3 px-4 space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <div className="w-12 h-12 rounded-full relative flex-shrink-0">
                                  <img
                                    src="assets/images/avatars/avatar-5.jpg"
                                    alt=""
                                    className="absolute h-full rounded-full w-full"
                                  />
                                  <span className="absolute bg-gray-300 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                                </div>
                                <div className="flex-1 min-w-0 relative text-gray-500">
                                  <h4 className="text-black font-semibold dark:text-white">
                                    {" "}
                                    Zara Ali{" "}
                                  </h4>
                                  <span className="absolute right-0 top-1 text-xs">
                                    4 hours ago
                                  </span>
                                  <p className="truncate">
                                    Consectetuer adiscing elit
                                  </p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block flex items-center py-3 px-4 space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <div className="w-12 h-12 rounded-full relative flex-shrink-0">
                                  <img
                                    src="assets/images/avatars/avatar-4.jpg"
                                    alt=""
                                    className="absolute h-full rounded-full w-full"
                                  />
                                  <span className="absolute bg-green-500 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                                </div>
                                <div className="flex-1 min-w-0 relative text-gray-500">
                                  <h4 className="text-black font-semibold dark:text-white">
                                    David Peterson
                                  </h4>
                                  <span className="absolute right-0 top-1 text-xs">
                                    Week ago
                                  </span>
                                  <p className="truncate">
                                    Nam liber tempor{" "}
                                    <i className="uil-grin-tongue-wink"></i>
                                  </p>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                className="block flex items-center py-3 px-4 space-x-3 hover:bg-gray-100 dark:hover:bg-gray-700"
                              >
                                <div className="w-12 h-12 rounded-full relative flex-shrink-0">
                                  <img
                                    src="assets/images/avatars/avatar-2.jpg"
                                    alt=""
                                    className="absolute h-full rounded-full w-full"
                                  />
                                  <span className="absolute bg-green-500 border-2 border-white bottom-0 h-3 m-0.5 right-0 rounded-full shadow-md w-3"></span>
                                </div>
                                <div className="flex-1 min-w-0 relative text-gray-500">
                                  <h4 className="text-black font-semibold dark:text-white">
                                    David Peterson
                                  </h4>
                                  <span className="absolute right-0 top-1 text-xs">
                                    Week ago
                                  </span>
                                  <p className="truncate">
                                    Esmod tincidunt ut laoreet
                                  </p>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <!--  message--> */}
                  <ChatV2 />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default ChatList;
