import React, { Component, Fragment } from "react";
import { useState, useEffect } from "react";
import { Route, useParams, Link } from "react-router-dom";
import ImageComponentDiv from "./ImageComponentDiv";
import ImageComponent from "./ImageComponent";
import Load from "./Load";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

export default function UserChannel(props) {
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const loggedInId = useState(window.localStorage.getItem("user_id"));
  const { user_name } = useParams(["user_name"]);
  const channels = props.channels;
  const userId = props.user_id;

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function subscribeToogle(id) {
    // console.log(user_name);
    const access_token = await window.localStorage.getItem("access_token");
    const userName = await window.localStorage.getItem("user_name");
    let result = await axiosFetch
      .post(
        `/channels/${id}/subscribe`,
        userName,
        {
          headers: {
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/html",
            Authorization: "Bearer " + access_token,
          },
          // method: 'POST',
        }
      )
      .then((resp) => {
        // getAllChannels();
        props.functionProp();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  if (props.channels.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}
          <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
            <div className="card-body p-0">
              {props.isLoggedInUser ? (
                <h5
                  className="middle-wrap fw-600 text-grey-900"
                  style={{ textAlign: "center", lineHeight: 2 }}
                >
                  Hello @{user_name}! You Haven't Created Any {props.type}.{" "}
                  <span>
                    {" "}
                    Click The Button Above To Do So Now
                  </span>{" "}
                  
                </h5>
              ) : (
                <h5
                  className="middle-wrap fw-600 text-grey-900"
                  style={{ textAlign: "center", lineHeight: 2 }}
                >
                  Hello! @{user_name} Hasn't Created Any {props.type}. You Can Check
                  Back Later
                </h5>
              )}
            </div>
          </div>
          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}
        <div className="row">
           <div className="col-xl-12">
             <div className="row ps-2 pe-1">
        {props.channels.map((channel, id) => (
      
          <div className="col-md-6 col-sm-6 pe-2 ps-2">
            <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-0 mt-2">
            {props.type == 'Page' ? (
                <ImageComponentDiv
                onError={fixImage}
                image={channel.cover_pic}
                alt="avater"
                class="card-body position-relative h100 bg-image-cover bg-image-center"
              />
              ) : (
                <ImageComponentDiv
                  onError={fixImage}
                  image={channel.cover_pic}
                  alt="avater"
                  class="card-body position-relative h100 bg-image-cover bg-image-center"
                />
              )}
              <div className="card-body d-block w-100 pl-10 pe-4 pb-4 pt-0 text-left position-relative">
                <figure
                  className="avatar position-absolute w75 z-index-1 left-15"
                  style={{
                    marginTop: `-40px`,
                    marginLeft: "-10px",
                  }}
                >
                  {props.type == 'Page' ? (
                    <ImageComponent
                    // onError={fixImage}
                    image={channel.page_pic}
                    alt="avater"
                    class="float-right p-1 bg-white rounded-circle w-100 "
                  />
                  ) : (
                    <ImageComponent
                      // onError={fixImage}
                      image={channel.channel_pic}
                      alt="avater"
                      class="float-right p-1 bg-white rounded-circle w-100 "
                    />
                  )}
                </figure>

                <div className="clearfix"></div>
                {props.type == 'Page' ? 
                <>
                <Link
                  to={{
                    pathname: `/page/${channel.slug}`,
                    state: { channelId: channel.id },
                  }}
                >
                  <h4 className="fw-700 font-xss mt-3 mb-0">
                    Page: {channel.name}{" "}
                  </h4>
                </Link><br/>
                </>
                :
                <>
                <Link
                  to={{
                    pathname: `/channel/${channel.slug}`,
                    state: { channelId: channel.id },
                  }}
                >
                  <h4 className="fw-700 font-xss mt-3 mb-0">
                    Channel: {channel.name}{" "}
                  </h4>
                </Link><br/>
                </>
  }
                <Link
                //  to={`/profile/${channel.admin.user_name}`}
                >
                  {" "}
                  <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                    Created By:{" "}
                    <span className="text-primary-500">
                      {channel.admin.user_name}
                    </span>{" "}
                  </p>{" "}
                </Link>
               
                <div
                  style={{ marginTop: 2, marginLeft: "-5px" }}
                  className="d-flex align-items-center"
                  // className="position-absolute right-5 top-10 d-flex align-items-center"
                >
                   {props.type == 'Page' ?
                  <ul className="d-flex align-items-center justify-content-center mt-1">
                    <li className="m-2">
                      <h4 className="fw-600 font-sm">
                        {channel.subscribers_count}
                        <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                          Followers
                        </span>
                      </h4>
                    </li>
                    <li className="m-2">
                      <h4 className="fw-600 font-sm">
                      {channel.posts_count}
                        <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                          Posts
                        </span>
                      </h4>
                    </li>
                   
                  </ul>
                  :
                  <ul className="d-flex align-items-center justify-content-center mt-1">
                    <li className="m-2">
                      <h4 className="fw-600 font-sm">
                        {channel.subscribers_count}
                        <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                          Subscribers
                        </span>
                      </h4>
                    </li>
                    <li className="m-2">
                      <h4 className="fw-600 font-sm">
                        {channel.videos_count}
                        <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                          Videos
                        </span>
                      </h4>
                    </li>
                    <li className="m-2">
                      <h4 className="fw-600 font-sm">
                        {channel.movies_count}
                        <span className="font-xsssss fw-500 mt-1 text-grey-500 d-block">
                          Movies
                        </span>
                      </h4>
                    </li>
                  </ul>
  }
                </div>

                {props.type == 'Page' ? 
                <div
                  style={{ marginTop: "-15px", marginLeft: "-10px" }}
                  // className="position-absolute right-15 top-10 d-flex align-items-center"
                >
                  <Link
                    to={{
                      pathname: `/page/${channel.slug}`,
                      state: { channelId: channel.id },
                    }}
                  >
                    <a
                      // onClick={() => subscribeToogle(channel.id)}
                      style={{ alignSelf: "center" }}
                      className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                    >
                      VIEW
                    </a>
                  </Link>
                </div>
                :
                <div
                style={{ marginTop: "-15px", marginLeft: "-10px" }}
                // className="position-absolute right-15 top-10 d-flex align-items-center"
              >
                <Link
                  to={{
                    pathname: `/channel/${channel.slug}`,
                    state: { channelId: channel.id },
                  }}
                >
                  <a
                    // onClick={() => subscribeToogle(channel.id)}
                    style={{ alignSelf: "center" }}
                    className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                  >
                    VIEW
                  </a>
                </Link>
              </div>
  }
              </div>
            </div>
          </div>
        
        ))}
          </div>
          </div>
          </div>
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
      // </div>
    );
  }
}
