import React, { Component, useState, useEffect } from "react";

import "./movie-card.scss";
import axios from "axios";
import axiosFetch from '../../Interceptors2';
import { format, formatDistance, subDays } from "date-fns";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import IconButton from "@mui/material/IconButton";
import Button from "../button/Button";
import Tooltip from "@mui/material/Tooltip";
import MuiAlert from "@material-ui/lab/Alert";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import ChannelsContentEdit from "../../components/ChannelsContentEdit";
import ChannelsEpisodesModal from "../../components/ChannelsEpisodesModal";
import ChannelsSeriesEdit from "../../components/ChannelsSeriesEdit";
import AddCasts from "../../components/AddCasts";
// import IconButton from "@mui/material/IconButton";

const SeriesCard = (props) => {
  // const item  = props.item;
  const movie = props.movies;
  const id = props.id;
  const type = props.type;

  const channelSlug = props.channelSlug;
  const channelId = props.channelId;
  const adminId = props.adminId;
    // const url = props.url;
    const url = `channel/${channelSlug}/series/episode`
  const { channel } = useParams(["channel"]);
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = useState("released");
  const [isCreating, setIsCreating] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [title, setTitle] = useState("");
  const [tags, setTags] = useState([]);
  const [overview, setOverview] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [image, setImage] = useState("");
  const animatedComponents = makeAnimated();
  // const { url } = useParams();

  let history = useHistory();

  const handleClose = () => {
    setConfirmDelete(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  function refresh() {
    window.location.reload();
  }

  function ChangeTag(value) {
    console.log(`selected ${value}`);
    // setAlertTagsName(value);

    // console.log(`state ${alertTagsName}`);

    value.map((tag) =>
      // alertTagsName.push(tag)
      tags.push(tag.value)
    );

    console.log(`name ${tags}`);
  }
  function confirmDeletion(id) {
    if (confirmDelete == true) {
      setConfirmDelete(false);
    } else {
      setConfirmDelete(true);
    }
  }
  async function deleteVideo(id) {
    const access_token = await window.localStorage.getItem("access_token");
    // const userID = await window.localStorage.getItem("user_id");
    // alert(id)
    axiosFetch
      .delete(
        `/movie/${id}`,
        // id,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        window.location.reload();
        setConfirmDelete(false);
        props.functionProp()
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }
  async function createSeries() {
    setIsCreating(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("channel_id", props.channelId);
    formData.append("title", title);
    formData.append("tags", tags);
    formData.append("overview", overview);
    formData.append("image", image);
    formData.append("cover_image", coverImage);
    // formData.append("pricing", pricing);
    formData.append("status", status);

    axiosFetch
      .post(
        `/movie/series/${props.channelId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(`hi ${resp.data}`);
        setTitle("");
        setTags("");
        setOverview("");
        setImage("");
        setCoverImage("");
        setIsCreating(false);
        // props.functionProp();
        setOpen(false);
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
    // }
  }
  // useEffect(() => {}, [console.log(status)]);
  return (
    <>
      <div
        className="movie-card overflow-hidden"
        style={{
          backgroundImage: `url("${movie.image}")`,
          marginRight:10
        }}
      >
        {/* <Link
          to={{
            pathname: `/${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
            // pathname: `/channel/series/episode/${movie.slug}`,
            // pathname: `${url}/${movie.slug}`,
            // {`/channel/series/episode/${series.slug}`}
            // state: { channelSlug, id: movie.id, adminId: adminId },
          }}
        >
          <Button
            className="btn-outline"
            // onClick={() => history.push( url +'/' + movie.slug)}
          >
            <i className="bx bx-play"></i>
          </Button>
        </Link> */}
        
        <div style={{display:'flex', alignSelf:'center', justifyContent:'center'}}>
        <IconButton   className="btn-outline">
          <i className="bx bx-play"  
          style={{ color: "#fff", fontSize: 60 }}
            onClick={() => {
              history.push({
                pathname: `/${url}/${movie.slug}?channel=${channelSlug}&w=${movie.id}&a=${adminId}`,
                
              })
              refresh()
            }
          }></i>
              </IconButton>
        {props.isAdmin ? (
          <>
            <div className="clearfix"></div>
            {/* <ChannelsContentEdit  */}
            <ChannelsContentEdit
              movie={movie}
              isEpisode={true}
              title={movie.title}
              overview={movie.overview}
              tags={movie.tags}
              image={movie.image}
              cover_image={movie.cover_image}
              type='episode'
              status={movie.status}
              pricing={movie.pricing}
              tags={movie.tags}
              movieId={movie.id}
              channelId={props.channelId}
              functionProp={props.functionProp}
              functionProp2={props.functionProp2}
            />
            {/* <AddCasts movieId ={movie.id} color='#fff' type='movie' movieType ={movie.type}/> */}
            <AddCasts movieId={movie.id} color='#fff' getUrl='casts/movie/get' addUrl='casts' removeUrl='casts' type='movie' channelId={props.channelId} movieType ={movie.type}/>
            {/* <ChannelsEpisodesModal
              className="d-inline-block"
              seriesId={movie.id}
              title={movie.title}
              channelId={props.channelId}
              functionProp={props.functionProp}
              functionProp2={props.functionProp2}
              // functionProp={() => props.functionProp()}
            /> */}

            <Tooltip title="Delete Content">
              <IconButton>
                <DeleteForeverOutlinedIcon
                  onClick={() => confirmDeletion(movie.id)}
                  style={{ color: "#fff", fontSize: 30, }}
                />{" "}
              </IconButton>
            </Tooltip>
            {/* <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500 me-2">
                  <i className="btn-round-sm bg-greylight ti-ruler-pencil text-grey-500 me-1"></i>{" "}
                  Free
                </h5>
              
                <h5 className="mt-4 mb-4 d-inline-block font-xssss fw-600 text-grey-500">
                  <i className="btn-round-sm bg-greylight ti-credit-card text-grey-500 me-1"></i>{" "}
                  Card
                </h5> */}
            <div className="clearfix"></div>
          </>
        ) : (
          ""
        )}
        </div>
        <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
          {movie.title}
          {/* {movie.type} */}
        </h4>
        {/* <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
           
            {movie.movies.length} Episodes
          </h4> */}

        {props.isAdmin ? (
          <>
          <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
            {/* {movie.title} */}
            Status: {movie.status}
          </h4>
          {/* <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
            
            Pricing: {movie.pricing}  {movie.price}
          </h4> */}
          </>
        ) : (
          <>
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
              {/* {movie.title} */}
              Type: {type}
            </h4>
            {/* <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
           
            Pricing: {movie.pricing}  {movie.price}
          </h4> */}
            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xss text-white mb-3 mb-1 text-center">
              {/* {movie.title} */}
              Released:{" "}
              {formatDistance(new Date(movie.created_at), new Date(), {
                addSuffix: true,
              })}
            </h4>
          </>
        )}
        {confirmDelete ? (
          <MuiAlert
            onClose={handleClose}
            severity="error"
            action={
              <>
                <a
                  onClick={handleClose}
                  className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                >
                  No, Cancel.
                </a>

                <a
                  onClick={() => deleteVideo(movie.id)}
                  className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                >
                  Yes, delete it.
                </a>
              </>
            }
          >
            Are you sure you want to delete this ?
          </MuiAlert>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SeriesCard;
