import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";

// import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import Load2 from "./Load2";
import SingleAlertComponent from "./SingleAlertComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import AlertLocation from "./AlertLocation";
import AlertLocationCurrent from "./AlertLocationCurrent";
import AlertLocationV2 from "./AlertLocationV2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";

export default function SingleAlertLocation() {
  //   const latitude = window.sessionStorage.getItem("lat");
  //   const longitude = window.sessionStorage.getItem("lon");
  //   const watch_latitude = window.sessionStorage.getItem("wat_lat");
  //   const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const userName = window.localStorage.getItem("first_name");
  const [alerts, setAlerts] = useState([]);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const adminLat = window.sessionStorage.getItem("wat_lat");
  const adminLng = window.sessionStorage.getItem("wat_lon");
  const [copied, setCopied] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname);

  const alertId = queryParams.get("i");

  function closeSnack() {
    setCopied(false)
  }

  async function getAlertLoad() {
    // const reloadCount = localStorage.getItem("reloadCount");
    // if (reloadCount < 1) {
    //   localStorage.setItem("reloadCount", String(reloadCount + 1));
    //   console.log("reload count is less than one");
    //   // history.push(`${pathname}`)
    //   // history.go()
    //   window.location.reload();
    // } else {
    //   localStorage.removeItem("reloadCount");
    // }

    setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        setFetchingAlerts(false);
        console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAlert() {
    // setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        console.log(JSON.stringify(resp.data));
        // setFetchingAlerts(false);
        // console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getAlertLoad();
  }, []);

  if (fetchingAlerts == true) {
    console.log(`final ${fetchingAlerts}`);
    return <>Loading Map...</>;
  }
  if (alerts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Loading...
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <div style={{ margin: 20 }}>
        <h3> Viewing Alert Info As Admin </h3>
        <br />
        <CopyToClipboard
         text={adminLat + ' ' + adminLng}
         onCopy={() => setCopied(true)}
       >
        <p>
          Hello Admin! Your Current Location is lat-> {adminLat} : lng->{" "}
          {adminLng}. <span style={{color:'blue'}}>Click to copy </span>
        </p>
        </CopyToClipboard>
        {alerts.alert.alert_location.latitude ? (
           <CopyToClipboard
           text={alerts.alert.alert_location.latitude + ' ' + alerts.alert.alert_location.longitude}
           onCopy={() => setCopied(true)}
         >
          <p style={{color:'blue'}}>
            Copy User's Previous location : Lat->{" "}
            {alerts.alert.alert_location.latitude} : Lng->{" "}
            {alerts.alert.alert_location.longitude}
          </p>
          </CopyToClipboard>
        ) : (
          <p>location is : not recieved </p>
        )}
        {alerts.alert.alert_location.watch_latitude ? (
         <CopyToClipboard
         text={alerts.alert.alert_location.watch_latitude + ' ' + alerts.alert.alert_location.watch_longitude}
         onCopy={() => setCopied(true)}
       >
        <p style={{color:'blue'}}>
            Current location is : lat->{" "}
            {alerts.alert.alert_location.watch_latitude} : lng->{" "}
            {alerts.alert.alert_location.watch_longitude}
          </p>
          </CopyToClipboard>
        ) : (
          <p>Current location is : not recieved </p>
        )}
        {alerts.alert.user ? (
          <>
          
          <p>
            Alarrt Creator's Detail : Name( {alerts.alert.owner.first_name}{" "}
            {alerts.alert.owner.last_name} ). Phone ({" "}
            {alerts.alert.owner.phone_number} ) . Email ({" "}
            {alerts.alert.owner.email} )
            . 
          </p>
          <CopyToClipboard
         text={alerts.alert.alert_location.ip_address}
         onCopy={() => setCopied(true)}
       >
        <p style={{color:'blue'}}>
            Copy IP Address is -> {alerts.alert.alert_location.ip_address}
          </p>
          </CopyToClipboard>
          
          <br/>
          <Button
          // disabled
          variant="contained"
          color="primary"
          href={`/profile/${alerts.alert.owner.user_name}`}
          // target="_blank hgj"
          // onClick={this.createPost}
        >
          View Profile
        </Button>
        <br/>
          {/* <a href={`/profile/${alerts.alert.owner.user_name}`}> View Profile</a>{" "} */}
         
          </>
        ) : (
          <p>User Details : not recieved </p>
        )}
       
        <br />
        <Button
          // disabled
          variant="contained"
          color="primary"
          href="/"
          // target="_blank"
          // onClick={this.createPost}
        >
          Back To Alerts
        </Button>
        <br />
        <h5> There are 3 location markers. You -> Where you currently are. B -> Where the user was when alarrt was created. C -> Where user currently is. </h5>
        <br />
       
        <AlertLocationCurrent
          phone={alerts.alert.user.phone_number}
          startLat={alerts.alert.alert_location.latitude}
          startLong={alerts.alert.alert_location.longitude}
          currentLat={alerts.alert.alert_location.watch_latitude}
          currentLong={alerts.alert.alert_location.watch_longitude}
          locationName={alerts.alert.location}
          adminLat={adminLat}
          adminLng={adminLng}
        />
        {/* <SingleAlertComponent alert={alerts} functionProp={() => getAlert()} /> */}

        {copied ? (
                <>
                <br/>
               
                 <Snackbar
                    open={copied}
                    autoHideDuration={1000}
                    onClose={closeSnack}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  >
                    <Alert  onClose={closeSnack} severity="success">
                   Link Copied Successfully. Share with your friends
                    </Alert>
                  </Snackbar>
                </>
              ) : null} 
      </div>
    );
  }
}
