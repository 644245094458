import React, { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import SendSms from "../components/SendSms";
import SendPushNotification from "../components/SendPushNotification";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";


import { Snackbar } from "@material-ui/core";

// class Settings extends Component {
export default function Settings(props) {
  // render() {

  const [copied, setCopied] = useState(false);
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const ip_address = window.sessionStorage.getItem("ip");
  const userType = window.localStorage.getItem("au");

  function closeSnack() {
    setCopied(false);
  }

  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}

      <div className="main-content bg-lightblue theme-dark-bg right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card  vh-180 w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        Settings
                      </h4>
                      <div className="nav-caption fw-600 font-xssss text-grey-500 mb-2">
                        General
                      </div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/profileinformation"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-home font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Update Profile
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/sos-contacts"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-user-plus font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Add SOS Contacts
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        <li className="list-inline-item d-block border-bottom me-0">
                          <span
                            
                            className="pt-2 pb-2 d-flex align-items-center"
                          > 
                          <span className="btn-round-md pointer bg-blue-gradiant">
                                <i className="font-xs feather-copy text-white"></i>
                              </span>
                            <CopyToClipboard
                              text={`${watch_latitude} ${watch_longitude}`}
                              onCopy={() => setCopied(true)}
                            >
                              <span>
                              <h4 className="fw-600 font-xsss mb-0 mt-0 ml-2">
                              Share Location:
                            </h4>
                            <h6 className="fw-600 font-xssss mb-0 mt-1 ml-2">
                              (Click To Copy, Then Paste In Google Maps)
                            </h6>
                              </span>
                            </CopyToClipboard>

                            
                         
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </span>
                        </li>

                         
                      </ul>

                      {userType === "true" ?

                      <SendPushNotification />
                      :
                      ''
}

                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                        Account Settings
                      </div>
                      <ul className="list-inline mb-4">
                        <li className="list-inline-item d-block me-0">
                          <Link
                            to="/social_accounts"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-twitter font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Social Networks
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        {/* <li className="list-inline-item d-block border-bottom me-0"><Link to="/payment" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-mini-gradiant text-white feather-credit-card font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">My Cards</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                        {/* <li className="list-inline-item d-block  me-0"><Link to="/password" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-inbox font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Send Random Sms</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}
                        <SendSms />
                        {/* <li className="list-inline-item d-block me-0"><a href="/password" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Send Random Sms</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li> */}
                      </ul>

                      <div className="nav-caption fw-600 font-xsss text-grey-500 mb-2">
                        Other
                      </div>
                      <ul className="list-inline">
                        <li className="list-inline-item d-block border-bottom me-0">
                          <a
                            href="/help"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-help-circle font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">FAQ</h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </a>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <a
                            href="/contact-us"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-mail font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Contact Us
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </a>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/notifications"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-bell font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Your Notifications
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <a
                            href="https://wa.me/message/3VHYR6VAI3MSD1"
                            target="_blank"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-message-circle font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Whatsapp Support
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </a>
                        </li>
                        <li className="list-inline-item d-block border-bottom me-0">
                          <Link
                            to="/support"
                            target="_blank"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <i
                              className="btn-round-md bg-blue-gradiant text-white feather-help-circle font-md me-3"
                              style={{
                                display: "flex",
                                fontSize: 20,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            ></i>{" "}
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              Get Technical Support
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        {/* <li className="list-inline-item d-block me-0"><a href="/login" className="pt-2 pb-2 d-flex align-items-center"><i className="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 className="fw-600 font-xsss mb-0 mt-0">Logout</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li> */}
                      </ul>
                      <br />
                      <br />
                      <br />

                      {copied ? (
                        <>
                          <br />
                          {/* <p className="text-primary">link copied</p> */}
                          {/* <Alert onClose={closeSnack} severity="success">
                          Link Copied Successfully
                        </Alert> */}
                          <Snackbar
                            open={copied}
                            autoHideDuration={1000}
                            onClose={closeSnack}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Alert onClose={closeSnack} severity="success">
                              Link Copied Successfully. Share anywhere
                            </Alert>
                          </Snackbar>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      <Appfooter />
    </Fragment>
  );
}
// }

// export default Settings;
