import * as React from "react";

import useScreenRecorder from "use-screen-recorder";
import RecordScreen from "./RecordScreen";

export default function RecordScreen2() {

// const Example = () => {
  const {
    blobUrl,
    pauseRecording,
    resetRecording,
    resumeRecording,
    startRecording,
    status,
    stopRecording,
  } = useScreenRecorder({ audio: true });

  return (
    <div>
        <video src={blobUrl} controls autoPlay loop />
      {/* <video src={blobUrl} /> */}

      <small>Status: {status}</small>

      <button onClick={startRecording}>Start Recording</button>
      <button onClick={stopRecording}>Stop Recording</button>
      <button onClick={pauseRecording}>Pause Recording</button>
      <button onClick={resumeRecording}>Resume Recording</button>
      <button onClick={resetRecording}>Reset Recording</button>
    </div>
  );
// };

}