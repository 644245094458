import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import MuiAlert from "@material-ui/lab/Alert";
import CreatePost2 from "./CreatePost2";
import { Button, makeStyles } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Snackbar } from "@material-ui/core";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Chip from "@material-ui/core/Chip";
import { Upload } from "@aws-sdk/lib-storage";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import Resizer from "react-image-file-resizer";
import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const steps = [
  {
    label: "Select campaign settings",
  },
  {
    label: "Create an ad group",
  },
  {
    label: "Create an ad",
  },
];

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  textInput: {
    color: "red",
    // fontSize: 4
  },
}));

export default function PostUpdateModal(props) {
  const classes = useStyles();
  const [isUpdating, setIsUpdating] = useState(false);
  const [postCategory, setPostCategory] = useState("");
  //   (props.content.post_category_id);
  const [isLimit, setIsLimit] = useState(false);
  const [postCategoryName, setPostCategoryName] = useState("");
  const [postTags, setPostTags] = useState([]);
  const [postTagsName, setPostTagsName] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newTag, setNewTag] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [headline, setHeadline] = useState("");
  //   (props.content.headline);
  const [url, setUrl] = useState("");
  const [body, setBody] = useState(props.content.body);
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [images, setImages] = useState([]);
  const [postImage, setPostImage] = useState([]);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [postVideo, setPostVideo] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [userName, setUserName] = useState("");
  const [sendToFriends, setSendToFriends] = useState("No");
  const [openAlert, setOpenAlert] = useState(false);
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [creatingTag, setCreatingTag] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  //state for alert categories mapping
  const [allCategories, setAllCategories] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [userPic, setUserPic] = useState("");
  const [userFriendsCount, setUserFriendsCount] = useState("");
  const history = useHistory();
  const [invalidFile, setInvalidFile] = useState(false);
  const [checked, setChecked] = React.useState(false);
  const { Option } = Select;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showLive, setShowLive] = useState(false);
  const [showUrl, setShowUrl] = useState(false);


  const animatedComponents = makeAnimated();
  const options = [
    { value: "first-series", label: "First Series" },
    { value: "new-post", label: "New Post" },
  ];

  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleNextCat = () => {
    getPostCategories();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setOpen(false);
    setProcessed(false);
    setError(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEmptyFields(false);
  };
  const handleClose2 = () => {
    // setOpen(false);
    setCreatedCat(false);
    setEmptyFields(false);
    setProcessed(false);
  };

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      // setChecked2(true);
    } else {
      setShowLive(false);
      // setChecked2(false);
    }
  };
  const toogleUrlVid = (event) => {
    if (showUrl == false) {
      setShowUrl(true);
      // setChecked2(true);
    } else {
      setShowUrl(false);
      // setChecked2(false);
    }
    
  };

  async function validate() {
    // console.log(`video ${JSON.stringify(video)}`);
    // console.log(`trailer ${JSON.stringify(trailer)}`);
    if (!body) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      // update();
      uploadVideo();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function validateImage(e, value) {
    setPostImage([...e]);
    postImage.push(e);
    for (let i = 0; i < postImage[0].length; i++) {
      postImageUrls.push(URL.createObjectURL(postImage[0][i]));
    }
    setPostImage(postImageUrls);
  }
  function clearImages() {
    setPostImage([]);
    setPostImageUrls([]);
    setImages([]);
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  async function onChange3(event) {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  }

  const cancelCreation = () => {
    setActiveStep(0);
    setOpen(false);
    // history.go(`/`);
  };

  const ChangePostCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setPostCategory(value.id);
      setPostCategoryName(value.name);
      console.log(postCategory);
      console.log(postCategoryName);
    } else {
      setPostCategory("");
      setPostCategoryName("");
      console.log("value empty");
    }
  };

  function getPostCategories() {
    const access_token = window.localStorage.getItem("access_token");
    const user_name = window.localStorage.getItem("user_name");
    axiosFetch
      .get(`/post_category/${user_name}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getPostCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/post_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getPostCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  //   function ChangePostTag(value) {
  //     console.log(`selected ${value}`);
  //     setPostTagsName(value);

  //     console.log(`state ${postTagsName}`);
  //   }
  function ChangePostTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => postTagsName.push(tag.value));
    console.log(`name ${postTagsName}`);

    setSelectedTags([...new Set(postTagsName)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setIsUpdating(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "posts/videos/" + user_id + "/" + video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          // setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            // setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            update();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      update();
      console.log("video very empty");
    }
  }

  async function update() {
    setIsUpdating(true);
    setCreatingMessage("Updating Your Post, almost done");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();

    formData.append("post_category_id", postCategory);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("url", url);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }

    if (videoUrl) {
      formData.append("video", videoUrl);
    }

    formData.append("tags", postTagsName);
    // formData.append("sendToFriends", sendToFriends);
    // formData.append("type", "post");
    // formData.append("status", "active");

    axiosFetch
      .post(`/posts/update/${props.postId}`, formData, {
        // method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(`updated ${JSON.stringify(resp.data)}`);

        setIsUpdating(false);
        //
        if (resp.data.status == "success") {
          // setOpen(false);
          setSelectedTags([]);
          setPostImage([]);
          setPostImageUrls([]);
          setImages([]);
          localStorage.removeItem("postVideo");
          setProcessed(true);
          props.functionProp();
          setOpen(false)
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setIsUpdating(false);
          setProcessed(false);
        }
      })
      .catch((error) => {
        setIsUpdating(false);
        setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
    // }
  }
  useEffect(() => {
    // getPostCategories();
  }, []);
  return (
    <>
      <a
        onClick={handleClickOpen}
        className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
      >
        <i className="uil-edit-alt mr-1"></i>
        {props.title}
      </a>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Update Your Post Here</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Skip The Step You Don't Want To Update. You don't need to update
            all, just edit the info you need to change and click update
          </DialogContentText>

          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            style={{ width: "90vw" }}
          >
            {error ? (
              <div>
                <Alert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue updating your post. Kindly try again [{" "}
                  {errorMessage} ]
                </Alert>
                <br />
              </div>
            ) : (
              ""
            )}
            {processed ? (
              <Paper square elevation={0} className={classes.resetContainer}>
                <br />
                <Typography>
                  {" "}
                  Your post has been updated! Invite Your Friends Too So They
                  Can Benefit From This!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  // onClick={() => setOpen(false)}
                  onClick={handleReset}
                >
                  Go Back Home
                </Button>
                {/* <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button> */}
                <br />
              </Paper>
            ) : (
              ""
            )}

            <Step key="first">
              <StepLabel> Update Post Title / Heading </StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Your post heading goes here - Heading is optional, body is
                  required.
                </Typography>
                <TextField
                  variant="standard"
                  id="standard-basic"
                  // label={`Type to update your headline here ${userName}`}
                  required
                  size="small"
                  style={{
                    width: "100%",
                    fontSize: 4,
                    color: "red",
                    paddingleft: 60,
                    paddingleft: 20,
                  }}
                  onChange={(e) => setHeadline(e.target.value)}
                  defaultValue={props.content.headline}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />{" "}
                <br />
                <br />
                <textarea
                  onChange={(e) => setBody(e.target.value)}
                  style={{ paddingLeft: 50, paddingTop: 10 }}
                  defaultValue={props.content.body}
                  name="message"
                  className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                  cols="30"
                  rows="10"
                  // placeholder={`Tell us what is happening ${userName}`}
                ></textarea>
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleClose}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextCat}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="second">
              <StepLabel>Add post to your series - optional</StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  This is optional, if no change is made, your previous series
                  will be used.
                </Typography>{" "}
                <Autocomplete
                  id="combo-box-demo"
                  options={allCategories}
                  onChange={ChangePostCategory}
                  // onChange={(e) => setAlertCategoryName(e.target.value.name) }
                  inputValue={postCategoryName}
                  getOptionLabel={(option) => option.name}
                  fullWidth
                  PaperComponent={({ children }) => (
                    <Paper style={{ background: "#fff" }}>{children}</Paper>
                  )}
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="select series"
                      variant="standard"
                      style={{ backgroundColor: "pink !important" }}
                      LabelProps={{
                        className: classes.textInput,
                      }}
                    />
                  )}
                  required
                />
                <br />
                <TextField
                  variant="standard"
                  id="standard-basic"
                  label="Create New"
                  size="small"
                  // style={{width:'40%'}}
                  style={{ width: "100%" }}
                  onChange={(e) => setNewCategory(e.target.value)}
                  value={newCategory}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />
                {creatingCat ? (
                  <Button
                    disabled
                    variant="contained"
                    color="primary"
                    component="span"
                    style={{ marginTop: 7 }}
                  >
                    Creating New Series
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={createCategory}
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{ marginTop: 7 }}
                    >
                      Create Series
                    </Button>
                    {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                  </>
                )}
                {/* </div> */}
                {/* </div> */}
                {createdCat ? (
                  <>
                    <Snackbar
                      open={createdCat}
                      autoHideDuration={4000}
                      onClose={handleClose2}
                      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    >
                      <Alert onClose={handleClose2} severity="success">
                        Series successfully created. Select it above
                      </Alert>
                    </Snackbar>
                  </>
                ) : (
                  ""
                )}
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="third">
              <StepLabel> Change Your Post Tags</StepLabel>
              <StepContent>
                <Typography gutterBottom variant="body1">
                  New tags added will replace previous ones.
                </Typography>{" "}
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Previous tags you created :
                </Typography>
                {props.content.tags.map((tag) => (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 5 }}
                    className="infotag pointer"
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={`${tag.name}`}
                  />
                ))}{" "}
                <br />
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Tags You've Selected
                </Typography>
                {selectedTags.map((selectedTags) => (
                  <Chip
                    style={{ marginRight: 10, marginBottom: 5 }}
                    className="infotag pointer"
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={`${selectedTags}`}
                  />
                ))}
                <br />
                <CreatableSelect
                  isClearable
                  isMulti
                  onChange={ChangePostTag}
                  components={animatedComponents}
                  options={options}
                ></CreatableSelect>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Leave empty if no new tag
                </Typography>
                {/* <br/> */}
                <br />
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Add / Update External Source. Link Is Optional - start with
                  https://....
                </Typography>
                <TextField
                  variant="standard"
                  id="standard-basic"
                  label={"https://..."}
                  required
                  size="small"
                  style={{ width: "100%", fontSize: 4, color: "red" }}
                  onChange={(e) => setUrl(e.target.value)}
                  defaultValue={url}
                  LabelProps={{
                    className: classes.textInput,
                  }}
                />
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="fourth">
              <StepLabel> Add to your post image - (Optional)</StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  // className={classes.postHead}
                >
                  Upload new images below. Old ones are removed
                </Typography>{" "}
                {/* <br /> */}
                <div>
                  {!postImageUrls.length == 0 ? (
                    <>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        Slide to view all
                      </Typography>

                      <Carousel>
              {postImageUrls.map((slideImage, index) => (
                
                <img
                  src={slideImage}
                  key={index}
                  style={{ zIndex: -10 }}
                  alt=""
                />
              ))}
            </Carousel>

                      {/* <Zoom scale={1.4} arrows={false} indicators={true} duration='2000'>
                          {postImageUrls.map((slideImage, index) => (
                            // <div className="each-slide" key={index}>
                              <img src={slideImage} key={index} style={{zIndex: -10}} alt="" />
                             
                            // </div>
                          ))}
                          </Zoom> */}
                         <br/> 

                      {/* <div data-uk-slider="finite: true center: true autoplay: true">
                        <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
                          {postImageUrls.map((imageSrc) => (
                            <li style={{ marginRight: 10 }}>
                              <img src={imageSrc} alt="" />
                            </li>
                          ))}
                        </ul>
                      </div> */}

                      <Button
                        // onClick={() => setPostImageUrls([])}
                        onClick={clearImages}
                        className={classes.button}
                      >
                        Clear Images
                      </Button>
                      {/* {/* <br /> */}
                    </>
                  ) : (
                    <div className="col-lg-12 mb-3">
                      <div className="card mt-3 border-0">
                        <div className="card-body d-flex justify-content-between align-items-end p-0">
                          <div className="form-group mb-0 w-100">
                            {/* {/* <input type="file" name="file" id="file" multiple className="input-file" onChange={showImages} /> */}

                            <input
                              type="file"
                              name="file"
                              accept="image/*"
                              multiple
                              id="file"
                              className="input-file"
                              // onChange={validateImage}
                              onChange={(e) => {
                                // if (e.target.files.length > 4) {
                                //   setIsLimit(true);
                                // } else {
                                  // setImages([...e.target.files]);
                                  console.log([...e.target.files]);
                                  validateImage(e.target.files);
                                  onChange3(e);
                                // }
                              }}
                            />
                            <label
                              htmlFor="file"
                              className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                            >
                              <i className="ti-cloud-down large-icon me-3 d-block"></i>
                              <span className="js-fileName">
                                Drag and drop or click to add your media file
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isLimit ? (
                    <div>
                      <Alert
                        severity="warning"
                        onClose={handleClose}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        Maximum upload is 4 images, kindly reduce.
                      </Alert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  <br />
                </div>
                {/* </form> */}
                <div>
                  <div>
                    <br />
                    <Button
                      // disabled={activeStep === 0}
                      onClick={cancelCreation}
                      className={classes.button}
                    >
                      Close
                    </Button>
                    <Button
                      // disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Next" : "Next"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
            <Step key="fifth">
              <StepLabel> Last Step - (Optional) </StepLabel>
              <StepContent>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.postHead}
                >
                  Add or update your post video
                </Typography>
                <br />
            
                <div>
                      <>
                        {videoUrl ? (
                          <>
                            <br />
                            <Typography
                              gutterBottom
                              variant="body1"
                              className={classes.postHead}
                            >
                              Your post video
                            </Typography>

                            <br />
                            <ReactPlayer
                              controls={true}
                              // width='70%'
                              height="400px"
                              url={videoUrl}
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                            />
                            <br />
                            <br />
                            {creatingPost ? (
                              <Button disabled>{creatingMessage}</Button>
                            ) : (
                              <Button
                                onClick={
                                  () => {
                                    setVideoUrl("");
                                    setShowLive(false);
                                    setShowUrl(false);
                                  }
                                  //   this.setState({ profile_pic: "" })
                                }
                              >
                                Clear
                              </Button>
                            )}

                            <br />
                          </>
                        ) : (
                          <>
                            {video ? (
                              <>
                                <br />
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  className={classes.postHead}
                                >
                                  Your post video
                                </Typography>

                                <br />
                                <video width="400" controls>
                                  <source src={URL.createObjectURL(video)} />
                                </video>
                                <br />
                                <br />
                                {creatingPost ? (
                                  <Button disabled>{creatingMessage}</Button>
                                ) : (
                                  <Button
                                    onClick={
                                      () => {
                                        setVideo("");
                                        setShowLive(false);
                                        setShowUrl(false);
                                      }
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Remove
                                  </Button>
                                )}

                                <br />
                              </>
                            ) : (
                              <>
                                {showUrl ? (
                                  <>
                                    <br />
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      // className={classes.postHead}
                                    >
                                      You can upload a video from a url. If you
                                      wish to record a live video, use the
                                      record live video button instead
                                    </Typography>
                                    <br />
                                    <TextField
                                      //autoFocus
                                      margin="dense"
                                      id="name"
                                      label={`Your video's url`}
                                      value={videoUrl}
                                      // { window.localStorage.getItem("postVideo")}
                                      onChange={(e) => {
                                        // window.localStorage.setItem(
                                        //   "postVideo",
                                        //   e.target.value
                                        // )
                                        setVideoUrl(e.target.value);
                                      }}
                                      type="text"
                                      fullWidth
                                      variant="standard"
                                      style={{ width: "100%" }}
                                    />
                                    <br />
                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Cancel
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    {showLive ? (
                                      <>
                                        <br />
                                        <Typography
                                          gutterBottom
                                          variant="body1"
                                          // className={classes.postHead}
                                        >
                                          You can take a live recording using
                                          either of your front or back camera.
                                          If you have a video recorded already,
                                          use the upload video button instead
                                        </Typography>
                                        <br />
                                        <VideoRecorder
                                          onRecordingComplete={(videoBlob) => {
                                            // Do something with the video...
                                            console.log("videoBlob", videoBlob);
                                            setVideo(videoBlob);
                                          }}
                                        />
                                        <br />
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-12 mb-1">
                                          <div className="card mt-1 border-0">
                                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                              <div className="form-group mb-0 w-100">
                                                <input
                                                  type="file"
                                                  name="file3"
                                                  id="file3"
                                                  accept="video/*"
                                                  className="input-file"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.files[0].size >
                                                      300000 * 1028
                                                    ) {
                                                      setUploadLimit(true);
                                                    } else {
                                                      setUploadLimit(false);
                                                      setVideo(
                                                        e.target.files[0]
                                                      );
                                                    }
                                                  }}
                                                  // onChange={(e) => setVideo(e.target.files[0])}
                                                />
                                                <label
                                                  htmlFor="file3"
                                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                                >
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">
                                                    Click to replace your post
                                                    video
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <br /> */}
                                        {/* <br /> */}
                                      </>
                                    )}

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleLiveVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Record Live Video
                                        </Button>
                                        <br />
                                      </label>
                                    </span>

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Upload Video Url
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                <div>
                  {uploadLimit ? (
                    <div>
                      <MuiAlert
                        severity="warning"
                        onClose={closeError}
                        action={
                          <>
                            <CancelIcon onClick={closeError} />
                          </>
                        }
                      >
                        Maximum size allowed is 300 mb
                      </MuiAlert>
                      {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                      <br />
                      <a
                        href="https://www.freeconvert.com/video-compressor"
                        target="_blank"
                        className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                      >
                        {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                        <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                          Quickly compress it
                        </span>{" "}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <br />

                    <Button
                      //  disabled={index === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    {isUpdating ? (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          disabled
                        >
                          Updating Your Post
                        </Button>
                        <h4>{creatingMessage}</h4>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={validate}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1
                            ? "Update"
                            : "Update"}
                        </Button>
                      </>
                    )}
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={validate}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? "Update" : "Update"}
                    </Button> */}
                  </div>
                </div>
              </StepContent>
            </Step>
          </Stepper>

          {error ? (
            <div>
              <Alert
                severity="error"
                onClose={handleClose}
                action={
                  <>
                    {/* <a
                                onClick={handleBack}
                                className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                              >
                                Go Back
                              </a> */}
                    <CancelIcon onClick={closeError} />
                  </>
                }
              >
                There was an issue updating your post. Kindly try again [{" "}
                {errorMessage} ]
              </Alert>
              {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
              <br />
            </div>
          ) : (
            ""
          )}

          {processed ? (
            <Paper square elevation={0} className={classes.resetContainer}>
              <br />
              <Typography>
                {" "}
                Your post has been updated! - Invite your friends and family
                with your referral link so they can benefit from this! You also
                get free sms points when anyone signs up with your link. Go to
                your profile to get your referral link now.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                // onClick={() => setOpen(false)}
                onClick={handleReset}
              >
                Go Back Home
              </Button>
              {/* <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button> */}
              <br />
            </Paper>
          ) : (
            ""
          )}

          {processed ? (
            <>
              <Snackbar
                open={processed}
                autoHideDuration={9000}
                // onClose={handleClose2}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                <Alert onClose={handleClose2} severity="success">
                  Post successfully updated!
                </Alert>
              </Snackbar>
            </>
          ) : (
            ""
          )}

          <Box sx={{ maxWidth: 400 }}>
            {activeStep === steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                {/* <Typography>
                  Seems you've come to the last step. 
                </Typography> */}
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Go Back
                </Button>
              </Paper>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {isUpdating ? (
            <>
              <Button disabled>Updating Your Post</Button>
              <h4>{creatingMessage}</h4>
            </>
          ) : (
            <>
              <Button onClick={validate}>Update Now</Button>
            </>
          )}
          {/* <Button onClick={validate}>Create</Button> */}
        </DialogActions>
        {emptyFields ? (
          <MuiAlert onClose={handleClose2} severity="warning">
            Oops - body can not be empty. Kindly update
          </MuiAlert>
        ) : (
          ""
        )}
      </Dialog>{" "}
    </>
  );
}
