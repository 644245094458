import React, { Component, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

export default function Helpbox() {
  // class Helpbox extends Component {
  // render() {
  // const history = useHistory();
  // useEffect(()=> {
  //     if(window.localStorage.getItem("access_token"))
  //     {
  //       history.push("/")
  //     }
  //   },[])

  return (
    // <Fragment>
    //     <Header />
    //     <Leftnav />
    //     <Rightchat />

    // <div className="main-content right-chat-active">
    //     <div className="middle-sidebar-bottom">
    //         <div className="middle-sidebar-left">
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <div style={{ marginLeft: 20, marginRight: 20, paddingTop:30 }}>
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div
              className="card w-100 border-0 p-0 rounded-3 overflow-hidden bg-image-contain bg-image-center"
              style={{ backgroundImage: `url("assets/images/help-bg.png")` }}
            >
              <div
                className="card-body p-md-5 p-4 text-center"
                style={{ backgroundColor: "#2516c7" }}
              >
                <h2 className="fw-700 display2-size text-white display2-md-size lh-2">
                  Welcome to Alarrt Help Center!
                </h2>
                <p className="font-xsss ps-lg-5 pe-lg-5 lh-28 text-grey-200">
                  Explore our frequently asked questions below and get quick
                  answers to questions you might have. If you need further
                  assitance, have a complaint, or wish to pass accross a vital
                  information to us, Send us a message using the button below
                  and we'll respond shortly
                </p>
                {/* <div className="form-group w-lg-75 mt-4 border-light border p-1 bg-white rounded-3 ms-auto me-auto">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form-group icon-input mb-0">
                        <i className="ti-search font-xs text-grey-400"></i>
                        <input
                          type="text"
                          className="style1-input border-0 ps-5 font-xsss mb-0 text-grey-500 fw-500 bg-transparent"
                          placeholder="Search anything.."
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <a
                        href="helpbox"
                        className="w-100 d-block btn bg-current text-white font-xssss fw-600 ls-3 style1-input p-0 border-0 text-uppercase "
                      >
                        Search
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card w-100 border-0 shadow-none bg-transparent mt-5">
              <Accordion
                className="accodion-style--1 accordion"
                preExpanded={"0"}
              >
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What is Alarrt about ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Alarrt is an information media app that primarily enables
                      you post personal emergencies and stay informed of State and National emergencies around
                      you. It allows you alert your friends and love ones immediately
                      via instant sms and emails with the speed of light in a
                      single click! It also enables us alert emergency services around your alert location.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What Do Alerts Mean ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Alerts are emergency information you post on the platform.
                      These emergencies can be personal (happening to you at the
                      moment) or something you're witnessing. Once you create or
                      realert an alert, your close friends are notified
                      immediately via sms and mail. You can also send random
                      alerts via sms to your friends who might need the information. This
                      ensures they are informed and stay away from the hostile
                      area.
                      Currently,every alert created is visible on the platform so other users can realert them swiftly and so Emergency Response Services can be notified immediately to render help.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        Having issues accessing my account
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      If you are having issues creating your account, the email
                      or username you're using might have been used by another
                      user. If you have created your account, but are having
                      issues logging in, you can easily change your password to
                      a new one. If you still can't get access, kindly send us a
                      message using the button below.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem className="card shadow-xss">
                  <AccordionItemHeading className="card-header">
                    <AccordionItemButton>
                      <h5 className="fw-600 pt-2 pb-2 mb-0">
                        What are reward points ?
                      </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel className="card-body">
                    <p>
                      Reward points are equivalent to SMS points. Sms points
                      enables you send instant sms to friends from Alarrt. You
                      are awarded sms points when you create your account on
                      Alarrt. You get additional points if you signup with a
                      friend's referral link, when friends signup with your
                      referral link, when you verify your email address and much
                      more scenerios. Your referral link can be gotten from your
                      profile.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <br />
              <br />
              <a
                href="/contact-us"
                className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block mr-4 px-4 py-2 rounded shado text-white"
              >
                Need More Info? Click To Send Us A Message
              </a>{" "}
              <br />
              <a
                href="https://about.alarrt.com"
                className="bg-success text-center flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
              >
                Know more about Alarrt here
              </a>{" "}
              <br />
              <a
                href="/signin"
                className="bg-current text-center flex font-bold hover:bg-current text-center hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
              >
                Go Home
              </a>{" "}
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    //         </div>
    //     </div>
    // </div>

    // <Popupchat />
    // <Appfooter />

    // </Fragment>
  );
}
// }
