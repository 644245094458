import React, { Component, useState, Fragment, useEffect } from "react";
import { useParams, Switch, Route, useHistory, Link } from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import Profiledetail from "../components/Profiledetail";
import Profilephoto from "../components/Profilephoto";
import PageCard from "../components/PageCard";
import Createpost from "../components/Createpost";
import Events from "../components/Events";
import Postview from "../components/Postview";
import Chip from "@material-ui/core/Chip";
import Load from "../components/Load";
import Load2 from "../components/Load2";
// import { useState, useEffect } from "react";
// import Load from "../components/Load";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import CreatePost2 from "../components/CreatePost2";
import CreatePostFab from "../components/CreatePostFab";
import PostComponent from "../components/PostComponent";
import SinglePostComponent from "../components/SinglePostComponent";
import Pagedetail from "../components/Pagedetail";

export default function PageSingle() {
  const { slug } = useParams(["slug"]);
  // const [fetchingProfile, setFetchingProfile] = useState(false);
  const [fetchingPage, setFetchingPage] = useState(false);
  const [pages, setPages] = useState([]);
  const [posts, setPosts] = useState([]);
  // const [userId, setUserId] = useState(
  //   window.localStorage.getItem("user_id")
  // );
  // const userId = window.localStorage.getItem("user_id");
  const userId = useState(window.localStorage.getItem("user_id"));
  // const [userName, setUserName] = useState("");
  const user_name = window.localStorage.getItem("user_name");
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);

  async function getDetailsLoad() {
    setFetchingPage(true);
    const access_token = await window.localStorage.getItem("access_token");
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/page/get/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        setPages(resp.data);
        setPosts(resp.data.posts);
        setFetchingPage(false);
        if (userId[0] == resp.data.page.admin.id) {
          return setIsLoggedInUser(true);
        } else {
          return setIsLoggedInUser(false);
        }
        // } else {
        //   setFetchingProfile(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingPage(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getDetails() {
    // setFetchingPage(true);
    const access_token = await window.localStorage.getItem("access_token");
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/page/get/${slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        setPages(resp.data);
        setPosts(resp.data.posts);
        //   setFetchingPage(false);
        // console.log(resp.data.page.admin.id);
        // alert(JSON.stringify(userId))
        if (userId[0] == resp.data.page.admin.id) {
          return setIsLoggedInUser(true);
        } else {
          return setIsLoggedInUser(false);
        }
        // } else {
        //   setFetchingProfile(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        // setFetchingPage(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function checkUserId() {
    // console.log(`no 1 ${profile.id}`);
    // console.log(`no 2 ${userId}`);
    if (userId[0] == pages.admin.id) {
      // console.log("is logged in user");
      return setIsLoggedInUser(true);
    } else {
      // console.log("is another user");
      return setIsLoggedInUser(false);
    }
  }

  useEffect(() => {
    getDetailsLoad();
    getDetails();
    // checkUserId();
  }, [console.log(`logged in id ${isLoggedInUser}`)]);
  {
    // console.log(JSON.stringify(pages));
  }

  if (fetchingPage == "true") {
    return (
      <>
        <Load />
      </>
    );
  }
  // if (pages.length == 0) {
  //   return (
  //     <>
  //       <Load />
  //     </>
  //   );
  // }
  else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  {/* {isLoggedInUser} */}
                  <PageCard
                    pages={pages}
                    isLoggedInUser={isLoggedInUser}
                    functionProp={() => getDetails()}
                  />
                  <Pagedetail pages={pages} />
                  {/* <Profiledetail /> */}
                  {/* <Profilephoto />
                                    <Events /> */}
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                  {isLoggedInUser ? (
                    <CreatePost2
                      functionProp={() => getDetails()}
                      type="page"
                      pageId={pages.page.id}
                      channelId=""
                    />
                  ) : (
                    ""
                  )}
                  {posts.length == 0 ? (
                    <>
                      <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                        <div className="card-body p-0">
                          {isLoggedInUser ? (
                            <>
                              <h5
                                className="middle-wrap fw-600 text-grey-900"
                                style={{ textAlign: "center", lineHeight: 2 }}
                              >
                                Hello @{user_name}! Seems You Haven't Created A
                                Post For This Page. You Can Use The Box Above To
                                Create One. If you have, hold on a few seconds
                                while this Loads
                              </h5>{" "}
                              <br />
                              <Load2 />
                            </>
                          ) : (
                            <>
                              <h5
                                className="middle-wrap fw-600 text-grey-900"
                                style={{ textAlign: "center", lineHeight: 2 }}
                              >
                                Hello! Seems No Post Has Been Created For This
                                Page. You Can Check Back Later
                              </h5>
                              <br />
                              <Load2 />
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    // <>
                    //   {posts.length == 1 ? (
                    //     <SinglePostComponent
                    //       post={posts}
                    //       type="page"
                    //       functionProp={() => getDetails()}
                    //     />
                    //   ) : (
                        <PostComponent
                          posts={posts}
                          type="page"
                          functionProp={() => getDetails()}
                        />
                    //   )}
                    // </>
                  )}

                  {/* {fetchingPage == 'true' } */}
                  {isLoggedInUser ? (
                    <>
                      <CreatePostFab
                        type="page"
                        pageId={pages.page.id}
                        channelId=""
                        functionProp={() => getDetails()}
                      />
                      <CreatePostFab
                        type="page"
                        pageId={pages.page.id}
                        channelId=""
                        functionProp={() => getDetails()}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
        {/* <CreatePostFab /> */}
      </Fragment>
    );
  }
}

// export default Grouppage;
