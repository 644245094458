import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
// import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
// import AlertLocationCurrent from './AlertLocationCurrent';

const AnyReactComponent = ({ text }) => (
    <div style={{
      color: 'white', 
      background: 'grey',
      padding: '15px 10px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      {text}
    </div>
  );

class AlertLocation2 extends Component {
  static defaultProps = {
    center: {
      lat: 6.45,
      lng: 3.43
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>

{/* <AlertLocationCurrent
        centerAroundCurrentLocation
        google={this.props.google}
      >
        <Marker onClick={this.onMarkerClick} name={'Current Location'} />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </AlertLocationCurrent> */}
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={6.450410}
            lng={3.435330}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default AlertLocation2;