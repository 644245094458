import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import Profiledetail from "../components/Profiledetail";
import Profilephoto from "../components/Profilephoto";
import Events from "../components/Events";
import ProfilecardTwo from "../components/ProfilecardTwo";
import Category from "../components/CategoryAlerts";

import Createpost from "../components/Createpost";
import AlertSingle from "../components/AlertSingle";
import Load from "../components/Load";
import { Route, useParams, useLocation } from "react-router-dom";
import CreateAlertFab from "../components/CreateAlertFab";

// import { useParams } from 'react-router-dom';

// class Authorpage extends Component {
export default function SingleAlert() {
  // const id = useLocation()
  const location = useLocation();
  // const [alertId, setAlertId] = useState(id.state.id);
  // const alertId = location.state.id;
//   const queryParams = new URLSearchParams(location.search);
//   console.log(`hiii ${queryParams}`);
//   console.log(location.pathname)
// //   const pathname = location.pathname
  
//   const alertId = queryParams.get("i");

  const { slug } = useParams(["slug"]);
  // alert(alertId)
  // const { id } = useParams(["id"]);
  // console.log(`id issss ${alertId}`)



  return (
    <Fragment>
      <Header />
      {/* <Leftnav /> */}
      {/* <Rightchat /> */}
      {/* {console.log(`category is ${JSON.stringify(category.first_name)}`)}
                {console.log(`category is 2 ${category.first_name}`)} */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12">
                {/* <ProfilecardTwo dataParentToChild = {data} user_name={this.state.user_name} /> */}
                {/* {firstName} */}
                <AlertSingle />
                {/* slug={slug} alertId={alertId}/> */}
                <CreateAlertFab />
              </div>
              {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    <Profiledetail category={profile} />
                                    <Profilephoto />
                                    <Events />
                                </div> */}
              {/* <div className="col-xl-8 col-xxl-9 col-lg-8">
                                    <Createpost />
                                    <UserPosts user_name={user_name} id="31" postvideo="" postimage="post.png" avater="user.png" user="David Goria" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Popupchat /> */}
      <Appfooter />
    </Fragment>
  );
}

// export default Authorpage;
