import { Component, useState, useEffect } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import { Select } from "antd";
import {
  Button,
  Container,
  Fab,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
  Paper,
  Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { Add as AddIcon } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import InputLabel from "@material-ui/core/InputLabel";
// import { Select } from "antd";
import CancelIcon from "@mui/icons-material/Cancel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Test3 from "../components/Test3";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import Chip from "@material-ui/core/Chip";
import Resizer from "react-image-file-resizer";
import { Upload } from "@aws-sdk/lib-storage";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";


const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: 160,
    right: 20,
  },
  container: {
    width: 500,
    overflow: "scroll",
    height: 600,
    backgroundColor: "white",
    position: "absolute",
    top: 30,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "95vw",
      height: "90vh",
    },
  },
  form: {
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(3),
  },
  image: {
    maxWidth: "100%",
    maxHeight: 320,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  modalStyle: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    display: "block",
  },
  input: {
    display: "none",
  },
  textInput: {
    color: "red",
    // fontSize: 4
  },
  select: {
    width: "80%",
    backgroundColor: "#fff",
  },
}));

function getSteps() {
  return [
    "Select campaign settings",
    "Create an ad group",
    "Create an ad group",
    "Create an ad",
    "last",
  ];
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreatePostFab = (props) => {
  const classes = useStyles();
  const [userId, setUserId] = useState("");
  const [postCategory, setPostCategory] = useState("");
  const [postCategoryName, setPostCategoryName] = useState("");
  const [postTags, setPostTags] = useState([]);
  const [postTagsName, setPostTagsName] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newTag, setNewTag] = useState("");
  const [headline, setHeadline] = useState("");
  const [url, setUrl] = useState("");
  const [body, setBody] = useState("");
  const [video, setVideo] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [images, setImages] = useState([]);
  const [postImage, setPostImage] = useState([]);
  const [postImageUrls, setPostImageUrls] = useState([]);
  const [postVideo, setPostVideo] = useState([]);
  // const [postImage, setPostImage] = useState("");
  const [open, setOpen] = useState(false);
  // const [firstName, setFirstName] = useState("");
  // const [userName, setUserName] = useState("");
  const verified = window.localStorage.getItem("verified");
  const firstName = window.localStorage.getItem("first_name");
  const userFriendsCount = window.localStorage.getItem("friends");
  const userName = window.localStorage.getItem("user_name");
  const userPic = window.localStorage.getItem("profile_pic");

  const [sendToFriends, setSendToFriends] = useState("No");
  const [openAlert, setOpenAlert] = useState(false);
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [creatingTag, setCreatingTag] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [creatingPost, setCreatingPost] = useState(false);
  const [processed, setProcessed] = useState(false);
  const [emptyFields, setEmptyFields] = useState(false);
  //state for alert categories mapping
  const [allCategories, setAllCategories] = useState([]);
  const [allTags, setAllTags] = useState([]);
  // const [userPic, setUserPic] = useState("");
  // const [userFriendsCount, setUserFriendsCount] = useState("");
  const history = useHistory();
  const [invalidFile, setInvalidFile] = useState(false);

  const [checked, setChecked] = React.useState(false);
  const { Option } = Select;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLimit, setIsLimit] = useState(false);
  const animatedComponents = makeAnimated();

  const [showLive, setShowLive] = useState(false);
  const [showUrl, setShowUrl] = useState(false);

  const options = [
    { value: "first-series", label: "First Series" },
    { value: "new-post", label: "New Post" },
  ];

  const childrenTag = [];
  {
    allTags.map((tag) =>
      childrenTag.push(<Option key={tag.name}> {tag.name} </Option>)
    );
  }

  const sendToFriendsSelected = (event) => {
    setChecked(event.target.checked);
    setSendToFriends("Yes");
    console.log("sending to friends");
  };

  const ChangePostCategory = (event, value) => {
    // value.id = 1;
    if (value) {
      setPostCategory(value.id);
      setPostCategoryName(value.name);
      console.log(postCategory);
      console.log(postCategoryName);
    } else {
      setPostCategory("");
      setPostCategoryName("");
      console.log("value empty");
      getTags();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
    setCreatedCat(false);
    setEmptyFields(false);
  };

  function closeError() {
    setError(false);
    setIsLimit(false);
    setUploadLimit(false);
    console.log("closed");
  }

  const toogleLiveVid = (event) => {
    if (showLive == false) {
      setShowLive(true);
      // setChecked2(true);
    } else {
      setShowLive(false);
      // setChecked2(false);
    }
  };
  const toogleUrlVid = (event) => {
    if (showUrl == false) {
      setShowUrl(true);
      // setChecked2(true);
    } else {
      setShowUrl(false);
      // setChecked2(false);
    }
  };

  const cancelCreation = () => {
    setActiveStep(0);
    setOpen(false);
    // history.go(`/`);
  };

  //stepper
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setOpen(false);
    setProcessed(false);
    props.functionProp();
    // history.go(`/`);
  };

  const goBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOpenAlert(true);
  };

  async function validateImage(e, value) {
    setPostImage([...e]);
    postImage.push(e);
    for (let i = 0; i < postImage[0].length; i++) {
      postImageUrls.push(URL.createObjectURL(postImage[0][i]));
    }
    setPostImage(postImageUrls);
  }
  function clearImages() {
    setPostImage([]);
    setPostImageUrls([]);
    setImages([]);
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      console.log(`now inside resize file  ${JSON.stringify(file)}`);
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "WEBP",
        90,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
          // console.log(comp)
          // setCompImages(uri)
          images.push(uri);
          // setCompImages([image4])
          // setImages(images => [...images,compImages] );
          // setImages(...compImages)
        },
        "file",
        1080,
        1080
      );
    });

  async function onChange3(event) {
    console.log(event.target.files);
    for (let i = 0; i < event.target.files.length; i++) {
      // console.log(file)
      try {
        console.log("resizing... 1"[i]);
        const image = resizeFile(event.target.files[i]);
        console.log(`resized 1 ${JSON.stringify(image)}`);
      } catch (err) {
        console.log(err);
      }
    }
  }

  function processImage(e) {
    console.log(`proceesss ${JSON.stringify(e.target.files)}`);
    if (postImage.length < 1 || postImage.length > 4) {
      // alert('you can only upload 1 - 4 images')
      return;
    }

    const newImagesUrl = [];
    {
      e.target.files.map(
        (postImage) =>
          // newImagesUrl.push(URL.createObjectURL(postImage))
          postImageUrls.push(URL.createObjectURL(postImage))
        // postTagsName.push(tag.name)
      );
    }
    {
      e.target.files.map(
        (postImage) =>
          // newImagesUrl.push(URL.createObjectURL(postImage))
          postImage.push(URL.createObjectURL(postImage))
        // postTagsName.push(tag.name)
      );
    }
    // postImage.forEach(postImage => newImagesUrl.push(URL.createObjectURL(postImage)));
    // setPostImageUrls(newImagesUrl)
    // setPostImage(newImagesUrl)
  }

  async function validate() {
    if (!body) {
      // console.log(postCategory)
      setEmptyFields(true);
      return;
    } else {
      // createPost();
      uploadVideo();
    }
  }

  async function validateChannel() {
    // console.log(`tags ${postTagsName}`);
    // console.log(`headline ${headline}`);
    // console.log(`body ${body}`);
    // console.log(`cat ${postCategory}`);
    // console.log(`tags ${postTagsName}`);
    // alert(postCategory)
    //  alert(postCategoryName)
    if (!body) {
      // console.log(postCategory)
      setEmptyFields(true);
      return;
    } else {
      createChannelPost();
    }
  }

  // function getUser() {
  //   const access_token = window.localStorage.getItem("access_token");

  //   axios
  //     .get("https://alert-lumen-api.herokuapp.com/api/users/profile", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + access_token,
  //       },
  //     })
  //     .then((resp) => {
  //       setUserFriendsCount(resp.data.friends);
  //       setFirstName(resp.data.user.first_name);
  //       setUserName(resp.data.user.user_name);
  //       setUserPic(resp.data.user.profile_pic);
  //     })
  //     .catch((error) => console.error(`Error Seen : ${error}`));
  // }
  async function uploadVideo() {
    if (video) {
      setCreatingMessage("Video Detected, Uploading First");
      console.log("video is nooot empty");

      setCreatingPost(true);

      // console.log(e.target.file[0]);
      // setVideo(e.target.files[0]);
      console.log("using youtube mutipart");
      console.log(video);

      const creds = {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      };

      const client = new S3Client({ region: "us-east-1", credentials: creds });
      const params = { Bucket: "alert-app-v2", Key: video.name, Body: video };
      const command = new CompleteMultipartUploadCommand(params);

      const user_id = window.localStorage.getItem("user_id");
      var filePath = "posts/videos/" + user_id + "/" + video.name;
      // https://alert-app-v2.s3.amazonaws.com/users/cover_pics/245/Instagram%20Post%20Timeline%20_1645379639.WEBP
      const finalUrl = `https://alert-app-v2.s3.amazonaws.com/${filePath}`;

      setCreatingMessage(
        "Uploading your video. This may take a quick minute or two"
      );
      const target = { Bucket: "alert-app-v2", Key: filePath, Body: video };
      try {
        const parallelUploads3 = new Upload({
          client: new S3Client({ region: "us-east-1", credentials: creds }),
          leavePartsOnError: false, // optional manually handle dropped parts
          params: target,
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          console.log(progress);
          var uploaded = parseInt((progress.loaded * 100) / progress.total);
          console.log("status is" + uploaded);
          console.log("upload tyep is " + typeof uploaded);
          setVideoPercent(uploaded);
          setCreatingMessage(`Almost done uploading - ${uploaded}% completed`);
          setFileUrl(finalUrl);
          window.localStorage.setItem("postVideo", finalUrl);
          // setVideoUrl(finalUrl);
          // videoUrl = fileUrl
          if (uploaded == 100) {
            // setVideoUrl(finalUrl);
            console.log("set video url " + videoUrl);
            createPost();
          }
        });

        const done = parallelUploads3.done();
        console.log("done 3 " + JSON.stringify(done));
        // if(videoPercent == '100'){
        //   setVideoUrl(fileUrl)
        //   createPost()
        // }
      } catch (e) {
        console.log(e);
      }

      // setCreatingPost(false)
    } else {
      // setCreatingMessage('Video Detected, Uploading First')
      createPost();
      console.log("video very empty");
    }
  }

  async function createPost() {
    setCreatingPost(true);
    setCreatingMessage("Creating Your Post, almost done");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    const postVideoUrl = await window.localStorage.getItem("postVideo");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("post_category_id", postCategory);
    formData.append("channel_id", props.channelId);
    formData.append("page_id", props.pageId);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("url", url);
    // formData.append("image[]", images);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    //THIS IS WORKING TOO
    // images.map((image) => {
    // formData.append('image[]', image)
    // console.log(`hjsimage ${JSON.stringify(image)}`)
    // });
    //THIS IS WORKING TOO
    // for(var a = 0; a<images.length; a++)
    // {
    //   // const fd = new FormData();
    //   //console.log(images[a])
    //   formData.append('image[]', images[a]['file']);
    //   console.log(`oluuu`)
    // }
    // formData.append("image[]", images);
    if (postVideoUrl) {
      formData.append("video", postVideoUrl);
    }
    if (videoUrl) {
      formData.append("video", videoUrl);
    }
    // formData.append("video", postVideoUrl);
    formData.append("tags", postTagsName);
    // formData.append("sendToFriends", sendToFriends);
    formData.append("type", props.type);
    formData.append("status", "active");

    let result = await axiosFetch
      .post("/posts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // uploadImage();
        console.log(
          body,
          headline,
          url,
          images,
          postCategory,
          postTagsName,
          //   sendToFriends,
          postImage
        );
        console.log(resp.data);

        //
        if (resp.data.status == "success") {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setCreatingPost(false);
          setOpenAlert(true);
          setProcessed(true);
          setHeadline("");
          setBody("");
          setPostTagsName([]);
          setPostCategory("");
          setPostCategoryName("");
          setUrl("");
          setVideoUrl("");
          setFileUrl("");
          setVideo("");
          localStorage.removeItem("postVideo");
          setImages([]);
          setPostImage([]);
          setPostImageUrls([]);
          setVideo("");
          setSelectedTags([]);
          props.functionProp();
        } else {
          setCreatingPost(false);
          setError(true);
          setProcessed(false);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function createChannelPost() {
    setCreatingPost(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("post_category_id", postCategory);
    formData.append("channel_id", props.channelId);
    formData.append("page_id", props.pageId);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("url", url);
    // formData.append("image[]", images);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    //THIS IS WORKING TOO
    // images.map((image) => {
    // formData.append('image[]', image)
    // console.log(`hjsimage ${JSON.stringify(image)}`)
    // });
    //THIS IS WORKING TOO
    // for(var a = 0; a<images.length; a++)
    // {
    //   // const fd = new FormData();
    //   //console.log(images[a])
    //   formData.append('image[]', images[a]['file']);
    //   console.log(`oluuu`)
    // }
    // formData.append("image[]", images);
    formData.append("tags", postTagsName);
    // formData.append("sendToFriends", sendToFriends);
    formData.append("type", "channel");
    formData.append("status", "active");

    let result = await axiosFetch
      .post("/channels/create/post", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data);

        setCreatingPost(false);
        //
        if (resp.data.status == "success") {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setCreatingPost(false);
          setOpenAlert(true);
          setProcessed(true);
        } else {
          setError(true);
          setCreatingPost(false);
          setErrorMessage(resp.data.message);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        setProcessed(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  function showImages(e) {
    console.log(e.target.files);
    setPostImage(e.target.files);
    console.log(`alert Image: ${postImage}`);
  }

  // const imageAdded = (e) => {
  //   // if (e.target.files && e.target.files.length > 0) {
  //     setPostImage(e.target.files[0]);
  //     console.log(`hello ${postImage}`);
  //   // }
  // }

  function getPostCategories() {
    const access_token = window.localStorage.getItem("access_token");
    const user_name = window.localStorage.getItem("user_name");
    axiosFetch
      .get(`/post_category/${user_name}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts Categories: ${allAlertCategories}`);
        setAllCategories(result);
        // getPostCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function createCategory() {
    setCreatingCat(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/post_category",
        {
          method: "POST",
          name: newCategory,
          status: "active",
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        setNewCategory("");
        getPostCategories();
        setCreatingCat(false);
        setCreatedCat(true);
        // alert("Category Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function getTags() {
    const access_token = window.localStorage.getItem("access_token");
    axiosFetch
      .get("/tags", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const result = resp.data;
        const allAlertCategories = JSON.stringify(result);
        // console.log(`Alerts tags: ${JSON.stringify(result)}`);
        setAllTags(result);
        // getPostCategories();
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  // function ChangePostTag(value) {
  //   console.log(`selected ${value}`);
  //   setPostTagsName(value);

  //   console.log(`state ${postTagsName}`);
  // }
  function ChangePostTag(value) {
    console.log(`selected ${value}`);
    value.map((tag) => postTagsName.push(tag.value));
    console.log(`name ${postTagsName}`);

    setSelectedTags([...new Set(postTagsName)]);
    console.log(`selected tag ${JSON.stringify(selectedTags)}`);
  }
  function handleTagChange(value) {
    console.log(`selected ${value}`);
  }

  async function createTags() {
    setCreatingTag(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .post(
        "/tags",
        {
          method: "POST",
          name: newTag,
          // status: 'active'
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log("new category created");
        // postTagsName.push(newTag);
        console.log(newTag);
        setNewTag("");
        getTags();
        setCreatingTag(false);
        // alert("Tag Successfully Created");
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    // getUser();
    getTags();
    getPostCategories();
  }, []);

  // const renderPhotos = (source) => {
  //   return source.map((photo) => {
  //     return (
  //       <img
  //         className="p-2"
  //         src={photo}
  //         alt=""
  //         ket={photo}
  //         style={{ width: "20%", height: "180px" }}
  //       />
  //     );
  //   });
  // };
  // }, [allCategories]);
  //  console.log(`Alerts Categories State: ${postCategory}`);
  // const { Option } = Select;
  return (
    <>
      <Tooltip
        title="Create A Post"
        aria-label="add"
        onClick={() => setOpen(true)}
      >
        <Fab color="primary" className={classes.fab} style={{zIndex: 30}}>
          <EmojiEmotionsOutlinedIcon style={{ fontSize: 27 }} />
        </Fab>
      </Tooltip>
      <Modal open={open} className={classes.modalStyle}>
        <form
          className={classes.form}
          encType="multipart/form-data"
          autoComplete="off"
        >
          <Container className={classes.container}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              // style={{ width: "100vw" }}
            >
              {/* {steps.map((label, index) => ( */}
              <Step key="first">
                {/* {verified === "null" ? (
                   <Typography
                   gutterBottom
                   variant="body1"
                   className={classes.postHead}
                 >
                    Verify Your Email First To Start Creating Posts And Much More!
                 </Typography>
                  
                ) : (
                  <> */}
                {props.type == "channel" || props.type == "page" ? (
                  <StepLabel> Create A Post For Your {props.type} </StepLabel>
                ) : (
                  <StepLabel> Create A Detailed Post</StepLabel>
                )}
                {/* </>
                )} */}
                <StepContent>
                  {props.type == "channel" || props.type == "page" ? (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Add a post to your {props.type}. Your subscribers are
                      alerted immediately
                    </Typography>
                  ) : (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Hello {firstName}! What would you like to share?
                    </Typography>
                  )}

                  {/* <form className={classes.form} autoComplete="off"> */}
                  <div className={classes.item}>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Your post heading goes here - Heading is optional, body is
                      required.
                    </Typography>

                    <TextField
                      id="standard-basic"
                      label="heading"
                      required
                      size="small"
                      style={{ width: "100%", fontSize: 4, color: "red" }}
                      onChange={(e) => setHeadline(e.target.value)}
                      value={headline}
                      LabelProps={{
                        className: classes.textInput,
                      }}
                    />
                  </div>

                  <div className={classes.item}>
                    <div
                      className="card-body p-0 mt-3 position-relative"
                      style={{ width: "100%" }}
                    >
                      <figure className="avatar position-absolute ms-2 mt-1 top-5">
                        <img
                          src={userPic}
                          alt="icon"
                          className="shadow-sm rounded-circle w30"
                        />
                      </figure>
                      <textarea
                        onChange={(e) => setBody(e.target.value)}
                        style={{ paddingLeft: 50, paddingTop: 10 }}
                        value={body}
                        name="message"
                        className="h100 bor-0 w-100 rounded-xxl font-xssss text-black-500 fw-500 border-light-md theme-dark-bg"
                        cols="30"
                        rows="10"
                        placeholder={`What would you like to share ${firstName}`}
                      ></textarea>
                    </div>
                  </div>

                  {/* </form> */}

                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Next" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step key="second">
                <StepLabel> Select From Your Series - Optional</StepLabel>
                <StepContent>
                  {props.type == "channel" || props.type == "page" ? (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Tip: For post organization, create a series / category for
                      your {props.type}. e.g your {props.type}'s name or
                      'announcement'
                    </Typography>
                  ) : (
                    ""
                  )}
                  <div className={classes.item}>
                    <div className={classes.select}>
                      {/* <div style={{ width: "50%" }}> */}
                      <Autocomplete
                        id="combo-box-demo"
                        options={allCategories}
                        style={{ width: "100%" }}
                        onChange={ChangePostCategory}
                        // onChange={(e) => setPostCategoryName(e.target.value.name) }
                        inputValue={postCategoryName}
                        getOptionLabel={(option) => option.name}
                        fullWidth
                        PaperComponent={({ children }) => (
                          <Paper style={{ background: "#fff", width: "100%" }}>
                            {children}
                          </Paper>
                        )}
                        style={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select A Series"
                            variant="standard"
                            style={{ backgroundColor: "pink !important" }}
                          />
                        )}
                        required
                      />
                      {/* <CachedOutlinedIcon style={{ width: "20%" }} /> */}
                      {/* <div> */}

                      {/* <FormHelperText>Select A Series</FormHelperText> */}
                    </div>

                    {/* <div className={classes.actionsContainer}> */}
                    <TextField
                      id="standard-basic"
                      label="Create New"
                      size="small"
                      // style={{width:'40%'}}
                      style={{ width: "100%" }}
                      onChange={(e) => setNewCategory(e.target.value)}
                      value={newCategory}
                    />
                    {!newCategory ? (
                      <>
                        <br />
                        <br />
                        <Button
                          disabled
                          variant="contained"
                          color="primary"
                          // component="span"
                          style={{ marginLeft: 7 }}
                        >
                          Type First
                        </Button>
                      </>
                    ) : (
                      <>
                        {creatingCat ? (
                          <Button
                            disabled
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginTop: 7 }}
                          >
                            Creating New Series
                          </Button>
                        ) : (
                          <Button
                            onClick={createCategory}
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{ marginTop: 7 }}
                          >
                            Create Series
                          </Button>
                        )}
                      </>
                    )}
                    {/* </div> */}
                  </div>

                  {createdCat ? (
                    <>
                      <Snackbar
                        open={createdCat}
                        autoHideDuration={4000}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Alert onClose={handleClose} severity="success">
                          Series successfully created. Select it above
                        </Alert>
                      </Snackbar>
                    </>
                  ) : (
                    ""
                  )}
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Close
                      </Button>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Next" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key="third">
                <StepLabel> Add Tags To Your Post - Optional</StepLabel>
                <StepContent>
                  {props.type == "channel" || props.type == "page" ? (
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Tip: For more reach, use your {props.type}'s name or
                      'announcement' as a tag
                    </Typography>
                  ) : (
                    ""
                  )}
                  <div className={classes.item}>
                    <FormControl required className="d-flex">
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                        Tags You've Selected
                      </Typography>
                      {selectedTags.map((selectedTags) => (
                        <Chip
                          style={{ marginRight: 10, marginBottom: 5 }}
                          className="infotag pointer"
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={`${selectedTags}`}
                        />
                      ))}
                      <br />
                      <CreatableSelect
                        isClearable
                        isMulti
                        onChange={ChangePostTag}
                        components={animatedComponents}
                        options={options}
                        placeholder="Select or Create New Tags"
                      ></CreatableSelect>
                      {/* <Select
                        mode="tags"
                        style={{ width: "100%", height: 70 }}
                        placeholder="click enter to seperate"
                        onChange={ChangePostTag}
                        PaperComponent={({ childrenTag }) => (
                          <Paper style={{ background: "#000" }}>
                            {childrenTag}
                          </Paper>
                        )}
                      /> */}
                      <FormHelperText>
                        Type and click create to create new tag
                      </FormHelperText>
                      {/* {childrenTag} */}

                      {/* PaperComponent={({ children }) => ( */}
                      {/* <Paper style={{ background: "#eee", height:70 }}>
                            {childrenTag}
                          </Paper> */}
                      {/* )} */}
                      {/* </Select> */}

                      <div className={classes.item}>
                        <br />
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.postHead}
                        >
                          Add External Source. Link Is Optional - Start With
                          https://....
                        </Typography>
                        <TextField
                          id="standard-basic"
                          label={"https://.."}
                          required
                          size="small"
                          style={{ width: "90%", fontSize: 4, color: "red" }}
                          onChange={(e) => setUrl(e.target.value)}
                          value={url}
                          LabelProps={{
                            className: classes.textInput,
                          }}
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        // disabled={activeStep === 0}
                        onClick={cancelCreation}
                        className={classes.button}
                      >
                        Close
                      </Button>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? "Next" : "Next"}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key="fourth">
                <StepLabel>Upload Your Images - Optional</StepLabel>
                <StepContent>
                  {/* <form className={classes.form} autoComplete="off"> */}

                  <div className={classes.item}>
                    <div>
                      {/* <Typography gutterBottom variant="caption" className={classes.postHead}> */}
                      <h4> Almost Done {firstName} </h4>
                      {/* </Typography> */}
                    </div>

                    {
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.postHead}
                      >
                       Upload your images below
                      </Typography>
                    }
                    {/* { postImageUrls.map(imageSrc => <img src={imageSrc}   className={classes.image}  />) } */}
                    {!postImageUrls.length == 0 ? (
                      <>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.postHead}
                        >
                          Slide to view all
                        </Typography>
                        <Carousel>
              {postImageUrls.map((slideImage, index) => (
                
                <img
                  src={slideImage}
                  key={index}
                  style={{ zIndex: -10 }}
                  alt=""
                />
              ))}
            </Carousel>

                        {/* <Zoom scale={1.4} arrows={false} indicators={true} duration='2000'>
                          {postImageUrls.map((slideImage, index) => (
                            // <div className="each-slide" key={index}>
                              <img src={slideImage} key={index} style={{zIndex: -10}} alt="" />
                            // </div>
                          ))}
                        </Zoom> */}
                        <br/>

                        {/* <div data-uk-slider="finite: true center: true autoplay: true">
                          <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-4@">
                            {postImageUrls.map((imageSrc) => (
                              <li style={{ marginRight: 10 }}>
                                <img src={imageSrc} alt="" />
                              </li>
                            ))}
                          </ul>
                        </div> */}

                        <Button
                          // onClick={() => setPostImageUrls([])}
                          onClick={clearImages}
                          className={classes.button}
                        >
                          Clear Images
                        </Button>
                        {/* {/* <br /> */}
                      </>
                    ) : (
                      <div className="col-lg-12 mb-3">
                        <div className="card mt-3 border-0">
                          <div className="card-body d-flex justify-content-between align-items-end p-0">
                            <div className="form-group mb-0 w-100">
                              {/* {/* <input type="file" name="file" id="file" multiple className="input-file" onChange={showImages} /> */}

                              <input
                                type="file"
                                name="file"
                                accept="image/*"
                                multiple
                                id="file"
                                className="input-file"
                                // onChange={validateImage}
                                onChange={(e) => {
                                  // if (e.target.files.length > 4) {
                                  //   setIsLimit(true);
                                  // } else {
                                    // setImages([...e.target.files]);
                                    console.log([...e.target.files]);
                                    validateImage(e.target.files);
                                    onChange3(e);
                                  // }
                                }}
                              />

                              <label
                                htmlFor="file"
                                className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                              >
                                <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                <span className="js-fileName">
                                  Drag and drop or click to add your media file
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isLimit ? (
                      <div>
                        <Alert
                          severity="warning"
                          onClose={handleClose}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum upload is 4 images, kindly reduce.
                        </Alert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {invalidFile ? <h4> {invalidFile} </h4> : ""}
                    <br />

                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          // disabled={activeStep === 0}
                          onClick={cancelCreation}
                          className={classes.button}
                        >
                          Close
                        </Button>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          {activeStep === steps.length - 1 ? "Next" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </StepContent>
              </Step>

              <Step key="fifth">
                <StepLabel>Upload Your Video - Optional</StepLabel>
                <StepContent>
                  {/* <form className={classes.form} autoComplete="off"> */}

                  <div className={classes.item}>
                    <div>
                      {/* <Typography gutterBottom variant="caption" className={classes.postHead}> */}
                      <h4> Last Step {firstName} </h4>
                      {/* </Typography> */}
                    </div>
                    <Typography
                      gutterBottom
                      variant="body1"
                      className={classes.postHead}
                    >
                      Add a video to your post
                    </Typography>

                    <div>
                      <>
                        {videoUrl ? (
                          <>
                            <br />
                            <Typography
                              gutterBottom
                              variant="body1"
                              className={classes.postHead}
                            >
                              Your post video
                            </Typography>

                            <br />
                            <ReactPlayer
                              controls={true}
                              // width='70%'
                              height="400px"
                              url={videoUrl}
                              config={{
                                file: {
                                  attributes: { controlsList: "nodownload" },
                                },
                              }}
                            />
                            <br />
                            <br />
                            {creatingPost ? (
                              <Button disabled>{creatingMessage}</Button>
                            ) : (
                              <Button
                                onClick={
                                  () => {
                                    setVideoUrl("");
                                    setShowLive(false);
                                    setShowUrl(false);
                                  }
                                  //   this.setState({ profile_pic: "" })
                                }
                              >
                                Clear
                              </Button>
                            )}

                            <br />
                          </>
                        ) : (
                          <>
                            {video ? (
                              <>
                                <br />
                                <Typography
                                  gutterBottom
                                  variant="body1"
                                  className={classes.postHead}
                                >
                                  Your post video
                                </Typography>

                                <br />
                                <video width="400" controls>
                                  <source src={URL.createObjectURL(video)} />
                                </video>
                                <br />
                                <br />
                                {creatingPost ? (
                                  <Button disabled>{creatingMessage}</Button>
                                ) : (
                                  <Button
                                    onClick={
                                      () => {
                                        setVideo("");
                                        setShowLive(false);
                                        setShowUrl(false);
                                      }
                                      //   this.setState({ profile_pic: "" })
                                    }
                                  >
                                    Remove
                                  </Button>
                                )}

                                <br />
                              </>
                            ) : (
                              <>
                                {showUrl ? (
                                  <>
                                    <br />
                                    <Typography
                                      gutterBottom
                                      variant="body1"
                                      // className={classes.postHead}
                                    >
                                      You can upload a video from a url. If you
                                      wish to record a live video, use the
                                      record live video button instead
                                    </Typography>
                                    <br />
                                    <TextField
                                      //autoFocus
                                      margin="dense"
                                      id="name"
                                      label={`Your video's url`}
                                      value={videoUrl}
                                      // { window.localStorage.getItem("postVideo")}
                                      onChange={(e) => {
                                        // window.localStorage.setItem(
                                        //   "postVideo",
                                        //   e.target.value
                                        // )
                                        setVideoUrl(e.target.value);
                                      }}
                                      type="text"
                                      fullWidth
                                      variant="standard"
                                      style={{ width: "100%" }}
                                    />
                                    <br />
                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Cancel
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                    <br />
                                  </>
                                ) : (
                                  <>
                                    {showLive ? (
                                      <>
                                        <br />
                                        <Typography
                                          gutterBottom
                                          variant="body1"
                                          // className={classes.postHead}
                                        >
                                          You can take a live recording using
                                          either of your front or back camera.
                                          If you have a video recorded already,
                                          use the upload video button instead
                                        </Typography>
                                        <br />
                                        <VideoRecorder
                                          onRecordingComplete={(videoBlob) => {
                                            // Do something with the video...
                                            console.log("videoBlob", videoBlob);
                                            setVideo(videoBlob);
                                          }}
                                        />
                                        <br />
                                        <br />
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-lg-12 mb-1">
                                          <div className="card mt-1 border-0">
                                            <div className="card-body d-flex justify-content-between align-items-end p-0">
                                              <div className="form-group mb-0 w-100">
                                                <input
                                                  type="file"
                                                  name="file3"
                                                  id="file3"
                                                  accept="video/*"
                                                  className="input-file"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.files[0].size >
                                                      300000 * 1028
                                                    ) {
                                                      setUploadLimit(true);
                                                    } else {
                                                      setUploadLimit(false);
                                                      setVideo(
                                                        e.target.files[0]
                                                      );
                                                    }
                                                  }}
                                                  // onChange={(e) => setVideo(e.target.files[0])}
                                                />
                                                <label
                                                  htmlFor="file3"
                                                  className="rounded-3 text-center bg-white btn-tertiary js-labelFile p-4 w-100 border-dashed"
                                                >
                                                  <i className="ti-cloud-down large-icon me-3 d-block"></i>
                                                  <span className="js-fileName">
                                                    Click to add your post video
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <br /> */}
                                        {/* <br /> */}
                                      </>
                                    )}

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleLiveVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Record Live Video
                                        </Button>
                                        <br />
                                      </label>
                                    </span>

                                    <span
                                      style={{
                                        marginTop: 20,
                                        display: "flex",
                                        alignSelf: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <label htmlFor="contained-button-file-3">
                                        <Button
                                          variant="contained"
                                          component="span"
                                          color="primary"
                                          onClick={() => {
                                            toogleUrlVid();
                                            // setChecked2(true);
                                          }}
                                          // onClick={toogleLiveVid}
                                        >
                                          Upload Video Url
                                        </Button>
                                        <br />
                                      </label>
                                    </span>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>

                    <br />
                    {uploadLimit ? (
                      <div>
                        <MuiAlert
                          severity="warning"
                          onClose={closeError}
                          action={
                            <>
                              <CancelIcon onClick={closeError} />
                            </>
                          }
                        >
                          Maximum size allowed is 300 mb
                        </MuiAlert>
                        {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                        <br />
                        <a
                          href="https://www.freeconvert.com/video-compressor"
                          target="_blank"
                          className="bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                        >
                          {/* <BoltIcon style={{ fontSize: 35 }} /> */}
                          <span className="d-inline-block ls-3 fw-600 text-white font-xxs mb-0">
                            Quickly compress it
                          </span>{" "}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <br/><br/>
                  {verified === "null" ? 
                  <>
                      <a
                        onClick={handleBack}
                        class="bg-success flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                      >
                        Go Back
                      </a>
                      </>
                      :
                      ''
                  } */}

                  <div className={classes.actionsContainer}>
                    <div>
                      {/* {verified === "null" ? (
                      <a
                        href="/email_verification"
                        class="bg-success flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                      >
                        Verify Email First
                      </a>
                    ) : (
                      <> */}
                      {props.type == "channel" ? (
                        // 'cnannel'
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>

                          {/* {activeStep === steps.length - 1 ?  */}
                          {creatingPost ? (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                // onClick={handleNext}
                                disabled
                                className={classes.button}
                              >
                                Creating Your Channel Post
                                {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                              </Button>
                              <h4>{creatingMessage}</h4>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={validate}
                              // onClick={uploadImage}
                              className={classes.button}
                            >
                              {/* {creatingPost ? Alert All 2 : Creating Your Alert } */}
                              {activeStep === steps.length - 1
                                ? "Create Channel Post"
                                : "Create Channel Post"}
                            </Button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </>
                    )} */}
                      {/* 
                    {verified === "null" ? (
                      <a
                        href="/email_verification"
                        class="bg-success flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                      >
                        Verify Email First
                      </a>
                    ) : (
                      <> */}
                      {props.type == "page" ? (
                        // 'cnannel'
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>

                          {/* {activeStep === steps.length - 1 ?  */}
                          {creatingPost ? (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                // onClick={handleNext}
                                disabled
                                className={classes.button}
                              >
                                Creating Your Page Post
                                {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                              </Button>
                              <h4>{creatingMessage}</h4>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={validate}
                              // onClick={uploadImage}
                              className={classes.button}
                            >
                              {/* {creatingPost ? Alert All 2 : Creating Your Alert } */}
                              {activeStep === steps.length - 1
                                ? "Create Page Post"
                                : "Create Page Post"}
                            </Button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </>
              verified ends here      )} */}

                      {/* {verified === "null" ? (
                      <a
                        href="/email_verification"
                        class="bg-success flex font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                      >
                        Verify Email First
                      </a>
                    ) : (
                      <> */}
                      {props.type == "post" ? (
                        // 'normal'
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.button}
                          >
                            Back
                          </Button>

                          {/* {activeStep === steps.length - 1 ?  */}
                          {creatingPost ? (
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                // onClick={handleNext}
                                disabled
                                className={classes.button}
                              >
                                Creating Your Post
                                {/* {activeStep === steps.length - 1 ? 'Alert All' : 'Next'} */}
                              </Button>
                              <h4>{creatingMessage}</h4>
                            </>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={validate}
                              // onClick={uploadImage}
                              className={classes.button}
                            >
                              {/* {creatingPost ? Alert All 2 : Creating Your Alert } */}
                              {activeStep === steps.length - 1
                                ? "Create Post"
                                : "Create Post"}
                            </Button>
                          )}
                          {/* onClick={() => setOpenAlert(true)} */}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* </>
             verified ends here       )} */}
                    </div>
                  </div>
                  {emptyFields ? (
                    <>
                      <br />
                      <br />
                      <Alert onClose={handleClose} severity="warning">
                        To create a post, you need to ensure the body is not
                        empty
                      </Alert>
                    </>
                  ) : (
                    ""
                  )}
                </StepContent>
              </Step>
              {/* ))} */}
            </Stepper>

            {error ? (
              <div>
                <Alert
                  severity="error"
                  onClose={handleClose}
                  action={
                    <>
                      <a
                        onClick={handleBack}
                        className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                      >
                        Go Back
                      </a>
                      <CancelIcon onClick={closeError} />
                    </>
                  }
                >
                  There was an issue creating your post. Kindly try again [{" "}
                  {errorMessage} ]
                </Alert>
                {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                <br />
              </div>
            ) : (
              ""
            )}
            {/* {activeStep === steps.length && ( */}
            {processed ? (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>
                  {" "}
                  Awesome! Your post has been created {firstName}. - Invite your
                  friends and family with your referral link so they can benefit
                  from Alarrt! You also get free sms points when anyone signs up
                  with your link. Go to your profile to get your referral link
                  now.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  // onClick={() => setOpen(false)}
                  onClick={handleReset}
                >
                  Go Back Home
                </Button>
                {/* <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
              </Paper>
            ) : (
              ""
            )}
          </Container>
        </form>
      </Modal>

      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleClose} severity="success">
          Post Created Successfully!
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreatePostFab;
