import React, { Component } from "react";
import { Link } from "react-router-dom";
import DarkMode from "../components/Darkbutton";
import Darkbutton from "../components/Darkbutton";
import {
  Fab, Tooltip
} from "@material-ui/core";


class Appfooter extends Component {
  state = {
    user_name: window.localStorage.getItem("user_name"),
    profile_pic: window.localStorage.getItem("profile_pic"),
  }; 
  render() {
    return (
      <>
       {/* fab: {
    // position: "absolute",
    position: "fixed",
    bottom: 90,
    right: 20,
  }, */}
  
  <a href='https://wa.me/message/3VHYR6VAI3MSD1'
  // href='https://api.whatsapp.com/send/?phone=09074701151&text=Hello+Alarrt+Support%2C+I+need...+%28type+your+reason+for+getting+in+touch+here%29&type=phone_number&app_absent=0'
  >
  <Tooltip
        title="Call Alarrt Support"
        aria-label="add"
        // onClick={() => setOpen(true)}  gghh
      >
        <Fab color="primary"
        style={{backgroundColor: '#311bb4',color:'#311bb4', 'position': 'fixed', 'bottom': 160, right: 20, zIndex: 30}}>
          {/* <img src="/assets/images/alert-fab3.webp" /> */}
          <img src="/assets/images/alert-fab3.webp" />
          {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
        </Fab>
      </Tooltip>
      </a>

  <a href='/channel/alarrt-user-support/series' >
   <Tooltip
        title="Watch Alarrt Guides"
        aria-label="add"
      >
      {/* <https://alarrt.com/channel/alarrt-user-support/series/how-to-create-and-send-friends-alert-notifications?channel=alarrt-user-support&w=5&a=115*/}
        <Fab  style={{backgroundColor: '#fff',color:'#fff', 'position': 'fixed', 'bottom': 90, right: 20, zIndex: 30}}>
          <i className="feather-tv" style={{ fontSize: 30, color: '#2516c7', zIndex:20 }}></i>
        </Fab>
        {/* <p className="fw-600 font-xsss text-white text-primary mt-1">Create Alert</p> */}
        {/* </div> style={{ backgroundColor: "#2516c7" }} */}
       
      </Tooltip>
      </a>
        <br /> <br />
        <div className="app-footer border-0 shadow-lg bg-blue-gradiant mt-10">
          <a href="/" className="nav-content-bttn text-center nav-center">
            <i className="feather-zap"></i>
            <p className="fw-600 font-xsssss text-white text-center mt-1">Alarrts</p>
          </a>
          <a href="/timeline" className="nav-content-bttn text-center">
            <i className="feather-camera"></i>
            <p className="fw-600 font-xsssss text-white text-center mt-1">Timeline</p>
          </a>
          <a href="/videos" className="nav-content-bttn text-center">
            <i className="feather-tv"></i>
            <p className="fw-600 font-xsssss text-white text-center mt-1">Learn</p>
          </a>

          {/* <DarkMode /> */}

          {/* <a href="/chats" className="nav-content-bttn text-center" data-tab="chats"><i className="feather-message-circle"></i></a>            */}
          <a href="/settings" className="nav-content-bttn text-center">
            <i className="feather-settings font-xl text-white"></i>
            <p className="fw-600 font-xsssss text-white text-center mt-1">Settings</p>
          </a> 

          {/* <Darkbutton mobile="mobile" /> */}
          <a
            href={`/profile/${this.state.user_name}`}
            className="nav-content-bttn text-center"
          >
            <img
              src={this.state.profile_pic}
              alt="user"
              className="mt-1 shadow-sm rounded-circle w45"
              style={{
                // marginRight: 30,
                maxWidth: 35,
                maxHeight: 45,
                // border: "2px solid #045de9",
              }}
            />
            <p className="fw-600 font-xsssss text-white text-center mt-1">Profile</p>
            {/* <i className="feather-user"></i> */}
          </a>

          {/* <a href="/defaultsettings" className="nav-content-bttn text-center"><img src="assets/images/female-profile.png" alt="user" className="w30 shadow-xss" /></Link> */}
        </div>
      </>
    );
  }
}

export default Appfooter;
