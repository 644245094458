import React, { Component, useEffect } from "react";
import "./test.css";
import { Link } from "react-router-dom";
import { PermMedia, Label, Room, EmojiEmotions } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import ShowMoreText from "react-show-more-text";

const useStyles = makeStyles((theme) => ({
  card: {
    // raised: true
  },
}));

export default function Comment() {
  const classes = useStyles();

  const test = () => {
   console.log('hollar')
  }

  useEffect(() => {
     test();
  }, []);


  return (
    <>
      {/* <Card className={classes.card}> */}
      <div className="shar">
        <div className="shareWrapper">
          <div className="shareTop">
          <img
            className="shareProfileImg"
            src="/assets/images/user.png"
            alt=""
          />
            {/* <figure className="avatar me-3">
              <img
                src={`/assets/images/user.png`}
                alt="avater"
                className="shadow-sm rounded-circle w45"
              />
            </figure> */}
            {/* <img className="shareProfileImg" src="/assets/person/1.jpeg" alt="" /> */}
            {/* <input
            placeholder="Add a comment..."
            className="shareInput"
          /> */}
            <TextField
              id="standard-multiline-flexible"
              label="Comment..."
              multiline
              className="shareInput"
              // maxRows={4}
              // value={value}
              // onChange={handleChange}
            />
            <SendRoundedIcon />
            {/* <button className="shareButton">Comment</button> */}
          </div>
          {/* <div className="shareHr"> </div> */}
          {/* <hr className="shareHr"/> */}
          <div className="shareBottom">
            <div className="shareOptions">
              {/* <div className="shareOption">
                    <PermMedia htmlColor="tomato" className="shareIcon"/>
                    <span className="shareOptionText">Photo or Video</span>
                </div>
                <div className="shareOption">
                    <Label htmlColor="blue" className="shareIcon"/>
                    <span className="shareOptionText">Tag</span>
                </div>
                <div className="shareOption">
                    <Room htmlColor="green" className="shareIcon"/>
                    <span className="shareOptionText">Location</span>
                </div>
                <div className="shareOption">
                    <EmojiEmotions htmlColor="goldenrod" className="shareIcon"/>
                    <span className="shareOptionText">Feelings</span>
                </div> */}
            </div>
            {/* <button className="shareButton">Comment</button> */}
          </div>
        </div>
      </div>

      
        <div className="shareWrapper">
          <div className="shareTop">
          <img
            className="shareProfileImg"
            src="/assets/images/user.png"
            alt=""
          />
          {test()}
            {/* <figure className="avatar me-3">
              <img
                src={`/assets/images/user.png`}
                alt="avater"
                className="shadow-sm rounded-circle w45"
              />
            </figure> */}
            {/* <img className="shareProfileImg" src="/assets/person/1.jpeg" alt="" /> */}
            <div className="card-body p-0 d-flex">
              {/* <figure className="avatar me-3">
                  <img
                    src={`assets/images/user.png`}
                    alt="avater"
                    className="shadow-sm rounded-circle w45"
                  />
                </figure> */}
              <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                {" "}
                Olumide Shode
                <Link to={`/profile/`}>
                  {" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                    @its_soa
                  </span>{" "}
                </Link>
              </h4>
            </div>
            {/* <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"> */}
            {/* {formatDistance(new Date(alert.created_at), new Date(), {
                      addSuffix: true,
                    })} */}
            {/* 5 hours ago */}
            {/* {alert.created_at} */}
            {/* </span> */}
            {/* </h4> */}
            {/* </div> */}
            {/* <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
            Olumide Shode
            </h4>
            <h4 className="fw-700 text-grey-500 font-xssss mt-1 pointer">
            Hello everyoe! this is a test comment
            </h4> */}
            {/* <button className="shareButton">Follow</button> */}
            <Button variant="contained" color="primary">
              Follow
            </Button>
            {/* <button className="shareButton">Comment</button> */}
          </div>
          {/* <div className="shareHr"> </div> */}
          {/* <hr className="shareHr"/> */}
          <div className="shareBottom">
            <div className="shareOptions">
              {/* <button className="shareButton">Comment</button> */}
            </div>
            {/* <button className="shareButton">Comment</button> */}
          </div>
        </div>
      

      <div>
        <div className="d-flex" style={{ marginLeft: 10, marginRight: 10 }}>
          <img
            className="shareProfileImg"
            src="/assets/images/user.png"
            alt=""
          />

          <div className="d-flex">
            <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
              <Link to={`/profile/`}>@its_soaa</Link>
            </h4>
            {/* <h4
              className="fw-700 text-grey-400 font-xssss mt-1 pointer"
              style={{ marginLeft: 10 }}
            >
              5 mins ago
            </h4> */}
          </div>

          <div style={{ marginLeft: 15 }}>
            <ShowMoreText
              lines={1}
              more="Read more"
              less="Show less"
              className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
              anchorClass="my-anchor-css-class"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              Lorem ipsum dolor sit amet consectetur adipiscing elit, semper
              rhoncus nisl facilisis conubia aliquam hendrerit pulvinar,
              ultrices posuere feugiat orci auctor mi. Dui vitae etiam euismod
              tellus diam, morbi eget sollicitudin cubilia, iaculis vulputate
              per netus. or iaculis quam, nisi aliquet vitae mus senectus.
            </ShowMoreText>
          </div>

          <FavoriteBorderIcon style={{ marginLeft: 10 }} />
        </div>
        <div className="shareBottom" style={{ marginLeft: 60 }}>
          <div className="shareOptions">
            {/* <h4 className="fw-700 text-grey-400 font-xssss mt-1 pointer" style={{marginLeft:10}}>
                5 mins ago
            </h4> */}
            <div className="shareOption">
              {/* <PermMedia htmlColor="tomato" className="shareIcon"/> */}
              <span className="fw-700 text-grey-600 font-xssss mt-1 pointer">
                5 mins ago
              </span>
            </div>
            <div className="shareOption">
              {/* <Label htmlColor="blue" className="shareIcon"/> */}
              <span className="fw-700 text-grey-600 font-xssss mt-1 pointer">
                {" "}
                <b>120</b> likes
              </span>
            </div>
            <div className="shareOption">
              {/* <Room htmlColor="green" className="shareIcon"/> */}
              <span className="fw-700 text-grey-600 font-xssss mt-1 pointer">
                Reply
              </span>
            </div>
            
          </div>
        </div>
      </div>

      {/* </Card> */}
    </>
  );
}
