import React, { Component } from "react";
import { Route, useParams, Link } from "react-router-dom";
import SearchBarV2 from "./SearchBarV2";

class Pagetitle extends Component {
  state = {
    verified: window.localStorage.getItem("verified"),
  };

  render() {
    const { title } = this.props;
    const { userName } = this.props;
    const { text } = this.props;
    const { url } = this.props;
    const { back } = this.props;
    const { home } = this.props;
    // const { external } = this.props;
    const { isChannel } = this.props;
    const { isPage } = this.props;
    const { bg } = this.props;
    const { color } = this.props;
    const { channelSlug } = this.props;
    const { channelUrl } = this.props;
    const { searchUrl } = this.props;
    const { govSearchUrl } = this.props;
    const { redirect } = this.props;
    return (
      <>
        <div
          className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3"
          style={{ backgroundColor: bg }}
        >
          {/* {color ?  */}
          <div style={{ display: "flex" }}>
            <h2
              className="fw-700 mb-0 mt-0 font-md d-flex align-items-center"
              style={{ color: color }}
            >
              {" "}
              {title}
            </h2>

            {/* {redirect ? ( */}
            <div
              className="pt-0 pb-0 ms-auto z-index-1"
              style={{ display: "flex", alignSelf: "flex-end" }}
            >
              <SearchBarV2
                style={{ alignSelf: "flex-end" }}
                className="pt-0 pb-0 ms-auto"
                searchUrl={searchUrl}
                govSearchUrl={govSearchUrl}
                redirect={redirect}
              />
            </div>
            {/* // ) : (
              //   ""
              // )} */}

            {/* <a href="/" className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"><i className="feather-filter font-xss text-grey-500"></i></a> */}
          </div>

          <div style={{ display: "flex", alignSelf: "flex-start" }}>
            <Link to={`/${userName}/${url}`}>
              {" "}
              <h4 className="font-xsssss text-center d-none d-lg-block text-primary fw-600 ms-2 me-2">
                <b className="text-primary-900 mb-1 font-sm fw-700 d-inline-block ls-3 text-dark"></b>
                {text}
              </h4>{" "}
            </Link>{" "}
          </div>

          {isChannel ? (
            // <>
            //   {this.state.verified === "null" ? (
            //     <a
            //       href="/email_verification"
            //       className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            //     >
            //       Verify Email First
            //     </a>
            // ) : (
            <a
              href="/create_channel"
              className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            >
              {isChannel}
            </a>
          ) : (
            //   )}
            // </>
            ""
          )}
          {isPage ? (
            // <>
            //   {this.state.verified === "null" ? (
            //     <a
            //       href="/email_verification"
            //       className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            //     >
            //       Verify Email First
            //     </a>
            //   ) : (
            <a
              href="/create_page"
              className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            >
              {isPage}
            </a>
          ) : (
            //   )}
            // </>
            ""
          )}
          {back ? (
            <div style={{ display: "flex" }} className="d-inline-block">
              <a
                href={`/channel/${channelSlug}/${channelUrl}`}
                className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
              >
                {back}
              </a>{" "}
              <div>
                <a
                  href={"/chillax"}
                  //   style={{ marginLeft: 40 }}
                  className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                >
                  Back To Chillax
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
          {home ? (
            <a
              href={"/"}
              className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            >
              {home}
            </a>
          ) : (
            ""
          )}

          {/* {external ? (
            <a
              href={url}
              className="mt-4 p-0 btn p-2 lh-24 w150 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
            >
              {text} hii
            </a>
          ) : (
          ''
          )} */}
        </div>
        <br />
      </>
    );
  }
}

export default Pagetitle;
