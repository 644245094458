import React, { Component , Fragment } from "react";
import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Rightchat from '../components/Rightchat';
import Appfooter from '../components/Appfooter';
import Popupchat from '../components/Popupchat';

import Profiledetail from '../components/Profiledetail';
import Profilephoto from '../components/Profilephoto';

import Createpost from '../components/Createpost';
import Events from '../components/Events';
import Postview from '../components/Postview';
import Load from '../components/Load';
import CreatePost2 from "../components/CreatePost2";
import CreatePostFab from "../components/CreatePostFab";


class Grouppage extends Component {
    render() {
        return (
            <Fragment> 
                <Header />
                <Leftnav />
                <Rightchat />

                <div className="main-content right-chat-active">
                    <div className="middle-sidebar-bottom">
                        <div className="middle-sidebar-left pe-0">
                            <div className="row">
                                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                                    
                                    {/* <Profiledetail /> */}
                                    {/* <Profilephoto />
                                    <Events /> */}
                                </div>
                                <div className="col-xl-8 col-xxl-9 col-lg-8 mt-3">
                                    {/* <CreatePost2 />
                                    
                                    <Createpost /> */}
                                    <Postview id="32" postvideo="" postimage="post.png" avater="user.png" user="Surfiya Zakir" time="22 min ago" des="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus." />
                                   
                                    <Load />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popupchat />
                <Appfooter /> 
                {/* <CreatePostFab /> */}
            </Fragment>
        );
    }
}

export default Grouppage;