 import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Component, useState, useEffect } from "react";
import { debounce } from "lodash";
import {
  FacebookMessengerIcon,
  TelegramIcon,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  FacebookIcon,
  LinkedinIcon,
  InstapaperShareButton,
  TelegramShareButton,
  PinterestShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Alert from "@mui/material/Alert";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import {
  Snackbar,
} from "@material-ui/core";


export default function ShareButtonsPost(props) {
  const [copied, setCopied] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };
  function closeSnack() {
    setCopied(false)
  }
  return (
    <>
      <div>
        
        <a>
          {" "}
          <i 
            onClick={toggleMenu}
            style={{ position: "relative", fontSize: 25, color: "#2516c7" }}
          className="ti-more-alt text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>{" "}
        </a>
        {showMenu ? (
          <ClickAwayListener onClickAway={handleClickAway}>
        <div
          className="bg-white  shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
          // data-uk-drop="mode: click;pos: bottom-right"
          style={styles}
        >
       
          <ul className="d-flex align-items-center justify-content-between mt-2">
          <li className="me-1">
               <CopyToClipboard
                text={`https://app.alarrt.com/post/guest/${props.post.unique_id}/${props.post.id}`}
                onCopy={() => setCopied(true)}
              >
                                 <span className="btn-round-lg pointer bg-linkedin">
                <i className="font-xs feather-copy text-white"></i>
              </span>
              </CopyToClipboard>
              <br />
              {/* {copied ? (
                <>
                <br/>
               
                 <Snackbar
                    open={copied}
                    autoHideDuration={1000}
                    onClose={closeSnack}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  >
                    <Alert  onClose={closeSnack} severity="success">
                   Link Copied Successfully. Share with your friends
                    </Alert>
                  </Snackbar>
                </>
              ) : null} */}
            </li>
            
            <li className="me-1">
              <TwitterShareButton
                url={`https://app.alarrt.com/post/guest/${props.post.unique_id}/${props.post.id}`}
                // {`https://app.alarrt.com/signin/post/${props.post.unique_id}`}
                // {props.post.headline.lengt}
                title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                
                hashtag="#alarrt"
              >
                <span className="btn-round-lg pointer bg-twiiter">
                  <i className="font-xs ti-twitter-alt text-white"></i>
                </span>

                {/* <TwitterIcon size={24} /> */}
              </TwitterShareButton>
            </li>
            <li className="me-1">
              <FacebookShareButton
               url={`https://app.alarrt.com/post/guest/${props.post.unique_id}/${props.post.id}`}
                title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                hashtag="#alarrt"
              >
                <span className="btn-round-lg pointer bg-facebook">
                  <i className="font-xs ti-facebook text-white"></i>
                </span>
                {/* <FacebookIcon size={24} /> */}
              </FacebookShareButton>
            </li>
            <li>
              <WhatsappShareButton
               url={`https://app.alarrt.com/post/guest/${props.post.unique_id}/${props.post.id}`}
                title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                hashtag="#alarrt"
              >
                <span className="btn-round-lg pointer bg-whatsup">
                  <i className="font-xs feather-message-circle text-white"></i>
                </span>
                {/* <WhatsappIcon size={24} /> */}
              </WhatsappShareButton>
            </li>
            {/* <li className="me-1">
            <LinkedinShareButton
                 url={`https://app.alarrt.com/signin/post/${props.post.unique_id}`}
                  title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                  hashtag="#alarrt"
                >
              <span className="btn-round-lg pointer bg-linkedin">
                <i className="font-xs ti-linkedin text-white"></i>
              </span>
              </LinkedinShareButton>
            </li> */}
            {/* <li className="me-1">
              <InstapaperShareButton url={`https://app.alarrt.com/signin/post/${props.post.unique_id}`}
                  title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                  hashtag="#alarrt"
                >
              <span className="btn-round-lg pointer bg-instagram">
                <i className="font-xs ti-instagram text-white"></i>
              </span>
              </InstapaperShareButton>
            </li> */}
        
            {/* <li>
            <PinterestShareButton
                 url={`https://app.alarrt.com/signin/post/${props.post.unique_id}`}
                  title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                  hashtag="#alarrt"
                >
              <span className="btn-round-lg pointer bg-pinterest">
                <i className="font-xs ti-pinterest text-white"></i>
              </span>
              </PinterestShareButton>
            </li> */}
            <li>
              <EmailShareButton
               url={`https://app.alarrt.com/post/guest/${props.post.unique_id}/${props.post.id}`}
                title={`Take a look at this post ${props.post.body}. View It now on Alarrt`}
                hashtag="#alarrt"
              >
                <span className="btn-round-lg pointer bg-twiiter">
                  <i className="font-xs feather-mail text-white"></i>
                </span>
                {/* <EmailIcon size={24} /> */}
              </EmailShareButton>
            </li>
            <br/><br/>
            {/* <li>
          <a
            href="#"
            className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
          >
            <i className="uil-comment-slash mr-1"></i> Disable comments
          </a>
        </li> */}
          </ul>
        </div>
        </ClickAwayListener>
        ) : (
          ""
        )}

 {copied ? (
                <>
                <br/>
                 
                 <Snackbar
                    open={copied}
                    autoHideDuration={1000}
                    onClose={closeSnack}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  >
                    <Alert  onClose={closeSnack} severity="success">
                   Link Copied Successfully
                    </Alert>
                  </Snackbar>
                </>
              ) : ''} 
      </div>
    </>
  );
}
