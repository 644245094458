import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import Chip from "@material-ui/core/Chip";
import { Snackbar } from "@material-ui/core";
import { format, formatDistance, subDays } from "date-fns";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CreateComment from "./comment/CreateComment";
import AllThreads from "./comment/AllThreads";
import CreateThread from "./comment/CreateThread";
import SingleComment from "./comment/SingleComment";
import Load from "./Load";
import LikesProfilePic from "./LikesProfilePic";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import SingleImage from "./SingleImage";
import TwoImages from "./TwoImages";
import ThreeImages from "./ThreeImages";
import ShareButtons from "./ShareButtons";
import FourImages from "./FourImages";
import Test4 from "./Test4";
import { Player } from "video-react";
import AlertUpdateModal from "./AlertUpdateModal";
import CommentModal from "./comment/CommentModal";
import BoltIcon from "@mui/icons-material/Bolt";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import LikersModal from "./LikersModal";
import ReportModal from "./ReportModal";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ForwardAlerts from "./ForwardAlert";
import ForwardAlertRandom from "./ForwardAlertRandom";
import ImageComponent from "./ImageComponent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import AlertLocation from "./AlertLocation";
import { DeleteObjectsCommand } from "@aws-sdk/client-s3";
import AlertDropComp from "./AlertDropComp";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//
export default function AlertComponent(props) {
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const userId = useState(window.localStorage.getItem("user_id"));
  const [currentAlert, setCurrentAlert] = useState("");
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isThread, setIsThread] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [error, setError] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentMenu, setCurrentMenu] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  // const [error, setError] = useState(false);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const [realerting, setRealerting] = useState(false);
  const [realerted, setRealerted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const userType = window.localStorage.getItem("type");
  const [following, setFollowing] = useState(false);
  const [followed, setFollowed] = useState(false);
  const [followText, setFollowText] = useState("follow");
  const history = useHistory();
  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    width: "90%",
    bgcolor: "background.paper",
  };

  function toggleMenu() {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };
  const authId = useState(window.localStorage.getItem("user_id"));

  function closeSnack() {
    setFollowed(false);
    setDeleted(false);
    setSaved(false);
  }

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  function handleClose2() {
    setRealerted(false);
    setSaved(false);
    setConfirmDelete(false);
  }

  async function realert(id) {
    setRealerting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/realert/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setRealerting(false);
        setRealerted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function saveAlert(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteAlert(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/alerts/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        setDeleted(true);
        props.functionProp();
        props.functionProp2();
        setShowMenu(false);

        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function Follow(user_name) {
    setFollowing(true);
    setFollowText("following...");
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setFollowText("Followed!");
        props.functionProp();
        setFollowed(true);
        setFollowing(false);
        // setFollowText("follow");
        // getFollowing();
        // setFollowed(false)
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function likeAlert(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/like/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;

        props.functionProp();
        setIsLiking(false);
        console.log(`hiii ${resp.data}`);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleAction = () => setOpenAction(!openAction);
  const toggleComment = () => setIsComment(!isComment);
  const toggleThread = () => setIsThread(!isThread);

  function openComment() {
    setIsComment(true);
  }

  let menuRef = useRef();
  useEffect(() => {
    let handler = (event) => {
      // alert('current is ', currentMenu, 'curaler', currentAlert)
      if (!menuRef.current || !menuRef.current.contains(event.target)) {
        // && currentAlert != window.sessionStorage.getItem('ca')
        setShowMenu(false);
      }
    };

    // document.addEventListener("mousedown", handler);

    // return () => {
    //   document.removeEventListener("mousedown", handler);
    // };
  }, []);

  const menuClass = `${isOpen ? " show" : ""}`;
  const actionClass = `${openAction ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  //   const { isLiked } = this.state;
  const commentOpen = isComment;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingAlerts == true) {
    // console.log(`final ${fetchingAlerts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load />
      </>
    );
  }
  if (props.alerts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Hello {userName}! You can always find what is happening around you
              here. However, no alarrt has been created in the last 24 hours. You
              can be the first to add one!
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <>
        {/* {console.log(JSON.stringify(props.alerts))} */}
        {props.alerts.map((alert) => (
          <div
            key={alert.alert.id}
            className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
          >
            {/* {userType === "true" ? <AlertLocation /> : ""} */}
            {/* {alert.alert.owner == null ? ( */}
            {alert.alert.realerted == "false" ? (
              <div className="card-body p-0 d-flex">
                <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={alert.alert.user.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                    onError={fixImage}
                    src={alert.alert.user.profile_pic}
                    alt="avater"
                    className="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  /> */}
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  {alert.alert.user.first_name} {alert.alert.user.last_name}
                  <Link to={`/profile/${alert.alert.user.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{alert.alert.user.user_name}
                      {alert.alert.user.is_active == "false" ? (
                        <span className="text-danger ml-5">
                          ( Deactivated )
                        </span>
                      ) : (
                        ""
                      )}
                    </span>{" "}
                  </Link>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {formatDistance(
                      new Date(alert.alert.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                    {/* {alert.alert.created_at} */}
                  </span>
                </h4>
                {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}

                <div className={`ms-auto pointer ${actionClass}`} ref={menuRef}>
                  {userId[0] == alert.alert.user.id ? (
                    <>
                      <AlertDropComp
                        canReport={false}
                        canUpdate={true}
                        canDelete={false}
                        alert={alert.alert}
                        functionProp={() => props.functionProp()}
                        functionProp2={() => props.functionProp2()}
                      />
                    </>
                  ) : (
                    <>
                      <AlertDropComp
                        canReport={true}
                        canUpdate={false}
                        canDelete={false}
                        alert={alert.alert}
                        functionProp={() => props.functionProp()}
                        functionProp2={() => props.functionProp2()}
                      />

                      {/* </ClickAwayListener> */}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                {/* owner */}
                <div className="card-body p-0 d-flex">
                  <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                    <ImageComponent
                      image={alert.alert.owner.profile_pic}
                      class="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    />
                    {/* <img
                      onError={fixImage}
                      src={alert.alert.owner.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                  </figure>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    <span style={{ paddingRight: 2 }}>
                      Alerted From : {alert.alert.owner.first_name}{" "}
                      {alert.alert.owner.last_name}'s
                    </span>
                    <Link
                      // to={{
                      //   pathname: `/alert/${alert.alert.slug}?i=${alert.alert.alert_origin}`,
                      // }}
                      onClick={() => {
                        history.push({
                          pathname: `/alert/${alert.alert.slug}?i=${alert.alert.alert_origin}`,
                        });
                        window.location.reload();
                      }}
                    >
                      Alert
                    </Link>
                    <Link to={`/profile/${alert.alert.owner.user_name}`}>
                      {" "}
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                        {" "}
                        @{alert.alert.owner.user_name}
                        {alert.alert.owner.is_active == "false" ? (
                          <span className=" text-danger ml-5">
                            ( Deactivated )
                          </span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </Link>
                  </h4>
                </div>

                <div className="card-body p-0 d-flex">
                  <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                    <ImageComponent
                      image={alert.alert.user.profile_pic}
                      class="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    />
                    {/* <img
                      onError={fixImage}
                      src={alert.alert.user.profile_pic}
                      alt="avater"
                      className="shadow-sm me-3 rounded-circle w45"
                      style={{ border: "2px solid #045de9" }}
                    /> */}
                  </figure>
                  <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    {alert.alert.user.first_name} {alert.alert.user.last_name}
                    <Link to={`/profile/${alert.alert.user.user_name}`}>
                      {" "}
                      <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                        {" "}
                        @{alert.alert.user.user_name}
                        {alert.alert.user.is_active == "false" ? (
                          <span className=" text-danger ml-5">
                            ( Deactivated )
                          </span>
                        ) : (
                          ""
                        )}
                      </span>{" "}
                    </Link>
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                      {formatDistance(
                        new Date(alert.alert.created_at),
                        new Date(),
                        {
                          addSuffix: true,
                        }
                      )}
                      {/* {alert.alert.created_at} */}
                    </span>
                  </h4>
                  {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}

                  <div
                    className={`ms-auto pointer ${actionClass}`}
                    ref={menuRef}
                  >
                    {userId[0] == alert.alert.user.id ? (
                      <>
                        <AlertDropComp
                          canReport={false}
                          canUpdate={false}
                          canDelete={true}
                          alert={alert.alert}
                          functionProp={() => props.functionProp()}
                          functionProp2={() => props.functionProp2()}
                        />
                      </>
                    ) : (
                      <>
                        <AlertDropComp
                          canReport={true}
                          canUpdate={false}
                          canDelete={false}
                          alert={alert.alert}
                          functionProp={() => props.functionProp()}
                          functionProp2={() => props.functionProp2()}
                        />
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            <Link
              // to={{
              //   pathname: `/alert/${alert.alert.slug}?i=${alert.alert.id}`,
              //   // state: { id: alert.alert.id },
              // }}
              onClick={() => {
                history.push({
                  pathname: `/alert/${alert.alert.slug}?i=${alert.alert.id}`,
                });
                window.location.reload();
              }}
            >
              {/* <Link to={`/alert.alert/${alert.alert.slug}`} state={{ id: alert.alert.id }}> */}
              <h3 className="fw-700 text-grey-900 pointer">
                {" "}
                {alert.alert.headline}{" "}
              </h3>
            </Link>
            {alert.alert.video ? (
              <div className="card-body p-0 rounded-3 overflow-hidden uttam-die">
                <br />

                {/* <Player
                  playsInline
                  // poster={alert.alert.images[0].url}
                  src={alert.alert.video}
                  fluid
                /> */}
                <ReactPlayer
                  url={alert.alert.video}
                  controls={true}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                  playsinline={true}
                  width="100%"
                  // light={alert..image}
                />
              </div>
            ) : (
              ""
            )}
            {/* {alert.alert.images.length} */}
            {/* <p> Ji {alert.alert.videos.id} </p> */}
            {/* {alert.alert.videos.length} */}
            {alert.alert.images ? (
              <div className="card-body d-block p-0 mb-3">
                {/* <br /> */}

                {/* <Test4 images={alert.alert.images}/> */}
                {/* <Lightbox images={alert.alert.images.url}> */}
                {alert.alert.images.length == 1 ? (
                  <SingleImage
                    images={alert.alert.images}
                    text={alert.alert.headline}
                  />
                ) : (
                  ""
                )}
                {alert.alert.images.length == 2 ? (
                  <TwoImages
                    images={alert.alert.images}
                    text={alert.alert.headline}
                  />
                ) : (
                  ""
                )}
                {alert.alert.images.length == 3 ? (
                  <ThreeImages
                    images={alert.alert.images}
                    text={alert.alert.headline}
                  />
                ) : (
                  ""
                )}
                {alert.alert.images.length == 4 ? (
                  <FourImages
                    images={alert.alert.images}
                    text={alert.alert.headline}
                  />
                ) : (
                  ""
                )}

                {alert.alert.images.length > 4 && (
                  <Carousel>
                    {alert.alert.images.map((slideImage, index) => (
                      <img
                        src={slideImage.url}
                        key={index}
                        style={{ zIndex: -10 }}
                        alt=""
                      />
                    ))}
                  </Carousel>
                  // <Zoom scale={1.4} indicators={true} style={{ zIndex: -10 }}>
                  //   {alert.alert.images.map((slideImage, index) => (

                  //       <img src={slideImage.url} key={index} style={{zIndex: -10}} alt="" />

                  //   ))}
                  // </Zoom>
                )}
              </div>
            ) : (
              ""
            )}
            {alert.alert.audio && (
              <>
              <br/>
              <audio controls>
                <source src={alert.alert.audio} type="audio/mpeg" />
              </audio>
              <br/>
              </>
            )}
            <div className="card-body p-0 me-lg-5">
              <ShowMoreText
                /* Default options */
                lines={3}
                more="Read more"
                less="Show less"
                className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
                // className="content-css"
                anchorClass="my-anchor-css-class"
                // onClick={executeOnClick}
                expanded={false}
                // width={480}
                truncatedEndingComponent={"... "}
              >
                {alert.alert.body}
              </ShowMoreText>

              {/* <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{alert.body} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p> */}
              <Link to={`/alert-category/${alert.alert.alert_category.slug}`}>
                {" "}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Category Type:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={alert.alert.alert_category.name}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
              </Link>


              {alert.alert.type == "distress" ? (
              
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Alarrt Type:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={alert.alert.type}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>
                  ) : (
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Status:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={alert.alert.status}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>
              
              )}

              {!alert.alert.location == "" ? (
                <>
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    Location:{" "}
                    <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={alert.alert.location}
                    />{" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                    </span>{" "}
                  </h6>{" "}
                </>
              ) : (
                ""
              )}

              {!alert.alert.url == "" ? (
                <a href={alert.alert.url} target="_blank">
                  {/* <Link to={`/alert.alert/${alert.alert.slug}`} state={{ id: alert.alert.id }}> */}
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    {" "}
                    URL: <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={alert.alert.url}
                    />
                  </h6>
                </a>
              ) : (
                ""
              )}
              {!alert.alert.tags.length == 0 ? (
                <>
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    Info Tags{" "}
                  </h6>
                  <div>
                    {alert.alert.tags.map((tag) => (
                      <>
                        <a href={`/alert-tag/${tag.slug}`}>
                          {/* <Link to={`/alert-tag/${tag.slug}`}> */}
                          <Chip
                            style={{ marginRight: 10, marginBottom: 5 }}
                            className="infotag pointer"
                            variant="outlined"
                            color="primary"
                            size="small"
                            label={`# ${tag.name}`}
                          />
                          {/* </Link> */}
                        </a>
                      </>
                    ))}
                    <br /> <br />
                  </div>
                </>
              ) : (
                ""
              )}
              {userType === "true" ? (
                <>
                  <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                    View Location
                  </h6>
                  <div>
                    <a
                      onClick={() => {
                        history.push({
                          pathname: `/alert/location/${alert.alert.slug}?i=${alert.alert.alert_origin}`,
                        });
                        window.location.reload();
                      }}
                      // href={`/alert/${alert.alert.slug}/location?i=${alert.alert.alert_origin}`}
                    >
                      {/* <Link to={`/alert-tag/${tag.slug}`}> */}
                      <Chip
                        style={{ marginRight: 10, marginBottom: 5 }}
                        className="infotag pointer"
                        variant="outlined"
                        color="primary"
                        size="small"
                        label={`View Location`}
                      />
                      {/* </Link> */}
                    </a>

                    <br />
                  </div>
                </>
              ) : (
                ""
              )}
              {/* {alert.alert.alert_location.latitude} */}
            </div>
            {!alert.alert.likers.length == 0 ? (
              <LikesProfilePic
                type="alert"
                likers={alert.likers}
                likes={alert.alert.likers}
                comments={alert.alert.alert_comments_count}
                realerts={alert.alert.re_alerts}
                allLikers={alert.alert.likers}
                color="#000"
                text="thanks"
                likersType="Alert"
                // class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss"
              />
            ) : (
              ""
            )}{" "}
            {/* <LikesProfilePic user={alert.alert.user} comments={alert.alert.alert_comments_count} /> */}
            <div className="card-body d-flex p-0">
              <div
                className="emoji-bttn pointer d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss me-2"
                onClick={toggleActive}
              >
                {isLiking && currentAlert == alert.alert.id ? (
                  <HourglassEmptyRoundedIcon
                    style={{ fontSize: 25, color: "#2516c7" }}
                  />
                ) : (
                  <>
                    {alert.likedStatus == "false" ? (
                      <>
                        <ThumbUpAltOutlinedIcon
                          onClick={() => {
                            setCurrentAlert(alert.alert.id);
                            likeAlert(alert.alert.id);
                          }}
                          style={{ fontSize: 25, color: "#2516c7" }}
                        />{" "}
                      </>
                    ) : (
                      <>
                        <ThumbUpAltIcon
                          onClick={() => {
                            setCurrentAlert(alert.alert.id);
                            likeAlert(alert.alert.id);
                          }}
                          style={{ fontSize: 25, color: "#2516c7" }}
                        />{" "}
                      </>
                    )}
                  </>
                )}
                <span style={{ paddingLeft: 5 }}>
                  {" "}
                  {alert.alert.likers.length}
                  {/* <LikersModal
                    likers={alert.alert.likers}
                    color="#000"
                    text="Thanks"
                    type="Alert"
                    class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss"
                  /> */}
                </span>

                <span className="d-flex align-items-center fw-800 text-grey-800 text-dark lh-26 font-xssss">
                  {realerting && currentAlert == alert.alert.id ? (
                    <HourglassEmptyRoundedIcon
                      style={{ fontSize: 25, color: "#2516c7" }}
                    />
                  ) : (
                    <FlashOnIcon
                      style={{
                        marginLeft: 10,
                        fontSize: 25,
                        color: "#2516c7",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCurrentAlert(alert.alert.id);
                        realert(alert.alert.alert_origin);
                      }}
                    />
                  )}
                  {alert.alert.re_alerts}
                  {/* re-alerts */}
                </span>
                <div style={{ marginLeft: 13 }}></div>
              </div>

              {authId[0] == alert.alert.user.id && (
                <span className="d-flex align-items-center fw-800 text-grey-800 text-dark lh-26 font-xssss">
                  <FlashAutoIcon
                    style={{
                      fontSize: 25,
                      color: "#2516c7",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCurrentAlert(alert.alert.id);
                      toggleThread();
                    }}
                  />{" "}
                </span>
              )}

              <div style={{ marginLeft: 10 }}></div>
              <a className="d-flex align-items-center fw-600 text-grey-900 text-dark lh-26 font-xssss">
                <i
                  style={{ color: "#2516c7", fontSize: 25 }}
                  className="feather-message-circle btn-round-sm"
                ></i>
                {isComment ? (
                  <span
                    className="d-none-xss"
                    onClick={() => {
                      setCurrentAlert(alert.alert.id);
                      toggleComment();
                    }}
                  >
                    Close {alert.alert.alert_comments_count} updates
                  </span>
                ) : (
                  <span
                    className="d-none-xss"
                    onClick={() => {
                      setCurrentAlert(alert.alert.id);
                      toggleComment();
                    }}
                  >
                    See {alert.alert.alert_comments_count} updates
                  </span>
                )}
              </a>

              <div
                className={`pointer ms-auto d-flex align-items-center fw-600 text-current lh-26 font-xssss ${menuClass}`}
              >
                <ShareButtons alert={alert.alert} />
              </div>
            </div>

            {alert.followingUser == "true" ? (
              ""
            ) : (
              <>
                {userId[0] == alert.alert.user.id ? (
                  ""
                ) : (
                  <div className="flex items-center justify-between py-3">
                    <div className="flex flex-1 items-center space-x-4">
                      {" "}
                      <br />
                      <p>
                        Follow @{alert.alert.user.user_name} to see future
                        alarrts and posts in your timeline{" "}
                      </p>{" "}
                      {following && currentAlert == alert.alert.id ? (
                        <a
                          // className="ms-auto pointer"
                          disabled
                          // onClick={() => Follow(alert.alert.user.user_name)}
                          style={{ marginTop: -8 }}
                          className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                        >
                          {" "}
                          {followText}{" "}
                        </a>
                      ) : (
                        <a
                          // className="ms-auto pointer"
                          onClick={() => {
                            setCurrentAlert(alert.alert.id);
                            Follow(alert.alert.user.user_name);
                          }}
                          style={{ marginTop: -8 }}
                          className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                        >
                          {" "}
                          follow
                        </a>
                      )}
                    </div>
                    <br />
                    {followed && currentAlert == alert.alert.id ? (
                      <Snackbar
                        open={followed}
                        autoHideDuration={3000}
                        onClose={closeSnack}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        <Alert onClose={closeSnack} severity="success">
                          Followed {alert.alert.user.user_name} Successfully
                        </Alert>
                      </Snackbar>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
            {isThread && (
              <CreateThread
                Id={alert.alert.id}
                url={"alert_threads"}
                threads={alert.alert.threads}
                functionProp={props.functionProp}
                // openComment={openComment}
                // favUrl={"alert"}
                authId={authId[0]}
                userId={alert.alert.user.id}
              />
            )}
            <AllThreads
              Id={alert.alert.id}
              url={"alert_threads"}
              user_name={alert.alert.user.user_name}
            pic={alert.alert.user.profile_pic}
              threads={alert.alert.threads}
              functionProp={props.functionProp}
              // openComment={openComment}
              // favUrl={"alert"}
              authId={authId[0]}
              userId={alert.alert.user.id}
            />
            <CreateComment
              Id={alert.alert.id}
              url={"alerts/comment"}
              favComments={alert.favComments}
              functionProp={props.functionProp}
              openComment={openComment}
              favUrl={"alert"}
              authId={authId[0]}
              userId={alert.alert.user.id}
              mutipleMedia='true'
            />
            {isComment && currentAlert == alert.alert.id ? (
              <>
                <SingleComment
                  Id={alert.alert.id}
                  comments={alert.comments}
                  favUrl={"alert"}
                  url1={"alerts"}
                  deleteUrl="alertComment"
                  functionProp={props.functionProp}
                  authId={authId[0]}
                  userId={alert.alert.user.id}
                  mutipleMedia='true'
                />
                <span
                  className="fw-600 text-grey-900 mt-2 pointer"
                  onClick={() => {
                    setCurrentAlert(alert.alert.id);
                    toggleComment();
                  }}
                  // onClick={toggleComment}
                >
                  {/* 283 Comments */}
                  Close Comments
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        ))}
        {deleted ? (
          <Snackbar
            open={deleted}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Deleted Alert Successfully
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
        {saved ? (
          <Snackbar
            open={saved}
            autoHideDuration={5000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Saved Alert Successfully. View In Your Profile
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}

        {realerted ? (
          <Snackbar
            open={realerted}
            autoHideDuration={2000}
            onClose={handleClose2}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={handleClose2} variant="filled" severity="success">
              Re alerted successfully
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
        {/* <CreateAlert2 functionProp={() => props.functionProp} functionProp2={() => props.functionProp2} /> */}
        {/* <CreateAlert2 functionProp={() => props.functionProp} functionProp2={() => props.functionProp2} /> */}
      </>
    );
  }
  // }
}

// export default Alerts;
