// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { useState, useEffect, lazy, Suspense } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import * as serviceWorker from "./serviceWorker";

// Create Import File
import "./main.scss";
import ProtectedRoute from "./ProtectedRoute";
import Load3 from "./components/Load3";
import Load from "./components/Load";

import Home from "./pages/Home";
import Postfeed from "./pages/Postfeed";

import Badge from "./pages/Badge";
import AlarrtSubscription from "./pages/AlarrtSubscription";
// Common Layout
import Demo from "./demo/Demo";
import Channels from "./pages/Channels";

import ChannelPage from "./pages/ChannelPage";
import CommunityPage from "./pages/CommunityPage";
import SingleChannelAdmin from "./pages/SingleChannelAdmin";
import Group from "./pages/Group";
import Storie from "./pages/Storie";
import Member from "./pages/Member";
import Followers from "./pages/Followers";
import Followings from "./pages/Followings";
import SOSContact from "./pages/SOSContact";
import ChatList from "./pages/ChatList";
import Emailopen from "./pages/Emailopen";
import Settings from "./pages/Settings";
import Account from "./pages/Account";

import Contactinfo from "./pages/Contactinfo";
import Socialaccount from "./pages/Socialaccount";
import Password from "./pages/Password";

import Payment from "./pages/Payment";
import Notification from "./pages/Notification";
import Helpbox from "./pages/Helpbox";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Notfound from "./pages/Notfound";

import ShopOne from "./pages/ShopOne";
import ShopTwo from "./pages/ShopTwo";
import ShopThree from "./pages/ShopThree";
import Singleproduct from "./pages/Singleproduct";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Chat from "./pages/Chat";
import Live from "./pages/Live";
import Job from "./pages/Job";
import Event from "./pages/Event";
import Hotel from "./pages/Hotel";
import Videos from "./pages/Videos";
import Comingsoon from "./pages/Comingsoon";

import Grouppage from "./pages/Grouppage";
import Userprofile from "./pages/Userprofile";
import ExploreFeed from "./pages/ExploreFeed";
import Authorpage from "./pages/Authorpage";
import Hotelsingle from "./pages/Hotelsingle";
import Analytics from "./pages/Analytics";

import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";

// Admin

import UserList from "./pages/admin/userList/UserList";
import User from "./pages/admin/user/User";
import NewUser from "./pages/admin/newUser/NewUser";
import ProductList from "./pages/admin/productList/ProductList";
import Product from "./pages/admin/product/Product";
import NewProduct from "./pages/admin/newProduct/NewProduct";

import NotFoundPage from "./pages/NotFoundPage";
import Test3 from "./components/Test3";
import Test2 from "./components/Test2";
import Test4 from "./components/Test4";

import SearchBarV2 from "./components/SearchBarV2";
import Alertcategories1 from "./components/Alertcategories1";
import AlertCategory from "./pages/AlertCategory";
import AlertTags from "./pages/AlertTags";
import SingleAlert from "./pages/SingleAlert";
import SingleAlertGuest from "./pages/SingleAlertGuest";
import SinglePostGuest from "./pages/SinglePostGuest";
import SingleAlertAdmin from "./components/SingleAlertAdmin";
import SingleAlertLocation from "./components/SingleAlertLocation";
import CloseFriends from "./pages/CloseFriends";

import CreateChannel from "./pages/CreateChannel";
import CreatePage from "./pages/CreatePage";
import PostCategory from "./pages/PostCategory";
import PostTags from "./pages/PostTags";
import Movies from "./components/movies/Movies";
import SingleMovie2 from "./components/movies/SingleMovie2";
import SingleMovie from "./components/movies/SingleMovie";
import RecordScreen from "./components/RecordScreen";
import RecordScreen2 from "./components/RecordScreen2";
import WatchLocation from "./pages/WatchLocation";
import AlertLocation from "./components/AlertLocation";
import AlertLocation2 from "./components/AlertLocation2";
import Detail from "./pages/movies/detail/Detail";
import PageSingle from "./pages/PageSingle";

// import Createtest from "./components/Createtest";

import ResetPassword from "./pages/auth/ResetPassword";
import AppPolicy from "./pages/auth/AppPolicy";
import OldNotification from "./pages/OldNotification";
import Verification from "./pages/Verirfication";
import VerificationComplete from "./pages/VerificationComplete";
import SinglePost from "./pages/SinglePost";
import ContactUs from "./pages/auth/ContactUs";
import HomeMovie from "./pages/movies/HomeMovie";
import ChannelsOld from "./pages/ChannelsOld";
import SingleEpisode from "./components/movies/SingleEpisode";
import ChannelUpdate from "./pages/ChannelUpdate";
import SinglePostComponent2 from "./components/SinglePostComponent2";
import AllMovies from "./components/movies/AllMovies";
import UserAlerts from "./components/UserAlerts";
// import Pages from "./pages/AllPages";
import AllPages from "./pages/AllPages";
import Communities from "./pages/Communities";
import Chillarx from "./components/movies/Chillarx";
import AllNews from "./components/movies/AllNews";
import AlertFilterAdmin from "./components/widgets/AlertFilterAdmin";
import AlertCategoriesSlide from "./components/widgets/AlertCategoriesSlide";
import AlertLocationQuick from "./components/AlertLocationQuick";
import HomeExpiredAlerts from "./pages/HomeExpiredAlerts";
import UserSubscriptions from "./pages/UserSubscriptions";
// import UserSubscriptions from "./pages/UserSubscriptions";


// hjhk
// const Home = React.lazy(() => import( "./pages/Home"));
// const Postfeed = React.lazy(() => import( "./pages/Postfeed"));
// const Channels = React.lazy(() => import( "./pages/Channels"));
// const ChannelPage = React.lazy(() => import( "./pages/ChannelPage"));
// const Followers = React.lazy(() => import( "./pages/Followers"));
// const Followings = React.lazy(() => import( "./pages/Followings"));
// const ChatList = React.lazy(() => import( "./pages/ChatList"));
// const Emailopen = React.lazy(() => import( "./pages/Emailopen"));
// const Settings = React.lazy(() => import( "./pages/Settings"));
// const Account = React.lazy(() => import(  "./pages/Account"));
// const Contactinfo = React.lazy(() => import( "./pages/Contactinfo"));
// const Socialaccount = React.lazy(() => import( "./pages/Socialaccount"));
// const Password = React.lazy(() => import( "./pages/Password"));
// const Group = React.lazy(() => import( "./pages/Group"));
// const Badge = React.lazy(() => import( "./pages/Badge"));
// const Payment = React.lazy(() => import( "./pages/Payment"));
// const Notification = React.lazy(() => import( "./pages/Notification"));
// const Helpbox = React.lazy(() => import( "./pages/Helpbox"));
// const Login = React.lazy(() => import( "./pages/Login"));
// const Register = React.lazy(() => import( "./pages/Register"));
// const Forgot = React.lazy(() => import( "./pages/Forgot"));
// const Notfound = React.lazy(() => import( "./pages/Notfound"));
// const Comingsoon = React.lazy(() => import( "./pages/Comingsoon"));
// const Grouppage = React.lazy(() => import( "./pages/Grouppage"));
// const Userprofile = React.lazy(() => import( "./pages/Userprofile"));
// const ExploreFeed = React.lazy(() => import( "./pages/ExploreFeed"));
// const Authorpage = React.lazy(() => import( "./pages/Authorpage"));
// const Analytics = React.lazy(() => import( "./pages/Analytics"));

// const SignIn = React.lazy(() => import(  "./pages/auth/SignIn"));
// const SignUp = React.lazy(() => import( "./pages/auth/SignUp"));
// const ForgotPassword = React.lazy(() => import( "./pages/auth/ForgotPassword"));
// const NotFoundPage = React.lazy(() => import( "./pages/NotFoundPage"));
// const Test3 = React.lazy(() => import( "./components/Test3"));
// const Test2 = React.lazy(() => import( "./components/Test2"));
// const Test4 = React.lazy(() => import( "./components/Test4"));
// const SearchBarV2 = React.lazy(() => import( "./components/SearchBarV2"));
// const Alertcategories1 = React.lazy(() => import( "./components/Alertcategories1"));
// const AlertCategory = React.lazy(() => import( "./pages/AlertCategory"));
// const AlertTags = React.lazy(() => import( "./pages/AlertTags"));
// const SingleAlert = React.lazy(() => import( "./pages/SingleAlert"));
// const CloseFriends = React.lazy(() => import( "./pages/CloseFriends"));
// const CreateChannel = React.lazy(() => import( "./pages/CreateChannel"));
// const CreatePage = React.lazy(() => import( "./pages/CreatePage"));
// const PostCategory = React.lazy(() => import( "./pages/PostCategory"));
// const PostTags = React.lazy(() => import( "./pages/PostTags"));
// const Movies = React.lazy(() => import( "./components/movies/Movies"));
// const SingleMovie2 = React.lazy(() => import(  "./components/movies/SingleMovie2"));
// const SingleMovie = React.lazy(() => import( "./components/movies/SingleMovie"));
// const Detail = React.lazy(() => import( "./pages/movies/detail/Detail"));
// const HomeMovie = React.lazy(() => import( "./pages/movies/HomeMovie"));
// const ChannelsOld = React.lazy(() => import( "./pages/ChannelsOld"));
// const SingleEpisode = React.lazy(() => import( "./components/movies/SingleEpisode"));
// const ChannelUpdate = React.lazy(() => import( "./pages/ChannelUpdate"));
// const SinglePostComponent2 = React.lazy(() => import( "./components/SinglePostComponent2"));
// const AllMovies = React.lazy(() => import( "./components/movies/AllMovies"));
// const UserAlerts = React.lazy(() => import( "./components/UserAlerts"));
// const AllPages = React.lazy(() => import(  "./pages/AllPages"));
// const Pages = React.lazy(() => import( "./pages/AllPages"));
// const ResetPassword = React.lazy(() => import( "./pages/auth/ResetPassword"));
// const PageSingle = React.lazy(() => import( "./pages/PageSingle"));
// const OldNotification = React.lazy(() => import( "./pages/OldNotification"));
// const Verification = React.lazy(() => import( "./pages/Verirfication"));
// const VerificationComplete = React.lazy(() => import( "./pages/VerificationComplete"));
// const SinglePost = React.lazy(() => import( "./pages/SinglePost"));
// const ContactUs = React.lazy(() => import( "./pages/auth/ContactUs"));
// import Chat from "./pages/Chat";
// const Chat = React.lazy(() => import( "./pages/Chat"));
// const SingleAlertLocation = React.lazy(() => import( "./components/SingleAlertLocation"));
// const SingleAlertAdmin = React.lazy(() => import( "./components/SingleAlertAdmin"));
// const AlertFilterAdmin = React.lazy(() => import( "./components/widgets/AlertFilterAdmin"));
// const RecordScreen = React.lazy(() => import( "./components/RecordScreen"));
// const RecordScreen2 = React.lazy(() => import( "./components/RecordScreen"));
// const AlertLocation = React.lazy(() => import( "./components/AlertLocation"));
// const AlertLocation2 = React.lazy(() => import( "./components/AlertLocation2"));
// const Chillarx = React.lazy(() => import( "./components/movies/Chillarx"));
// const AllNews = React.lazy(() => import( "./components/movies/AllNews"));
// const WatchLocation = React.lazy(() => import( "./pages/WatchLocation"));
// const SingleChannelAdmin = React.lazy(() => import( "./pages/SingleChannelAdmin"));
// const Job = React.lazy(() => import( "./pages/Job"));


// const Home = React.lazy(() => import( "/components/:user_name"));
// const Home = React.lazy(() => import( "/components/:user_name"));
// const Home = React.lazy(() => import( "/components/:user_name"));
// const Home = React.lazy(() => import( "/components/:user_name"));
// const Home = React.lazy(() => import( "/components/:user_name"));

// my components

// const SignIn = React.React.lazy(() => import('./pages/auth/SignIn'));
// const SignUp = React.React.lazy(() => import('./pages/auth/SignUp'));
// const ForgotPassword = React.React.lazy(() => import('./pages/auth/ForgotPassword'));

// export default class Routes extends Component{
export default function Routes() {
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;
  const [showSnack, setShowSnack] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setShowSnack(false);
  }


  useEffect(() => {
    if (
      window.localStorage.getItem("access_token") &&
      pathname.includes("/signin")
    ) {
      if (pathname === "/signin") {
        history.push("/");
        console.log("pathname is on signin");
      } else {
        const queryParams = new URLSearchParams(location.search);
        console.log(`hiii ${queryParams}`);
        localStorage.setItem("params", queryParams);
        console.log("it has a parameter");
        const newPath = pathname.slice(7);
        console.log("new path is " + newPath);
        localStorage.setItem("newPath", newPath);
        console.log("new path stored");

        history.push(`${newPath}?${queryParams}`);
        history.go();
      }
    }

    if (
      !window.localStorage.getItem("access_token") &&
      pathname.includes("/signin")
    ) {
      if (pathname === "/signin") {
        history.push("/signin");
        console.log("pathname is on signin");
      } 
      else {
        // const reloadCount = localStorage.getItem("reloadCount");
        // if (reloadCount < 1) {
          // localStorage.setItem("reloadCount", String(reloadCount + 1));
          // console.log("reload count is less than one");

          const queryParams = new URLSearchParams(location.search);
          console.log(`param is ${queryParams}`);
          localStorage.setItem("params", queryParams);
          console.log("it has a parameter");
          const newPath = pathname.slice(7);
          console.log("new path is " + newPath);
          localStorage.setItem("newPath", newPath);
          console.log("new path stored");

          // history.push(`/signin/redirect-after-signin`);
          // history.go();
          setShowSnack(true)
          // alert("Welcome! Please login first. You'll be redirected afterwards")
          
          
        // } else {
        //   localStorage.removeItem("reloadCount");
        // }

        // history.push(`/signin/redirect-after-sigin`)
        // history.go()
      }
    }

    if (pathname.includes("/verify")) {
      const vLink = pathname.slice(15);
      const verLink = "https://" + vLink;
      window.localStorage.setItem("verification_link", verLink);
      // alert(pathname.slice(8))
      history.push("/complete_verification");
      history.go();
    }
    // console.log(`route ${location.pathname}`);
    // if(window.localStorage.getItem("access_token") && pathname.includes('/forgot-password') || pathname.includes('nup') || pathname.includes('signin') || pathname.includes('reset-password') || pathname.includes('reset')){
    // if(window.localStorage.getItem("access_token")){
    // console.log('redirecting')
    // history.push("/home");
    // || pathname.includes('/help')
    // }
    // if(pathname.includes('reset-password') || pathname.includes('reset')){
    //   console.log('includes reset redirect')
    // }
    // else{
    //   history.push("/signin");
    // }
  }, []);
  // render(){
  return (
    <BrowserRouter basename="/">
      {/* <React.Suspense fallback={<Load message='Loading ...' />}> */}
      {/* <Suspense fallback={<Load message='Loading ...' />}> */}
      <Switch>
        <Route
          exact
          path="/signin"
          name="SignIn Page"
          render={(props) => <SignIn {...props} />}
        />
        <Route
          exact
          path="/signin/:id"
          name="SignIn Page"
          render={(props) => <SignIn {...props} />}
        />
        <Route
          exact
          path="/signup"
          name="SignUp Page"
          render={(props) => <SignUp {...props} />}
        />

        <Route
          exact
          path="/forgot-password"
          name="Forgot Password Page"
          render={(props) => <ForgotPassword {...props} />}
        />

        <Route
          exact
          path="/reset-password/:id"
          name="Reset Password Page"
          render={(props) => <ResetPassword {...props} />}
        />
        <Route
          exact
          path="/reset-password1/:id"
          name="Help Page"
          render={(props) => <Forgot {...props} />}
        />
        <Route
          exact
          path="/reset-password2/:id"
          name="Help Page"
          render={(props) => <Password {...props} />}
        />
        <Route
          exact
          path="/help"
          name="Help Page"
          render={(props) => <Helpbox {...props} />}
        />

<Route
          exact
          path="/terms-and-policy"
          name="Terms and Policy"
          render={(props) => <AppPolicy {...props} />}
        />


        <Route
          exact
          path={"/contact-us"}
          name="Help Page"
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          exact
          path={"/register"}
          name="Help Page"
          render={(props) => <Register {...props} />}
        />

<Route
          exact
          path="/alert/guest/:slug"
          name="Alarrt Guest"
          render={(props) => <SingleAlertGuest {...props} />}
        />

<Route
          exact
          path="/post/guest/:u_id/:id"
          name="Post Guest"
          render={(props) => <SinglePostGuest {...props} />}
        />


        <Route exact path={"/forgot"}>
          <ProtectedRoute Cmp={Forgot} />
        </Route>

        <Route exact path={"/password"}>
          <ProtectedRoute Cmp={Password} />
        </Route>
        <Route exact path="/email_verification" name="Verify Email">
          <ProtectedRoute Cmp={Verification} />
        </Route>
        <Route exact path="/complete_verification" name="Verify Email">
          <ProtectedRoute Cmp={VerificationComplete} />
        </Route>
        {/* <Route exact path={"/"} component={Home}/> */}
        <Route exact path={"/notfound2"}>
          <ProtectedRoute Cmp={NotFoundPage} />
        </Route>
        <Route exact path={"/notfound"}>
          <ProtectedRoute Cmp={Notfound} />
        </Route>

        <Route exact path={"/"}>
          <ProtectedRoute Cmp={Home} />
        </Route>

        <Route exact path={"/alarrts"}>
          <ProtectedRoute Cmp={HomeExpiredAlerts} />
        </Route>
        {/* <Route exact path={"/test"}>
          <ProtectedRoute Cmp={Test3} />
        </Route> */}
        {/* <Route exact path={"/test2"}>
          <ProtectedRoute Cmp={Test2} />
        </Route>
        <Route exact path={"/test4"}>
          <ProtectedRoute Cmp={Test4} />
        </Route> */}
        <Route exact path={"/home"}>
          <ProtectedRoute Cmp={Home} />
        </Route>
        {/* <Route exact path={"/load"}>
          <ProtectedRoute Cmp={Load3} />
        </Route> */}
        <Route exact path={"/timeline"}>
          <ProtectedRoute Cmp={Postfeed} />
        </Route>
        <Route exact path={"/post2"}>
          <ProtectedRoute Cmp={SinglePostComponent2} />
        </Route>

        <Route exact path={"/all/categories"}>
          <ProtectedRoute Cmp={AlertCategoriesSlide} />
        </Route>

        <Route exact path={"/alert/:slug"}>
          <ProtectedRoute Cmp={SingleAlert} />
        </Route>
        <Route exact path={"/alert/location/:slug"}>
          <ProtectedRoute Cmp={SingleAlertLocation} />
        </Route>
        <Route exact path={"/locationquick"}>
          <ProtectedRoute Cmp={AlertLocationQuick} />
        </Route>
        <Route exact path={"/alert/admin/:slug"}>
          <ProtectedRoute Cmp={SingleAlertAdmin} />
        </Route>
        <Route exact path={"/post/:u_id/:id"}>
          <ProtectedRoute Cmp={SinglePost} />
        </Route>
        <Route exact path={"/alert-category/:slug"}>
          <ProtectedRoute Cmp={AlertCategory} />
        </Route>
        <Route exact path={"/alerts/category/admin"}>
          <ProtectedRoute Cmp={AlertFilterAdmin} />
        </Route>
        <Route exact path={"/post-category/:username/:slug"}>
          <ProtectedRoute Cmp={PostCategory} />
        </Route>
        <Route exact path={"/alert-tag/:slug"}>
          <ProtectedRoute Cmp={AlertTags} />
        </Route>
        <Route exact path={"/tag/:slug/posts"}>
          <ProtectedRoute Cmp={PostTags} />
        </Route>

        <Route exact path={"/profile2"}>
          {/* <Route exact path={"/profile/:user_name"}> */}
          <ProtectedRoute Cmp={Userprofile} />
        </Route>

      

        <Route exact path={"/support"}>
          <ProtectedRoute Cmp={RecordScreen} />
        </Route>
        <Route exact path={"/technical-support2"}>
          <ProtectedRoute Cmp={RecordScreen2} />
        </Route>
        <Route exact path={"/my-location"}>
          <ProtectedRoute Cmp={WatchLocation} />
        </Route>

        <Route exact path={"/alert-location"}>
          <ProtectedRoute Cmp={AlertLocation} />
        </Route> 

       <Route exact path={"/alert-location2"}>
          <ProtectedRoute Cmp={AlertLocation2} />
        </Route>

        {/* <Route exact path={"/profile2"}> */}
        <Route exact path={"/profile/:user_name"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/alerts"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/home"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/pages"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/channels"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/pictures"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/movies"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/saved_posts"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        
        <Route exact path={"/profile/:userName/soscontacts"}>
          <ProtectedRoute Cmp={SOSContact} />
        </Route>
        <Route exact path={"/profile/:userName/closefriends"}>
          {/* <Route exact path={"/profile/:user_name"}> */}
          <ProtectedRoute Cmp={CloseFriends} />
        </Route>
        <Route exact path={"/profile/:user_name/saved_alerts"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/starredMovies"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/watchlist"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/subscriptions"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/referrals"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>
        <Route exact path={"/profile/:user_name/points"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route>

        {/* <Route exact path={"/profile"}>
          <ProtectedRoute Cmp={Authorpage} />
        </Route> */}

      <Route exact path={"/my-subscriptions"}>
          <ProtectedRoute Cmp={AlarrtSubscription} />
        </Route>

      <Route exact path={"/my-requests"}>
          <ProtectedRoute Cmp={AlarrtSubscription} />
        </Route>

        <Route exact path={"/admin/subscriptions"}>
          <ProtectedRoute Cmp={UserSubscriptions} />
        </Route>
        
        <Route exact path={"/admin/requests"}>
          <ProtectedRoute Cmp={UserSubscriptions} />
        </Route>

        <Route exact path={"/badges"}>
          <ProtectedRoute Cmp={Badge} />
        </Route>
        <Route exact path={"/search"}>
          <ProtectedRoute Cmp={SearchBarV2} />
        </Route>
        <Route exact path={"/channels"}>
          <ProtectedRoute Cmp={Channels} />
        </Route>
        <Route exact path={"/channels2"}>
          <ProtectedRoute Cmp={ChannelsOld} />
        </Route>

        <Route exact path="/channel/admin/:slug">
          <ProtectedRoute Cmp={SingleChannelAdmin} />
        </Route>

        <Route exact path={"/channel/:slug"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/series"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/subscribers"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/videos"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/video"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/movies"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/movie"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/details"}>
          <ProtectedRoute Cmp={ChannelPage} />
        </Route>
        <Route exact path={"/channel/:slug/update"}>
          <ProtectedRoute Cmp={ChannelUpdate} />
        </Route>
        <Route exact path={"/create_channel"}>
          <ProtectedRoute Cmp={CreateChannel} />
        </Route>
        {/* <Route exact path={"/chillax/:id/movies"}>
          <ProtectedRoute Cmp={Movies} />
        </Route> */}
        <Route exact path={"/videos"}>
          <ProtectedRoute Cmp={Chillarx} />
        </Route>
        <Route exact path={"/chillax"}>
          <ProtectedRoute Cmp={Chillarx} />
        </Route>
        <Route exact path={"/headlines"}>
          <ProtectedRoute Cmp={AllNews} />
        </Route>
        <Route exact path={"/movies2"}>
          <ProtectedRoute Cmp={SingleMovie2} />
        </Route>
        {/* <Route exact path={"/channel/:url/:slug"}>
          <ProtectedRoute Cmp={SingleMovie} />
        </Route> */}
        <Route exact path={"/channel/:slug/:url/:slug"}>
          <ProtectedRoute Cmp={SingleMovie} />
        </Route>
        {/* <Route exact path={"/channel/series/episode/:slug/:url/:slug"}> */}
        <Route exact path={"/channel/:slug/series/episode/:slug"}>
          <ProtectedRoute Cmp={SingleEpisode} />
        </Route>
        <Route exact path={"/home-movie"}>
          <ProtectedRoute Cmp={HomeMovie} />
        </Route>
        <Route exact path={"/groups"}>
          <ProtectedRoute Cmp={Group} />
        </Route>
        <Route exact path={"/pages"}>
          <ProtectedRoute Cmp={AllPages} />
        </Route>
         
        <Route exact path={"/page/:slug"}>
          <ProtectedRoute Cmp={PageSingle} />
        </Route>
        <Route exact path={"/create_page"}>
          <ProtectedRoute Cmp={CreatePage} />
        </Route>

        <Route exact path={"/communities"}>
          <ProtectedRoute Cmp={Communities} />
        </Route>

        <Route exact path={"/community/:slug"}>
          <ProtectedRoute Cmp={CommunityPage} />
        </Route>
       
        {/* <Route exact path={"/stories"}>
          <ProtectedRoute Cmp={Storie} />
        </Route> */}
        <Route exact path={"/explorefeed"}>
          <ProtectedRoute Cmp={ExploreFeed} />
        </Route>
        <Route exact path={"/chats"}>
          <ProtectedRoute Cmp={ChatList} />
        </Route>
        <Route exact path={"/chat/:user_name"}>
          <ProtectedRoute Cmp={Chat} />
        </Route>
        <Route exact path={"/defaultemailopen"}>
          <ProtectedRoute Cmp={Emailopen} />
        </Route>
        <Route exact path={"/settings"}>
          <ProtectedRoute Cmp={Settings} />
        </Route>
        {/* <Route exact path={"/videos"}>
          <ProtectedRoute Cmp={Videos} />
        </Route> */}
        <Route exact path={"/analytics"}>
          <ProtectedRoute Cmp={Analytics} />
        </Route>
        <Route exact path={"/profileinformation"}>
          <ProtectedRoute Cmp={Account} />
        </Route>
        <Route exact path={"/:userName/followers"}>
          <ProtectedRoute Cmp={Followers} />
        </Route>
        <Route exact path={"/:userName/following"}>
          <ProtectedRoute Cmp={Followings} />
        </Route>

        <Route exact path={"/sos-contacts"}>
          <ProtectedRoute Cmp={SOSContact} />
        </Route>
        <Route exact path={"/closefriends"}>
          
          <ProtectedRoute Cmp={CloseFriends} />
        </Route>
        {/* <Route exact path={"/members"}>
          <ProtectedRoute Cmp={Member} />
        </Route> */}
        <Route exact path={"/contactinformation"}>
          <ProtectedRoute Cmp={Contactinfo} />
        </Route>
        <Route exact path={"/social_accounts"}>
          <ProtectedRoute Cmp={Socialaccount} />
        </Route>

        <Route exact path={"/payment"}>
          <ProtectedRoute Cmp={Payment} />
        </Route>
        <Route exact path={"/notifications"}>
          <ProtectedRoute Cmp={Notification} />
        </Route>
        <Route exact path={"/oldnotifications"}>
          <ProtectedRoute Cmp={OldNotification} />
        </Route>

        {/* <Route exact path={"/shop1"}>
          <ProtectedRoute Cmp={ShopOne} />
        </Route>
        <Route exact path={"/shop2"}>
          <ProtectedRoute Cmp={ShopTwo} />
        </Route>
        <Route exact path={"/shop3"}>
          <ProtectedRoute Cmp={ShopThree} />
        </Route>
        <Route exact path={"/singleproduct"}>
          <ProtectedRoute Cmp={Singleproduct} />
        </Route> */}
        {/* <Route exact path={"/cart"}>
          <ProtectedRoute Cmp={Cart} />
        </Route>
        <Route exact path={"/checkout"}>
          <ProtectedRoute Cmp={Checkout} />
        </Route> */}

        {/* <Route exact path={"/livefeed"}>
          <ProtectedRoute Cmp={Live} />
        </Route> */}

        <Route exact path={"/jobs"}>
          <ProtectedRoute Cmp={Job} />
        </Route>

        {/* <Route exact path={"/jobs"}>
          <ProtectedRoute Cmp={Job} />
        </Route>
        <Route exact path={"/events"}>
          <ProtectedRoute Cmp={Event} />
        </Route>
       
        <Route exact path={"/grouppage"}>
          <ProtectedRoute Cmp={Grouppage} />
        </Route> */}

<Route exact path={"/hotels"}>
          <ProtectedRoute Cmp={Hotel} />
        </Route>

        <Route exact path={"/comingsoon"}>
          <ProtectedRoute Cmp={Comingsoon} />
        </Route>
        <Route exact path={"/hoteldetails"}>
          <ProtectedRoute Cmp={Hotelsingle} />
        </Route>

        {/* Admin Routes */}
        {/* 
        <Route exact path={"/product/:productId"}>
          <ProtectedRoute Cmp={Product} />
        </Route>
        <Route exact path={"/newproduct"}>
          <ProtectedRoute Cmp={NewProduct} />
        </Route>
        <Route exact path={"/products"}>
          <ProtectedRoute Cmp={ProductList} />
        </Route> */}
        {/* <Route exact path={"/users"}>
          <ProtectedRoute Cmp={UserList} />
        </Route>
        <Route exact path={"/newUser"}>
          <ProtectedRoute Cmp={NewUser} />
        </Route>
        <Route exact path={"/user/:userId"}>
          <ProtectedRoute Cmp={User} />
        </Route> */}
        <Route component={Notfound} />

        {/* <Route exact path={"/h"}>
                        <ProtectedRoute Cmp = {Home} />
                    </Route>
                      <Route exact path={"/h"}>
                        <ProtectedRoute Cmp = {Home} />
                    </Route> */}
      </Switch>

      {showSnack ? (
                <>
                  <Snackbar
                    open={showSnack}
                    autoHideDuration={8000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  >
                    <Alert onClose={handleClose} severity="warning">
                    Welcome! Please login first. You'll be redirected afterwards
                    </Alert>
                  </Snackbar>
                </>
              ) : (
                ""
              )}
      {/* </React.Suspense> */}
      {/* </Suspense> */}
    </BrowserRouter>
  );
  // }
}

// ReactDOM.render(<Root/>, document.getElementById('root'));
// serviceWorker.register();
