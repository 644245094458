// import React, { Component , Fragment } from "react";
import React, { Component, useEffect, Fragment, useState } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import { Select } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Load from "../components/Load";
import { format, formatDistance, subDays } from "date-fns";
import { useHistory, useParams } from "react-router";
import ShowMoreText from "react-show-more-text";
import ImageComponent from "../components/ImageComponent";
// import ImageComponent from "./ImageComponent";

const notiList = [
  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "12 minute ago",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Victor Exrixon",
    status: "feather-thumbs-up bg-primary-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "45 minute ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    status: "feather-thumbs-up bg-primary-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "1 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Goria Coast",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "2 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "5 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "David Goria",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "12 hour ago",
    read: "",
  },

  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "12 minute ago",
    read: "bg-lightblue theme-light-bg",
  },
  {
    imageUrl: "user.png",
    name: "Victor Exrixon",
    status: "feather-thumbs-up bg-primary-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "45 minute ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    status: "feather-thumbs-up bg-primary-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "1 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Goria Coast",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "2 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "Hurin Seary",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "5 hour ago",
    read: "",
  },
  {
    imageUrl: "user.png",
    name: "David Goria",
    status: "feather-heart bg-red-gradiant",
    subject: "Mobile App Design",
    des: "UI/UX Community : Mobile Apps UI Designer is required for Tech… ",
    attach: "attach",
    time: "12 hour ago",
    read: "bg-lightblue theme-light-bg",
  },
];

export default function Notification() {
  const [isActive, setIsActive] = useState("");
  const [unReadnotifications, setUnReadNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [fetchingNotifications, setFetchingNotifications] = useState(false);
  let history = useHistory();

  async function getUnreadNotifications() {
    setFetchingNotifications(true);
    console.log("fetching notifications");
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    axiosFetch
      .get(
        `/users/show/${user_id}/all_notifications`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log(`alerts list ${JSON.stringify(resp.data[0].created_at)}`);
        setUnReadNotifications(resp.data.unreadNotifications);
        setAllNotifications(resp.data.notifications);
        setFetchingNotifications(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function silentGetUnreadNotifications() {
    setFetchingNotifications(true);
    console.log("fetching notifications");
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    axiosFetch
      .get(
        `/users/show/${user_id}/all_notifications`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        alert(`refreshed notifications ${resp.data}}`);
        setUnReadNotifications(resp.data.unreadNotifications);
        setAllNotifications(resp.data.notifications);
        setFetchingNotifications(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

   async function MarkAsRead() {
    setFetchingNotifications(true);
    console.log("fetching notifications");
    const access_token = await window.localStorage.getItem("access_token");
    const user_id = await window.localStorage.getItem("user_id");
    axiosFetch
      .get(
        `/users/show/${user_id}/read_notifications`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        
        getUnreadNotifications()
        setFetchingNotifications(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  // setCasts(res.cast.slice(0, 5));
  useEffect(() => {
    getUnreadNotifications();
  }, []);

  if (fetchingNotifications == true) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12">
                  <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                    <Load />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
  
  if (allNotifications.length == 0) {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12">
                  <div className="chat-wrapper p-3 w-100 position-relative scroll-bar bg-white theme-dark-bg">
                   <p className="text-primary text-center">Seems you have no new unread notification</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }

  else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content theme-dark-bg right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left">
              <div className="row">
                <div className="col-lg-12 vh-300">
                  <div className="chat-wrapper p-3 w-100 vh-500 bg-white theme-dark-bg" style={{overflow:'auto'}}>
                  {/* <div className="flex items-center justify-between py-3">
              <div className="flex flex-1 items-center space-x-4"> */}
                    
                    <h2 className="fw-700 mb-4 mt-2 font-md text-grey-900 d-flex align-items-center">
                      {unReadnotifications} UnRead Notifications
                      <span className="circle-count bg-success text-white font-xsssss rounded-3 ms-2 ls-3 fw-600 p-2  mt-0">
                        {unReadnotifications}
                      </span>
                      {/* <div
                      onClick={() => silentGetUnreadNotifications()}
                        // onclick={silentGetUnreadNotifications}
                        className="ms-2 btn-round-sm bg-greylight rounded-3"
                      >
                        <i className="feather-refresh-cw font-xss text-current-500"> Refresh</i>
                      </div> */}
                      {/* <a
                        href="/notifications"
                        className="ms-auto btn-round-sm bg-greylight rounded-3"
                      >
                        <i className="feather-hard-drive font-xss text-grey-500"></i>
                      </a>
                      <a
                         onClick={() => silentGetUnreadNotifications()}
                        className="ms-2 btn-round-sm bg-greylight rounded-3"
                      >
                        <i className="feather-x-square font-xss text-grey-500"></i>
                      </a> */}
                      <a
                         onClick={() => MarkAsRead()}
                        className="ms-2 btn-round-sm bg-greylight rounded-3"
                      >
                        <i className="feather-x-circle font-xs text-primary-500"></i>
                      </a>
                      <a
                       onClick={() => MarkAsRead()}
                      >
                        <p className="font-xsss text-primary-500">Mark All As Seen</p>
                      </a>
                    </h2>

                    <ul className="notification-box">
                      {allNotifications.map((notification, index) => (
                        <li key={index}>
                          <a
                            className={`d-flex align-items-center p-3 rounded-3 `}
                            // bg-lightblue theme-light-bg
                          >
                            {/* <ImageComponent
                              image={notification.data.user.profile_pic}
                              class="w45 me-3"
                            /> */}
                            {/* <Link to={`/profile/${notification.data.user.user_name}`} > */}
                            <span onClick={() => history.push(`/profile/${notification.data.user.user_name}`)}>
                            <img src={notification.data.user.profile_pic} alt="user" className="w45 me-3 rounded-circle" />
                            </span>
                            {/* </Link> */}
                            <i
                              className={`text-white me-2 font-xssss notification-react `}
                            ></i>
                            <h6 className="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-500 lh-20">
                              <strong>{notification.data.head}</strong>{" "}
                              {notification.data.data}
                              <span className="d-block text-grey-500 font-xssss fw-600 mb-0 mt-0 0l-auto">
                                {" "}
                                {formatDistance(
                                  new Date(notification.created_at),
                                  new Date(),
                                  { addSuffix: true }
                                )}
                              </span>{" "}
                            </h6>


<br/>
                        {!notification.data.link ?
                      ''
                      : 
                      // <div style={{justifyContent:'flex-end'}}>
                        <a
                        // className=" d-flex flex-end  bg-success font-bold hover:bg-success hover:text-white inline-block items-center lg:block max-h-10 mr-4 px-4 py-2 rounded shado text-white"
                        // className="ms-auto pointer flex flex-end ti-more-alt text-grey-500 font-xs"
                        href={`/${notification.data.link}`}
                        // onClick={() => Follow(post.post.user.user_name)}
                        // style={{ marginTop: -8 }}
                        // className="border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                       
                      {notification.data.text}
                      </a>
                      // </div>
                       }
                            {/* <i className="ti-more-alt text-grey-500 font-xs ms-auto"></i> */}
                          </a>
                               
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Notification;
