import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ChannelDetails from "./ChannelDetails";
import Profilephoto from "./Profilephoto";
import UserChannels from "./UserChannels";
// import ProfileTabs from "./ProfileTabs";
import Events from "./Events";
import Createpost from "./Createpost";
import UserPosts from "./UserPosts";
import UserAlerts from "./UserAlerts";
import MovieGrid from "../moviesComponents/movie-grid/MovieGrid";
import SeriesGrid from "../moviesComponents/movie-grid/SeriesGrid";
import SeriesCardChannels from "../moviesComponents/movie-card/SeriesCardChannels";
import ChannelPosts from "./ChannelPosts";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import Load from "./Load";

export default function ChannelsTabs(props) {
  const [key, setKey] = useState("details");
  const { slug } = useParams(["slug"]);
  const [channels, setChannels] = useState([]);
  const [channelId, setChannelId] = useState('');
  const [channelMovies, setChannelMovies] = useState([]);
  const [channelVideos, setChannelVideos] = useState([]);
  const [channelSeries, setChannelSeries] = useState([]);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const userId = useState(window.localStorage.getItem("user_id"));
  const admin_id = props.admin.id;
  const location = useLocation();
  const path = location.pathname;
  let history = useHistory();
  const [fetchingChannel, setFetchingChannel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  // const [userPosts, setUserPosts] = useState([]);
  const handleClose = () => {
    // setOpen(false);
    setError(false);
    // setEmptyFields(false);
  };
  const [value, setValue] = React.useState("details");

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.push(`/channel/${slug}`)
    setValue(newValue);
    // e.prevent
    event.preventDefault();
    history.push(`/channel/${slug}/${newValue}`)
  };

  function getQuery() {
    const queryParams = new URLSearchParams(location.search);
    console.log(`hiii ${queryParams}`);
    const firstQuery = queryParams.delete("channel");
    const secondQuery = queryParams.delete("w");
    const thirdQuery = queryParams.delete("a");
    // setChannelSlug(firstQuery);
    // setMovieId(secondQuery);
    // setAdminId(thirdQuery);
  }

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if(path.includes('/detai')){
      setValue('details')
    }
    if(path.includes('/vide')){
      setValue('videos')
    }
    if(path.includes('/seri')){
      setValue('series')
    }
    if(path.includes('/movi')){
      setValue('movies')
    }
    // .slice(-3)
  };


  function closeError() {
    setError(false);
    console.log("closed");
  }

  async function getChannelDetailsLoad() {
    setFetchingChannel(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/channels/details/${slug}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        // console.log(data)
        // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
        setChannels(resp.data.channel);
        setChannelId(resp.data.channel.id);
        setChannelMovies(resp.data.channel.movies);
        setChannelVideos(resp.data.channel.videos);
        setChannelSeries(resp.data.channel.series);
        setFetchingChannel(false);
        // } else {
        //   setFetchingChannel(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        setFetchingChannel(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getChannelDetails() {
    // setFetchingProfile(true);
    // console.log(` 1 ${fetchingProfile}`)
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/channels/details/${slug}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // if (resp.data.status == "success") {
        const data = JSON.stringify(resp.data);
        // console.log(data)
        // console.log(`user:data 2 = ${JSON.stringify(resp.data.subscriptions)}`)
        setChannels(resp.data.channel);
        setChannelId(resp.data.channel.id);
        setChannelMovies(resp.data.movies);
        setChannelVideos(resp.data.videos);
        setChannelSeries(resp.data.series);
        setFetchingChannel(false);
        // } else {
        //   // setFetchingChannel(false);
        //   setError(true);
        //   setErrorMessage(resp.data.message);
        // }
      })
      .catch((error) => {
        // setFetchingChannel(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

 
  function callback(k) {
    console.log(k);
    setKey(k);
    {
      console.log(`hello ${key}`);
    }
    // history.replace({
    //   pathname: `${path}/series`,
    //   // search: "?id=5",
    //   // hash: "#react",
    //   // state: { fromPopup: true }
    // });
  }

  useEffect(() => {
    // getQuery()
    getChannelDetailsLoad();
    getPathname();

    // event.preventDefault();
    // history.push(`${path}/${newValue}`)
    // checkUserId();
  }, []);

  if (setFetchingChannel == true) {
    return (
      <>
        <Load />
      </>
    );
  } else {

  return (
    <>
      <div className="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
        {error ? (
          <div>
            <br />
            <Alert
              severity="error"
              onClose={handleClose}
              action={
                <>
                  <CancelIcon onClick={closeError} />
                </>
              }
            >
              There was an issue fetching channel details. Kindly refresh or
              check your network [ {errorMessage} ]
            </Alert>
            {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
            <br />
          </div>
        ) : (
          ""
        )}

        <Box sx={{ bgcolor: "background.paper" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", marginleft: 20  }}>
              <TabList
                // value={key}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                // centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
                style={{ marginleft: 20}}
              >
                {/* <Tabs centered> */}
                  <Tab value="details" label="Channel Details"></Tab>
                  <Tab value="videos" label="Videos"></Tab>
                  <Tab value="movies" label="Movies"></Tab>
                  <Tab value="series" label="Series"></Tab>
                {/* </Tabs> */}
              </TabList>
            </Box>

            <TabPanel value="details" style={{ marginRight: 30 }}>
              <br />
              <div className="row">
                <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0">
                  <ChannelDetails channel={props.channel} admin={props.admin} />
                  {/* <Profilephoto text="Latest Pictures" /> */}
                  {/* <Events /> */}
                </div>
                <div className="col-xl-8 col-xxl-9 col-lg-8">
                  <h5 className="text-center">
                    {" "}
                    View {channels.name} Latest Posts{" "}
                  </h5>
                  <ChannelPosts
                    posts={props.posts}
                    functionProp={props.functionProp}
                    user_name={`${channels.name} admin`}
                    user_id={props.adminId}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel value="videos">
              <br />
              {channelVideos ? (
                <>
                  <h5 className="text-center">
                    {" "}
                    {props.channel.name} has {channelVideos.length} videos.
                    Explore them below
                  </h5>
                  <br />
                  <MovieGrid
                    adminId={props.adminId}
                    content={channelVideos}
                    type="Video"
                    url="video"
                    channelSlug={props.channel.slug}
                  />
                </>
              ) : (
                <h5 className="text-center">
                  {" "}
                  {props.channel.name} has no videos yet
                </h5>
              )}
            </TabPanel>

            <TabPanel value="movies">
              {/* <br/> */}
              {channelMovies ? (
                <>
                  <h5 className="text-center">
                    {" "}
                    {props.channel.name} has {channelMovies.length} movies.
                    Explore them below
                  </h5>{" "}
                  <br />
                  <MovieGrid
                    adminId={props.adminId}
                    content={channelMovies}
                    type="Movie"
                    url="movie"
                    channelSlug={props.channel.slug}
                  />
                </>
              ) : (
                <h5 className="text-center">
                  {" "}
                  {props.channel.name} has no movies yet
                </h5>
              )}
            </TabPanel>

            <TabPanel value="series">
              {/* <br/> */}
              {channelSeries ? (
                <>
                  <h5 className="text-center">
                    {" "}
                    {props.channel.name} has {channelSeries.length} series.
                    Explore them below
                  </h5>{" "}
                  <br />
                  <SeriesCardChannels
                    series={channelSeries}
                    type="Series"
                    url={`series`}
                    // channelId={channels.id}
                    channelId={channelId}
                    channelSlug={props.channel.slug}
                    //  isAdmin="true"
                    adminId={props.adminId}
                    functionProp={() => getChannelDetails()}
                  />
                </>
              ) : (
                <h5 className="text-center">
                  {" "}
                  {props.channel.name} has no series yet
                </h5>
              )}

              {/* <SeriesGrid
              adminId={props.adminId}
              content={channelSeries}
              type="Series"
              url={`series`}
              channelSlug={props.channel.slug}
            /> */}
              {/* <MovieGrid adminId={props.adminId} content={channelSeries} type='Series' url={`series`} channelSlug={props.channel.slug} /> */}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </>
  );
          }
}
