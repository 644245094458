import React, { Component } from "react";
import { useState, useEffect } from "react";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory, useParams } from "react-router";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CancelIcon from "@mui/icons-material/Cancel";
import imageCompression from "browser-image-compression";
import Resizer from "react-image-file-resizer";
import Compressor from "compressorjs";
import { Upload } from "@aws-sdk/lib-storage";
import { Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  S3Client,
  S3,
  CompleteMultipartUploadCommand,
  AbortMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";

import Checkbox from "@mui/material/Checkbox";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Fab, Tooltip } from "@material-ui/core";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import VideoRecorder from "react-video-recorder";
import ReactPlayer from "react-player";

// import { Select } from "antd";

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     // position: "absolute",
//     position: "fixed",
//     bottom: 160,
//     right: 20,
//   },

// }));

export default function CallForHelp(props) {
  // const classes = useStyles();
  const [record, setRecord] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [checked, setChecked] = React.useState(true);
  const [showTags, setShowTags] = useState(true);
  const [showLocation, setShowLocation] = useState(false);
  const animatedComponents = makeAnimated();
  const [alertTags, setAlertTags] = useState([]);
  const [alertTagsName, setAlertTagsName] = useState("Call Me");
  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const ip_address = window.sessionStorage.getItem("ip");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const lat = window.sessionStorage.getItem("lat");
  const lng = window.sessionStorage.getItem("lon");

  const [alertId, setAlertId] = useState("");
  const [showUrl, setShowUrl] = useState(false);
  const [updatingAlert, setUpdatingAlert] = useState(false);

  const options = [
    { value: "robbery-attack", label: "Robbery Attack" },
    { value: "need-medical-help", label: "Need Medical Help" },
    { value: "", label: "Lagos State" },
    { value: "don't-call", label: "Dont Call" },
  ];
  const { Option } = Select;
  const alertTypeSelect = [
    { value: "fire", label: "Need Fire Rescue" },
    {
      value: "accident",
      label: "Need Medical Rescue (Accidents, Poisoning, Health Crises)",
    },
    {
      value: "security",
      label: "Need Security Rescue (Kidnap, Robbery, Assault)",
    },
  ];
  const handleModalClose = () => {
    setOpen(false);
    setCreated(false);
    setIsSuccess(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [body, setBody] = useState(
    "I Am In An Emergency And I Need A Phone First Aid Assistance!"
  );
  const [headline, setHeadline] = useState(
    "I Am In An Emergency And I Need A Phone First Aid Assistance!"
  );
  const [newCategory, setNewCategory] = useState("");
  const [creatingCat, setCreatingCat] = useState(false);
  const [createdCat, setCreatedCat] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [location, setLocation] = useState("");
  const [alertCategory, setAlertCategory] = useState("");
  const [alertCategoryName, setAlertCategoryName] = useState("");

  let history = useHistory();
  const bodyLocal = window.localStorage.getItem("body");
  const headlineLocal = window.localStorage.getItem("headline");

  const firstName = window.localStorage.getItem("first_name");
  const user_name = window.localStorage.getItem("user_name");
  const profile_pic = window.localStorage.getItem("profile_pic");
  const verified = window.localStorage.getItem("verified");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [compImages, setCompImages] = useState([]);
  const [files, setFiles] = useState([]);

  const [alertImage, setAlertImage] = useState([]);
  const [alertImageUrls, setAlertImageUrls] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [created, setCreated] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [sentVideo, setSentVideo] = useState(false);
  const [isLimit, setIsLimit] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [emptyBody, setEmptyBody] = useState(false);
  const [video, setVideo] = useState("");
  const [audio, setAudio] = useState("");
  const [uploadingVideo, setUploadingVideo] = useState(false);
  const [uploadLimit, setUploadLimit] = useState(false);
  const [locationWarning, setLocationWarning] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [creatingMessage, setCreatingMessage] = useState("");
  const [videoPercent, setVideoPercent] = useState("");
  const [invalidFile, setInvalidFile] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage2, setErrorMessage2] = useState("");
  const [sendToFriends, setSendToFriends] = useState("no");

  const [alertInfo, setAlertInfo] = useState("");

  const [error, setError] = useState(false);
  const loopData = [];

  const Input = styled("input")({
    display: "none",
  });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setEmptyBody(false);
    setCreatedCat(false);
    setCreated(false);
    setError(false);
    setLocationWarning(false);
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function getLastAlert() {
    setCreatingPost(true);
    const access_token = window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get("/alerts/get_last", {
        method: "GET",
        // withCredentials: true,
        headers: {
          // withCredentials = true,
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        // alert(resp.data);
        setAlertId(resp.data);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function sendRequest() {
    const access_token = await window.localStorage.getItem("access_token");
    const formData = new FormData();
    formData.append("type", "phone_aid");
    formData.append("description", "Request for phone call first aid");
    formData.append("status", "requested");

    let result = await axiosFetch
      .post(`/users/add/request`, formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setCreated(true);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  async function createAlert() {
    if (!lat || !lng) {
      //   alert("Creating Your Alarrt Without GPS Location");
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Lat is :", position.coords.latitude);
        console.log("Lon is :", position.coords.longitude);
        window.sessionStorage.setItem("lat", position.coords.latitude);
        window.sessionStorage.setItem("lon", position.coords.longitude);
      });
      // return;
    }
    // else {
    setCreatingPost(true);
    // setCreatingMessage("Sending Your Alarrt, Stay Calm");
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    formData.append("user_id", userID);
    formData.append("alert_category_id", 75);
    formData.append("body", body);
    formData.append("headline", headline);
    formData.append("tags", alertTagsName);
    // formData.append("url", url);
    formData.append("location", location);
    console.log("olumide!!!");
    for (let i = 0; i < images.length; i++) {
      formData.append("image[]", images[i]);
      console.log(`hjsimage ${JSON.stringify(images)}`);
    }

    formData.append("sendToFriends", sendToFriends);
    formData.append("type", "distress");
    formData.append("status", "sent_out");
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("watch_latitude", watch_latitude);
    formData.append("watch_longitude", watch_longitude);
    formData.append("ip_address", ip_address);

    console.log(video);
    let result = await axiosFetch
      .post("/alerts", formData, {
        headers: {
          Accept: "*/*",
          // "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        if (resp.data.status == "success") {
          localStorage.setItem("locId", resp.data.location);
          setCreatingPost(false);
          props.functionProp();
          props.functionProp2();
          // setHeadline("");
          // setBody("");
          setAlertTagsName("Call Me");

          setIsSuccess(true);
          setCreated(true);
          // setIsNext(true);
        } else {
          setError(true);
          setErrorMessage(resp.data.message);
          setCreatingPost(false);
          //   setCreated(false);
        }
      })
      .catch((error) => {
        setCreatingPost(false);
        console.log(error);
        setError(true);
        setErrorMessage2(error.message);
      });
    // } else ends
  }

  function getLocation() {
    if ("geolocation" in navigator) {
      console.log("Location is Available");
    } else {
      console.log("Location is Not Available");
    }
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Lat is :", position.coords.latitude);
      console.log("Lon is :", position.coords.longitude);
      window.sessionStorage.setItem("lat", position.coords.latitude);
      window.sessionStorage.setItem("lon", position.coords.longitude);
    });

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("full posoition " + position);
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }

  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        console.log("Watch Lat is :", position.coords.latitude);
        console.log("Watch Lon  is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
  }

  useEffect(() => {
    getLocation();
    watchLocation();
  }, [console.log(" headline -> ", headline, "body is -> ", body)]);

  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
      <div className="card-body p-0 text-center">
        {/* <h3 className="fw-600 text-grey-900 font-xssss mt-0 mb-3 me-4">
          Hi {firstName}! What Is Happening?
        </h3> */}

        <Tooltip
          title="Call For Help"
          aria-label="add"
          // onClick={handleClickOpen}
          onClick={() => {
            handleClickOpen();
            // getLocation();
            // watchLocation();
          }}
        >
          <Fab
            color="secondary"
            style={{
              zIndex: 30,
              width: 100,
              height: 100,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {/* <img src="/assets/images/alert-fab3.webp" /> */}
            <img src="/assets/images/alert-fab3.webp" />
            {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
          </Fab>
        </Tooltip>
        <br />
        <br />
        <h3 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
          {" "}
          Click To Call For Phone First Aid Help
        </h3>
      </div>

      <Dialog open={open} onClose={handleModalClose}>
        <DialogTitle>
          {" "}
          Call For Phone First Aid Help If In An Emergency.
        </DialogTitle>
        <DialogContentText
          style={{ paddingRight: 20, paddingLeft: 20, paddingTop: 30 }}
        >
          
            <h4 className="fw-500 font-xss text-grey-500">
              {props.status.status} {props.status.days}
            </h4>
            <a className='text-center' href='/my-subscriptions' > View Subscription</a>
        
        </DialogContentText>
        <DialogContent>
          <br />

          <div>
            <h4 className="text-center">
              Proceed Only If In An Emergency {firstName}.
            </h4>

            <div className="mt-1">
              {/* <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3"> */}
              <div className="card-body text-center">
                <Tooltip
                  title="Call Now"
                  aria-label="add"
                  // onClick={handleClickOpen}
                  onClick={() => {
                    getLocation();
                    watchLocation();
                    //   alert('hii');
                    sendRequest();
                  }}
                >
                  <a href="Tel: 080002255372">
                    <Fab
                      color="secondary"
                      style={{
                        zIndex: 30,
                        width: 100,
                        height: 100,
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      {/* <img src="/assets/images/alert-fab3.webp" /> */}
                      <img src="/assets/images/alert-fab3.webp" />
                      {/* <Bolt style={{ fontSize: 30, zIndex:2 }} /> */}
                    </Fab>
                  </a>
                </Tooltip>
                {/* </div> */}
              </div>
              <br />
              <br />
              <h5 className="fw-600 text-primary text-center font-xss mt-0 mb-3 me-4">
                {" "}
                Click The Button Above To Place Your Call
              </h5>
            </div>
          </div>

          {created ? (
            <>
              <br />
              <Alert variant="filled" onClose={handleClose} severity="success">
                Hello {firstName}! We've recieved your request for a phone-call
                first aid. We hope you were able to get the assistance you
                needed from ERA? A member of our team will be in touch soon.
                {/* <b>
                  Please if you are moving from your current location, Please
                  refresh the app at interval so it updates your new location.
                </b> */}
              </Alert>
            </>
          ) : (
            ""
          )}
        </DialogContent>

        <br></br>
        <br />

        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalClose}
            // style={{ marginLeft: 15 }}
          >
            Go Back Home
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
// }

// export default CreatePost2;
