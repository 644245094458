import React, {
  Component,
  Fragment,
  useMemo,
  useState,
  useEffect,
} from "react";
import {
  Map,
  GoogleApiWrapper,
  Marker,
  // Circle,
  InfoWindow,
  Polyline,
  // Listing,
  Polygon,
} from "google-maps-react";
import {
  // GoogleMap,
  // Marker,
  DirectionsRenderer,
  Circle,
  // MarkerClusterer,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";

const mapStyles = {
  width: "100%",
  height: "80%",
};

const defaultOptions = {
  strokeOpacity: 0.5,
  strokeWeight: 2,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
};
const closeOptions = {
  ...defaultOptions,
  zIndex: 3,
  fillOpacity: 0.05,
  strokeColor: "#8BC34A",
  fillColor: "#8BC34A",
};
const middleOptions = {
  ...defaultOptions,
  zIndex: 2,
  fillOpacity: 0.05,
  strokeColor: "#FBC02D",
  fillColor: "#FBC02D",
};
const farOptions = {
  ...defaultOptions,
  zIndex: 1,
  fillOpacity: 0.05,
  strokeColor: "#FF5252",
  fillColor: "#FF5252",
};

const AdminLocation = ({ text }) => (
  <div
    style={{
      color: "white",
      background: "grey",
      padding: "15px 10px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

const svgMarker = {
  path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
  fillColor: "blue",
  fillOpacity: 0.6,
  strokeWeight: 0,
  rotation: 0,
  scale: 2,
  // anchor: window.google.maps.Point(15, 30),
};


class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.locationName,
      lat: this.props.latitude,
      long: this.props.longitude,
      currentLat: this.props.currentLat,
      currentLong: this.props.currentLong,
      showingInfoWindow: false, // Hides or shows the InfoWindow
      activeMarker: {}, // Shows the active marker upon click
      selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
      adminLat: parseFloat(window.sessionStorage.getItem("wat_lat")),
      adminLng: parseFloat(window.sessionStorage.getItem("wat_lon")),
      adminCords: {
        lat: parseFloat(window.sessionStorage.getItem("wat_lat")),
        lng: parseFloat(window.sessionStorage.getItem("wat_lon"))
      },
      currentCords: {
        lat: parseFloat(this.props.currentLat),
        lng: parseFloat(this.props.currentLong)
      },
      directions: "",
      flightPlanCoordinates: [
        { lat: parseFloat(window.sessionStorage.getItem("wat_lat")), lng: parseFloat(window.sessionStorage.getItem("wat_lon"))},
        { lat: parseFloat(this.props.currentLat), lng: parseFloat(this.props.currentLong) },
        
      ],
    };
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  fetchDirections = (adminCords) => {
    // if (!this.state.currentCords) return;

    const service = new window.google.maps.DirectionsService();
    console.log('getting driectuionn ,.....')
    service.route(
      {
        origin: this.state.adminCords,
        destination: this.state.currentCords,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        console.log(result, status)
        if (status === "OK" && result) {
          this.setState({ directions: result });

          // setDirections(result);
        }
      }
    );
  };

 

  //   ),
  //   []
  // );

  render() {
    return (
      <Fragment>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDkht3ZHPVLDFsmE41PnvZUyHiYzuY0b8s&libraries=geometry"></script>
        {/* <Header /> */}
        <div>
          <p>using class own oooo</p>
          <Map
            google={this.props.google}
            zoom={14}
            mapId="a4e2da29cdf9cd48"
            // options={options}
            onClick={this.onMapClicked}
            style={mapStyles}
            zoomControl={true}
            mapTypeControl={true}
            scaleControl={true}
            streetViewControl={true}
            panControl={true}
            rotateControl={true}
            fullscreenControl={true}
            initialCenter={{
              lat: this.state.adminLat,
              lng: this.state.adminLng,
            }}
          >
             <Polyline
                path={this.state.flightPlanCoordinates}
                strokeColor="#0000FF"
                strokeOpacity={0.8}
                strokeWeight={2} />
            {/* <AdminLocation lat={6.45041} lng={3.43533} text="My Marker" /> */}

            <Polygon
              paths={this.state.flightPlanCoordinates}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#0000FF"
              fillOpacity={0.35}
            />
          
            {console.log('state directions is '+ this.state.directions)}
            {this.state.directions && (
               
              <DirectionsRenderer
                directions={this.state.directions}
                options={{
                  polylineOptions: {
                    zIndex: 50,
                    strokeColor: "#1976D2",
                    strokeWeight: 5,
                  },
                }}
              />
            )}

            <Marker
              position={{ lat: this.state.adminLat, lng: this.state.adminLng }}
              onClick={this.onMarkerClick}
              name={"Admin location " + this.state.name}
              label="You"
              // icon={{

              //   url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
              //   // url: "/assets/images/not-found.png",
              //   anchor: window.google.maps.Point(12, 12),
              //   scaledSize: window.google.maps.Size(12, 12),
              //   // scaledSize: new google.maps.Size(64,64)
              // }}
            />

            <Marker
              // position={{ lat: 6.636154, lng: 3.8769831 }}
              position={{ lat: this.state.lat, lng: this.state.long }}
              onClick={this.onMarkerClick}
              // onClick={() => {
              //   this.fetchDirections(this.state.adminCords);
              // }}
              name={"Previous location " + this.state.name}
              icon={svgMarker}
              label="Pre"
              // icon={{
              //   url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
              //   anchor: window.google.maps.Point(12, 12),
              //   scaledSize: window.google.maps.Size(12, 12),
              //   // scaledSize: new google.maps.Size(64,64)
              // }}
              // name={this.state.selectedPlace.name}
            />

            <Marker
              position={{
                lat: this.state.currentLat,
                lng: this.state.currentLong,
              }}
              // onClick={this.onMarkerClick}
              onClick={() => {
                this.fetchDirections(this.state.adminCords);
              }}
              name={"Current location " + this.state.name}
              icon={{
                url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
                anchor: window.google.maps.Point(12, 12),
                scaledSize: window.google.maps.Size(12, 12),
                // scaledSize: new google.maps.Size(64,64)
              }}
              // name={this.state.selectedPlace.name}
            />

            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
            >
              <div>
                <h1>{this.state.selectedPlace.name}</h1>
                {/* <h4>{'marker name '+this.state.name}</h4> */}
                {/* {this.state.selectedPlace.name}</h4> */}
              </div>
            </InfoWindow>

            <Circle
              radius={5000}
              center={this.state.adminCords}
              onMouseover={() => console.log("mouse over on circle")}
              onClick={() => console.log("click circle")}
              onMouseout={() => console.log("mouseout from circle")}
              strokeColor="transparent"
              strokeOpacity={0}
              strokeWeight={5}
              fillColor="#FF0000"
              fillOpacity={0.05}
              zIndex= {1}
            />

            <Circle
             center={this.state.adminCords}
              radius={10000}
              options={closeOptions}
            />
            <Circle
              center={this.state.adminCords}
              radius={30000}
              options={middleOptions}
            />
            <Circle
             center={this.state.adminCords}
              radius={50000}
              options={farOptions}
            />
          </Map>
        </div>
        <Appfooter />
      </Fragment>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS_API_KEY,
})(MapContainer);
