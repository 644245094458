import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Appfooter from "../components/Appfooter";

import SingleAlertComponent from "../components/SingleAlertComponent";
import axiosFetch from "../Interceptors2";

import Load2 from "../components/Load2";
import { Route, useParams, useLocation } from "react-router-dom";
import CreateAlertFab from "../components/CreateAlertFab";

// import { useParams } from 'react-router-dom';

// class Authorpage extends Component {
export default function SingleAlertGuest() {
  const [alerts, setAlerts] = useState([]);
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const [fetchingAlerts, setFetchingAlerts] = useState(false);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  console.log(`hiii ${queryParams}`);
  console.log(location.pathname);

  const alertId = queryParams.get("i");

  const { slug } = useParams(["slug"]);

  async function getAlertLoad() {
    setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/guest/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        setFetchingAlerts(false);
        console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getAlert() {
    // setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/guest/${alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        // setFetchingAlerts(false);
        // console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    // getProfile();
    getAlertLoad();
  }, []);

  if (alerts.length == []) {
    console.log(`final ${fetchingAlerts}`);
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"> */}
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-xl-12">
                {userName ? (
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ marginLeft: 10 }}
                  >
                    Welcome {userName}! Fetching the Alarrt.
                  </h3>
                ) : (
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ marginLeft: 10 }}
                  >
                    Welcome Vistor! Fetching the Alarrt.
                  </h3>
                )}

                <Load2 />
              </div>
            </div>
            {/* </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        {/* <Header /> */}
        {/* <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0"> */}
        <div className="card w-100 bg-white shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <div className="row">
              <div className="col-xl-12">
                {userName ? (
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ marginLeft: 10 }}
                  >
                    Welcome {userName}! you are viewing this Alarrt as a
                    visitor.{" "}
                    <a
                      href={`/alert/${alerts.alert.slug}?i=${alerts.alert.id}`}
                      
                      className='text-primary'
                    >
                      Click Here
                    </a>{" "}
                    to view as an ALARRT user / add comments
                  </h3>
                ) : (
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ marginLeft: 10 }}
                  >
                    Welcome Visitor! Seems you're not yet at ALARRT. 
                    <a href="/signup"  className='text-primary'> Signup </a> OR{" "}
                    <a href="/signin"  className='text-primary'> Login </a> to start receiving real-time safety alarrts near you.
                  </h3>
                )}
                <SingleAlertComponent
                  alert={alerts}
                  functionProp={() => getAlert()}
                  isGuest="true"
                />

                {userName ? (
                  <>
                    <h3
                      className="middle-wrap fw-600 text-grey-900"
                      style={{ marginLeft: 10 }}
                    >
                      You are viewing this alarrt as a visitor {userName}
                      <a
                        href={`/alert/${alerts.alert.slug}?i=${alerts.alert.id}`}
                        
                        className='text-primary'
                      >
                        {" "}
                        Click here{" "}
                      </a>{" "}
                      to add comments
                    </h3>
                  </>
                ) : (
                  <h3
                    className="middle-wrap fw-600 text-grey-900"
                    style={{ marginLeft: 10 }}
                  >
                     Stay safe on the go! <a href="/signup"  className='text-primary'> Signup </a> OR{" "}
                    <a href="/signin"  className='text-primary'> login </a> to stay Alarrt!
                  </h3>
                )}
              </div>
            </div>
           
            {/* </div>
              </div>
            </div> */}
          </div>
        </div>
        <div style={{marginBottom: 150}}></div>
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}
