import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";

import axios from "axios";
import axiosFetch from "../Interceptors2";
import { Button, TextField, Paper, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Appfooter from "../components/Appfooter";
import Header from "../components/Header";
import Load2 from "./Load2";
import SingleAlertComponent from "./SingleAlertComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import AlertLocation from "./AlertLocation";
import AlertLocationCurrent from "./AlertLocationCurrent";
import AlertLocationV2 from "./AlertLocationV2";
import Select from "react-select";
import makeAnimated from "react-select/animated";

export default function SingleAlertAdmin(props) {
  //   const latitude = window.sessionStorage.getItem("lat");
  //   const longitude = window.sessionStorage.getItem("lon");
  //   const watch_latitude = window.sessionStorage.getItem("wat_lat");
  //   const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const firstName = window.localStorage.getItem("first_name");
  const [alerts, setAlerts] = useState([]);

  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const [deactivating, setDeactivating] = useState(false);
  const [notifying, setNotifying] = useState(false);
  const [deactivated, setDeactivated] = useState(false);
  const [status, setStatus] = useState("");
  const [update, setUpdate] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updated, setUpdated] = useState(false);
  const adminLat = window.sessionStorage.getItem("wat_lat");

  const animatedComponents = makeAnimated();
  const location = useLocation();
  const options = [
    { value: "verified", label: "Verified" },
    { value: "unverified", label: "Un Verified" },
    { value: "inactive", label: "In Active" },
    { value: "active", label: "Active" },
    { value: "on_route", label: "On Route" },
    { value: "sent_out", label: "Sent Out" },
    { value: "resolved", label: "Resolved" },
  ];

  async function getAlertLoad() {
    setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${props.alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        setFetchingAlerts(false);
        console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function changeStatus(value) {
    console.log(`selected ${JSON.stringify(value.value)}`);
    setStatus(value.value);
    setUpdate(true);
  }

  async function getAlert() {
    // setFetchingAlerts(true);
    console.log(` 1 ${fetchingAlerts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(`/alerts/show/single/${props.alertId}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setAlerts(resp.data);
        console.log(JSON.stringify(resp.data));
        // setFetchingAlerts(false);
        // console.log(` 2 ${fetchingAlerts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(alerts.length)
        // console.log(`alerts list ${this.state.alerts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function makeInactive(id) {
    setDeactivating(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/alerts/disable/${id}`, id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setDeactivating(false);
        setDeactivated(true);
        // console.log(resp.data);
        getAlertLoad();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function sendNotiifications(id) {
    setNotifying(true);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/alerts/send_notifications/${id}`, id, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        alert(
          "notifications have been sent out! You should get one soon. Try again only if you don't get any in the next 5 minutes "
        );
        setNotifying(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function updateStatus(id) {
    setIsUpdating(true);
    setUpdate(false);
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    const formData = new FormData();

    formData.append("status", status);

    axiosFetch
      .post(`/alerts/update/status/${id}`, formData, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setIsUpdating(false);
        setUpdate(true);
        setUpdated(true);
        // console.log(resp.data);
        getAlert();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  function closeSnack() {
    setDeactivated(false);
    setUpdated(false);
  }

  // async function updateStatus(){
  //   alert('updating status')
  //   setIsUpdating(true)
  //   setUpdate(false)
  // }

  useEffect(() => {
    getAlertLoad();
  }, [console.log("status is " + status)]);

  if (fetchingAlerts == true) {
    console.log(`final ${fetchingAlerts}`);
    return <>Loading ...</>;
  }
  if (alerts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Loading Alert...
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <div style={{ margin: 20 }}>
        <h3>
          {" "}
          Welcome Admin {firstName}! You Are Viewing This Alert Info As An Admin{" "}
        </h3>
        {deactivating ? (
          <Button disabled variant="contained" color="primary">
            Deactivating ..
          </Button>
        ) : (
          <Button
            // disabled
            variant="contained"
            color="primary"
            // href="/"
            // target="_blank"
            onClick={() => makeInactive(alerts.alert.id)}
            // onClick={makeInactive}
          >
            Make Inactive
          </Button>
        )}
        <br />
        <br />
        <Button
          // disabled
          variant="contained"
          color="primary"
          href="/alerts/category/admin"
          // target="_blank"

          // onClick={makeInactive}
        >
          View Alert Categories
        </Button>
        <br />
        <br />
        <p>
          ALARRT ALL USERS BELOW. Only do this if the alert has been verified.
          Sender's detail is listed below. <br />
          Full Name : {alerts.alert.owner.first_name}{" "}
          {alerts.alert.owner.last_name} <br />
          Email is {alerts.alert.owner.email} <br />
          Phone is {alerts.alert.owner.phone_number} <br />
        </p>
        <a href={`/profile/${alerts.alert.owner.user_name}`}> View Profile</a>{" "}
        <br />
        <br />
        
        {notifying ? (
          <Button disabled variant="contained" color="primary">
            Sending Out Notifications ..
          </Button>
        ) : (
          <Button
            // disabled
            variant="contained"
            color="secondary"
            onClick={() => sendNotiifications(alerts.alert.id)}
          >
            Send Notifications To All Users
          </Button>
        )}
        <br />
        <br />
        <p>Update Alert Status Currently set to {alerts.alert.status}</p>
        <Select
          // isClearable
          components={animatedComponents}
          defaultValue={alerts.alert.status}
          options={options}
          onChange={changeStatus}
        />
        <br />
        {update ? (
          <Button
            // disabled
            variant="contained"
            color="primary"
            // href="/"
            // target="_blank"
            // onClick={updateStatus}
            onClick={() => updateStatus(alerts.alert.id)}
          >
            Update Status
          </Button>
        ) : (
          <>
            {isUpdating ? (
              <Button
                disabled
                variant="contained"
                color="primary"
                // href="/"
                // target="_blank"
                //  onClick={updateStatus}
              >
                Updating...
              </Button>
            ) : (
              ""
            )}
          </>
        )}
        <br />
        <SingleAlertComponent alert={alerts} functionProp={() => getAlert()} />
        <br />
        {deactivated ? (
          <Snackbar
            open={deactivated}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Deactivated Alert Successfully
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
        {updated ? (
          <Snackbar
            open={updated}
            autoHideDuration={3000}
            onClose={closeSnack}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Alert onClose={closeSnack} severity="success">
              Update Alert Status Successfully
            </Alert>
          </Snackbar>
        ) : (
          ""
        )}
      </div>
    );
  }
}
