import React, { Component, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Load from "./Load";
import ShowMoreText from "react-show-more-text";
import { format, formatDistance, subDays } from "date-fns";
import Chip from "@material-ui/core/Chip";

export default function CommunityDetails(props) {
  const [isFollowing, setIsFollowing] = useState(false);

  function executeOnClick(isExpanded) {
    // console.log(isExpanded);
  }
  // render() {
  return (
    <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3">
      <div className="card-body d-block p-4">
        <h4 className="fw-700 mb-3 font-xsss text-grey-900">About Community</h4>
        {/* <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{props.profile.bio}</p> */}
        <ShowMoreText
          /* Default options */
          lines={3}
          more="Read more"
          less="Show less"
          className="fw-500 text-black-900 lh-26 font-xssss w-100 mb-2"
          // className="content-css"
          anchorClass="my-anchor-css-class"
          onClick={executeOnClick}
          expanded={false}
          // width={480}
          truncatedEndingComponent={"... "}
        >
          {props.community.description}
          {/* {props.profile.bio} */}
        </ShowMoreText>
      </div>
      <div className="card-body border-top-xs d-flex">
        <i className="feather-lock text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-0">
          Type{" "}
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            Community membership is {props.community.type}
          </span>
        </h4>
      </div>

      {props.community.type == "approved" ||
        (props.community.type == "verified" && (
          <div className="card-body d-flex pt-0">
            <i className="feather-eye text-grey-500 me-3 font-lg"></i>
            <h4 className="fw-700 text-grey-900 font-xssss mt-0">
              Status{" "}
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                This community has been {props.community.type}
              </span>
            </h4>
          </div>
        ))}
      {props.community.type == "disabled" && (
        <div className="card-body d-flex pt-0">
          <i className="feather-eye text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">
            Status{" "}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              This Community Has Been Disabled
            </span>
          </h4>
        </div>
      )}
      {props.community.type == "created" && (
        <div className="card-body d-flex pt-0">
          <i className="feather-eye text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-0">
            Status{" "}
            <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
              This Community Was Recently Created And Hasn't Been Approved Yet.
            </span>
          </h4>
        </div>
      )}

      <div className="card-body d-flex pt-0">
        <i className="feather-calendar text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          Created:
          <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
            {formatDistance(new Date(props.community.created_at), new Date(), {
              addSuffix: true,
            })}
          </span>
        </h4>
      </div>

      <div className="card-body d-flex pt-0"> 
        {props.community.tags && (
          <>
          <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
          <h4 className="fw-700 text-grey-900 font-xssss mt-1">Locations: </h4>
            {props.community.tags.map((tag) => (
              <>
                <p style={{ marginRight: 10, marginBottom: 5 }}>{tag.name}</p>
                <p style={{ marginRight: 10, marginBottom: 5 }}>{tag.name}</p>
                {/* <Chip
                  style={{ marginRight: 10, marginBottom: 5 }}
                  className="infotag pointer"
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={`# ${tag.name}`}
                />
                <Chip
                  style={{ marginRight: 10, marginBottom: 5 }}
                  className="infotag pointer"
                  variant="outlined"
                  color="primary"
                  size="small"
                  label={`# ${tag.name}`}
                /> */}
              </>
            ))}
          </>
        )}
      </div>

      {/* <div className="card-body d-flex pt-0">
                    <i className="feather-map-pin text-grey-500 me-3 font-lg"></i>
                    <h4 className="fw-700 text-grey-900 font-xssss mt-1">Location:
                    { !props.admin.state && props.admin.country === null ?
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Nigeria</span> 
                    :
                        <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{props.admin.state}, {props.admin.country}</span> 
                    }
                    </h4>
                </div> */}
      <div className="card-body d-flex pt-0">
        <i className="feather-users text-grey-500 me-3 font-lg"></i>
        <h4 className="fw-700 text-grey-900 font-xssss mt-1">
          Admin: {props.admin.user_name}
        </h4>
      </div>
    </div>
  );
  // }
}

// export default Profiledetail;
