import React, { Component, Fragment, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  useLocation,
  Link,
} from "react-router-dom";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

import axios from "axios";
import axiosFetch from "../Interceptors2";
import Load from "../components/Load";
import Alert from "@mui/material/Alert";
import { Button } from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { Snackbar } from "@material-ui/core";
import { usePaystackPayment } from "react-paystack";
import UserTransactions from "../components/UserTransactions";
import UserRequests from "../components/UserRequests";
import TestTransaction from "../components/TestTransaction";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Box from "@mui/material/Box";

export default function AlarrtSubscription() {
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [subStatus, setSubStatus] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [userName, setUserName] = useState("");
  const userName = window.localStorage.getItem("first_name");
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [fetching, setFetching] = useState(false);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const [value, setValue] = React.useState("subscriptions");
  const location = useLocation();
  const history = useHistory();

  const path = location.pathname;
  const pathname = window.location.pathname;

  const getPathname = () => {
    console.log(`path name ${location.pathname}`);
    if (path.includes("/my-subscriptions")) {
      setValue("my-subscriptions");
    }
    if (path.includes("/my-requests")) {
      setValue("my-requests");
    }

    // .slice(-3)
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    history.replace(`/${newValue}`);
    setValue(newValue);
    // e.prevent
    // event.preventDefault();
    // history.replace(`/profile/${user_name}/${newValue}`)
  };

  const monthlyConfig = {
    reference: new Date().getTime().toString(),
    email: profile.email,
    amount: 1000 * 100,
    // plan: process.env.REACT_APP_PAYSTACK_MONTHLY_PLAN,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
  };

  const quarterlyConfig = {
    reference: 'Quarterly-Subscriptions',
    email: profile.email,
    amount: 2500 * 100,
    plan: process.env.REACT_APP_PAYSTACK_QUARTERLY_PLAN,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_TEST,
  };

  // you can call this function anything
  const onClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert("Oops!");
  };

  const initializePayment = usePaystackPayment(monthlyConfig);
  const initializePaymentQuarter = usePaystackPayment(quarterlyConfig);

  const onSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);

    recordTransaction(
      "Resq Monthly Plan",
      "Payment to get access to Resq Monthly",
      1000,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "resq",
      "monthly"
    );
    // addSubscription("resq", "monthly");
    setIsSuccess(true);
  };

  const onSuccessQuarter = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);

    recordTransaction(
      "Resq Quarterly Plan",
      "Payment to get access to Resq Quarterly",
      2500,
      reference.reference,
      reference.transaction,
      `${reference.status} - ${reference.message}`,
      "resq",
      "quarterly"
    );
    // addSubscription("resq", "quarterly");
    setIsSuccess(true);
  };

  async function recordTransaction(
    type,
    description,
    amount,
    reference,
    transaction,
    status,
    resq,
    duration
  ) {
    const access_token = await window.localStorage.getItem("access_token");

    // alert([type, description, amount, reference, commission, payout])
    const formData = new FormData();

    formData.append("type", type);

    formData.append("description", description);
    formData.append("amount", amount);
    formData.append("reference", reference);
    formData.append("transaction", transaction);
    formData.append("status", status);

    axiosFetch
      .post(`/users/add/transaction`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);
        console.log("transaction recorded successfully");
        addSubscription(resq, duration);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function addSubscription(type, duration) {
    const access_token = await window.localStorage.getItem("access_token");

    // alert([type, description, amount, reference, commission, payout])
    const formData = new FormData();
    formData.append("type", type);
    formData.append("duration", duration);

    axiosFetch
      .post(`/users/add/subscription`, formData, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        const data2 = JSON.stringify(resp.data);
        console.log("subscription recorded successfully");

        getSubStatus();
        getProfile();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getUserProfile() {
    setIsLoading(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      // .get("https://alert-lumen-api.herokuapp.com/api/users/showId/" + userID, {
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setProfile(resp.data.user);
        // setUserName(resp.data.user.user_name);

        setIsLoading(false);

        // console.log(`profile details ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getSubStatus() {
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      // .get("https://alert-lumen-api.herokuapp.com/api/users/showId/" + userID, {
      .get("/users/check/status", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setSubStatus(resp.data);
        // setUserName(resp.data);
        console.log(resp.data);
      })
      .catch((error) => {
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getProfile() {
    // setIsLoading(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      // .get("https://alert-lumen-api.herokuapp.com/api/users/showId/" + userID, {
      .get("/users/profile", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setProfile(resp.data.user);
        // setUserName(resp.data.user.user_name);

        // setIsLoading(false);

        // console.log(`profile details ${JSON.stringify(resp.data)}`);
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  async function getSubscriptions() {
    setFetching(true);
    // console.log(` 1 ${fetching}`)
    const access_token = await window.localStorage.getItem("access_token");
    // setUserId(window.localStorage.getItem("user_id"));
    console.log(window.localStorage.getItem("user_id"));
    axiosFetch
      .get(`/users/my/subscriptions`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setUserSubscriptions(resp.data);
        setFetching(false);
      })
      .catch((error) => {
        setFetching(false);
        console.log(error);
        setError(true);
        setErrorMessage(error.message);
      });
  }

  const handleClose = () => {
    setIsSuccess(false);
  };
  function closeError() {
    setError(false);
    console.log("closed");
  }

  useEffect(() => {
    getUserProfile();
    getSubStatus();
    getPathname();
    // getSubscriptions()
  }, []);

  if (isLoading == true) {
    return (
      <Fragment>
        <Header />
        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
          <div className="card-body p-4 w-100 border-0 d-flex rounded-3">
            {/* <h5 className="text-center"> Fetching Your Profile </h5> */}
          </div>
          <Load />
        </div>
        {/* </div>  */}
        {/* //  </div> */}
        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> hj
                <Rightchat /> */}
        <div className="main-content ">
          {/* PLANS */}
          <div >
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle title={"Subscribe to a plan " + userName} />

                    <div className="row ps-2 pe-2">
                      {/* monthly */}
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div
                            className="card-body position-relative h100 bg-image-cover bg-image-center"
                            style={{
                              backgroundImage: `url("assets/images/bg-blue.webp")`,
                            }}
                          ></div>
                          <div className="card-body d-block w-100 p-4 text-center">
                            {/* <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src={`assets/images/${value.imageUrl}`} alt="avater" className="float-right p-1 bg-white rounded-circle w-100" /></figure> */}
                            <div className="clearfix"></div>
                            <span className="font-lg fw-700 mt-3 pe-3 ls-2 lh-32 d-inline-block text-current">
                              <span className="font-xsssss">N</span> 1000{" "}
                              <span className="font-xsssss text-grey-500">
                                / mo
                              </span>{" "}
                            </span>
                            <div className="clearfix"></div>
                            <h4 className="fw-800 font-xs mt-3 mb-0 text-current">
                              {" "}
                              Resq (Monthly){" "}
                            </h4>
                            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                              In need of emergency assistance? We've got you
                              covered with our Resq plan.
                            </p>
                            <ul>
                            <li className="m-2">
                                <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  
                                  24/7 Emergency Assitance Coverage
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block"
                                  >
                                    (Covered)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                                <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  
                                  Real-Time Emergency Alarrts SMS
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block"
                                  >
                                    (Unlimited)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                              Phone Call First Aid
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (Covered)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  Swift Access To On-Site Home Emergency Treatment
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (External Service Fee Applies)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                              Connection To Ambulance Emergency Rescue
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (External Service Fee Applies)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  Referral To Emergency-Ready Hospitals
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (Covered)
                                  </span>
                                </h4>
                              </li>
                            </ul>

                            {subStatus.isSubscribed == "true" &&
                              subStatus.type == "monthly" && (
                                <h4 className="fw-500 font-xss text-grey-500">
                                  {subStatus.status} {subStatus.days}
                                </h4>
                              )}

                            <div className="mt-4 w-100"></div>

                            <div className="clearfix"></div>

                            {subStatus.isSubscribed == "true" &&
                            subStatus.type == "monthly" ? (
                              <a
                                style={{ color: "#000" }}
                                // onClick={() => {
                                //   initializePayment(onSuccess, onClose);
                                // }}
                                className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg text-black"
                              >
                                Subscribed To Plan
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  initializePayment(onSuccess, onClose);
                                }}
                                className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                              >
                                SUBSCRIBE
                              </a>
                            )}
                          </div>
                        </div>
                      </div>

                      {/* quatrterly */}
                      <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div
                            className="card-body position-relative h100 bg-image-cover bg-image-center"
                            style={{
                              backgroundImage: `url("assets/images/bg-blue.webp")`,
                            }}
                          ></div>
                          <div className="card-body d-block w-100 p-4 text-center">
                            {/* <figure className="avatar ms-auto me-auto mb-0 position-relative w90 z-index-1"><img src={`assets/images/${value.imageUrl}`} alt="avater" className="float-right p-1 bg-white rounded-circle w-100" /></figure> */}
                            <div className="clearfix"></div>
                            <span className="font-lg fw-700 mt-3 pe-3 ls-2 lh-32 d-inline-block text-current">
                              <span className="font-xsssss">N</span> 2500{" "}
                              <span className="font-xsssss text-grey-500">
                                / quarter
                              </span>{" "}
                            </span>
                            <div className="clearfix"></div>
                            <h4 className="fw-800 font-xs mt-3 mb-0 text-current">
                              {" "}
                              Resq (Quarterly){" "}
                            </h4>
                            <p className="fw-500 font-xssss text-grey-500 mt-0 mb-3">
                              In need of emergency assistance? We've got you
                              covered with our Resq plan.
                            </p>
                            <ul>
                            <li className="m-2">
                                <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  
                                  24/7 Emergency Assitance Coverage
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block"
                                  >
                                    (Covered)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                                <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  
                                  Real-Time Emergency Alarrts SMS
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block"
                                  >
                                    (Unlimited)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                              Phone Call First Aid
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (Covered)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  Swift Access To On-Site Home Emergency Treatment
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (External Service Fee Applies)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                              Connection To Ambulance Emergency Rescue
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (External Service Fee Applies)
                                  </span>
                                </h4>
                              </li>
                              <li className="m-2">
                              <h4 style={{ color: "#000" }} className="fw-700 font-xss d-block">
                                  Referral To Emergency-Ready Hospitals
                                  <span className="fw-500 font-xsss text-grey-500 mt-1 d-block">
                                  (Covered)
                                  </span>
                                </h4>
                              </li>
                            </ul>

                            {subStatus.isSubscribed == "true" &&
                              subStatus.type == "quarterly" && (
                                <h4 className="fw-500 font-xss text-grey-500">
                                  {subStatus.status} {subStatus.days}
                                </h4>
                              )}
                            <div className="mt-4 w-100"></div>

                            <div className="clearfix"></div>

                            {subStatus.isSubscribed == "true" &&
                            subStatus.type == "quarterly" ? (
                              <a
                                style={{ color: "#000" }}
                                // onClick={() => {
                                //   initializePayment(onSuccess, onClose);
                                // }}
                                className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl font-xsssss fw-700 ls-lg text-black"
                              >
                                Subscribed To Plan
                              </a>
                            ) : (
                              <a
                                onClick={() => {
                                  initializePaymentQuarter(
                                    onSuccessQuarter,
                                    onClose
                                  );
                                }}
                                className="mt-4 p-0 btn p-2 lh-24 w100 ms-1 ls-3 d-inline-block rounded-xl bg-current font-xsssss fw-700 ls-lg text-white"
                              >
                                SUBSCRIBE
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                      {/* ))} */}
                    </div>

                    <div>
                      <br />
                      <br />
                      {isSuccess ? (
                        <>
                          <br />
                          <br />
                          <Snackbar
                            open={isSuccess}
                            autoHideDuration={5000}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <Alert
                              onClose={handleClose}
                              variant="filled"
                              severity="success"
                              // action={
                              //   <a
                              //     href={`/profile/${profile.user_name}`}
                              //     className=" d-lg-block bg-success p-3 z-index-1 rounded-3 text-white font-xsssss fw-700 ls-3"
                              //   >
                              //     {" "}
                              //     Check It Out
                              //   </a>
                              // }
                            >
                              Awesome! Your plan was activated sucessfully!
                            </Alert>
                          </Snackbar>
                        </>
                      ) : (
                        ""
                      )}

                      {error ? (
                        <div>
                          <br />
                          <br />
                          <Alert
                            severity="error"
                            onClose={handleClose}
                            action={
                              <>
                                <CancelIcon onClick={closeError} />
                              </>
                            }
                          >
                            There was an issue updating / geting your profile.
                            Kindly try again [ {errorMessage} ]
                          </Alert>
                          {/* <Alert severity="error" onClose={handleClose}>There was an issue logging you in. Kindly confirm your email or password is correct</Alert> */}
                          <br />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* PST SUBS */}

          {fetching == true ? (
            <Load />
          ) : (
            <div>
                  <Box sx={{  width: "100%", bgcolor: "background.paper" }}>
            
            <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                // value={key}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                centered
                allowScrollButtonsMobile
                scrollButtons="auto"
                aria-label="scrollable force tabs example"
              >
            <Tab value="my-subscriptions" label="Subscriptions">
           
            </Tab>

            <Tab value="my-requests" label="Requests" component={Link} to={`/my-requests`} >
           
            </Tab>
            

            </Tabs>
            </Box>


            <TabPanel value="my-subscriptions">
              <br/>
              {/* <div className="row"> */}
                {/* <div className="col-xl-4 col-xxl-3 col-lg-4 pe-0"> */}
                <UserTransactions userName={userName} />
                {/* <h2> All Transactions </h2> */}
                  {/* <Events />  */}
                {/* </div> */}
                
               
              {/* </div> */}

            </TabPanel>

            <TabPanel value="my-requests">
            <br/>
  
             {/* <h2> All Requests </h2> */}
             <UserRequests userName={userName} />
            
            </TabPanel>

            </TabContext>
          </Box>
             
              {/* <TestTransaction /> */}
            </div>
          )}
        </div>
        {/* // <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}
