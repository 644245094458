import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import { BrowserRouter, useHistory } from "react-router-dom";
// import { useHistory, useParams, withRouter  } from "react-router";
import "./App.css";
import "./App.scss";
import "swiper/swiper.min.css";
import "./moviesComponents/boxicons-2.0.7/css/boxicons.min.css";
import "../node_modules/video-react/dist/video-react.css";
import { fetchToken, onMessageListener } from './firebase';
// import { onMessag from "./fireba";
import Notifications2 from "./components/Notifications/Notifications";
import ReactNotifications from "./components/Notifications/ReactNotifications";
import { Notifications } from 'react-push-notification';
import addNotification from 'react-push-notification';
import HttpsRedirect from 'react-https-redirect';
// import "react-slideshow-image/dist/styles.css";

import * as PusherPushNotifications from "@pusher/push-notifications-web";

import Routes from "./Routes";
import ReactGA from "react-ga";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import RouteChangeTracker from "./RouteChangeTracker";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import axios from "axios";
import Load from "./components/Load";
import { setCookie } from "react-use-cookie";
import { getCookie } from "xsrf-cookie";
import Cookies from "universal-cookie";
import axiosFetch from "./Interceptors2";

import createAuthRefreshInterceptor from "axios-auth-refresh";
import { format, formatDistance, differenceInMinutes } from "date-fns";
import FBNotification from "./FBNotification";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Row, Col, Toast } from "react-bootstrap";

// import { useHistory, useLocation } from "react-router-dom";

//

function App() {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const updateversion = window.localStorage.getItem("updateVersion");
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);

  const latitude = window.sessionStorage.getItem("lat");
  const longitude = window.sessionStorage.getItem("lon");
  const watch_latitude = window.sessionStorage.getItem("wat_lat");
  const watch_longitude = window.sessionStorage.getItem("wat_lon");
  const ip_address = window.sessionStorage.getItem("ip");
  
  const push_token = window.localStorage.getItem("token");
  const accessT = window.localStorage.getItem("access_token");
  const can_update = window.localStorage.getItem("can_update");
  
  const [isTokenFound, setTokenFound] = useState(false);
  const [ipAddress, setIpAddress] = useState('');
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [shownNotification, setShownNotification] = useState(false);
  fetchToken(setTokenFound);

  // onMessageListener2().then(payload => {
  //   setNotification({title: payload.notification.title, body: payload.notification.body})
  //   // setShow(true);
  //   addNotification({
  //     title: 'Warning',
  //     subtitle: 'This is a subtitle',
  //     message: 'This is a very long message',
  //     theme: 'darkblue',
  // icon: 'https://cdn.lordicon.com/giaigwkd.json',
  //     native: true // when using native, your OS will handle theming.
  // });
  //   console.log('second notification payload is ',payload);
  // }).catch(err => console.log('failed: ', err));

  // const onShowNotificationClicked = () => {
  //   setNotification({title: "Notification", body: "This is a test notification"})
  //   setShow(true);
  // }

  onMessageListener()
    .then((payload) => {
      
      // setNotification({
      //   title: payload.notification.title,
      //   body: payload.notification.body,
      // });
      // setShownNotification(true)
      addNotification({
        title: payload.notification.title,
        subtitle: payload.notification.title,
        message:  payload.notification.body,
        theme: 'darkblue',
        duration: 8000,
        // icon: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.youtube.com%2Fchannel%2FUCIYBgiBUefVLJB9bq2dlp2g%2Ffeatured&psig=AOvVaw3bVvMDfp6nUyX3NmwD4Dd1&ust=1651175029043000&source=images&cd=vfe&ved=0CAwQjRxqFwoTCNiwkLyAtfcCFQAAAAAdAAAAABAD',
        native: true // when using native, your OS will handle theming.

    });
      console.log('notification payload is ',payload);
      // setShownNotification(false)
      // resolve(payload);
      // return payload;
    })
    .catch((err) => console.log("failed: ", err));



  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_ACCESS_KEY,
    plugins: [new BugsnagPluginReact()],
  });
  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
  // Bugsnag.notify(new Error('Test error'))

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ACCESS_KEY,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  // axios.defaults.baseURL = 'https://alarrt-dev-api.herokuapp.com/api';
  axios.defaults.headers.common["Access-Control-Allow-Origin"] =
    "http:localhost:3000";
  axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
  // axios.defaults.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf_token"]').attr('content');
  const cookies = new Cookies(document.cookie);
  console.log(cookies.get("XSRF-TOKEN")); // Pacman or undefined if not set yet
  let xsrfToken = getCookie(); // No need to pass the parameter to get xsrf token.

  // axios.defaults.withCredentials = true
  // axios.defaults.headers.common['withCredentials'] = true;
  // axios.defaults.headers.common['host'] = 'https://localhost:3000';

  // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  //  xsrfCookieName: 'XSRF-TOKEN', // default
  setCookie("locale", "jjcifdiiolocal");
  // const xsrfToken = getCookie('locale');
  // getCookie('XSRF-TOKEN');

  console.log(" new xrf is " + xsrfToken);

  console.log("cokies are " + document.cookie);
  let token = document.head.querySelector('meta[name="csrf-token"]');

  if (token) {
    //     // window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    console.log("found token" + token.content);
  } else {
    console.error(
      "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
    );
  }
  const userId = window.localStorage.getItem("user_id");
  const Name =
    window.localStorage.getItem("first_name") +
    window.localStorage.getItem("last_name");
  const userEmail = window.localStorage.getItem("email");
  const userName = window.localStorage.getItem("user_name");

  LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
  // env
  LogRocket.identify(userId, {
    name: Name,
    email: userEmail,
    // Add your own custom user variables here, ie:
    user_name: userName,
  });
  setupLogRocketReact(LogRocket);
  
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING);
  ReactGA.pageview(window.location.pathname + window.location.search);
  // LogRocket.getSessionURL(function (sessionURL) {
  //   ga('send', {
  //     hitType: 'event',
  //     eventCategory: 'LogRocket',
  //     eventAction: sessionURL,
  //   });
  // });

  function handleContextMenu(e) {
    e.preventDefault();

    // ...
  }
  function watchLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(function (position) {
        // console.log("App Watch Lat Log is :", position.coords.latitude);
        // console.log(" App Watch Lon Log is :", position.coords.longitude);

        window.sessionStorage.setItem("wat_lat", position.coords.latitude);
        window.sessionStorage.setItem("wat_lon", position.coords.longitude);
      });
    }
    // console.log("App Watching location....");
    // console.log("App Lat is :", latitude);
    // console.log("App Lon  is :", longitude);
    // console.log("App Watch Lat is :", watch_latitude);
    // console.log("App Watch Lon  is :", watch_longitude);
  }

  async function updateLocation() {
    const locationId = window.localStorage.getItem("locId");

    if (locationId) {
      console.log("updating location 2...");
      const formData = new FormData();
      formData.append("current_lat", watch_latitude);
      formData.append("current_lon", watch_longitude);
      formData.append("ip_address", ip_address);

      let result = await axiosFetch
        .post(`/alerts/update/location/${locationId}`, formData, {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data)
          console.log("updated location 2!");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("seems no alert has been created yet!");
    }
  }

  const requestNotificationPermission = async () => {
    const permission = await window.Notification.requestPermission();
    // value of permission can be 'granted', 'default', 'denied'
    // granted: user has accepted the request
    // default: user has dismissed the notification permission popup by clicking on x
    // denied: user has denied the request.
    if (permission !== "granted") {
      console.log("Notification Permission not granted ");
    }
  };

  async function trackToken(){
    if(push_token && can_update ){
      console.log('push id token can be stored', isTokenFound)
      console.log("updating location...");
      const formData = new FormData();
      formData.append("push_key", push_token);
      // formData.append("ip_address", ip_address);
      // formData.append("lat", watch_latitude);
      // formData.append("long", watch_longitude);

      let result = await axiosFetch
        .post(`/users/save/token`, formData, {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data)
          console.log("updated push token!");
        })
        .catch((error) => {
          console.log(error);
        });
    } 
    else {
      console.log("seems push token has not been found yet!");
    }

  }

  async function trackLocation(){
    if(accessT ){
      console.log('location & ip can be stored', isTokenFound)
      console.log("updating user location...");
      const formData = new FormData();
      formData.append("ip_address", ip_address);
      formData.append("lat", watch_latitude);
      formData.append("long", watch_longitude);

      let result = await axiosFetch
        .post(`/users/save/location`, formData, {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            // Authorization: "Bearer " + access_token,
          },
        })
        .then((resp) => {
          // console.log(resp.data)hjjdk
          console.log("updated push token!");
        })
        .catch((error) => {
          console.log(error);
        });
    } 
    else {
      console.log("seems push token has not been found yet!");
    }

  }

  function checkAppVersion(){
   const envVersion = process.env.REACT_APP_VERSION
    console.log('app version is outdated')
    if(updateversion != envVersion){
      window.localStorage.setItem("updateVersion", envVersion); 
      window.location.reload(true)
      console.log('updated app version')
      
      //made a mistake
      // var retVal = window.confirm(`Opps! Seems like an update has been made since you were here. Reload now to get latest version. Click OK to reload now`);
      // if( retVal == true ) {
      //   window.localStorage.setItem("updateVersion", envVersion);
      //   window.location.reload(true)
      // } else {
      //    return false;
      // }
    }
  }
  function replaceAndRedirect(){
    const protocol = window.location.protocol;
    const newProtocol = protocol.slice(5);
    // alert("Page protocol is " + window.location.hostname + window.location.pathname);
    
    const path = "facebook.com";
    //  window.location.pathnamee
    if (window.location.protocol == "http:") {
      console.log("on http, it will be redirected");
      // window.location.href = window.location.href.replace("http:", "https:");
      // history.push({
      //   pathname: `https://${window.location.hostname}${window.location.pathname}`,
      // })
        history.push(`https://${window.location.hostname}`)
        history.go()
        // location.replace(`https:${location.href.substring(location.protocol.length)}`);
        
    }
    if (window.location.hostname == "www.alarrt.com") {
      console.log("it is on www, will be redirected");
      // history.push({
      //   pathname: 'https://alarrt.com',
      // })
          history.push('https://alarrt.com')
          history.go()
    }

    // onClick={() => history.push(`/channel/${props.route}/${movie.slug}`)}
    // onClick={()=> window.location.reload()} https://api.ipify.org/?format=json

  }

  function getIpAddresss() {
    // const access_token = window.localStorage.getItem("access_token");
    fetch('https://api.ipify.org/?format=json')
    .then(results => results.json())
    .then(data => window.sessionStorage.setItem("ip", data.ip))
    // .then(data => setIpAddress())
    // .ther
    // axios
    //   .get("https://api.ipify.org/?format=json", {
    //     method: "GET",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       // Authorization: "Bearer " + access_token,
    //     },
    //   })
    //   .then((resp) => {
    //     console.log("IPpp adress is ". resp)
    //   })
    //   .catch((error) => console.error(`Error Seen : ${error}`));
  }

  useEffect(() => {
    // getIpAddresss();
    // replaceAndRedirect()
    checkAppVersion()
    // if (window.location.protocol == "http:") {mjk
    //   console.log("it can be redirected");
    //   window.location.href = window.location.href.replace("http:", "https:");
    // }
    // window.localStorage.setItem("updateVersion", 1)njjkk;
    // window.onbeforeunload = function () {
    //   return 'Are you really want to refresh this page?';
    //  }
    // subscribeToPushService()
    // alert(process.env.REACT_APP_FCM_API_KEY)
    requestNotificationPermission();
    trackToken()
    trackLocation();
    watchLocation();
    updateLocation();

    ReactGA.pageview(window.location.pathname + window.location.search);

    // const messaging = firebase.messaging()
    // messaging.requestPermission().then(()=>{
    //   return messaging.getToken()
    // }).then(token => {
    //   console.log('firesbase token is ', token)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    // const swRegistration2 = navigator.serviceWorker.register('./service-worker.js');

    // // document.addEventListener("contextmenu", handleContextMenu);
    // window.navigator.serviceWorker.ready.then(swRegistration2 => {
    //   const beamsClient = new PusherPushNotifications.Client({
    //     instanceId: '98cccc40-56e6-4c8c-b70d-66c8c3cca835',
    //     swRegistration2: swRegistration2,
    //   })
    // })

    // const beamsClient = new PusherPushNotifications.Client({
    //   instanceId: "98cccc40-56e6-4c8c-b70d-66c8c3cca835",
    //   // swRegistration2: swRegistration2,
    // });

    // beamsClient
    //   .start()

    //   .then((beamsClient) => beamsClient.getDeviceId())
    //   .then((deviceId) =>
    //     console.log("Successfully registered with Beams. Device ID:", deviceId)
    //   )
    //   // await PusherBeams.instance.setDeviceInterests(['debug-new', ‘hello’]);
    //   .then(() => beamsClient.setDeviceInterests(['debug-primary', 'primary']))
    //   // .then(() => beamsClient.addDeviceInterest("debug-primary"))
    //   .then(() => beamsClient.getDeviceInterests())
    //   .then((interests) => console.log("Current interests:", interests))
    //   .then(() => console.log("Successfully registered and subscribed!"))
    //   .catch((error) => console.log(error));
    // .catch(console.error);

    // alert('welcome o')
   
  }, [isTokenFound, console.log('ip address is -> ', ipAddress)]);

  const buttonClick = () => {
    addNotification({
        title: 'Warning',
        subtitle: 'This is a subtitle',
        message: 'This is a very long message',
        theme: 'darkblue',
        icon: 'https://cdn.lordicon.com/giaigwkd.json',
        native: true // when using native, your OS will handle theming.
    });
};

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <div className="App" style={{ backgroundColor: "#fff" }}>
          {/* <Suspense fallback={<Load message='Loading ...' setShow(true)/>}> */}
          <Notifications />
          <Routes />
          <RouteChangeTracker />
          {/* <Button onClick={() => buttonClick()}>Show Toast</Button> */}
          {isTokenFound && (
            <>
              {/* <p> Notification permission enabled </p> */}
              {console.log("Notification permission enabled")}
            </>
          )}
          {!isTokenFound && (
            <>
              {/* <p> Need notification permission </p> */}
              {console.log("Need notification permission")}
            </>
          )}

          {/* <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={8000}
            autohide
            animation
            style={{
              position: 'fixed', 
              bottom: 190,
              right: 20,
              minWidth: 200,
            }}
          >
            <Toast.Header>
              {/* <img
                  src="/assets/images/gift.png"
                className="rounded mr-2"
                alt=""
              /> */}
              {/* <strong className="mr-auto">{notification.title}</strong> */}
              {/* <small>just now</small> */}
            {/* </Toast.Header>
            <Toast.Body>{notification.body}</Toast.Body>
          </Toast>  */}

          {/* {show ? (
            ''
        <ReactNotifications
          title={notification.title}
          body={notification.body}
        />
      ) : (
        <></>
      )} */}
      {/* <Notifications2 /> */}
        </div>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

{
  /* <CloudinaryContext cloudName="alarrt">
  <div>
    <Image publicId="sample" width="50" />
  </div>
  <Image publicId="sample" width="0.5" />
</CloudinaryContext> */
}

export default App;
