import React, { Component } from "react";
import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  Link,
} from "react-router-dom";

import axios from "axios";
import axiosFetch from '../Interceptors2';
import Chip from "@material-ui/core/Chip";
import { format, formatDistance, subDays } from "date-fns";
// import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import RepeatIcon from "@material-ui/icons/Repeat";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteRoundedIcon from "@material-ui/icons/FavoriteRounded";
import ThumbDownOutlinedIcon from "@material-ui/icons/ThumbDownOutlined";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import Comment from "./comment/CreateComment";
import Load2 from "./Load2";
import SingleAlertComponent from "./SingleAlertComponent";
import MuiAlert from "@material-ui/lab/Alert";
import ShowMoreText from "react-show-more-text";
import SinglePostComponent from "./SinglePostComponent";

//
export default function PostSingle(props) {
  const [isOpen, setIsOpen] = useState("");
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const [isActive, setIsActive] = useState("");
  const [posts, setPosts] = useState([]);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [fetchingPosts, setFetchingPosts] = useState(false);

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  // async function loggedIn(){
  //     setIsLoggedIn(true)
  //     alert('LoggedIn Successfully')
  // }

  async function getPostLoad() {
    setFetchingPosts(true);
    console.log(` 1 ${fetchingPosts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/posts/show/${props.postId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setPosts(resp.data);
        setFetchingPosts(false);
        console.log(` 2 ${fetchingPosts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(posts.length)
        // console.log(`posts list ${this.state.posts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  async function getPost() {
    // setFetchingPosts(true);
    console.log(` 1 ${fetchingPosts}`);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/posts/show/${props.postId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        setPosts(resp.data);
        // setFetchingPosts(false);
        // console.log(` 2 ${fetchingPosts}`);
        // console.log(resp.data.user.first_name);
        //  console.log(posts.length)
        // console.log(`posts list ${this.state.posts}`);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  const toggleOpen = () => setIsOpen(!this.state.isOpen);
  const toggleActive = () => setIsActive(!this.state.isActive);

  useEffect(() => {
    getPostLoad();
  }, []);

  // componentDidUpdate() {
  //     this.getAllposts();
  //     console.log("comonent did uodate")
  // }

  // render() {
  // console.log(`posts are ${this.state.posts}`);
  // const {user ,time , des, avater , postimage , postvideo ,id } = this.props;

  const menuClass = `${isOpen ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  {
    /* <FlipMove> */
  }

  // if (this.state.posts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingPosts == true) {
    console.log(`final ${fetchingPosts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load2 />
      </>
    );
  }
  if (posts.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Hello {userName}! No Post Has Been Created In The Last 24 Hours.
              Use The Plus Button To Create One
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <>
       {console.log(JSON.stringify(posts))}
      <SinglePostComponent post={posts}  functionProp={() => getPost()} />
        {/* {posts.map((alert) => ( */}
        {/* ))}  */}
      </>
    );
  }
  // }
}

// export default posts;
