import React, { useState, useMemo, useCallback, useRef } from "react";
// import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
  Circle,
  MarkerClusterer,
} from "@react-google-maps/api";
import Appfooter from "./Appfooter";

const containerStyle = {
  // width: "100%",
  // height: "80%",
  width: "100vh",
  height: "800px",
};
const google = window.google;

const adminLat = window.sessionStorage.getItem("wat_lat");
const adminLng = window.sessionStorage.getItem("wat_lon");



console.log("adminlat is " + adminLat + " admin lng is "+ adminLng)

function AlertLocationV2(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: ['places, '],
  });

  const [map, setMap] = React.useState(null);
  const center = {
    lat: props.adminLat,
    lng: props.adminLng
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const options = useMemo(
    () => ({
      mapId: "a4e2da29cdf9cd48",
      // b181cac70f27f5e6
      disableDefaultUI: true,
      clickableIcons: false,
    }),
    []
  );

  return isLoaded ? (
  //   <GoogleMap
  //   zoom={10}
  //   // center={{
  //   //   lat: adminLat,
  //   //   lng: adminLng
  //   // }}
  //   center = {center}
  //   mapContainerClassName="map-container"
  //   options={options}
  //   // onLoad={onLoad}
  // >

// </GoogleMap>
      // <Map 
      //   startLat={props.startLat}
      //   startLong={props.startLong}
      //   currentLat={props.currentLat}
      //   currentLong= {props.currentLong}
      //   locationName={props.locationName}
      //   adminLat={props.adminLat}
      //   adminLng={props.adminLng}
      //   />
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <></>
    </GoogleMap>
  ) : (
    <p>Loading Map ...</p>
  );
}

export default React.memo(AlertLocationV2);
