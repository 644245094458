import React, { Component, useState, useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  useHistory,
  Link,
  useLocation,
} from "react-router-dom";
// import { Select } from "antd";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import { AutoComplete } from "antd";
import MuiAlert from "@material-ui/lab/Alert";
import Alert from "@mui/material/Alert";
import CreatePost2 from "./CreatePost2";
import { Button } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import GroupAddOutlined from "@mui/icons-material/GroupAddOutlined";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { getCircularProgressUtilityClass } from "@mui/material";

export default function ForwardAlertRandom(props) {
  const [emptyFields, setEmptyFields] = useState(false);
  const history = useHistory();
  const [isForwarding, setIsForwarding] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [forwarded, setForwarded] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [user, setUser] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [results, setResults] = useState([]);
  const [items, setItems] = useState([]);

  const animatedComponents = makeAnimated();
  const [open, setOpen] = React.useState(false);
  const { Option } = AutoComplete;

  const handleClose = () => {
    setOpen(false);

  };
  const handleClose2 = () => {
    setForwarded(false);
    setEmptyFields(false);
    setError(false);
  };

 

  async function validate() {
    if (!phoneNumber) {
      // console.log(alertCategory)
      setEmptyFields(true);
      return;
    } else {
      forwardAlert();
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function forwardAlert() {
    setIsForwarding(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");

    const formData = new FormData();
    // formData.append("channel_id", props.channelId);
    formData.append("phone_number", phoneNumber);

    axiosFetch
      .post(
        `/alerts/forwardv2/${props.alertId}`,
        formData,
        {
          // method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        if(resp.data.status == 'error'){
          setIsForwarding(false)
          setError(true);
          setErrorMessage(resp.data.message);
      }else{

        setIsForwarding(false);
        // setUserId("");
        setUser("");
        setPhoneNumber("");
        setLastName("");
        setForwarded(true);
        setItems([]);
        // setOpen(false);
        // props.functionProp();
        console.log(`response ${JSON.stringify(resp.data)}`);
        // setIsSuccess(true);
      }
      })
      .catch((error) => {
        setIsForwarding(false);
        setError(true);
        setErrorMessage(error.response.data.message);
        console.log(error);
      });
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  useEffect(() => {}, []);

  return (
    <>
      <Tooltip
        className="d-inline-block"
        title="Send As Sms"
        onClick={handleClickOpen}
      >
        <a
          onClick={handleClickOpen}
          className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
        >
          <i className="feather-send mr-1"></i> Alarrt Friend's Number
        </a>
      </Tooltip>
      <Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {" "}
            Forward this alarrt to your friend via sms
            {props.channelId}{" "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              You can forward this alarrt to your friends that are not yet on Alarrt. They will be notified via SMS.
            </DialogContentText>
            <br/>
            <p>Only forward to friends this alarrt will be beneficial to</p>
            {/* {console.log(JSON.stringify(casts))} */}
            <p> Type friend's number here </p>
            {/* <br /> */}

            <TextField
          id="standard-basic"
          label={`Your Friend's Number`}
          variant="standard"
          required
          size="small"
          style={{ width: "100%", fontSize: 4, color: "red" }}
          onChange={(e) => 
            setPhoneNumber(e.target.value) 
          }
          value={phoneNumber}
         
        />
            
            <br /><br/>
            {isForwarding ? 
            <Button
             disabled
              // fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              // onClick={validate}
            >
              Forwarding
            </Button>

            :

            <Button
              // type="submit"
              // fullWidth
              variant="contained"
              color="primary"
              // className={classes.submit}
              onClick={validate}
            >
              Forward Alert
            </Button>

           }   
           

            <br />
            <br />
            {emptyFields ? (
            <MuiAlert onClose={handleClose2} severity="warning">
              Oops! Add A Phone Number First
            </MuiAlert>
          ) : (
            ""
          )}
          {forwarded ? (
            <MuiAlert onClose={handleClose2} severity="success">
              Yippy! You've alerted your friend successfully. Alarrt more friends
              that needs to be aware
            </MuiAlert>
          ) : (
            ""
          )}

          {error ? (
            <div>
              <Alert severity="error" onClose={handleClose2}>
                There was an issue forwarding this alert. [ {errorMessage} ].
          
              </Alert>
              <br />
            </div>
          ) : (
            ""
          )}
            <br />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {isForwarding ? (
              <Button disabled>Forwarding</Button>
            ) : (
              <Button onClick={validate}>Forward Alert</Button>
              // <Button onClick={console.log(`hi ${movieTitle}`)}>forwardAlert</Button>
            )}
            {/* <Button onClick={validate}>Create</Button> */}
          </DialogActions>
        
        </Dialog>{" "}
      </Box>
    </>
  );
}
