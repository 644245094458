import React, { Component, useState, Fragment, useEffect } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";
import { Route, useParams, Link } from "react-router-dom";
import axios from "axios";
import axiosFetch from '../Interceptors2';
import Load from "../components/Load";

// class Followers extends Component {
export default function Followers() {
  const { userName } = useParams(["userName"]);
  const [userId, setUserId] = useState("");
  const authId = useState(window.localStorage.getItem("user_id"));
  const [fetchingFollowers, setFetchingFollowers] = useState(false);
  const [unFollowing, setUnFollowing] = useState(false);
  const [followers, setFollowers] = useState([]);
  // const [following, setFollowing] = useState([]);

  async function test() {
    return console.log("wow");
  }
  async function getProfile() {
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(`user profile ${access_token}`);
    axiosFetch
      .get(`/users/show/${userName}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(resp.data.user.id);
        setUserId(resp.data.user.id);
        // console.log(userID, userUN, userFN, userLN);
        //
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }
  async function getFollowers() {
    setFetchingFollowers(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .get(
        `/users/${userName}/followers`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        // console.log(`followers 1 ${resp.data}`)
        // const data2 = JSON.stringify(resp.data)
        // console.log(`followers 2 ${data2}`)
        setFollowers(resp.data);
        setFetchingFollowers(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
    // }
  }

  async function unFollowUser(user_name) {
    setUnFollowing(true);
    const access_token = await window.localStorage.getItem("access_token");
    console.log(user_name);
    axiosFetch
      .post(
        `/users/follow/${user_name}`,
        user_name,
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + access_token,
          },
        }
      )
      .then((resp) => {
        console.log(`unfollow ${resp.data}`);
        // const data2 = JSON.stringify(resp.data)
        // console.log(`unfollow 1 ${data2}`)
        // setIsFollowing(false)
        getFollowers();
        setUnFollowing(false);
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  useEffect(() => {
    getFollowers();
    getProfile();
  }, []);

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  if (fetchingFollowers == true) {
    // console.log(`final ${this.state.fetchingAlerts}`)
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Friends Followng " + userName}
                      userName={userName}
                      url="following"
                      text="View Following"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <Load />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  }

  if (followers.length == 0) {
    return (
      <>
        <Fragment>
          <Header />
          {/* <Leftnav /> */}
          {/* <Rightchat /> */}

          <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                <div className="row">
                  <div className="col-xl-12">
                    <Pagetitle
                      title={"Friends Followng " + userName}
                      userName={userName}
                      url="following"
                      text="View Following"
                    />
                    {/* <h3> No Alert Has Been Recently Created </h3> */}
                    <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
                      <div className="card-body p-0">
                        {authId[0] == userId ? (
                          <h3
                            className="middle-wrap fw-600 text-grey-900"
                            style={{ textAlign: "center", lineHeight: 2 }}
                          >
                            Hello {userName}! No one is following you yet.
                            Search for friends to follow
                          </h3>
                        ) : (
                          <h3
                            className="middle-wrap fw-600 text-grey-900"
                            style={{ textAlign: "center", lineHeight: 2 }}
                          >
                            Hello! No one is following {userName} yet. Check
                            Back Later
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Popupchat /> */}
          <Appfooter />
        </Fragment>
      </>
    );
  } else {
    return (
      <Fragment>
        <Header />
        {/* <Leftnav /> */}
        {/* <Rightchat /> */}

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle
                    title={userName + "'s Followers"}
                    userName={userName}
                    url="following"
                    text="View Following"
                  />

                  {/* <h1> {user_name} </h1> */}

                  <div className="row ps-2 pe-2">
                    {followers.map((user, index) => (
                      <div key={index} className="col-md-3 col-sm-4 pe-2 ps-2">
                        <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                          <div className="card-body d-block w-100 ps-3 pe-3 pb-4 text-center">
                            <figure className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative w65 z-index-1">
                              <img
                                onError={fixImage}
                                src={user.user.profile_pic}
                                alt="avater"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                              />
                            </figure>
                            <div className="clearfix w-100"></div>
                            <h4 className="fw-700 font-xsss mt-3 mb-0">
                              {user.user.first_name} {user.user.last_name}
                            </h4>
                            {/* {test()} */}
                            <Link to={`/profile/${user.user.user_name}`}>
                              <p className="fw-500 text-primary mt-0 mb-3">
                                @{user.user.user_name}
                              </p>
                            </Link>
                            {authId[0] == user.user.id ? (
                              <>
                                <p className="fw-200 text-grey-500 mt-0 mb-3">
                                  {" "}
                                  You Follow @{userName}!{" "}
                                </p>
                              </>
                            ) : authId[0] == userId ? (
                              <>
                                {unFollowing ? (
                                  <a
                                  disabled
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    Just A Sec ...
                                  </a>
                                ) : (
                                  <a
                                    onClick={() =>
                                      unFollowUser(user.user.user_name)
                                    }
                                    className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                  >
                                    {user.action}
                                  </a>
                                )}
                              </>
                            ) : (
                              <>
                              {unFollowing ? (
                                <a
                                disabled
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                >
                                  Just A Sec ...
                                </a>
                              ) : (
                                <a
                                  onClick={() =>
                                    unFollowUser(user.user.user_name)
                                  }
                                  className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white"
                                >
                                  {user.action}
                                </a>
                              )}
                            </>
                            )}

                            {/* <a onClick={followUser} className="mt-0 btn pt-2 pb-2 ps-3 pe-3 lh-24 ms-1 ls-3 d-inline-block rounded-xl bg-success font-xsssss fw-700 ls-lg text-white">FOLLOW BACK</a> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Popupchat /> */}
        <Appfooter />
      </Fragment>
    );
  }
}

// export default Followers;
