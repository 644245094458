import React, { Component } from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import axiosFetch from "../Interceptors2";
import Chip from "@material-ui/core/Chip";
import { Snackbar } from "@material-ui/core";
import { Player } from "video-react";
import { format, formatDistance, subDays } from "date-fns";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CreateComment from "./comment/CreateComment";
import SingleComment from "./comment/SingleComment";
import AllThreads from "./comment/AllThreads";
import CreateThread from "./comment/CreateThread";
import Load from "./Load";
import ShowMoreText from "react-show-more-text";
import LikesProfilePic from "./LikesProfilePic";
import SingleImage from "./SingleImage";
import TwoImages from "./TwoImages";
import ThreeImages from "./ThreeImages";
import FourImages from "./FourImages";
import ShareButtons from "./ShareButtons";

import AlertUpdateModal from "./AlertUpdateModal";
import BoltIcon from "@mui/icons-material/Bolt";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import FlashAutoIcon from "@mui/icons-material/FlashAuto";
import MuiAlert from "@material-ui/lab/Alert";
import LikersModal from "./LikersModal";
import ReportModal from "./ReportModal";
import ImageComponent from "./ImageComponent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ForwardAlerts from "./ForwardAlert";
import ForwardAlertRandom from "./ForwardAlertRandom";
import AlertLocation from "./AlertLocation";
import AlertDropComp from "./AlertDropComp";
import ReactPlayer from "react-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//
export default function SingleAlertComponent(props) {
  const [userName, setUserName] = useState(
    window.localStorage.getItem("user_name")
  );
  const userId = useState(window.localStorage.getItem("user_id"));
  const [isOpen, setIsOpen] = useState(false);
  const [isComment, setIsComment] = useState(false);
  const [isThread, setIsThread] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [fetchingAlerts, setFetchingAlerts] = useState(false);
  const authId = useState(window.localStorage.getItem("user_id"));
  const userType = window.localStorage.getItem("type");
  const [realerting, setRealerting] = useState(false);
  const [realerted, setRealerted] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [following, setFollowing] = useState(false);
  const [followed, setFollowed] = useState(false);
  const [followText, setFollowText] = useState("follow");
  const styles = {
    position: "absolute",
    // top: 0,
    right: 30,
    // left: 0,
    zIndex: 1,
    border: "1px solid",
    p: 1,
    width: "80%",
    bgcolor: "background.paper",
  };

  function toggleMenu(event, reason) {
    showMenu == false ? setShowMenu(true) : setShowMenu(false);
    if (reason === "clickaway") {
      alert("clicked away");
    }
  }
  const handleClickAway = () => {
    setShowMenu(false);
  };

  function closeSnack() {
    setFollowed(false);
    setDeleted(false);
    setSaved(false);
  }
  async function Follow(user_name) {
    setFollowing(true);
    setFollowText("following...");
    const access_token = await window.localStorage.getItem("access_token");
    // console.log(user_name)
    axiosFetch
      .post(`/users/follow/${user_name}`, user_name, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        setFollowText("Followed!");
        props.functionProp();
        setFollowed(true);
        setFollowing(false);
        // getFollowing();
      })
      .catch((error) => console.log(`Error Seen : ${error}`));
  }

  // history = useHistory();
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function openComment() {
    setIsComment(true);
  }

  function fixImage(ev) {
    ev.target.src = "https://alert-app-v1.s3.amazonaws.com/user.png";
  }

  async function realert(id) {
    setRealerting(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/realert/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setRealerting(false);
        setRealerted(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  function handleClose2() {
    setRealerted(false);
    setSaved(false);
  }

  async function saveAlert(id) {
    setSaving(true);
    const access_token = await window.localStorage.getItem("access_token");
    const authId = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/save/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        // alert('relaerted!')
        console.log(`hiii ${resp.data}`);
        setSaving(false);
        setSaved(true);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function deleteAlert(id) {
    setIsDeleting(true);
    const access_token = await window.localStorage.getItem("access_token");
    axiosFetch
      .delete(`/alerts/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      })
      .then((resp) => {
        console.log(JSON.stringify(resp.data));
        setConfirmDelete(false);
        setIsDeleting(false);
        setDeleted(true);
        props.functionProp();
        setShowMenu(false);
        // setOpen(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  async function likeAlert(id) {
    setIsLiking(true);
    const access_token = await window.localStorage.getItem("access_token");
    const userID = await window.localStorage.getItem("user_id");
    axiosFetch
      .post(`/alerts/like/${id}`, id, {
        headers: {
          Accept: "*/*",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "text/html",
          Authorization: "Bearer " + access_token,
        },
        // method: 'POST',
      })
      .then((resp) => {
        const alerts = resp.data;
        props.functionProp();
        console.log(`hiii ${resp.data}`);
        setIsLiking(false);
      })
      .catch((error) => console.error(`Error Seen : ${error}`));
  }

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);
  const toggleAction = () => setOpenAction(!openAction);
  const toggleComment = () => setIsComment(!isComment);
  const toggleThread = () => setIsThread(!isThread);

  let menuRef = useRef();
  useEffect(() => {
    // getAlert();
    let handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const menuClass = `${isOpen ? " show" : ""}`;
  const actionClass = `${openAction ? " show" : ""}`;
  const emojiClass = `${isActive ? " active" : ""}`;
  //   const { isLiked } = this.state;
  const commentOpen = isComment;
  {
    /* <FlipMove> */
  }

  // if (this.state.alerts.length = 0) {
  //     return(
  //         <>
  //             <h3> Hellooo No Alert Has Been Recently Created </h3>

  //         </>
  //     )

  // }
  if (fetchingAlerts == true) {
    // console.log(`final ${fetchingAlerts}`);
    return (
      <>
        {/* <h3> No Alert Has Been Recently Created </h3> */}
        <Load />
      </>
    );
  }
  if (props.alert.length == 0) {
    return (
      <>
        <div className="card w-100 shadow-xss rounded-xxl border-0 ps-4 pt-4 pe-4 pb-3 mb-3">
          <div className="card-body p-0">
            <h3
              className="middle-wrap fw-600 text-grey-900"
              style={{ textAlign: "center", lineHeight: 2 }}
            >
              Hello {userName}! No Alert Has Been Created In The Last 24 Hours.
              Use The Plus Button To Create One
            </h3>
          </div>
        </div>
      </>
    );
  }
  //   else {
  else {
    return (
      <>
        {console.log(JSON.stringify(props.alert))}
        {/* {props.alerts.map((alert) => ( */}
        <div
          key={props.alert.alert.id}
          className="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3"
          // onClick={handleClickAway}
        >
          {/* {userType === "true" ? <AlertLocation /> : ""} */}
          {/* {props.alert.alert.owner == null ? ( */}
          {props.alert.alert.realerted == "false" ? (
            <div className="card-body p-0 d-flex">
              <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                <ImageComponent
                  image={props.alert.alert.user.profile_pic}
                  class="shadow-sm me-3 rounded-circle w45"
                  style={{ border: "2px solid #045de9" }}
                />
                {/* <img
                  onError={fixImage}
                  src={props.alert.alert.user.profile_pic}
                  alt="avater"
                  className="shadow-sm me-3 rounded-circle w45"
                /> */}
              </figure>
              <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                {" "}
                {/* {props.alert.alert.} */}
                {props.alert.alert.user.first_name}{" "}
                {props.alert.alert.user.last_name}
                <Link to={`/profile/${props.alert.alert.user.user_name}`}>
                  {" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                    @{props.alert.alert.user.user_name}
                  </span>{" "}
                </Link>
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {formatDistance(
                    new Date(props.alert.alert.created_at),
                    new Date(),
                    {
                      addSuffix: true,
                    }
                  )}
                  {/* {alert.created_at} */}
                </span>
              </h4>
              {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
              <div
                className={`ms-auto pointer ${actionClass}`}
                // id="dropdownMenu4"
                // data-bs-toggle="dropdown"
                // aria-expanded="false"
                // onClick={toggleAction}
              >
                {/* <i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i> */}
              </div>

              <div className={`ms-auto pointer`} ref={menuRef}>
                {userId[0] == props.alert.alert.user.id ? (
                  <>
                    <AlertDropComp
                      canReport={false}
                      canUpdate={true}
                      canDelete={false}
                      alert={props.alert.alert}
                      functionProp={() => props.functionProp()}
                      functionProp2={() => props.functionProp2()}
                    />
                    {/* <div>
                      <a>
                        {" "}
                        <i
                          onClick={toggleMenu}
                          style={{ fontSize: 25, position: "relative" }}
                          className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                        ></i>{" "}
                      </a>
                      {showMenu ? (
                        // // <ClickAwayListener onClickAway={handleClickAway}>
                        <div
                          className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                          // data-uk-drop="mode: click;pos: bottom-right"
                          style={styles}
                        >
                          <ul className="space-y-1">
                            <li>
                              <AlertUpdateModal
                                title="Edit Alert"
                                content={props.alert.alert}
                                type="alert"
                                alertId={props.alert.alert.id}
                                functionProp={() => props.functionProp()}
                                functionProp2={() => props.functionProp()}
                              />
                            </li>

                            <li>
                              <a
                                onClick={() =>
                                  realert(props.alert.alert.alert_origin)
                                }
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-zap mr-1"></i> Re-alert
                              </a>
                            </li>

                            <li>
                              <div>
                                {realerting ? (
                                  <p>
                                    {" "}
                                    Realerting... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {realerted ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setRealerted(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've realerted this alert successfully.
                                    Your close friends have been notified via
                                    email and sms as well.
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>

                            <li>
                              <ForwardAlerts
                                alertId={props.alert.alert.alert_origin}
                              />
                            </li>
                            <li>
                              <ForwardAlertRandom
                                alertId={props.alert.alert.alert_origin}
                              />
                            </li>
                            <li>
                              <a
                                onClick={() => saveAlert(props.alert.alert.id)}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="uil-favorite mr-1"></i> Save Alert
                              </a>
                            </li>
                            <li>
                              <div>
                                {saving ? (
                                  <p>
                                    {" "}
                                    Saving Alert... This will take a few seconds{" "}
                                  </p>
                                ) : (
                                  ""
                                )}
                                {saved ? (
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="success"
                                    action={
                                      <>
                                        <a
                                          onClick={() => setSaved(false)}
                                          className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          close
                                        </a>
                                      </>
                                    }
                                  >
                                    You've saved this alert successfully.
                                    <a href={`/profile/${userName}`}>
                                      View it in your profile
                                    </a>
                                  </MuiAlert>
                                ) : (
                                  ""
                                )}
                              </div>
                            </li>
                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>
                            <li>
                              <a
                                onClick={toggleMenu}
                                className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                              >
                                <i className="feather-x mr-1"></i> Close This
                              </a>
                            </li>

                            <li>
                              <hr className="-mx-2 my-2 dark:border-gray-800" />
                            </li>

                            <li>
                              <a
                                // href="#" reaerted alert should be deletable
                                disabled
                                // onClick={() => setConfirmDelete(true)}
                                className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                              >
                                <i className="uil-trash-alt mr-1"></i> Delete
                              </a>
                              {confirmDelete ? (
                                <div>
                                  <p>
                                    {" "}
                                    Are you sure you want to delete this ?{" "}
                                  </p>
                                  <MuiAlert
                                    onClose={handleClose2}
                                    severity="error"
                                    action={
                                      <>
                                        <a
                                          disabled
                                          // onClick={() =>
                                          //   // deleteAlert(props.alert.alert.id)
                                          // }
                                          className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          Yes, delete it.
                                        </a>
                                        <br />
                                        <a
                                          onClick={() =>
                                            setConfirmDelete(false)
                                          }
                                          className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                        >
                                          No, Cancel
                                        </a>
                                      </>
                                    }
                                  >
                                    Are you sure you want to delete this ?
                                  </MuiAlert>
                                </div>
                              ) : (
                                ""
                              )}
                            </li>
                          </ul>
                        </div>
                      ) : (
                        // <ClickAwayListener>
                        ""
                      )}
                    </div> */}
                  </>
                ) : (
                  <AlertDropComp
                    canReport={true}
                    canUpdate={false}
                    canDelete={false}
                    alert={props.alert.alert}
                    functionProp={() => props.functionProp()}
                    functionProp2={() => props.functionProp2()}
                  />
                  // <div>
                  //   <a>
                  //     {" "}
                  //     <i
                  //       onClick={toggleMenu}
                  //       style={{ fontSize: 25, position: "relative" }}
                  //       className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                  //     ></i>{" "}
                  //   </a>
                  //   {showMenu ? (
                  //     // // <ClickAwayListener onClickAway={handleClickAway}>
                  //     <div
                  //       className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                  //       // data-uk-drop="mode: click;pos: bottom-right"
                  //       style={styles}
                  //     >
                  //       <ul className="space-y-1">
                  //         <li>
                  //           <a
                  //             onClick={() =>
                  //               realert(props.alert.alert.alert_origin)
                  //             }
                  //             className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  //           >
                  //             <i className="feather-zap mr-1"></i> Re-alert
                  //           </a>
                  //         </li>

                  //         <li>
                  //           <div>
                  //             {realerting ? (
                  //               <p>
                  //                 {" "}
                  //                 Realerting... This will take a few seconds{" "}
                  //               </p>
                  //             ) : (
                  //               ""
                  //             )}
                  //             {realerted ? (
                  //               <MuiAlert
                  //                 onClose={handleClose2}
                  //                 severity="success"
                  //                 action={
                  //                   <>
                  //                     <a
                  //                       onClick={() => setRealerted(false)}
                  //                       className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                  //                     >
                  //                       close
                  //                     </a>
                  //                   </>
                  //                 }
                  //               >
                  //                 You've realerted this alert successfully. Your
                  //                 close friends have been notified via email and
                  //                 sms as well.
                  //               </MuiAlert>
                  //             ) : (
                  //               ""
                  //             )}
                  //           </div>
                  //         </li>
                  //         <li>
                  //           <ForwardAlerts
                  //             alertId={props.alert.alert.alert_origin}
                  //           />
                  //         </li>
                  //         <li>
                  //           <ForwardAlertRandom
                  //             alertId={props.alert.alert.alert_origin}
                  //           />
                  //         </li>

                  //         {/* <li>
                  //          <a
                  //           //  onClick={disableComment}
                  //            className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  //          >
                  //            <i className="uil-comment-slash mr-1"></i> Disable
                  //            comments
                  //          </a>
                  //        </li> */}
                  //         <li>
                  //           <a
                  //             onClick={() => saveAlert(props.alert.alert.id)}
                  //             className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  //           >
                  //             <i className="uil-favorite mr-1"></i> Save Alert
                  //           </a>
                  //         </li>
                  //         <li>
                  //           <div>
                  //             {saving ? (
                  //               <p>
                  //                 {" "}
                  //                 Saving Alert... This will take a few seconds{" "}
                  //               </p>
                  //             ) : (
                  //               ""
                  //             )}
                  //             {saved ? (
                  //               <MuiAlert
                  //                 onClose={handleClose2}
                  //                 severity="success"
                  //                 action={
                  //                   <>
                  //                     <a
                  //                       onClick={() => setSaved(false)}
                  //                       className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                  //                     >
                  //                       close
                  //                     </a>
                  //                   </>
                  //                 }
                  //               >
                  //                 You've saved this alert successfully.
                  //                 <a href={`/profile/${userName}`}>
                  //                   View it in your profile
                  //                 </a>
                  //               </MuiAlert>
                  //             ) : (
                  //               ""
                  //             )}
                  //           </div>
                  //         </li>
                  //         <li>
                  //           <hr className="-mx-2 my-2 dark:border-gray-800" />
                  //         </li>
                  //         <li>
                  //           <a
                  //             onClick={toggleMenu}
                  //             className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                  //           >
                  //             <i className="feather-x mr-1"></i> Close This
                  //           </a>
                  //         </li>

                  //         <li>
                  //           {/* <br /> */}
                  //           <hr className="-mx-2 my-2 dark:border-gray-800" />
                  //         </li>
                  //         <li>
                  //           <ReportModal
                  //             type="alert"
                  //             text="Report"
                  //             url="alerts"
                  //             id={props.alert.alert.id}
                  //           />
                  //         </li>
                  //       </ul>
                  //     </div>
                  //   ) : (
                  //     // <ClickAwayListener>
                  //     ""
                  //   )}
                  // </div>
                )}
              </div>
            </div>
          ) : (
            // owner
            <>
              <div className="card-body p-0 d-flex">
                <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={props.alert.alert.owner.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                    onError={fixImage}
                    src={props.alert.alert.owner.profile_pic}
                    alt="avater"
                    className="shadow-sm me-3 rounded-circle w45"
                  /> */}
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  <span style={{ paddingRight: 2 }}>
                    Alerted From : {props.alert.alert.owner.first_name}{" "}
                    {props.alert.alert.owner.last_name}'s
                  </span>
                  <a
                    href={
                      `/alert/${props.alert.alert.slug}?i=${props.alert.alert.alert_origin}`
                      // pathname: `/alert/${props.alert.alert.slug}?i=${props.alert.alert.id}`,
                      // state: { id: props.alert.alert.alert_origin },
                    }
                  >
                    Alert
                  </a>
                  <Link to={`/profile/${props.alert.alert.owner.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{props.alert.alert.owner.user_name}
                    </span>{" "}
                  </Link>
                </h4>
              </div>

              <div className="card-body p-0 d-flex">
                <figure className="avatar me-3" style={{ marginLeft: 0 }}>
                  <ImageComponent
                    image={props.alert.alert.user.profile_pic}
                    class="shadow-sm me-3 rounded-circle w45"
                    style={{ border: "2px solid #045de9" }}
                  />
                  {/* <img
                    onError={fixImage}
                    src={props.alert.alert.user.profile_pic}
                    alt="avater"
                    className="shadow-sm me-3 rounded-circle w45"
                  /> */}
                </figure>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  {props.alert.alert.user.first_name}{" "}
                  {props.alert.alert.user.last_name}
                  <Link to={`/profile/${props.alert.alert.user.user_name}`}>
                    {" "}
                    <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                      {" "}
                      @{props.alert.alert.user.user_name}
                    </span>{" "}
                  </Link>
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                    {formatDistance(
                      new Date(props.alert.alert.created_at),
                      new Date(),
                      {
                        addSuffix: true,
                      }
                    )}
                    {/* {alert.created_at} */}
                  </span>
                </h4>
                {/* <div className="ms-auto pointer"><i className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss"></i></div> */}
                <div className={`ms-auto pointer`} ref={menuRef}>
                  {userId[0] == props.alert.alert.user.id ? (
                    <AlertDropComp
                      canReport={false}
                      canUpdate={false}
                      canDelete={true}
                      alert={props.alert.alert}
                      functionProp={() => props.functionProp()}
                      functionProp2={() => props.functionProp2()}
                    />
                  ) : (
                    // <>
                    //   <div>
                    //     <a>
                    //       {" "}
                    //       <i
                    //         onClick={toggleMenu}
                    //         style={{ fontSize: 25, position: "relative" }}
                    //         className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                    //       ></i>{" "}
                    //     </a>
                    //     {showMenu ? (
                    //       // // <ClickAwayListener onClickAway={handleClickAway}>
                    //       <div
                    //         className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                    //         // data-uk-drop="mode: click;pos: bottom-right"
                    //         style={styles}
                    //       >
                    //         <ul className="space-y-1">
                    //           <li>
                    //             <a
                    //               onClick={() =>
                    //                 realert(props.alert.alert.alert_origin)
                    //               }
                    //               className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    //             >
                    //               <i className="feather-zap mr-1"></i> Re-alert
                    //             </a>
                    //           </li>
                    //           <li>
                    //             <div>
                    //               {realerting ? (
                    //                 <p>
                    //                   {" "}
                    //                   Realerting... This will take a few seconds{" "}
                    //                 </p>
                    //               ) : (
                    //                 ""
                    //               )}
                    //               {realerted ? (
                    //                 <MuiAlert
                    //                   onClose={handleClose2}
                    //                   severity="success"
                    //                   action={
                    //                     <>
                    //                       <a
                    //                         onClick={() => setRealerted(false)}
                    //                         className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    //                       >
                    //                         close
                    //                       </a>
                    //                     </>
                    //                   }
                    //                 >
                    //                   You've realerted this alert successfully.
                    //                   Your close friends have been notified
                    //                   email and via sms as well.
                    //                 </MuiAlert>
                    //               ) : (
                    //                 ""
                    //               )}
                    //             </div>
                    //           </li>
                    //           <li>
                    //             <ForwardAlerts
                    //               alertId={props.alert.alert.alert_origin}
                    //             />
                    //           </li>
                    //           <li>
                    //             <ForwardAlertRandom
                    //               alertId={props.alert.alert.alert_origin}
                    //             />
                    //           </li>
                    //           <li>
                    //             <a
                    //               onClick={() =>
                    //                 saveAlert(props.alert.alert.id)
                    //               }
                    //               className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    //             >
                    //               <i className="uil-favorite mr-1"></i> Save
                    //               Alert
                    //             </a>
                    //           </li>
                    //           <li>
                    //             <div>
                    //               {saving ? (
                    //                 <p>
                    //                   {" "}
                    //                   Saving Alert... This will take a few
                    //                   seconds{" "}
                    //                 </p>
                    //               ) : (
                    //                 ""
                    //               )}
                    //               {saved ? (
                    //                 <MuiAlert
                    //                   onClose={handleClose2}
                    //                   severity="success"
                    //                   action={
                    //                     <>
                    //                       <a
                    //                         onClick={() => setSaved(false)}
                    //                         className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    //                       >
                    //                         close
                    //                       </a>
                    //                     </>
                    //                   }
                    //                 >
                    //                   You've saved this alert successfully.
                    //                   <a href={`/profile/${userName}`}>
                    //                     View it in your profile
                    //                   </a>
                    //                 </MuiAlert>
                    //               ) : (
                    //                 ""
                    //               )}
                    //             </div>
                    //           </li>
                    //           <li>
                    //             <hr className="-mx-2 my-2 dark:border-gray-800" />
                    //           </li>
                    //           <li>
                    //             <a
                    //               onClick={toggleMenu}
                    //               className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                    //             >
                    //               <i className="feather-x mr-1"></i> Close This
                    //             </a>
                    //           </li>

                    //           <li>
                    //             <hr className="-mx-2 my-2 dark:border-gray-800" />
                    //           </li>
                    //           <li>
                    //             <a
                    //               disabled
                    //               // href="#"
                    //               // onClick={() => setConfirmDelete(true)}
                    //               className="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600"
                    //             >
                    //               <i className="uil-trash-alt mr-1"></i> Delete
                    //             </a>
                    //             {confirmDelete ? (
                    //               <div>
                    //                 {isDeleting ? (
                    //                   <p>Deleting, Just a sec ...</p>
                    //                 ) : (
                    //                   <p>
                    //                     {" "}
                    //                     Are you sure you want to delete this ?{" "}
                    //                   </p>
                    //                 )}

                    //                 <MuiAlert
                    //                   onClose={handleClose2}
                    //                   severity="error"
                    //                   action={
                    //                     <>
                    //                       <a
                    //                         onClick={() =>
                    //                           deleteAlert(props.alert.alert.id)
                    //                         }
                    //                         className="bg-danger p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    //                       >
                    //                         Yes, delete it.
                    //                       </a>
                    //                       <br />
                    //                       <a
                    //                         onClick={() =>
                    //                           setConfirmDelete(false)
                    //                         }
                    //                         className="bg-primary p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                    //                       >
                    //                         No, Cancel
                    //                       </a>
                    //                     </>
                    //                   }
                    //                 >
                    //                   Are you sure you want to delete this ?
                    //                 </MuiAlert>
                    //               </div>
                    //             ) : (
                    //               ""
                    //             )}
                    //           </li>
                    //           <li>
                    //             {isDeleting ? (
                    //               <p>Deleting, Just a sec ...</p>
                    //             ) : (
                    //               ""
                    //             )}
                    //           </li>
                    //         </ul>
                    //       </div>
                    //     ) : (
                    //       // <ClickAwayListener>
                    //       ""
                    //     )}
                    //   </div>
                    // </>
                    <>
                      <AlertDropComp
                        canReport={true}
                        canUpdate={false}
                        canDelete={false}
                        alert={props.alert.alert}
                        functionProp={() => props.functionProp()}
                        functionProp2={() => props.functionProp2()}
                      />
                      {/* <div>
                        <a>
                          {" "}
                          <i
                            onClick={toggleMenu}
                            style={{ fontSize: 25, position: "relative" }}
                            className="feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
                          ></i>{" "}
                        </a>
                        {showMenu ? (
                          // // <ClickAwayListener onClickAway={handleClickAway}>
                          <div
                            className="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
                            // data-uk-drop="mode: click;pos: bottom-right"
                            style={styles}
                          >
                            <ul className="space-y-1">
                              <li>
                                <a
                                  onClick={() =>
                                    realert(props.alert.alert.alert_origin)
                                  }
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-zap mr-1"></i> Re-alert
                                </a>
                              </li>
                              <li>
                                <div>
                                  {realerting ? (
                                    <p>
                                      {" "}
                                      Realerting... This will take a few
                                      seconds. Your close friends will also be
                                      notified by SMS and email{" "}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {realerted ? (
                                    <MuiAlert
                                      onClose={handleClose2}
                                      severity="success"
                                      action={
                                        <>
                                          <a
                                            onClick={() => setRealerted(false)}
                                            className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                          >
                                            close
                                          </a>
                                        </>
                                      }
                                    >
                                      You've realerted this alert successfully.
                                      Your close friends have been notified via
                                      email and sms as well.
                                    </MuiAlert>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </li>
                              <li>
                                <ForwardAlerts
                                  alertId={props.alert.alert.alert_origin}
                                />
                              </li>
                              <li>
                                <ForwardAlertRandom
                                  alertId={props.alert.alert.alert_origin}
                                />
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    saveAlert(props.alert.alert.id)
                                  }
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="uil-favorite mr-1"></i> Save
                                  Alert
                                </a>
                                <li>
                                  <div>
                                    {saving ? (
                                      <p>
                                        {" "}
                                        Saving Alert... This will take a few
                                        seconds{" "}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    {saved ? (
                                      <MuiAlert
                                        onClose={handleClose2}
                                        severity="success"
                                        action={
                                          <>
                                            <a
                                              onClick={() => setSaved(false)}
                                              className="bg-success p-3 z-index-1 rounded-3 text-white font-xsssss text-center text-uppercase fw-700 ls-3"
                                            >
                                              close
                                            </a>
                                          </>
                                        }
                                      >
                                        You've saved this alert successfully.
                                        <a href={`/profile/${userName}`}>
                                          View it in your profile
                                        </a>
                                      </MuiAlert>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </li>
                              </li>

                              <li>
                               
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <ReportModal
                                  type="alert"
                                  text="Report"
                                  url="alerts"
                                  id={props.alert.alert.id}
                                />
                              </li>
                              <li>
                                <hr className="-mx-2 my-2 dark:border-gray-800" />
                              </li>
                              <li>
                                <a
                                  onClick={toggleMenu}
                                  className="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                                >
                                  <i className="feather-x mr-1"></i> Close This
                                </a>
                              </li>
                            </ul>
                          </div>
                        ) : (
                          // <ClickAwayListener>
                          ""
                        )}
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </>
          )}

          {/* <hr className="-mx-2 my-2 dark:border-gray-800" /> */}
          <br />
          <Link
            to={{
              pathname: `/alert/${props.alert.alert.slug}?i=${props.alert.alert.id}`,
              // state: { id: props.alert.alert.id },
            }}
          >
            <h3 className="fw-700 text-grey-900 pointer">
              {" "}
              {props.alert.alert.headline}{" "}
            </h3>
          </Link>

          {props.alert.alert.video ? (
            <div className="card-body p-0 mb-3 rounded-3 overflow-hidden uttam-die">
              {/* <a href="/defaultvideo" className="video-btn"> */}
              <br />
              {/* <Player
                playsInline
                // poster={alert.alert.images[0].url}
                src={props.alert.alert.video}
                fluid
              /> */}
              <ReactPlayer
                url={props.alert.alert.video}
                controls={true}
                config={{
                  file: { attributes: { controlsList: "nodownload" } },
                }}
                playsinline={true}
                width="100%"
                // light={alert..image}
              />
            </div>
          ) : (
            ""
          )}

          {props.alert.alert.images ? (
            <div className="card-body d-block p-0 mb-3">
              <br />
              {/* <div className="row ps-2 pe-2"> */}

              {props.alert.alert.images.length == 1 ? (
                <SingleImage
                  images={props.alert.alert.images}
                  text={props.alert.alert.headline}
                />
              ) : (
                ""
              )}
              {props.alert.alert.images.length == 2 ? (
                <TwoImages
                  images={props.alert.alert.images}
                  text={props.alert.alert.headline}
                />
              ) : (
                ""
              )}
              {props.alert.alert.images.length == 3 ? (
                <ThreeImages
                  images={props.alert.alert.images}
                  text={props.alert.alert.headline}
                />
              ) : (
                ""
              )}
              {props.alert.alert.images.length == 4 ? (
                <FourImages
                  images={props.alert.alert.images}
                  text={props.alert.alert.headline}
                />
              ) : (
                ""
              )}
              {props.alert.alert.images.length > 4 && (
                <Carousel>
                  {props.alert.alert.images.map((slideImage, index) => (
                    <img
                      src={slideImage.url}
                      key={index}
                      style={{ zIndex: -10 }}
                      alt=""
                    />
                  ))}
                </Carousel>
              )}

              {/* <div className="col-sm-12 p-1">
                      <img
                        src={props.alert.alert.image}
                        className="rounded-3 w-100"
                        alt="Alert Image"
                        style={{
                          maxWidth: 800,
                          maxHeight: 450,
                          objectFit: "contain",
                        }}
                      />
                    </div> */}

              {/* </div> */}
            </div>
          ) : (
            ""
          )}

          <div className="card-body p-0 me-lg-5">
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more"
              less="Show less"
              className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2"
              // className="content-css"
              anchorClass="my-anchor-css-class"
              // onClick={executeOnClick}
              expanded={false}
              // width={480}
              truncatedEndingComponent={"... "}
            >
              {props.alert.alert.body}
            </ShowMoreText>
            {/* <p className="fw-500 text-black-500 lh-26 font-xssss w-100 mb-2">{alert.body} <a href="/defaultvideo" className="fw-600 text-primary ms-2">See more</a></p> */}
            <Link
              to={`/alert-category/${props.alert.alert.alert_category.slug}`}
            >
              {" "}
              <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                {" "}
                Category Type:{" "}
                <Chip
                  className="pointer infotag"
                  color="primary"
                  size="small"
                  label={props.alert.alert.alert_category.name}
                />{" "}
                <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                  {" "}
                </span>{" "}
              </h6>{" "}
            </Link>
            {/* {props.alert.alert.type == "distress" ? (
              <> */}
            <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
              {" "}
              Alert Type:{" "}
              <Chip
                className="pointer infotag"
                color="primary"
                size="small"
                label={props.alert.alert.type}
              />{" "}
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                {" "}
              </span>{" "}
            </h6>{" "}
            <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
              {" "}
              Status:{" "}
              <Chip
                className="pointer infotag"
                color="primary"
                size="small"
                label={props.alert.alert.status}
              />{" "}
              <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                {" "}
              </span>{" "}
            </h6>{" "}
            {/* </>
            ) : (
              <></>
            )} */}
            {!props.alert.alert.location == "" ? (
              <>
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  Location:{" "}
                  <Chip
                    className="pointer infotag"
                    color="primary"
                    size="small"
                    label={props.alert.alert.location}
                  />{" "}
                  <span className="d-block font-xssss fw-500 mt-1 lh-3 text-primary pointer">
                    {" "}
                  </span>{" "}
                </h6>{" "}
              </>
            ) : (
              ""
            )}
            {!props.alert.alert.url == "" ? (
              <a href={props.alert.alert.url} target="_blank">
                {/* <Link to={`/alert.alert/${alert.alert.slug}`} state={{ id: alert.alert.id }}> */}
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  {" "}
                  URL: <Chip
                      className="pointer infotag"
                      color="primary"
                      size="small"
                      label={props.alert.alert.url}
                    />{" "}
                </h6>
              </a>
            ) : (
              ""
            )}
            {!props.alert.alert.tags.length == 0 ? (
              <>
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  Info Tags{" "}
                </h6>
                <div>
                  {props.alert.alert.tags.map((tag) => (
                    <>
                      <a href={`/alert-tag/${tag.slug}`}>
                        {/* <Link to={`/alert-tag/${tag.slug}`}> */}
                        <Chip
                          style={{ marginRight: 10, marginBottom: 5 }}
                          className="infotag pointer"
                          variant="outlined"
                          color="primary"
                          size="small"
                          label={`# ${tag.name}`}
                        />
                        {/* </Link> */}
                      </a>
                    </>
                  ))}
                  <br /> <br />
                </div>
              </>
            ) : (
              ""
            )}
            {userType === "true" ? (
              <>
                <h6 className="fw-500 text-grey-900 font-xssss mt-1 pointer">
                  View Location
                </h6>
                <div>
                  <a
                    href={`/alert/location/${props.alert.alert.slug}?i=${props.alert.alert.alert_origin}`}
                  >
                    {/* <Link to={`/alert-tag/${tag.slug}`}> */}
                    <Chip
                      style={{ marginRight: 10, marginBottom: 5 }}
                      className="infotag pointer"
                      variant="outlined"
                      color="primary"
                      size="small"
                      label={`View Location`}
                    />
                    {/* </Link> */}
                  </a>

                  <br />
                </div>
              </>
            ) : (
              ""
            )}
          </div>

          {/* <br /> */}
          {!props.alert.alert.likers.length == 0 ? (
            <LikesProfilePic
              type="alert"
              likers={props.alert.likers}
              likes={props.alert.alert.likers}
              comments={props.alert.alert.alert_comments_count}
              realerts={props.alert.alert.re_alerts}
              allLikers={props.alert.alert.likers}
              color="#000"
              text="thanks"
              likersType="Alert"
            />
          ) : (
            ""
          )}
     

          {props.isGuest == 'true' ?
          // 'yoou cant'
          <div className="card-body d-flex p-0">
          <div
            className="emoji-bttn pointer d-flex align-items-center fw-600 lh-26 font-xssss me-2"
            onClick={toggleActive}
          >
            
                    <ThumbUpAltOutlinedIcon
                      // onClick={() => likeAlert(props.alert.alert.id)}
                      style={{ fontSize: 25, color: "#eee" }}
                    />{" "}
              
            <span style={{ paddingLeft: 5 }}>
              {props.alert.alert.likers.length}
              {/* <LikersModal */}
              {/* <LikersModal likers={props.alert.alert.likers} color='#000' text='Thanks' type='Alert'
                class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss" /> */}
              {/* {props.alert.alert.likers.length} thanks{" "} */}
            </span>

            <div className="pointer">
              
                <FlashOnIcon
                  style={{
                    marginLeft: 10,
                    fontSize: 25,
                    color: "#eee",
                    cursor: "pointer",
                  }}
                 
                />
             
              {props.alert.alert.re_alerts}
              {/* re-alerts{" "} */}
            </div>
            {/* <i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> <i className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i>2.8K Like */}
          </div>
          <div style={{ marginLeft: 13 }}></div>

          <div style={{ marginLeft: 10 }}></div>
          <a
            // href="#"
            className="d-flex align-items-center text-grey-900 text-dark fw-600 lh-26 font-xssss"
          >
            <i
              style={{ color: "#2516c7", fontSize: 25 }}
              className="feather-message-circle text-700 btn-round-sm "
            ></i>
            {isComment ? (
              <span className="d-none-xss" onClick={toggleComment}>
                {/* 283 Comments */}
                Close {props.alert.alert.alert_comments_count} updates
                {/* View 0 Comments */}
              </span>
            ) : (
              <span className="d-none-xss" onClick={toggleComment}>
                {/* 283 Comments */}
                See {props.alert.alert.alert_comments_count} updates
                {/* View 0 Comments */}
              </span>
            )}
          </a>
          <div
            className={`pointer ms-auto d-flex align-items-center fw-600 text-current lh-26 font-xssss ${menuClass}`}
          >
            <ShareButtons alert={props.alert.alert} />
          </div>
        </div>
        :
        <div className="card-body d-flex p-0">
        <div
          className="emoji-bttn pointer d-flex align-items-center fw-600 lh-26 font-xssss me-2"
          onClick={toggleActive}
        >
          {isLiking ? (
            <HourglassEmptyRoundedIcon
              style={{ fontSize: 25, color: "#2516c7" }}
            />
          ) : (
            <>
              {props.alert.likedStatus == "false" ? (
                <>
                  {/* <p>hi2</p> */}
                  {/* <FavoriteBorderIcon */}
                  <ThumbUpAltOutlinedIcon
                    onClick={() => likeAlert(props.alert.alert.id)}
                    style={{ fontSize: 25, color: "#2516c7" }}
                  />{" "}
                </>
              ) : (
                <>
                  {/* <p>hi</p> */}
                  {/* <FavoriteRoundedIcon */}
                  <ThumbUpAltIcon
                    onClick={() => likeAlert(props.alert.alert.id)}
                    style={{ fontSize: 25, color: "#2516c7" }}
                  />{" "}
                </>
              )}
            </>
          )}
          <span style={{ paddingLeft: 5 }}>
            {props.alert.alert.likers.length}
            {/* <LikersModal */}
            {/* <LikersModal likers={props.alert.alert.likers} color='#000' text='Thanks' type='Alert'
              class="d-flex align-items-center fw-900 text-grey-900 text-dark lh-26 font-xssss" /> */}
            {/* {props.alert.alert.likers.length} thanks{" "} */}
          </span>

          <div className="pointer">
            {realerting ? (
              <HourglassEmptyRoundedIcon
                style={{ fontSize: 25, color: "#2516c7" }}
              />
            ) : (
              <FlashOnIcon
                style={{
                  marginLeft: 10,
                  fontSize: 25,
                  color: "#2516c7",
                  cursor: "pointer",
                }}
                onClick={() => {
                  realert(props.alert.alert.alert_origin);
                }}
              />
            )}
            {props.alert.alert.re_alerts}
            {/* re-alerts{" "} */}
          </div>
          {/* <i className="feather-thumbs-up text-white bg-primary-gradiant me-1 btn-round-xs font-xss"></i> <i className="feather-heart text-white bg-red-gradiant me-2 btn-round-xs font-xss"></i>2.8K Like */}
        </div>
        <div style={{ marginLeft: 13 }}></div>

        {authId[0] == props.alert.alert.user.id && (
          <span className="d-flex align-items-center fw-800 text-grey-800 text-dark lh-26 font-xssss">
            <FlashAutoIcon
              style={{ fontSize: 25, color: "#2516c7", cursor: "pointer" }}
              onClick={() => {
                // setCurrentAlert(props.alert.alert.id);
                toggleThread();
              }}
            />{" "}
          </span>
        )}

        <div style={{ marginLeft: 10 }}></div>
        <a
          // href="#"
          className="d-flex align-items-center text-grey-900 text-dark fw-600 lh-26 font-xssss"
        >
          <i
            style={{ color: "#2516c7", fontSize: 25 }}
            className="feather-message-circle text-700 btn-round-sm "
          ></i>
          {isComment ? (
            <span className="d-none-xss" onClick={toggleComment}>
              {/* 283 Comments */}
              Close {props.alert.alert.alert_comments_count} updates
              {/* View 0 Comments */}
            </span>
          ) : (
            <span className="d-none-xss" onClick={toggleComment}>
              {/* 283 Comments */}
              See {props.alert.alert.alert_comments_count} updates
              {/* View 0 Comments */}
            </span>
          )}
        </a>
        <div
          className={`pointer ms-auto d-flex align-items-center fw-600 text-current lh-26 font-xssss ${menuClass}`}
        >
          <ShareButtons alert={props.alert.alert} />
        </div>
      </div>
        }

{props.isGuest == 'true' ?
<div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
<p>
                      Signup to follow @{props.alert.alert.user.user_name} and see past and
                      future alarrts in your timeline{" "}
                    </p>
                    <a
                        href='/signup'
                        style={{ marginTop: -8 }}
                        className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                        Signup
                      </a>
                    </div>
                    </div>
:
<>
          {props.alert.followingUser == "true" ? (
            ""
          ) : (
            <>
              {userId[0] == props.alert.alert.user.id ? (
                ""
              ) : (
                <div className="flex items-center justify-between py-3">
                  <div className="flex flex-1 items-center space-x-4">
                    {" "}
                    <br />
                    <p>
                      Follow @{props.alert.alert.user.user_name} to see past and
                      future alarrts in your timeline{" "}
                    </p>{" "}
                    {following ? (
                      <a
                        // className="ms-auto pointer"
                        disabled
                        // onClick={() => Follow(alert.alert.user.user_name)}
                        style={{ marginTop: -8 }}
                        className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                        {" "}
                        {followText}{" "}
                      </a>
                    ) : (
                      <a
                        // className="ms-auto pointer"
                        onClick={() => Follow(props.alert.alert.user.user_name)}
                        style={{ marginTop: -8 }}
                        className="ms-auto pointer border border-gray-200 font-semibold px-4 py-1 rounded-full hover:bg-success-600 hover:text-primary hover:border-success-600 dark:border-gray-800"
                      >
                        {" "}
                        {followText}{" "}
                      </a>
                    )}
                  </div>
                  <br />
                  {followed ? (
                    <Snackbar
                      open={followed}
                      autoHideDuration={3000}
                      onClose={closeSnack}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Alert onClose={closeSnack} severity="success">
                        Followed {props.alert.alert.user.user_name} Successfully
                      </Alert>
                    </Snackbar>
                  ) : (
                    ""
                  )}

                  {deleted ? (
                    <Snackbar
                      open={deleted}
                      autoHideDuration={3000}
                      onClose={closeSnack}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Alert onClose={closeSnack} severity="success">
                        Deleted Alert Successfully
                      </Alert>
                    </Snackbar>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          )}

</>
  }


          {isThread && (
            <CreateThread
              Id={props.alert.alert.id}
              url={"alert_threads"}
              threads={props.alert.alert.threads}
              functionProp={props.functionProp}
              // openComment={openComment}
              // favUrl={"alert"}
              authId={authId[0]}
              userId={props.alert.alert.user.id}
            />
          )}
          <AllThreads
            Id={props.alert.alert.id}
            url={"alert_threads"}
            user_name={props.alert.alert.user.user_name}
            pic={props.alert.alert.user.profile_pic}
            threads={props.alert.alert.threads}
            functionProp={props.functionProp}
            // openComment={openComment}
            // favUrl={"alert"}
            authId={authId[0]}
            userId={props.alert.alert.user.id}
          />

          {/* <div className="card-body d-block p-0 mb-3"> */}
          {props.isGuest ?
          <>
          {isComment ? (
            <>
              <SingleComment
                Id={props.alert.alert.id}
                comments={props.alert.comments}
                favUrl={"alert"}
                deleteUrl="alertComment"
                url1={"alerts"}
                functionProp={props.functionProp}
                // authId={authId[0]}
                authId='0'
                userId={props.alert.alert.user.id}
                isGuest={props.isGuest}
                mutipleMedia='true'
              />
              <span
                className="fw-600 text-grey-900 mt-2 pointer"
                onClick={toggleComment}
              >
                Close Comments
              </span>
            </>
          ) : (
            ""
          )}
          </>
        :
        <>
          <CreateComment
            Id={props.alert.alert.id}
            url={"alerts/comment"}
            favComments={props.alert.favComments}
            functionProp={props.functionProp}
            openComment={openComment}
            favUrl={"alert"}
            authId={authId[0]}
            userId={props.alert.alert.user.id}
            mutipleMedia='true'
            // alertId = {props.alert.alert.id}
          />

          {isComment ? (
            <>
              <SingleComment
                Id={props.alert.alert.id}
                comments={props.alert.comments}
                favUrl={"alert"}
                deleteUrl="alertComment"
                url1={"alerts"}
                functionProp={props.functionProp}
                authId={authId[0]}
                userId={props.alert.alert.user.id}
                mutipleMedia='true'
              />
              <span
                className="fw-600 text-grey-900 mt-2 pointer"
                onClick={toggleComment}
              >
                Close Comments
              </span>
            </>
          ) : (
            ""
          )}

</>
        }

          {/* </div> */}

          {realerted ? (
            <Snackbar
              open={realerted}
              autoHideDuration={2000}
              onClose={handleClose2}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={handleClose2} variant="filled" severity="success">
                Re alerted successfully
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}

          {saved ? (
            <Snackbar
              open={saved}
              autoHideDuration={5000}
              onClose={closeSnack}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Alert onClose={closeSnack} severity="success">
                Saved Alert Successfully. View In Your Profile
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
        </div>
        {/* ))} */}
      </>
    );
  }
  // }
}

// export default Alerts;
